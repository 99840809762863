import React, {useState} from "react";
import {Workspace} from "../../components/Workspace";
import {Tray} from "../../components/Tray";
import {ConsoleFeedView} from "./ConsoleFeedView";
import Editor from "@monaco-editor/react";
import {LogEventViewer} from "./LogEventViewer";
import {v4} from "uuid";
import {DerbyshireState} from "./DerbyshireState";
import {StateDispatcher} from "../../../ship/test/core/StateDispatcher";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";

export type DerbyshireMainProps = {
}

export const DerbyshireMain: React.FC<DerbyshireMainProps> = props => {
    const [state, setState] = useState<DerbyshireState>({
        openLogEntry: undefined,
        unorderedLog: [],
        showInfosInLogEventViewer: true
    });

    return (
        <DerbyshireStateContext.Provider value={[state, setState]} children={
            <div style={{
                paddingTop: "8px",
                display: "grid",
                gridTemplateColumns: "auto min-content",
                height: "100%",
                width: "100%",
                overflow: "hidden"
            }}>
                <div style={{
                    display: "grid",
                    gridTemplateRows: "auto min-content",
                    width: "100%",
                    height: "calc(100% - 0px)",
                    overflow: "hidden"
                    // gap: "8px",
                }}>
                    <Workspace children={<ConsoleFeedView/>} config={{mode: "desktop", name: "style-library"}}/>
                    {/*
                    <div style={{
                        width: "100%",
                        height: "60px",
                        backgroundColor: "#101016",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} children={
                        <Editor
                            className={"searchbar-input"}
                            height={"22px"}
                            width={"100%"}
                            saveViewState
                            options={{
                                fontSize: 16,
                                fontLigatures: true,
                                lineNumbers: "off",
                                autoIndent: "full",
                                codeLens: false,
                                cursorSmoothCaretAnimation: true,
                                smoothScrolling: true,
                                autoClosingBrackets: "always",
                                autoClosingQuotes: "always",
                                hideCursorInOverviewRuler: true,
                                lineDecorationsWidth: 0,
                                renderValidationDecorations: "off",
                                overviewRulerBorder: false,
                                renderLineHighlight: "none",
                                cursorStyle: "underline",
                                matchBrackets: "always",
                                scrollbar: {
                                    vertical: "hidden",
                                    horizontal: "hidden"
                                },
                                minimap: {
                                    enabled: false
                                },
                            }}
                            onMount={(editor, monaco) => {
                                editor.onKeyDown(e => {
                                    if (e.code === "Enter") {
                                        const value = editor.getValue();
                                        if (value.trim().length === 0) return;
                                        try {
                                            const res = eval(value);
                                            console.log(res ?? "<- void");
                                        } catch (e) {
                                            console.error(e)
                                        }
                                        e.preventDefault();
                                        editor.setValue("");
                                    }
                                });
                            }}
                            beforeMount={monaco => {
                                monaco.editor.defineTheme("ses-x-dark-tritanopia-notes", {
                                    base: "vs-dark",
                                    inherit: true,
                                    rules: [],
                                    colors: {
                                        "editor.background": "#101016",
                                        "editor.lineHighlightBackground":  "#101016",
                                    }
                                });
                            }}
                            theme={"ses-x-dark-tritanopia-notes"}
                            language={"javascript"}
                        />
                    }/>
                    */}
                </div>

                <TransitionGroup style={{ height: "100%" }}>
                    { state.openLogEntry !== undefined && (
                        <Collapse style={{ height: "100%" }} orientation={"horizontal"} children={
                            <Tray children={
                                <LogEventViewer/>
                            } style={{
                                marginLeft: "8px",
                                height: "100%"
                            }} config={{
                                name: "right",
                                growOrientation: "vertical",
                                resizable: true,
                                resizablePropertyOverrides: {
                                    width: 350,
                                    axis: "x",
                                    resizeHandles: ["w"]
                                }
                            }}/>
                        }/>
                    ) }
                </TransitionGroup>
            </div>
        }/>
    );
}

export const DerbyshireStateContext = React.createContext<
    [DerbyshireState, StateDispatcher<DerbyshireState>]
>([{
    unorderedLog: [],
    showInfosInLogEventViewer: true
}, () => {}]);
