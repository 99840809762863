import {GuardStrategy} from "./GuardStrategy";

/**
 * Checks if the user has a session
 */
export const isWithSession: GuardStrategy = () => !!localStorage.getItem("session-token");

/**
 * Checks if the user isn't logged in.
 *
 * This is used for the login / boarding / signup pages, which should automatically be bypassed
 * if the user is already logged in.
 */
export const isWithoutSession: GuardStrategy = () => !localStorage.getItem("session-token");

