import React, {useContext} from "react";
import {ColorPaletteEntry} from "./ColorPaletteEntry";
import styled from "styled-components";
import {CCPContext} from "./ControlledColorPicker";

const StyledColorTile = styled.div<{ cpe: ColorPaletteEntry }>`
  --outline-size: 1px;
  background-color: ${p => p.cpe.color.toHex()};
  width: 100%;
  // height: 44px;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
  transition: outline 100ms;
  outline-offset: -2px;
  outline: var(--outline-size) solid rgba(147, 112, 219, 0);

  // border: 5px solid #1C6EA4;

  &:hover {
    // outline: 2px solid rgb(48, 54, 61);
    outline: var(--outline-size) solid rgba(147, 112, 219, 1);
  }
`;

export type ColorTileProps = {
    color: ColorPaletteEntry
}

export const ColorTile: React.FC<ColorTileProps> = props => {
    const ccp = useContext(CCPContext)!;

    return (
        <StyledColorTile cpe={props.color} onClick={() => {
            ccp.updateColor(props.color.color);
        }}/>
    );
}
