import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {triton} from "../../../../triton/Triton";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";

/**
 * TODO: Add functionality
 */
export const SystemEnvironmentVariable: NodeSetupInfo = {
    label: "SystemEnvironmentVariable",
    classname: "system.env.variable",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "system.env.variable",
        label: "env var",
        defOutPins: ["="],
        visualConfig: {
            dye: triton().col("color_primary")
        },
        customRenderer() {
            return (
                <DescriptiveTypography text={"xyz"}/>
            );
        }
    })
}
