import React from "react";
import styled from "styled-components";
import {ButtonGroup} from "../components/ButtonGroup";
import {AddRounded, RemoveRounded} from "@mui/icons-material";
import {IconButton} from "../components/IconButton";
import {PropsDeclarationPrefix} from "./PropsDeclarationPrefix";

const StyledStringField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  .field-prefix {
    font-family: Consolas, "Courier New", monospace;
    font-size: 13px;
    color: white;
    margin: 0;
    white-space: nowrap;
  }
  
  .string-input {
    flex-grow: 1;
    border: none;
    border-radius: 8px;
    background-color: #101016;
    height: 32px;
    outline: none;
    font-family: Consolas, "Courier New", monospace;
    color: white;
  }
`;

export type StringFieldProps = {
    name: string,
    data: string,
    onChange: (data: string) => void
}

export const StringField: React.FC<StringFieldProps> = props => {
    return (
        <StyledStringField>
            <PropsDeclarationPrefix type={"string"} name={props.name}/>

            <input className={"string-input"} value={props.data} inputMode={"text"} type={"text"} onChange={event => {
                props.onChange(event.currentTarget.value);
            }}/>

        </StyledStringField>
    );
}
