import {APIShard} from "../APIShard";
import {FSStateMirror} from "./FSStateMirror";
import {NetDisk, NetDiskEvents} from "./NetDisk";
import {IDisk} from "./IDisk";
import {Observer} from "../pubsub/Observer";

export enum FSManagerEvents {
    STATE_UPDATED,
    DISK_STATE_UPDATED
}

export class FSManager extends APIShard<
    FSStateMirror,
    FSManagerEvents,
    FSStateMirror
> {

    protected getInitialStateMirror(): FSStateMirror | undefined {
        return ({
            currentDiskID: undefined,
            disks: []
        });
    }

    private diskObserver?: Observer<any>;

    init() {
        super.init();

        this.registerDisk(new NetDisk(this, { id: "net" }, {
            // url: "http://127.0.0.1:8080/fs/ls/"
            url: "192.168.178.20:8080"
        }));
        this.mountDisk("net");
    }

    public isADiskMounted(): boolean {
        return this.stateMirror.currentDiskID !== undefined;
    }

    public registerDisk(disk: IDisk): this {
        this.setState(prevState => ({
            disks: [...prevState.disks, disk]
        }));
        disk.register();
        return this;
    }

    public switchDisk(toDiskID: string) {
        this.unmountCurrentDisk();
        this.mountDisk(toDiskID);
    }

    private unmountCurrentDisk() {
        if (!this.stateMirror.currentDiskID) return;
        this.disk.unmount();
        this.diskObserver?.destructor();
        this.diskObserver = undefined;
        this.setState(() => ({
            currentDiskID: undefined
        }));
    }

    private mountDisk(diskID: string) {
        this.setState(() => ({
            currentDiskID: diskID
        }));
        this.disk.mount();
        this.diskObserver = this.disk.observable.observe(NetDiskEvents.FS_LS_SNAPSHOT_CHANGE).on(() => {
            this.notify(FSManagerEvents.DISK_STATE_UPDATED, this.stateMirror);
        });
    }

    public get disk(): IDisk {
        return this.stateMirror.disks
            .find(disk => disk.info.id === this.stateMirror.currentDiskID)!!;
    }

    private setState(updater: (prevState: FSStateMirror) => Partial<FSStateMirror>) {
        this.stateMirror = {
            ...this.stateMirror,
            ...updater(this.stateMirror)
        };
        this.indicateStateChange()
    }

    private indicateStateChange() {
        this.stateMirror = {...this.stateMirror};
        this.notify(FSManagerEvents.STATE_UPDATED, this.stateMirror)
    }
}
