import React, {FC, useEffect} from "react";
import {Pin} from "../backend/Pin";
import styled from "styled-components";
import {useTriton} from "../../triton/TritonHooks";
import {Triton} from "../../triton/Triton";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {NodePinDisplay} from "./NodePinDisplay";
import {Tag} from "../../ardai/components/Tag";
import {useNodeCanvasBackend} from "./NodeCanvasBackend";
import {NodeCanvasBackendEventTypes} from "./NodeCanvasBackendEventTypes";
import {useKeyboardEvent} from "../../ardai/_/keyCommand/test/KeyCommandTest";
import {DefaultCharacterSymbols} from "./DefaultCharacterSymbols";
import {ColorableTag} from "../../ardai/components/ColorableTag";
import {useForceRenderFunc} from "../../ForceRerenderHook";
import {NodeEventTypes} from "../backend/NodeEventTypes";

const StyledWireInfoCard = styled.div<{
    t: Triton
}>`
  --padding: 8px;
  z-index: 10;
  display: grid;
  grid-template-rows: min-content auto min-content;
  border-radius: 8px;
  background-color: ${p => p.t.col("bg_modal")};

  * {
    font-family: "JetBrains Mono", monospace;
  }
  
  .header {
    padding: var(--padding);
    display: flex;
    align-items: center;
  }

  .main {
    display: grid;
    grid-template-columns: 1fr auto 1fr;

    .pin-bank {
      
    }
  }
  
  .footer {
    padding: var(--padding);
    display: flex;
    gap: 4px;
    align-items: center;
  }
`;

export type WireInfoCardProps = {
    originPort: Pin,
    destPort: Pin,
    closeInfo: () => void
}

export const WireInfoCard: FC<WireInfoCardProps> = props => {
    const backend = useNodeCanvasBackend();
    const t = useTriton();

    const cutWire = () => {
        props.originPort.disconnect(props.destPort);
        props.closeInfo();
        backend.events.notify(NodeCanvasBackendEventTypes.GRAPH_UPDATE, undefined);
    }

    // const forceRender = useForceRenderFunc();
    // TODO: How to live update the wire info in pin name & type change
    // useEffect(() => backend.events.observeGlobally().on((key, data) => {
    //     forceRender();
    // }).destructor, []);

    useKeyboardEvent("keydown", e => {
        if (e.key === "Delete") {
            cutWire();
        }
    })

    return (
        <StyledWireInfoCard t={t}>
            <div className={"header handle"}>
                <DescriptiveTypography text={`${props.originPort.config.label ?? DefaultCharacterSymbols.placeholder}: `} style={{ whiteSpace: "pre" }}/>
                <ColorableTag tag={props.originPort.config.typeName ?? DefaultCharacterSymbols.placeholder} c={t.colObj("color_secondary")}/>

                <DescriptiveTypography text={` -> `} style={{ whiteSpace: "pre" }}/>

                <DescriptiveTypography text={`${props.destPort.config.label ?? DefaultCharacterSymbols.placeholder}: `} style={{ whiteSpace: "pre" }}/>
                <ColorableTag tag={props.destPort.config.typeName ?? DefaultCharacterSymbols.placeholder} c={t.colObj("color_secondary")}/>
            </div>
            <div className={"main"}>

            </div>
            <div className={"footer"}>
                <Tag tag={`cut`} onClick={() => {
                    cutWire();
                }}/>
                <Tag tag={`close`} onClick={props.closeInfo}/>
            </div>
        </StyledWireInfoCard>
    );
}
