import React from "react";
import {CSSProperties} from "styled-components";

export type TextVariants = string
    | "normal-title"
    | "normal-text"
    | "normal-secondary"
    | "normal-link-button"
    | "artistic-display"
    ;

export type ArcTextProps = {
    text: React.ReactNode,
    variant?: TextVariants
}

export const variantStyleMapping = new Map<TextVariants, CSSProperties>([
    ["normal-title", {
        color: "white",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "23px"
    }],
    ["normal-text", {
        color: "white",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "14px"
    }],
    ["normal-secondary", {
        color: "white",
        opacity: .6,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "14px"
    }],
    ["normal-link-button", {
        color: "#B55EF9",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "14px"
    }],
    ["artistic-display", {
        color: "white",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: 800,
        lineHeight: "44px",
        fontFamily: "DM Serif Display"
    }],
])

export const ArcText: React.FC<ArcTextProps> = props => {
    const variant = props.variant ?? "normal-text";
    return (
        <p style={{
            ...variantStyleMapping.get(variant),
            padding: 0,
            margin: 0
        }} children={props.text}/>
    );
}
