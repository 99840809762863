import React from "react";
import {Color} from "../../base/logic/style/Color";
import styled from "styled-components";
import {PropsDeclarationPrefix} from "./PropsDeclarationPrefix";
import {MenuButton} from "../components/MenuButton";
import {IconButton} from "../components/IconButton";
import {AddRounded, MoreRounded, MoreVertRounded} from "@mui/icons-material";
import {CopyIcon} from "../../base/components/base/CopyIcon";
import {ClipboardCopyButton} from "../components/ClipboardCopyButton";
import {Contextable} from "../../triton/components/Contextable";
import {ControlledColorPicker} from "../../triton/components/forms/ControlledColorPicker";
import {Menu} from "../components/Menu";

const StyledColorField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  
  .color-input-wrapper {
    width: 100%;
    
    & > * {
      flex-grow: 1;
      display: flex;
    }
  }
  
  .color-input {
    flex-grow: 1;
    border: none;
    border-radius: 8px;
    background-color: #101016;
    height: 32px;
    outline: none;
    font-family: Consolas, "Courier New", monospace;
    color: white;
    cursor: pointer;   
  }
`;

export type ColorFieldProps = {
    name: string,
    data: Color,
    onChange: (data: Color) => void
}

export const ColorField: React.FC<ColorFieldProps> = props => {
    return (
        <StyledColorField>
            <PropsDeclarationPrefix name={props.name} type={"color"}/>

            <div className={"color-input-wrapper"} children={
                <Menu
                    menuProps={{ containerProps: { style: { flexGrow: 1 } } }}
                    opener={
                        <input
                            className={"color-input"}
                            value={props.data.toHex()}
                            type={"color"}
                            onClick={e => e.preventDefault()}
                        />
                    }
                    children={<ControlledColorPicker
                        color={props.data}
                        onChange={color => props.onChange(color)}
                    />}
                />
            }/>



            <ClipboardCopyButton copyValueProducer={() => props.data.toHex()}/>
        </StyledColorField>
    );
}
