import React from "react";
import {FormElement} from "../../triton/components/forms/FormElement";
import {Slider} from "@mui/material";
import {IconButton} from "../../ardai/components/IconButton";
import {InfoRounded, MoreHorizRounded, RefreshRounded, TextSnippetRounded} from "@mui/icons-material";
import {Formik} from "formik";
import styled from "styled-components";
import {Menu} from "../../ardai/components/Menu";
import {MenuButton} from "../../ardai/components/MenuButton";
import {PromptRenderMethod} from "../../ardai/components/promptRenderer/PromptRenderMethod";
import {FlatIconButton} from "../../ardai/components/FlatIconButton";
import {useTriton} from "../../triton/TritonHooks";
import {Tag} from "../../ardai/components/Tag";
import {Triton} from "../../triton/Triton";

export const StyledVariableSlider = styled.div<{
    t: Triton
}>`  
  
  .body-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .number-input {
    border: none;
    border-radius: 8px;
    // background-color: #101016;
    background-color: ${p => p.t.col("input_background")};
    height: 32px;
    outline: none;
    text-align: center;
    font-family: Consolas, "Courier New", monospace;
    color: white;
  }
  
  .suggestions {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export type VariableSliderSuggestion = {
    value: number | (() => number),
    label: React.ReactNode,

}

export type VariableSliderProps = {
    title?: string,
    shortInfoText?: string,
    minVal?: number,
    maxVal?: number,
    value?: number,
    defaultValue?: number,
    stepSize?: number,
    suggestions?: Array<VariableSliderSuggestion>,

    onChange?: (value: number) => void,

    simpleMode?: boolean,
    minSliderWidth?: string | number
}

export const VariableSlider: React.FC<VariableSliderProps> = props => {
    const t = useTriton();
    const value = props.value ?? props.defaultValue;
    const stepSize = props.stepSize ?? 1;
    const min = props.minVal ?? 1;
    const max = props.maxVal ?? 100;
    const markDefaultValue = true;
    const isSimpleMode = props.simpleMode ?? false;

    const setValue = (value: number) => {
        props.onChange?.(value);
    }

    return (
        <StyledVariableSlider t={t}>
            <FormElement title={props.title}>
                <div className={"body-container"}>
                    <div style={{
                        flexDirection: "row",
                        alignItems: "center",
                        display: "flex",
                        width: "100%",
                        gap: "8px",
                    }}>
                        <div style={{
                            // backgroundColor: "#101016",
                            backgroundColor: t.col("input_background"),
                            flexDirection: "row",
                            borderRadius: "8px",
                            alignItems: "center",
                            display: "flex",
                            padding: "8px 16px",
                            height: "32px",
                            width: "100%"
                        }}>
                            <Slider
                                onChange={(event, value) => {
                                    setValue(value as number);
                                }}
                                value={value}
                                valueLabelDisplay={"auto"}
                                min={min}
                                max={max}
                                step={stepSize}
                                // sx={{ color: "#5028c8" }}
                                sx={{
                                    minWidth: props.minSliderWidth,
                                    transition: "all 80ms",
                                    color: t.col("color_secondary"),
                                    "&:active": {
                                        color: t.col("color_primary"),

                                        ".MuiSlider-track": {
                                            height: 8
                                        }
                                    }
                                }}
                                size={"small"}
                                marks={[
                                    (markDefaultValue && props.defaultValue) ? {
                                        value: props.defaultValue,
                                    } : undefined
                                ].filter(mark => mark !== undefined) as any[]}
                            />
                        </div>


                        <input
                            className={"number-input"}
                            value={value}
                            inputMode={"numeric"}
                            min={min}
                            max={max}
                            type={"number"}
                            step={stepSize}
                            onChange={event => {
                                setValue(Number(event.currentTarget.value));
                            }}
                        />

                        { !isSimpleMode && (
                            <>
                                <FlatIconButton
                                    deactivated={props.defaultValue === undefined}
                                    children={<RefreshRounded fontSize={"small"}/>}
                                    tooltip={props.defaultValue === undefined ? undefined : `Reset to ${props.defaultValue}`}
                                    onClick={() => setValue(props.defaultValue ?? min)}
                                />

                                <Menu opener={
                                    <FlatIconButton
                                        children={<MoreHorizRounded fontSize={"small"}/>}
                                    />
                                }>
                                    <MenuButton text={"Full documentation"} icon={<InfoRounded/>}/>
                                </Menu>
                            </>
                        ) }
                    </div>

                    { props.suggestions && (
                        <div className={"suggestions"}>
                            { props.suggestions.map((suggestion, idx) => (
                                <Tag active={suggestion.value === value} key={idx} tag={suggestion.label} onClick={() => {
                                    setValue(typeof suggestion.value === "function" ?
                                        suggestion.value() :
                                        suggestion.value
                                    );
                                }}/>
                            )) }
                        </div>
                    ) }


                </div>
            </FormElement>
        </StyledVariableSlider>

    );
}
