import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {triton} from "../../../../triton/Triton";
import {LogDisplayComponent} from "../components/LogDisplayComponent";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {Tag} from "../../../../ardai/components/Tag";
import {
    AutoGraphRounded,
    ClearAllRounded,
    TextDecreaseRounded,
    TextIncrease,
    TextIncreaseRounded
} from "@mui/icons-material";
import {ButtonGroup} from "../../../../ardai/components/ButtonGroup";

export const SimplePinLogger: NodeSetupInfo = {
    label: "SimplePinLogger",
    classname: "simple-pin-logger",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "simple-pin-logger",
        label: "pin logger (simple)",
        defInPins: ["log", "cls"],
        state: {
            logLines: new Array<string>(),
            fontSize: 10
        },
        init() {
            const node = this;
            const logHistoryLength = 100;

            this.pins.in("log").attach({
                read(logLine: any) {
                    node.state.update(prevState => ({
                        logLines: [String(logLine), ...prevState.logLines].slice(0, logHistoryLength)
                    }));
                }
            });

            this.pins.in("cls").attach({
                read(signal: any) {
                    node.state.update({
                        logLines: []
                    });
                }
            });
        },
        customRenderer: node => (
            <div style={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
                padding: 0,
                alignItems: "center",
                height: "100%",
                justifyContent: "center"
            }}>
                <LogDisplayComponent
                    node={node}
                    logLines={node.state.state.logLines}
                    logLineRenderer={line => (
                        <DescriptiveTypography text={line} style={{
                            fontSize: node.state.state.fontSize ?? 10
                        }}/>
                    )}
                />

                {/* toolbar */}
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    padding: "8px 0"
                }}>
                    <Tag
                        tag={<ClearAllRounded sx={{ fontSize: 14 }}/>}
                        onClick={() => node.state.update({ logLines: [] })}
                    />

                    <ButtonGroup>
                        <Tag tag={<TextDecreaseRounded sx={{ fontSize: 14 }}/>} onClick={e => {
                            e.stopPropagation();
                            node.state.update(prevState => ({
                                fontSize: prevState.fontSize - 1
                            }));
                        }}/>
                        <Tag tag={<TextIncreaseRounded sx={{ fontSize: 14 }}/>} onClick={e => {
                            e.stopPropagation();
                            node.state.update(prevState => ({
                                fontSize: prevState.fontSize + 1
                            }));
                        }}/>
                    </ButtonGroup>
                </div>
            </div>
        )
    })

}
