import {PinLayout} from "./PinLayout";
import {v4} from "uuid";
import {StandaloneObservable} from "../../ardai/webapi/pubsub/StandaloneObservable";
import {NodeEvent} from "./NodeEvent";
import {NodeEventTypes} from "./NodeEventTypes";
import {State} from "../../State";
import {ReactNode} from "react";
import {NodeVisualConfig} from "./NodeVisualConfig";
import {Inflatable} from "./serialization/v2/Inflatable";
import {markInflatable} from "./serialization/v2/DecoratorMarkInflatable";
import {inflate} from "./serialization/v2/DecoratorInflate";
import "reflect-metadata";
import {SerializableObject} from "../../../std/SerializableObject";
import {SaveStateNode} from "./serialization/v2/SaveStateNode";
import {FQNodeId, mkFQNodeId} from "./FQNodeId";
import {InflationContext} from "./serialization/v2/InflationContext";
import {transient} from "./serialization/v2/DecoratorTransient";
import {NodeEnvironment} from "../host/NodeEnvironment";
import {AttachmentManager} from "./attachments/AttachmentManager";

export type NodeFunc<StateType> = (this: Node<StateType>, node: Node<StateType>) => void;

export type NodeConfig<StateType> = {
    visualConfig?: NodeVisualConfig
    label: string, // TODO: Move to "visualConfig"
    description?: string, // TODO: Move to "visualConfig"
    customRenderer?: ((node: Node<StateType>) => ReactNode), // TODO: Move to "visualConfig"
    classname?: string,
    id?: string,
    state?: StateType,
    defInPins?: Array<string>,
    defOutPins?: Array<string>,
    init?: NodeFunc<StateType>,
    reset?: NodeFunc<StateType>,
}

@markInflatable()
export class Node<StateType = any> implements Inflatable {

    @transient
    private _env?: NodeEnvironment;

    public id: string;

    public readonly observer: StandaloneObservable<NodeEventTypes | string, NodeEvent> = new StandaloneObservable<NodeEventTypes | string, NodeEvent>();

    public readonly pins: PinLayout = new PinLayout(this);

    public readonly config: NodeConfig<StateType>;

    public _state?: State<StateType>;

    @inflate
    public classname: string;

    @inflate
    public isHidden: boolean = false;

    @inflate
    public isAnonymousClass: boolean = false;

    @inflate
    public _testSubNode?: string;

    public readonly attachments = new AttachmentManager(this);

    constructor(config: Partial<NodeConfig<StateType>> = {}) {
        this.config = {
            label: "unnamed",
            defInPins: [],
            defOutPins: [],
            ...config
        };

        this.id = this.config.id ?? v4();
        this.classname = this.config.classname ?? v4();
        this.isAnonymousClass = this.config.classname === undefined;

        if (this.config.state !== undefined) {
            this._state = new State<StateType>(this.config.state);
            this._state.observer.relayGlobally(this.observer);
        }

        this.config.defInPins?.forEach(inPinKey => {
            this.pins.in(inPinKey);
        })
        this.config.defOutPins?.forEach(defOutPins => {
            this.pins.out(defOutPins);
        })

        // this.init();
    }

    public init() {
        if (this.config.init !== undefined) {
            this.config.init.call(this, this);
        }
    }

    public reset() {
        if (this.config.reset !== undefined) {
            this.config.reset.call(this, this);
        }
    }

    public get state(): State<StateType> {
        return this._state!;
    }

    /**
     * Remove all inbound & outbound connections to all if this node's pins
     */
    public cutLoose(): this {
        this.pins.getAllPins().forEach(pin => {
            pin.cutLoose();
        });
        return this;
    }

    public createInternalWire<PinSignalType = any>(cfg: {
        targetInPin: string,
        targetOutPin: string,
        filter?: (this: Node<StateType>, signal: PinSignalType, node: Node<StateType>) => PinSignalType,
        transformer?: (signal: PinSignalType) => PinSignalType
    }): this {
        const thisCtx = this;
        this.pins.in(cfg.targetInPin).attach({
            read(data: any) {
                if (!(cfg.filter?.call(thisCtx, data, thisCtx) ?? true)) return;
                const transformedSignal = cfg.transformer === undefined ? data : cfg.transformer(data);
                this.node.pins.out(cfg.targetOutPin).write(transformedSignal);
            }
        });
        this.pins.out(cfg.targetOutPin);
        return this;
    }

    public writeOnAllPins(data: any): this {
        this.pins.allDefaultOut.forEach(pin => {
            pin.write(data);
        });
        return this;
    }

    public onDelete() {
        if (this._testSubNode !== undefined) {
            this.env.deleteNode(this._testSubNode);
        }
    }

    /**
     * TODO: Remove '?' -> way to set state if no initial state is provided in the node setup
     *
     * @param save
     * @param node
     * @param ctx
     */
    amendInflate(save: SerializableObject, node: SaveStateNode, ctx: InflationContext) {
        this.state?.setState(save["state"] as StateType);
    }

    amendDeflate(save: SerializableObject, node: SaveStateNode) {
        if (this.state?.state !== undefined) {
            save["state"] = this.state.state as SerializableObject;
        }
    }

    public get fqId(): FQNodeId {
        return mkFQNodeId(this.id);
    }

    get env(): NodeEnvironment {
        return this._env!; // TODO: Better error handling
    }

    set env(value: NodeEnvironment) {
        this._env = value;
    }
}
