import {StandardizedAPIResponse} from "./StandardizedAPIResponse";

export type ResponseHandler = (response: StandardizedAPIResponse) => void;

export class ConditionalResponseHandler {

    private readonly handlers: Map<number, ResponseHandler> = new Map<number, ResponseHandler>();

    public when(code: number, handler: ResponseHandler): ConditionalResponseHandler {
        this.handlers.set(code, handler);
        return this;
    }

    public handle(response: StandardizedAPIResponse, fallbackHandler?: ResponseHandler) {
        const handler = this.handlers.get(response.code);
        if (handler === undefined) {
            fallbackHandler?.(response);
            return;
        }
        handler(response);
    }

    public ok(handler: ResponseHandler): ConditionalResponseHandler {
        return this.when(0, handler);
    }

    public genericError(handler: ResponseHandler): ConditionalResponseHandler {
        return this.when(-1, handler);
    }
}

export {
    ConditionalResponseHandler as CRH
}
