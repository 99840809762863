import React, {useContext, useState} from "react";
import {ISADBImageGrid} from "../../ardai/components/ISADBImageGrid";
import {webDB} from "../../ardai/webapi/WebAPIDB";
import {useLiveQuery} from "dexie-react-hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import {Workspace} from "../../ardai/components/Workspace";
import {Screen} from "../../base/components/base/Page";
import {ImageView} from "../../ardai/components/ImageView";
import {ImagePreview} from "../../ardai/components/ImagePreview";
import {ISAImage} from "../../ardai/components/ISAImage";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {ArdaiAPIStateContext} from "../../ardai/ArdaiMain";
import {ArdaiAPIContext} from "../../ardai/webapi/WebAPI";
import {HistoryTabSelectionView} from "./HistoryTabSelectionView";
import {ControlledImagePreview} from "../../ardai/components/ControllerImagePreview";
import {SDInterfaceStateContext} from "./SDInterfaceMain";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {MenuGroup} from "../../ardai/components/MenuGroup";
import {DefaultButton} from "../../ardai/components/DefaultButton";
import {ConfirmationDialog} from "../../base/components/base/ConfirmationDialog";

export type HistoryTabState = {
    openedImageID?: string,
    selectedImages: Array<string>,
    detailedImageView: boolean,
}

export const HistoryTab: React.FC = props => {
    const len = useLiveQuery(() => webDB.sdInterfaceResults.count());
    const [state, setState] = useState<HistoryTabState>({
        selectedImages: [],
        detailedImageView: false
    });
    const mainState = useContext(ArdaiAPIStateContext);
    const interfaceState = useContext(SDInterfaceStateContext);
    const api = useContext(ArdaiAPIContext);

    const images = useLiveQuery(async () => webDB.sdInterfaceResults
        .where("id")
        .anyOfIgnoreCase(state.selectedImages)
        // TODO: remove limit
        .limit(16)
        .toArray(),
        [state.selectedImages]
    );

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "min-content auto",
            gap: "8px",
            width: "100%",
            height: "100%",
            overflow: "hidden"
        }}>
            <Workspace config={{
                name: "history-image-selector",
                mode: "desktop",
                resizable: true
            }} children={
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    overflow: "scroll"
                }}>
                    <MenuGroup title={"Latest batch"} boldTitle={true}>
                        <ISADBImageGrid isaTable={"sdInterfaceResults"} imageIDs={interfaceState.currentGeneratedBatchIds ?? []} imageRenderer={data => (
                            <ControlledImagePreview
                                opened={state.openedImageID === data.id}
                                selected={state.selectedImages.includes(data.id)}
                                onRequestDelete={() => webDB.sdInterfaceResults.delete(data.id)}
                                key={data.id}
                                for={data}
                                onClick={(event) => {
                                    if (event.ctrlKey) {
                                        setState(prevState => ({
                                            ...prevState,
                                            selectedImages: Array.from(new Set([...prevState.selectedImages, data.id]))
                                        }));
                                        return;
                                    }
                                    setState(prevState => ({ ...prevState, openedImageID: data.id }));
                                }}
                            />
                        )}/>

                        <DefaultButton
                            size={"small"}
                            children={"Delete latest batch"}
                            tooltip={"Removes images from this batch from the history."}
                            deactivated={!interfaceState.currentGeneratedBatchIds || interfaceState.currentGeneratedBatchIds.length === 0 }
                            onClick={e => {
                                if (!window.confirm("Are you sure, that you want to delete the current batch's images?")) return;
                                webDB.sdInterfaceResults.bulkDelete(interfaceState.currentGeneratedBatchIds ?? [])
                            }}
                        />
                    </MenuGroup>

                    <MenuGroup title={"All images"} boldTitle={true}>
                        <ISADBImageGrid isaTable={"sdInterfaceResults"} imageRenderer={data => (
                            <ControlledImagePreview
                                opened={state.openedImageID === data.id}
                                selected={state.selectedImages.includes(data.id)}
                                onRequestDelete={() => webDB.sdInterfaceResults.delete(data.id)}
                                key={data.id}
                                for={data}
                                onClick={(event) => {
                                    if (event.ctrlKey) {
                                        setState(prevState => ({
                                            ...prevState,
                                            selectedImages: Array.from(new Set([...prevState.selectedImages, data.id]))
                                        }));
                                        return;
                                    }
                                    setState(prevState => ({ ...prevState, openedImageID: data.id }));
                                }}
                            />
                        )}/>
                    </MenuGroup>
                </div>
            }/>

            <div style={{
                display: "grid",
                gridTemplateRows: "auto min-content",
                width: "100%",
                height: "100%",
                maxHeight: "100%",

                // TODO: Remove fix..
                overflow: "scroll"
            }}>
                <Workspace config={{
                    name: "history-image-selector",
                    mode: "desktop"
                }} children={
                    (!state.openedImageID) ? (
                        <div style={{
                            height: "100%",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex"
                        }}>
                            <DescriptiveTypography text={"No image selected"}/>
                        </div>
                    ) : (
                        <div style={{
                            height: "100%",
                            width: "100%",
                            maxHeight: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            overflow: "hidden"
                        }}>
                            <ISAImage imageRenderer={i => (
                                <img
                                    style={{
                                        borderRadius: "8px",
                                        height: "auto",
                                        width: "auto",
                                        maxHeight: "100%",
                                        maxWidth: "100%",
                                        cursor: "pointer",
                                        objectFit: "scale-down",

                                        overflow: "hidden"
                                    }}
                                    alt={"stable diffusion result"}
                                    src={URL.createObjectURL(i.data)}
                                />
                            )} imageID={state.openedImageID} isaTable={"sdInterfaceResults"}/>
                        </div>
                    )
                }/>

                <HistoryTabSelectionView
                    detailedImageView={state.detailedImageView}
                    toggleDetailedImageView={() => setState(prevState => ({ ...prevState, detailedImageView: !prevState.detailedImageView }))}
                    selectedImages={state.selectedImages}
                    clearSelection={() => setState(prevState => ({ ...prevState, selectedImages: [] }))}
                    removeImageFromSelection={imageID => setState(prevState => ({ ...prevState, selectedImages: prevState.selectedImages.filter(i => i !== imageID) }))}
                />
            </div>

        </div>
    );
}
