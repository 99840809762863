import {Node} from "./Node";

export abstract class NodeDependent {

    public node: Node

    protected constructor(node: Node) {
        this.node = node;
    }
}
