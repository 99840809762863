import React, {useContext} from "react";
import styled from "styled-components";
import {AzypodWidget} from "../widgets/AzypodWidget";
import {SteeringAPWidget} from "../widgets/SteeringAPWidget";
import {ValveControlDisplay} from "../../entities/valve/ValveControlDisplay";
import {SimulationContext} from "../../Simulation2Main";
import {EngineFuelLineWidget} from "../widgets/EngineFuelLineWidget";

const StyledVesselSimulationWidgetBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 6px;
  height: 100%;
  width: 100%;
  
  overflow: scroll;
  // TODO: No dirty tricks ^^
  max-width: 100vw;
  
  .widget-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 100%;
  }
  
  .widget-separator {
    width: 3px;
    height: 75%;
    background-color: rgb(33, 38, 45);
    border-radius: 5px;
  }
`;

export const VesselSimulationWidgetBar: React.FC = props => {

    return (
        <StyledVesselSimulationWidgetBar>
            <div className={"widget-container"}>
                <EngineFuelLineWidget/>
                <div className={"widget-separator"}/>
                <AzypodWidget/>
                <div className={"widget-separator"}/>
                <SteeringAPWidget/>
            </div>
        </StyledVesselSimulationWidgetBar>
    );
}
