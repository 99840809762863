import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import React, {FC} from "react";
import {ButtonGroup} from "../../../../ardai/components/ButtonGroup";
import {Tag} from "../../../../ardai/components/Tag";
import {DownloadRounded, FullscreenRounded, SettingsRounded} from "@mui/icons-material";
import fileDownload from "js-file-download";
import axios from "axios";
import {ButtonModalCompound} from "../../../../ardai/components/ButtonModalCompound";
import {useTriton} from "../../../../triton/TritonHooks";
import InfiniteViewer from "react-infinite-viewer";
import {useAdvancedState, useStaticState} from "../../../../ardai/hooks/StaticStateHook";
import {AnimatePresence, motion} from "framer-motion";

export type NodeImageDisplayState = {
    displaySize: number
}

export const NodeImageDisplay: NodeSetupInfo = {
    label: "NodeImageDisplay",
    classname: "visualization.img",
    parameterConfig: [],
    factory: parameters => new Node<NodeImageDisplayState>({
        id: v4(),
        classname: "visualization.img",
        label: "img",
        defInPins: ["img"],
        state: {
            displaySize: 100
        },
        customRenderer: node => {
            return (
                <NodeImageDisplayComponent node={node}/>
            );
        }
    })
}

type NodeImageDisplayComponentState = {
    enableAdvancedView: boolean
}

const NodeImageDisplayComponent: FC<{
    node: Node
}> = props => {
    const { node } = props;
    const t = useTriton();

    const imgBase64 = node.pins.in("img").lastReadState;
    const imgDataUrl = `data:image/png;base64,${imgBase64}`;

    const [state, ctx] = useAdvancedState<NodeImageDisplayComponentState>({
        initial: {
            enableAdvancedView: false
        }
    }).stateWithCtx;

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            alignItems: "center",
            padding: "8px 0"
        }}>
            {/* image display */}
            <div style={{
                width: node.state.state.displaySize ?? 100,
                aspectRatio: "1 / 1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <img
                    style={{
                        height: "auto",
                        // width: "auto",
                        width: "100%",
                        maxHeight: "100%",
                        maxWidth: "100%",
                        borderRadius: 8
                    }}
                    alt={"stable diffusion result"}
                    src={imgDataUrl}
                />
            </div>

            {/* toolbar & information */}
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: 4
            }}>
               <ButtonModalCompound
                    borderless
                    button={<Tag tag={<FullscreenRounded sx={{ fontSize: 14 }}/>}/>}
                    modalContent={ctx => (
                        <div style={{
                            backgroundColor: t.col("bg_main"),
                            height: "100vh",
                            width: "100vw",
                            display: "grid",
                            gridTemplateRows: "min-content auto",
                            gap: 8
                        }}>
                            <div style={{
                                height: "60px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 8
                            }}>

                            </div>
                            <div style={{
                                height: "100%",
                                display: "grid",
                                overflow: "hidden"
                            }}>
                                <InfiniteViewer
                                    rangeX={[-1e3, 1e3]}
                                    rangeY={[-1e3, 1e3]}
                                    useAutoZoom={true}
                                    zoomRange={[.75, 5]}
                                    useTransform={true}
                                    useWheelPinch={true}
                                    preventWheelClick={true}
                                    useMouseDrag={true}
                                    useWheelScroll={true}
                                >
                                    <div style={{
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden"
                                    }}>
                                        <img
                                            style={{
                                                height: "100%",
                                                width: "auto",
                                                // width: "100%",
                                                maxHeight: "100%",
                                                maxWidth: "100%",
                                                // imageRendering: "pixelated"
                                            }}
                                            alt={"stable diffusion result"}
                                            src={`data:image/png;base64,${node.pins.in("img").lastReadState}`}
                                        />
                                    </div>
                                </InfiniteViewer>
                            </div>
                        </div>
                    )}
                />

                <ButtonGroup>

                    <Tag tag={<DownloadRounded sx={{ fontSize: 14 }}/>} onClick={async () => {
                        const dataToBlob = async (imageData: string) => {
                            return await (await fetch(imageData)).blob();
                        };
                        const blob = await dataToBlob(`data:image/png;base64,${node.pins.in("img").lastReadState}`);
                        fileDownload(blob, "image.png")
                    }}/>
                    <Tag
                        active={state.enableAdvancedView}
                        applyActiveScaling
                        tag={
                            <SettingsRounded sx={{
                                fontSize: 14
                            }}/>
                        }
                        onClick={() => ctx.reverseBool("enableAdvancedView")}
                    />
                </ButtonGroup>
            </div>

            { state.enableAdvancedView && (
                <motion.div
                    style={{
                        width: "100%",
                    }}
                >
                    <div style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: 4
                    }}>
                        <ButtonGroup>
                            <Tag tag={"S"} onClick={() => node.state.update(prevState => ({
                                displaySize: 100
                            }))}/>
                            <Tag tag={"M"} onClick={() => node.state.update(prevState => ({
                                displaySize: 200
                            }))}/>
                            <Tag tag={"L"} onClick={() => node.state.update(prevState => ({
                                displaySize: 300
                            }))}/>
                        </ButtonGroup>
                    </div>
                </motion.div>
            ) }
        </div>
    );
}
