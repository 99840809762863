import React, {FC, useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useStaticState} from "../../hooks/StaticStateHook";
import {MenuGroup} from "../../components/MenuGroup";
import {SingleLineInput} from "../../../triton/components/forms/SingleLineInput";
import {FormElement} from "../../../triton/components/forms/FormElement";
import _ from "lodash";
import {DefaultButton} from "../../components/DefaultButton";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {ControlledBooleanInput} from "../../components/ControlledBooleanInput";
import {BasicSingleSelect} from "../../../triton/components/forms/BasicSingleSelect";
import {useTriton} from "../../../triton/TritonHooks";
import {Tag} from "../../components/Tag";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {ISAImage} from "../../components/ISAImage";
import {ArdaiAPIContext} from "../../webapi/WebAPI";
import {Workspace} from "../../components/Workspace";
import {Zoom} from "@mui/material";
import {ColorField} from "../../testing/ColorField";
import {Color, ofHex} from "../../../base/logic/style/Color";
import {FormikInput} from "../../../triton/components/forms/FormikInput";
import {triton} from "../../../triton/Triton";
import {ButtonGroup} from "../../components/ButtonGroup";
import {SDPromptField} from "../../../imageSorter/sdInterface/SDPromptField";
import {px} from "../../../base/logic/style/DimensionalMeasured";
import fileDownload from "js-file-download";
import {ButtonModalCompound} from "../../components/ButtonModalCompound";
import {Modal} from "../../../triton/components/dialogs/Modal";
import {Menu} from "../../components/Menu";
import {FormikTextArea} from "../../../triton/components/forms/FormikTextArea";
import {TritonTextArea} from "../../../triton/components/forms/TritonTextArea";
import {TabBar} from "../../components/TabBar";
import {TabBodyRenderer} from "../../components/TabBodyRenderer";

const StyledChallengeTemplateCreator = styled.div`
  // display: flex;
  // flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  
  display: grid;
  grid-template-columns: 300px auto 300px;
  gap: 8px;
`;

export type ChallengeInfo = {
    title: string,
    page: number,
    cols: number,
    challengeFieldCount: number,
    challengeFieldGoal?: number,
    pageMode: "fit-content" | "A4",
    challengeFieldAspectRatioHeight: number,
    fieldIndexOffset?: number,
    watermarkSrc?: string,

    overwriteChallengeFieldBaseColor: boolean,
    challengeFieldBaseColor: string
    challengePaperBaseColor: string,
    paperPadding: number,

    challengePrompt?: string,
    socialHandle?: string

    renderScale: number,
}

export type ChallengeTemplateCreatorState = {
    activeChallengeSetupTab: "challenge" | "paper" | "challenge-fields" | string
}

export const ChallengeTemplateCreator: FC = props => {

    const [state, setState] = useState<ChallengeTemplateCreatorState>({
        activeChallengeSetupTab: "challenge"
    });

    const [challengeInfo, challengeInfoCtx] = useStaticState<ChallengeInfo>({
        id: "ChallengeTemplateCreator-ChallengeInfo",
        initial: {
            cols: 3,
            challengeFieldCount: 6,
            page: 1,
            title: "",
            pageMode: "fit-content",
            challengeFieldAspectRatioHeight: 1.08,
            overwriteChallengeFieldBaseColor: false,
            challengeFieldBaseColor: "#D9D9D9",
            challengePaperBaseColor: "#FFFFFF",
            paperPadding: 42,
            renderScale: 1,
            fieldIndexOffset: 0
        }
    }).stateWithCtx;

    const [templateImage, takeScreenshot] = require("use-react-screenshot").useScreenshot({
        quality: 2
    });

    const templateRenderContainerRef = useRef(null);
    const api = useContext(ArdaiAPIContext);
    const debouncedRenderFuncRef = useRef(_.debounce(() => takeScreenshot(templateRenderContainerRef.current), .5e3));

    useEffect(() => {
        debouncedRenderFuncRef.current();
    }, [challengeInfo]);

    function convertBase64ToBlob(base64Image: string) {
        // Split into two parts
        const parts = base64Image.split(';base64,');
        // Hold the content type
        const imageType = parts[0].split(':')[1];
        // Decode Base64 string
        const decodedData = window.atob(parts[1]);
        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);
        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }
        // Return BLOB image after conversion
        return new Blob([uInt8Array], { type: imageType });
    }

    return (
        <StyledChallengeTemplateCreator>

            {/* Configuration */}
            <Workspace config={{ mode: "desktop", name: "config" }} style={{
                display: "flex",
                gap: 8,
                flexDirection: "column",
                overflowY: "scroll"
            }}>
                <div style={{
                    width: "100%"
                }}>
                    <TabBar
                        activeBar={state.activeChallengeSetupTab}
                        onTabChange={tab => {
                            setState(prevState => ({ ...prevState, activeChallengeSetupTab: tab }))
                        }}
                        equalSizeMode
                        tabs={[
                            { id: "challenge", title: "Basic" },
                            { id: "paper", title: "Paper" },
                            { id: "challenge-fields", title: "Fields" },
                        ]}
                    />
                </div>

                <TabBodyRenderer active={state.activeChallengeSetupTab} tabs={new Map<string, () => React.ReactElement>([
                    ["challenge", () => (
                        <MenuGroup title={"Basic"}>
                            <SingleLineInput
                                autoFocus
                                placeholder={"Challenge title"}
                                value={challengeInfo.title}
                                onChange={(_, s) => challengeInfoCtx.update({
                                    title: s
                                })}
                            />
                            <SingleLineInput
                                placeholder={"Social handle"}
                                value={challengeInfo.socialHandle}
                                onChange={(_, s) => challengeInfoCtx.update({
                                    socialHandle: s
                                })}
                            />
                            <FormElement title={"Challenge prompt"}>
                                <TritonTextArea
                                    value={challengeInfo.challengePrompt}
                                    onChange={(_, s) => challengeInfoCtx.update({
                                        challengePrompt: s
                                    })}
                                />
                            </FormElement>
                            <FormElement title={"Number of columns"}>
                                <SingleLineInput
                                    baseProps={{
                                        type: "number",
                                        min: 1,
                                        max: 6
                                    }}
                                    value={challengeInfo.cols}
                                    onChange={(e, s) => challengeInfoCtx.update({
                                        cols: _.clamp(Number(s), 1, 6)
                                    })}
                                />
                            </FormElement>
                            <FormElement title={"Number of challenge fields"}>
                                <SingleLineInput
                                    baseProps={{
                                        type: "number",
                                        min: 1,
                                        max: 1e2
                                    }}
                                    value={challengeInfo.challengeFieldCount}
                                    onChange={(e, s) => challengeInfoCtx.update({
                                        challengeFieldCount: _.clamp(Number(s), 1, 1e2)
                                    })}
                                />
                            </FormElement>
                            <FormElement title={"Field index offset"}>
                                <SingleLineInput
                                    baseProps={{
                                        type: "number",
                                    }}
                                    value={challengeInfo.fieldIndexOffset}
                                    onChange={(e, s) => challengeInfoCtx.update({
                                        fieldIndexOffset: Number(s)
                                    })}
                                />
                            </FormElement>
                            <FormElement title={"Page number"}>
                                <SingleLineInput
                                    baseProps={{
                                        type: "number",
                                        min: 1
                                    }}
                                    value={challengeInfo.page}
                                    onChange={(e, s) => challengeInfoCtx.update({
                                        page: Math.max(Number(s), 1)
                                    })}
                                />
                            </FormElement>
                        </MenuGroup>
                    )],
                    ["paper", () => (
                        <MenuGroup title={"Paper settings"}>
                            <BasicSingleSelect name={""} selected={challengeInfo.pageMode} title={"Template format"} options={[
                                { id: "fit-content", text: "Fit content" },
                                { id: "A4", text: "ISO A4" }
                            ]} onSelect={format => challengeInfoCtx.update({
                                pageMode: format as any
                            })}/>

                            <FormElement title={"Paper padding"}>
                                <SingleLineInput
                                    baseProps={{
                                        type: "number",
                                        min: 0
                                    }}
                                    value={challengeInfo.paperPadding}
                                    onChange={(e, s) => challengeInfoCtx.update({
                                        paperPadding: Math.max(Number(s), 0)
                                    })}
                                />

                                <div style={{
                                    display: "grid",
                                    gap: 8,
                                    gridTemplateColumns: "repeat(auto-fit, minmax(1px, 1fr))"
                                }}>
                                    { [0, 8, 16, 42].map(padding => (
                                        <Tag tag={`${padding}px`} active={padding === challengeInfo.paperPadding} highlightOnHover onClick={() => {
                                            challengeInfoCtx.update({
                                                paperPadding: padding
                                            })
                                        }}/>
                                    )) }
                                </div>
                            </FormElement>

                            <FormElement title={"Background color"}>
                                <ButtonGroup orientation={"horizontal"}>
                                    <input type={"color"} value={challengeInfo.challengePaperBaseColor} style={{ width: "100%" }} onChange={e => {
                                        challengeInfoCtx.update({
                                            challengePaperBaseColor: e.currentTarget.value
                                        })
                                    }}/>
                                    <Tag tag={"Reset background color"} onClick={() => {
                                        challengeInfoCtx.update({
                                            challengePaperBaseColor: "#FFFFFF"
                                        })
                                    }}/>
                                </ButtonGroup>
                                <ControlledBooleanInput
                                    value={challengeInfo.overwriteChallengeFieldBaseColor}
                                    title={"Use custom background color"}
                                    update={bool => {
                                        challengeInfoCtx.update({
                                            overwriteChallengeFieldBaseColor: bool
                                        })
                                    }}
                                />
                            </FormElement>
                            <MenuGroup title={"Watermark"} indentation={8} showIndentationLine>
                                <SingleLineInput
                                    placeholder={"Watermark source (UUID)"}
                                    value={challengeInfo.watermarkSrc}
                                    onChange={(_, s) => challengeInfoCtx.update({
                                        watermarkSrc: s
                                    })}
                                />
                                <Tag tag={"Use current image"} highlightOnHover onClick={() => {
                                    challengeInfoCtx.update({
                                        watermarkSrc: api.state.selectedImageId
                                    });
                                }}/>
                            </MenuGroup>
                        </MenuGroup>
                    )],
                    ["challenge-fields", () => (
                        <MenuGroup title={"Challenge fields"}>
                            <FormElement title={"Aspect ratio (height-component)"}>
                                <SingleLineInput
                                    baseProps={{
                                        type: "number",
                                        min: .2,
                                        max: 2,
                                        step: .01
                                    }}
                                    value={challengeInfo.challengeFieldAspectRatioHeight}
                                    onChange={(e, s) => challengeInfoCtx.update({
                                        challengeFieldAspectRatioHeight: _.clamp(Number(s), .2, 2)
                                    })}
                                />
                                <div style={{
                                    display: "grid",
                                    gap: 8,
                                    gridTemplateColumns: "repeat(3, 1fr)"
                                }}>
                                    <Tag tag={"Square"} highlightOnHover onClick={() => {
                                        challengeInfoCtx.update({
                                            challengeFieldAspectRatioHeight: 1
                                        })
                                    }}/>
                                    <Tag tag={"1.08"} highlightOnHover onClick={() => {
                                        challengeInfoCtx.update({
                                            challengeFieldAspectRatioHeight: 1.08
                                        })
                                    }}/>
                                    <Tag tag={"A4 (1.4142)"} highlightOnHover onClick={() => {
                                        challengeInfoCtx.update({
                                            challengeFieldAspectRatioHeight: 1.4142
                                        })
                                    }}/>
                                </div>
                            </FormElement>

                            <FormElement title={"Background color"}>
                                <ButtonGroup orientation={"horizontal"}>
                                    <input type={"color"} value={challengeInfo.challengeFieldBaseColor} style={{ width: "100%" }} onChange={e => {
                                        challengeInfoCtx.update({
                                            challengeFieldBaseColor: e.currentTarget.value
                                        })
                                    }}/>
                                    <Tag tag={"Reset background color"} onClick={() => {
                                        challengeInfoCtx.update({
                                            challengeFieldBaseColor: "#D6D6D6"
                                        })
                                    }}/>
                                </ButtonGroup>
                                <ControlledBooleanInput
                                    value={challengeInfo.overwriteChallengeFieldBaseColor}
                                    title={"Use custom background color"}
                                    update={bool => {
                                        challengeInfoCtx.update({
                                            overwriteChallengeFieldBaseColor: bool
                                        })
                                    }}
                                />
                            </FormElement>
                        </MenuGroup>
                    )],
                ])}/>
            </Workspace>

            {/* Output */}
            <Workspace config={{ mode: "desktop", name: "output" }} style={{
                overflow: "hidden"
            }}>
                <div style={{
                    width: "100%",
                    height: "100%",
                    overflow: "scroll"
                }}>
                    <TransitionGroup>
                        { templateImage && (
                            <Zoom>
                                {(() => {
                                    const imgSrcFile = new File(
                                        [convertBase64ToBlob(templateImage)],
                                        "template.png"
                                    )

                                    return (
                                        <img
                                            width={"100%"}
                                            // src={templateImage}
                                            src={URL.createObjectURL(imgSrcFile)}
                                            alt={"Rendered template image"}
                                        />
                                    );
                                })()}
                            </Zoom>
                        ) }
                    </TransitionGroup>
                </div>
            </Workspace>

            <div style={{
                display: "grid",
                gap: 8,
                gridTemplateRows: "min-content auto"
            }}>
                {/* Actions */}
                <Workspace config={{ mode: "desktop", name: "actions" }} style={{
                    display: "flex",
                    gap: 8,
                    flexDirection: "column",
                    height: "min-content"
                }}>
                    <DefaultButton children={"Render template"} fullwidth onClick={() => {
                        takeScreenshot(templateRenderContainerRef.current);
                    }}/>

                    <DefaultButton
                        children={"Save template"}
                        fullwidth
                        deactivated={templateImage === undefined || api.state.selectedProject === undefined}
                        onClick={async () => {
                            if (templateImage === undefined) return;
                            const project = await api.getCurrentProject();
                            if (project === undefined) return;

                            await api.appendFilesToCurrentProject([new File(
                                [convertBase64ToBlob(templateImage)],
                                "template.png"
                            )]);
                        }}
                    />

                    <DefaultButton children={"Download template"} fullwidth deactivated={templateImage === undefined} onClick={async () => {
                        if (templateImage === undefined) return;
                        const info = challengeInfo;
                        fileDownload(convertBase64ToBlob(templateImage), `${info.title}-${info.page}.png`);
                    }}/>

                    <DefaultButton children={"Export template config"} fullwidth onClick={async () => {
                        const info = challengeInfo;
                        fileDownload(JSON.stringify(info, null, 2), `template-${info.title}.json`);
                    }}/>

                    <ButtonModalCompound
                        button={
                            <DefaultButton children={"Import template config (modal)"} fullwidth/>
                        }
                        modalContent={ctx => (
                            <Modal open={true} onClose={ctx.close}>
                                <input type={"file"} accept={"application/json"} onChange={async (e) => {
                                    const fileBody = await e.currentTarget.files?.item(0)?.text();
                                    if (fileBody === undefined) return;

                                    const inflatedChallengeInfo = JSON.parse(fileBody);
                                    challengeInfoCtx.update(inflatedChallengeInfo);
                                    ctx.close()
                                }}/>
                            </Modal>
                        )}
                    />

                    <Menu opener={
                        <DefaultButton children={"Import template config (menu)"} fullwidth/>
                    }>
                        <input type={"file"} accept={"application/json"} onChange={async (e) => {
                            const fileBody = await e.currentTarget.files?.item(0)?.text();
                            if (fileBody === undefined) return;

                            const inflatedChallengeInfo = JSON.parse(fileBody);
                            challengeInfoCtx.update(inflatedChallengeInfo);
                        }}/>
                    </Menu>
                </Workspace>

                <Workspace config={{ mode: "desktop", name: "render-config" }}>
                    <MenuGroup title={"Render settings"}>
                        <FormElement title={"Render scale"}>
                            <SingleLineInput
                                baseProps={{
                                    type: "number",
                                    min: 1,
                                    max: 8
                                }}
                                value={challengeInfo.renderScale}
                                onChange={(e, s) => challengeInfoCtx.update({
                                    renderScale: _.clamp(Number(s), 1, 8)
                                })}
                            />
                            <div style={{
                                display: "grid",
                                gap: 8,
                                gridTemplateColumns: "repeat(auto-fit, minmax(1px, 1fr))"
                            }}>
                                { [1, 1.5, 2, 4, 8].map(padding => (
                                    <Tag tag={`${padding}x`} active={padding === challengeInfo.renderScale} highlightOnHover onClick={() => {
                                        challengeInfoCtx.update({
                                            renderScale: padding
                                        });
                                    }}/>
                                )) }
                            </div>
                        </FormElement>
                    </MenuGroup>
                </Workspace>
            </div>

            {/* Internal rendering component */}
            <div style={{ opacity: 0, position: "absolute", zIndex: -1 }}>
                <div ref={templateRenderContainerRef} style={{ transform: `scale(${
                    challengeInfo.renderScale ? Math.min(challengeInfo.renderScale, 8) : 1
                })` }}>
                    <ChallengeTemplateRenderer info={challengeInfo}/>
                </div>
            </div>
        </StyledChallengeTemplateCreator>
    );
}

const StyledChallengeTemplateRenderer = styled.div<{
    info: ChallengeInfo
}>`
  display: flex;
  flex-direction: column;
    // gap: ${p => p.info.paperPadding ?? 42}px;
  gap: 42px;
  padding: ${p => p.info.paperPadding ?? 42}px;
  // padding: 42px;
  width: 640px;
  aspect-ratio: 1 / ${p => p.info.pageMode === "A4" ? 1.4142 : "auto"};
  overflow: hidden;
  background-color: ${p => p.info.challengePaperBaseColor};

  * {
    color: black !important;
    font-family: "JetBrains Mono", monospace !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .left, .right {

    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }

  .challenge-fields {
    display: grid;
    grid-template-columns: repeat(${p => p.info.cols}, 1fr);
    gap: 8px;
    overflow-y: scroll;
  }

  .footer {
    margin-top: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    align-items: center;

    .watermark {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      // background-color: sandybrown;
      // background-color: rgba(244, 164, 96, 0.42);
    }

    .social-handle-container {
      align-items: end;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const ChallengeTemplateRenderer: FC<{
    info: ChallengeInfo
}> = props => {
    const info = props.info;
    const date = new Date();
    const timestamp = date.toLocaleString('en-us',{ month: 'short', year: 'numeric'})

    return (
        <StyledChallengeTemplateRenderer info={info}>
            <div className={"header"}>
                <div className={"left"}>
                    <DescriptiveTypography text={"Challenge"}/>
                    <MainTypography text={info.title} style={{ fontSize: 20 }}/>
                </div>
                <div className={"right"}>
                    <DescriptiveTypography text={`${timestamp}`}/>
                    <MainTypography text={`page ${info.page}`} style={{ fontSize: 20 }}/>
                </div>
            </div>

            <div className={"challenge-fields"} children={_.range(1, info.challengeFieldCount + 1).map(idx => (
                <ChallengeField idx={idx + ((info.page - 1) * info.challengeFieldCount) + (info.fieldIndexOffset ?? 0)} key={idx} info={info}/>
            ))}/>

            { info.challengePrompt && (
                <div>
                    <DescriptiveTypography text={
                        info.challengePrompt
                    }/>
                </div>
            ) }

            <div className={"footer"}>
                <span/>
                <div className={"watermark"}>
                    { info.watermarkSrc && (
                        <ISAImage
                            imageID={info.watermarkSrc!}
                            isaTable={"images"}
                            imageRenderer={i => (
                                <img src={URL.createObjectURL(i.data)} style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    objectFit: "contain"
                                }} alt={"Watermark"}/>
                            )}
                        />
                    ) }
                </div>

                <div className={"social-handle-container"}>
                    <DescriptiveTypography text={info.socialHandle}/>
                </div>
            </div>
        </StyledChallengeTemplateRenderer>
    );
}

export const ChallengeField: FC<{
    idx: number,
    info: ChallengeInfo
}> = props => {
    const info = props.info;
    const t = useTriton();

    return (
        <div>
            <div style={{
                width: "100%",
                aspectRatio: `1 / ${info.challengeFieldAspectRatioHeight ?? 1.08}`,
                // backgroundColor: t.col("color_secondary")
                backgroundColor: info.overwriteChallengeFieldBaseColor ? info.challengeFieldBaseColor : t.col("color_primary")
            }}/>
            <DescriptiveTypography text={`#${props.idx}`}/>
        </div>
    );
}
