import React, {FC, useState} from "react";
import Selecto from "react-selecto";
import styled from "styled-components";
import {NodeCanvasNodeRenderer} from "./NodeCanvasNodeRenderer";
import {NodeCanvasLineRenderer} from "./NodeCanvasLineRenderer";
import {NodeFrontendBrandingWatermarkLayer} from "./components/NodeFrontendBrandingWatermarkLayer";
import {NodeCanvasSidebar} from "./components/NodeCanvasSidebar";
import {useStaticState} from "../../ardai/hooks/StaticStateHook";
import {useNodeCanvasBackend} from "./NodeCanvasBackend";
import {NodeFrontendSelectionAnnotationLayer} from "./components/NodeFrontendSelectionAnnotationLayer";
import {NodeCanvasBackgroundLayer} from "./NodeCanvasBackgroundLayer";
import InfiniteViewer from "react-infinite-viewer";
import Editor from "@monaco-editor/react";
import {Triton} from "../../triton/Triton";
import {useTriton} from "../../triton/TritonHooks";

const StyledNodeCanvasMain = styled.div<{
    t: Triton
}>`
  height: 100%;
  position: relative;
  
  .view-main-section {
    height: 100%;
  }
  
  .node-canvas-wrapper {
    // overflow: scroll;
    // width: 100%;
    // height: 100%;


    .node-canvas {}
  }
  
  .node-canvas-container {
    position: relative;
    z-index: 1;
  }
  
  .selecto-selection {
    border-color: ${p => p.t.col("color_secondary")};
    background-color: ${p => p.t.colObj("color_secondary").withAlpha(.1).css()};
    border-radius: 8px;
  }
`;

export type NodeCanvasMainProps = {}

export const NodeCanvasMain: FC<NodeCanvasMainProps> = props => {
    const backend = useNodeCanvasBackend();
    const t = useTriton();

    const [state, ctx] = useStaticState({
        staticMode: false,
        id: "NodeCanvasMain-local",
        initial: {
            // TODO: remove
            showWatermark: false
        }
    }).stateWithCtx;

    const [debugState, setDebugState] = useState({
        handToolMode: false
    })

    return (
        <StyledNodeCanvasMain
            t={t}
            style={{
                backgroundColor: debugState.handToolMode ? "blue" : undefined,
                cursor: debugState.handToolMode ? "grabbing" : undefined
            }}
            // onMouseDown={e => {
            //     if (e.button === 1) {
            //         e.preventDefault();
            //         setDebugState(prevState => ({
            //             ...prevState,
            //             handToolMode: true
            //         }))
            //     } else {
            //         // setDebugState(prevState => ({
            //         //     ...prevState,
            //         //     handToolMode: false
            //         // }))
            //     }
            // }}
            // onMouseUp={e => {
            //     setDebugState(prevState => ({
            //         ...prevState,
            //         handToolMode: false
            //     }))
            // }}
        >

            {/*
             <InfiniteViewer
                margin={0}
                threshold={0}
                rangeX={[-10e3, 10e3]}
                rangeY={[-10e3, 10e3]}
                useAutoZoom={true}
                zoomRange={[.5, 2]}
                useTransform={true}
                useWheelPinch={true}
                // useMouseDrag={true}
                // useWheelScroll={true}
            >
                <div className={"viewer"}>
                    <div className={"viewport"}>
                        <NodeCanvasMain/>
                    </div>
                </div>
            </InfiniteViewer>
            */}

            <main className={"view-main-section"}>
                <InfiniteViewer
                    // margin={0}
                    // threshold={0}
                    rangeX={[-5e3, 5e3]}
                    rangeY={[-5e3, 5e3]}
                    // useAutoZoom={false}
                    useAutoZoom={true}
                    zoomRange={[.5, 3]}
                    // zoomRange={[1, 1]}

                    useTransform={true}
                    // useTransform={false}

                    useWheelPinch={true}
                    // useWheelPinch={false}

                    preventWheelClick={true}
                    // preventWheelClick={false}

                    useMouseDrag={debugState.handToolMode}

                    // useMouseDrag={true}
                    useWheelScroll={true}
                >
                    <div className={"viewer"}>
                        <main className={"node-canvas-container viewport"} id={"_node-canvas"}>
                            <NodeFrontendSelectionAnnotationLayer/>
                            <NodeCanvasNodeRenderer/>
                            <div className={"layer line-layer"} children={<NodeCanvasLineRenderer/>}/>
                            { state.showWatermark && (
                                <NodeFrontendBrandingWatermarkLayer/>
                            ) }
                        </main>
                    </div>
                </InfiniteViewer>

                {/*
                <main className={"node-canvas-container viewport"} id={"_node-canvas"}>
                    <NodeFrontendSelectionAnnotationLayer/>
                    <NodeCanvasNodeRenderer/>
                    <div className={"layer line-layer"} children={<NodeCanvasLineRenderer/>}/>
                    { state.showWatermark && (
                        <NodeFrontendBrandingWatermarkLayer/>
                    ) }
                </main>
                */}

                <div style={{
                    zIndex: 2,
                    marginBottom: 8,
                }}>
                    <NodeCanvasSidebar/>
                </div>
            </main>

            {/* utils */}
            <Selecto
                container={document.body}
                dragContainer={window}
                selectByClick={false}
                selectFromInside={false}
                hitRate={0}
                toggleContinueSelect={"shift"}
                selectableTargets={[".trait-selectable", ".trait-prevent-selection-collapse"]}
                onSelect={e => {
                    // TODO: Tidy up ".trait-prevent-selection-collapse"-filtering

                    e.added.filter(el => !el.classList.contains("trait-prevent-selection-collapse")).forEach(el => {
                        el.classList.add("group-selected");
                    });
                    e.removed.filter(el => !el.classList.contains("trait-prevent-selection-collapse")).forEach(el => {
                        el.classList.remove("group-selected");
                    });
                    backend.setSecondarySelection((
                        e.selected.filter(el => !el.classList.contains("trait-prevent-selection-collapse"))
                    ) as Array<HTMLElement>);
                }}
            />
        </StyledNodeCanvasMain>
    );
}
