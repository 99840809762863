import React, {useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {ConsoleLogRenderer} from "./ConsoleLogRenderer";
import {LogLevel} from "./LogLevel";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {
    AutoAwesomeRounded,
    DeleteRounded,
    DownloadRounded,
    FilterAltRounded,
    HistoryRounded,
    LinkRounded,
    SettingsRounded
} from "@mui/icons-material";
import {FormikSingleLineInput} from "../../../triton/components/forms/FormikSingleLineInput";
import {Formik} from "formik";
import {Menu} from "../../components/Menu";
import {IconButton} from "../../components/IconButton";
import {ButtonGroup} from "../../components/ButtonGroup";
import {CheckMenuButton, MenuButton} from "../../components/MenuButton";
import {MenuDivider} from "@szhsin/react-menu";
import {LogEvent} from "./LogEvent";
import {v4} from "uuid";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {Tag} from "../../components/Tag";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {LogAmountDisplay} from "./LogAmountDisplay";
import {DerbyshireStateContext} from "./DerbyshireMain";

export const StyledConsoleFeedView = styled.div`
  display: grid;
  grid-template-rows: min-content auto;
  width: 100%;
  height: 100%;
  gap: 8px;
  color: white;
  
  .console-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    
    .header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      
    }
    
    .header-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }
  
  .console-body {
    height: 100%;
    overflow-y: scroll;
    
    .log-tray {
      
    }
  }
`;

export const ConsoleFeedView: React.FC = props => {
    const [engaged, setEngaged] = useState(true);
    const [log, setLog] = useState<Array<LogEvent>>([]);
    const [state, setState] = useContext(DerbyshireStateContext);

    const appendLog = (message: string, level: LogLevel) => {
        setLog(prevState => [...prevState, {
            id: v4(),
            message: message,
            category: "Interception",
            level: level,
            timestamp: new Date().toLocaleTimeString(),
            timestampUnix: new Date().getTime()
        }]);
    };

    const relays = useRef({
        info: console.log,
        error: console.error,
        debug: console.debug,
        trace: console.trace,
    });

    useEffect(() => {
        console.log = (message, optionalParams) => {
            relays.current.info(message, optionalParams);
            appendLog(message, LogLevel.INFO);
        }
        console.error = (message, optionalParams) => {
            relays.current.error(message, optionalParams);
            appendLog(message, LogLevel.ERROR);
        }
        console.debug = (message, optionalParams) => {
            relays.current.debug(message, optionalParams);
            appendLog(message, LogLevel.DEBUG);
        }
        console.trace = (message, optionalParams) => {
            relays.current.trace(message, optionalParams);
            appendLog(message, LogLevel.TRACE);
        }

        return () => {
            // e slint-disable-next-line react-hooks/exhaustive-deps
            Object.entries(relays.current).forEach(value => {
                console[value[0] as "info"] = value[1];
            });
        }
    }, []);

    return (
        <StyledConsoleFeedView>
            <div className={"console-header"}>
                <div className={"header-left"}>
                    <HistoryRounded style={{ width: "20px" }}/>
                    <MainTypography text={"Derbyshire Log"} style={{
                        whiteSpace: "nowrap"
                    }}/>
                </div>
                <div className={"header-right"}>
                    {
                        // Object.entries(state.logCounts).map((e: [string, number]) => (
                        //     <>
                        //         <LogAmountDisplay
                        //             level={LogLevel.ERROR}
                        //             amount={e[1]}
                        //             onSelect={() => {}}
                        //             levelStr={"trace"}
                        //         />
                        //     </>
                        // ))
                    }
                    <Formik initialValues={{ filter: "" }} onSubmit={values => {}} children={fp => (
                        <FormikSingleLineInput placeholder={"Filter"} name={"filter"} formikProps={fp}/>
                    )}/>
                    <ButtonGroup>
                        <IconButton children={<AutoAwesomeRounded/>} size={"small"} onClick={e => console.debug("Hello world")}/>
                        <IconButton tooltip={"Clear all"} children={<DeleteRounded/>} size={"small"} onClick={() => setLog([])}/>
                        <IconButton children={<LinkRounded/>} size={"small"} variant={engaged ? "primary" : "default"} onClick={() => setEngaged(prevState => !prevState)}/>
                    </ButtonGroup>

                    <Menu>
                        <MenuButton text={"Download log"} icon={<DownloadRounded/>}/>
                        <MenuButton text={"Clear log"} icon={<DeleteRounded/>}/>

                        <MenuDivider/>
                        <CheckMenuButton text={"Engage log"} checked={engaged} onSelect={() => {
                            setEngaged(prevState => !prevState);
                        }}/>
                    </Menu>
                </div>
            </div>

            <div className={"console-body"} children={
                engaged ? (
                    log.length === 0 ? (
                        <div className={"log-tray"} style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "100%",
                            height: "100%",
                            gap: "4px"
                        }}>
                            <DescriptiveTypography style={{ fontWeight: "bold" }} text={"Empty"}/>
                            <DescriptiveTypography style={{ fontStyle: "italic" }} text={"Derbyshire console log"}/>
                            <TransitionGroup>
                                { log.length > 0 && (
                                    <Collapse>
                                        <Tag highlightOnHover={false} tag={`${log.length} log entries`} onClick={() => {
                                            setEngaged(true);
                                        }}/>
                                    </Collapse>
                                ) }
                            </TransitionGroup>
                        </div>
                    ) : (
                        log.map(le => (
                            <ConsoleLogRenderer key={le.id} log={le}/>
                        ))
                    )
                ) : (
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "100%",
                        height: "100%",
                        gap: "4px"
                    }}>
                        <DescriptiveTypography style={{ fontWeight: "bold" }} text={"Log disengaged"}/>
                        <DescriptiveTypography style={{ fontStyle: "italic" }} text={"Derbyshire console log is disengaged"}/>
                        <TransitionGroup>
                            { log.length > 0 && (
                                <Collapse>
                                    <Tag highlightOnHover={false} tag={`${log.length} log entries`} onClick={() => {
                                        setEngaged(true);
                                    }}/>
                                </Collapse>
                            ) }
                        </TransitionGroup>
                    </div>
                )
            }/>
        </StyledConsoleFeedView>
    );
}
