import {FC, useEffect, useRef} from "react";
import {useNodeCanvasBackend} from "../NodeCanvasBackend";
import styled from "styled-components";
import {Pos} from "../Pos";
import {useForceRenderFunc} from "../../../ForceRerenderHook";
import {NodeCanvasBackendEventTypes} from "../NodeCanvasBackendEventTypes";
import {triton} from "../../../triton/Triton";
import {Tag} from "../../../ardai/components/Tag";
import {ButtonGroup} from "../../../ardai/components/ButtonGroup";
import {motion, useMotionValue} from "framer-motion";
import {
    AccountTreeRounded,
    AdjustRounded, CableRounded,
    CloseRounded,
    CommentBankRounded,
    CommitRounded,
    ContentCopyRounded,
    DeleteRounded, FolderRounded, JoinFullRounded, MergeRounded, MergeTypeRounded,
    PushPinRounded
} from "@mui/icons-material";
import {Dot} from "../../../ardai/components/Dot";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {Node} from "../../backend/Node";
import {AttachmentGroupMember} from "../../backend/attachments/defaults/AttachmentGroupMember";
import {NodeEvent} from "../../backend/NodeEvent";

export const StyledNodeFrontendSelectionAnnotationLayer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  
  .selection-annotation-canvas {
    position: relative;
    height: 100%;
    width: 100%;    
    
    .selection-annotation {
      --grid-spacing: 25px;
      --border-margin: 5px;
      position: relative;
      // background-color: ${() => triton().colObj("color_secondary").withAlpha(.5).css(false)};
      // border-radius: 8px;
      // background: conic-gradient(
      //         from 90deg at 1px 1px, #0000 90deg, ${() => triton().col("bg_modal")} 0
      // ) 0 0/var(--grid-spacing) var(--grid-spacing);
      
      &:before {
        content: '';
        width: calc(100% + 2 * var(--border-margin));
        height: calc(100% + 2 * var(--border-margin));;
        position: absolute;
        margin: calc(-1 * var(--border-margin));
        transform: translateX(-1px) translateY(-1px);
        border: 1px dashed ${() => triton().col("color_secondary")};
        opacity: .5;
        border-radius: 12px;

        background: conic-gradient(
                from 90deg at 1px 1px, #0000 90deg, ${() => triton().col("bg_modal")} 0
        ) 0 0/var(--grid-spacing) var(--grid-spacing);
      }
      
      .annotation-toolbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        width: 100%;
        justify-content: center;
        position: absolute;
        top: calc(100% + 2 * var(--border-margin));
      }
    }
  }
`;

export const NodeFrontendSelectionAnnotationLayer: FC = props => {
    const backend = useNodeCanvasBackend();
    const env = backend.environment;

    const forceRender = useForceRenderFunc();

    useEffect(() => backend.events.observe(
        NodeCanvasBackendEventTypes.NODE_POSITION_UPDATE,
        NodeCanvasBackendEventTypes.NODE_POSITION_UPDATE_DELTA,
        NodeCanvasBackendEventTypes.GRAPH_UPDATE,

        // TODO: Can it be more fine-grained?
        NodeCanvasBackendEventTypes.GENERIC
    ).on((key, data) => {
        forceRender();
    }).destructor, []);

    const htmlElems = backend.state.ui.selection.secondary?.htmlNodes ?? [];

    let minX: number | undefined, minY: number | undefined;
    let maxX: number | undefined, maxY: number | undefined;

    htmlElems.forEach(e => {
        const x = e.getBoundingClientRect().x;
        const y = e.getBoundingClientRect().y;
        const xEnd = e.getBoundingClientRect().right;
        const yEnd = e.getBoundingClientRect().bottom;

        if (minX === undefined) minX = x;
        else minX = Math.min(minX, x);

        if (minY === undefined) minY = y;
        else minY = Math.min(minY, y);

        if (maxX === undefined) maxX = xEnd;
        else maxX = Math.max(maxX, xEnd);

        if (maxY === undefined) maxY = yEnd;
        else maxY = Math.max(maxY, yEnd);
    })

    const selectionAnnotationCanvasRef = useRef<HTMLDivElement>(null);

    const selectionAnnotationCanvas = selectionAnnotationCanvasRef.current;
    const canvasBoundingRect = selectionAnnotationCanvas?.getBoundingClientRect();
    const topLeft: Pos = {
        x: (minX ?? 0) - (canvasBoundingRect?.x ?? 0),
        y: (minY ?? 0) - (canvasBoundingRect?.y ?? 0),
    }
    const bottomRight: Pos = {
        x: (maxX ?? 0) - (canvasBoundingRect?.x ?? 0),
        y: (maxY ?? 0) - (canvasBoundingRect?.y ?? 0),
    }

    const width = bottomRight.x - topLeft.x;
    const height = bottomRight.y - topLeft.y;

    const shouldShowSelectionAnnotation = minX !== undefined;

    function getAllSelectedNodes(): Array<Node> {
        const nodeIdAttrName = "data-node-target-id";
        return htmlElems
            .filter(e => e.hasAttribute(nodeIdAttrName))
            .map(e => {
                const nodeId = e.getAttributeNode(nodeIdAttrName)?.value;
                if (nodeId === undefined) throw new Error(`Attribute '${nodeIdAttrName}' doesn't have a value`);
                return env.getNodeByID(nodeId);
            });
    }

    function performActionOnAllSelectedNodes(action: (node: Node) => void) {
        getAllSelectedNodes().forEach(action);
    }

    return (
        <StyledNodeFrontendSelectionAnnotationLayer>
            <div className={"selection-annotation-canvas"} ref={selectionAnnotationCanvasRef}>
                { shouldShowSelectionAnnotation && (
                    <motion.div
                        transition={{ duration: .15 }}
                        className={"selection-annotation"}
                        style={{
                            top: topLeft.y,
                            left: topLeft.x,
                            width,
                            height
                        }}
                        animate={{
                            top: topLeft.y,
                            left: topLeft.x,
                            width,
                            height
                        }}
                    >
                        <div className={"annotation-toolbar trait-prevent-selection-collapse"}>
                            <ButtonGroup>
                                <Tag tag={
                                    <>
                                        <AdjustRounded sx={{ fontSize: 14 }}/>
                                        <DescriptiveTypography text={"new"}/>
                                    </>
                                }/>
                                <Tag tag={
                                    <>
                                        <MergeRounded sx={{ fontSize: 14 }}/>
                                        <DescriptiveTypography text={"combine"}/>
                                    </>
                                } onClick={() => {
                                    // TODO: fix and move to different place...
                                    let subNode: Node;
                                    getAllSelectedNodes().reverse().forEach((node, index, array) => {
                                        if (subNode === undefined) {
                                            subNode = node;
                                            return;
                                        }
                                        // Store 'group-membership' attachment on the sub node
                                        subNode.isHidden = true;
                                        subNode.attachments.set("group-membership", {
                                            groupParentNodeId: node.id
                                        } satisfies AttachmentGroupMember);
                                        // Store sub node reference
                                        node._testSubNode = subNode.id;
                                        subNode = node;
                                    });
                                    backend.events.notify(NodeCanvasBackendEventTypes.GENERIC, new NodeEvent());
                                }}/>
                                <Tag tag={
                                    <>
                                        <CableRounded sx={{ fontSize: 14 }}/>
                                        <DescriptiveTypography text={"cut"}/>
                                    </>
                                } onClick={() => {
                                    performActionOnAllSelectedNodes(node => {
                                        node.cutLoose();
                                        backend.events.notify(NodeCanvasBackendEventTypes.GRAPH_UPDATE, undefined);
                                    })
                                }}/>
                                <Tag tag={
                                    <>
                                        <FolderRounded sx={{ fontSize: 14 }}/>
                                        <DescriptiveTypography text={"group"}/>
                                    </>
                                }/>
                                <Tag tag={<DeleteRounded sx={{ fontSize: 14 }}/>}  onClick={() => {
                                    performActionOnAllSelectedNodes(node => {
                                        backend.deleteNode(node.id)
                                    });
                                    backend.endSecondarySelection();
                                }}/>
                                <Tag tag={<AccountTreeRounded sx={{ fontSize: 14 }}/>}/>
                            </ButtonGroup>
                        </div>
                    </motion.div>
                )}

                <div style={{
                    position: "absolute",
                    top: topLeft.y,
                    left: topLeft.x,
                    aspectRatio: "1 / 1",
                    width: 10,
                    // backgroundColor: "red",
                    transform: "translateX(-50%) translateY(-50%)"
                }}/>
                <div style={{
                    position: "absolute",
                    top: bottomRight.y,
                    left: bottomRight.x,
                    aspectRatio: "1 / 1",
                    width: 10,
                    // backgroundColor: "green",
                    transform: "translateX(-50%) translateY(-50%)"
                }}/>
            </div>
        </StyledNodeFrontendSelectionAnnotationLayer>
    );
}
