import {ImageMetaData} from "../../data/ImageMetaData";
import {StableDiffusionAttachment} from "./attachments/StableDiffusionAttachment";
import {getMetadata} from "meta-png";
import {ExtraNetworkMetaInfo} from "../../data/ExtraNetworkMetaInfo";

export class StableDiffusionAttachmentRetriever {

    constructor(
        private readonly src: Blob
    ) {}

    /**
     * TODO: Error of no negative prompt exists -> "Negative prompt:"-substring isn't added
     */
    public async retrieveMetaData(): Promise<StableDiffusionAttachment> {
        const r = await this.src.arrayBuffer();
        const meta = getMetadata(new Uint8Array(r), "parameters")!;

        // window.alert(meta)

        let [prompt, rest] = meta.split("Negative prompt:").map(s => s.trim());
        let promptShards = prompt.split(",").map(s => s.trim());
        let netInfos: Array<ExtraNetworkMetaInfo> = []
        promptShards.forEach(ps => {
            ps.match(/<.*>/)?.forEach(netInfRaw => {
                netInfRaw = netInfRaw.slice(1, -1)
                const [type, name, weightRaw] = netInfRaw.split(":");
                let weight = -1;
                try {
                    weight = Number(weightRaw)
                } catch (e) {
                    console.error(e)
                }
                netInfos.push({
                    type: type,
                    name: name,
                    weight: weight
                });
            })
        })

        let [negPrompt, negRest] = rest.split("Steps:").map(s => s.trim());
        let negPromptShards = negPrompt.split(",").map(s => s.trim());
        const mrBase = negRest.split(",").map(s => s.trim()).map(s => s.split(": "));
        mrBase[0] = ["Steps", mrBase[0][0]]
        let metaRest = new Map(mrBase as [string, string][]);

        return {
            negativePromptShards: negPromptShards,
            negativePrompt: negPrompt,
            promptShards: promptShards,
            prompt: prompt,
            extraNetworks: netInfos,
            kvs: metaRest,
            raw: meta
        };
    }
}
