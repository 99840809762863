import React from "react";
import styled from "styled-components";

const StyledCopyrightNotice = styled.p`
  font-weight: 300;
  font-size: .75rem;
  color: rgb(110, 118, 129);
  margin-top: 0;
  margin-bottom: 8px;
  width: min-content;
  white-space: nowrap;
`;

export const CopyrightNotice: React.FC = props => {
    const year = new Date().getUTCFullYear();
    const entityName = "Ardspace LLC";
    return (
        <StyledCopyrightNotice
            children={`© ${year} ${entityName}`}
        />
    );
}
