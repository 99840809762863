import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import {Color, hex} from "../../../base/logic/style/Color";
import {ColorSelectorProfile} from "./ColorSelectorProfile";
import {ColorSelector} from "./ColorSelector";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";

type StyledButtonProps = {
    baseColor: Color,
    primaryColor: Color,
    deactivateTransition: boolean,
    fontFamily: string
}

const StyledButton = styled.div<StyledButtonProps>`
  --primary-transition-duration: ${p => p.deactivateTransition ? "0" : ".35s"};
  min-height: 32px;
  // box-shadow: rgb(33, 38, 45) 0 0 0 1px ${p => `, ${p.primaryColor.css()} 0 0 5px 0`};
  box-shadow: rgb(33, 38, 45) 0 0 0 1px;
  background-color: rgb(1, 4, 9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: all .1s, box-shadow var(--primary-transition-duration);
  user-select: none;
  // cursor: pointer;
  
  
  &:active {
    transform: scale(.97);
  }

  & > * {
    font-size: 12px !important;
    transition: color var(--primary-transition-duration) !important;
    color: ${p => p.primaryColor.css()} !important;
    font-family: ${p => p.fontFamily};
  }
`;

export type ButtonProps = PropsWithChildren<{
    baseColor?: Color,
    color?: ColorSelectorProfile,
    square?: boolean,
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    deactivateTransition?: boolean,
    border?: boolean,

    monospace?: boolean
}>

export const Button: React.FC<ButtonProps> = props => {
    const baseColor: Color = props.baseColor ?? hex("#C9D1D9", .1);
    const primaryColor: Color = props.color !== undefined ? new ColorSelector(props.color, baseColor).color : baseColor;
    const border = props.border ?? true;

    const fontFam = props.monospace ?? false ? "monospace" : "unset";

    return (
        <StyledButton
            fontFamily={fontFam}
            deactivateTransition={props.deactivateTransition ?? false}
            primaryColor={primaryColor}
            children={
                typeof props.children === "string" ? (
                    <DescriptiveTypography text={props.children}/>
                ) : props.children
            }
            onClick={props.onClick}
            baseColor={baseColor}
            style={{
                aspectRatio: props.square ? "1 / 1" : "unset",
                boxShadow: border ? "rgb(33, 38, 45) 0 0 0 1px" : "unset"
            }}
        />
    );
}
