import React from "react";
import styled from "styled-components";
import {MoreHorizRounded} from "@mui/icons-material";
import {motion} from "framer-motion";
import {Text} from "../../../triton/components/typography/Text";
import {Color} from "../../../base/logic/style/Color";

const StyledPostViewHeader = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;  
  padding: 8px 16px;
  
  .post-view-header-user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    
    
  }
  
  .post-view-header-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
`;

export const PostViewHeader: React.FC = props => {
    return (
        <StyledPostViewHeader>
            <div className={"post-view-header-user-info"}>

                <img src={"https://pbs.twimg.com/profile_images/1661034678751510528/BGUMi8Sd_400x400.jpg"} style={{
                    width: 40,
                    aspectRatio: "1 / 1",
                    borderRadius: 80
                }} alt={"pfp"}/>

                <div>
                    <Text t={`Ard`} fs={16} fw={"bold"}/>
                    <Text t={`@ard`} fs={14} fw={"lighter"}/>
                </div>


            </div>
            <div className={"post-view-header-actions"}>

                <motion.div
                    style={{ display: "flex", alignItems: "center" }}
                    whileTap={{ scale: .98 }}
                    children={
                        <button children={<Text t={`Follow`} fs={16} fw={"bold"} style={{
                            color: "black"
                        }}/>} style={{
                            border: "none",
                            backgroundColor: "white",
                            borderRadius: 80,
                            height: 40,
                            padding: "0 20px"
                        }}/>
                    }
                />

                <motion.div style={{
                    display: "flex",
                    alignItems: "center"
                }} whileTap={{
                   scale: .9
                }} children={
                    <MoreHorizRounded/>
                }/>
            </div>
        </StyledPostViewHeader>
    );
}
