import React, {PropsWithChildren} from "react";
import styled from "styled-components";

const StyledScrollViewContainer = styled.div<ScrollViewContainerStyledProps>`
  display: flex;
  flex-direction: ${p => p.scrollOrientation === "y" ? "column" : "row"};
  height: 100%;
  width: 100vw;
  scroll-snap-type: ${p => p.scrollOrientation} mandatory;
  overflow-y: ${p => p.scrollOrientation === "y" ? "scroll" : "hidden"};
  overflow-x: ${p => p.scrollOrientation === "y" ? "hidden" : "scroll"};
  gap: 16px;
  flex-shrink: 0;
  
  // overscroll-behavior: none;
`;

type ScrollViewContainerStyledProps = {
    scrollOrientation: "x" | "y",
}

export type ScrollViewContainerProps = PropsWithChildren<{
    scrollOrientation?: "x" | "y",
    id?: string
}>

export const ScrollViewContainer: React.FC<ScrollViewContainerProps> = props => {
    const scrollOrientation = props.scrollOrientation ?? "y";

    return (
        <StyledScrollViewContainer
            id={props.id}
            scrollOrientation={scrollOrientation}
            children={props.children}
        />
    );
}
