import React, {useState} from "react";
import {TestResult} from "./carbide/TestResult";
import {carbideTest} from "./Carbide";
import {ButtonBase} from "../triton/components/buttons/ButtonBase";
import ReactJson from "react-json-view";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import styled from "styled-components";
import {TestResultState} from "./carbide/TestResultState";

const StyledCarbideTestComponent = styled.div`
  display: grid;
  grid-template-rows: repeat(2, min-content) auto;
  gap: 8px;
  align-items: center;
  justify-content: center;
  overflow: scroll;
`;

export const CarbideTestComponent: React.FC = props => {
    const [state, setState] = useState<{
        res?: TestResult
    }>({});

    return (
        <StyledCarbideTestComponent>
            <ButtonBase text={"Clear result"} baseProps={{
                onClick: () => {
                    setState(prevState => ({ ...prevState, res: undefined }));
                }
            }}/>

            <ButtonBase text={"Run test suite"} baseProps={{
                onClick: () => {
                    const res = carbideTest();
                    setState(prevState => ({ ...prevState, res: res }));
                }
            }}/>

            <TransitionGroup children={state.res && (
                <Collapse children={
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px"
                    }}>
                        { state.res.results.map(elem => {
                            return (
                                <div style={{
                                    width: "100%",
                                    borderRadius: "8px",
                                    color: "white",
                                    backgroundColor: "rgb(10,10,14)",
                                    overflow: "hidden",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                    padding: "8px"
                                }}>
                                    { TestResultState[elem.state] }

                                    <ReactJson enableClipboard={false} theme={"grayscale"} src={elem}/>
                                </div>
                            );
                        }) }
                    </div>
                }/>
            )}/>
        </StyledCarbideTestComponent>
    );
}
