import React, {PropsWithChildren} from "react";
import styled from "styled-components";

const StyledPostMetricsViewRow = styled.div`
  padding: 12px 0;
  
  .row-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
`;

export type PostMetricsViewRowProps = PropsWithChildren<{}>

export const PostMetricsViewRow: React.FC<PostMetricsViewRowProps> = props => {
    return (
        <StyledPostMetricsViewRow>
            <div className={"row-container"} children={props.children}/>
        </StyledPostMetricsViewRow>
    );
}
