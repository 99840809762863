import {FC, useEffect, useRef} from "react";
import {useTriton} from "../../triton/TritonHooks";
import {useForceRenderFunc} from "../../ForceRerenderHook";
import {Pin, PinMode} from "../backend/Pin";
import {useNodeCanvasBackend} from "./NodeCanvasBackend";
import {NodeCanvasBackendEventTypes} from "./NodeCanvasBackendEventTypes";
import {PortWireDisplay} from "./PortWireDisplay";

export const NodeCanvasLineRenderer: FC = props => {
    const backend = useNodeCanvasBackend();
    const t = useTriton();
    const forceRender = useForceRenderFunc();

    useEffect(() => {
        forceRender();
    }, []);

    useEffect(() => backend.events.observe(
        NodeCanvasBackendEventTypes.NODE_POSITION_UPDATE,
        NodeCanvasBackendEventTypes.NODE_POSITION_UPDATE_DELTA,
        NodeCanvasBackendEventTypes.GRAPH_UPDATE
    ).on((key, data) => {
        forceRender();
    }).destructor, []);

    const wrapperRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={wrapperRef} style={{
            // position: "relative",
            // left: -(wrapperRef.current?.getBoundingClientRect().x ?? 0),
            // top: -(wrapperRef.current?.getBoundingClientRect().y ?? 0),
        }}>
            { backend.environment.nodes.map((n, idx, arr) => {
                const links: Array<[Pin, Pin]> = [];

                n.pins.filterAllPins(pin => pin.canWrite).forEach(outPin => {
                    outPin.outputConnections.map(inPin => {
                        links.push([outPin, inPin]);
                    });
                });

                return (
                    <div key={`node-${n.config.id}-output-wires-group`} style={{
                        zIndex: 1
                    }}>
                        { links.map(l => (
                            <PortWireDisplay
                                key={`${l[0].config.id}-${l[1].config.id}`}
                                originPort={l[0]}
                                destPort={l[1]}
                                renderConfig={{
                                    enableSelectedLineVisualization: true
                                }}
                            />
                        )) }
                    </div>
                );
            }) }
        </div>
    );
}
