import React from "react";
import styled from "styled-components";
import {ButtonGroup} from "../components/ButtonGroup";
import {AddRounded, RemoveRounded} from "@mui/icons-material";
import {IconButton} from "../components/IconButton";
import {PropsDeclarationPrefix} from "./PropsDeclarationPrefix";

const StyledNumberField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  .field-prefix {
    font-family: Consolas, "Courier New", monospace;
    font-size: 13px;
    color: white;
    margin: 0;
    white-space: nowrap;
  }
  
  .number-input {
    flex-grow: 1;
    border: none;
    border-radius: 8px;
    background-color: #101016;
    height: 32px;
    width: inherit;
    outline: none;
    font-family: Consolas, "Courier New", monospace;
    color: white;
  }
`;

export type NumberFieldProps = {
    name: string,
    data: number,
    onChange: (data: number) => void
}

export const NumberField: React.FC<NumberFieldProps> = props => {
    return (
        <StyledNumberField>
            <PropsDeclarationPrefix name={props.name} type={"number"}/>

            <input className={"number-input"} value={props.data} inputMode={"numeric"} type={"number"} step={1} onChange={event => {
                props.onChange(Number(event.currentTarget.value));
            }}/>

            <ButtonGroup>
                <IconButton size={"small"} onClick={() => props.onChange(props.data + 1)} children={
                    <AddRounded sx={{ fontSize: 20 }}/>
                }/>
                <IconButton size={"small"} onClick={() => props.onChange(props.data - 1)} children={
                    <RemoveRounded sx={{ fontSize: 20 }}/>
                }/>
            </ButtonGroup>


        </StyledNumberField>
    );
}
