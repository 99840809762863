import React, {
    createContext,
    CSSProperties,
    FC,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";

import {Defaults, DominionV1, Types} from "./DominionTestV1";
import {DescriptiveTypography} from "../triton/components/typography/DescriptiveTypography";
import {Workspace} from "../ardai/components/Workspace";
import ReactJson from "react-json-view";
import {Tag} from "../ardai/components/Tag";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import _ from "lodash";
import {FlatIconButton} from "../ardai/components/FlatIconButton";
import {motion} from "framer-motion";
import {
    ArrowRightRounded,
    AutoGraphRounded,
    AutoModeRounded,
    ClearAllRounded,
    CloseFullscreenRounded,
    EngineeringRounded,
    ExtensionRounded,
    FullscreenRounded,
    InfoRounded,
    MoreHorizRounded, MoveDown,
    NightlightRoundRounded,
    NightsStayRounded,
    NumbersRounded,
    PersonRounded,
    PlayArrowRounded, QuestionMarkRounded,
    ReceiptLongRounded,
    RefreshRounded,
    ShieldOutlined, SortByAlphaRounded, StorefrontRounded,
    StoreRounded, SwipeDownRounded, SwipeRounded,
    TextSnippetRounded,
    TollRounded,
    UpgradeRounded,
    ViewStreamRounded
} from "@mui/icons-material";
import {Menu} from "../ardai/components/Menu";
import {useStaticState} from "../ardai/hooks/StaticStateHook";
import {CheckMenuButton, MenuButton} from "../ardai/components/MenuButton";
import {ColorableTag} from "../ardai/components/ColorableTag";
import {Color} from "../base/logic/style/Color";
import {MainTypography} from "../triton/components/typography/MainTypography";
import {HOCWrapper} from "../base/components/HOCWrapper";
import {DefaultButton} from "../ardai/components/DefaultButton";
import {Triton, triton} from "../triton/Triton";
import {ScrollView} from "../ardai/components/mobile/main/ScrollView";
import {ScrollViewContainer} from "../ardai/components/mobile/main/ScrollViewContainer";
import styled from "styled-components";
import {useTriton} from "../triton/TritonHooks";
import {FormDivider} from "../triton/components/forms/FormDivider";
import {ThemeSelectorMenuPart} from "../ardai/components/ThemeSelectorMenuPart";
import {TritonReactBridge} from "../triton/TritonReactBridge";
import {ButtonModalCompound} from "../ardai/components/ButtonModalCompound";
import defaultDeck = Defaults.defaultDeck;
import ICard = Types.ICard;
import NullaryFn = Types.NullaryFn;
import GameCallbacks = DominionV1.GameCallbacks;
import PaymentInfo = DominionV1.PaymentInfo;
import Card = Types.Card;
import TurnPhase = DominionV1.TurnPhase;
import IExtension = Types.IExtension;
import IField = Types.IField;
import FieldManager = Types.FieldManager;
import CardType = Types.CardType;
import {Interpolate} from "../triton/components/advanced/Interpolate";
import {CircularProgress} from "@mui/material";
import {TabBar} from "../ardai/components/TabBar";
import {ButtonGroup} from "../ardai/components/ButtonGroup";
import {Select} from "../base/components/base/Select";
import {Orientation} from "../base/logic/style/Orientation";
import {SelectMenuButton} from "../ardai/components/SelectMenuButton";
import {DeflationManager} from "./DeflationManager";
import {SaveDataLayout} from "./SaveDataLayout";
import {MenuGroup} from "../ardai/components/MenuGroup";
import {Dot} from "../ardai/components/Dot";
import {v4} from "uuid";

// TB.tb().then(() => {});

const createGame = (name: string = "placeholder-game", callbacks?: GameCallbacks) => new DominionV1.Game(name, {
    startHandSize: 8,
    players: [
        new DominionV1.Player("player", {
            deck: new DominionV1.Deck("deck", {
                name: "deck",
                cards: defaultDeck
            })
        })
    ],
    callbacks: callbacks ?? {
        satisfyBill(): Promise<PaymentInfo> {
            throw new Error();
        },
        selectCard(): Promise<DominionV1.SelectCardCallbackRequestCardInfo> {
            throw new Error();
        }
    }
});

export const GameContext = createContext<DominionV1.Game>(createGame())

export const DominionMain: FC = props => {
    const hocRef = useRef<HOCWrapper>();
    const game = useRef(createGame("game", {
        satisfyBill(request): Promise<PaymentInfo> {
            return new Promise((resolve, reject) => {
                hocRef.current?.dialog(
                    <PaymentSelector
                        bill={request.bill}
                        onAbort={() => {
                            hocRef.current?.closeLocalDialog();
                            request.signals.cancel();
                            reject();
                        }}
                        onSubmit={(payment) => {
                            hocRef.current?.closeLocalDialog();
                            resolve(payment);
                        }}
                    />, () => reject()
                );
            })
        },
        selectCard(request: DominionV1.SelectCardCallbackRequest): Promise<DominionV1.SelectCardCallbackRequestCardInfo> {
            return new Promise((resolve, reject) => {
                hocRef.current?.dialog(
                    <CardSelector
                        cards={request.from.map(r => r.card)}
                        title={request.title ?? "Select a card"}
                        onSelect={card => {
                            hocRef.current?.closeLocalDialog();
                            resolve(request.from.find(r => r.card === card)!);
                        }}
                    />, () => reject()
                );
            })
        }
    }));

    const startGame = () => game.current.prepare().start();
    const [eventLog, setEventLog] = useState<any[]>([]);
    useEffect(() => {
        game.current.relay.onFire((event, e) => {
            setEventLog(prevState => [...prevState, {
                type: event,
                ev: e
            }]);
        });
    }, [game, game.current]);

    const [state, ctx] = useStaticState({
        id: "DominionMain",
        initial: {
            showEventLog: false,
            showShelf: false,
            showTrash: false,
            keepShopOpen: false,
        }
    }).stateWithCtx;

    const player = game.current.activePlayer;
    const isShopShowing = state.keepShopOpen || game.current.activePlayer?.turn?.phase.data.type === DominionV1.TurnPhase.Types.BUY_PHASE;

    return (
        <TritonReactBridge factory={() => new Triton().start({
            makeGlobal: true
        })} children={() =>
            <GameContext.Provider value={game.current} children={
                <HOCWrapper componentDidMount={w => hocRef.current = w} body={() =>
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        overscrollBehavior: "none",
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                        gap: 8,
                        display: "grid",
                        gridTemplateRows: "min-content auto min-content"
                    }}>
                        <PlayerMetricsDisplay/>

                        <ScrollViewContainer scrollOrientation={"x"}>
                            <ScrollView>
                                <HandVerticalDisplay/>
                            </ScrollView>

                            <ScrollView style={{
                                display: "flex",
                                flexDirection: "column",
                                overflowY: "hidden",
                                gap: 8,
                            }}>
                                <div style={{
                                    flexGrow: 2,
                                    overflow: "hidden",
                                    maxHeight: "50%",
                                    minHeight: "min-content"
                                }} children={isShopShowing && (
                                    <EmpireShopDisplay/>
                                )}/>

                                {!isShopShowing && (
                                    <div style={{ height: "100%" }}/>
                                )}

                                <CardListRenderer
                                    cards={player?.hand.cards ?? []}
                                    title={"Hand"}
                                    style={{
                                        // flexShrink: 0,
                                        height: "50%",
                                        maxHeight: "100%"
                                    }}
                                    renderer={(card, ctx) => (
                                        <CardDisplay
                                            card={card}
                                            viewer={player!}
                                            onSelect={() => {
                                                // player?.useActionCardFromHand(index);
                                            }}
                                            summaryAppendix={
                                                card.types.has(CardType.ACTION) && (
                                                    <DefaultButton
                                                        flatIconMode={true}
                                                        size={"small"}
                                                        variant={false ? "primary" : "default"}
                                                        onClick={() => {
                                                            player?.useActionCardFromHand(ctx.originalIndex);
                                                        }}
                                                        children={
                                                            <div style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                gap: 4
                                                            }}>
                                                                <DescriptiveTypography text={"use"} noSelect style={{
                                                                    display: "inline-block",
                                                                    verticalAlign: "middle",
                                                                    lineHeight: "normal",
                                                                    textTransform: "uppercase",
                                                                    fontFamily: "monospace",
                                                                    color: false
                                                                        ? triton().col("fg_default")
                                                                        : triton().col("fg_muted")
                                                                }}/>
                                                                <ReceiptLongRounded fontSize={"small"} style={{
                                                                    color: false
                                                                        ? triton().col("fg_default")
                                                                        : triton().col("fg_muted")
                                                                }}/>
                                                            </div>
                                                        }
                                                    />
                                                ) || undefined
                                            }
                                        />
                                    )}
                                />

                                {/* hand */}
                                {/*
                                <Workspace
                                    config={{ mode: "desktop", name: "hand-display" }}
                                    style={{ display: "flex", flexDirection: "column", gap: 4, overscrollBehavior: "none", }}
                                    children={
                                        <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                                            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                                                <DescriptiveTypography text={"Hand"} noSelect/>
                                                { player?.hand.cards.length === 0 && (
                                                    <Tag tag={"Empty"} highlightOnHover={false}/>
                                                ) }
                                            </div>
                                            <div style={{ overflowY: "scroll", maxHeight: 500, overscrollBehavior: "none" }}>
                                                <TransitionGroup style={{ display: "flex", flexDirection: "column", gap: 4 }} children={
                                                    player?.hand.cards.map((card, index) => (
                                                        <Collapse key={card.id} style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                                                            <CardDisplay
                                                                card={card}
                                                                viewer={player!}
                                                                onSelect={() => {
                                                                    // player?.useActionCardFromHand(index);
                                                                }}
                                                                summaryAppendix={
                                                                    card.types.has(CardType.ACTION) && (
                                                                        <DefaultButton
                                                                            flatIconMode={true}
                                                                            size={"small"}
                                                                            variant={false ? "primary" : "default"}
                                                                            onClick={() => {
                                                                                player?.useActionCardFromHand(index);
                                                                            }}
                                                                            children={
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    gap: 4
                                                                                }}>
                                                                                    <DescriptiveTypography text={"use"} noSelect style={{
                                                                                        display: "inline-block",
                                                                                        verticalAlign: "middle",
                                                                                        lineHeight: "normal",
                                                                                        textTransform: "uppercase",
                                                                                        fontFamily: "monospace",
                                                                                        color: false
                                                                                            ? triton().col("fg_default")
                                                                                            : triton().col("fg_muted")
                                                                                    }}/>
                                                                                    <ReceiptLongRounded fontSize={"small"} style={{
                                                                                        color: false
                                                                                            ? triton().col("fg_default")
                                                                                            : triton().col("fg_muted")
                                                                                    }}/>
                                                                                </div>
                                                                            }
                                                                        />
                                                                    ) || undefined
                                                                }
                                                            />
                                                        </Collapse>
                                                    ))
                                                }/>
                                            </div>
                                        </div>
                                    }
                                />
                                */}
                            </ScrollView>


                            <ScrollView>
                                { state.showEventLog && (
                                    <Workspace
                                        config={{ mode: "desktop", name: "relay-log" }}
                                        children={
                                            <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                                                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                                                    <DescriptiveTypography text={"Relay log"} noSelect/>
                                                    { eventLog.length === 0 && (
                                                        <Tag tag={"Empty"} highlightOnHover={false}/>
                                                    ) }
                                                </div>
                                                <div style={{ overflowY: "scroll", maxHeight: 200 }}>
                                                    <TransitionGroup style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: 0
                                                    }}>
                                                        {
                                                            eventLog.reverse().map((e, index) => (
                                                                <Collapse key={e.ev.id}>
                                                                    <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                                                                        <DescriptiveTypography
                                                                            noSelect
                                                                            style={{ flexShrink: 0, fontFamily: "monospace" }}
                                                                            text={`[ ${_.padStart(index.toString().substring(0, 3) ?? "*", 3, "_")} ] ${e.type} =`}
                                                                        />
                                                                        <ReactJson
                                                                            src={e.ev}
                                                                            theme={"grayscale"}
                                                                            enableClipboard={false}
                                                                            collapsed
                                                                            style={{
                                                                                // paddingTop: 4,
                                                                                background: "transparent"
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Collapse>
                                                            ))
                                                        }
                                                    </TransitionGroup>
                                                </div>
                                            </div>
                                        }
                                    />
                                ) }

                                { state.showTrash && (
                                    <Workspace
                                        config={{ mode: "desktop", name: "shelf-display" }}
                                        style={{ display: "flex", flexDirection: "column", gap: 4 }}
                                        children={
                                            <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                                                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                                                    <DescriptiveTypography text={"Trash"} noSelect/>
                                                    { game.current.trash.cards.length === 0 && (
                                                        <Tag tag={"Empty"} highlightOnHover={false}/>
                                                    ) }
                                                </div>
                                                <div style={{ overflowY: "scroll", maxHeight: 500 }}>
                                                    <TransitionGroup style={{ display: "flex", flexDirection: "column", gap: 4 }} children={
                                                        game.current.trash.cards.map((card, index) => (
                                                            <Collapse key={card.id} style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                                                                <CardDisplay card={card} viewer={player!} onSelect={() => {
                                                                    player?.useActionCardFromHand(index);
                                                                }}/>
                                                            </Collapse>
                                                        ))
                                                    }/>
                                                </div>
                                            </div>
                                        }
                                    />
                                ) }

                                { state.showShelf && (
                                    <Workspace
                                        config={{ mode: "desktop", name: "shelf-display" }}
                                        style={{ display: "flex", flexDirection: "column", gap: 4 }}
                                        children={
                                            <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                                                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                                                    <DescriptiveTypography text={"Shelf"} noSelect/>
                                                    { player?.shelf.cards.length === 0 && (
                                                        <Tag tag={"Empty"} highlightOnHover={false}/>
                                                    ) }
                                                </div>
                                                <div style={{ overflowY: "scroll", maxHeight: 500 }}>
                                                    <TransitionGroup style={{ display: "flex", flexDirection: "column", gap: 4 }} children={
                                                        player?.shelf.cards.map((card, index) => (
                                                            <Collapse key={card.id} style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                                                                <CardDisplay card={card} viewer={player!} onSelect={() => {
                                                                    player?.useActionCardFromHand(index);
                                                                }}/>
                                                            </Collapse>
                                                        ))
                                                    }/>
                                                </div>
                                            </div>
                                        }
                                    />
                                ) }
                            </ScrollView>
                        </ScrollViewContainer>

                        <Workspace
                            config={{ mode: "desktop", name: "actions-display" }}
                            children={
                                <div style={{ paddingBottom: 16, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", gap: 2 }}>
                                        <FlatIconButton children={<RefreshRounded fontSize={"small"}/>} onClick={() => {
                                            game.current = createGame();
                                            setEventLog([]);
                                        }}/>
                                        <FlatIconButton children={<PlayArrowRounded fontSize={"small"}/>} onClick={() => {
                                            startGame();
                                        }}/>
                                        <FlatIconButton children={<ClearAllRounded fontSize={"small"}/>} onClick={() => {
                                            setEventLog([]);
                                            console.clear();
                                        }}/>

                                        <FlatIconButton active={state.keepShopOpen} tooltip={"Toggle shop display"} children={<StoreRounded fontSize={"small"}/>} onClick={() => {
                                            ctx.update(prevState => ({
                                                keepShopOpen: !prevState.keepShopOpen
                                            }));
                                        }}/>

                                        <FlatIconButton active={state.showEventLog} tooltip={"Toggle event log display"} children={<TextSnippetRounded fontSize={"small"}/>} onClick={() => {
                                            ctx.update(prevState => ({
                                                showEventLog: !prevState.showEventLog
                                            }));
                                        }}/>

                                        <Menu opener={
                                            <FlatIconButton children={<EngineeringRounded fontSize={"small"}/>}/>
                                        }>
                                            <Workspace
                                                config={{ mode: "desktop", name: "player-object-viewer" }}
                                                style={{ maxHeight: 500, overflowY: "scroll" }}
                                                children={
                                                    <ReactJson
                                                        src={game.current}
                                                        theme={"grayscale"}
                                                        enableClipboard={false}
                                                        collapsed
                                                        style={{
                                                            background: "transparent"
                                                        }}
                                                    />
                                                }
                                            />
                                        </Menu>

                                        <Menu opener={
                                            <FlatIconButton deactivated={player === undefined} children={<PersonRounded fontSize={"small"}/>}/>
                                        }>
                                            <Workspace
                                                config={{ mode: "desktop", name: "player-object-viewer" }}
                                                style={{ maxHeight: 500, overflowY: "scroll" }}
                                                children={
                                                    <ReactJson
                                                        src={player!}
                                                        theme={"grayscale"}
                                                        enableClipboard={false}
                                                        collapsed
                                                        style={{
                                                            background: "transparent"
                                                        }}
                                                    />
                                                }
                                            />
                                        </Menu>

                                        <Menu opener={
                                            <FlatIconButton children={<MoreHorizRounded fontSize={"small"}/>}/>
                                        }>
                                            <MenuButton text={"Reload website"} onSelect={() => {
                                                window.location.reload()
                                            }}/>

                                            <CheckMenuButton text={"Show shelf"} keepOpenOnClick checked={state.showShelf} onSelect={() => {
                                                ctx.update(prevState => ({
                                                    showShelf: !prevState.showShelf
                                                }));
                                            }}/>

                                            <CheckMenuButton text={"Show trash"} keepOpenOnClick checked={state.showTrash} onSelect={() => {
                                                ctx.update(prevState => ({
                                                    showTrash: !prevState.showTrash
                                                }));
                                            }}/>

                                            <ThemeSelectorMenuPart/>
                                        </Menu>
                                    </div>

                                    <div style={{ display: "flex" }}>
                                        <DefaultButton size={"small"} variant={"default"} onClick={() => {
                                            player?.turn?.nextPhase()
                                        }} children={
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <DescriptiveTypography text={"next"} noSelect style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    lineHeight: "normal",
                                                    textTransform: "uppercase",
                                                    fontFamily: "monospace"
                                                }}/>
                                                <ArrowRightRounded fontSize={"small"} style={{
                                                    color: triton().col("fg_muted")
                                                }}/>
                                            </div>
                                        }/>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                }/>
            }/>
        }/>
    );
}

type HandVerticalDisplayRenderProps = {
    reverse: boolean
}

const StyledHandVerticalDisplay = styled.div<HandVerticalDisplayRenderProps>`
  // display: grid;
  // grid-row-gap: 8px;
  // grid-template-rows: min-content min-content auto;
  overflow-x: hidden;
  // grid-auto-flow: ${p => p.reverse ? "dense" : "row"};
  
  display: flex;
  flex-direction: ${p => p.reverse ? "column-reverse" : "column"};
  gap: 8px;
  
  .card-hint, .card-toolbar {
    height: min-content;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .card-toolbar {
    gap: 4px;
  }
  
  #hand-card-scrolling-container {
    flex-shrink: 1;
  }
`;

export type HandVerticalDisplayState = {
    reverse: boolean,
    displayMode: "snap" | "scroll"
}

export const HandVerticalDisplay: FC = props => {
    const t = useTriton();
    const game = useContext(GameContext);
    const player = game.activePlayer;
    const hand = player?.hand;
    const cards = hand?.cards ?? [];
    const [activeCardIdx, setActiveCardIdx] = useState(0);

    const selectCard = (idx: number) => {
        setActiveCardIdx(idx);
        const offsetTop = (document.getElementsByClassName(`hand-card-wrapper`).item(idx) as any)?.offsetTop ?? 0;
        document.getElementById("hand-card-scrolling-container")?.scrollTo({
            top: offsetTop,
            behavior: "smooth"
        });
    }

    const [state, ctx] = useStaticState<HandVerticalDisplayState>({
        id: "HandVerticalDisplay",
        initial: {
            reverse: false,
            displayMode: "snap"
        }
    }).stateWithCtx;

    return (
        <StyledHandVerticalDisplay reverse={state.reverse}>

            <Workspace config={{ mode: "desktop", name: "hand-snap-display-toolbar" }} style={{
                flexGrow: 0,
                flexShrink: 2,
                height: "min-height",
                padding: 8
            }}>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto 1fr",
                    gap: 8,
                    alignItems: "center",
                    width: "100%",

                    overflow: "hidden"
                }}>
                    <div style={{
                        marginRight: "auto"
                    }}>
                        <ButtonGroup>
                            <Tag
                                tag={p => <SwipeDownRounded style={{
                                    fontSize: 14,
                                    color: p.props.active ? t.col("color_primary") : undefined
                                }}/>}
                                active={state.displayMode === "snap"}
                                onClick={() => ctx.update({ displayMode: "snap" })}
                            />
                            <Tag
                                tag={p => <MoveDown style={{
                                    fontSize: 14,
                                    color: p.props.active ? t.col("color_primary") : undefined
                                }}/>}
                                active={state.displayMode === "scroll"}
                                onClick={() => ctx.update({ displayMode: "scroll" })}
                            />
                        </ButtonGroup>
                    </div>

                    <div className={"card-hint"} style={{
                        overflowX: "scroll"
                    }}>
                        <Interpolate stepDelay={50} value={activeCardIdx} children={d => (
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 4
                            }}>
                                {/* cards.map((card, idx) => (
                                    <div onClick={() => selectCard(idx)} style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 2,
                                    }}>
                                        <FlatIconButton children={
                                            card.types.has(CardType.VICTORY_POINTS) && <ShieldOutlined style={{ fontSize: 14 }}/> ||
                                            card.types.has(CardType.CURRENCY) && <TollRounded style={{ fontSize: 14 }}/> ||
                                            card.types.has(CardType.ACTION) && <ReceiptLongRounded style={{ fontSize: 14 }}/>
                                        }/>

                                        <DescriptiveTypography text={idx} noSelect style={{
                                            cursor: "pointer",
                                            color: idx === d ? t.col("color_primary") : t.col("fg_muted")
                                        }}/>
                                    </div>
                                )) */}

                                { cards.map((card, idx) => (
                                    <Tag key={card.id} active={idx === d} onClick={() => selectCard(idx)} tag={
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: 4
                                        }}>
                                            <div style={{
                                                // color: t.col("fg_muted"),
                                                color: idx === d ? t.col("color_primary") : t.col("fg_muted"),
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }} children={
                                                card.types.has(CardType.VICTORY_POINTS) && <ShieldOutlined style={{ fontSize: 14 }}/> ||
                                                card.types.has(CardType.CURRENCY) && <TollRounded style={{ fontSize: 14 }}/> ||
                                                card.types.has(CardType.ACTION) && <ReceiptLongRounded style={{ fontSize: 14 }}/>
                                            }/>

                                            <DescriptiveTypography text={idx} noSelect style={{
                                                cursor: "pointer",
                                                color: idx === d ? t.col("color_primary") : t.col("fg_muted")
                                            }}/>

                                            { card.level > 1 && (
                                                <DescriptiveTypography
                                                    text={card.level}
                                                    noSelect
                                                    style={{
                                                        cursor: "pointer",
                                                        // color: idx === d ? "#9e73ff" : t.col("fg_muted")
                                                        color: "#9e73ff"
                                                    }}
                                                />
                                            ) }
                                        </div>
                                    }/>
                                )) }
                            </div>
                        )}/>
                    </div>

                    <div style={{
                        marginLeft: "auto"
                    }}>
                        <Menu opener={
                            <Tag tag={<MoreHorizRounded style={{
                                color: triton().col("fg_muted"),
                                fontSize: 16
                            }}/>}/>
                        }>
                            <CheckMenuButton
                                text={"Reverse"}
                                checked={state.reverse}
                                keepOpenOnClick
                                onSelect={() => ctx.reverseBool("reverse")}
                            />
                            <SelectMenuButton
                                name={"display-mode"}
                                disableSearchbar
                                title={"Display mode"}
                                selected={state.displayMode}
                                onSelect={o => ctx.update({ displayMode: o as any })}
                                options={[
                                    { id: "snap", text: "Snap" },
                                    { id: "scroll", text: "Scroll" }
                                ]}
                            />
                        </Menu>
                    </div>
                </div>
            </Workspace>



            { state.displayMode === "snap" && (
                <ScrollViewContainer id={"hand-card-scrolling-container"} scrollOrientation={"y"}>
                    { cards.map((card, idx) => (
                        <ScrollView key={idx} style={{ /* padding: "0 16px" */ }}>
                            <motion.div className={`hand-card-wrapper`} onViewportEnter={() => setActiveCardIdx(idx)} style={{
                                overflow: "hidden"
                            }}>
                                <Workspace config={{ mode: "desktop", name: "hand-snap-display" }} style={{
                                    overflow: "scroll",
                                    padding: 8
                                }}>
                                    <CardDisplay
                                        card={card}
                                        viewer={player!}
                                        initialExpanded
                                        initialShowMoreInfo
                                    />
                                </Workspace>
                            </motion.div>
                        </ScrollView>
                    )) }
                </ScrollViewContainer>
            ) }

            { state.displayMode === "scroll" && (
                <CardListRenderer
                    cards={player?.hand.cards ?? []}
                    title={"Hand"}
                    renderer={(card, ctx) => (
                        <CardDisplay
                            card={card}
                            viewer={player!}
                            onSelect={() => {
                                // player?.useActionCardFromHand(index);
                            }}
                            summaryAppendix={
                                card.types.has(CardType.ACTION) && (
                                    <DefaultButton
                                        flatIconMode={true}
                                        size={"small"}
                                        variant={false ? "primary" : "default"}
                                        onClick={() => {
                                            player?.useActionCardFromHand(ctx.originalIndex);
                                        }}
                                        children={
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: 4
                                            }}>
                                                <DescriptiveTypography text={"use"} noSelect style={{
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    lineHeight: "normal",
                                                    textTransform: "uppercase",
                                                    fontFamily: "monospace",
                                                    color: false
                                                        ? triton().col("fg_default")
                                                        : triton().col("fg_muted")
                                                }}/>
                                                <ReceiptLongRounded fontSize={"small"} style={{
                                                    color: false
                                                        ? triton().col("fg_default")
                                                        : triton().col("fg_muted")
                                                }}/>
                                            </div>
                                        }
                                    />
                                ) || undefined
                            }
                        />
                    )}
                />
            ) }
        </StyledHandVerticalDisplay>
    );
}

export const PlayerMetricsDisplay: FC = props => {
    const game = useContext(GameContext);
    const player = game.activePlayer!;

    return (
        <div>
            <div style={{ display: "grid", gap: 8, gridTemplateColumns: "repeat(3, 1fr)",
                // gridTemplateRows: "repeat(2, 1fr)"
            }}>
                {/*
                <Workspace config={{ mode: "desktop", name: "turn-metrics" }} children={
                    <CardInfoComponent
                        info={`${player?.turnCount ?? "?"} turns`}
                        icon={<NightlightRoundRounded/>}
                    />
                }/>
                */}

                {/*
                <Workspace config={{ mode: "desktop", name: "phase" }} children={(() => {
                    const phase = player?.turn?.phase?.data.type;
                    return (
                        <CardInfoComponent
                            info={(() => {
                                if (phase === undefined) return "-";
                                switch (phase) {
                                    case TurnPhase.Types.ACTION_PHASE:
                                        return "Actions"
                                    case TurnPhase.Types.BUY_PHASE:
                                        return "Buys"
                                    default:
                                        return phase;
                                }
                            })()}
                            icon={phase === undefined ? <QuestionMarkRounded/> : (
                                phase === TurnPhase.Types.ACTION_PHASE && <ReceiptLongRounded/> ||
                                phase === TurnPhase.Types.BUY_PHASE && <StoreRounded/> ||
                                "-"
                            )}
                        />
                    );
                })()}/>
                */}

                {/*
                <Workspace config={{ mode: "desktop", name: "deck-metrics" }} children={
                    <CardInfoComponent
                        info={`${player?.deck?.cards.length ?? "?"} cards`}
                        icon={<ViewStreamRounded/>}
                    />
                }/>
                */}

                {/*
                <Workspace config={{ mode: "desktop", name: "action-num" }} children={
                    <CardInfoComponent
                        info={`${player?.actionCounter.value ?? "?"} actions`}
                        icon={<ReceiptLongRounded/>}
                    />
                }/>
                <Workspace config={{ mode: "desktop", name: "buy-num" }} children={
                    <CardInfoComponent
                        info={`${player?.buyCounter.value ?? "?"} buy`}
                        icon={<StoreRounded/>}
                    />
                }/>
                <Workspace config={{ mode: "desktop", name: "money-num" }} children={
                    <CardInfoComponent
                        info={`${player?.moneyCounter.value ?? "?"} + ${
                            player?.hand.cards
                                .filter(c => c.types.has(CardType.CURRENCY))
                                .map(c => c.traits[CardType.CURRENCY]?.value()?.asCopper() ?? 0)
                                .reduceRight((p, c) => p + c, 0) ?? "?"
                        }`}
                        icon={<TollRounded/>}
                    />
                }/>
                */}
            </div>

            <div style={{
                display: "grid",
                gap: 0,
                gridTemplateColumns: "repeat(6, 1fr)",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 8
            }}>
                <Tag hideBackgroundOnDefault tag={ctx => (
                    <>
                        <RefreshRounded style={{
                            transition: "color .25s",
                            fontSize: 14,
                            color: ctx.props.active ? ctx.t.col("color_primary") : undefined
                        }}/>
                        <Interpolate value={player?.turnCount ?? 0}/>
                    </>
                )}/>
                <Tag hideBackgroundOnDefault tag={ctx => (
                    (() => {
                        const phase = player?.turn?.phase?.data.type;

                        return (
                            <>
                                {
                                    phase === undefined ? <QuestionMarkRounded style={{ fontSize: 14 }}/> : (
                                        phase === TurnPhase.Types.ACTION_PHASE && <ReceiptLongRounded style={{ fontSize: 14 }}/> ||
                                        phase === TurnPhase.Types.BUY_PHASE && <StoreRounded style={{ fontSize: 14 }}/> ||
                                        "-"
                                    )
                                }
                                <DescriptiveTypography text={(() => {
                                    if (phase === undefined) return "-";
                                    switch (phase) {
                                        case TurnPhase.Types.ACTION_PHASE:
                                            return "Act"
                                        case TurnPhase.Types.BUY_PHASE:
                                            return "Buy"
                                        default:
                                            return phase;
                                    }
                                })()}/>
                            </>
                        );
                    })()
                )}/>
                <Tag active={player?.turn?.phase.data.type === TurnPhase.Types.ACTION_PHASE} hideBackgroundOnDefault tag={ctx => (
                    <>
                        <ReceiptLongRounded style={{
                            transition: "color .25s",
                            fontSize: 14,
                            color: ctx.props.active ? ctx.t.col("color_primary") : undefined
                        }}/>
                        <Interpolate value={player?.actionCounter.value ?? 0}/>
                    </>
                )}/>
                <Tag active={player?.turn?.phase.data.type === TurnPhase.Types.BUY_PHASE} hideBackgroundOnDefault tag={ctx => (
                    <>
                        <StoreRounded style={{
                            transition: "color .25s",
                            fontSize: 14,
                            color: ctx.props.active ? ctx.t.col("color_primary") : undefined
                        }}/>
                        <Interpolate value={player?.buyCounter.value ?? 0}/>
                    </>
                )}/>
                <Tag active={(player?.buyCounter.value ?? 0) > 0} hideBackgroundOnDefault tag={ctx => (
                    <>
                        <TollRounded style={{
                            transition: "color .25s",
                            fontSize: 14,
                            color: ctx.props.active ? ctx.t.col("color_secondary") : undefined
                        }}/>
                        <DescriptiveTypography text={`${player?.moneyCounter.value ?? "?"} + ${
                            player?.hand.cards
                                .filter(c => c.types.has(CardType.CURRENCY))
                                .map(c => c.traits[CardType.CURRENCY]?.value()?.asCopper() ?? 0)
                                .reduceRight((p, c) => p + c, 0) ?? "?"
                        }`}/>
                    </>
                )}/>
                <Tag active={false} hideBackgroundOnDefault tag={ctx => (
                    <>
                        <ViewStreamRounded style={{
                            transition: "color .25s",
                            fontSize: 14,
                            color: ctx.props.active ? ctx.t.col("color_secondary") : undefined
                        }}/>
                        <Interpolate value={player?.deck?.cards.length ?? 0}/>
                    </>
                )}/>

                {/*
                <Tag tag={"deflate"} onClick={() => {
                    const save = new DeflationManager().deflate(game);
                    const saveRaw = JSON.stringify(save);
                    window.localStorage.setItem("save", saveRaw);
                }}/>
                <Tag tag={"inflate"} onClick={() => {
                    const saveRaw = window.localStorage.getItem("save");
                    if (saveRaw === null) return;
                    const save = JSON.parse(saveRaw) as SaveDataLayout;
                    new DeflationManager().inflate(game, save);
                    game.relay.fire("generic");
                }}/>
                */}
            </div>
        </div>
    );
}

export const CardSelector: FC<{
    title?: string,
    description?: string,
    cards: Array<ICard>,
    onSelect: (card: ICard) => void
}> = props => {
    const game = useContext(GameContext);
    const player = game.activePlayer;
    return (
        <div style={{
            padding: 16,
            borderRadius: 8,
            width: "calc(100vw - 2 * 16px)",
            maxWidth: 500,
            margin: "auto",
            marginBottom: 16,
            border: `1px solid ${triton().colObj("fg_muted").withAlpha(.25).css()}`,
            backgroundColor: triton().col("bg_main")
        }}>
            <Workspace
                config={{ mode: "desktop", name: "empire-shop-display" }}
                children={
                    <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                        <div>
                            <MainTypography text={props.title ?? "Select a card"}/>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                            { props.cards.map(card => (
                                <CardDisplay card={card} viewer={player!} onSelect={() => {
                                    props.onSelect(card);
                                }}/>
                            )) }
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export const PaymentSelector: FC<{
    bill: DominionV1.Bill<Types.ICard>,
    onSubmit: (payment: PaymentInfo) => void,
    onAbort?: NullaryFn
}> = props => {
    const game = useContext(GameContext);
    const player = game.activePlayer!;
    const currencyCards = player.hand.cards.filter(card => card.types.has(CardType.CURRENCY));
    const bill = props.bill;

    const [state, setState] = useState({
        open: true,
        moneyRequired: bill.moneyRequired,
        payment: {
            moneyProvided: player.moneyCounter.value,
            selectedPaymentCards: []
        } as DominionV1.PaymentInfo
    });

    const isSatisfied = props.bill.isSatisfied(bill, state.payment);

    const overSpendingAmount = state.payment.moneyProvided - bill.moneyRequired;
    const isOverSpending = overSpendingAmount > 0;

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            payment: {
                moneyProvided: player.moneyCounter.value,
                selectedPaymentCards: []
            }
        }))
    }, [props.bill])

    // useEffect(() => {
    //     if (isSatisfied) return;
    //     const payment = state.payment;
    //     const reqMoney = props.bill.moneyRequired;
    //     let provMoney = payment.moneyProvided;
    //     let newlySelectedCards: Array<ICard> = [];
    //     for (const curCard of currencyCards) {
    //         if (reqMoney <= provMoney) break;
    //         const curCardVal = curCard.traits["CURRENCY"]!.value().asCopper();
    //         if (curCardVal === 0) continue;
    //         if (payment.selectedPaymentCards.includes(curCard)) continue;
    //         newlySelectedCards.push(curCard);
    //         provMoney += curCardVal;
    //     }
    //     setState(prevState => ({
    //         ...prevState,
    //         payment: {
    //             ...prevState.payment,
    //             moneyProvided: prevState.payment.moneyProvided + provMoney,
    //             selectedPaymentCards: [
    //                 ...prevState.payment.selectedPaymentCards,
    //                 ...newlySelectedCards
    //             ]
    //         }
    //     }))
    // }, [props.bill])

    const buy = () => {
        if (!isSatisfied) return;
        props.onSubmit(state.payment);
    }

    const close = () => {
        setState(prevState => ({
            ...prevState,
            open: false
        }));
        props.onAbort?.();
    }

    return (
        <div style={{
            padding: 16,
            borderRadius: 8,
            width: "calc(100vw - 2 * 16px)",
            maxWidth: 500,
            margin: "auto",
            marginBottom: 16,
            border: `1px solid ${triton().colObj("fg_muted").withAlpha(.25).css()}`,
            backgroundColor: triton().col("bg_main")
        }}>
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                <MainTypography text={"Checkout"}/>
                <DescriptiveTypography text={`Please select payment methods`}/>

                {/*
                <Workspace config={{ mode: "desktop", name: "order-metrics" }}>
                    <FlatIconButton children={<ReceiptLongRounded fontSize={"small"}/>}/>
                    <MainTypography text={"Order"}/>
                    <CardDisplay
                        card={props.bill.for}
                        viewer={player}
                    />
                </Workspace>
                */}

                <div style={{ display: "grid", gap: 8, gridTemplateColumns: "repeat(2, 1fr)", gridTemplateRows: "min-content" }}>
                    <Workspace config={{ mode: "desktop", name: "bill-metrics" }}>
                        <FlatIconButton children={<ReceiptLongRounded fontSize={"small"}/>}/>
                        <MainTypography text={"Bill"}/>
                        <DescriptiveTypography text={`${props.bill.moneyRequired} copper`}/>
                    </Workspace>

                    <Workspace config={{ mode: "desktop", name: "payment-metrics" }}>
                        <FlatIconButton children={<TollRounded fontSize={"small"}/>}/>
                        <MainTypography text={"Payment"}/>

                        { player.moneyCounter.value > 0 && (
                            <DescriptiveTypography text={`Using ${player.moneyCounter.value} from player`}/>
                        ) }
                        <TransitionGroup children={
                            state.payment.moneyProvided - player.moneyCounter.value > 0 && (
                                <Collapse>
                                    <DescriptiveTypography text={`Additional ${state.payment.moneyProvided - player.moneyCounter.value}`}/>
                                </Collapse>
                            )
                        }/>

                        <FormDivider paddingVertical={4}/>

                        <DescriptiveTypography text={`${state.payment.moneyProvided} copper`}/>

                        <TransitionGroup children={
                            isOverSpending && (
                                <Collapse>
                                    <DescriptiveTypography text={`(Overspending ${overSpendingAmount})`} style={{
                                        fontStyle: "italic"
                                    }}/>
                                </Collapse>
                            )
                        }/>
                    </Workspace>
                </div>
                <Workspace config={{ mode: "desktop", name: "payment-selector" }} style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                    <DescriptiveTypography text={"Hand"}/>

                    <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                        { currencyCards.map(curCard => (
                            <CardDisplay
                                card={curCard}
                                viewer={player}
                                selected={state.payment.selectedPaymentCards.includes(curCard)}
                                toggleSelection={selected => {
                                    if (selected) {
                                        setState(prevState => ({
                                            ...prevState,
                                            payment: {
                                                ...prevState.payment,
                                                moneyProvided: prevState.payment.moneyProvided - curCard.traits[CardType.CURRENCY]!.value().asCopper(),
                                                selectedPaymentCards: prevState.payment.selectedPaymentCards.filter(pc => pc !== curCard)
                                            }
                                        }));
                                    } else {
                                        setState(prevState => ({
                                            ...prevState,
                                            payment: {
                                                ...prevState.payment,
                                                moneyProvided: prevState.payment.moneyProvided + curCard.traits[CardType.CURRENCY]!.value().asCopper(),
                                                selectedPaymentCards: [...prevState.payment.selectedPaymentCards, curCard]
                                            }
                                        }));
                                    }
                                }}
                            />
                        )) }
                    </div>
                </Workspace>

                <Workspace config={{ mode: "desktop", name: "payment-selector-quick-actions" }} style={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <FlatIconButton
                        children={<AutoModeRounded fontSize={"small"}/>}
                        tooltip={"Auto-select"}
                        onClick={() => {
                            if (isSatisfied) return;
                            const payment = state.payment;
                            const reqMoney = props.bill.moneyRequired;
                            let provMoney = payment.moneyProvided;
                            let newlySelectedCards: Array<ICard> = [];
                            for (const curCard of currencyCards) {
                                if (reqMoney <= provMoney) break;
                                const curCardVal = curCard.traits["CURRENCY"]!.value().asCopper();
                                if (curCardVal === 0) continue;
                                if (payment.selectedPaymentCards.includes(curCard)) continue;
                                newlySelectedCards.push(curCard);
                                provMoney += curCardVal;
                            }
                            setState(prevState => ({
                                ...prevState,
                                payment: {
                                    ...prevState.payment,
                                    moneyProvided: prevState.payment.moneyProvided + provMoney,
                                    selectedPaymentCards: [
                                        ...prevState.payment.selectedPaymentCards,
                                        ...newlySelectedCards
                                    ]
                                }
                            }))
                        }}
                    />
                </Workspace>

                <Workspace config={{ mode: "desktop", name: "payment-selector" }} style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 8
                }}>
                    <DefaultButton
                        children={"Cancel"}
                        onClick={() => props.onAbort?.()}
                        size={"small"}
                    />
                    <DefaultButton
                        children={"Buy"}
                        onClick={() => buy()}
                        deactivated={!isSatisfied}
                        variant={isSatisfied ? "primary" : "default"}
                        size={"small"}
                        tooltip={!isSatisfied && "Payment isn't sufficient"}
                    />
                </Workspace>
            </div>
        </div>
    );
}

export const StyledEmpireShopDisplay = styled.div`
  display: grid;
  grid-template-rows: min-content auto;
  height: 100%;
  overflow: hidden;
  
  .shop-body {
    overflow: hidden;
  }
`;

export const EmpireShopDisplay: FC = props => {
    const game = useContext(GameContext);
    const shop = game.empireShop;

    const triggerPurchaseOffer = (offer: DominionV1.ShopOffer<DominionV1.Player, Types.ICard>, idx: number) => {
        shop.buy(game.activePlayer!, idx).then(() => {});
    }

    return (
        <StyledEmpireShopDisplay>
            <span/>
            {/* <DescriptiveTypography text={"Shop"}/> */}

            <div className={"shop-body"}>
                <Workspace
                    config={{ mode: "desktop", name: "empire-shop-display" }}
                    style={{ overflow: "hidden", overscrollBehavior: "none", maxHeight: "100%" }}
                    children={
                        <div style={{
                            display: "flex",
                            overflow: "hidden",
                            flexDirection: "column",
                            gap: 4,
                            // height: "100%",
                            maxHeight: "100%"
                        }}>
                            <div>
                                <DescriptiveTypography text={"Empire shop"}/>
                            </div>
                            <div style={{ overflowY: "scroll", height: "100%" }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                                    { shop.offers.map((offer, idx) => (
                                        <CardOfferDisplay key={idx} offer={offer} onSelect={() => {
                                            triggerPurchaseOffer(offer, idx);
                                        }}/>
                                    )) }
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
        </StyledEmpireShopDisplay>
    );
}

export const CardOfferDisplay: FC<{
    offer: DominionV1.ShopOffer<DominionV1.Player, Types.ICard>,
    onSelect: NullaryFn
}> = props => {
    const game = useContext(GameContext);
    const player = game.activePlayer;
    const offer = props.offer;

    return (
        <CardDisplay
            card={offer.getPreview!}
            viewer={player!}
            summaryAppendix={
                offer.available === 0 && (<DescriptiveTypography text={"Out of stock"}/>) ||
                <DescriptiveTypography text={`${offer.available} available`}/>
            }
            onSelect={() => props.onSelect()}
        />
    );
}

export const CardDisplay: FC<{
    card: ICard,
    viewer: DominionV1.Player,
    onSelect?: (card: ICard) => void,
    summaryAppendix?: ReactNode,
    selected?: boolean,
    toggleSelection?: (selected: boolean) => void,
    initialExpanded?: boolean,
    initialShowMoreInfo?: boolean
}> = props => {
    const game = useContext(GameContext);
    const card = props.card;
    const viewer = props.viewer;
    const isOwned = card.isOwned();
    const isUpgradable = card.isUpgradable();
    const isPurchasable = card.types.has(CardType.PURCHASABLE);
    const price = card.traits[CardType.PURCHASABLE]?.calcBuyerPrice(viewer!);
    const hasValue = card.types.has(CardType.CURRENCY);
    const purchasingPower = card.traits[CardType.CURRENCY]?.value()
    const value = card.value;
    const level = card.level;

    const [state, setState] = useState<{
        expanded: boolean,
        showDebugInfo: boolean,
        tab: "upgrades" | "extensions" | "debug",
        showMoreInfo: boolean
    }>({
        expanded: props.initialExpanded ?? false,
        showDebugInfo: false,
        tab: "upgrades",
        showMoreInfo: props.initialShowMoreInfo ?? false
    });

    return (
        <div style={{
            display: "grid",
            alignItems: "center",
            columnGap: 4,
            gridTemplateColumns: "min-content auto",
            gridTemplateRows: "min-content auto",

            // overflow: "scroll",
            overflow: "hidden",

            maxHeight: "100%"
        }}>
            <FlatIconButton active={props.selected} onClick={() => props.toggleSelection?.(props.selected ?? false)} children={
                card.types.has(CardType.VICTORY_POINTS) && <ShieldOutlined fontSize={"small"}/> ||
                card.types.has(CardType.CURRENCY) && <TollRounded fontSize={"small"}/> ||
                card.types.has(CardType.ACTION) && <ReceiptLongRounded fontSize={"small"}/>
            }/>

            <div style={{ display: "flex", alignItems: "center", gap: 4, justifyContent: "space-between", overflowX: "hidden" }}>
                <div style={{ display: "flex", alignItems: "center", gap: 4, overflowX: "hidden" }}>
                    <FlatIconButton active={state.expanded} children={<InfoRounded fontSize={"small"}/>} onClick={() => {
                        setState(prevState => ({
                            ...prevState,
                            expanded: !prevState.expanded
                        }));
                    }}/>
                    <DescriptiveTypography noSelect style={{ flexShrink: 0, cursor: "pointer" }} text={`${card.displayName} =`} baseProps={{
                        onClick: () => {
                            props.onSelect?.(card)
                        }
                    }}/>
                    <div style={{ display: "flex", alignItems: "center", gap: 2, overflowX: "scroll" }}>
                        { (level > 1) && (
                            <ColorableTag
                                key={"lvl-indicator"}
                                c={Color.ofHex("#9e73ff")}
                                tag={`Lvl.${level}`}
                            />
                        ) }

                        { (card.types.has(CardType.PURCHASABLE) && price) && (
                            <ColorableTag
                                key={"purchasable-indicator"}
                                c={Color.ofHex("#ffdf60")}
                                tag={`${
                                    price.genericPrice === price.buyerPrice
                                        ? price.genericPrice
                                        : `${price.genericPrice} -> ${price.buyerPrice}`
                                }`}
                            />
                        ) }
                    </div>
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                    { props.summaryAppendix }
                </div>
            </div>

            <span/>

            <div>
                {/* TODO: Render extensions conditionally */}
                <CardExtensionsDisplay card={card}/>

                <TransitionGroup style={{

                }} children={ state.expanded && (
                    <Collapse>

                        <div style={{
                            paddingTop: 8,
                            paddingBottom: 8,
                            display: "flex",
                            flexDirection: "column",
                            gap: 4,
                            overflowY: "scroll"
                        }}>
                            { (card.description === undefined || card.description.length === 0) && (
                                <DescriptiveTypography text={"No description"}/>
                            ) }

                            { card.description?.map(descComp => (
                                descComp.type === "string" && (<DescriptiveTypography text={descComp.payload}/>) ||
                                <DescriptiveTypography text={`No description component renderer for type "${descComp.type}"`}/>
                            ))}

                            <FormDivider paddingVertical={4}/>

                            <div style={{ display: "grid", gridTemplateColumns: "auto min-content", gap: 4 }}>
                                <div style={{ display: "flex", alignItems: "center", gap: 2, overflowX: "scroll" }}>
                                    {
                                        Array.from(card.types.values()).map(t => (
                                            (t === Types.CardType.PURCHASABLE && price) && (
                                                <ColorableTag
                                                    key={t}
                                                    c={Color.ofHex("#ffdf60")}
                                                    tag={`${
                                                        price.genericPrice === price.buyerPrice
                                                            ? price.genericPrice
                                                            : `${price.genericPrice} -> ${price.buyerPrice}`
                                                    }`}
                                                />
                                            ) ||
                                            <Tag key={t} tag={t}/>
                                        ))
                                    }
                                </div>

                                <div style={{ display: "flex", alignItems: "start", gap: 4 }}>
                                    {/*
                                    <Tag tag={state.showMoreInfo ? "LESS" : "MORE"} onClick={() => {
                                        setState(prevState => ({ ...prevState, showMoreInfo: !prevState.showMoreInfo }));
                                    }}/>
                                    */}

                                    <Tag tag={<MoreHorizRounded style={{
                                        color: triton().col("fg_muted"),
                                        fontSize: 16
                                    }}/>} onClick={() => {
                                        setState(prevState => ({ ...prevState, showMoreInfo: !prevState.showMoreInfo }));
                                    }}/>

                                    <ButtonModalCompound
                                        borderless
                                        button={<Tag tag={
                                            <FullscreenRounded style={{
                                                color: triton().col("fg_muted"),
                                                fontSize: 16
                                            }}/>
                                        }/>}
                                        modalContent={ctx => (
                                            <Workspace
                                                config={{ mode: "desktop", name: "card-display-fullscreen" }}
                                                style={{
                                                    borderRadius: 0,
                                                    height: "100vh",
                                                    width: "100vw",
                                                    overflowY: "scroll"
                                                }}
                                            >
                                                <CardDisplay {...props} summaryAppendix={
                                                    <FlatIconButton
                                                        onClick={() => ctx.close()}
                                                        children={<CloseFullscreenRounded fontSize={"small"}/>}
                                                    />
                                                }/>
                                            </Workspace>
                                        )}
                                    />
                                </div>
                            </div>

                            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 4 }}>

                                { price && (
                                    <CardInfoComponent
                                        icon={<TollRounded/>}
                                        info={`Price: ${price.genericPrice === 0 ? "free" : price.genericPrice}`}
                                    />
                                ) }

                                { hasValue && purchasingPower && (
                                    <CardInfoComponent
                                        icon={<TollRounded/>}
                                        info={`Purchasing power: ${purchasingPower.asCopper()}`}
                                    />
                                ) }

                                <CardInfoComponent
                                    icon={<TollRounded/>}
                                    info={`Value: ${level}`}
                                />
                            </div>

                            <UpgradeDisplay card={card}/>

                            <TransitionGroup children={state.showMoreInfo && (
                                <Collapse style={{
                                    // display: "grid",
                                    // gridTemplateRows: "min-content auto",
                                    // gap: 16,
                                    // overflowY: "scroll"
                                }}>
                                    <TabBar
                                        bold={false}
                                        equalSizeMode
                                        activeBar={state.tab}
                                        onTabChange={tab => setState(prevState => ({ ...prevState, tab: tab as any }))}
                                        tabs={[
                                            { id: "upgrades", title: "Upgrades" },
                                            { id: "extensions", title: "Extensions" },
                                            { id: "debug", title: "Debug" },
                                        ]}
                                    />

                                    <div style={{
                                        marginTop: 16,
                                        overflowY: "scroll"
                                    }}>
                                        { (state.tab === "upgrades") && isOwned && isUpgradable && (
                                            <>
                                                {/*
                                            <DescriptiveTypography noSelect text={"Upgrades"} style={{
                                                marginTop: 16,
                                                marginBottom: 16,
                                                textTransform: "uppercase",
                                                textDecoration: "underline"
                                            }}/>
                                            */}

                                                { card.traits[CardType.UPGRADABLE]!.upgrades.map(upgrade => {
                                                    const upgradeBill = upgrade.bill(upgrade, card as Card);
                                                    const upgradeInfo = card.getUpgradeInfo(upgrade);
                                                    const canUpgradeBeUsed = upgradeInfo.useAmount < upgrade.config.maxUses;
                                                    const canUpgradeBeUsedRightNow = canUpgradeBeUsed && viewer.turn?.phase?.data.type === DominionV1.TurnPhase.Types.ACTION_PHASE;

                                                    return (
                                                        <div key={upgrade.id} style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: 4
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: 4
                                                            }}>
                                                                <FormDivider title={upgrade.displayName} bold/>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: 4
                                                                }}>
                                                                    <Tag hideBackgroundOnDefault onClick={async () => {
                                                                        if (!canUpgradeBeUsedRightNow) return;
                                                                        await card.upgrade(upgrade);
                                                                    }} tag={
                                                                        <div style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            gap: 4
                                                                        }}>
                                                                            <DescriptiveTypography text={"upgrade"} noSelect style={{
                                                                                display: "inline-block",
                                                                                verticalAlign: "middle",
                                                                                lineHeight: "normal",
                                                                                textTransform: "uppercase",
                                                                                fontFamily: "monospace",
                                                                                fontSize: 12,
                                                                                color: canUpgradeBeUsedRightNow
                                                                                    ? triton().col("fg_default")
                                                                                    : triton().col("fg_muted")
                                                                            }}/>
                                                                            <AutoGraphRounded style={{
                                                                                fontSize: 14,
                                                                                color: canUpgradeBeUsedRightNow
                                                                                    ? triton().col("fg_default")
                                                                                    : triton().col("fg_muted")
                                                                            }}/>
                                                                        </div>
                                                                    }/>
                                                                </div>
                                                            </div>

                                                            { (upgrade.description === undefined || upgrade.description.length === 0) && (
                                                                <DescriptiveTypography text={"No description"}/>
                                                            ) }

                                                            { upgrade.description?.map(descComp => (
                                                                descComp.type === "string" && (<DescriptiveTypography text={descComp.payload}/>) ||
                                                                <DescriptiveTypography text={`No description component renderer for type "${descComp.type}"`}/>
                                                            ))}

                                                            {/*
                                                        <FormDivider paddingVertical={4}/>
                                                        */}

                                                            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 4 }}>
                                                                <CardInfoComponent
                                                                    icon={<TollRounded/>}
                                                                    info={`Price: ${upgradeBill.moneyRequired}`}
                                                                />

                                                                <CardInfoComponent
                                                                    icon={<NumbersRounded/>}
                                                                    info={`Used: ${upgradeInfo.useAmount}/${upgrade.config.maxUses} times`}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                }) }
                                            </>
                                        ) }

                                        { (state.tab === "extensions") && isOwned && (
                                            <CardExtensionsShopDisplay card={card}/>
                                        ) }

                                        { (state.tab === "debug") && isOwned && isUpgradable && (
                                            <>
                                                {/*
                                            <DescriptiveTypography noSelect text={"Debug"} style={{
                                                marginTop: 16,
                                                marginBottom: 16,
                                                textTransform: "uppercase",
                                                textDecoration: "underline"
                                            }}/>
                                            */}

                                                <ReactJson
                                                    src={card}
                                                    theme={"grayscale"}
                                                    enableClipboard={false}
                                                    collapsed
                                                    style={{
                                                        background: "transparent"
                                                    }}
                                                />
                                            </>
                                        ) }
                                    </div>
                                </Collapse>
                            )}/>
                        </div>
                    </Collapse>
                ) }/>
            </div>
        </div>
    );
}

const StyledUpgradeDisplay = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: min-content auto;
  align-items: center;
`;

export const UpgradeDisplay: FC<{
    card: ICard
}> = props => {
    const t = useTriton();
    const level = props.card.level;
    const maxLevels = 4;

    return (
        <StyledUpgradeDisplay>
            <div style={{
                position: "relative",
                display: "flex",
                aspectRatio: "1 / 1",
                height: 60,
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CircularProgress
                    variant={"determinate"}
                    // value={maxLevels / level * 1e2}
                    value={level / maxLevels * 1e2 * .75}
                    sx={{
                        zIndex: 2,
                        position: "absolute",
                        color: t.col("color_primary"),
                        transform: "rotate(135deg) !important"
                    }}
                />
                <CircularProgress
                    variant={"determinate"}
                    value={75}
                    sx={{
                        position: "absolute",
                        color: t.col("fg_muted"),
                        opacity: .2,
                        transform: "rotate(135deg) !important"
                    }}
                />
            </div>

            <div style={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gap: 4 }}>
                <CardInfoComponent
                    icon={<TollRounded/>}
                    info={`Lvl: ${level}`}
                />

                <CardInfoComponent
                    icon={<NumbersRounded/>}
                    info={`Max. lvl: ${maxLevels}`}
                />
            </div>
        </StyledUpgradeDisplay>
    );
}

export interface Filter {
    name: string,
    filter(cards: Array<FilteredCard>): Array<FilteredCard>
}

export type CardListRendererContext = {
    readonly originalIndex: number,
    readonly index: number,
    readonly array: Array<FilteredCard>
}

export type FilteredCard = {
    readonly card: ICard,
    readonly originalIndex: number,
}

export type CardListRendererState = {
    enableSortedView: boolean
}

export const CardListRenderer: FC<{
    cards: Array<ICard>,
    title: string,
    renderer?: (card: ICard, ctx: CardListRendererContext) => React.ReactNode,
    style?: CSSProperties
}> = props => {
    const game = useContext(GameContext);
    const viewer = game.activePlayer!;
    const [filters, setFilters] = useState<Array<Filter>>([]);

    const [state, stateCtx] = useStaticState<CardListRendererState>({
        id: "CardListRenderer",
        staticMode: true,
        initial: {
            enableSortedView: false
        }
    }).stateWithCtx

    const cards = props.cards.map((card, idx) => ({
        card: card,
        originalIndex: idx
    } as FilteredCard));

    const filteredCards = useMemo(() => {
        let filtered = cards;
        filters.forEach(filter => filtered = filter.filter(filtered));
        return filtered;
    }, [cards, filters]);

    const isFilterActive = (name: string) => {
        return filters.find(filter => filter.name === name) !== undefined;
    }

    const removeFilter = (name: string) => {
        setFilters(prevState => [...prevState.filter(filter => filter.name !== name)]);
    }

    const toggleFilter = (filter: Filter) => {
        if (isFilterActive(filter.name)) removeFilter(filter.name);
        else setFilters(prevState => [...prevState, filter]);
    }

    const sortedCards = useMemo(() => {
        const struct: { [K: string]: Array<FilteredCard> } = {};
        filteredCards.forEach(c => {
            const type = c.card.mainType ?? "";
            if (struct[type] === undefined) struct[type] = [c]
            else struct[type].push(c)
        });
        return struct;
    }, [filteredCards]);

    useEffect(() => {
        filteredCards.sort((a, b) => (a.card.mainType ?? "") > (b.card.mainType ?? "") ? 1 : -1)
    }, [filteredCards]);

    return (
        <Workspace
            config={{ mode: "desktop", name: "card-list-display" }}
            style={{ overflow: "hidden", height: "100%", overscrollBehavior: "none", ...props.style ?? {} }}
            children={
                <div style={{ display: "flex", overflow: "hidden", flexDirection: "column", gap: 4, height: "100%", maxHeight: "100%" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "min-content auto min-content", gap: 8 }}>
                        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                            <DescriptiveTypography text={props.title} noSelect/>
                            <Interpolate value={filteredCards.length} stepDelay={1e2}/>
                            { filteredCards.length === 0 && (
                                <Tag tag={"Nothing"} highlightOnHover={false}/>
                            ) }
                        </div>

                        <FormDivider/>

                        <div style={{ display: "flex", alignItems: "center", gap: 4 }}>

                            <Tag active={state.enableSortedView} tag={ctx =>
                                <SortByAlphaRounded style={{
                                    fontSize: 16,
                                    color: state.enableSortedView ?
                                        triton().col("color_primary") :
                                        triton().col("fg_muted")
                                }}/>
                            } onClick={() => {
                                stateCtx.reverseBool("enableSortedView")
                            }}/>

                            <Dot/>

                            <Tag active={isFilterActive("action")} tag={ctx =>
                                <ReceiptLongRounded style={{
                                    fontSize: 16,
                                    color: ctx.props.active ?
                                        triton().col("color_primary") :
                                        triton().col("fg_muted")
                                }}/>
                            } onClick={() => {
                                toggleFilter({
                                    name: "action",
                                    filter(cards: Array<FilteredCard>): Array<FilteredCard> {
                                        return cards.filter(card => card.card.types.has(CardType.ACTION))
                                    }
                                })
                            }}/>

                            <Tag active={isFilterActive("currency")} tag={ctx =>
                                <TollRounded style={{
                                    fontSize: 16,
                                    color: ctx.props.active ?
                                        triton().col("color_primary") :
                                        triton().col("fg_muted")
                                }}/>
                            } onClick={() => {
                                toggleFilter({
                                    name: "currency",
                                    filter(cards: Array<FilteredCard>): Array<FilteredCard> {
                                        return cards.filter(card => card.card.types.has(CardType.CURRENCY))
                                    }
                                })
                            }}/>
                        </div>
                    </div>
                    <div style={{ overflowY: "scroll" }}>

                        { !state.enableSortedView && (
                            <TransitionGroup>
                                { filteredCards.map((card, idx, array) => (
                                    <Collapse key={idx}>
                                        { props.renderer?.(card.card, {
                                            index: idx,
                                            array: array,
                                            originalIndex: card.originalIndex
                                        }) ?? (
                                            <div children={<CardDisplay card={card.card} viewer={viewer}/>} style={{
                                                paddingTop: 4
                                            }}/>
                                        ) }
                                    </Collapse>
                                )) }
                            </TransitionGroup>
                        ) }

                        { state.enableSortedView && Object.entries(sortedCards).sort((a, b) => a[0] > b[0] ? 1 : -1).map(kv => (
                            <MenuGroup title={kv[0]} key={kv[0]}>
                                <TransitionGroup>
                                    { kv[1].map((card, idx, array) => (
                                        <Collapse key={idx}>
                                            { props.renderer?.(card.card, {
                                                index: idx,
                                                array: array,
                                                originalIndex: card.originalIndex
                                            }) ?? (
                                                <div children={<CardDisplay card={card.card} viewer={viewer}/>} style={{
                                                    paddingTop: 4
                                                }}/>
                                            ) }
                                        </Collapse>
                                    )) }
                                </TransitionGroup>
                            </MenuGroup>
                        )) }
                    </div>
                </div>
            }
        />
    );
}

export const CardExtensionsDisplay: FC<{
    card: ICard
}> = props => {
    const card = props.card;
    const manager = card.extensionManager;
    const extensions = manager.extensions;

    return (
        <div children={extensions.map(extension => {
            return (
                <CardExtensionDisplay
                    key={extension.id}
                    card={card}
                    extension={extension}
                />
            );
        })}/>
    );
}
export const DescriptionRenderer: FC<{
    description?: Types.DescriptorDescription
}> = props => {
    return (
        <div>
            { props.description?.map(descComp => (
                descComp.type === "string" && (<DescriptiveTypography text={descComp.payload}/>) ||
                <DescriptiveTypography text={`No description component renderer for type "${descComp.type}"`}/>
            ))}
        </div>
    );
}

export const StyledCardExtensionDisplay = styled.div`
  margin-top: 4px;
  
  .extension-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    justify-content: space-between;
    
    .left, .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }
  }
  
  .expansion-tray {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

export const CardExtensionDisplay: FC<{
    card: ICard,
    extension: IExtension
}> = props => {
    const card = props.card;
    const extension = props.extension;
    const game = useContext(GameContext);

    const [state, setState] = useState({
        expanded: false
    })

    return (
        <StyledCardExtensionDisplay>
            <div className={"extension-header"}>
                <div className={"left"}>
                    <FlatIconButton children={<ExtensionRounded/>} onClick={() => {
                        setState(prevState => ({ ...prevState, expanded: !prevState.expanded }));
                    }}/>
                    <DescriptiveTypography text={extension.descriptor.displayName ?? "-"} noSelect/>
                </div>

                <div className={"right"}>
                    { extension.actionManager.actions.map(action => {
                        const canActionBeUsedRightNow = action.canFire?.() ?? true;
                        const descriptor = action.descriptor;

                        return (
                            <DefaultButton
                                flatIconMode={!canActionBeUsedRightNow}
                                deactivated={!canActionBeUsedRightNow}
                                size={"small"}
                                // variant={canActionBeUsedRightNow ? "primary" : "default"}
                                onClick={async () => {
                                    if (!canActionBeUsedRightNow) return;
                                    await action.fire();

                                    // TODO: replace with better system... -> Make cards (in hand) their own GameComponent
                                    game.relay.fire("generic")
                                }}
                                children={
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 4
                                    }}>
                                        <DescriptiveTypography text={descriptor.displayName ?? "?"} noSelect style={{
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            lineHeight: "normal",
                                            textTransform: "uppercase",
                                            fontFamily: "monospace",
                                            color: canActionBeUsedRightNow ? triton().col("fg_default") : triton().col("fg_muted")
                                        }}/>
                                        {/*
                                        <AutoGraphRounded fontSize={"small"} style={{
                                            color: canActionBeUsedRightNow ? triton().col("fg_default") : triton().col("fg_muted")
                                        }}/>
                                        */}
                                    </div>
                                }
                            />
                        );
                    }) }

                    {/*
                    <FlatIconButton children={<ReceiptRounded/>}/>
                    */}
                </div>
            </div>

            <FieldsDisplay fieldManager={extension.fieldManager}/>

            <TransitionGroup children={state.expanded && (
                <Collapse>
                    <div className={"expansion-tray"}>
                        <DescriptionRenderer description={extension.descriptor.description}/>
                    </div>
                </Collapse>
            )}/>
        </StyledCardExtensionDisplay>
    );
}

export const CardExtensionsShopDisplay: FC<{
    card: ICard
}> = props => {
    const card = props.card;
    const owner = card.owner!;
    const canBuy = owner.turn?.phase.data.type === TurnPhase.Types.BUY_PHASE;
    const manager = card.extensionManager;
    const offers = manager.offers;
    const hasOffers = offers.length > 0;

    return (
        <>
            { hasOffers && (
                // <FormDivider paddingVertical={4} title={"Available extensions"}/>
                /*
                <DescriptiveTypography noSelect text={"Available extensions"} style={{
                    marginTop: 16,
                    marginBottom: 16,
                    textTransform: "uppercase",
                    textDecoration: "underline"
                }}/>
                */
                <></>
            ) }

            { offers.map(offer => {
                const bill = offer.createBill(owner);

                return (
                    <div key={offer.id} style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4
                    }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 4
                        }}>
                            <FormDivider title={offer.descriptor.displayName ?? "-"} bold/>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 4
                            }}>
                                <Tag hideBackgroundOnDefault onClick={async () => {
                                    await manager.buyExtension(offer);
                                }} tag={
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 4
                                    }}>
                                        <DescriptiveTypography text={"buy"} noSelect style={{
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            lineHeight: "normal",
                                            textTransform: "uppercase",
                                            fontFamily: "monospace",
                                            fontSize: 12,
                                            // color: triton().col("fg_muted")
                                            color: canBuy
                                                ? triton().col("fg_default")
                                                : triton().col("fg_muted")
                                        }}/>
                                        <AutoGraphRounded style={{
                                            fontSize: 14,
                                            // color: triton().col("fg_muted")
                                            color: canBuy
                                                ? triton().col("fg_default")
                                                : triton().col("fg_muted")
                                        }}/>
                                    </div>
                                }/>
                            </div>
                        </div>

                        <DescriptionRenderer description={offer.descriptor.description}/>

                        {/*
                        <FormDivider paddingVertical={4}/>
                        */}

                        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 4 }}>
                            <CardInfoComponent
                                icon={<TollRounded/>}
                                info={`Price: ${bill.moneyRequired}`}
                            />
                        </div>
                    </div>
                );
            }) }
        </>
    );
}

export const StyledCardInfoComponent = styled.div<{
    t: Triton
}>`
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  gap: 8px;

  .icon {
    height: 24px;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${p => p.t.col("fg_muted")};
      width: 16px;
    }
  }

  .info {
    overflow-x: scroll;
  }
`;

export const CardInfoComponent: FC<{
    icon?: ReactNode,
    info: ReactNode
}> = props => {
    const t = useTriton();

    return (
        <StyledCardInfoComponent t={t}>
            <div className={"icon"}>
                { props.icon }
            </div>
            <div className={"info"}>
                { (typeof props.info === "string" || typeof props.info === "number") ? (
                    <DescriptiveTypography text={props.info}/>
                ) : props.info}
            </div>
        </StyledCardInfoComponent>
    );
}

export const FieldsDisplay: FC<{
    fieldManager: FieldManager
}> = props => {
    const fields = props.fieldManager.fields;

    const x = () => {
        return x;
    };

    return (
        <div style={{
            marginTop: 4,
            overflowX: "scroll"
        }}>
            <div style={{
                display: "flex",
                gap: 4,
            }} children={fields.filter(field => field !== undefined).map(field => (
                <FieldRenderer key={field.name ?? v4()} field={field}/>
            ))}/>
        </div>
    );
}

export const FieldRenderer: FC<{
    field: IField<any>
}> = props => {
    const field = props.field;
    const value = field.value;

    return (
        <Tag
            tag={`${field.displayName}: ${value}`}
            highlightOnHover={false}
        />
    );
}
