import React from "react";
import styled from "styled-components";
import {Color} from "../../../../base/logic/style/Color";
import {ReactComponent as HexagonAvatarBedding} from "./avatar-shape.svg";
import {AvatarShape} from "./AvatarShape";
import {Text} from "../../../../triton/components/typography/Text";
import {ProfileField} from "./ProfileField";
import {FormDivider} from "../../../../triton/components/forms/FormDivider";
import {UserProfileContextData} from "./UserProfileContextData";
import {IconBadge} from "./IconBadge";
import {ActivityFieldCard} from "./ActivityFieldCard";
import {Circle} from "@mui/icons-material";
import {BackgroundMode} from "./BackgroundMode";
import {CommunityHighlightCard} from "./CommunityHighlightCard";

export const defaultMockupUserProfileProps: UserProfileProps = {
    primaryColor: Color.ofHex("#ffe082"),
    secondaryColor: Color.ofHex("#ffecb3"),
    backgroundColor: Color.ofHex("#6c2e35"),
    // bgSecondaryColor: Color.ofHex("#232428"),
    bgSecondaryColor: Color.ofHex("#242424"),
    // canvasOverlayColor: Color.ofHex("#111214"),
    // canvasOverlayColor: Color.ofHex("#000000").withAlpha(.73),
    canvasOverlayColor: Color.ofHex("#ffffff").withAlpha(.45),

    fontColor: Color.ofHex("#060607"),
    fontTextColor: Color.ofHex("#313338"),

    darkThemeFontColor: Color.ofHex("#f2f3f5"),
    darkThemeFontTextColor: Color.ofHex("#dbdee1"),

    size: 80,
    backgroundRotation: 45,
    darkThemed: true,
    borderSize: 4,
    bannerHeight: 120,
    bannerUrl: "https://media.tenor.com/M2RJCj2uckcAAAAC/kiznaiver-anime.gif",
    avatarUrl: "https://i0.wp.com/i.pinimg.com/originals/c1/93/ff/c193ff2e6f2775db2942c080afacd310.gif",
    backgroundUrl: "https://nextshark.b-cdn.net/wp-content/uploads/2018/01/001.gif?width=1536&auto_optimize=medium",
    avatarShape: AvatarShape.RECT,
    showActivity: true,
    aboutMe:
        "私の名前はアードです。ソフトウェア エンジニアであり、AI アートの愛好家です。" +
        "ここに何を書けばいいのか分かりません。 " +
        "何か食べ始めなければなりません、本当にお腹が空いてきました。🍱",
}

export const default2MockupUserProfileProps: UserProfileProps = {
    primaryColor: Color.ofHex("#ffe082"),
    secondaryColor: Color.ofHex("#ffecb3"),
    // backgroundColor: Color.ofHex("#ffecb3"),
    backgroundColor: Color.ofHex("#263238"),
    bgSecondaryColor: Color.ofHex("#fff8e1"),
    canvasOverlayColor: Color.black,

    fontColor: Color.ofHex("#060607"),
    fontTextColor: Color.ofHex("#313338"),

    darkThemeFontColor: Color.ofHex("#f2f3f5"),
    darkThemeFontTextColor: Color.ofHex("#dbdee1"),

    size: 80,
    backgroundRotation: 45,
    darkThemed: true,
    borderSize: 4,
    bannerHeight: 120,
    bannerUrl: "https://media.tenor.com/M2RJCj2uckcAAAAC/kiznaiver-anime.gif",
    avatarUrl: "https://i0.wp.com/i.pinimg.com/originals/c1/93/ff/c193ff2e6f2775db2942c080afacd310.gif",
    backgroundUrl: "https://nextshark.b-cdn.net/wp-content/uploads/2018/01/001.gif?width=1536&auto_optimize=medium",
    avatarShape: AvatarShape.CIRCLE,
    showActivity: true,

    backgroundMode: BackgroundMode.IMAGE,
    customStatus: "ソフトウェアエンジニア。^^ はい、それが私の代名詞です",
    aboutMe:
        "私の名前はアードです。ソフトウェア エンジニアであり、AI アートの愛好家です。" +
        "ここに何を書けばいいのか分かりません。 " +
        "何か食べ始めなければなりません、本当にお腹が空いてきました。🍱",
}

const StyledUserProfile = styled.div<UserProfileProps>`
  --padding: 16px;
  --gap: var(--padding);
  --main-bg-col: ${p => p.backgroundColor.css()};
  --secondary-bg-col: ${p => p.backgroundMode === BackgroundMode.GRADIENT ? p.bgSecondaryColor.css() : p.backgroundColor.css()};
  --avatar-outline-size: 6px;
  border-radius: 12px;
  // overflow: hidden;
  padding: ${p => p.borderSize}px;
  
  background: ${p => {
    switch (p.backgroundMode!) {
      case BackgroundMode.IMAGE: return `url(${p.backgroundUrl})`;
      default: return "linear-gradient(var(--main-bg-col), var(--secondary-bg-col))";
    }
  }};
  
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.24);
  font-family: "Whitney", sans-serif;
  color: ${p => p.darkThemed ? "white" : p.fontColor.css()};
  font-weight: bold;
  position: relative;
  z-index: 1;
  
  .ornament {
    position: absolute;
    top: -58px;
    left: -18px;
    z-index: -1;
    
    path {
      fill: var(--main-bg-col);
    }
  }
  
  .profile-body {
    width: 340px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
    background-color: ${p => p.darkThemed ? "rgba(0, 0, 0, .25)" : "rgba(255, 255, 255, .25)"};
  }  
  
  .banner {
    // width: 100%;
    // height: 60px;
    // background-color: ${p => p.primaryColor.css()};
    
    // background: center url("https://qph.cf2.quoracdn.net/main-qimg-82e925021ae0df9fc05e3bbb4ab8259b");
    background: center url("${p => p.bannerUrl}");
  }

  .avatar-wrapper {
    position: relative;

    .avatar-container {
      position: absolute;
      top: -4px;
      transform: translateY(-50%);
      left: calc(var(--padding) + var(--avatar-outline-size));
      border-radius: 500px;
    }
    
    .avatar {
      // background: center url("${p => p.avatarUrl}");
      // background-size: cover;
      width: ${p => p.size}px;
      height: ${p => p.size}px;
      aspect-ratio: 1 / 1;
      object-position: center;
      object-fit: cover;
    }
  }

  .header-tray {
    padding: 0 var(--gap);
    margin-bottom: var(--gap);
    width: 100%;
    height: 34px;
    padding-left: calc(var(--gap) + var(--padding) + 2 * var(--avatar-outline-size) + ${p => p.size}px);
    
    .header-space {
      // background-color: ${p => p.canvasOverlayColor.css()};
      background-color: ${p => p.darkThemed ? "rgba(0, 0, 0, .45)" : "rgba(255, 255, 255, .45)"};
      border-radius: 12px;
      height: 100%;
      margin-left: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: min-content;
      overflow-x: scroll;
      padding: 0 10px;
      
      .badges {
        display: flex;
        flex-direction: row;
        gap: 3px;
        align-items: center;
      }
    }
  }
  
  .main-tray {
    // background-color: ${p => p.canvasOverlayColor.css()};
    background-color: ${p => p.darkThemed ? "rgba(0, 0, 0, .45)" : "rgba(255, 255, 255, .45)"};
    
    border-radius: 12px;
    // height: 350px;
    padding: var(--gap);
    margin: var(--gap);
    margin-top: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    .profile-main-info {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
    
    .custom-status-wrapper {
      // margin-top: 12px;
    }
    
    .profile-fields {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      gap: 12px;
    }
  }
`;

export type UserProfileProps = {
    primaryColor: Color,
    secondaryColor: Color,
    backgroundColor: Color,
    bgSecondaryColor: Color,
    canvasOverlayColor: Color,
    size: number,
    backgroundRotation: number,
    fontColor: Color,
    fontTextColor: Color,
    darkThemeFontColor: Color,
    darkThemeFontTextColor: Color,
    darkThemed: boolean,
    borderSize: number,
    bannerHeight: number,
    bannerUrl: string | undefined,
    avatarUrl: string | undefined,
    backgroundUrl: string | undefined,
    avatarShape: AvatarShape,
    showActivity?: boolean,


    backgroundMode?: BackgroundMode,
    aboutMe?: string,
    customStatus?: string
}

export const UserProfile: React.FC<UserProfileProps> = props => {
    const avatarPaddingSizePx = 6;
    const padding = 16;
    const bannerHeight = props.bannerHeight;
    const showActivity = props.showActivity ?? true;
    // TODO: Remove
    const fontColor = props.darkThemed ? props.darkThemeFontColor : props.fontColor;

    const profileContext: UserProfileContextData = {
        mainProps: props,
        getFontColours: () => {
            const b = props.darkThemed;
            if (b) return {
                main: props.darkThemeFontColor,
                text: props.darkThemeFontTextColor
            }
            return {
                main: props.fontColor,
                text: props.fontTextColor
            }
        }
    };

    const colours = profileContext.getFontColours();

    return (
        <UserProfileContext.Provider value={profileContext} children={
            <StyledUserProfile className={"profile-wrapper"} {...props}>
                {/* <Ornament className={"ornament"}/> */}
                <div className={"profile-body"}>
                    <svg className="main-tray-banner-wrapper" viewBox={`0 0 340 ${bannerHeight}`} style={{ minWidth: 340, minHeight: bannerHeight }}>
                        <mask id="uid_1089">
                            <rect fill="white" x="0" y="0" width="100%" height="100%"></rect>

                            {(() => {
                                switch (props.avatarShape) {
                                    case AvatarShape.CIRCLE:
                                        return (
                                            <circle fill="black" cx={padding + avatarPaddingSizePx + props.size / 2} cy={bannerHeight - 4} r={6 + props.size / 2}/>
                                        );
                                    case AvatarShape.RECT:
                                        return (
                                            <rect
                                                fill="black"
                                                x={padding + avatarPaddingSizePx - avatarPaddingSizePx}
                                                y={bannerHeight - 4 - props.size / 2 - avatarPaddingSizePx}
                                                width={2 * avatarPaddingSizePx + props.size}
                                                height={2 * avatarPaddingSizePx + props.size}
                                                rx={22}
                                            />
                                        );
                                    case AvatarShape.HEXAGON:
                                        return (
                                            <g children={
                                                <HexagonAvatarBedding
                                                    width={100}
                                                    height={100}
                                                    x={padding - 4}
                                                    y={bannerHeight - 4 - 100 / 2}
                                                />
                                            }/>
                                        );
                                }
                            })()}
                        </mask>
                        <foreignObject x="0" y="0" width="100%" height="100%" overflow="visible" mask="url(#uid_1089)">
                            <div className="banner" style={{
                                // backgroundColor: props.primaryColor.css(),
                                height: bannerHeight,
                            }}></div>

                        </foreignObject>
                    </svg>

                    <div className={"avatar-wrapper"}>
                        <svg height="0" viewBox="0 0 200 188" width="0">
                            <defs>
                                <clipPath clipPathUnits="objectBoundingBox" id="shape-hex" transform="scale(0.005 0.005319148936170213)">
                                    <path d="M193.248 69.51C185.95 54.1634 177.44 39.4234 167.798 25.43L164.688 20.96C160.859 15.4049 155.841 10.7724 149.998 7.3994C144.155 4.02636 137.633 1.99743 130.908 1.46004L125.448 1.02004C108.508 -0.340012 91.4873 -0.340012 74.5479 1.02004L69.0879 1.46004C62.3625 1.99743 55.8413 4.02636 49.9981 7.3994C44.155 10.7724 39.1367 15.4049 35.3079 20.96L32.1979 25.47C22.5561 39.4634 14.0458 54.2034 6.74789 69.55L4.39789 74.49C1.50233 80.5829 0 87.2441 0 93.99C0 100.736 1.50233 107.397 4.39789 113.49L6.74789 118.43C14.0458 133.777 22.5561 148.517 32.1979 162.51L35.3079 167.02C39.1367 172.575 44.155 177.208 49.9981 180.581C55.8413 183.954 62.3625 185.983 69.0879 186.52L74.5479 186.96C91.4873 188.32 108.508 188.32 125.448 186.96L130.908 186.52C137.638 185.976 144.163 183.938 150.006 180.554C155.85 177.17 160.865 172.526 164.688 166.96L167.798 162.45C177.44 148.457 185.95 133.717 193.248 118.37L195.598 113.43C198.493 107.337 199.996 100.676 199.996 93.93C199.996 87.1841 198.493 80.5229 195.598 74.43L193.248 69.51Z"/>
                                </clipPath>
                            </defs>
                        </svg>

                        <svg height="0" viewBox="0 0 200 200" width="0">
                            <defs>
                                <clipPath clipPathUnits="objectBoundingBox" id="shape-circle" transform="scale(0.005 0.005)">
                                    <circle cx={100} cy={100} r={100} />
                                </clipPath>
                            </defs>
                        </svg>

                        <svg height="0" viewBox="0 0 200 200" width="0">
                            <defs>
                                <clipPath clipPathUnits="objectBoundingBox" id="shape-rect" transform="scale(0.005 0.005)">
                                    <rect x={0} y={0} width="200" height="200" rx="50" />
                                </clipPath>
                            </defs>
                        </svg>

                        <svg width={0} height={0} viewBox="0 0 80 80">
                            <defs>
                                <clipPath clipPathUnits="objectBoundingBox" id={"pol-avatar-clip"}>
                                    <path d="M6.000000000000002 45.033320996790806Q0 34.64101615137754 6.000000000000002 24.248711305964278L13.999999999999998 10.392304845413264Q20 0 32 0L48 0Q60 0 66 10.392304845413264L74 24.248711305964278Q80 34.64101615137754 74 45.033320996790806L66 58.88972745734182Q60 69.28203230275508 48 69.28203230275508L32 69.28203230275508Q20 69.28203230275508 13.999999999999998 58.88972745734182Z"/>
                                </clipPath>
                            </defs>
                        </svg>

                        <div className={"avatar-container"} style={{
                            width: props.size,
                            aspectRatio: "1 / 1",
                        }} children={
                            <img src={props.avatarUrl} className={"avatar"} width={80} height={80} style={{
                                clipPath: `url(#${(() => {
                                    switch (props.avatarShape) {
                                        case AvatarShape.CIRCLE:
                                            return "shape-circle";
                                        case AvatarShape.RECT:
                                            return "shape-rect";
                                        case AvatarShape.HEXAGON:
                                            return "shape-hex";
                                    }
                                })()})`,
                                // clipPath: "url(#pol-avatar-clip)",
                            }}/>
                        }/>
                    </div>

                    <div className={"header-tray"}>
                        <div className={"header-space"}>
                            <div className={"badges"}>
                                <IconBadge children={"🔰"}/>
                                <IconBadge children={"🔱"}/>
                                <IconBadge iconSizeOffset={-4} children={
                                    <img src={require("./discordpartner-badge.png")}/>
                                }/>
                            </div>
                        </div>
                    </div>

                    <div className={"main-tray"}>

                        <div className={"profile-main-info"}>
                            <Text fw={"bold"} fs={20} col={fontColor} t={"Ard「アード」"}/>
                            <Text fs={14} col={fontColor} ff={"Whitney Semibold"} t={"ard_works"}/>
                            <Text fs={14} col={colours.text} ff={"Whitney Light"} t={"Artist & Software Engineer"}/>
                        </div>

                        <div className={"custom-status-wrapper"}>
                            <Text ff={"Whitney Light"} fs={12} col={colours.text}>
                                🔰
                                <span style={{
                                    margin: "0 4px",
                                    position: "relative"
                                }} children={
                                    <Circle sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translateY(-50%) translateX(-50%)",
                                        fontSize: 4,
                                        color: "rgb(65, 67, 74)"
                                    }}/>
                                }/>
                                { props.customStatus }
                            </Text>
                        </div>

                        <FormDivider/>

                        <div className={"profile-fields"}>

                            { props.aboutMe !== undefined && props.aboutMe.trim().length > 0 && (
                                <ProfileField title={"About me"} children={
                                    <Text ff={"Whitney Light"} fs={12} col={colours.text} t={props.aboutMe}/>
                                }/>
                            ) }

                            <ProfileField title={"Ardspace member since"} children={
                                <Text ff={"Whitney Light"} fs={12} col={colours.text} t={"Aug 14, 2023"}/>
                            }/>

                            { showActivity && (
                                <ProfileField title={"Activity"} children={
                                    <ActivityFieldCard activity={{
                                        name: "WebStorm - 2022.3",
                                        details: "Working on ardspace.ai",
                                        state: "Editing UserProfile.tsx",
                                        startTimestampUnix: Date.now() - 6e5,
                                    }}/>
                                }/>
                            ) }
                        </div>
                    </div>


                    <div className={"community-tray main-tray"}>
                        <CommunityHighlightCard communityHighlight={{
                            name: "Ardspace",
                            iconUrl: "https://cdn.discordapp.com/avatars/1083071731427717120/8e9b2fe477b17509776e1f8446962bf0.png",
                            memberCount: 138
                        }}/>
                    </div>
                </div>
            </StyledUserProfile>
        }/>
    );
}

export const UserProfileContext = React.createContext<UserProfileContextData>(undefined as any);
