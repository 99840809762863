import {FC, useEffect} from "react";
import {Pin, PinMode} from "../backend/Pin";
import {useForceRenderFunc} from "../../ForceRerenderHook";
import styled from "styled-components";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {useStaticState} from "../../ardai/hooks/StaticStateHook";
import {useTriton} from "../../triton/TritonHooks";
import {Triton} from "../../triton/Triton";
import {getPinHtmlID, getPinWireHtmlID} from "./NodeFrontendUtils";
import Draggable from "react-draggable";
import {useNodeCanvasBackend} from "./NodeCanvasBackend";
import {NodePinExplorer} from "./NodePinExplorer";
import {PinInfoDisplay} from "./PinInfoDisplay";
import {ClickAwayListener} from "@mui/base";

const StyledNodeOutPinDisplay = styled.div<{
    t: Triton,
    p: NodeOutPinDisplayProps,
    s: NodeOutPinDisplayState
}>`
  // order: ${p => p.p.position === "left" ? -1 : 1};
  direction: ${p => p.p.position === "left" ? "rtl" : "ltr"};
  // grid-auto-flow: dense;
  
  position: relative;
  min-height: 18px;
  
  z-index: 10;
  background-color: transparent;
  transition: background-color .1s;
  margin-right: ${p => p.p.position === "left" ? 0 : "calc(-5px)"};
  margin-left: ${p => p.p.position !== "left" ? 0 : "calc(-5px)"};
  cursor: pointer;  
  display: grid;
  grid-template-columns: 1fr min-content;
  // grid-template-rows: repeat(2, 1fr);
  column-gap: 8px;
  row-gap: 2px;
  align-items: center;
  
  &:before {
    --bleed-h: 6px;
    --bleed-v: 0px;
    pointer-events: none;
    content: '';
    width: calc(100% + var(--bleed-h));
    height: calc(100% + var(--bleed-v));
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: center;
    transform: translateY(-50%) translateX(-50%);
    
    border-radius: 6px;
  }
  
  &[data-pin-selected=true] {
    &:before {
      background-color: ${p => p.p.pin.config.dye ?? p.t.col("color_primary")};
      opacity: .35;
      // background-color: ${p => p.t.colObj("color_primary").withAlpha(.5).css()};
    }
  }

  &[data-pin-selected=false] {
    &:hover {
      &:before {
        // background-color: ${p => p.t.colObj("color_primary").withAlpha(.19).css()};
        background-color: ${p => p.p.pin.config.dye ?? p.t.col("color_primary")};
        opacity: .19;
      }
    }
  }
  
  .pin-visualization {
    position: relative;
    z-index: 200;
    aspect-ratio: 1 / 1;
    width: 10px;
    
    border-radius: 50%;
    
    border: 1px solid ${p => p.s.active ? p.t.col("color_primary") : p.t.col("fg_muted")};
    background-color: ${p => p.s.active ? p.t.colObj("color_primary").withAlpha(.1).css() : p.t.colObj("fg_muted").withAlpha(.1).css()};

    &[data-has-error=true] {
      border-color: crimson;
    }
    
    &.soft-highlight {
      &:before {
        --transparent-gap: 5px;
        --thickness: 1.5px;
        content: '';
        border-radius: 50%;
        background-color: transparent;
        aspect-ratio: 1 / 1;
        position: absolute;
        transform-origin: center;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: calc(100% + var(--transparent-gap));

        box-shadow: 0 0 0 var(--thickness) ${p => p.t.colObj("color_secondary").withAlpha(1).css()};
      }
    }
    
    &[data-is-connected=false] {
      border-style: dashed;
    }
  }
  
  .pin-info-display-wrapper {
    --info-display-to-pin-gap: calc(16px);
    position: absolute;
    z-index: 2000;
    
    &[data-alignment="right"] {
      left: calc(100% + var(--info-display-to-pin-gap));
    }

    &[data-alignment="left"] {
      right: calc(100% + var(--info-display-to-pin-gap));
    }
  }
`;

export type NodeOutPinDisplayProps = {
    position: "top" | "right" | "down" | "left"
    pin: Pin,
    showLabel?: boolean
}

export type NodeOutPinDisplayState = {
    selected: boolean,
    softSelected: boolean,
    active: boolean
}

export const NodePinDisplay: FC<NodeOutPinDisplayProps> = props => {
    const backend = useNodeCanvasBackend();
    const activeCooldown = 2e2;

    const t = useTriton();
    const pin = props.pin;
    const pinDye = pin.config.dye;
    const isPinConnected = pin.outputConnections.length > 0;

    const forceRender = useForceRenderFunc();

    const [state, ctx] = useStaticState<NodeOutPinDisplayState>({
        id: "NodeOutPinDisplay-local",
        staticMode: false,
        initial: {
            selected: false,
            softSelected: false,
            active: false
        }
    }).stateWithCtx;

    const mayPlayFlickeringAnimation = (): boolean => {
        const reducedMotion = false; // TODO: Introduce a var in the backend state for this
        const enableFlickering = backend.state.ui.canvas.wiring.enableFlickering;
        return !reducedMotion && !enableFlickering;
    }

    useEffect(() => pin.observer.observeGlobally().on(() => {
        if (mayPlayFlickeringAnimation()) return;

        // FIXME: This causes the port to flicker, even if no data was pushed (*1)
        ctx.update({
            active: true
        });

        forceRender();

        pin.outputConnections.forEach(targetPin => {
            document.getElementById(getPinWireHtmlID(pin, targetPin))
                ?.setAttribute("data-wire-is-signalling", String(true))
        });

        setTimeout(() => {
            // FIXME: (*1)
            ctx.update({
                active: false
            });

            pin.outputConnections.forEach(targetPin => {
                document.getElementById(getPinWireHtmlID(pin, targetPin))
                    ?.setAttribute("data-wire-is-signalling", String(false))
            });
        }, activeCooldown);
    }).destructor, []);

    const canExplorePorts = pin.mode === PinMode.OUT;
    const portClass = "port";

    const isSoftSelected = state.softSelected;
    const isShowingLabel = props.showLabel ?? true;

    const status = pin.statusRegister;
    const statusDefaults = status.defaults;
    const isPinStatusDeactivated = statusDefaults.isDeactivated;
    const isPinStatusError = statusDefaults.isError;

    return (
        <StyledNodeOutPinDisplay
            data-pin-selected={state.selected}
            data-pin-soft-selected={isSoftSelected}
            t={t}
            p={props}
            s={state}
            onMouseEnter={() => {
                try {
                    pin.outputConnections.forEach(targetPin => {
                        document.getElementById(`${getPinHtmlID(targetPin)}-port`)!.classList.add("soft-highlight");
                    })

                    pin.outputConnections.forEach(targetPin => {
                        document.getElementById(getPinWireHtmlID(pin, targetPin))!.setAttribute("data-wire-is-highlighted", String(true));
                    })
                } catch (e) {}
            }}
            onMouseLeave={() => {
                try {
                    pin.outputConnections.forEach(targetPin => {
                        document.getElementById(`${getPinHtmlID(targetPin)}-port`)!.classList.remove("soft-highlight");
                    })
                    pin.outputConnections.forEach(targetPin => {
                        document.getElementById(getPinWireHtmlID(pin, targetPin))!.removeAttribute("data-wire-is-highlighted");
                    })
                } catch (e) {}
            }}
            onClick={e => {
                if (e.ctrlKey) {
                    ctx.reverseBool("selected");
                } else {
                    ctx.reverseBool("softSelected");
                }
                e.stopPropagation();
            }}
        >
            {/*
            { props.position === "right" ? (
                <DescriptiveTypography text={`(${pin.lastWriteState ?? " "}) ${pin.config.label}`}/>
            ) : (
                <DescriptiveTypography text={`${pin.config.label} (${pin.lastReadState ?? " "})`}/>
            )}
            */}

            { isShowingLabel && (
                <DescriptiveTypography
                    text={`${pin.config.label}`}
                    noSelect
                    style={{
                        opacity: isPinStatusDeactivated ? .3 : 1,
                        color: pinDye ?? t.col("fg_muted")
                    }}
                />
            ) || <span/> }

            <div
                id={`${getPinHtmlID(pin)}-port`}
                className={`pin-visualization ${portClass}`}
                data-node-id={pin.node.id}
                data-pin-id={pin.config.id}
                data-has-error={isPinStatusError}
                data-is-connected={isPinConnected}
                children={ canExplorePorts && (
                    <NodePinExplorer pin={pin}/>
                ) }
            />

            { state.softSelected && (
                <ClickAwayListener disableReactTree={true} onClickAway={() => ctx.reverseBool("softSelected")}>
                    <div className={"pin-info-display-wrapper"} data-alignment={props.position} children={
                        <PinInfoDisplay pin={pin}/>
                    }/>
                </ClickAwayListener>
            ) }
        </StyledNodeOutPinDisplay>
    );
}
