import {FC} from "react";
import {useStaticState} from "../../hooks/StaticStateHook";
import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../../webapi/WebAPIDB";
import styled from "styled-components";
import {ISADBImageGrid} from "../../components/ISADBImageGrid";
import {ControlledBooleanInput} from "../../components/ControlledBooleanInput";
import {BasicSingleSelect} from "../../../triton/components/forms/BasicSingleSelect";
import {Image} from "../../data/Image";
import {MenuGroup} from "../../components/MenuGroup";

const StyledSingleImageSelectorView = styled.div`
  display: grid;
  gap: 8px;
  grid-template-rows: min-content auto;
  overflow: hidden;
  
  .output-container {
    overflow-y: scroll;
  }
`;

export type SingleImageSelectorViewProps = {
    onSelect?: (i: Image) => void
}

export const SingleImageSelectorView: FC<SingleImageSelectorViewProps> = props => {

    const [params, paramsCtx] = useStaticState<SingleImageSelectorViewSearchParameters>({
        id: "SingleImageSelectorView-SearchParameters",
        staticMode: false,
        initial: {
            isFavourite: Tristate.ANY
        }
    }).stateWithCtx;

    function tristatePredicate<T>(obj: T, tri: Tristate, block: (b: boolean, obj: T) => boolean): boolean {
        if (tri === Tristate.ANY) return true;
        const isTrue = tri === Tristate.TRUE;
        return block(isTrue, obj);
    }

    class FalseLatch {
        public state: boolean = true;
        public update(b: boolean): this {
            if (!b) this.state = false;
            return this;
        }
    }

    const filteredImages = useLiveQuery(() => {
        return webDB.images.filter(obj => new FalseLatch()
            .update(tristatePredicate(obj, params.isFavourite, (b, i) => i.favourite === b))
            .state
        ).toArray();
    }, [params]);

    function selectImage(img: Image) {
        props.onSelect?.(img);
    }

    return (
        <StyledSingleImageSelectorView>

            <MenuGroup title={"Filters"}>
                <TristateFilterSelector
                    title={"Favourite"}
                    state={params.isFavourite}
                    onUpdate={tri => paramsCtx.update({
                        isFavourite: tri
                    })}
                />
            </MenuGroup>

            <div className={"output-container"}>
                <ISADBImageGrid preferPreviewImage imageIDs={filteredImages ? filteredImages.map(i => i.id) : []} imageRenderer={(data, ctx) => (
                    <img
                        src={URL.createObjectURL(data.data)}
                        onClick={() => selectImage(data)}
                        alt={data.id}
                        style={{
                            objectFit: "cover",
                            maxWidth: "100%",
                            overflow: "hidden",
                            borderRadius: 8,
                            cursor: "pointer"
                        }}
                    />
                )}/>
            </div>


        </StyledSingleImageSelectorView>
    );
}

const TristateFilterSelector: FC<{
    title: string,
    state: Tristate,
    onUpdate: (tri: Tristate) => void
}> = props => {
    return (
        <BasicSingleSelect
            title={props.title}
            disableSearchbar
            name={props.title}
            onSelect={v => props.onUpdate(v as any)}
            selected={props.state}
            options={[
                { id: Tristate.TRUE, text: "Yes" },
                { id: Tristate.FALSE, text: "No" },
                { id: Tristate.ANY, text: "Ignore" },
            ]}
        />
    );
}

export type SingleImageSelectorViewSearchParameters = {
    isFavourite: Tristate
}

enum Tristate {
    TRUE = "TRUE", FALSE = "FALSE", ANY = "ANY"
}
