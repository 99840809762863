import React, {PropsWithChildren} from "react";
import {Color} from "../../../base/logic/style/Color";
import {Text} from "../../../triton/components/typography/Text";

export type BadgeProps = PropsWithChildren<{
    color?: Color
}>

export const Badge: React.FC<BadgeProps> = props => {
    const bg = props.color ?? Color.black.withAlpha(.8)
    const isText = typeof props.children === "string";

    function renderChildren() {
        if (!isText) return props.children;
        return (
            <Text t={props.children} col={Color.white} fw={"bold"} fs={13} style={{
                textTransform: "uppercase"
            }}/>
        )
    }

    return (
        <div children={renderChildren()} style={{
            display: "flex",
            width: "min-content",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 6,
            padding: "2px 6px",
            borderRadius: 4,
            backgroundColor: bg.css()
        }}/>
    );
}
