import {FC} from "react";
import {TypographyProps} from "./TypographyProps";
import {useTriton} from "../../../../triton/TritonHooks";
import {Typography} from "./Typography";

export const LinkDisplayTypography: FC<TypographyProps<{
    highlightColor?: string
}>> = props => {
    const t = useTriton();
    const fontSize = props.fontSize ?? 24;
    const inActiveLineDecoHeight = 2;
    const highlightColor = props.highlightColor ?? t.col("color_secondary");

    return (
        <Typography {...props} s={{
            position: "relative",
            fontFamily: "'Nebula', 'JetBrains Mono', monospace",
            fontWeight: "lighter",
            whiteSpace: "nowrap",
            userSelect: "none",
            cursor: "pointer",
            lineHeight: typeof fontSize === "number" ? `${fontSize + 6}px` : "normal",
            fontSize: fontSize,
            color: t.col("fg_display_primary"),
            ...(props.s ?? {})
        }} sx={{
            "&:before": {
                zIndex: -1,
                content: "''",
                width: "100%",
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                transition: "all .1s",
                backgroundColor: highlightColor,
                height: `${inActiveLineDecoHeight}px`
            },
            "&:hover:before": {
                height: "100%",
            },
            "&:active:before": {
                // backgroundColor: "crimson",
                // width: "calc(100% + 8px)",
                // height: "calc(100% + 8px)",
                // bottom: -4,
                filter: "brightness(1.1)"
            }
        }}/>
    );
}
