import React, {useContext} from "react";
import {LogEvent} from "./LogEvent";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {Menu} from "../../components/Menu";
import {MenuButton} from "../../components/MenuButton";
import {LogLevelIndicator} from "./LogLevelIndicator";
import {LogLevel} from "./LogLevel";
import {DerbyshireStateContext} from "./DerbyshireMain";

const StyledConsoleLogRenderer = styled.div`
  padding-left: 8px;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: auto min-content;
  user-select: none;
  overflow: hidden;
  gap: 8px;
  transition: all 100ms;
  border-radius: 8px;
  cursor: pointer;
  
  &:hover {
    background-color: #ffffff05;
  }

  &:active {
    filter: brightness(1.1);
  }
  
  .log-main {
    width: calc(100% - 0px);
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  
  &:hover .log-appendix, &:focus-within .log-appendix {
    opacity: 1;
  }
  
  .log-appendix {
    opacity: 0;
    transition: opacity 100ms;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }
`;

export type ConsoleLogRendererProps = {
    log: LogEvent,
}

export const ConsoleLogRenderer: React.FC<ConsoleLogRendererProps> = props => {
    const [state, setState] = useContext(DerbyshireStateContext);

    return (
        <StyledConsoleLogRenderer onClick={event => {
            setState(prevState => ({
                ...prevState,
                openLogEntry: props.log
            }));
        }}>
            <div className={"log-main"}>
                <LogLevelIndicator level={props.log.level ?? LogLevel.INFO}/>

                <DescriptiveTypography text={props.log.timestamp} style={{
                    whiteSpace: "nowrap"
                }}/>

                <DescriptiveTypography text={String(props.log.message)} style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "calc(100% - 0px)"
                }}/>
            </div>
            <div className={"log-appendix"}>
                <Menu>
                    <MenuButton text={"Open"}/>
                </Menu>
            </div>
        </StyledConsoleLogRenderer>
    );
}
