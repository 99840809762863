import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {Pin} from "../../../backend/Pin";
import _ from "lodash";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";

export const AutoSizeDisplay: NodeSetupInfo = {
    label: "AutoSizeDisplay",
    classname: "output.display.auto-size-display",
    parameterConfig: [],
    factory: parameters => new Node<{
        lines: any[]
    }>({
        id: v4(),
        classname: "output.display.auto-size-display",
        label: "display",
        state: {
            lines: []
        },
        init() {
            const node = this;

            const lines = this.pins.createPinBank("lines", {
                label: "lines",
                dynamicSizeFlag: true,
                hideLabelFlag: true
            })

            let idx = 0;
            const createNewChannel = () => lines.pin(`${idx++}`);

            lines.attach({
                onPinRead(pin: Pin, value: any) {

                },

                onPinNewInboundConnection(thisPin: Pin, fromPin: Pin) {
                    createNewChannel();
                }
            });

            createNewChannel();
        },
        customRenderer: (node) => (
            <div style={{ minWidth: "min-content" }}>
                {
                    Array.from(node.pins.pinBanks.get("lines")!.pins.values()).map(pin => {
                        return (
                            <DescriptiveTypography
                                text={_.padStart(String(pin.lastReadState ?? "").slice(0, 20), 20, " ")}
                                style={{ whiteSpace: "pre" }}
                            />
                        );
                    })
                }
            </div>
        )
    })
}
