import {ReactNode} from "react";
import styled from "styled-components";
import {useTriton} from "../../../../triton/TritonHooks";
import {Triton} from "../../../../triton/Triton";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";

const StyledDataManipulationDisplayWrapper = styled.div<{
    t: Triton
}>`
  overflow: hidden;
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: min-content auto;
  gap: 8px;
  
  .descriptor {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    
    .descriptor-icon {      
      width: 16px;
      aspect-ratio: 1 / 1;
      display: flex;
      justify-content: center;
      align-items: center;
      
      svg {
        font-size: 18px;
        color: ${p => p.t.col("fg_muted")}
      }
    }
    
    .state-object-key-display {
      font-family: "JetBrains Mono", monospace;
      font-size: 12px;
    }
  }
  
  input {
    width: 100%;
    overflow: hidden;
    font-family: "JetBrains Mono", monospace;
    font-size: 12px;
  }
`;

export interface IDataManipulationDisplayWrapperContext {}

export function DataManipulationDisplayWrapper<T>(props: {
    label: string,
    icon?: ReactNode,
    children: (ctx: IDataManipulationDisplayWrapperContext) => ReactNode
}) {
    const t = useTriton();

    const newContext = (): IDataManipulationDisplayWrapperContext => ({

    });

    return (
        <StyledDataManipulationDisplayWrapper t={t}>
            <div className={"descriptor"}>
                { props.icon && (
                    <div className={"descriptor-icon"} children={props.icon}/>
                ) }
                <DescriptiveTypography text={props.label} style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }} baseProps={{ className: "state-object-key-display" }}/>
            </div>
            { props.children(newContext) }
        </StyledDataManipulationDisplayWrapper>
    );
}

