import React from "react";
import styled from "styled-components";
import {CopyrightNotice} from "./CopyrightNotice";
import {BrandedIconLinkButton} from "./BrandedIconLinkButton";
import {
    AirplaneTicketRounded,
    Airplay, ArrowLeftRounded,
    CommentBankRounded, CommentRounded, CommitRounded,
    GitHub,
    Google, HttpRounded, IosShare,
    LinkedIn,
    MailRounded, ShareRounded,
    TagRounded,
    Twitter,
    YouTube
} from "@mui/icons-material";
import {ReactComponent as Discord} from "../../../../../assets/discord/Discord-Logo-White.svg";
import {Link} from "./Link";
import {ListItem} from "../../../_/ListItem";
import {useNavigate} from "react-router-dom";

const StyledScreenSaverLeftWidget = styled.div`
  width: 100%;
  height: 250px;
  padding: 8px;
  // background-color: rgb(16, 16, 22);
  // border-radius: 8px;
  display: grid;
  grid-template-rows: auto min-content;
  gap: 8px;

  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border:1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  
  .saver-left-widget-header-container {
    .saver-left-widget-header {
      
    }
  }
  
  .saver-left-widget-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .branded-links-list, .links-list {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    
    .branded-links-list {
      gap: 8px;
      margin-bottom: 8px;
    }
  }
`;

/**
 * TODO: Introduce centralized social link maps
 *
 * @param props
 * @constructor
 */
export const ScreenSaverLeftWidget: React.FC = props => {
    const navigate = useNavigate();

    return (
        <StyledScreenSaverLeftWidget>
            <div className={"saver-left-widget-header-container"} style={{overflow: "scroll"}}>
                <div className={"saver-left-widget-header"}>

                    <ListItem title={"Give feedback"} icon={
                        <CommentRounded sx={{ fontSize: 20 }}/>
                    }/>
                    <ListItem title={"Create support ticket"} icon={
                        <AirplaneTicketRounded sx={{ fontSize: 20 }}/>
                    }/>
                    <ListItem title={"HyperLink™"} icon={
                        <HttpRounded sx={{ fontSize: 20 }}/>
                    }/>
                </div>
            </div>

            <div className={"saver-left-widget-footer"}>
                <CopyrightNotice/>
                <div className={"branded-links-list"}>
                    <BrandedIconLinkButton href={"#"} children={<GitHub/>}/>
                    <BrandedIconLinkButton href={"#"} children={<Discord/>}/>
                    <BrandedIconLinkButton href={"#"} children={<Twitter/>}/>
                    <BrandedIconLinkButton href={"#"} children={<LinkedIn/>}/>
                    <BrandedIconLinkButton href={"mailto:#"} children={<MailRounded/>}/>
                </div>
                <div className={"links-list"}>
                    <Link children={"About"}/>
                    <Link children={"Blog"}/>
                    <Link children={"Terms"}/>
                    <Link children={"Privacy"}/>
                    <Link children={"Security"}/>
                    <Link children={"Status"}/>
                </div>
            </div>
        </StyledScreenSaverLeftWidget>
    );
}
