import React from "react";
import styled from "styled-components";
import {Entity} from "../../ecs/entity/Entity";

const StyledValveIcon = styled.div`
  width: auto;
  aspect-ratio: 1 / 1 !important;
  border-radius: 8px;
  // border-radius: 100px;
  box-shadow: rgb(33, 38, 45) 0 0 0 1px;
  background-color: rgb(1, 4, 9);
  position: relative;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  --pipehint-width: 4px;
  
  .valve-pipe-hint {
    --pipehint-overflow: 0px;
    width: 100%;
    background-color: rgb(88, 166, 255);
    height: calc(var(--pipehint-width) + var(--pipehint-overflow));
    z-index: 2;
  }
  
  .valve-pointer-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .valve-pointer {
      width: 100%;
      height: var(--pipehint-width);
      background-color: #ffdf60;
      // background-color: rgb(88, 166, 255);
      // border-radius: 25px;
      transition: all 1s ease-in-out;
      // transition: all 1.5s;
      // transition-timing-function: cubic-bezier(0.250, 0.250, 0.645, 1.295);
    }
  }
  
  
`;

export type ValveIconProps = {
    entity: Entity
}

export const ValveIcon: React.FC<ValveIconProps> = props => {
    const state = props.entity?.state;
    const valveOpenPercentage = (state?.throughput ?? 0) / 100 * 90;

    return (
        <StyledValveIcon>
            <div className={"valve-pipe-hint"}/>
            <div className={"valve-pointer-holder"} children={
                <div className={"valve-pointer"} style={{
                    backgroundColor: valveOpenPercentage === 0 ? "#f14234" : (
                        valveOpenPercentage === 90 ? "rgb(88, 166, 255)" : ""
                    ),
                    transform: `rotate(${valveOpenPercentage - 90}deg)`
                }}/>
            }/>
            <div className={"valve-pipe-hint"}/>
        </StyledValveIcon>
    );
}
