import React from "react";
import styled from "styled-components";
import ArdspaceTypographyLogo from "../../../assets/graphics/logos/ardspace-logo-typography.png";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";

const StyledBoardingPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  user-select: none;
  
  .ardspace-header-logo {
    user-select: none;
    width: 52px;
  }
`;

export const BoardingPageHeader: React.FC = props => {
    return (
        <StyledBoardingPageHeader>
            <img className={"ardspace-header-logo"} src={ArdspaceTypographyLogo} alt={"Ardspace logo typography"}/>
            <DescriptiveTypography text={"Imprint"}/>
        </StyledBoardingPageHeader>
    );
}
