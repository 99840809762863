import React, {useContext, useEffect, useState} from "react";
import {ArdaiAPIStateContext} from "../ArdaiMain";
import {Project} from "../data/Project";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {BooleanContext} from "../../triton/components/BooleanContext";
import {Modal} from "../../triton/components/dialogs/Modal";
import {FileImportModal} from "./FileImportModal";
import {webDB} from "../webapi/WebAPIDB";
import {useLiveQuery} from "dexie-react-hooks";
import {ImagePreview} from "./ImagePreview";
import "../style/react-resizable-styles.css";
import {AspectRatioMode, ISADBImageGrid} from "./ISADBImageGrid";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {WorkspaceContext} from "./Workspace";
import {HOCWrapper} from "../../base/components/HOCWrapper";
import {ImageView} from "./ImageView";
import {Screen} from "../../base/components/base/Page";
import {ImageRounded, UploadRounded} from "@mui/icons-material";
import {FlatIconButton} from "./FlatIconButton";

export const ProjectView: React.FC = props => {
    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);
    const workspace = useContext(WorkspaceContext);



    const [currentProject, setCurrentProject] = useState<Project | undefined>(undefined);
    useEffect(() => {
        api.getCurrentProject().then(cp => setCurrentProject(cp));
    }, [api, state]);



    const project = useLiveQuery(() => {
        const pID = api.state.selectedProject;
        if (pID === undefined) return undefined;
        return webDB.projects.get(pID);
    }, [api.state.selectedProject]);

    const images = useLiveQuery(() => {
        return webDB.images
            .where("id")
            .anyOfIgnoreCase(project?.resources ?? [])
            // .limit(16)
            .toArray();
    // }, [currentProject]);
    }, [project]);

    return (
        <>
            {
                state.selectedProject === undefined ? (
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}>
                        <DescriptiveTypography text={"Select a project"}/>
                    </div>
                ) : (
                    <div style={{
                        flexShrink: 2,
                        display: "grid",
                        gridTemplateRows: "min-content auto",
                        height: "100%",
                        gap: "8px",
                    }}>
                        <div style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "8px"
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "8px"
                            }}>
                                <ImageRounded fontSize={"small"} style={{
                                    color: "white"
                                }}/>
                                <MainTypography text={"Images"} noSelect/>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                height: "100%",
                                gap: "4px"
                            }}>
                                <BooleanContext children={(bool, setBool) => (
                                    <>
                                        <Modal title={"Import images"} open={bool} onClose={() => setBool(false)} children={
                                            <FileImportModal open={bool} onCancel={() => setBool(false)} onSubmit={files => {
                                                api.appendFilesToCurrentProject(files).then(() => {});
                                            }}/>
                                        }/>

                                        <FlatIconButton
                                            onClick={() => setBool(true)}
                                            children={<UploadRounded fontSize={"small"}/>}
                                        />
                                    </>
                                )}/>
                            </div>
                        </div>

                        <div style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            flexDirection: "column",
                            gap: "8px",
                            overflow: "scroll"
                        }}>

                            {/*
                            <GenericMasonry cols={4} items={images ?? []} keyExtractor={data => data.id} renderer={data => (
                                <ImagePreview
                                    square={false}
                                    for={data}
                                    onClick={(event) => {
                                        if (event.ctrlKey) {
                                            api.selectionManager.toggleSelection(data.id);
                                            return;
                                        }
                                        // api.selectImageByID(data.id);

                                        // TODO: Use this way of selecting images
                                        api.selectImages({
                                            imagesIDs: images!.map(i => i.id),
                                            name: currentProject!.id,
                                            pointer: images!.indexOf(data)
                                        });
                                    }}
                                    onRequestDelete={() => api.removeImageFromCurrentProject(data.id)}
                                />
                            )}/>
                            */}




                            {/*
                            <HOCWrapper body={wrapper => (
                                // <ISADBImageGrid preferPreviewImage imageIDs={currentProject?.resources ?? []} imageRenderer={data => (
                                <ISADBImageGrid preferPreviewImage imageIDs={(images ?? []).map(i => i.id)} imageRenderer={data => (
                                    <ImagePreview
                                        for={data}
                                        // TODO: Set key -> But smart lul
                                        // key={data.id}
                                        onClick={(event) => {
                                            if (event.ctrlKey) {
                                                api.selectionManager.toggleSelection(data.id);
                                                return;
                                            }
                                            api.selectImageByID(data.id);

                                            // TODO: Use this way of selecting images
                                            // api.selectImages({
                                            //     imagesIDs: images!.map(i => i.id),
                                            //     name: currentProject!.id,
                                            //     pointer: images!.indexOf(data)
                                            // });

                                            if (workspace.mode === "mobile") {
                                                wrapper.dialog(
                                                    <Screen style={{ backgroundColor: "#1a1a20" }} children={
                                                        <div style={{
                                                            height: "100%",
                                                            width: "100%"
                                                        }}>
                                                            <ImageView/>
                                                        </div>
                                                    }/>
                                                );
                                            }
                                        }}
                                        onRequestDelete={() => api.removeImageFromCurrentProject(data.id)}
                                    />
                                )}/>
                            )}/>
                            */}

                            {/*
                            <GenericMasonry<Image>
                                cols={3}
                                items={images ?? []}
                                keyExtractor={img => img.id}
                                renderer={(img) => {
                                    return (
                                        <ImagePreview
                                            for={img}
                                            square={false}
                                            onRequestDelete={() => api.removeImageFromCurrentProject(img.id)}
                                            onClick={(event) => {
                                                if (event.ctrlKey) {
                                                    api.selectionManager.toggleSelection(img.id);
                                                    return;
                                                }
                                                api.selectImageByID(img.id);
                                            }}
                                        />
                                    );
                                }}
                            />
                            */}


                            <HOCWrapper body={wrapper => (
                                // <ISADBImageGrid preferPreviewImage imageIDs={currentProject?.resources ?? []} imageRenderer={data => (
                                <ISADBImageGrid preferPreviewImage imageIDs={(images ?? []).map(i => i.id)} imageRenderer={(data, ctx) => (
                                    <ImagePreview
                                        for={data}
                                        square={ctx.state.aspectRatioMode === AspectRatioMode.SQUARE}
                                        // TODO: Set key -> But smart lul
                                        // key={data.id}
                                        onClick={(event) => {
                                            if (event.ctrlKey) {
                                                api.selectionManager.toggleSelection(data.id);
                                                return;
                                            }

                                            api.selectImageByID(data.id);

                                            // TODO: Use this way of selecting images
                                            // api.selectImages({
                                            //     imagesIDs: images!.map(i => i.id),
                                            //     name: currentProject!.id,
                                            //     pointer: images!.indexOf(data)
                                            // });

                                            if (workspace.mode === "mobile") {
                                                wrapper.dialog(
                                                    <Screen style={{ backgroundColor: "#1a1a20" }} children={
                                                        <div style={{
                                                            height: "100%",
                                                            width: "100%"
                                                        }}>
                                                            <ImageView/>
                                                        </div>
                                                    }/>
                                                );
                                            }
                                        }}
                                        onRequestDelete={() => api.removeImageFromCurrentProject(data.id)}
                                    />
                                )}/>
                            )}/>


                            {
                                // (images !== undefined && images.length === 0) ? (
                                //     <DescriptiveTypography text={"No images"}/>
                                // ) : undefined
                            }
                        </div>
                    </div>
                )
            }
        </>
    );
}
