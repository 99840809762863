import {Vec2D} from "./Vec2D";
import {CartesianVec2D} from "./CartesianVec2D";
import {Quadrant} from "./Quadrant";

export class PolarVec2D extends Vec2D {

    constructor(theta: number = 0, r: number = 0) {
        super(theta, r);
    }

    // TODO: This smells fishy...
    public get cartesian(): CartesianVec2D {
        return new CartesianVec2D(
            this.y * Math.cos(this.x),
            this.y * Math.sin(this.x)
        );
    }

    public plus(operand: PolarVec2D): PolarVec2D {
        return this.cartesian.plus(operand.cartesian).polar;
    }

    public times(operand: number): PolarVec2D {
        return new PolarVec2D(
            this.theta,
            this.r * operand
        );
    }

    public rotate(deltaTheta: number = 0): PolarVec2D {
        return new PolarVec2D(
            this.theta + deltaTheta % 360,
            this.r
        );
    }

    public get theta() {
        return this.x;
    }

    public invert(): PolarVec2D {
        return new PolarVec2D(
            (this.theta + 180) % 360,
            this.r
        )
    }

    public get r() {
        return this.y;
    }

    public get quadrant(): Quadrant {
        const t = this.theta;
        if (t >= 0 && t < 90) {
            return Quadrant.NE;
        } else if (t >= 90 && t < 180) {
            return Quadrant.SE;
        } else if (t >= 180 && t < 270) {
            return Quadrant.SW;
        } else {
            return Quadrant.NW
        }
    }

    public toString(fractionDigits: number = -1) {
        return `[θ=${
            fractionDigits >= 0 ? this.theta.toFixed(fractionDigits) : this.theta
        }, r=${
            fractionDigits >= 0 ? this.r.toFixed(fractionDigits) : this.r
        }]`;
    }
}
