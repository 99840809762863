import {TabConfig} from "./TabConfig";
import {allTab} from "./tabs/AllTab";
import {engineTab} from "./tabs/EngineTab";
import {steeringTab} from "./tabs/SteeringTab";
import {mainTab} from "./tabs/MainTab";
import {steeringCompactTab} from "./tabs/SteeringCompactTab";

export namespace StdTabLibrary {

    export const tabs: Array<TabConfig> = [
        // system
        mainTab,
        // deprecated
        allTab,
        // atomic tabs
        engineTab, steeringTab,
        // mobile & compacted tabs
        steeringCompactTab
    ]
}
