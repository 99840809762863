import React, {useState} from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {InlineEditable} from "../../imageSorter/InlineEditable";
import {CircularProgress} from "@mui/material";
import {useTriton} from "../../triton/TritonHooks";
import {Triton} from "../../triton/Triton";

const StyledListItem = styled.div<{
    t: Triton
}>`
  padding: 6px 8px;
  // border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  // color: rgb(148, 155, 164);
  color: ${p => p.t.col("fg_secondary")};
  background-color: #DBDEE100;
  
  .list-item-title {
    color: ${p => p.t.col("fg_secondary")};
  }
  
  &:hover {
    // background-color: #36373DFF;
    // background-color: #1e202a4f;
    // background-color: #1e202a;
    background-color: ${p => p.t.col("layout_300")};
    
    .list-item-title {
      // color: #DBDEE1;
      // color: ${p => p.t.col("fg_secondary")};
    }
    
    .list-item-appendix-hover {
      opacity: 1 !important;
    }

    .list-item-appendix-default {
      opacity: 0 !important; 
    }
  }
  
  &[data-active=true] {
    // background-color: #404249;
    // background-color: #1e202a;
    background-color: ${p => p.t.col("layout_300")};

    .list-item-title {
      // color: white;
      color: ${p => p.t.col("fg_default")};
    }
  }
  
  .list-item-icon-container {
    position: relative;
    display: block;
    margin-right: 6px;
    width: 20px;
    height: 20px;
    
    svg {
      width: 16px;
      height: 16px;
    }
    
    & > * {
      color: ${p => p.t.col("icon_default")};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  
  .task-list-main {
    flex-grow: 1;
  }
  
  .list-item-appendix {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 20px;
    
    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 4px;
    }

    .list-item-appendix-hover {
      opacity: 0;
    }

    .list-item-appendix-default {
      opacity: 1;
    }
  }
`;

export type ListItemProps = {
    title?: string,
    icon?: JSX.Element,
    active?: boolean
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void,
    hideAppendix?: boolean,
    defaultAppendix?: JSX.Element,
    hoverAppendix?: JSX.Element,
    activeAppendix?: JSX.Element,
    loading?: boolean
}

/**
 * TODO: Remove hovering system
 *  Replace it by setting the opacity of the hover & default appendix
 *
 * @param props
 * @constructor
 */
export const ListItem: React.FC<ListItemProps> = props => {
    const isLoading = props.loading ?? false;
    const t = useTriton();

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        // if (e.ctrlKey) return; // TODO: Check why here
        props.onClick?.(e);
    }

    return (
        <StyledListItem t={t} data-active={props.active ?? false} onClick={handleClick}>
            { props.icon && (
                <div className={"list-item-icon-container"}>
                    { !isLoading && (
                        props.icon
                    ) }

                    { isLoading && (
                        <div children={
                            <CircularProgress
                                variant={"indeterminate"}
                                thickness={5}
                                size={14}
                                sx={{
                                    color: "rgb(148, 155, 164)"
                                }}
                            />
                        }/>
                    ) }
                </div>
            ) }

            { props.title && (
                <div className={"task-list-main"}>
                    <DescriptiveTypography
                        baseProps={{ className: "list-item-title" }}
                        noSelect
                        text={props.title}
                        style={{
                            fontSize: 14,
                            lineHeight: "20px",
                            // fontWeight: 500,
                            fontWeight: 400,
                            letterSpacing: ".24px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis"
                        }}
                    />


                    {/*
                <InlineEditable
                    useTextStylePreset={"description"}
                    initialValue={props.title}
                    requireControlClick
                    onChange={async value => {}}
                />
                */}
                </div>
            ) }

            { !props.hideAppendix && (
                <div className={"list-item-appendix"} onClick={(e) => e.stopPropagation()}>
                    { (props.activeAppendix !== undefined && props.active) && (
                        <div className={"list-item-appendix-active"} children={props.activeAppendix}/>
                    ) }

                    { (props.activeAppendix === undefined || !props.active) && (
                        <>
                            <div className={"list-item-appendix-default"} children={props.defaultAppendix}/>
                            <div className={"list-item-appendix-hover"} children={props.hoverAppendix}/>
                        </>
                    ) }
                </div>
            ) }


        </StyledListItem>
    );
}
