import React, {useEffect, useRef, useState} from "react";
import {DrawerContext} from "./DrawerContext";
import styled from "styled-components";
import {motion, useMotionValue, AnimatePresence } from "framer-motion";
import {FullscreenExitRounded, FullscreenRounded} from "@mui/icons-material";

const StyledDrawer = styled.div`
  z-index: 100;
  
  .drawer-backdrop {
    backdrop-filter: blur(5px) grayscale(1);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(1, 4, 9, 0.1);
  }
`;

export type DrawerProps = {
    children: (ctx: DrawerContext) => React.ReactNode,

    onClose: () => void,
    open: boolean
}

export type DrawerState = {
    shouldClose: boolean,
    shouldEnterFullscreen: boolean,
    fullscreen: boolean,
    // deprecated
    _ignoreDrawerScroll: boolean,
}

export const Drawer: React.FC<DrawerProps> = props => {
    const ctxRef = useRef(new DrawerContext());
    const drawerRef = useRef<HTMLDivElement>(null);
    const drawerContainerRef = useRef<HTMLDivElement>(null);
    const debugTextRef = useRef<HTMLElement>(null);
    const y = useMotionValue(0);
    const open = props.open;

    const [state, setState] = useState<DrawerState>({
        shouldClose: false,
        shouldEnterFullscreen: false,
        fullscreen: false,
        _ignoreDrawerScroll: false
    });

    y.on("renderRequest", () => {
        // debugTextRef.current!.innerText = `${y.get()}`;

        const drawerClosingThreshold = 80;

        if (y.get() > drawerClosingThreshold && !state.shouldClose) {
            setState(prevState => ({ ...prevState,
                shouldClose: true
            }));
        } else if (y.get() <= drawerClosingThreshold && state.shouldClose) {
            setState(prevState => ({ ...prevState,
                shouldClose: false
            }));
        }
    });

    return (
        <AnimatePresence mode={"sync"}>
            {props.open && (
                <StyledDrawer>

                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}

                        className={"drawer-backdrop"}
                        onClick={() => props.onClose()}
                    />

                    <motion.div
                        initial={{ y: "100%" }}
                        animate={{ y: 0 }}
                        exit={{ y: "110%" }}

                        transition={{
                            duration: .2,
                            type: "tween",
                            damping: 10,
                            stiffness: 50
                        }}
                        className={"drawer"}
                        dragPropagation={true}
                        ref={drawerRef}
                        drag={"y"}
                        dragSnapToOrigin

                        // dragElastic={0}
                        dragElastic={0.2}

                        dragDirectionLock={false}
                        dragTransition={{
                            bounceStiffness: 600,
                        }}
                        dragConstraints={{
                            // top: -50,
                            top: 0,
                            bottom: 500
                        }}
                        style={{
                            y: y,
                            boxSizing: "border-box",
                            position: "absolute",
                            left: 0,
                            width: "100%",
                            display: "grid",
                            zIndex: 100,
                            // padding: 8,
                            bottom: 0,
                            justifyContent: "center",
                        }}
                        onDragEnd={(event, info) => {
                            if (state.shouldEnterFullscreen) {
                                setState(prevState => ({ ...prevState,
                                    fullscreen: !prevState.fullscreen, // TODO: Replace with true
                                    shouldEnterFullscreen: false
                                }));
                            }

                            else if (state.shouldClose) {
                                props.onClose();
                            }
                        }}
                        children={
                            <>
                                <code ref={debugTextRef} style={{
                                    fontSize: 10,
                                    textAlign: "center",
                                    // width: "calc(100% - 64px)",
                                    position: "absolute",
                                    transform: "translateX(-50%)",
                                    top: -32,
                                    left: "50%",
                                    padding: 8,
                                    zIndex: 100,
                                    color: "rgb(255,223,96)",
                                    backgroundColor: "#010409",
                                    borderRadius: 8
                                }}/>

                                <motion.div children={props.children(ctxRef.current)} ref={drawerContainerRef} style={{
                                    width: "100%",
                                    overflow: "hidden",
                                    display: "grid",
                                    justifyContent: "center",

                                    backgroundColor: "#010409",

                                    overflowY: "hidden",

                                    padding: 16,
                                    // overflowY: state.ignoreDrawerScroll ? "hidden" : "scroll"
                                }} animate={state.fullscreen ? "fullscreen-mode" : "partial-mode"} variants={{
                                    "partial-mode": {
                                        borderRadius: 16,
                                        maxHeight: "70vh",
                                        // height: "auto",
                                        width: "calc(100vw - 2 * 8px)"
                                    },
                                    "fullscreen-mode": {
                                        borderRadius: 0,
                                        maxHeight: "100vh",
                                        height: "100vh",
                                        width: "100vw",
                                    }
                                }}/>

                                <motion.div
                                    viewport={{
                                        margin: `-${8}px`
                                    }}
                                    style={{
                                        position: "absolute",
                                        // bottom: -32,
                                        bottom: -32,
                                        width: "100%",
                                        display: "grid",
                                        justifyContent: "center",
                                        color: "rgb(201, 209, 217)",
                                        scale: 0
                                    }}
                                    onViewportEnter={entry => {
                                        setState(prevState => ({ ...prevState, shouldEnterFullscreen: true }));
                                    }}
                                    onViewportLeave={entry => {
                                        setState(prevState => ({ ...prevState, shouldEnterFullscreen: false }));
                                    }}
                                    whileInView={{
                                        scale: 2
                                    }}
                                    children={
                                        state.fullscreen ? (
                                            <FullscreenExitRounded sx={{ fontSize: 12 }}/>
                                        ) : (
                                            <FullscreenRounded sx={{ fontSize: 12 }}/>
                                        )
                                    }
                                />
                            </>
                        }
                    />
                </StyledDrawer>
            )}
        </AnimatePresence>
    );
}
