import React, {useContext} from "react";
import styled from "styled-components";
import {Button} from "../../components/Button";
import {
    AutoModeRounded,
    NorthEastRounded,
    NorthRounded, NorthWestRounded,
    NumbersRounded,
    WaterDamageRounded,
    WindPowerRounded
} from "@mui/icons-material";
import {hex} from "../../../../base/logic/style/Color";
import {as} from "../../../../atlas/Lang";
import {PIDController} from "../../math/PIDController";
import {SimulationContext} from "../../Simulation2Main";
import {KVEntryDisplay} from "../../../../ardai/components/KVEntryDisplay";
import {StyledControlDisplayGroup} from "../../components/StyledControlDisplayGroup";
import {castEntityFull} from "../../SimStd";
import {ShipMainEntity} from "../ship/ShipMainEntity";
import {Std} from "../../../../../Std";
import range = Std.range;
import {Quadrant} from "../../math/Quadrant";
import {SteeringAPEntity} from "./SteeringAPEntity";
import also = Std.also;

const StyledSteeringAPControlPanel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  // align-items: center;
  // justify-content: center;
`;

export const SteeringAPControlPanel: React.FC = props => {
    const sim = useContext(SimulationContext);
    const steeringAP = castEntityFull<SteeringAPEntity>(sim.entity("auto"));
    // const headingController = as<PIDController>(steeringAP?.state["headingController"]);
    const headingController = steeringAP?.getHeadingPIDController();
    const ship = castEntityFull<ShipMainEntity>(sim.entity("ship"));

    return (
        <StyledControlDisplayGroup>
            <StyledSteeringAPControlPanel>

                {/* main controller */}
                <div style={{ display: "grid", width: "100%", gridTemplateColumns: "repeat(5, 1fr)", gap: "8px", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ gridColumn: "span 2" }} children={
                        <Button children={<AutoModeRounded/>} color={{
                            if: [
                                { cond: () => steeringAP?.state?.engaged && (steeringAP?.currentTargetGeneratorName === "fallback"), color: hex("#f14234") },
                                { cond: () => steeringAP?.state?.engaged ?? false, color: hex("#60ffc7") }
                            ]
                        }} onClick={() => steeringAP.setStatePartially(prevState => ({
                            engaged: !prevState.engaged
                        }))}/>
                    }/>

                    <Button children={<NorthRounded/>} onClick={() => steeringAP.setStatePartially(prevState => ({
                        manualHeading: 0
                    }))}/>

                    <Button children={<NorthWestRounded/>} onClick={() => steeringAP.setStatePartially(prevState => ({
                        manualHeading: (prevState.manualHeading + 45) % 360
                    }))}/>

                    <Button children={<NorthEastRounded/>} onClick={() => steeringAP.setStatePartially(prevState => ({
                        manualHeading: (prevState.manualHeading - 45) % 360
                    }))}/>
                </div>

                {/* pid controller bounds */}
                <div style={{ display: "grid", width: "100%", gridTemplateColumns: "repeat(5, 1fr)", gap: "8px", justifyContent: "center", alignItems: "center" }}>
                    { [5, 10, 20, 30, 50].map(v => (
                        <Button children={`${v}`} onClick={() => headingController.setOutputBounds(-v, v)} color={{
                            if: [
                                { cond: () => (headingController?.outputMax ?? Number.MIN_VALUE) === v, color: hex("#ffdf60") }
                            ]
                        }}/>
                    )) }
                </div>

                {/* target generator selector panel */}
                <div style={{ display: "grid", width: "100%", gridTemplateColumns: "repeat(3, 1fr)", gap: "8px", justifyContent: "center", alignItems: "center" }}>
                    {([
                        ["manual", <NumbersRounded/>],
                        ["wind", <WindPowerRounded/>],
                        ["current", <WaterDamageRounded/>]
                    ] as [string, React.ReactNode][]).map(gen => (
                        <Button children={gen[1]} onClick={() => steeringAP.switchTargetGenerator(gen[0])} color={{
                            if: [{ cond: () => steeringAP?.currentTargetGeneratorName === gen[0], color: hex("#ffdf60") }]
                        }}/>
                    ))}
                </div>

                <KVEntryDisplay monospace title={"Status"} enableCopy={false} value={
                    steeringAP?.state.status ? also("", () => {
                        const status = steeringAP?.state?.status!!;
                        return `${status.status} (${status.statusCode})`;
                    }) : "No status"
                }/>

                <KVEntryDisplay monospace title={"Heading"} enableCopy={false} value={
                    `${(() => {
                        const master = steeringAP?.state?.target?.master;
                        if (master === undefined) return "?"
                        return `${master.get().toFixed(1)} [${master.name}]`
                    })()} (${headingController?.target?.toFixed(1) ?? '?'})`
                }/>

                <KVEntryDisplay monospace title={"Man. heading"} enableCopy={false} value={
                    `${steeringAP?.state.manualHeading ?? '?'}`
                }/>

                <KVEntryDisplay monospace title={"Error"} enableCopy={false} value={
                    `${headingController?.error?.toFixed(2) ?? '?'}`
                }/>

                <KVEntryDisplay monospace title={"Output max"} enableCopy={false} value={
                    `${headingController?.outputMax ?? '?'}`
                }/>

                <KVEntryDisplay monospace title={"Wind att"} enableCopy={false} value={`${
                    !(ship && headingController) ? "?" : (
                        `${ship.state.heading.theta.toFixed(2)}, ${(() => {
                            const attackAngle = ship.getAmbientWindForce().theta - ship.state.heading.theta;
                            const quadrantAttackAngle = attackAngle % 90;
                            let attackForceFac = 45 - Math.abs(45 - quadrantAttackAngle); // 0-45
                            attackForceFac = range(0, 45, 0, 1, attackForceFac);

                            let orientationFac = -1;
                            switch (ship.getAmbientWindForce().quadrant) {
                                case Quadrant.NW:
                                case Quadrant.SE:
                                    orientationFac = 1;
                            }
                            
                            return `q=${ship.getAmbientWindForce().quadrant} f=${
                                (orientationFac * attackForceFac).toFixed(2)
                            }`;
                        })()}`
                    )
                }`}/>
            </StyledSteeringAPControlPanel>
        </StyledControlDisplayGroup>
    );
}
