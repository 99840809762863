import {NodeSetupInfo} from "../../../NodeSetupInfo";
import {Node} from "../../../../backend/Node";
import {v4} from "uuid";
import {DefaultCharacterSymbols} from "../../../DefaultCharacterSymbols";
import {SimNodeUtils} from "./utils/SimNodeUtils";
import {FC, ReactNode} from "react";
import {Tag} from "../../../../../ardai/components/Tag";
import {Interpolate} from "../../../../../triton/components/advanced/Interpolate";
import {DescriptiveTypography} from "../../../../../triton/components/typography/DescriptiveTypography";
import {IndicatorContainer} from "../../../components/indicators/IndicatorContainer";
import {IndicatorIcon} from "../../../components/indicators/IndicatorIcon";
import {IndicatorUtils} from "../../../components/indicators/utils/IndicatorUtils";
import {useTriton} from "../../../../../triton/TritonHooks";
import {AirRounded, HeatPumpRounded, MoneyRounded, PercentRounded, VerticalAlignTopRounded} from "@mui/icons-material";
import {GaugeDisplay, NodeGauge} from "./NodeGauge";

export const NodeFluidPump: NodeSetupInfo = {
    label: "NodeFluidPump",
    classname: "sim.fluid-pump",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "sim.fluid-pump",
        label: "pump",
        defInPins: [DefaultCharacterSymbols.clockPinKey, "#"],
        defOutPins: ["#"],
        state: {
            // internal transfer config
            srcValueStateKey: "level",
            destValueStateKey: "level",
            destCapacityStateKey: "capacity",
            // normal transfer config
            maxFlowRate: 100,
            actualFlowRate: 0,
            actualTransferAmount: 0,
            // visual display config
            enableGaugeDisplay: true
        },
        init: function () {
            this.pins.in(DefaultCharacterSymbols.clockPinKey).attachOnRead(sig => {
                // const isFlowReversed = this.state.state.actualFlowRate < 0;
                const srcNode = this.pins.in("#").inputConnections[0].node;
                const destNode = this.pins.out("#").outputConnections[0].node;

                const actualTransferAmount = SimNodeUtils.transferFluid({
                    maxTransferAmount: Math.abs(this.state.state.actualFlowRate),
                    getSrcAvailable: () => srcNode.state.state[this.state.state.srcValueStateKey],
                    getDestAvailableCapacity: () => {
                        const destValue = destNode.state.state[this.state.state.destValueStateKey]
                        const destCapacity = destNode.state.state[this.state.state.destCapacityStateKey]
                        return Math.max(0, destCapacity - destValue);
                    },
                    doReduceSrcLevel: by => {
                        srcNode.pins.in("±").onRead(-by);
                    },
                    doIncreaseDestLevel: by => {
                        destNode.pins.in("±").onRead(by);
                    }
                });

                this.state.update({
                    actualTransferAmount
                })
            });
        },
        customRenderer: node => {
            const s = node.state.state;
            return (
                <FluidPumpDisplay
                    enableGaugeDisplay={s.enableGaugeDisplay ?? true}
                    targetFlowRate={s.actualFlowRate}
                    maxFlowRate={s.maxFlowRate}
                    actualTransferAmount={s.actualTransferAmount}
                    updateFlowRate={(rate, delta) => {
                        delta = delta ?? false;
                        if (delta) rate = s.actualFlowRate + rate;
                        const isRateNegative = rate < 0;
                        let cappedRate = Math.min(s.maxFlowRate, Math.abs(rate));
                        if (isRateNegative) cappedRate *= -1;
                        node.state.update({
                            actualFlowRate: cappedRate
                        });
                    }}
                />
            );
        }
    })
}

type FluidPumpDisplayProps = {
    targetFlowRate: number,
    maxFlowRate: number,
    actualTransferAmount: number,
    // visual configuration
    enableGaugeDisplay?: boolean
    // upstream functions
    updateFlowRate(rate: number, delta?: boolean): void
}

const FluidPumpDisplay: FC<FluidPumpDisplayProps> = props => {
    const t = useTriton();
    const pumpTargetRatePercentage = props.targetFlowRate / props.maxFlowRate * 100;
    const actualTransferAmount = props.actualTransferAmount;
    let percentageOfQuotaReached = actualTransferAmount <= 0 ? 0 : actualTransferAmount / props.targetFlowRate * 100;
    percentageOfQuotaReached = props.targetFlowRate <= 0 ? 0 : percentageOfQuotaReached;

    return (
        <div style={{
            padding: "8px 0",
            display: "flex",
            flexDirection: "column",
            gap: 6 // -> becomes a visible gap of 4px, cause containers have 1px box-shadows each
        }}>
            { props.enableGaugeDisplay && (
                <IndicatorContainer compact>
                    <GaugeDisplay
                        actual={pumpTargetRatePercentage}
                        visuals={{
                            showMarkedAreas: false,
                            showValue: false,
                            centralModuleWidth: 20,
                            height: 75
                        }}
                        modules={{
                            enableMarkerModule: false
                        }}
                    />
                    <GaugeDisplay
                        actual={percentageOfQuotaReached}
                        visuals={{
                            showThresholdMarkers: false,
                            showMarkedAreas: true,
                            showValue: false,
                            centralModuleWidth: 20,
                            height: 75
                        }}
                        modules={{
                            enableMarkerModule: false
                        }}
                        areas={[
                            {
                                start: 90,
                                end: Number.MAX_VALUE,
                                bevelMode: "top",
                                withinAreaColor: "#60ffc7",
                                outsideAreaColor: "#21262d",
                                border: false
                            },
                            {
                                start: 50,
                                end: 89,
                                bevelMode: "none",
                                withinAreaColor: "#ffdf60",
                                outsideAreaColor: "#21262d",
                                border: false
                            },
                            {
                                start: 0,
                                end: 49,
                                bevelMode: "bottom",
                                withinAreaColor: props.targetFlowRate > 0 ? "crimson" : "#21262d",
                                outsideAreaColor: "#21262d",
                                border: false
                            },
                        ]}
                    />
                </IndicatorContainer>
            ) }

            <IndicatorContainer>

                {/* actual volume transfer */}
                <IndicatorIcon
                    mappingKey={actualTransferAmount}
                    colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => mapping
                        .eq(props.maxFlowRate, "#9148e1")
                        .fallback(0, "rgb(33, 38, 45)")
                    )}
                    iconMapping={new IndicatorUtils.NumericMapping<ReactNode>(mapping => mapping
                        .fallback(0, <AirRounded/>)
                    )}
                />
                <Interpolate value={actualTransferAmount} children={d => (
                    <DescriptiveTypography text={d} style={{
                        fontSize: 12
                    }}/>
                )}/>

                {/* quota */}
                <IndicatorIcon
                    mappingKey={percentageOfQuotaReached}
                    colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => mapping
                        .g(percentageOfQuotaReached, "#ffdf60")
                        .eq(0, "rgb(33, 38, 45)")
                        .fallback(0, "#60ffc7")
                    )}
                    iconMapping={new IndicatorUtils.NumericMapping<ReactNode>(mapping => mapping
                        .fallback(0, <PercentRounded/>)
                    )}
                />
                <DescriptiveTypography text={Math.round(percentageOfQuotaReached)} style={{
                    fontSize: 12
                }}/>

                {/* target flow rate in % from max possible flow rate */}
                <IndicatorIcon
                    mappingKey={pumpTargetRatePercentage}
                    colorMapping={new IndicatorUtils.NumericMapping<string>(mapping => mapping
                        .g(percentageOfQuotaReached, "#ffdf60")
                        .eq(0, "rgb(33, 38, 45)")
                        .fallback(0, "#60ffc7")
                    )}
                    iconMapping={new IndicatorUtils.NumericMapping<ReactNode>(mapping => mapping
                        .fallback(0, <PercentRounded/>)
                    )}
                />
                <DescriptiveTypography text={Math.round(pumpTargetRatePercentage)} style={{
                    fontSize: 12
                }}/>
            </IndicatorContainer>

            <IndicatorContainer>
                <Tag tag={"-"} onClick={e => props.updateFlowRate(-(e.altKey ? 10 : 1), true)}/>
                <Tag tag={"+"} onClick={e => props.updateFlowRate(e.altKey ? 10 : 1, true)}/>

                <Tag tag={"0"} onClick={() => props.updateFlowRate(0)}/>
                <Tag tag={
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        width: "100%",
                        height: "100%"
                    }}>
                        <VerticalAlignTopRounded sx={{
                            fontSize: 14,
                            position: "absolute"
                        }}/>
                    </div>
                } onClick={() => props.updateFlowRate(100)}/>
            </IndicatorContainer>
        </div>
    );
}
