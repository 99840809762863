import React, {useContext, useState} from "react";
import styled from "styled-components";
import {SDInterfaceAPIContext} from "../../imageSorter/sdInterface/SDInterfaceAPI";
import {SDInterfaceStateContext} from "../../imageSorter/sdInterface/SDInterfaceMain";
import {TabBar} from "../components/TabBar";
import {
    CodeRounded,
    DataArrayRounded,
    DeveloperBoard,
    MonitorWeight, MonitorWeightRounded,
    MoreVertRounded,
    TagRounded
} from "@mui/icons-material";
import {Menu} from "../components/Menu";
import {ButtonModalCompound} from "../components/ButtonModalCompound";
import {MenuButton} from "../components/MenuButton";
import {ColorableTag} from "../components/ColorableTag";
import {Color} from "../../base/logic/style/Color";
import {HelpHint} from "../components/HelpHint";
import {
    LineArrayToJsonArrayConvertorMain
} from "../tools/lineArrayToJsonArrayConvertor/LineArrayToJsonArrayConvertorMain";
import {TopicSelectorMain} from "../tools/topicSelector/TopicSelectorMain";
import {Link, redirect, useNavigate} from "react-router-dom";
import {PowerActionMenuSection} from "../components/navbar/PowerActionMenuSection";
import {FlatIconButton} from "../components/FlatIconButton";
import {useStaticState} from "../hooks/StaticStateHook";
import {ThemeSelectorMenuPart} from "../components/ThemeSelectorMenuPart";


const StyledMobileGalleryViewNavigation = styled.div`
  padding: 8px 16px;
  display: grid;
  grid-template-columns: auto min-content;
  background-color: rgb(6, 6, 7);
  border-radius: 20px 20px 0 0;
  
  .navigation-appendix {
    margin-left: 8px;
  }
`;

export type MobileGalleryViewNavigationState = {
    activeTab: string
}

export const MobileGalleryViewNavigation: React.FC = props => {
    const sdApi = useContext(SDInterfaceAPIContext);
    const state = useContext(SDInterfaceStateContext);
    const navigate = useNavigate();

    const [ls, ctx] = useStaticState<MobileGalleryViewNavigationState>({
        id: "MobileGalleryViewNavigation",
        initial: {
            activeTab: ""
        }
    }).stateWithCtx;

    const locHash = window.location.hash.replace("#", "");

    const switchTab = (tab: string) => {
        navigate(`#${locHash}`);

        document.getElementById(tab)?.scrollIntoView({
            behavior: "smooth"
        });

        ctx.update({
            activeTab: tab
        });
    }

    return (
        <StyledMobileGalleryViewNavigation>
            <TabBar
                activeBar={ls.activeTab}
                onTabChange={tab => {
                    switchTab(tab);
                }}
                tabs={[
                    { id: "vfs", title: "VFS", icon: <CodeRounded/> },
                    { id: "project", title: "Project", icon: <CodeRounded/> },
                    { id: "image", title: "Image", icon: <CodeRounded/> },
                    { id: "challenge", title: "Challenge", icon: <MonitorWeightRounded/> },
                    { id: "meta", title: "Meta", icon: <CodeRounded/> },
                ]}
            />

            <div className={"navigation-appendix"} children={
                <Menu opener={
                    <FlatIconButton children={<MoreVertRounded fontSize={"small"}/>}/>
                }>
                    <ButtonModalCompound
                        button={
                            <MenuButton icon={<DataArrayRounded/>} text={"Line-array to json-array convertor"} appendix={<>
                                <ColorableTag tag={"BETA"} c={Color.ofHex("#8561ff")}/>
                                <HelpHint text={"Convert a line-array file (typically .txt) to a json-array file (.json)"}/>
                            </>}/>
                        }
                        modalContent={ctx => (
                            <LineArrayToJsonArrayConvertorMain onClose={ctx.close}/>
                        )}
                    />
                    <ButtonModalCompound
                        button={
                            <MenuButton icon={<TagRounded/>} text={"Topic selector"}/>
                        }
                        modalContent={ctx => (
                            <TopicSelectorMain onClose={ctx.close}/>
                        )}
                    />

                    <Link to={"/ai/development/component"} style={{ textDecoration: "none" }} children={
                        <MenuButton icon={<DeveloperBoard/>} text={"Component testing space"}/>
                    }/>

                    <ThemeSelectorMenuPart/>

                    <PowerActionMenuSection/>
                </Menu>
            }/>


        </StyledMobileGalleryViewNavigation>
    );
}
