import React, {useContext, useState} from "react";
import {TopicSelectorStateContext} from "./TopicSelectorStateContext";
import {AddRounded, FoodBankRounded} from "@mui/icons-material";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {ButtonModalCompound} from "../../components/ButtonModalCompound";
import {IconButton} from "../../components/IconButton";
import {Formik} from "formik";
import {v4} from "uuid";
import {StyledModal} from "../../components/StyledModal";
import {FormElement} from "../../../triton/components/forms/FormElement";
import {FormikSingleLineInput} from "../../../triton/components/forms/FormikSingleLineInput";
import {ErrorMessage} from "../../../triton/components/forms/ErrorMessage";
import {Grid} from "../../../triton/components/layouts/Grid";
import {ButtonBase} from "../../../triton/components/buttons/ButtonBase";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {TopicPreviewCard} from "./TopicPreviewCard";
import {CheckMenuButton, MenuButton} from "../../components/MenuButton";
import {Menu} from "../../components/Menu";

type TopicViewState = {
    showExcluded: boolean
}

export const TopicView: React.FC = props => {
    const [state, setState] = useContext(TopicSelectorStateContext);
    const [ls, setLs] = useState<TopicViewState>({
        showExcluded: true
    });

    let visibleTopics = state.topics;
    if (!ls.showExcluded) {
        visibleTopics = visibleTopics.filter(t => !t.excluded);
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: 8
        }}>
            <div style={{
                display: "flex",
                gap: 8,
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <div style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "center"
                }}>
                    <FoodBankRounded style={{ width: 18 }}/>
                    <MainTypography text={"Topics"} noSelect/>
                </div>
                <div style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "center"
                }}>
                    <ButtonModalCompound
                        button={
                            <IconButton size={"small"} children={<AddRounded/>}/>
                        }
                        modalContent={ctx => (
                            <Formik initialValues={{ title: "" }} validate={values => {
                                const errors: any = {};
                                if (state.topics.filter(s => s.title === values.title.trim()).length > 0) errors.title = "Duplicate title";
                                return errors;
                            }} onSubmit={(values) => {
                                setState(prevState => ({
                                    ...prevState,
                                    topics: [
                                        ...prevState.topics, {
                                            id: v4(),
                                            title: values.title.trim()
                                        }
                                    ]
                                }));
                                ctx.close();
                            }} children={fp => (
                                <StyledModal icon={<FoodBankRounded/>} title={"Add topic"} onClose={ctx.close} children={
                                    <FormElement title={"Title"} children={
                                        <>
                                            <FormikSingleLineInput autoFocus placeholder={"Turmeric / Curry / Pepper"} name={"title"} formikProps={fp}/>
                                            <ErrorMessage formikProps={fp} name={"title"}/>
                                        </>
                                    }/>
                                } footer={(
                                    <Grid style={{ width: "100%" }}>
                                        <ButtonBase text={"Add"} baseProps={{
                                            onClick: e => fp.handleSubmit(e)
                                        }}/>
                                    </Grid>
                                )}/>
                            )}/>
                        )}
                    />
                    <Menu>
                        <CheckMenuButton text={"Show excluded topics"} checked={ls.showExcluded} onSelect={() => {
                            setLs(prevState => ({
                                ...prevState,
                                showExcluded: !prevState.showExcluded
                            }))
                        }}/>
                        <MenuButton text={"Include all"} onSelect={() => {
                            setState(prevState => ({
                                ...prevState,
                                topics: prevState.topics.map(t => {
                                    t.excluded = false;
                                    return t;
                                })
                            }))
                        }}/>
                    </Menu>
                </div>
            </div>

            <div style={{
                display: "flex",
                gap: 4,
                flexDirection: "column"
            }}>
                <TransitionGroup children={visibleTopics.map(topic => (
                    <Collapse key={topic.id} children={
                        <div style={{ marginBottom: 8 }}>
                            <TopicPreviewCard topic={topic}/>
                        </div>
                    }/>
                ))}/>
            </div>
        </div>
    );
}
