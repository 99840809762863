import React, {useContext, useState} from "react";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {
    BugReportRounded, CenterFocusStrongRounded, FullscreenRounded,
    ImageRounded, MoreHorizRounded, PlayArrowRounded
} from "@mui/icons-material";
import {SDPromptEngine} from "./SDPromptEngine";
import {SDPromptField} from "./SDPromptField";
import {Workspace} from "../../ardai/components/Workspace";
import {SDInterfaceAPIContext} from "./SDInterfaceAPI";
import {SDInterfaceRequestContext, SDInterfaceStateContext} from "./SDInterfaceMain";
import {ISADBImageGrid} from "../../ardai/components/ISADBImageGrid";
import {SingleOutputImagePreview} from "./SingleOutputImagePreview";
import {SDLivePreview} from "./SDLivePreview";
import {Menu} from "../../ardai/components/Menu";
import {MenuButton} from "../../ardai/components/MenuButton";
import {
    ControlModalCompound,
    ModalRenderer
} from "../../ardai/components/ControlModalCompound";
import {StyledModal} from "../../ardai/components/StyledModal";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {CollapseIconButton} from "../../ardai/components/CollapseIconButton";
import Collapse from "@mui/material/Collapse";
import {TransitionGroup} from "react-transition-group";
import {Accordion, AccordionDetails} from "@mui/material";
import styled from "styled-components";
import {UGLLexer} from "../../ardai/unifiedGraphicsLanguage/UGLLexer";
import {IconButton} from "../../ardai/components/IconButton";
import {Token} from "../../ardai/unifiedGraphicsLanguage/Token";
import {UGLTestRuntime} from "../../ardai/unifiedGraphicsLanguage/UGLTestRuntime";
import {percent} from "../../base/logic/style/DimensionalMeasured";
import {ScreenSaverLeftWidget} from "../../ardai/components/shared/screenSaver/ScreenSaverLeftWidget";
import {motion} from "framer-motion";
import {FlatIconButton} from "../../ardai/components/FlatIconButton";
import {Tray} from "../../ardai/components/Tray";
import {OutputConfigurationDisplay} from "./output/OutputConfigurationDisplay";
import {ControlledImagePreview} from "../../ardai/components/ControllerImagePreview";
import {webDB} from "../../ardai/webapi/WebAPIDB";
import {StableDiffusionAttachmentRetriever} from "../../ardai/webapi/pngProperties/StableDiffusionAttachmentRetriever";

export const StyledMainTab = styled.div`

  [data-active=true] {
    height: 100%;
  }
  
  .MuiCollapse-wrapper {
    height: 100%;
  }
`;

export type MainTabState = {
    expandedTab: "script" | "prompt" | "negativePrompt" | string | undefined,
    expandedTabs: Array<string>
}

export const MainTab: React.FC = props => {
    const sdApi = useContext(SDInterfaceAPIContext);
    const state = useContext(SDInterfaceStateContext);
    const [ls, setLs] = useState<MainTabState>({
        expandedTab: undefined,
        expandedTabs: ["prompt", "negativePrompt"]
    });
    const requestContextData = useContext(SDInterfaceRequestContext);
    const initialRequestData = requestContextData?.initialRequestData!;
    const deltaRequestData = requestContextData?.deltaRequestData!;
    if (requestContextData === undefined) return <>RCD not available</>

    const isTabExpanded = (tab: string) => {
        return ls.expandedTabs.includes(tab);
    }

    const toggleTab = (tab: string) => {
        if (isTabExpanded(tab)) {
            setLs(prevState => ({
                ...prevState,
                expandedTabs: prevState.expandedTabs.filter(expTab => expTab !== tab)
            }));
        } else {
            setLs(prevState => ({
                ...prevState,
                expandedTabs: [...prevState.expandedTabs, tab]
            }));
        }
    }

    const isFocusingOnTab = (tab: string) => {
        return ls.expandedTabs.length === 1 && ls.expandedTabs[0] === tab;
    }

    const focusOnTab = (tab: string) => {
        setLs(prevState => ({
            ...prevState,
            expandedTabs: [tab]
        }));
    }

    return (
        <StyledMainTab style={{
            display: "grid",
            // gridTemplateColumns: "repeat(2, 1fr) auto",
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: "1rem",
            height: "100%",
            maxHeight: "100%",
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                height: "100%",
                maxHeight: "100%",
                overflow: "hidden"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <MainTypography text={"Engine script"} noSelect style={{ cursor: "pointer" }} onClick={() => {
                        toggleTab("script")
                    }}/>
                    {/* <DescriptiveTypography text={"Prompt"}/> */}

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "4px",
                        alignItems: "center"
                    }}>
                        <FlatIconButton
                            children={<CenterFocusStrongRounded fontSize={"small"}/>}
                            tooltip={"Focus on tab"}
                            onClick={() => focusOnTab("script")}
                            deactivated={isFocusingOnTab("script")}
                        />

                        <Menu opener={
                            <FlatIconButton
                                children={<MoreHorizRounded fontSize={"small"}/>}
                            />
                        }>
                            <MenuButton icon={<BugReportRounded/>} text={"Try to compile prompt"} onSelect={async () => {
                                const ctx = (await new SDPromptEngine().initUserMixins()).parse(deltaRequestData.prompt);

                                let prompt = deltaRequestData.prompt;
                                const lexer = new UGLLexer();
                                prompt = JSON.stringify(lexer.collapse(prompt), null, 2);

                                sdApi.updateRequestData({
                                    negativePrompt: prompt
                                });
                            }}/>
                        </Menu>
                    </div>
                </div>

                <motion.div
                    animate={isTabExpanded("script") ? "expanded" : "collapsed"}
                    variants={{
                        expanded: {
                            height: "100%",
                        },
                        collapsed: {
                            height: "0%"
                        }
                    }}
                    children={
                        <div style={{
                            display: "block",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative"
                        }} children={
                            <div style={{
                                position: "absolute",
                                height: "100%",
                                overflow: "scroll",
                                width: "100%",
                            }} children={
                                <SDPromptField
                                    value={initialRequestData?.prompt ?? ""}
                                    onChange={value => sdApi.updateRequestData({
                                        prompt: value ?? ""
                                    })}
                                />
                            }/>
                        }/>
                    }
                />

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <MainTypography text={"Prompt"} noSelect style={{ cursor: "pointer" }} onClick={() => {
                        toggleTab("prompt")
                    }}/>
                    {/* <DescriptiveTypography text={"Prompt"}/> */}
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "4px",
                        alignItems: "center"
                    }}>
                        <DescriptiveTypography text={(() => {
                            // const p = sdApi.requestContextData.deltaRequestData?.prompt;
                            const p = sdApi.state.request.prompt;
                            if (p === undefined) return "";
                            if (p.trim().length === 0) return "empty";
                            return `${p.split(",").length} tokens / ${p.length} chars`;
                        })()}/>

                        <FlatIconButton
                            children={<CenterFocusStrongRounded fontSize={"small"}/>}
                            tooltip={"Focus on tab"}
                            onClick={() => focusOnTab("prompt")}
                            deactivated={isFocusingOnTab("prompt")}
                        />

                        <Menu opener={
                            <FlatIconButton
                                children={<MoreHorizRounded fontSize={"small"}/>}
                            />
                        }>
                            <MenuButton icon={<BugReportRounded/>} text={"Try to compile prompt"} onSelect={async () => {
                                const ctx = (await new SDPromptEngine().initUserMixins()).parse(deltaRequestData.prompt);
                                alert(JSON.stringify(ctx));
                            }}/>
                        </Menu>
                    </div>
                </div>

                <motion.div
                    animate={isTabExpanded("prompt") ? "expanded" : "collapsed"}
                    variants={{
                        expanded: {
                            height: "100%",
                        },
                        collapsed: {
                            height: "0%"
                        }
                    }}
                    children={
                        <div style={{
                            display: "grid",
                            height: "100%",
                        }} children={
                            <div style={{
                                display: "block",
                                height: "100%",
                                overflow: "hidden",
                                position: "relative"
                            }} children={
                                <div style={{
                                    position: "absolute",
                                    height: "100%",
                                    width: "100%",
                                    overflow: "scroll"
                                }} children={
                                    <ControlModalCompound controller={ctx => (
                                        <SDPromptField
                                            // value={initialRequestData?.prompt ?? ""}
                                            value={sdApi.state.request.prompt ?? ""}
                                            onChange={value => sdApi.updateRequest({
                                                prompt: value ?? ""
                                            })}
                                        />
                                    )} modals={new Map<string, ModalRenderer>([
                                        ["cancel-dialog", (ctx, param) => (
                                            <StyledModal title={"Cancel"}/>
                                        )]
                                    ])}/>
                                }/>
                            }/>
                        }/>
                    }
                />

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <MainTypography text={"Negative prompt"} noSelect style={{ cursor: "pointer" }} onClick={() => {
                        toggleTab("negativePrompt")
                    }}/>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "4px",
                        alignItems: "center"
                    }}>
                        <DescriptiveTypography text={(() => {
                            // const p = sdApi.requestContextData.deltaRequestData?.negativePrompt;
                            const p = sdApi.state.request.negativePrompt;
                            if (p === undefined) return "";
                            if (p.trim().length === 0) return "empty";
                            return `${p.split(",").length} tokens / ${p.length} chars`;
                        })()}/>

                        <FlatIconButton
                            children={<CenterFocusStrongRounded fontSize={"small"}/>}
                            tooltip={"Focus on tab"}
                            onClick={() => focusOnTab("negativePrompt")}
                            deactivated={isFocusingOnTab("negativePrompt")}
                        />

                        <Menu opener={
                            <FlatIconButton
                                children={<MoreHorizRounded fontSize={"small"}/>}
                            />
                        }>
                            <MenuButton icon={<BugReportRounded/>} text={"Try to compile prompt"} onSelect={async () => {
                                const ctx = (await new SDPromptEngine().initUserMixins()).parse(deltaRequestData.negativePrompt);
                                alert(JSON.stringify(ctx));
                            }}/>
                        </Menu>
                    </div>
                </div>

                <motion.div
                    animate={isTabExpanded("negativePrompt") ? "expanded" : "collapsed"}
                    variants={{
                        expanded: {
                            height: "100%",
                        },
                        collapsed: {
                            height: "0%"
                        }
                    }}
                    children={
                        <div style={{
                            display: "grid",
                            height: "100%",
                        }} children={
                            <div style={{
                                display: "block",
                                height: "100%",
                                overflow: "hidden",
                                position: "relative"
                            }} children={
                                <div style={{
                                    position: "absolute",
                                    height: "100%",
                                    width: "100%",
                                    overflow: "scroll"
                                }} children={
                                    <ControlModalCompound controller={ctx => (
                                        <SDPromptField
                                            // value={initialRequestData?.negativePrompt ?? ""}
                                            value={sdApi.state.request.negativePrompt ?? ""}
                                            onChange={value => sdApi.updateRequest({
                                                negativePrompt: value ?? ""
                                            })}
                                        />
                                    )} modals={new Map<string, ModalRenderer>([
                                        ["cancel-dialog", (ctx, param) => (
                                            <StyledModal title={"Cancel"}/>
                                        )]
                                    ])}/>
                                }/>
                            }/>
                        }/>
                    }
                />
            </div>

            {/*
            <Workspace config={{ name: "sd-result-view", mode: "desktop" }} style={{ overflow: "hidden" }} children={
                (() => {
                    // Preview image present
                    if (state.phase === "generating") return (<SDLivePreview/>);

                    // Not rendering && single result preview available
                    else if (state.phase === "default" && state.currentGeneratedBatchIds !== undefined && state.currentGeneratedBatchIds.length === 1) return (<SingleOutputImagePreview/>);

                    // Not rendering && multiple result previews available
                    else if (state.phase === "default" && state.currentGeneratedBatchIds !== undefined && state.currentGeneratedBatchIds.length > 1) return (
                        <ISADBImageGrid isaTable={"sdInterfaceResults"} imageIDs={state.currentGeneratedBatchIds}/>
                    );

                    // Not rendering && No image available
                    else if ((state.currentGeneratedBatchIds === undefined || state.currentGeneratedBatchIds.length === 0) && !state.previewImage) return (
                        <div style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }} children={
                            <span style={{
                                height: "auto",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"}
                            } children={
                                <ImageRounded/>
                            }/>
                        }/>
                    );
                })()
            }/>
            */}

            {/*
            <Tray config={{
                name: "out-view",
                resizable: true,
                growOrientation: "horizontal",
                resizablePropertyOverrides: {
                    resizeHandles: ["w"],
                    axis: "x",
                    width: 800
                }
            }} children={
                <div style={{
                    display: "grid",
                    gap: "8px",
                    height: "100%",
                }}>
                    <DescriptiveTypography text={":zzz:"}/>
                </div>
            }/>
            */}
        </StyledMainTab>
    );
}
