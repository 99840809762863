
import React, {useContext, useEffect, useRef} from "react";
import {StorybookPage, ValidationErrorObject} from "./StorybookPage";
import {Flex} from "../Flex";
import {Formik, FormikProps} from "formik";
import styled from "styled-components";
import {StorybookAPIContext} from "./Storybook";
import {BackButton} from "../components/BackButton";

const StyledStorybookPageBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  
  .storybook-header {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 30px;
    
    .storybook-header-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
`;

export type PageRendererProps = {
    page: StorybookPage<any>,
    master?: boolean
}

/* e slint-disable react-hooks/exhaustive-deps */
export const PageMainRenderer: React.FC<PageRendererProps> = props => {
    const page = props.page;
    const api = useContext(StorybookAPIContext);
    const isMasterPage = props.master ?? false;
    const formikRef = useRef<FormikProps<any>>(null)
    const backPage = page.backPage?.();
    const hasValidBackwardsTransition = backPage !== undefined && !(page.blockBackwardsTransition?.() ?? false);
    const backwardsPageTitle = backPage?.title ?? backPage?.name;
    const name = page.name;
    const initialValues = api.getPageData(name, page.initialValues);

    // Update the APIs formik reference
    useEffect(() => {
        if (isMasterPage && formikRef.current !== null) {
            api.updateMasterFormikProps(formikRef.current)
            api.updateMasterFormikRef(formikRef);
        }
    }, [api, isMasterPage, formikRef, props.page])

    // Initiate page
    useEffect(() => {
        if (isMasterPage) {
            props.page.onPageInit?.(api, true)
        }
    }, [isMasterPage, props.page]);

    return (
        <Formik
            validateOnChange={page.validateOnChange ?? true}
            // validateOnMount={true}
            // validateOnBlur={true}
            innerRef={formikRef}
            initialValues={initialValues ?? {}}
            key={`${page.name}-formik-instance`}
            onSubmit={async (values, formikHelpers) => {
                // Submission was successful :: Store values to Storybook internal data storage
                api.setPageData(page.name, values);
                await page.onSubmit?.(api, values);
            }}
            validate={async values => {
                const errors: ValidationErrorObject<any> = {};
                await page.validate?.(values, errors);
                return errors;
            }}
            children={fp => (
                <StyledStorybookPageBody>
                    <div className={"storybook-header"}>
                        <div className={"storybook-header-action"}>
                            { (backPage) && (
                                <BackButton
                                    deactivated={!hasValidBackwardsTransition || fp.isSubmitting}
                                    text={backwardsPageTitle!}
                                    onBack={() => api.backward(backPage)}
                                />
                            ) }
                        </div>

                        {page.header?.(api, fp)}
                    </div>

                    <Flex g={55} baseProps={{
                        style: {
                            flexDirection: "column",
                            width: "100%"
                        }
                    }}>
                        <Flex g={25} children={page.page(api, fp)} baseProps={{
                            style: {
                                flexDirection: "column",
                                width: "100%"
                            }
                        }}/>

                        { page.bottomActionPanel?.(api, fp, fp.isSubmitting) }
                    </Flex>
                </StyledStorybookPageBody>
            )}
        />
    );
}
