import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {CRH} from "./ConditionalResponseHandler";
import {arcURL} from "../Globals";

export async function axiosExo<T = any, Res = any>(arc: AxiosRequestConfig<T>, cfg?: Omit<ExoRoutineConfig<T>, "arc">): Promise<ExoResponse<Res>> {
    return await exo({
        ...cfg,
        arc: arc
    });
}

export async function exo<T = any, Res = any>(cfg: ExoRoutineConfig<T>): Promise<ExoResponse<Res>> {
    let res: AxiosResponse<any, any>
    try {
        res = await axios({
            ...cfg.arc
        });
        performConditionalResponseHandling(res.data, cfg.crh);
        return {
            response: res,
            data: res.data,
            masterTokenAuthAttempted: false
        }
    } catch (e: any) {
        console.error("[Exo] Axios finished with error: " + e)
        const status = e.response.status;
        if (status === 401) {
            // Unauthorized request :: Either the session JWT isn't valid / is expired
            // or another internal server error labeled 401 happened.
            const success = await triggerSessionTokenRefresh(cfg);
            const res = await axios(cfg.arc);
            performConditionalResponseHandling(res.data, cfg.crh);
            return {
                response: res,
                data: res.data,
                masterTokenAuthAttempted: true,
                masterTokenAuthSucceeded: success,
            }
        }
        throw e;
    }
}

export interface ExoRoutineConfig<T> {
    arc: AxiosRequestConfig<T>,
    crh?: CRH
}

export interface ExoResponse<Res = any> {
    response?: AxiosResponse<any, any>,
    data?: Res,
    masterTokenAuthAttempted: boolean,
    masterTokenAuthSucceeded?: boolean
}

function performConditionalResponseHandling(data: any, crh?: CRH) {
    if (crh !== undefined) {
        try {
            crh.handle(data)
        } catch (e) {
            console.log(e)
        }
    }
}

async function triggerSessionTokenRefresh(cfg: ExoRoutineConfig<any>): Promise<boolean> {
    console.debug("[Exo] Trying to exchange master-token for new session-token")
    // Try to start the session if the necessary master-token is present
    const masterToken = localStorage.getItem("master-token");
    console.debug("[Exo] Master-token:", masterToken)
    // This shouldn't be happening...
    if (masterToken === null) return false;
    return new Promise((resolve, reject) => {
        axios({
            url: arcURL("crude-authenticate"),
            data: { crudeToken: masterToken },
            method: "post"
        }).then(res => new CRH().ok(response => {
            const jwt = response.payload.token;
            console.debug("[Exo] Token exchange was successful. JWT: ", jwt)
            // Response contains new session-token :: Auto-Auth was successful
            localStorage.setItem("session-token", jwt);
            axios.defaults.headers.common = {'Authorization': `Bearer ${jwt}`}
            resolve(true);
        }).handle(res.data, response => {
            // Unexpected return code, this indicates a 'severe' auth-flow error
            resolve(false);
        })).catch(reason => reject(reason));
    });
}
