import React, {PropsWithChildren, useContext, useRef, useState} from "react";
import {Project} from "./data/Project";
import {Default, Mobile} from "../base/components/logic/Media";
import {Workspace} from "./components/Workspace";
import {WebAPI, ArdaiAPIContext} from "./webapi/WebAPI";
import 'react-tooltip/dist/react-tooltip.css'
import {DefaultImageGalleryView} from "./DefaultImageGalleryView";
import {TestScriptingView} from "./unifiedGraphicsLanguage/TestScriptingView";
import {TritonContext} from "../triton/TritonContext";
import {Triton} from "../triton/Triton";
import {TritonReactBridge} from "../triton/TritonReactBridge";
import {isMobile, useDeviceData} from "react-device-detect";
import {MobileArdaiMain} from "./mobile/MobileArdaiMain";
import {ImageSelection} from "./data/ImageSelection";
import {TritonDefaults} from "../triton/TritonDefaults";

export type ArdaiAppState = {
    fvsPath: Array<string>,
    projects: Array<Project>,
    isProjectCreationDialogOpened: boolean,
    isFolderCreationDialogOpened: boolean,
    selectedProject?: string,
    selectedImages: Array<string>,
    selectionMode: boolean,
    selectionPreview: boolean,
    openedTrays: Array<string>,
    trayOccupancy: {[K in string | "left" | "main" | "right" | "bottom" | "left-secondary"]: string | undefined},
    selectedStyleId?: string

    selectedImageId?: string,
    imageSelection?: ImageSelection,

    showMainNavbar: boolean
}

function generateAppState(): ArdaiAppState {
    return ({
        isProjectCreationDialogOpened: false,
        isFolderCreationDialogOpened: false,
        selectedImages: [],
        projects: [],
        fvsPath: [],
        selectionMode: false,
        selectionPreview: true,
        // openedTrays: ["left", "main", "right" /*,"bottom"*/],
        openedTrays: [],
        showMainNavbar: true,
        trayOccupancy: {
            left: "vfs-view",
            // main: "image-view",
            right: "project-view",
            // bottom: "derbyshire-view",
        }
    });
}

function generateTritonInstance(): Triton {
    return new Triton()
        .registerThemes(
            TritonDefaults.theme,
            TritonDefaults._1Theme,
            TritonDefaults._4Theme,
            TritonDefaults.blueFutureTheme,
            TritonDefaults.crimsonFutureTheme,
            TritonDefaults.fireTheme,
            TritonDefaults.lightTheme,
            TritonDefaults.blackTheme,
            TritonDefaults.forrestTheme,
            TritonDefaults.glassyDarkTheme,
            TritonDefaults.oceanTheme,
            TritonDefaults.yellowPastelTheme,
        )
        .start({
            makeGlobal: true
        });
}

export const ArdaiMain: React.FC = props => {
    const [state, setState] = useState<ArdaiAppState>(generateAppState());
    const api = useRef(new WebAPI(state, setState));
    api.current.updateState(state);

    // TODO: Remove
    // tbProcessingPipelineStep();

    return (
        <ArdaiAPIStateContext.Provider value={state} children={
            // React-ArdaiAppState ~ Ardspace-API synchronizer
            <ArdaiMaster children={
                // API ~ Main Ardai api
                <ArdaiAPIContext.Provider value={api.current} children={
                    // Triton ~ Theming system
                    <TritonReactBridge factory={generateTritonInstance} children={() =>
                        <MediaViewMultiplexer/>
                    }/>
                }/>
            }/>
        }/>
    );
}

export const MediaViewMultiplexer: React.FC = props => {
    return (
        <>
            <Mobile children={
                <MobileArdaiMain/>
            }/>

            <Default children={
                <DefaultImageGalleryView/>
            }/>
        </>
    )
}

export const ArdaiAPIStateContext = React.createContext<ArdaiAppState>(generateAppState())

export const ArdaiMaster: React.FC<PropsWithChildren> = (props) => {
    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);
    api.updateState(state);

    return (
        <>{ props.children }</>
    );
};
