import {SRCReader} from "./SRCReader";
import {Token} from "./Token";

export class UGLLexer {

    public shard(s: string, sep: string = ","): Array<string> {
        let shards: Array<string> = [], fifo: Array<string> = [], buf = "";
        const top = () => fifo[fifo.length - 1];
        const checkBr = (e: string, p: [string, string]) => top() === p[0] && e === p[1];
        for (const e of s) {
            if (e === sep && fifo.length === 0) {
                shards.push(buf);
                buf = "";
                continue;
            }
            buf += e;
            if (checkBr(e, ["(", ")"]) ||
                checkBr(e, ["{", "}"]) ||
                checkBr(e, ["[", "]"]) ||
                checkBr(e, ["<", ">"]) ||
                checkBr(e, ["'", "'"]) ||
                checkBr(e, ["\"", "\""])
            ) fifo.pop();
            else if ("([{<'\"".includes(e)) fifo.push(e);
        }
        if (buf.length > 0) shards.push(buf);
        return shards.map(s => s.trim());
    }

    public collapse(s: string): Array<any> {
        const shards = this.shard(s);
        return shards.map(e => {
            // Advanced syntax trees
            if (e.startsWith("@")) return this.collapseMixin(e)
            // else if (e.startsWith(":")) return ["i_fn", e.slice(1)];
            else if (e.startsWith(":")) return this.collapseInternalFunc(e.slice(1));
            else if (e.startsWith("fn")) return ["u_fn_dec", e];
            else if (e.startsWith("var")) return this.collapseVariableDeclaration(e);

            // TODO: Add general support for this
            else if (e.startsWith("val")) return this.collapseVariableDeclaration(e);

            // else if (e.startsWith("{") && e.endsWith("}")) return ["lambda", this.collapse(e.slice(1, -1))];
            else if (e.startsWith("{") && e.endsWith("}")) return this.collapseLambdaFunc(e);

            else if (e.startsWith("[") && e.endsWith("]")) return this.collapseArray(e.slice(1, -1));
            else if (e.startsWith("<") && e.endsWith(">")) return this.collapseObject(e.slice(1, -1));
            else if (e.startsWith("\"") && e.endsWith("\"")) return ["literal", e.slice(1, -1)];
            else if (e.startsWith("'") && e.endsWith("'")) return ["literal:template", e.slice(1, -1)];

            // Simple values
            // else if (e === "true") return ["boolean", true];
            else if (e === "true") return this.toConst("boolean", true);
            // else if (e === "false") return ["boolean", false];
            else if (e === "true") return this.toConst("boolean", false);
            // else if (/^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/g.test(e)) return ["number", Number(e)];
            else if (/^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/g.test(e)) return this.toConst("number", Number(e));

            else if (e.startsWith("$")) return {
                type: "var:get",
                main: e.slice(1)
            };

            // Just literals
            else return ["literal", e];
        });
    }

    public toConst(datatype: string, data: any): Token {
        return {
            type: "const",
            main: datatype,
            data: data,
        };
    }

    public collapseLambdaFunc(s: string): Token {
        return {
            type: "lambda",
            params: this.collapse(s.slice(1, -1)),
            main: "",
        }
    }

    public collapseInternalFunc(s: string): Token {
        if (!s.includes("(")) return {
            type: "i_fn",
            main: s,
            params: []
        };
        let mxName = "";
        const funcParamCode = new SRCReader(s).also(r => mxName = r.nLookAhead(s => s === "(")).remainder().slice(1, -1);
        return {
            type: "i_fn",
            main: mxName,
            staticParams: [{
                type: "i_fn:param",
                main: funcParamCode
            }]
        };
    }

    public collapseMixin(s: string): Token {
        s = s.slice(1);
        if (!s.includes("(")) return {
            type: "mixin",
            main: s,
            tags: ["pure"],
            params: []
        }
        let mxName = "";
        const mixinParamCode = new SRCReader(s).also(r => mxName = r.nLookAhead(s => s === "(")).remainder().slice(1, -1);
        return {
            type: "mixin",
            main: mxName,
            params: this.collapse(mixinParamCode)
        }
    }

    public collapseObject(s: string): Array<any> {
        const elements = this.collapse(s);
        const parsedElements = elements.map(e => {
            let k = "";
            const v = new SRCReader(e[1]).also(r => k = r.nLookAhead(s => s === ":")).remainder().slice(1);
            return ["object:entry", k, this.collapse(v)]
        });
        return ["object", parsedElements];
    }

    public collapseArray(s: string): Array<any> {
        return ["array", this.collapse(s)];
    }

    public collapseVariableDeclaration(s: string): Array<any> {
        s = s.slice(3).trim();
        let name = "";
        const v = new SRCReader(s).also(r => name = r.nLookAhead(s => s === "=")).remainder().slice(1);
        return ["var:declare", name.trim(), this.collapse(v)]
    }
}
