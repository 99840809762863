import React from "react";
import {KeyRecording} from "./KeyCommandTest";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {Triton} from "../../../../triton/Triton";
import {useTriton} from "../../../../triton/TritonHooks";

const StyledKeyRecordingChip = styled.div<{
    t: Triton
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  gap: 8px;
  // background-color: #1e202a;
  background-color: ${p => p.t.col("layout_300")};
  border-radius: .3rem;
  height: 24px;
  
  span {
    font-family: monospace;
  }
`;

export type KeyRecordingChipProps = {
    kr: KeyRecording
}

export const KeyRecordingChip: React.FC<KeyRecordingChipProps> = props => {
    const kr = props.kr;
    const op = kr.option;
    const t = useTriton();

    return (
        <StyledKeyRecordingChip t={t}>
            <DescriptiveTypography text={`${kr.key} (${kr.node.config.title?.substring(0, 1) ?? '?'})`} noSelect style={{
                // fontWeight: "bold"
            }}/>
            { (!op && kr.node.hasOptions()) && (
                <DescriptiveTypography text={"Choose an option.."} noSelect style={{
                    fontStyle: "italic"
                }}/>
            ) }
            { op && (
                <DescriptiveTypography text={op.title ?? op.value} noSelect/>
            ) }
        </StyledKeyRecordingChip>
    );
}
