import {Token} from "./Token";
import {UGLInstructionContext} from "./UGLInstructionContext";
import {logInstruction} from "./lib/debug/LogInstruction";
import {MemStack} from "./MemStack";
import {UGLInstructionReactor} from "./UGLInstructionReactor";

const helloWorldLiteralSupplierInstruction: UGLInstructionReactor = {
    run: () => {
        return "Hello world!"
    }
}

const forInstruction: UGLInstructionReactor = {
    run: (instruction, ctx) => {
        const loopCount: Token = instruction.params![0];
        const loopCountNumber = loopCount.data;
        if (loopCountNumber < 1) return;
        const lambda: Token = instruction.params![1];
        if (lambda.params === undefined) return;
        for (let i = 0; i < loopCountNumber; i++) {
            new UGLTestRuntime().execute(lambda.params ?? []);
        }
    }
}

const runIfInstruction: UGLInstructionReactor = {
    run: (instruction, ctx) => {
        const activationValue = ctx.params[0] ?? false;
        const fun = instruction.params?.[1];
        if (!(activationValue as boolean) || fun === undefined) return;
        new UGLTestRuntime().execute(fun.params ?? []);
    }
}

export class UGLTestRuntime {

    public execute(script: Array<Token>) {
        this.executeSubScript(script);
    }

    public executeSubToken(token: Token): any {
        return this.executeSubScript([token])[0];
    }

    public executeSubScript(script: Array<Token>): Array<any> {
        return script.map(t => {
            const collapsedParams: Array<any> = t.params?.map(pT => {
                return this.executeSubToken(pT);
            }) ?? [];

            return this.processToken(
                t,
                new class implements UGLInstructionContext {
                    mem = new MemStack();
                    params = collapsedParams
                }()
            );
        });
    }

    private processToken(token: Token, ctx: UGLInstructionContext): any {
        let res: any = null;
        switch (token.type) {
            case "mixin": {
                if (token.main === "log") {
                    res = logInstruction.run(token, ctx);
                }
                if (token.main === "hw") {
                    res = helloWorldLiteralSupplierInstruction.run(token, ctx);
                }
                if (token.main === "for") {
                    res = forInstruction.run(token, ctx);
                }
                if (token.main === "runIf") {
                    res = runIfInstruction.run(token, ctx);
                }
                break;
            }
        }

        return res;
    }
}
