import React, {useState} from "react";
import {Post} from "./models/Post";
import styled from "styled-components";
import {PostCard} from "./PostCard";
import {PostFullscreenView} from "./PostFullscreenView";

const StyledPostView = styled.div`
`;

export type PostViewProps = {
    post: Post,
}

export const PostView: React.FC<PostViewProps> = props => {
    const post = props.post;
    const [expanded, setExpanded] = useState(false);

    return (
        <StyledPostView className={"post-view-main"}>
            <PostCard
                post={post}
                onPostSelect={() => setExpanded(true)}
            />
            <PostFullscreenView
                post={post}
                expanded={expanded}
                onCloseFullscreenView={() => setExpanded(false)}
            />
        </StyledPostView>
    );
}
