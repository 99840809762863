import React, {useEffect} from "react";
import {KSU} from "../../Simulation2Main";
import {AzypodState} from "./AzypodEntity";
import {StyledControlDisplayGroup} from "../../components/StyledControlDisplayGroup";
import {Interpolate} from "../../../../triton/components/advanced/Interpolate";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {GenericValveIndicator} from "../../GenericValveIndicator";
import styled from "styled-components";
import {ButtonBase} from "../../../../triton/components/buttons/ButtonBase";
import {AzypodIndicator} from "./AzypodIndicator";
import {Std} from "../../../../../Std";
import invlerp = Std.invlerp;
import lerp = Std.lerp;
import {motion, useMotionValue, useSpring, useTime, useTransform} from "framer-motion";
import {InterpolatedRotaryIndicator, RotaryIndicator} from "./RotaryIndicator";
import {Slider} from "@mui/material";
import {Entity} from "../../ecs/entity/Entity";
import {KVEntryDisplay} from "../../../../ardai/components/KVEntryDisplay";
import {InvertColorsRounded, NorthEastRounded, NorthRounded, UTurnRightRounded} from "@mui/icons-material";
import {Button} from "../../components/Button";
import {as} from "../../../../atlas/Lang";
import {PIDController} from "../../math/PIDController";
import {hex} from "../../../../base/logic/style/Color";

const StyledAzypodControlPanel = styled.div` 
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  .indicator-panel {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
  
  .controls {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
  }
  
  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(-360deg);
    }
  }
`;

export type AzypodControlPanelProps = {
    entity: Entity<AzypodState>
}

export const AzypodControlPanel: React.FC<AzypodControlPanelProps> = props => {
    const e = props.entity;

    const grossKSU = e?.simulation?.entity("engine").state[KSU] ?? 0
    const isRotating = grossKSU > 0;

    const maxRotationTimeMS = 5e3, minRotationTimeMS = 1e3;
    const maxKSU = 1e2, minKSU = 0;
    const onScale = invlerp(minKSU, maxKSU, grossKSU);
    let rotationTimeMS = Math.floor(maxRotationTimeMS - lerp(minRotationTimeMS, maxRotationTimeMS, onScale));
    if (grossKSU === maxKSU) rotationTimeMS = minRotationTimeMS;

    const actualDeg = e?.state?.actualDeg ?? 0;
    const requestedDeg = e?.state?.requestedDeg ?? 0;
    const isDeviating = actualDeg !== requestedDeg;

    return (
        <StyledControlDisplayGroup>
            <StyledAzypodControlPanel>

                <div className={"indicator-panel"}>
                    <AzypodIndicator
                        actualAngle={actualDeg}
                        requestedAngle={requestedDeg}
                        shaftKSU={grossKSU}
                    />

                    {/* <RotaryIndicator rpm={rotationTimeMS}/> */}
                    <InterpolatedRotaryIndicator
                        rotating={grossKSU > 0}
                        rpm={grossKSU}
                        // rpm={2}
                    />
                </div>

                <div style={{ display: "grid", gap: 8, gridTemplateColumns: "repeat(2, 1fr)" }}>
                    <StyledControlDisplayGroup>
                        <Slider value={-actualDeg} min={-180} max={180} orientation={"horizontal"} size={"small"} sx={{
                            transition: "color 1s",
                            color: isDeviating ? "#ffdf60" : (
                                // !isRotating ? "#f14234" :
                                !isRotating ? "rgba(201, 209, 217, 0.1)" :
                                    (actualDeg % 90 === 0) ? "#9148e1" :
                                        ("rgb(88, 166, 255)")
                            ),
                        }}/>
                    </StyledControlDisplayGroup>

                    <div style={{ display: "grid", justifyContent: "center", alignItems: "center", gap: 8, gridTemplateColumns: "repeat(2, 1fr)" }}>
                        {/*
                        <ButtonBase children={<DescriptiveTypography text={"-5"}/>} baseProps={{
                            style: {
                                padding: 0,
                                // aspectRatio: "1 / 1"
                            },
                            onClick: () => e.setStatePartially({
                                requestedDeg: e.state.requestedDeg - 5
                            })
                        }}/>
                        */}

                        {/*
                        <ButtonBase children={<DescriptiveTypography text={"+5"}/>} baseProps={{
                            style: {
                                padding: 0,
                                // aspectRatio: "1 / 1"
                            },
                            onClick: () => e.setStatePartially({
                                requestedDeg: e.state.requestedDeg + 5
                            })
                        }}/>
                        */}

                        <Button children={"-5"} onClick={() => e.setStatePartially({
                            requestedDeg: e.state.requestedDeg - 5
                        })}/>

                        <Button children={"+5"} onClick={() => e.setStatePartially({
                            requestedDeg: e.state.requestedDeg + 5
                        })}/>
                    </div>
                </div>


                <StyledControlDisplayGroup>
                    <DescriptiveTypography text={
                        `REQ: ${(e?.state?.requestedDeg ?? 0).toFixed(2)}`
                    }/>
                </StyledControlDisplayGroup>
                <StyledControlDisplayGroup>
                    <DescriptiveTypography text={
                        `ACT: ${(e?.state?.actualDeg ?? 0).toFixed(2)}`
                    }/>
                </StyledControlDisplayGroup>

                <Interpolate value={(((e?.state as any)?.[KSU] as any) ?? -1) as number} children={d => (
                    <KVEntryDisplay title={"KSU"} enableCopy={false} value={
                        `${d}`
                    }/>
                )}/>





                <div className={"controls"}>
                    {[-45, 45, 90].map(d => (
                        <ButtonBase children={<DescriptiveTypography text={d}/>} baseProps={{
                            style: {
                                padding: 0,
                                // aspectRatio: "1 / 1"
                            },
                            onClick: () => e.setStatePartially({
                                requestedDeg: d
                            })
                        }}/>
                    ))}

                    <Button children={<UTurnRightRounded/>} onClick={() => {
                        e.setStatePartially({
                            requestedDeg: (e.state.requestedDeg + 180) % 360
                        })
                    }}/>

                    <Button children={<NorthRounded/>} onClick={() => {
                        e.setStatePartially({
                            requestedDeg: 0
                        })
                    }}/>

                    <Button children={<InvertColorsRounded/>} onClick={() => {
                        e.setStatePartially({
                            requestedDeg: -e.state.requestedDeg
                        })
                    }}/>

                    {[20, 50, 90].map(maxRotationSpeed => (
                        <Button color={{
                            if: [
                                { cond: () => e.state.maxRotationSpeed === maxRotationSpeed, color: hex("#ffdf60") }
                            ]
                        }} children={`${maxRotationSpeed}`} onClick={() => {
                            e.setStatePartially({
                                maxRotationSpeed: maxRotationSpeed
                            })
                        }}/>
                    ))}

                </div>
            </StyledAzypodControlPanel>
        </StyledControlDisplayGroup>
    );
}
