import React from "react";
import styled from "styled-components";
import {ChevronRight} from "@mui/icons-material";
import {OrientationMapping, orientationToDegrees} from "./OrientationMapping";

export type StyledRotatableChevronIconProps = {
    rotation: number
}

const StyledRotatableChevronIcon = styled.div<StyledRotatableChevronIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  .view-opener {
    color: #949BA4;
    transition: transform ease-in-out .1s;
    transform: rotate(${p => p.rotation - 90}deg);
  }
`;

export type RotatableChevronIconProps = {
    collapsed?: boolean,
    offPositionDirection?: OrientationMapping,
    onPositionDirection?: OrientationMapping,
}

export const RotatableChevronIcon: React.FC<RotatableChevronIconProps> = props => {
    const offPositionDirection = props.offPositionDirection ?? OrientationMapping.EAST;
    const onPositionDirection = props.onPositionDirection ?? OrientationMapping.SOUTH;
    const offPDeg = orientationToDegrees(offPositionDirection);
    const onPDeg = orientationToDegrees(onPositionDirection);

    return (
        <StyledRotatableChevronIcon rotation={props.collapsed ? offPDeg : onPDeg} children={
            <ChevronRight className={"view-opener"} sx={{
                fontSize: 16
            }}/>
        }/>
    );
}
