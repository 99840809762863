import {APIShard} from "../APIShard";
import {legacyProjectVFD, VirtualFileDriver} from "./VirtualFileDriver";
import {VFSElement} from "../../data/VFSElement";
import {VirtualFileDriverOpeningResult} from "./VirtualFileDriverOpeningResult";

export class VirtualFileDriverManager extends APIShard {

    private readonly _drivers: Array<VirtualFileDriver> = new Array<VirtualFileDriver>();

    constructor() {
        super();
        this.registerDefaultVFDs();
    }

    private registerDefaultVFDs() {
        // (Legacy) project
        this.registerVirtualFileDriver(legacyProjectVFD);
    }

    /**
     * TODO: Check for duplicated
     *
     * @param vfd
     */
    public registerVirtualFileDriver(vfd: VirtualFileDriver): VirtualFileDriverManager {
        this._drivers.push(vfd);
        return this;
    }

    public findApplicableVFDs(file: VFSElement): Array<VirtualFileDriver> {
        return this.drivers.filter(vfd => vfd.canOpen?.(file) ?? true);
    }

    public async open(file: VFSElement): Promise<VirtualFileDriverOpeningResult> {
        const candidates = this.findApplicableVFDs(file);
        const vfd = candidates[0];

        if (vfd === undefined) {
            return {
                success: false
            }
        }

        else return await vfd.open(file, {
            api: this.api()
        });
    }

    get drivers(): Array<VirtualFileDriver> {
        return this._drivers;
    }
}
