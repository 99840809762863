import {as} from "../../../../atlas/Lang";
import {v4} from "uuid";
import {
    EntityConfig,
    EntityData,
    GenericEntityState,
    Logger,
    Pipe,
    SimulationLayer,
    StdEntityPipelineNamespace,
    TickAble
} from "../../Simulation2Main";
import {Trait, TraitIdentifier} from "./trait/Trait";
import {castEntityFull} from "../../SimStd";

export class Entity<T = GenericEntityState> implements TickAble {

    private static readonly defaultConfig: EntityConfig = {
        asyncTick: async e => {},
        syncTick: e => {},
        namedTraits: new Map<TraitIdentifier, Trait>(),
        loggerEnabled: false,
        children: [],
        groups: [],
        traits: []
    }

    private readonly _config: EntityConfig<T>;

    public pipelines: Map<string, Pipe<Entity>> = new Map<string, Pipe<Entity>>();

    public children: Map<string, Entity> = new Map<string, Entity>();

    public traits: Map<TraitIdentifier, Trait> = new Map<TraitIdentifier, Trait>();

    private _parent?: Entity;

    private _sim?: SimulationLayer;

    private _id: string;

    private _simulationID?: string

    private childEntitiesAwaitingSimulationIntegration: Array<Entity> = []

    private _groups: Array<string> = []

    private _logger: Logger = new Logger(this);

    constructor(id?: string, config: Partial<EntityConfig<T>> = {}) {
        this._config = as<any>({
            ...Entity.defaultConfig,
            ...config
        });
        this._id = id ?? v4();
        this.init();
    }

    init() {
        this._groups = this.config.groups;
        this._logger.enabled = this.config.loggerEnabled ?? false;

        this.config.traits?.forEach(trait => {
            this.registerTrait(trait.traitName, trait);
        });

        this.config.namedTraits?.forEach((trait, name) => {
            this.registerTrait(name, trait);
        });
    }

    public registerTrait(name: string, trait: Trait) {
        trait.entity = this;
        this.traits.set(name, trait);
    }

    public hasTrait(traitName: TraitIdentifier): boolean {
        return this.traits.has(traitName);
    }

    public getTrait<TraitType extends Trait>(traitName: TraitIdentifier): TraitType {
        return this.traits.get(traitName)! as TraitType;
    }

    public setParent(parent: Entity<any>): Entity<T> {
        this._parent = parent;
        return this;
    }

    public setSimulationID(simID: string) {
        this._simulationID = simID;
    }

    public setID(id: string) {
        this._id = id;
    }

    public integrateIntoSimulation(sim: SimulationLayer) {
        this.setSim(sim);
        if (this.config.children.length > 0) {
            this.addChildren(...this.config.children);
        }
        this.children.forEach(c => c.setSim(sim));
        sim.addEntities(false, ...this.childEntitiesAwaitingSimulationIntegration);
        this.childEntitiesAwaitingSimulationIntegration = [];
    }

    public setSim(sim: SimulationLayer) {
        this._sim = sim;
    }

    public get state(): EntityData<T> {
        const rawState: any = this.simulation.state.entities[this.simulationID] ?? {
            id: this.id
        };

        return as<EntityData<T>>(rawState);
    }

    public setState(updater: (prevState: EntityData) => EntityData) {
        this.simulation.setState(prevState => ({
            ...prevState,
            entities: {
                ...prevState.entities,
                [this.simulationID]: updater(prevState.entities[this.simulationID] ?? {})
            }
        }));
    }

    public setStatePartially(value: ((prevState: EntityData<T>) => Partial<EntityData<T>>) | Partial<EntityData<T>>) {
        this.simulation.setState(prevState => {
            let newPartialState = typeof value === "function" ? value(prevState.entities[this.simulationID] as any) : value;
            return ({
                ...prevState,
                entities: {
                    ...prevState.entities,
                    [this.simulationID]: {
                        ...prevState.entities[this.simulationID],
                        ...newPartialState
                    }
                }
            });
        });
    }

    public out(key: string | StdEntityPipelineNamespace): Pipe<Entity> {
        let p = this.pipelines.get(key);
        if (p !== undefined) return p;
        p = new Pipe<any>();
        this.pipelines.set(key, p);
        return p;
    }

    /**
     *
     */
    async tick(): Promise<void> {
        Array.from(this.traits.values()).map(trait => trait.tick());
        // Perform sync tick
        this.syncTick();
        // Perform async tick
        await this.asyncTick();

        // TODO: Check if children should tick before this?
        this.children.forEach(c => {
            c.tick();
        });
    }

    async asyncTick(): Promise<void> {
        await this.config.asyncTick(this);
    };

    syncTick(): void {
        this.config.syncTick(this);
    }

    /**
     * T | { [K: string]: any }
     *  -> { [K: string]: any } for compatibility and advanced automation purposes
     */
    public getInitialState(): T | GenericEntityState {
        return this.config.initialState ?? {};
    }

    /**
     * TODO: Check return val -> setState after return
     * Returns the next value of the field. <br/>
     * Operand: number
     *
     * @param dx The field to be added to
     * @param d The amount added to the fields value
     * @param fieldDefVal A default base field value, used if the actual field value is not defined.
     * @param max
     */
    public addToField(dx: string, d: number, fieldDefVal: number = 0, max: number = Number.MAX_VALUE): number {
        let nextVal: number = fieldDefVal;
        this.setState(prevState => {
            nextVal = (prevState[dx] ?? fieldDefVal) + d;
            return ({
                ...prevState,
                [dx]: Math.min(nextVal, max)
            });
        });
        return nextVal;
    }

    /**
     * TODO: Write docs
     * <br/>
     * Operand: number
     *
     * @param dx ?
     * @param d ?
     * @param min ?
     * @param fieldDefVal ?
     */
    public async reduceField(dx: string, d: number, min: number = Number.MIN_VALUE, fieldDefVal: number = 0): Promise<{
        nVal: number,
        redBy: number
    }> {
        return new Promise((resolve) => {
            if (d === 0) resolve({
                nVal: ((this.state as any)[dx]) ?? fieldDefVal,
                redBy: 0
            });

            this.setState(prevState => {
                let [nVal, nValNormalized] = [-1, -1];
                nVal = (prevState[dx] ?? fieldDefVal) - d;
                nValNormalized = Math.max(nVal, min);
                resolve({
                    nVal: nVal,
                    redBy: nVal >= min ? d : d + nVal
                });
                return ({
                    ...prevState,
                    [dx]: nValNormalized
                });
            })
        });
    }

    public addChildren(...entities: Array<Entity<any>>): Entity<T> {
        entities.forEach(e => {
            this.children.set(e.id, e);
            e.setParent(this);
        });

        if (this.simulation === undefined) {
            this.childEntitiesAwaitingSimulationIntegration.push(...entities);
        } else {
            this.simulation.addEntities(false, ...entities);
        }

        return this;
    }

    /**
     * TODO: Add "kind"-checking
     */
    public as<Type extends Entity<any>>(): Type {
        return castEntityFull<Type>(this);
    }

    public getChild(id: string): Entity {
        return this.children.get(id)!;
    }

    public getChildrenFromGroup(group: string): Array<Entity> {
        return Array.from(this.children.values()).filter(c => c.groups.includes(group));
    }

    public get simulation(): SimulationLayer {
        return this._sim!;
    }

    get parent(): Entity | undefined {
        return this._parent;
    }

    get config(): EntityConfig<T> {
        return this._config as EntityConfig<T>;
    }

    get simulationID(): string {
        return this._simulationID!;
    }

    get id(): string {
        return this._id!;
    }

    get groups(): Array<string> {
        return this._groups;
    }

    get logger(): Logger {
        return this._logger;
    }
}
