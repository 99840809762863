import React, {CSSProperties, HTMLAttributes} from "react";
import styled from "styled-components";

const StyledDescriptiveTypography = styled.span`
  font-size: 12px;
  color: rgb(139, 148, 158);
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  line-height: 1.5;
  word-wrap: break-word;
`;

export type DescriptiveTypographyProps = {
    text?: React.ReactNode,
    style?: CSSProperties,
    baseProps?: HTMLAttributes<any>,
    noSelect?: boolean
}

export const DescriptiveTypography: React.FC<DescriptiveTypographyProps> = props => {
    return (
        <StyledDescriptiveTypography
            className={"triton-typography-secondary"}
            children={props.text}
            {...props.baseProps}
            style={{
                userSelect: props.noSelect ? "none" : "auto",
                ...props.style,
            }}
        />
    );
}
