import React, {CSSProperties, PropsWithChildren} from "react";
import styled from "styled-components";

const StyledScrollView = styled.div`
  scroll-snap-align: start;
  
  width: 100vw !important;
  height: 100% !important;
  flex-shrink: 0;
  
  display: grid;
  
  overflow: hidden;
  
`;

export type ScrollViewProps = PropsWithChildren<{
    style?: CSSProperties,
    id?: string
}>

export const ScrollView: React.FC<ScrollViewProps> = props => {
    return (
        <StyledScrollView
            id={props.id}
            style={props.style}
            children={props.children}
        />
    );
}
