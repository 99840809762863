import {FC, PropsWithChildren} from "react";
import {SocialLinkData} from "../SocialLinkData";
import styled from "styled-components";
import {useTriton} from "../../../triton/TritonHooks";
import {Triton} from "../../../triton/Triton";

const StyledSocialsIcon = styled.div<{
    t: Triton
}>`
  svg {
    height: 20px;
    transition: all .1s;
    cursor: pointer;
    color: ${p => p.t.col("icon_default")};
  }
`;

export const SocialsIcon: FC<PropsWithChildren<{
    for: SocialLinkData
}>> = props => {
    const t = useTriton();
    return (
        <StyledSocialsIcon t={t}>
            { props.children }
        </StyledSocialsIcon>
    );
}
