import React from "react";
import styled from "styled-components";
import {useTriton} from "../../triton/TritonHooks";
import {Checkbox} from "@mui/material";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {HelpHint} from "./HelpHint";

const StyledControlledBooleanInput = styled.div`
  .input {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
`;

export type ControlledBooleanInputProps = {
    value: boolean,
    title: string,
    update: (bool: boolean) => void,

    helpText?: string,
    resetValue?: boolean,
}

export const ControlledBooleanInput: React.FC<ControlledBooleanInputProps> = props => {
    const t = useTriton();

    const change = (bool: boolean = !props.value) => {
        props.update(bool);
    }

    return (
        <StyledControlledBooleanInput>
            <div className={"input"} onClick={() => change()}>
                <Checkbox checked={props.value} onChange={(event, checked) => {change(checked)}} sx={{
                    padding: "4px",
                    color: "rgb(139, 148, 158)",
                    '&.Mui-checked': {
                        color: t.col("color_primary"),
                    },
                }}/>
                <MainTypography text={props.title} style={{
                    cursor: "pointer",
                    userSelect: "none",
                    fontWeight: "normal"
                }}/>
                <HelpHint text={props.helpText}/>
            </div>
        </StyledControlledBooleanInput>
    );
}
