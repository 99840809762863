// noinspection SpellCheckingInspection

import {NodeSetupInfo} from "../../../NodeSetupInfo";
import {Node} from "../../../../backend/Node";
import {v4} from "uuid";
import {PinMode} from "../../../../backend/Pin";
import {Serializable} from "../../../../../../std/Serializable";
import {SDDefaultSamplers} from "../../../../../imageSorter/sdInterface/SDDefaultSamplers";
import axios from "axios";

type StableDiffusionParameters = {
    prompt: string,
    negativePrompt: string,
    contentFreeGuidance: number,
    samplerSteps: number,
    sampler: string,
    width: number,
    height: number
    // clipSkip: number,
}

export type NodeT2IState = {
    parameters: StableDiffusionParameters
}

export const NodeT2I: NodeSetupInfo<NodeT2IState> = {
    label: "NodeT2I",
    classname: "tests.sdwui.t2i",
    parameterConfig: [],
    factory: parameters => new Node<NodeT2IState>({
        id: v4(),
        classname: "tests.sdwui.t2i",
        label: "t2i",
        defInPins: [
            "c"
        ],
        defOutPins: [
            "img",

            "stat",
            "body"
        ],
        state: {
            parameters: {
                prompt: "",
                negativePrompt: "",
                width: 512,
                height: 512,
                sampler: "UniPC",
                samplerSteps: 50,
                contentFreeGuidance: 7,
                // clipSkip: 2
            }
        },
        init() {
            const pinToStateDict: { [Key in keyof StableDiffusionParameters]: string } = {
                prompt: "prompt+",
                negativePrompt: "prompt-",
                sampler: "sampler",
                samplerSteps: "steps",
                contentFreeGuidance: "cfg",
                width: "w",
                height: "h"
            }

            const pinTobackendDict: { [Key in keyof StableDiffusionParameters]: string } = {
                prompt: "prompt",
                negativePrompt: "negative_prompt",
                sampler: "sampler_name",
                samplerSteps: "steps",
                contentFreeGuidance: "cfg_scale",
                width: "width",
                height: "height"
            }

            const params = this.pins.createPinBank("params", {
                label: "params",
                defaultPinMode: PinMode.IN,
                hideLabelFlag: true
            });
            Object.entries(pinToStateDict).forEach(entry => {
                params.pin(entry[1]).attachOnRead(data => {
                    this.state.update(prevState => ({
                        parameters: {
                            ...prevState.parameters,
                            [entry[0]]: data
                        }
                    }));
                })
            });

            this.pins.in("c").attachOnRead(async () => {
                const reqBodyObj: any = {};
                Object.entries(this.state.state.parameters).forEach(entry => {
                    const translatedKey = pinTobackendDict[entry[0] as keyof StableDiffusionParameters];
                    reqBodyObj[translatedKey] = entry[1];
                });

                const res = await axios.post("http://localhost:7860/sdapi/v1/txt2img", reqBodyObj, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                });

                const body = res.data;
                const img = body.images[0];
                this.pins.out("img").write(img);
                this.pins.out("body").write(body);
                this.pins.out("stat").write(res.status);
            });
        }
    })
}
