import {LanguageParserPipeline} from "../../ardai/promptlang/LanguageParserPipeline";
import {SDPromptMixin} from "./SDPromptMixin";
import {webDB} from "../../ardai/webapi/WebAPIDB";

export class SDPromptEngine extends LanguageParserPipeline {

    private readonly mixins: Array<SDPromptMixin> = [];

    constructor() {
        super();
        this.initInternalMixins();
        this.initPipeline();
    }

    private initInternalMixins() {
        this.mixin({
            key: "pick",
            func: (ctx, parameters) => {
                if (parameters.length === 0) return "";
                const i = this.randomInt(0, parameters.length - 1);
                return parameters[i]
            }
        })

        this.mixin({
            key: "flicker",
            func: (ctx, parameters) => {
                if (parameters.length === 0) return "";
                const show = this.randomInt(0, 1) === 1;
                return show ? parameters[0] : "";
            }
        })
    }

    public async initUserMixins(): Promise<SDPromptEngine> {
        await webDB.mixins.each((data, cursor) => {
            this.mixin({
                key: data.key,
                // TODO: Add support for LINK & FUNC mixins
                func: () => data.target
            });
        });
        return this;
    }

    private initPipeline() {
        this
            // Delete inline comments / multiline comments
            .segmentFunc(ctx => ctx.cmd = ctx.cmd.replaceAll(/\/\*.*\*\//g, ""))
            // Delete empty lines,
            .segmentFunc(ctx => ctx.cmd = ctx.cmd.split("\n").filter(s => s.trim().length > 0).join("\n"))
            // Delete comment lines
            .segmentFunc(ctx => ctx.cmd = ctx.cmd.split("\n").filter(s => !s.trim().startsWith("#")).join("\n"))
            .segmentFunc(ctx => {
                const simplexParam = /:[\w_:]+/g, multiParam = /\([\w_:\s,.]*\)/g;
                const mixinRegex = /@\w+((:[\w_:]+)|(\([\w_:\s,.]*\)))?/g;
                Array.from(ctx.cmd.matchAll(mixinRegex)).forEach(value => {
                    const fullTerm = value["0"];
                    const term = fullTerm.substring(1);
                    const key = Array.from(term.matchAll(/\w+/g))[0]["0"]
                    let param = term.replace(key, "");
                    const isSimplex = simplexParam.test(param);
                    const isMulti = multiParam.test(param);
                    if (isMulti) param = param.slice(1, -1).trim();
                    if (isSimplex) param = param.substring(1);
                    const paramArray = param.split(",").map(s => s.trim());
                    const mixin = this.mixins.find(m => m.key === key);
                    if (mixin === undefined) {
                        ctx.cmd = ctx.cmd.replace(fullTerm, "");
                        return;
                    }
                    // TODO: Make recursive call if 'generated'-value contains mixins itself
                    const generated = mixin.func(ctx, paramArray);
                    ctx.cmd = ctx.cmd.replace(fullTerm, generated);
                })
            })
            // Clear empty prompt shards (, , , ,) & remove line breaks
            .segmentFunc(ctx => ctx.cmd = ctx.cmd.split(",").map(s => s.trim()).filter(s => s.length > 0).join(", "))
            // Replace invoke-ai styled shard weights
            .segmentFunc(ctx => ctx.cmd = this.mapShads(ctx.cmd, s => {
                if (!/\w+(-+|\++)/g.test(s)) return s;
                if (s.endsWith("+")) {
                    const mag = /\++/g.exec(s)?.["0"]?.length ?? 0;
                    const factorizedMag = 1 + mag * .1;
                    return `(${s.replaceAll("+", "")}:${
                        Math.floor(factorizedMag * 10) / 10
                    })`;
                } else {
                    const mag = /-+/g.exec(s)?.["0"]?.length ?? 0;
                    const factorizedMag = 1 - mag * .1;
                    return `(${s.replaceAll("-", "")}:${
                        Math.ceil(factorizedMag * 10) / 10
                    })`;
                }
            }))
    }

    public mixin(mixin: SDPromptMixin): SDPromptEngine {
        this.mixins.push(mixin);
        return this;
    }

    private mapShads(s: string, mapper: (s: string) => string): string {
        return s
            .split(",")
            .map(s => s.trim())
            .map(s => mapper(s))
            .join(", ")
    }

    private randomInt(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}
