import React, {useContext, useState} from "react";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import Editor from "@monaco-editor/react";
import styled from "styled-components";
import {SearchLogic} from "../logic/SearchLogic";
import {webDB} from "../webapi/WebAPIDB";
import {Image} from "../data/Image";
import {ISADBImageGrid} from "./ISADBImageGrid";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {IconButton} from "./IconButton";
import {CloseRounded, MoreVertRounded, SelectAllRounded} from "@mui/icons-material";
import {ImagePreview} from "./ImagePreview";
import {ArdaiAPIContext} from "../webapi/WebAPI";

export type SearchbarState = {
    intellisenseTrayOpened: boolean,
    focused: boolean,
    filteredImages?: Image[]
}

const StyledSearchbar = styled.div`
  width: 100%;
  position: relative;
  // height: min-content;
  
  .searchbar-input {}
`;

export const Searchbar: React.FC = props => {
    const api = useContext(ArdaiAPIContext);
    const [state, setState] = useState<SearchbarState>({
        intellisenseTrayOpened: false,
        focused: false
    });

    return (
        <StyledSearchbar onBlur={(event) => {
            if (!event.currentTarget.contains(event.relatedTarget)) {
                setState(prevState => ({ ...prevState, focused: false }));
            }
        }}>
            <div style={{
                width: "100%",
                height: "60px",
                backgroundColor: "#101016",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }} onClick={event => {
                // setState(prevState => ({ ...prevState, focused: !prevState.focused }));
            }} children={
                <Editor
                    className={"searchbar-input"}
                    height={"22px"}
                    width={"100%"}
                    saveViewState
                    options={{
                        fontSize: 16,
                        fontLigatures: true,
                        lineNumbers: "off",
                        autoIndent: "full",
                        codeLens: false,
                        autoClosingBrackets: "always",
                        autoClosingQuotes: "always",
                        hideCursorInOverviewRuler: true,
                        cursorSmoothCaretAnimation: true,
                        lineDecorationsWidth: 0,
                        renderValidationDecorations: "off",
                        overviewRulerBorder: false,
                        renderLineHighlight: "none",
                        cursorStyle: "underline",
                        matchBrackets: "always",
                        scrollbar: {
                            vertical: "hidden",
                            horizontal: "hidden"
                        },
                        minimap: {
                            enabled: false
                        },
                    }}
                    onChange={(value, ev) => {

                    }}
                    onMount={(editor, monaco) => {
                        editor.onKeyDown(e => {
                            if (e.code === "Enter") e.preventDefault();
                        });

                        editor.onKeyDown(async e => {
                            if (e.code === "Enter") {
                                const filtered = await new SearchLogic().parseImageQuery(editor.getValue(), api)(await webDB.images.toArray());
                                setState(prevState => ({
                                    ...prevState,
                                    filteredImages: filtered,
                                    focused: filtered.length > 0
                                }));
                                editor.focus();
                            }
                        }, this)
                    }}
                    beforeMount={monaco => {
                        monaco.languages.register({ id: "ardai-search-lang" });

                        monaco.languages.setLanguageConfiguration("ardai-search-lang", {
                            brackets: [
                                ["{", "}"],
                                ["(", ")"],
                                ["{", "}"],
                                ["<", ">"],
                                ["'", "'"],
                                ["\"", "\""],
                            ],
                        })

                        monaco.languages.setMonarchTokensProvider("ardai-search-lang", {
                            tokenizer: {
                                root: [
                                    // function sugar
                                    [/:limit/, "macro"],
                                    // Keywords & base highlighting
                                    [/\{.*}/, "macro"],
                                    [/!/, "symbol"],
                                    [/:[\w<>=]+/, "keyword"],
                                    [/#\w+/, "tag"],
                                    [/-\w+/, "param"],
                                    [/\$\w+/, "variable"],
                                    [/@\w+/, "annotation"],
                                    [/->/, "arrow-right"],
                                    [/=>/, "arrow-right"],
                                    [/-/, "bullet-point"],
                                    [/:/, "double-point"],
                                    [/;/, "semicolon"],
                                    [/(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)/, "number"],
                                    [/".*"/, "string"],
                                    [/'.*'/, "string"],
                                    // units
                                    [/mb/, "unit"],
                                    [/gb/, "unit"],
                                ]
                            }
                        });

                        monaco.editor.defineTheme("ses-x-dark-tritanopia-notes", {
                            base: "vs-dark",
                            inherit: true,
                            rules: [
                                { token: "arrow-right", foreground: "#A782BB" },
                                { token: "unit", foreground: "#A782BB" },
                                { token: "bullet-point", foreground: "#585858" },
                                { token: "double-point", foreground: "#585858" },
                                { token: "param", foreground: "#585858" },
                                { token: "symbol", foreground: "#CA7732" },
                                { token: "keyword", foreground: "#CA7732" },
                                { token: "semicolon", foreground: "#CA7732" },
                                { token: "method", foreground: "#FFC66D" },
                                { token: "tag", foreground: "#FFC66D" },
                                { token: "macro", foreground: "#FFC66D" },
                                { token: "variable", foreground: "#FFC66D" },
                                { token: "annotation", foreground: "#FFC66D" },
                                // { token: "number", foreground: "#5028c8" },
                                { token: "number", foreground: "#A782BB" },
                                // { token: "string", foreground: "#5028c8" },
                                { token: "string", foreground: "#A782BB" },
                            ],
                            colors: {
                                "editor.background": "#101016",
                                "editor.lineHighlightBackground":  "#101016",
                            }
                        });

                        function createDependencyProposals(range: any): Array<any> {
                            return [
                                {
                                    label: 'limit',
                                    kind: monaco.languages.CompletionItemKind.Function,
                                    documentation: "Limits the filter stack size",
                                    insertText: ':limit(${2})',
                                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                                    range: range,
                                },
                                {
                                    label: 'random',
                                    kind: monaco.languages.CompletionItemKind.Function,
                                    documentation: "Selects a random set of resources from the stack.",
                                    insertText: ':rnd(${2})',
                                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                                    range: range,
                                },
                                {
                                    label: 'favourite',
                                    kind: monaco.languages.CompletionItemKind.Function,
                                    documentation: "Filters only resources marked as favourite",
                                    insertText: ':favourite',
                                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                                    range: range,
                                },
                                {
                                    label: 'open',
                                    kind: monaco.languages.CompletionItemKind.Snippet,
                                    documentation: "Opens the first selected resources",
                                    insertText: '-o',
                                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                                    range: range,
                                },
                                {
                                    label: 'select',
                                    kind: monaco.languages.CompletionItemKind.Snippet,
                                    documentation: "Selects all resources in the stack",
                                    insertText: '-s',
                                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                                    range: range,
                                },
                                {
                                    label: 'void',
                                    kind: monaco.languages.CompletionItemKind.Snippet,
                                    documentation: "Voids the stack",
                                    insertText: '-v',
                                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                                    range: range,
                                },
                            ];
                        }

                        monaco.languages.registerCompletionItemProvider("ardai-search-lang", {
                            provideCompletionItems: function (model, position) {
                                const word = model.getWordUntilPosition(position);
                                const range = {
                                    startLineNumber: position.lineNumber,
                                    endLineNumber: position.lineNumber,
                                    startColumn: word.startColumn,
                                    endColumn: word.endColumn,
                                };
                                return {
                                    suggestions: createDependencyProposals(range),
                                };
                            },
                        });
                    }}
                    theme={"ses-x-dark-tritanopia-notes"}
                    language={"ardai-search-lang"}
                />
            }/>

            <TransitionGroup style={{
                position: "absolute",
                top: "calc(60px + 16px - 16px - 8px)",
                // boxShadow: "0 0 50px black",
                left: "0",
                width: "100%",
                zIndex: 10
            }} children={
                !state.focused ? undefined : (
                    <Collapse children={
                        <div style={{
                            width: "100%",
                            // height: "300px",
                            borderRadius: "8px",
                            backgroundColor: "#101016",
                            // border: "1px solid #313131",
                            padding: "1rem"
                        }} children={
                            state.filteredImages === undefined ? (
                                <DescriptiveTypography text={"No filtered images"}/>
                            ) : (
                                <div style={{  }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        width: "100%",
                                        justifyContent: "space-between"
                                    }}>
                                        <MainTypography text={"Search results"}/>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "4px"
                                        }}>
                                            <IconButton size={"small"} tooltip={"Select all"} onClick={() => {
                                                api.selectionManager.select(state.filteredImages?.map(i => i.id) ?? [])
                                            }} children={
                                                <SelectAllRounded/>
                                            }/>
                                            <IconButton size={"small"} children={
                                                <MoreVertRounded/>
                                            }/>
                                            <IconButton size={"small"} onClick={() => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    intellisenseTrayOpened: false,
                                                    focused: false,
                                                    filteredImages: []
                                                }))
                                            }} children={
                                                <CloseRounded/>
                                            }/>
                                        </div>
                                    </div>
                                    <ISADBImageGrid imageIDs={state.filteredImages.map(i => i.id)} imageRenderer={data => (
                                        <ImagePreview
                                            for={data}
                                            onClick={(event) => {
                                                if (event.ctrlKey) {
                                                    api.selectionManager.toggleSelection(data.id);
                                                    return;
                                                }
                                                api.selectImageByID(data.id);
                                            }}
                                            onRequestDelete={() => api.removeImageFromCurrentProject(data.id)}
                                        />
                                    )}/>
                                </div>
                            )
                        }/>
                    }/>
                )
            }/>
        </StyledSearchbar>
    );
}
