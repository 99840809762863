import {StorybookPage} from "../../../../storybook/StorybookPage";
import {passwordPage} from "./PasswordStoryPage";
import {Flex} from "../../../../Flex";
import {StorybookStaticStepIndicator} from "../../../../components/StorybookStaticStepIndicator";
import {TypoHeaderVisual} from "../../../../components/TypoHeaderVisual";
import {FormElement} from "../../../../../../../triton/components/forms/FormElement";
import {RoundedFormikDateInput} from "../../../../components/form/RoundedFormikDateInput";
import {ErrorMessage} from "../../../../../../../triton/components/forms/ErrorMessage";
import {RoundedMainTextButton} from "../../../../components/RoundedMainTextButton";
import {CircularProgress} from "@mui/material";
import {BoardingActionHint} from "../../../../components/BoardingActionHint";
import React from "react";
import {tosPage} from "./TermsAndPrivacyPage";

export const birthdatePage: StorybookPage<{ birthdate: string }> = {
    name: "birthdate",
    title: "Birthdate",
    backPage: () => passwordPage,
    initialValues: { birthdate: "" },
    header: (api, fp) => (
        <Flex g={30} baseProps={{
            style: {
                flexDirection: "column",
                alignItems: "center"
            }
        }}>
            <StorybookStaticStepIndicator step={3}/>
            <TypoHeaderVisual
                header={"What's your age"}
                descriptiveSubheader={"Please state your age below"}
                text={"We need to know how old you are, to provide you with the best and safest content"}
            />
        </Flex>
    ),
    page: (api, fp) => (
        <FormElement>
            <RoundedFormikDateInput
                fp={fp}
                name={"birthdate"}
            />
            <ErrorMessage formikProps={fp} name={"birthdate"}/>
        </FormElement>
    ),
    bottomActionPanel: (api, fp, isSubmitting) => (
        <RoundedMainTextButton
            type={"submit"}
            text={!fp.isSubmitting ? "Next" : (
                <CircularProgress size={22} thickness={5} style={{ color: "black" }}/>
            )}
            onClick={() => api.submitAndForward(tosPage)}
        />
    ),
    footer: (api) => (
        <BoardingActionHint question={"Already have an account?"} action={"Log In"} onClick={() => {}}/>
    )
}
