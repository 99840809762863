import React from "react";
import styled from "styled-components";
import {ColorLibrarySection} from "./ColorLibrarySection";
import {SubHeaderTypography} from "../typography/SubHeaderTypography";
import {DescriptiveTypography} from "../typography/DescriptiveTypography";
import {ColorTile} from "./ColorTile";
import {CategoryHeader} from "../../../ardai/_/CategoryHeader";

const StyledColorPaletteRenderer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  .color-section-header {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  
  .color-section-tile-container {
    display: grid;
    // gap: 8px;
    gap: 4px;
    // grid-template-columns: repeat(auto-fit, minmax(24px, 1fr));
    grid-template-columns: repeat(10, 1fr);
  }
`;

export type ColorPaletteRendererProps = {
    colorSection: ColorLibrarySection
}

export const ColorPaletteRenderer: React.FC<ColorPaletteRendererProps> = props => {
    const sc = props.colorSection;
    return (
        <StyledColorPaletteRenderer>
            <div className={"color-section-header"}>
                <SubHeaderTypography text={sc.name}/>
                { sc.description && (
                    <DescriptiveTypography text={sc.description}/>
                ) }
            </div>

            <div className={"color-section-tile-container"} children={
                sc.colors.map(c => (
                    <ColorTile color={c}/>
                ))
            }/>
        </StyledColorPaletteRenderer>
    );
}
