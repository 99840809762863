import React, {useContext} from "react";
import {ArdaiAPIStateContext} from "../ArdaiMain";
import {Route, Routes, useLocation} from "react-router-dom";
import {useTheme} from "../../triton/TritonHooks";
import {TritonContext} from "../../triton/TritonContext";
import {ScreenSaver} from "../components/shared/screenSaver/ScreenSaver";
import {NotificationCenterWrapperTest} from "../_/notifications/test/NotificationCenterTest";
import {CommandContext, CommandNode, CommandNodeOption, KeyCommandTest} from "../_/keyCommand/test/KeyCommandTest";
import {hex} from "../../base/logic/style/Color";
import {AppHeader} from "../components/desktop/AppHeader";
import {GlobalStyles} from "@mui/material";
import {GalleryView} from "../views/gallery/GalleryView";
import {GenerationView} from "../views/generate/GenerationView";
import {Workspace} from "../components/Workspace";
import {TestScriptingView} from "../unifiedGraphicsLanguage/TestScriptingView";
import {TestingViewMain} from "../testing/TestingViewMain";
import {default2MockupUserProfileProps, UserProfile, UserProfileProps} from "../testing/tests/profile/UserProfile";
import {DType} from "../testing/DType";
import {Background} from "../background/Background";
import {BackgroundType} from "../background/BackgroundType";
import {as} from "../../atlas/Lang";
import {BackgroundSolidInformation} from "../background/BackgroundSolidInformation";
import {BackgroundImageInformation} from "../background/BackgroundImageInformation";
import {ImageRenderingMode} from "../../atlas/hyperion/datatypes/ImageRenderingMode";
import {MobileNavigation} from "./navigation/MobileNavigation";
import {Simulation} from "../../ship/test/core/Simulation";
import {Triton} from "../../triton/Triton";
import styled from "styled-components";
import {MobileGalleryView} from "../views/gallery/MobileGalleryView";

const StyledMobileArdaiMain = styled.div`
  overflow: hidden;
  width: 100vw;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  .ardai-main-wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
  }
  
  .ardai-main-nav {
    flex-shrink: 0;
  }
`;

export const MobileArdaiMain: React.FC = props => {
    const state = useContext(ArdaiAPIStateContext);
    const theme = useTheme();
    const location = useLocation();
    const triton = useContext(TritonContext);

    return (
        <StyledMobileArdaiMain>
            <MobileArdaiMainUtilsAndStyle triton={triton}/>

            <div className={"ardai-main-wrapper"} id={"ardai-main-wrapper"} children={
                <Routes location={location}>
                    <Route path={"/"} element={<MobileGalleryView/>}/>
                    <Route path={"/gallery"} element={<MobileGalleryView/>}/>
                    <Route path={`/generate`} element={<GenerationView/>}/>

                    {/* Development views/routes - TODO: Only make accessible in development mode */}
                    <Route path={`/development/scripting`} element={
                        <Workspace config={{ mode: "desktop", name: "scripting-test" }} children={
                            <TestScriptingView/>
                        }/>
                    }/>
                    <Route path={`/development/component`} element={<TestingViewMain<UserProfileProps> test={{
                        defaultProps: default2MockupUserProfileProps,
                        generateComponent: p => <UserProfile {...p}/>,
                        propertyDTypeLookup: {
                            primaryColor: DType.COLOR,
                            secondaryColor: DType.COLOR,
                            backgroundColor: DType.COLOR,
                            canvasOverlayColor: DType.COLOR,
                            bgSecondaryColor: DType.COLOR,
                            fontColor: DType.COLOR,
                        }
                    }}/>}/>
                </Routes>
            }/>

            <div className={"ardai-main-nav"} id={"ardai-main-nav"} children={
                <MobileNavigation/>
            }/>



            <Background config={{
                type: BackgroundType.SOLID,
                data: as<BackgroundSolidInformation>({
                    // color: "#0E0E0E"
                    color: triton.col("bg_main")
                })
                // type: BackgroundType.IMAGE,
                // data: as<BackgroundImageInformation>({
                //     opacity: 1,
                //     blur: 0,
                //     srcType: "url",
                //     renderingMode: ImageRenderingMode.AUTO,
                //     // srcLink: "https://i.pinimg.com/originals/b8/2f/28/b82f28a7e9c8fcb3868d3d94652c107c.gif"
                //     srcLink: "https://cdn.discordapp.com/attachments/1097192990432317510/1156527343435780106/00044-1044527989.png?ex=6517ee85&is=65169d05&hm=2e285b11b67b6bac6a36d0531f3d617fcc359cd520102e17f3c4b0afe77cec00&"
                // })
            }}/>
        </StyledMobileArdaiMain>
    );
}

const MobileArdaiMainUtilsAndStyle: React.FC<{
    triton: Triton
}> = props => (<>
    {/* Styles for links */}
    <GlobalStyles styles={{
        "a:focus, button:focus, [role=button]:focus, input[type=radio]:focus, input[type=checkbox]:focus": {
            outline: "2px solid mediumpurple",
            outlineOffset: "-2px",
            boxShadow: "none",
            transition: "outline 0ms !important"
        },
        "a:focus-visible, button:focus-visible, [role=button]:focus-visible, input[type=radio]:focus-visible, input[type=checkbox]:focus-visible": {
            outline: "2px solid mediumpurple",
            outlineOffset: "-2px",
            boxShadow: "none"
        },
        "a:focus:not(:focus-visible), button:focus:not(:focus-visible), [role=button]:focus:not(:focus-visible), input[type=radio]:focus:not(:focus-visible), input[type=checkbox]:focus:not(:focus-visible)": {
            outline: "solid 1px transparent",
        }
    }}/>

    {/* Styles for typography */}
    <GlobalStyles styles={{
        ".triton-typography-main": {
            color: props.triton.col("fg_default")
        },
        ".triton-typography-secondary": {
            color: props.triton.col("fg_muted")
        }
    }}/>
</>)
