import {Color} from "../base/logic/style/Color";
import {Theme} from "./theming/Theme";

export namespace TritonDefaults {

    export const theme: Theme = {
        id: "dark-theme",
        title: "Dark theme",
        isDarkTheme: true,
        linker: {
            // Background colors
            bg_main: "color_gb_main",
            bg_tray: "color_gb_tray",
            bg_menu: "color_gb_tray",
            bg_modal: "color_gb_modal",

            // Button colors
            button_primary: "color_primary",
            button_default: "gray_300",

            // Foreground (typography & icons) colors

            fg_display_primary: "fg_display_primary",
            fg_display_secondary: "fg_display_secondary",

            fg_default: "fg_default",
            fg_secondary: "fg_secondary",
            fg_muted: "fg_muted",

            color_primary: "color_primary",
            color_secondary: "color_secondary",

            layout_100: "gray_100",
            layout_200: "gray_200",
            layout_300: "gray_300",

            // Icon colors
            icon_default: "fg_default",

            input_background: "gray_250"
        },
        colors: {
            purple: Color.palettes.triton.purple.css(),

            color_gb_main: "#060607",
            // color_gb_main: "rgb(255,255,255)", // light mode
            // color_gb_main: "transparent",
            // color_gb_main: "#ffe082",

            // color_gb_tray: "#071A26", // *1
            color_gb_tray: "#0a0a0e", // *2 - but little darker
            // color_gb_tray: "#f0f2f5", // light mode

            // color_gb_tray: "#101016F6", // *2
            // color_gb_tray: "#F54F29",
            // color_gb_tray: "#9AEBA3",
            // color_gb_tray: "rgb(255,223,96)",
            // color_gb_tray: "transparent",
            // color_gb_tray: "rgba(0,0,0,0.9)",
            // color_gb_tray: "#ffecb3",

            color_gb_modal: "#161b22",

            // color_primary: "#FFD393",
            // color_primary: "#FFE8B5",
            // color_primary: "#DAFDBA",
            // color_primary: "#E3C0FF",
            // color_primary: "#f14234", // red
            // color_primary: "#1d4fde", // blue
            // color_primary: "#ffbd29", // orange
            // color_primary: "#0CF29E", // *1 - light green
            color_primary: "#ff7a00", // X orange

            color_secondary: "#ffbd29", // yellow


            color_default: "#1e202a",
            // color_default: "#ffdf60",

            fg_display_primary: "#fff8e1",
            fg_display_secondary: "#fff8e1",

            fg_default: "#fff8e1",
            // fg_default: "#181818", // light mode

            fg_muted: "#8b949e",
            fg_secondary: "#8b949e",
            // fg_default: "rgba(6,6,7,1)",
            // fg_muted: "rgba(49,51,56,1)"

            // TODO: Remove
            "gray-500": "#8b949e",

            gray_100: "#060607",
            gray_200: "#0a0a0e",
            gray_250: "#101016",
            gray_300: "#1e202a"
        },
        cssClasses: {
            layout_box: {
                mixins: ["box"]
            },
            glass_layout_box: {
                mixins: ["glass"]
            }
        },
        cssMixins: {

            /**
             *
             */
            box: function () {
                return {
                    backgroundColor: this.col("bg_tray"),
                    borderRadius: ".5rem",
                }
            },

            /**
             * Murky glass box effect
             */
            glass: {
                background: "linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))",
                backdropFilter: "blur(10px)",
                borderRadius: "20px",
                border: "1px solid rgba(255, 255, 255, 0.18)",
                boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.37)",
            }
        }
    }

    // noinspection SpellCheckingInspection
    export const glassyDarkTheme: Theme = {
        ...theme,
        id: "glassy-dark-theme",
        title: "C(G)lassy dark theme",
        cssClasses: {
            ...theme.cssClasses,
            layout_box: {
                mixins: ["box", "glass"]
            }
        }
    }

    export const blueFutureTheme: Theme = {
        ...theme,
        id: "blue-future-theme",
        title: "Blue sci-fi theme",
        colors: {
            ...theme.colors,
            // color_primary: "#80daff",
            color_primary: "#60ffc7",
            color_secondary: "#08d7de"
        }
    }

    export const greenFutureTheme: Theme = {
        ...theme,
        id: "green-future-theme",
        title: "Green sci-fi theme",
        colors: {
            ...theme.colors,
            color_primary: "#82da83",
            color_secondary: "#3cda41"
        }
    }

    export const purpleFutureTheme: Theme = {
        ...theme,
        id: "purple-future-theme",
        title: "Purple sci-fi theme",
        colors: {
            ...theme.colors,
            color_primary: "#c766fd",
            color_secondary: "#7314cc"
        }
    }

    export const crimsonFutureTheme: Theme = {
        ...theme,
        id: "crimson-theme",
        title: "Crimson sci-fi theme",
        colors: {
            ...theme.colors,
            color_primary: "#DC143C",
            color_secondary: "#08d7de",
            color_gb_main: "#DC143C"
        }
    }

    export const oceanTheme: Theme = {
        ...theme,
        id: "ocean-theme",
        title: "Ocean theme",
        colors: {
            ...theme.colors,
            color_primary: "#DAFDBA",
            color_secondary: "#9AEBA3",
            gray_100: "#45C4B0",
            gray_200: "#45C4B0",
            gray_250: "#45C4B0",
            gray_300: "#45C4B0",
            color_gb_tray: "#13678A",
            color_gb_main: "#012030",
        }
    }

    export const _1Theme: Theme = {
        ...theme,
        id: "_1-theme",
        title: "_1 theme",
        colors: {
            ...theme.colors,
            color_primary: "#C7FFED",
            color_secondary: "#D8FFDB",
            gray_100: "#008F8C",
            gray_200: "#008F8C",
            gray_250: "#008F8C",
            gray_300: "#008F8C",
            color_gb_tray: "#015958",
            color_gb_main: "#023535",
        },
    }

    export const forrestTheme: Theme = {
        ...theme,
        id: "forrest-theme",
        title: "Forrest theme",
        linker: {
            ...theme.linker,
            fg_secondary: "fg_muted"
        },
        colors: {
            ...theme.colors,
            color_primary: "#97ED8A",
            color_secondary: "#45BF55",
            gray_100: "#168039",
            gray_200: "#168039",
            gray_250: "#168039",
            gray_300: "#168039",
            color_gb_modal: "#044D29",
            color_gb_tray: "#044D29",
            color_gb_main: "#00261C",
            fg_muted: "#c7d7c6",
        },
    }

    export const fireTheme: Theme = {
        ...theme,
        id: "fire-theme",
        title: "Fire theme",
        linker: {
            ...theme.linker,
            bg_modal: "color_gb_main"
        },
        colors: {
            ...theme.colors,
            color_primary: "#FF8E00",
            color_secondary: "#FF7F00",
            gray_100: "#FF6702",
            gray_200: "#FF6702",
            gray_250: "#FF6702",
            gray_300: "#FF6702",
            color_gb_tray: "#FF551B",
            color_gb_main: "#E54818",
            fg_default: "#fff8e1",
            fg_muted: "#fff8e1",
        },
    }

    export const _4Theme: Theme = {
        ...theme,
        id: "_4-theme",
        title: "_4 theme",
        colors: {
            ...theme.colors,
            color_primary: "#5F49F2",
            color_secondary: "#5079F2",
            gray_100: "#F2B807",
            gray_200: "#F2B807",
            gray_250: "#F2B807",
            gray_300: "#F2B807",
            color_gb_tray: "#F28907",
            color_gb_main: "#F2220F",
        },
    }

    export const blackTheme: Theme = {
        ...theme,
        id: "black-theme",
        title: "Black theme",
        colors: {
            ...theme.colors,
            color_primary: "#2f2f2f",
            color_secondary: "#2f2f2f",
            gray_100: "#000000",
            gray_200: "#000000",
            gray_300: "#000000",
            color_gb_tray: "#000000",
            color_gb_main: "#000000",
        },
    }

    export const lightTheme: Theme = {
        ...theme,
        id: "light-theme",
        title: "Light theme",
        isDarkTheme: false,
        colors: {
            ...theme.colors,
            color_gb_main: "#ffffff",
            color_gb_tray: "#f5f6f8",
            color_primary: "#ff7a00",
            color_secondary: "#ffbd29",
            color_default: "#1e202a",
            fg_default: "#181818",
            fg_muted: "#444444",
            gray_250: "#e8ebee",
            gray_300: "#e8ebee",
            color_gb_modal: "#ffffff"
        }
    }

    export const yellowPastelTheme: Theme = {
        ...lightTheme,
        id: "yellow-pastel-theme",
        title: "Yellow pastel theme",
        colors: {
            ...theme.colors,
            color_primary: "#ffdf60",
            color_secondary: "#ffdf60",
            gray_100: "#fdd441",
            gray_200: "#fdd441",
            gray_250: "#fdd441",
            gray_300: "#fdd441",
            color_gb_tray: "#ffe580",
            color_gb_main: "#fff0b7",
            color_gb_modal: "#fff0b7",
            color_default: "#000000",
            fg_default: "#000000",
            fg_muted: "#000000",
            fg_secondary: "#000000",
        },
    }
}
