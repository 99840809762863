import React from "react";
import styled from "styled-components";
import MainAppLogo from "../../assets/logos/main_app_logo.png";

const StyledAppMainBranding = styled.div`
  
`;

export const AppMainBranding: React.FC = props => {
    return (
        <StyledAppMainBranding>
            {/*
            <img src={MainAppLogo} alt={"main app logo"} style={{
                userSelect: "none",
            }}/>
            */}
        </StyledAppMainBranding>
    );
}
