import {useContext} from "react";
import {StateDispatcher} from "../../../ship/test/core/StateDispatcher";
import {useAutoSettings} from "../../hooks/SettingsHook";
import {ArdaiAPIContext} from "../../webapi/WebAPI";
import {webDB} from "../../webapi/WebAPIDB";

export interface SerializedStateContext {
    loading: boolean,
    reset: () => void
}

export function useSerializedState<T = any>(key: string, def: T): [T, StateDispatcher<T>, SerializedStateContext] {
    const s = useAutoSettings(key, def);
    const loading = s === undefined;
    const api = useContext(ArdaiAPIContext);

    const setStateWrapper: StateDispatcher<T> = async newState => {
        let newStateValue: T;
        if (typeof newState === "function") {
            const newStateFunc = newState as (prevState: T) => T;
            newStateValue = newStateFunc(s ?? def);
        } else {
            newStateValue = newState as T;
        }
        try {
            // TODO: Prevent unnecessary db updates -> state === newState
            await api.settingsManager.updateSettingsObject<T>(key, () => newStateValue);
        } catch (e) {
            console.error("Error white updating serialized state", e);
        }
    }

    return [s ?? def, setStateWrapper, {
        loading: loading,
        reset: () => webDB.settings.delete(key)
    }];
}
