import React, {useState} from "react";
import styled from "styled-components";
import {ColorizeRounded, PaletteRounded} from "@mui/icons-material";
import {TabBar} from "../../../ardai/components/TabBar";
import {Color} from "../../../base/logic/style/Color";
import _ from "lodash";
import {ColorPickerPaletteView} from "./ColorPickerPaletteView";
import {ColorPickerManualView} from "./ColorPickerManualView";


const StyledControlledColorPicker = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  .color-input {
    flex-grow: 1;
    width: 100%;
    border: none;
    border-radius: 8px;
    background-color: #101016;
    height: 32px;
    outline: none;
    font-family: Consolas, "Courier New", monospace;
    color: white;
    cursor: pointer;
  }
  
  .rgb-shard {
    text-align: center;
  }
`;

export type ControlledColorPickerProps = {
    color: Color,
    onChange: (color: Color) => void
}

export type ControlledColorPickerState = {
    tab: string,
    hexFloatingValue: string | undefined,
    debouncedChangeHandler: (color: Color) => void
}

export const ControlledColorPicker: React.FC<ControlledColorPickerProps> = props => {

    const [state, setState] = useState<ControlledColorPickerState>({
        tab: "picker",
        hexFloatingValue: undefined,
        debouncedChangeHandler: _.debounce((color: Color) => {
            props.onChange(color);
        }, 100, { leading: true, trailing: true })
    });

    const update = (color: Color) => {
        // props.onChange(color);
        state.debouncedChangeHandler(color);
    }

    const updateChannel = (x: number, channel: "r" | "g" | "b") => {
        const color = props.color;
        color[channel] = x;
        props.onChange(color);
    };

    return (
        <CCPContext.Provider value={{
            color: props.color,
            updateChannel: (x, channel) => updateChannel(x, channel),
            updateColor: color => update(color)
        }} children={
            <StyledControlledColorPicker>
                <TabBar
                    activeBar={state.tab}
                    onTabChange={tab => setState(prevState => ({ ...prevState, tab: tab }))}
                    tabs={[
                        { id: "picker", title: "Picker", icon: <ColorizeRounded/> },
                        { id: "palette", title: "Palette", icon: <PaletteRounded/> }
                    ]}
                />
                { state.tab === "picker" && (<ColorPickerManualView/>) }
                { state.tab === "palette" && (<ColorPickerPaletteView/>) }
            </StyledControlledColorPicker>
        }/>
    );
}

export type ControlledColorPickerContext = {
    updateColor: (color: Color) => void,
    updateChannel: (x: number, channel: "r" | "g" | "b") => void,
    color: Color
}

export const CCPContext = React.createContext<ControlledColorPickerContext | undefined>(undefined);
