import React, {useContext} from "react";
import {AzypodIndicator} from "../../entities/azypod/AzypodIndicator";
import {KSU, SimulationContext} from "../../Simulation2Main";
import {castEntity, castEntityFull} from "../../SimStd";
import {AzypodState} from "../../entities/azypod/AzypodEntity";

export const AzypodWidget: React.FC = props => {
    const sim = useContext(SimulationContext);
    const azypod = castEntity<AzypodState>(sim.entity("port-azypod"));
    const engine = sim.entity("engine");

    const grossKSU = engine?.state[KSU] ?? 0;
    const actualDeg = azypod?.state?.actualDeg ?? 0;
    const requestedDeg = azypod?.state?.requestedDeg ?? 0;

    return (
        <div style={{
            height: 60,
            aspectRatio: "1 / 1"
        }} children={
            <AzypodIndicator
                actualAngle={actualDeg}
                requestedAngle={requestedDeg}
                shaftKSU={grossKSU}
            />
        }/>
    );
}
