import React, {FC, PropsWithChildren, useContext, useEffect, useRef} from "react";
import styled from "styled-components";
import {useStaticState} from "../../hooks/StaticStateHook";
import {ChallengeInfo, ChallengeTemplateRenderer} from "../challengeTemplateCreator/ChallengeTemplateCreator";
import {ArdaiAPIContext} from "../../webapi/WebAPI";
import _ from "lodash";
import {Workspace} from "../../components/Workspace";
import {TransitionGroup} from "react-transition-group";
import {Zoom} from "@mui/material";
import {SingleLineInput} from "../../../triton/components/forms/SingleLineInput";
import {Tag} from "../../components/Tag";
import {MenuGroup} from "../../components/MenuGroup";
import {ISAImage} from "../../components/ISAImage";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {ButtonGroup} from "../../components/ButtonGroup";
import {FormElement} from "../../../triton/components/forms/FormElement";
import {DefaultButton} from "../../components/DefaultButton";
import fileDownload from "js-file-download";
import {ButtonModalCompound} from "../../components/ButtonModalCompound";
import {Modal} from "../../../triton/components/dialogs/Modal";
import {Menu} from "../../components/Menu";
import {SingleImageSelectorView} from "../imageSelectionView/SingleImageSelectorView";

const StyledCopyStudyTemplateCreator = styled.div`
  .ui-main {
    box-sizing: border-box;
    overflow: hidden;
    display: grid;
    grid-template-columns: 300px auto 300px;
    gap: 8px;
  }
`;

/**
 * Master
 * - social
 * - copy image (maybe db path?)
 *
 * "Student":
 * - socials
 *
 * Settings:
 * - show socials
 * - show guidelines
 * - guideline resolution
 * - background color
 * - banner color
 * - challenge field background color
 *
 * @param props
 * @constructor
 */
export const CopyStudyTemplateCreator: FC = props => {
    const api = useContext(ArdaiAPIContext);

    const [copyStudyConfig, copyStudyConfigCtx] = useStaticState<CopyStudyTemplateConfig>({
        id: "CopyStudyTemplateCreator-CopyStudyTemplateConfig",
        initial: {
            showBanner: true,
            showGuidelines: true,
            backgroundColor: "#000000",
            bannerBackgroundColor: "#ff0000",
            challengeFieldBackgroundColor: "#6000ff",
            fontColor: "#ffffff",
            masterSocials: "",
            studentSocials: "",
            renderScale: 1
        }
    }).stateWithCtx;

    const [templateImage, takeScreenshot] = require("use-react-screenshot").useScreenshot({
        quality: 2
    });

    const templateRenderContainerRef = useRef(null);
    const debouncedRenderFuncRef = useRef(_.debounce(() => takeScreenshot(templateRenderContainerRef.current), .5e3));
    useEffect(() => {
        try {
            debouncedRenderFuncRef.current()
        } catch (e) {
            console.error(e);
        }
    }, [copyStudyConfig]);

    function convertBase64ToBlob(base64Image: string) {
        // Split into two parts
        const parts = base64Image.split(';base64,');
        // Hold the content type
        const imageType = parts[0].split(':')[1];
        // Decode Base64 string
        const decodedData = window.atob(parts[1]);
        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);
        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }
        // Return BLOB image after conversion
        return new Blob([uInt8Array], { type: imageType });
    }

    return (
        <StyledCopyStudyTemplateCreator>
            <div className={"ui-main"}>
                <Workspace config={{ mode: "desktop", name: "config" }} style={{
                    display: "flex",
                    gap: 8,
                    flexDirection: "column",
                    overflowY: "scroll"
                }}>
                    <MenuGroup title={"Credits"} indentation={8} showIndentationLine>
                        <SingleLineInput
                            placeholder={"Masters socials"}
                            value={copyStudyConfig.masterSocials}
                            onChange={(_, s) => copyStudyConfigCtx.update({
                                masterSocials: s
                            })}
                        />
                        <Tag tag={"Unknown master"} highlightOnHover onClick={() => {
                            copyStudyConfigCtx.update({
                                masterSocials: ""
                            });
                        }}/>

                        <SingleLineInput
                            placeholder={"Student socials"}
                            value={copyStudyConfig.studentSocials}
                            onChange={(_, s) => copyStudyConfigCtx.update({
                                studentSocials: s
                            })}
                        />
                        <Tag tag={"Unknown student"} highlightOnHover onClick={() => {
                            copyStudyConfigCtx.update({
                                studentSocials: ""
                            });
                        }}/>
                    </MenuGroup>

                    <MenuGroup title={"Masters image"} indentation={8} showIndentationLine>
                        <SingleLineInput
                            placeholder={"Link to master image"}
                            value={copyStudyConfig.masterImageLink}
                            onChange={(_, s) => copyStudyConfigCtx.update({
                                masterImageLink: s
                            })}
                        />

                        <SingleLineInput
                            placeholder={"Masters image source (UUID)"}
                            value={copyStudyConfig.masterImageSrc}
                            onChange={(_, s) => copyStudyConfigCtx.update({
                                masterImageSrc: s
                            })}
                        />

                        <div style={{
                            display: "grid",
                            gap: 4,
                            gridTemplateColumns: "repeat(2, 1fr)",
                            alignItems: "center",
                        }}>
                            <Tag tag={"Use current image"} highlightOnHover onClick={() => {
                                copyStudyConfigCtx.update({
                                    masterImageSrc: api.state.selectedImageId
                                });
                            }}/>

                            <ButtonModalCompound
                                button={<Tag tag={"Select image"} highlightOnHover/>}
                                modalContent={ctx => (
                                    <Modal title={"Select image"} open={true} onClose={() => ctx.close()}>
                                        <SingleImageSelectorView onSelect={i => {
                                            copyStudyConfigCtx.update({
                                                masterImageSrc: i.id
                                            });
                                            ctx.close();
                                        }}/>
                                    </Modal>
                                )}
                            />
                        </div>


                    </MenuGroup>

                    <MenuGroup title={"Visuals"} indentation={8} showIndentationLine>
                        <FormElement title={"Template background"}>
                            <ButtonGroup orientation={"horizontal"}>
                                <input type={"color"} value={copyStudyConfig.backgroundColor} style={{ width: "100%" }} onChange={e => {
                                    copyStudyConfigCtx.update({
                                        backgroundColor: e.currentTarget.value
                                    })
                                }}/>
                                <Tag tag={"Black"} onClick={() => {
                                    copyStudyConfigCtx.update({
                                        bannerBackgroundColor: "#000000"
                                    })
                                }}/>
                                <Tag tag={"Reset"} onClick={() => {
                                    copyStudyConfigCtx.update({
                                        backgroundColor: "#FFFFFF"
                                    })
                                }}/>
                            </ButtonGroup>
                        </FormElement>

                        <FormElement title={"Info banner background"}>
                            <ButtonGroup orientation={"horizontal"}>
                                <input type={"color"} value={copyStudyConfig.bannerBackgroundColor} style={{ width: "100%" }} onChange={e => {
                                    copyStudyConfigCtx.update({
                                        bannerBackgroundColor: e.currentTarget.value
                                    })
                                }}/>
                                <Tag tag={"White"} onClick={() => {
                                    copyStudyConfigCtx.update({
                                        bannerBackgroundColor: "#ffffff"
                                    })
                                }}/>
                                <Tag tag={"Reset"} onClick={() => {
                                    copyStudyConfigCtx.update({
                                        bannerBackgroundColor: "#000000"
                                    })
                                }}/>
                            </ButtonGroup>
                        </FormElement>

                        <FormElement title={"Challenge field background"}>
                            <ButtonGroup orientation={"horizontal"}>
                                <input type={"color"} value={copyStudyConfig.challengeFieldBackgroundColor} style={{ width: "100%" }} onChange={e => {
                                    copyStudyConfigCtx.update({
                                        challengeFieldBackgroundColor: e.currentTarget.value
                                    })
                                }}/>
                                <Tag tag={"Reset"} onClick={() => {
                                    copyStudyConfigCtx.update({
                                        challengeFieldBackgroundColor: "#D9D9D9"
                                    })
                                }}/>
                            </ButtonGroup>
                        </FormElement>

                        <FormElement title={"Font color"}>
                            <ButtonGroup orientation={"horizontal"}>
                                <input type={"color"} value={copyStudyConfig.fontColor} style={{ width: "100%" }} onChange={e => {
                                    copyStudyConfigCtx.update({
                                        fontColor: e.currentTarget.value
                                    })
                                }}/>
                                <Tag tag={"Reset"} onClick={() => {
                                    copyStudyConfigCtx.update({
                                        fontColor: "#ffffff"
                                    })
                                }}/>
                            </ButtonGroup>
                        </FormElement>
                    </MenuGroup>
                </Workspace>

                {/* Output */}
                <Workspace config={{ mode: "desktop", name: "output" }}>
                    <div style={{
                        width: "100%",
                        height: "100%",
                        overflow: "scroll"
                    }}>
                        <TransitionGroup>
                            { templateImage && (
                                <Zoom>
                                    {(() => {
                                        const imgSrcFile = new File([convertBase64ToBlob(templateImage)], "template.png");
                                        return (
                                            <img
                                                width={"100%"}
                                                src={URL.createObjectURL(imgSrcFile)}
                                                alt={"Rendered template image"}
                                            />
                                        );
                                    })()}
                                </Zoom>
                            ) }
                        </TransitionGroup>
                    </div>
                </Workspace>

                <div style={{
                    display: "grid",
                    gap: 8,
                    gridTemplateRows: "min-content auto"
                }}>
                    {/* Actions */}
                    <Workspace config={{ mode: "desktop", name: "actions" }} style={{
                        display: "flex",
                        gap: 8,
                        flexDirection: "column",
                        height: "min-content"
                    }}>
                        <DefaultButton children={"Render template"} fullwidth onClick={() => {
                            takeScreenshot(templateRenderContainerRef.current);
                        }}/>

                        <DefaultButton
                            children={"Save template to current project"}
                            fullwidth
                            deactivated={templateImage === undefined || api.state.selectedProject === undefined}
                            onClick={async () => {
                                if (templateImage === undefined) return;
                                const project = await api.getCurrentProject();
                                if (project === undefined) return;

                                await api.appendFilesToCurrentProject([new File(
                                    [convertBase64ToBlob(templateImage)],
                                    "template.png"
                                )]);
                            }}
                        />

                        <DefaultButton children={"Download template"} fullwidth deactivated={templateImage === undefined} onClick={async () => {
                            if (templateImage === undefined) return;
                            const info = copyStudyConfig;
                            fileDownload(convertBase64ToBlob(templateImage), `copy-study-from-${
                                info.masterSocials.trim().length === 0 ? "unknown" : info.masterSocials
                            }.png`);
                        }}/>

                        <DefaultButton children={"Export template config"} fullwidth onClick={async () => {
                            const info = copyStudyConfig;
                            fileDownload(JSON.stringify(info, null, 2), `copy-study-from-${
                                info.masterSocials.trim().length === 0 ? "unknown" : info.masterSocials
                            }.json`);
                        }}/>

                        <ButtonModalCompound
                            button={
                                <DefaultButton children={"Import template config (modal)"} fullwidth/>
                            }
                            modalContent={ctx => (
                                <Modal open={true} onClose={ctx.close}>
                                    <input type={"file"} accept={"application/json"} onChange={async (e) => {
                                        const fileBody = await e.currentTarget.files?.item(0)?.text();
                                        if (fileBody === undefined) return;

                                        const inflatedChallengeInfo = JSON.parse(fileBody);
                                        copyStudyConfigCtx.update(inflatedChallengeInfo);
                                        ctx.close()
                                    }}/>
                                </Modal>
                            )}
                        />

                        <Menu opener={
                            <DefaultButton children={"Import template config (menu)"} fullwidth/>
                        }>
                            <input type={"file"} accept={"application/json"} onChange={async (e) => {
                                const fileBody = await e.currentTarget.files?.item(0)?.text();
                                if (fileBody === undefined) return;

                                const inflatedChallengeInfo = JSON.parse(fileBody);
                                copyStudyConfigCtx.update(inflatedChallengeInfo);
                            }}/>
                        </Menu>
                    </Workspace>

                    <Workspace config={{ mode: "desktop", name: "render-config" }}>
                        <MenuGroup title={"Render settings"}>
                            <FormElement title={"Render scale"}>
                                <SingleLineInput
                                    baseProps={{
                                        type: "number",
                                        min: 1,
                                        max: 8
                                    }}
                                    value={copyStudyConfig.renderScale}
                                    onChange={(e, s) => copyStudyConfigCtx.update({
                                        renderScale: _.clamp(Number(s), 1, 8)
                                    })}
                                />
                                <div style={{
                                    display: "grid",
                                    gap: 8,
                                    gridTemplateColumns: "repeat(auto-fit, minmax(1px, 1fr))"
                                }}>
                                    { [1, 1.5, 2, 4, 8].map(padding => (
                                        <Tag tag={`${padding}x`} active={padding === copyStudyConfig.renderScale} highlightOnHover onClick={() => {
                                            copyStudyConfigCtx.update({
                                                renderScale: padding
                                            });
                                        }}/>
                                    )) }
                                </div>
                            </FormElement>
                        </MenuGroup>
                    </Workspace>
                </div>
            </div>

            {/* Internal rendering component */}
            <div style={{ opacity: 0, position: "absolute", zIndex: -1 }}>
                <div ref={templateRenderContainerRef}
                    style={{ transform: `scale(${
                        copyStudyConfig.renderScale ? Math.min(copyStudyConfig.renderScale, 8) : 1
                    })`
                }}>
                    <CopyStudyTemplateRenderer config={copyStudyConfig}/>
                </div>
            </div>
        </StyledCopyStudyTemplateCreator>
    );
}

export type CopyStudyTemplateConfig = {
    showBanner: boolean,
    showGuidelines: boolean,
    backgroundColor: string,
    bannerBackgroundColor: string,
    challengeFieldBackgroundColor: string,
    fontColor: string,
    masterImageSrc?: string,
    masterImageLink?: string,
    masterSocials: string,
    studentSocials: string,

    renderScale: number
}

const StyledCopyStudyTemplate = styled.div<{
    c: CopyStudyTemplateConfig
}>`
  width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.c.backgroundColor ?? "white"};
  
  * {
    color: ${p => p.c.fontColor ?? "black"} !important;
    font-family: "JetBrains Mono", monospace !important;
  }

  .study {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem;
  }

  .study-info {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    background-color: ${p => p.c.bannerBackgroundColor ?? "black"};
    
    .student-info-container {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }
`;

const CopyStudyTemplateRenderer: FC<{
    config: CopyStudyTemplateConfig
}> = props => {
    const c = props.config;
    const date = new Date();
    const timestamp = date.toLocaleString('en-us',{ month: 'short', year: 'numeric'})

    return (
        <StyledCopyStudyTemplate c={c}>
            <div className={"study"}>
                <CopyStudyTemplateImageField>
                    { c.masterImageSrc && (
                        <ISAImage
                            imageID={c.masterImageSrc!}
                            isaTable={"images"}
                            imageRenderer={i => (
                                <img src={URL.createObjectURL(i.data)} style={{
                                    width: "100%",
                                    height: "100%",
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    objectFit: "cover"
                                }} alt={"master image"}/>
                            )}
                        />
                    ) }
                </CopyStudyTemplateImageField>
                <CopyStudyTemplateImageField backgroundColor={c.challengeFieldBackgroundColor}>

                </CopyStudyTemplateImageField>
            </div>
            <div className={"study-info"}>
                <div className={"master-info-container"}>
                    { c.masterSocials.trim().length > 0 && (
                        <DescriptiveTypography text={c.masterSocials}/>
                    ) || (
                        <DescriptiveTypography text={"unknown master"} style={{
                            fontStyle: "italic"
                        }}/>
                    ) }
                    { c.masterImageLink && (
                        <DescriptiveTypography text={c.masterImageLink} style={{
                            fontStyle: "italic"
                        }}/>
                    ) }

                </div>

                <div className={"student-info-container"}>
                    { c.studentSocials.trim().length > 0 && (
                        <DescriptiveTypography text={c.studentSocials}/>
                    ) || (
                        <DescriptiveTypography text={"unknown student"} style={{
                            fontStyle: "italic"
                        }}/>
                    ) }
                    <DescriptiveTypography text={`${timestamp}`}/>
                </div>
            </div>
        </StyledCopyStudyTemplate>
    );
}

const CopyStudyTemplateImageField: FC<PropsWithChildren<{
    backgroundColor?: string
}>> = props => {
    return (
        <div style={{
            width: "100%",
            minWidth: "100%",
            height: "100%",
            overflow: "hidden",
            backgroundColor: props.backgroundColor,
        }}>{ props.children }</div>
    );
}
