import {NodeSetupInfo} from "../NodeSetupInfo";
import {Node} from "../../backend/Node";
import {v4} from "uuid";
import {Tag} from "../../../ardai/components/Tag";
import {EditRounded, MoreHorizRounded} from "@mui/icons-material";
import {triton} from "../../../triton/Triton";
import {FormDivider} from "../../../triton/components/forms/FormDivider";
import {Menu} from "../../../ardai/components/Menu";

export const NodeEnumSelector: NodeSetupInfo = {
    label: "NodeEnumSelector",
    classname: "input.enum-input-debug",
    parameterConfig: [],
    factory: parameters => {

        function appendToExpr(node: Node, appendix: string) {
            node.state.update(prevState => ({
                nDeltaExpr: (prevState.nDeltaExpr ?? "") + appendix
            }));
        }

        function removeFromExpr(node: Node, deleteCount: number = 1) {
            node.state.update(prevState => ({
                nDeltaExpr: (prevState.nDeltaExpr as string ?? "").slice(0, -deleteCount)
            }));
        }

        function clearExpr(node: Node) {
            node.state.update({
                nDeltaExpr: undefined
            });
        }

        return new Node<{
            mode: "view" | "edit",
            pointer: number,
            editPointer: number,
            enum: Array<{
                key: number,
                label: string,
                value: string
            }>
        }>({
            id: v4(),
            classname: "input.enum-input-debug",
            label: "enum *",
            defOutPins: ["e"],
            state: {
                mode: "view",
                pointer: 0,
                editPointer: 0,
                enum: [
                    { key: 0, label: "a", value: "a" },
                    { key: 1, label: "b", value: "b" },
                    { key: 2, label: "c", value: "c" }
                ]
            },
            init() {

            },
            customRenderer: node => (
                <div style={{
                    display: "flex",
                    gap: 4,
                    flexDirection: "column",
                    padding: 0,
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                    paddingTop: 8,
                    width: 135
                }}>
                    <div style={{
                        width: "100%",
                        display: "grid",
                        gap: 4,
                        gridTemplateColumns: "1fr 3fr",
                        alignItems: "center",
                    }}>
                        <Tag tag={<EditRounded sx={{ fontSize: 14 }}/>} hideBackgroundOnDefault onClick={() => {
                            node.state.update(prevState => ({
                                mode: prevState.mode === "view" ? "edit" : "view"
                            }))
                        }}/>
                    </div>

                    <div style={{
                        width: "100%",
                        display: "grid",
                        paddingBottom: node.state.state.mode === "edit" ? 0 : 8
                    }}>
                        { node.state.state.enum.map((entry, idx) => {
                            const isSelected = node.state.state.pointer === entry.key;
                            return (
                                <Tag
                                    tag={entry.label}
                                    active={isSelected}
                                    hideBackgroundOnDefault={!isSelected}
                                    onClick={() => {
                                        node.state.update({
                                            pointer: entry.key
                                        });
                                        node.pins.out("e").write(entry.value);
                                    }}
                                />
                            );
                        }) }
                    </div>

                    { node.state.state.mode === "edit" && (
                        <>
                            <div style={{ width: "100%" }}>
                                <FormDivider/>
                            </div>

                            {/*
                                <DescriptiveTypography text={`${node.state.state.nDeltaExpr ?? "0"}`} style={{
                                    textAlign: "end",
                                    width: "100%"
                                }}/>
                            */}

                            <input
                                placeholder={"label"}
                                style={{
                                    width: "100%",
                                    fontSize: 12,
                                    color: triton().col("fg_muted"),
                                    border: "none",
                                    backgroundColor: "transparent",
                                    outline: "none"
                                }}
                            />

                            <input
                                placeholder={"value"}
                                style={{
                                    width: "100%",
                                    fontSize: 12,
                                    color: triton().col("fg_muted"),
                                    border: "none",
                                    backgroundColor: "transparent",
                                    outline: "none"
                                }}
                            />

                            <div style={{
                                display: "grid",
                                gap: 4,
                                gridTemplateColumns: "repeat(3, 1fr)",
                                paddingBottom: 8,
                                width: "100%"
                            }}>
                                <Tag tag={"del"}/>
                                <Tag tag={"add"}/>
                                <Menu opener={
                                    <Tag tag={
                                        <MoreHorizRounded sx={{ fontSize: 14 }}/>
                                    }/>
                                }></Menu>
                            </div>
                        </>
                    ) }
                </div>
            )
        });
    }
}
