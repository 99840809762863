import React, {useState} from "react";
import {Workspace} from "../../ardai/components/Workspace";
import {MixinCard} from "./MixinCard";
import {DefaultButton} from "../../ardai/components/DefaultButton";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {Menu} from "../../ardai/components/Menu";
import {ButtonModalCompound} from "../../ardai/components/ButtonModalCompound";
import {MixinCreationDialog} from "./MixinCreationDialog";
import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../../ardai/webapi/WebAPIDB";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {MenuButton} from "../../ardai/components/MenuButton";
import {DeleteRounded, MenuRounded, UploadRounded} from "@mui/icons-material";
import {StyledModal} from "../../ardai/components/StyledModal";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {ButtonBase, ButtonVariant} from "../../triton/components/buttons/ButtonBase";
import {MixinDisplay} from "./MixinDisplay";
import {IconButton} from "../../ardai/components/IconButton";

export type MixinTabState = {
    selectedMixinID?: string,
    leftSidebarVisible: boolean
}

export const MixinTab: React.FC = props => {
    const [state, setState] = useState<MixinTabState>({
        leftSidebarVisible: true
    });

    const mixins = useLiveQuery(() => {
        return webDB.mixins.toArray();
    });

    return (
        <div style={{
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            overflow: "hidden",
            display: "grid",
            gridTemplateColumns: "min-content auto"
        }}>
            { state.leftSidebarVisible && (
                <div style={{
                    marginRight: "8px",
                    width: "100%",
                    height :"100%",
                    display: "grid",
                    gap: "8px",
                    gridTemplateRows: "min-content auto",
                    overflow: "hidden",
                    maxHeight: "100%",
                }}>
                    <Workspace config={{
                        mode: "desktop",
                        name: "mixin-selector-toolbar",
                    }} children={
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            gap: "8px"
                        }}>
                        <span style={{ width: "100%" }} children={
                            <ButtonModalCompound
                                button={
                                    <DefaultButton size={"small"} fullwidth variant={"primary"} children={
                                        <MainTypography text={"Create mixin"}/>
                                    }/>
                                }
                                modalContent={ctx => (
                                    <MixinCreationDialog onClose={() => ctx.close()}/>
                                )}
                            />
                        }/>

                            <Menu>
                                <MenuButton text={"Import mixins"} icon={<UploadRounded/>}/>
                                <ButtonModalCompound
                                    button={
                                        <MenuButton text={"Delete all"} icon={<DeleteRounded/>} disabled={mixins === undefined || mixins?.length === 0}/>
                                    }
                                    modalContent={ctx => (
                                        <StyledModal showHeader={false} onClose={() => ctx.close()} title={"Delete all mixins?"} footer={
                                            <div style={{
                                                gridTemplateColumns: "repeat(2, 1fr)",
                                                display: "grid",
                                                width: "100%",
                                                gap: "8px"
                                            }}>
                                                <ButtonBase variant={ButtonVariant.DANGER} text={"Yes, delete all"} baseProps={{
                                                    onClick: () => {
                                                        webDB.mixins.clear();
                                                        ctx.close();
                                                    }
                                                }}/>
                                                <ButtonBase text={"No, cancel"} baseProps={{
                                                    onClick: () => ctx.close()
                                                }}/>
                                            </div>
                                        }>
                                            <MainTypography text={"Do you really want to clear all mixins?"}/>
                                            <DescriptiveTypography text={<><strong children={"Important: "}/>This action cannot be undone.</>}/>
                                        </StyledModal>
                                    )}
                                />
                            </Menu>
                        </div>
                    }/>
                    <Workspace config={{
                        mode: "desktop",
                        name: "mixin-selector",
                        resizable: true
                    }} style={{
                        overflow: "scroll"
                    }} children={
                        <TransitionGroup children={
                            mixins?.map(md => (
                                <Collapse key={md.id} children={
                                    <span style={{
                                        display: "block",
                                        width: "100%",
                                        overflow: "scroll",
                                        paddingBottom: "8px"
                                    }} children={
                                        <MixinCard for={md} onSelect={() => {
                                            setState(prevState => ({ ...prevState, selectedMixinID: md.id }))
                                        }}/>
                                    }/>
                                }/>
                            ))
                        }/>
                    }/>
                </div>
            ) || <span/> }

            <div style={{
                width: "100%",
                height :"100%",
                display: "grid",
                gap: "8px",
                gridTemplateRows: "min-content auto",
                overflow: "hidden",
                maxHeight: "100%",
            }}>
                <Workspace config={{
                    mode: "desktop",
                    name: "mixin-toolbar"
                }}>
                    <IconButton
                        size={"small"}
                        children={<MenuRounded/>}
                        tooltip={"Toggle sidebar"}
                        onClick={() => setState(prevState => ({ ...prevState, leftSidebarVisible: !prevState.leftSidebarVisible }))}
                    />
                </Workspace>

                <Workspace config={{
                    mode: "desktop",
                    name: "mixin-view"
                }} children={
                    state.selectedMixinID === undefined ? (
                        <span style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }} children={
                            <DescriptiveTypography text={"Select a mixin"}/>
                        }/>
                    ) : (
                        <MixinDisplay mixinID={state.selectedMixinID} onClose={() => {
                            setState(prevState => ({ ...prevState, selectedMixinID: undefined }));
                        }} onDeleteRequest={() => {
                            const mixinToDelete = state.selectedMixinID;
                            if (mixinToDelete === undefined) return;
                            setState(prevState => ({ ...prevState, selectedMixinID: undefined }));
                            webDB.mixins.delete(mixinToDelete);
                        }}/>
                    )
                }/>
            </div>
        </div>
    );
}
