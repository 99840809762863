import React, {useContext} from "react";
import {Spice} from "./Spice";
import {TopicSelectorStateContext} from "./TopicSelectorStateContext";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import styled from "styled-components";
import {IconButton} from "../../components/IconButton";
import {DeleteRounded, VisibilityOffRounded, VisibilityRounded} from "@mui/icons-material";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {Tag} from "../../components/Tag";

const StyledSpicePreviewCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #1e202a;
  padding: 8px;
  border-radius: 8px;
  
  &[data-disabled=true] {
    opacity: .5;
  }
  
  .spice-preview-title {    
    &:hover {
      filter: brightness(1.2);
      text-decoration-line: underline;
    }
  }
  
  &:hover .spice-preview-appendix {
    opacity: 1;
  }
  
  .spice-preview-appendix {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    transition: opacity 50ms;
    opacity: 0;
  }
`;

export type SpicePreviewCardProps = {
    spice: Spice
}

export const SpicePreviewCard: React.FC<SpicePreviewCardProps> = props => {
    const [state, setState] = useContext(TopicSelectorStateContext);
    const spice = props.spice;
    const toggle = (key: "excluded" | "mandatory") => setState(prevState => ({
        ...prevState,
        spices: prevState.spices.map(s => {
            if (s !== spice) return s;
            s[key] = !spice[key];
            return s;
        })
    }));

    let footerText = "";
    footerText += spice.values.slice(-3).join(", ");
    const overflow = spice.values.length - 3;
    if (overflow > 0) footerText += ` +${overflow}`;

    let tags: Array<string> = [];
    if (spice.mandatory) tags.push("mandatory");
    if (spice.excluded) tags.push("excluded");

    return (
        <StyledSpicePreviewCard data-disabled={spice.excluded} onClick={e => {

            if (e.altKey || e.ctrlKey) {
                e.preventDefault();
                e.stopPropagation();
            }
            if (e.ctrlKey) {
                toggle("mandatory");
            }
        }}>
            <div>
                <DescriptiveTypography noSelect text={spice.title} style={{ cursor: "pointer" }} baseProps={{
                    className: "spice-preview-title",
                    // onClick: () => setState(prevState => ({
                    //     ...prevState,
                    //     spices: prevState.spices.filter(s => s.id !== spice.id)
                    // }))
                }}/>

                { footerText !== spice.title && (
                    <DescriptiveTypography
                        noSelect
                        style={{ fontStyle: "italic" }}
                        text={footerText}
                    />
                ) }

                <TransitionGroup children={
                    tags.length > 0 && (
                        <Collapse children={
                            <TransitionGroup style={{ display: "flex", gap: 4, alignItems: "center" }} children={
                                tags.map(t => (
                                    <Collapse key={t} orientation={"horizontal"} children={
                                        <Tag tag={t}/>
                                    }/>
                                ))
                            }/>
                        }/>
                    )
                }/>
            </div>

            <div className={"spice-preview-appendix"}>
                { spice.excluded ? (
                    <IconButton size={"small"} children={<VisibilityOffRounded/>} tooltip={"Include"} onClick={() => toggle("excluded")}/>
                ) : (
                    <IconButton size={"small"} children={<VisibilityRounded/>} tooltip={"Exclude"} onClick={() => toggle("excluded")}/>
                ) }
                <IconButton size={"small"} children={<DeleteRounded/>} onClick={() => {
                    setState(prevState => ({
                        ...prevState,
                        spices: prevState.spices.filter(s => s.id !== spice.id)
                    }));
                }}/>
            </div>
        </StyledSpicePreviewCard>
    );
}
