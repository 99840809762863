import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const SineWaveGen: NodeSetupInfo = {
    label: "",
    classname: "arithmetic.sin-wave-gen",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "arithmetic.sin-wave-gen",
        label: "wave",
        defInPins: ["t"],
        defOutPins: ["mag"],
        init: function () {
            this.pins.in("t").attach({
                read(x: any) {
                    const offsetX = 0;
                    const offsetY = 0;
                    const fx = Math.sin(x + offsetX) + offsetY;
                    this.node.pins.out("mag").write(fx);
                }
            })
        }
    })
}
