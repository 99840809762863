import React, {FC, useContext} from "react";
import {Workspace} from "../../ardai/components/Workspace";
import {SDLivePreview} from "./SDLivePreview";
import {SingleOutputImagePreview} from "./SingleOutputImagePreview";
import {ISADBImageGrid} from "../../ardai/components/ISADBImageGrid";
import {ImageRounded} from "@mui/icons-material";
import {OutputConfigurationDisplay} from "./output/OutputConfigurationDisplay";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {ControlledImagePreview} from "../../ardai/components/ControllerImagePreview";
import {StableDiffusionAttachmentRetriever} from "../../ardai/webapi/pngProperties/StableDiffusionAttachmentRetriever";
import {SDInterfaceAPIContext} from "./SDInterfaceAPI";
import {SDInterfaceStateContext} from "./SDInterfaceMain";
import {useStaticState} from "../../ardai/hooks/StaticStateHook";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {CollapseIconButton} from "../../ardai/components/CollapseIconButton";

export type SDInterfaceSidebarState = {
    expandedHistorySection: boolean
}

export const SDInterfaceSidebar: FC = props => {
    const api = useContext(SDInterfaceAPIContext);
    const mainState = useContext(SDInterfaceStateContext);

    const [state, ctx] = useStaticState<SDInterfaceSidebarState>({
        id: "SDInterfaceSidebar",
        initial: {
            expandedHistorySection: true
        }
    }).stateWithCtx;

    return (
        <div style={{
            paddingLeft: 8,
            display: "grid",
            gridTemplateRows: "auto min-content",
            gap: 8,
            height: "100%",
            overflow: "hidden",
            width: "100%"
        }}>
            <div style={{
                // height: "100%",
                flexShrink: 2,
                flexGrow: 1,
                overflow: "hidden"
            }} children={
                <Workspace
                    config={{ name: "sd-result-view", mode: "desktop" }}
                    style={{
                        overflow: "scroll",
                        height: "100%"
                    }}
                    children={(() => {
                        // Preview image present
                        if (mainState.phase === "generating") return (
                            <SDLivePreview/>
                        );

                        // Not rendering && single result preview available
                        else if (mainState.phase === "default" && mainState.currentGeneratedBatchIds !== undefined && mainState.currentGeneratedBatchIds.length === 1) return (
                            <SingleOutputImagePreview/>
                        );

                        // Not rendering && multiple result previews available
                        else if (mainState.phase === "default" && mainState.currentGeneratedBatchIds !== undefined && mainState.currentGeneratedBatchIds.length > 1) return (
                            <ISADBImageGrid
                                isaTable={"sdInterfaceResults"}
                                imageIDs={mainState.currentGeneratedBatchIds}
                            />
                        );

                        // Not rendering && No image available
                        else if ((mainState.currentGeneratedBatchIds === undefined || mainState.currentGeneratedBatchIds.length === 0) && !mainState.previewImage) return (
                            <div style={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }} children={
                                <span style={{
                                    height: "auto",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"}
                                } children={
                                    <ImageRounded/>
                                }/>
                            }/>
                        );
                    })()}
                />
            }/>

            <div style={{
                flexShrink: 1,
                flexGrow: 2,
                display: "flex",
                flexDirection: "column",
                gap: 8
            }}>
                <Workspace
                    config={{ name: "out-config-view", mode: "desktop" }}
                    children={
                        <OutputConfigurationDisplay/>
                    }
                />

                <Workspace
                    config={{ name: "out-history-view", mode: "desktop" }}
                    children={
                        <div style={{
                            height: "100%",
                            display: "grid",
                            gridTemplateRows: "min-content auto",
                            overflow: "scroll",
                            gap: 4
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                                <MainTypography text={"History"} noSelect/>

                                <CollapseIconButton open={state.expandedHistorySection} onToggle={open => {
                                    ctx.update({
                                        expandedHistorySection: open
                                    });
                                }}/>
                            </div>
                            <TransitionGroup>
                                { state.expandedHistorySection && (
                                    <Collapse>
                                        <ISADBImageGrid isaTable={"sdInterfaceResults"} preferPreviewImage imageRenderer={data => (
                                            <ControlledImagePreview
                                                opened={false}
                                                selected={false}
                                                // onRequestDelete={() => webDB.sdInterfaceResults.delete(data.id)}
                                                onRequestDelete={() => {}}
                                                key={data.id}
                                                for={data}
                                                onClick={() => {
                                                    if (!window.confirm("load selected prompt? (Overwrites current prompt)")) return;
                                                    new StableDiffusionAttachmentRetriever(data.data).retrieveMetaData().then(attachment => {
                                                        api.updateRequestData({
                                                            prompt: attachment.prompt,
                                                            negativePrompt: attachment.negativePrompt,
                                                        });
                                                    });
                                                }}
                                            />
                                        )}/>
                                    </Collapse>
                                ) }
                            </TransitionGroup>
                        </div>
                    }
                />
            </div>
        </div>
    );
}
