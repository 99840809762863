import React from "react";
import styled from "styled-components";

const StyledLink = styled.a`
  text-decoration: none !important;
  cursor: pointer;
  font-weight: 300;
  font-size: .75rem !important;
  color: rgb(88, 166, 255);
  text-decoration-color: rgb(88, 166, 255);
  background-color: transparent;
  height: 18px;
  white-space: normal;
  margin-right: 1rem;
  word-wrap: break-word;
  user-select: text;
`;

export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {}

export const Link: React.FC<LinkProps> = props => {
    return (
        <StyledLink {...props}/>
    );
}
