import {PinBank} from "../backend/PinBank";
import {Alignment} from "./Alignment";
import {FC} from "react";
import {useNodeCanvasBackend} from "./NodeCanvasBackend";
import {PinBankDisplay} from "./PinBankDisplay";
import {PinBankRendererFunc} from "./PinBankRendererFunc";

export type PinBankRendererProps = {
    bank: PinBank,
    align?: Alignment
}

export const PinBankRenderer: FC<PinBankRendererProps> = props => {
    const backend = useNodeCanvasBackend();
    const env = backend.environment;
    const bank = props.bank;

    const visCfg = bank.config.visualConfig;
    const isUsingCustomPinBankRenderer = visCfg !== undefined && visCfg.binBankRendererResourceName !== undefined;

    const renderDefaultPinBank = () => (
        <PinBankDisplay {...props}/>
    );

    if (isUsingCustomPinBankRenderer) {
        const renderer = env.resources
            .getContainer<PinBankRendererFunc, void>("pin-bank-renderers")
            .getResourceWithoutParams(visCfg!.binBankRendererResourceName!);

        try {
            return (
                <>{
                    renderer(bank, {
                        align: bank.isInGroup("output") ? Alignment.RIGHT : Alignment.LEFT
                    })
                }</>
            );
        } catch (e) {
            console.error(e);
            return renderDefaultPinBank();
        }

    }

    return renderDefaultPinBank();
}
