import React from "react";
import {RoundedButton} from "./RoundedButton";
import styled from "styled-components";
import {OpenInNewRounded} from "@mui/icons-material";

const StyledLinkLikeButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: rgba(0, 0, 0, .6);
  
  svg {
    width: 18px;
    color: rgba(0, 0, 0, .6) !important;
  }
`;

export type LinkLikeButtonProps = {
    text: React.ReactNode,
    onClick: () => void
}

export const LinkLikeButton: React.FC<LinkLikeButtonProps> = props => {
    return (
        <RoundedButton onClick={() => props.onClick()}>
            <StyledLinkLikeButton>
                {props.text}
                <OpenInNewRounded/>
            </StyledLinkLikeButton>
        </RoundedButton>
    );
}
