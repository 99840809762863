import {APIShard} from "./APIShard";

export class LayoutManager extends APIShard {

    public openProgramInTray = (tray: string, program: string, options: Partial<{
        single: boolean
    }> = {}) => {
        if (options.single ?? true) {
            const traysWithProgram = Object.entries(this.api().state.trayOccupancy)
                .filter(([k, v]) => v === program)
                .map(kv => kv[0]);

            traysWithProgram.forEach(tray => {
                this.closeProgramInTray(tray)
            });
        }

        this.api().setState(prevState => ({
            ...prevState,
            trayOccupancy: {
                ...prevState.trayOccupancy,
                [tray]: program,
            },
            openedTrays: [tray, ...prevState.openedTrays]
        }));
    }

    public closeProgramInTray = (tray: string) => {
        this.api().setState(prevState => ({
            ...prevState,
            trayOccupancy: {
                ...prevState.trayOccupancy,
                [tray]: undefined
            },
            openedTrays: prevState.openedTrays.filter(s => s !== tray)
        }));
    }

    public toggleProgram = (tray: string, program: string) => {
        const tray_ = Object
            .entries(this.api().state.trayOccupancy)
            .find(([k, v]) => v === program);
        const isOpened = tray_ !== undefined;
        if (isOpened) {
            this.closeProgramInTray(tray);
        } else {
            this.openProgramInTray(tray, program);
        }
    }

    public closeTray = (tray: string) => {
        this.api().setState(prevState => ({
            ...prevState,
            openedTrays: prevState.openedTrays.filter(s => s !== tray)
        }));
    }

    public closeAllTrays = () => {
        this.api().setState(prevState => ({
            ...prevState,
            openedTrays: []
        }));
    }
}
