import React from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {ExtraNetworkMetaInfo} from "../data/ExtraNetworkMetaInfo";

const StyledExtraNetworkTagIndicator = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e202a;
  border-radius: 0.4rem;
  border: none;
  // padding: 4px 8px;
  padding: 0 !important;
  cursor: pointer;
  transition: all 100ms;
  overflow: hidden;
  margin: 0;
  outline: none;

  &:active {
    filter: brightness(1.1);
  }

  .title, .appendix {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .title {
    
    .type-def {
      height: 26px;
      padding: 4px 8px;
      // background-color: #5028c8;
      background-color: rgb(43, 49, 58);
      width: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      span {
        font-weight: normal;
        line-height: normal;
        vertical-align: central;
        // color: white;
        letter-spacing: 1px;
        font-family: "Fira Code Medium", monospace !important;
      }
    }
  }

  .appendix {
    padding: 4px 8px;
  }
`;

export type ExtraNetworkTagIndicatorProps = {
    netInfo: ExtraNetworkMetaInfo
}

export const ExtraNetworkTagIndicator: React.FC<ExtraNetworkTagIndicatorProps> = props => {
    return (
        <StyledExtraNetworkTagIndicator>
            <div className={"title"}>
                <div className={"type-def"}>
                    <DescriptiveTypography noSelect text={props.netInfo.type} style={{ textTransform: "uppercase" }}/>
                </div>
                <DescriptiveTypography text={props.netInfo.name}/>
            </div>
            <div className={"appendix"}>
                <DescriptiveTypography text={props.netInfo.weight}/>
            </div>
        </StyledExtraNetworkTagIndicator>
    );
}
