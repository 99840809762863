import React from "react";
import {FormikProps} from "formik";
import {Checkbox} from "@mui/material";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {HelpHint} from "./HelpHint";
import {ErrorMessage} from "../../triton/components/forms/ErrorMessage";
import styled from "styled-components";
import {IconButton} from "./IconButton";
import {InfoRounded, MoreVertRounded, Refresh, RefreshRounded} from "@mui/icons-material";
import {Menu} from "./Menu";
import {MenuButton} from "./MenuButton";
import {ButtonModalCompound} from "./ButtonModalCompound";
import {BasicDocumentationDialog} from "./dialogs/BasicDocumentationDialog";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {Grid} from "../../triton/components/layouts/Grid";

export const StyledFormikAdvancedFormCheckbox = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
  flex-direction: column;
  
  .checkbox-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  .checkbox-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    justify-content: end;
  }
  
  .icon-wrapper {
    border-radius: 8px;
    background-color: #0d1117;
  }
`;

export type FormikAdvancedFormCheckboxProps = {
    formik: FormikProps<any>,
    helpText?: string,
    title: string,
    name: string,
    defaultValue?: boolean,
    caption?: string,
    documentation?: string
}

export const FormikAdvancedFormCheckbox: React.FC<FormikAdvancedFormCheckboxProps> = props => {
    return (
        <StyledFormikAdvancedFormCheckbox>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "4px",
                width: "100%"
            }}>
                <div className={"checkbox-left"}>
                    <div className={"icon-wrapper"} children={
                        <Checkbox sx={{
                            padding: "4px",
                            color: "rgb(139, 148, 158)",
                            '&.Mui-checked': {
                                color: "#5028c8",
                            },
                        }} defaultChecked={props.formik.values[props.name] ?? false} onChange={(event, checked) => {
                            props.formik.setFieldValue(props.name, checked);
                        }}/>
                    }/>
                    <MainTypography style={{ userSelect: "none" }} text={props.title}/>
                    <HelpHint text={props.helpText}/>
                </div>

                <div className={"checkbox-right"}>
                    <Menu>
                        <MenuButton
                            disabled={props.defaultValue === undefined}
                            icon={<RefreshRounded/>}
                            text={"Reset"}
                            appendix={props.defaultValue && `Default: ${props.defaultValue ? "Checked" : "Unchecked"}`}
                            onSelect={() => {
                                props.formik.setFieldValue(props.name, props.defaultValue)
                            }}
                        />
                        <ButtonModalCompound
                            button={
                                <MenuButton
                                    text={"Open documentation"}
                                    icon={<InfoRounded/>}
                                />
                            }
                            modalContent={ctx => (
                                <BasicDocumentationDialog
                                    documentation={
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "1rem"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "4px"
                                            }}>
                                                <DescriptiveTypography style={{ fontWeight: "bold" }} text={"Default value:"}/>
                                                <DescriptiveTypography text={
                                                    props.defaultValue === undefined ? "None" : props.defaultValue ? "Checked" : "Unchecked"
                                                }/>
                                            </div>

                                            <div>
                                                <MainTypography text={"Description"}/>
                                                <DescriptiveTypography text={props.documentation ?? "No description provided"}/>
                                            </div>
                                        </div>
                                    }
                                    onClose={() => ctx.close()}
                                    title={"Checkbox details"}
                                />
                            )}
                        />
                    </Menu>
                </div>
            </div>
            <ErrorMessage formikProps={props.formik} name={props.name}/>
            { props.caption && (
                <DescriptiveTypography text={props.caption}/>
            ) }
        </StyledFormikAdvancedFormCheckbox>

    );
}
