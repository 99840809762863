import React from "react";
import styled from "styled-components";
import {FormDivider} from "../../triton/components/forms/FormDivider";
import {MenuButton} from "./MenuButton";
import {
    ArrowRightRounded,
    LightModeRounded, LinkRounded,
    ModeNightRounded,
    OpenInFullRounded, OpenInNewRounded,
    OpenWithRounded,
    RefreshRounded
} from "@mui/icons-material";
import {ColorableTag} from "./ColorableTag";
import {useTriton} from "../../triton/TritonHooks";
import {Theme} from "../../triton/theming/Theme";
import {TritonDefaults} from "../../triton/TritonDefaults";
import lightTheme = TritonDefaults.lightTheme;
import theme = TritonDefaults.theme;
import {ListItem} from "../_/ListItem";
import {useStaticState} from "../hooks/StaticStateHook";
import {MenuGroup} from "./MenuGroup";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {AdvancedStringInput} from "./advancedStringProcessor/AdvancedStringInput";
import {SingleLineInput} from "../../triton/components/forms/SingleLineInput";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {ButtonModalCompound} from "./ButtonModalCompound";
import {Modal} from "../../triton/components/dialogs/Modal";
import {Menu} from "./Menu";

const StyledThemeSelectorMenuPart = styled.div`
`;

export type ThemeSelectorMenuPartState = {
    searchString: string
}

export const ThemeSelectorMenuPart: React.FC = props => {
    const triton = useTriton();
    const themes: Array<Theme> = [
        // Defaults
        theme, lightTheme,

        // Specials
        TritonDefaults.glassyDarkTheme,
        TritonDefaults.blueFutureTheme,
        TritonDefaults.crimsonFutureTheme,
        TritonDefaults.oceanTheme,
        TritonDefaults._1Theme,
        TritonDefaults.forrestTheme,
        TritonDefaults.fireTheme,
        TritonDefaults._4Theme,
        TritonDefaults.blackTheme,
        TritonDefaults.yellowPastelTheme,

    ];

    const [state, ctx] = useStaticState<ThemeSelectorMenuPartState>({
        id: "ThemeSelectorMenuPart",
        staticMode: false,
        initial: {
            searchString: ""
        }
    }).stateWithCtx;

    const filteredThemes = themes.filter(t => t.title?.includes(state.searchString) ?? true);

    return (
        <StyledThemeSelectorMenuPart>

            <MenuGroup title={"Theme"}>
                <SingleLineInput placeholder={"Search theme by name"} value={state.searchString} onChange={(e, value) => {
                    ctx.update({
                        searchString: value
                    })
                }}/>

                <TransitionGroup>
                    { filteredThemes.map(t => {
                        const appendix = triton.theme.id === t.id ? (
                            <ColorableTag tag={"Selected"}/>
                        ) : undefined

                        return (
                            <Collapse key={t.id}>
                                <ListItem
                                    // keepOpenOnClick
                                    icon={(t.isDarkTheme ?? false) ? <ModeNightRounded/> : <LightModeRounded/>}
                                    title={t.title ?? t.id}
                                    active={triton.theme.id === t.id}
                                    onClick={() => {
                                        triton.setState(prevState => ({
                                            ...prevState,
                                            activeTheme: t
                                        }))
                                    }}
                                />
                            </Collapse>
                        )
                    }) }

                    { filteredThemes.length === 0 && (
                        <Collapse>
                            <DescriptiveTypography text={"No themes found"}/>
                        </Collapse>
                    ) }
                </TransitionGroup>

                <div>
                    <Menu opener={
                        <MenuButton
                            keepOpenOnClick
                            text={"All themes"}
                            appendix={<ArrowRightRounded fontSize={"small"}/>}
                        />
                    } children={
                        filteredThemes.map(t => (
                            <MenuButton
                                keepOpenOnClick
                                icon={(t.isDarkTheme ?? false) ? <ModeNightRounded/> : <LightModeRounded/>}
                                text={t.title ?? t.id}
                                selected={triton.theme.id === t.id}
                                onSelect={() => {
                                    triton.setState(prevState => ({
                                        ...prevState,
                                        activeTheme: t
                                    }))
                                }}
                            />
                        ))
                    }/>

                    <MenuButton
                        keepOpenOnClick
                        text={"Reset theme"}
                        onSelect={() => triton.resetTheme()}
                        icon={<RefreshRounded/>}
                    />

                    <ButtonModalCompound
                        button={
                            <MenuButton
                                keepOpenOnClick
                                text={"Open theme-selector as modal"}
                                icon={<OpenInNewRounded/>}
                            />
                        }
                        modalContent={modelCtx => (
                            <Modal title={"Themes"} open={true} onClose={modelCtx.close} children={
                                <ThemeSelectorMenuPart/>
                            }/>
                        )}
                    />
                </div>
            </MenuGroup>
        </StyledThemeSelectorMenuPart>
    );
}
