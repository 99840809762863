import React, {useState} from "react";
import styled from "styled-components";
import {Entity} from "../../ecs/entity/Entity";
import {ListItem} from "../../../../ardai/_/ListItem";
import {ChevronLeft, CommitRounded, DataObject, FormatShapesRounded, Interests, ShapeLine} from "@mui/icons-material";
import {StringField} from "../../../../ardai/testing/StringField";
import {CollapseIconButton} from "../../../../ardai/components/CollapseIconButton";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {FlatIconButton} from "../../../../ardai/components/FlatIconButton";
import {motion} from "framer-motion";
import {MainTypography} from "../../../../triton/components/typography/MainTypography";
import {StateEntryExtractor} from "../../StateEntryExtractor";
import {Std} from "../../../../../Std";
import also = Std.also;
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {RawStateEntry} from "../../RawStateEntry";

const StyledEntityDisplay = styled.div`
  width: 100%;
  
  .entity-display-header {
    display: grid;
    grid-template-columns: auto min-content;
    gap: 8px;
    align-items: center;
  }
  
  .entity-display-details-container {
    padding: 8px 0 8px 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export type EntityDisplayProps = {
    entity: Entity
}

export type EntityDisplayState = {
    expanded: boolean
}

export const EntityDisplay: React.FC<EntityDisplayProps> = props => {
    const e = props.entity;

    const [state, setState] = useState<EntityDisplayState>({
        expanded: false
    });

    return (
        <StyledEntityDisplay>

            <div className={"entity-display-header"}>
                <ListItem
                    title={e.id}
                    // icon={<CommitRounded/>}
                    // icon={<ShapeLine/>}
                    // icon={<Interests/>}
                    icon={<DataObject sx={{
                        fontSize: 16
                    }}/>}
                    onClick={() => setState(prevState => ({
                        ...prevState,
                        expanded: !prevState.expanded
                    }))}
                />

                <FlatIconButton onClick={() => setState(prevState => ({
                    ...prevState,
                    expanded: !prevState.expanded
                }))} children={
                    <div style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} children={
                        <motion.div style={{
                            aspectRatio: "1 / 1",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }} animate={state.expanded ? "expanded" : "collapsed"} variants={{
                            expanded: {
                                rotate: 90
                            },
                            collapsed: {
                                rotate: 270
                            }
                        }} children={
                            <ChevronLeft sx={{
                                fontSize: 20
                            }}/>
                        }/>
                    }/>
                }/>
            </div>


            <TransitionGroup children={state.expanded && (
                <Collapse>
                    <div className={"entity-display-details-container"}>

                        { e.config.description && (
                            <DescriptiveTypography text={e.config.description}/>
                        ) }

                        <MainTypography text={"State"}/>

                        { (() => {
                            const kvs = new StateEntryExtractor().extract(e.state)
                            if (kvs.length === 0) return (
                                <DescriptiveTypography text={"No visible state entries"}/>
                            )

                            return kvs.map(kv => (
                                <StringField
                                    name={kv.key}
                                    data={JSON.stringify(kv.value)}
                                    onChange={data => {}}
                                />
                            ))
                        })() }

                        <MainTypography text={"Traits"}/>
                        { (() => {
                            const traits = Array.from(e.traits?.values() ?? []);

                            if (traits.length === 0) return (
                                <DescriptiveTypography text={"Entity has no traits"}/>
                            )

                            return traits.map(trait => (
                                <div>

                                    <StringField
                                        name={"trait.traitName"}
                                        data={trait.traitName}
                                        onChange={data => {}}
                                    />

                                    { Object.getOwnPropertyNames(Object.getPrototypeOf(trait)).map(p => (
                                        <StringField
                                            name={p}
                                            data={p}
                                            onChange={data => {}}
                                        />
                                    )) }

                                    { Object
                                        .entries(trait)
                                        .filter(kv => kv[0] !== "traitName")
                                        .filter(kv => kv[0] !== "_entity")
                                        .map(kv => (
                                            <StringField
                                                name={kv[0]}
                                                data={JSON.stringify(kv[1])}
                                                onChange={data => {}}
                                            />
                                        ))
                                    }
                                </div>
                            ))
                        })() }

                        <MainTypography text={"Children"}/>

                        { (() => {
                            const children = Array.from(e.children.values());

                            if (children.length === 0) return (
                                <DescriptiveTypography text={"No children"}/>
                            )

                            return (
                                <div style={{
                                    // paddingLeft: 20
                                    paddingLeft: 10
                                }} children={
                                    children.map(cE => (
                                        <EntityDisplay entity={cE}/>
                                    ))
                                }/>
                            )
                        })() }

                    </div>
                </Collapse>
            )}/>


        </StyledEntityDisplay>
    );
}
