import {InflationLibGlobals} from "./InflationLibGlobals";
import {SerializableObject} from "../../../../../std/SerializableObject";
import {SaveStateNode} from "./SaveStateNode";
import {ArrayLinkImpl} from "../../../../../std/Accumulate";
import {InflatableHelpers} from "./InflatableHelpers";
import {Inflatable} from "./Inflatable";
import "reflect-metadata";
import {InflateObjectAttachment} from "./InflateObjectAttachment";
import {transient} from "./DecoratorTransient";
import {InflationContext} from "./InflationContext";

export type MarkInflatableConfig = {
    readonly recursive: boolean,
    readonly additionalTransients: Array<string | symbol>
}

export function markInflatable(cfg: Partial<MarkInflatableConfig> = {}) {
    const fullCfg: MarkInflatableConfig = { // TODO: Make use of cfg
        recursive: true,
        additionalTransients: [],
        ...cfg
    };

    return function <T extends { new (...args: any[]): {} }>(constructor: T) {


        return class extends constructor {

            // noinspection JSUnusedLocalSymbols
            private _inflate = new InflateObjectAttachment(this, fullCfg);

            private get _inflateTargets(): Array<string | symbol> {
                return Reflect.getMetadata(InflationLibGlobals.inflateTargetsMetadataKey, this) || [];
            }

            /**
             * @deprecated
             * @private
             */
            private get _inflateTransients(): Array<string | symbol> {
                return Reflect.getMetadata(InflationLibGlobals.inflateTransientsMetadataKey, this) || [];
            }

            private _amendDeflate = (save: SerializableObject, node: SaveStateNode) => (<Inflatable><unknown>this)
                .amendDeflate?.(save, node);

            private _amendInflate = (save: SerializableObject, node: SaveStateNode, ctx: InflationContext) => (<Inflatable><unknown>this)
                .amendInflate?.(save, node, ctx);

            // noinspection JSUnusedGlobalSymbols
            deflate = (): SaveStateNode => new ArrayLinkImpl(this._inflateTargets)
                .map(x => <string>x)
                .accumulateAsDispatch({} as SerializableObject, (x1, x2) => x1[x2] = (<any>this)[x2])
                .run(save => InflatableHelpers.deflateRecursively(<Inflatable><unknown>this, save))
                .also(node => this._amendDeflate(node.data, node))
                .unboxed;

            // noinspection JSUnusedGlobalSymbols
            inflate = (save: SerializableObject, node: SaveStateNode, ctx: InflationContext) => new ArrayLinkImpl(this._inflateTargets)
                .map(x => <string>x)
                .forEach(propertyKey => (this as any)[propertyKey] = save[propertyKey as string])
                .run(() => InflatableHelpers.inflateRecursively(<Inflatable><unknown>this, node, ctx))
                .also(() => this._amendInflate(save, node, ctx));
        };
    }
}
