import React from "react";
import {RoundedButton, RoundedButtonProps} from "./RoundedButton";
import {MainTypography} from "../../../../triton/components/typography/MainTypography";

export type RoundedMainTextButtonProps = RoundedButtonProps & {
    text: React.ReactNode
}

export const RoundedMainTextButton: React.FC<RoundedMainTextButtonProps> = props => {
    return <RoundedButton {...(props as RoundedButtonProps)} children={
        <MainTypography text={props.text} style={{
            color: "black",
            fontWeight: "bold",
            fontSize: "16px",
            userSelect: "none",
            display: "flex",
            alignItems: "center"
        }}/>
    }/>
}
