import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import moment from "moment";

const StyledScreenSaverRightWidget = styled.div`
  width: 100%;
  height: 250px;
  padding: 8px;
  // background-color: rgb(16, 16, 22);
  // border-radius: 8px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border:1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
`;

export const ScreenSaverRightWidget: React.FC = props => {
    const [time, setTime] = useState(new Date().toLocaleTimeString("de-DE"))

    useEffect(() => {
        const timerID = setInterval(() => {
            setTime(new Date().toLocaleTimeString("de-DE"));
        }, 1e3);

        return () => {
            clearInterval(timerID);
        };
    })

    return (
        <StyledScreenSaverRightWidget>
            <p children={time} style={{
                color: "white",
                textAlign: "center",
                fontFamily: "Fira Code",
                fontSize: 32,
                fontWeight: 400,
                margin: 0
            }}/>
            <DescriptiveTypography text={
                `${moment().format("dddd, Do")}`
            }/>
        </StyledScreenSaverRightWidget>
    );
}
