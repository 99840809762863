import {commonCore} from "./CommonCore";
import {axiosExo} from "../../shared/Exo";
import {arcURL} from "../../Globals";

export class Userspace {

    public async getProfileData() {
        return commonCore().cache.fetchAsync("profile-data", async () => (await axiosExo({
            url: arcURL("profile"),
            method: "get"
        })).data);
    }
}
