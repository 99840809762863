import React from "react";
import styled from "styled-components";
import {MultiTray, TrayRenderer} from "../../_/multiTray/MultiTray";
import {GenerationSettingsToolWindow} from "./toolWindows/generationSettingsToolWindow/GenerationSettingsToolWindow";

const StyledGenerateMainLayout = styled.div`
  // background-color: red;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
  
  .north-container {
    // background-color: orangered;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    
    .alpha-container, .beta-container, .gamma-container {
      width: 100%;
      height: 100%;
    }
  }
  
  .south-container {
    // background-color: orange;
  }
`;

export const GenerateMainLayout: React.FC = props => {
    return (
        <StyledGenerateMainLayout>
            <div className={"north-container"}>
                <div className={"alpha-container"} children={
                    <MultiTray
                        name={"alpha-container"}
                        defaultTray={"generation-settings"}
                        tabHeaders={[
                            { id: "generation-settings", title: "Settings" },
                            { id: "a", title: "ControlNet" }
                        ]}
                        trays={new Map<string, TrayRenderer>([
                            ["generation-settings", ctx => <GenerationSettingsToolWindow/>]
                        ])}
                    />
                }/>
                <div className={"beta-container"} children={
                    <MultiTray
                        name={"beta-container"}
                        tabHeaders={[
                            { id: "a", title: "Preview" },
                            { id: "generation-settings", title: "Settings" }
                        ]}
                        trays={new Map<string, TrayRenderer>([

                        ])}
                    />
                }/>
                <div className={"gamma-container"} children={
                    <MultiTray
                        name={"gamma-container"}
                        defaultTray={"generation-settings"}
                        tabHeaders={[
                            { id: "a", title: "History" },
                            { id: "b", title: "Debugger" },
                            { id: "c", title: "TaskManager" },
                        ]}
                        trays={new Map<string, TrayRenderer>([
                            ["generation-settings", ctx => <GenerationSettingsToolWindow/>]
                        ])}
                    />
                }/>
            </div>
            <div className={"south-container"}></div>
        </StyledGenerateMainLayout>
    );
}
