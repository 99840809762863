import {FC, PropsWithChildren} from "react";
import styled from "styled-components";
import {SecondaryTextTypography} from "./typography/SecondaryTextTypography";
import {Alignment} from "../../../node/frontend/Alignment";

const StyledTextWithDescriptiveHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  position: relative;
  
  &.align-right {
    & > * {
      text-align: right;
    }
  }
  
  
`;

export type TextWithDescriptiveHeaderProps = PropsWithChildren<{
    subtitle: string,
    align?: Alignment
}>

export const TextWithDescriptiveHeader: FC<TextWithDescriptiveHeaderProps> = props => {
    const align = props.align ?? Alignment.LEFT;

    return (
        <StyledTextWithDescriptiveHeader className={align === Alignment.RIGHT ? "align-right" : ""}>
            <SecondaryTextTypography text={props.subtitle} s={{
                textTransform: "uppercase",
                fontWeight: "500"
            }}/>
            { props.children }
        </StyledTextWithDescriptiveHeader>
    );
}
