import {v4} from "uuid";

export interface IKVStore<Key extends string = string> {
    readonly id: string
    set(key: Key, val: string): string;
    get(key: Key, defVal: string): string;
    get(key: Key): string | undefined;
}

export class LocalStorageKVStore<Key extends string = string> implements IKVStore<Key> {

    constructor(
        readonly id: string = v4()
    ) {}

    get(key: Key, defVal: string): string;
    get(key: Key): string | undefined;
    get(key: Key, defVal?: string): string | undefined {
        const val = window.localStorage.get(this.localKeyToGlobalKey(key));
        return val ?? defVal;
    }

    set(key: Key, val: string): string {
        window.localStorage.setItem(key, val);
        return val;
    }

    private localKeyToGlobalKey(key: Key): string {
        return `${this.id}-${key}`;
    }
}
