import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import {DimensionalMeasured} from "../../../base/logic/style/DimensionalMeasured";

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export type FlexProps = PropsWithChildren<{
    baseProps?: React.HTMLAttributes<HTMLElement>,
    g?: string | number
}>

export const Flex: React.FC<FlexProps> = props => {
    return (
        <StyledFlex {...props.baseProps} children={props.children} style={{
            ...props.baseProps?.style,
            gap: props.g,
        }}/>
    );
}
