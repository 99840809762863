import React, {FC} from "react";
import {useTriton} from "../triton/TritonHooks";
import {GlobalStyles} from "@mui/material";

export const GlobalStyling: FC = props => {
    const triton = useTriton();

    return (
        <>
            <GlobalStyles styles={{
                "a:focus, button:focus, [role=button]:focus, input[type=radio]:focus, input[type=checkbox]:focus": {
                    outline: "2px solid mediumpurple",
                    outlineOffset: "-2px",
                    boxShadow: "none",
                    transition: "outline 0ms !important"
                },
                "a:focus-visible, button:focus-visible, [role=button]:focus-visible, input[type=radio]:focus-visible, input[type=checkbox]:focus-visible": {
                    outline: "2px solid mediumpurple",
                    outlineOffset: "-2px",
                    boxShadow: "none"
                },
                "a:focus:not(:focus-visible), button:focus:not(:focus-visible), [role=button]:focus:not(:focus-visible), input[type=radio]:focus:not(:focus-visible), input[type=checkbox]:focus:not(:focus-visible)": {
                    outline: "solid 1px transparent",
                }
            }}/>

            <GlobalStyles styles={{
                ".triton-typography-main": {
                    color: triton.col("fg_default")
                },
                ".triton-typography-secondary": {
                    color: triton.col("fg_muted")
                }
            }}/>
        </>
    );
}
