import React, {useContext, useState} from "react";
import styled from "styled-components";
import {StyledControlDisplayGroup} from "../../components/StyledControlDisplayGroup";
import {SimulationContext} from "../../Simulation2Main";
import {Button} from "../../components/Button";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {hex} from "../../../../base/logic/style/Color";
import _ from "lodash";
import {InvertColorsRounded} from "@mui/icons-material";
import {useEventListener} from "@mantine/hooks";
import {useKeyboardEvent} from "../../../../ardai/_/keyCommand/test/KeyCommandTest";
import {Entity} from "../../ecs/entity/Entity";

const StyledMultiplexedCoreControlPanel = styled.div`
  .core-selection-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
  }
  
  .core-control-panel {
    display: grid;
    grid-template-columns: repeat(auto-fill, 32px);
    gap: 4px;
  }
`;

export type MultiplexedCoreControlPanelState = {
    selCores: Array<string>
}

export const MultiplexedCoreControlPanel: React.FC = props => {
    const [state, setState] = useState<MultiplexedCoreControlPanelState>({
        selCores: []
    });
    const sim = useContext(SimulationContext);
    const engine = sim.entity("engine");

    const cores = engine?.getChildrenFromGroup("cores");
    const selCores = engine?.getChildrenFromGroup("cores").filter(c => state.selCores.includes(c.id));

    const isCoreSelected = (id: string) => selCores.filter(c => c.id === id).length > 0;

    const toggleCoreSelection = (id: string) => setState(prevState => ({
        ...prevState,
        selCores: prevState.selCores.includes(id) ?
            prevState.selCores.filter(s => s !== id) :
            _.uniq([...prevState.selCores, id])
    }));

    useEventListener("scroll", ev => {
        window.alert(ev)
    })


    const invertCoreSelection = () => {
        setState(prevState => {
            const inverted = cores
                .map(sC => prevState.selCores.includes(sC.id) ? undefined : sC.id)
                .filter(id => id !== undefined);

            console.log(inverted)

            return ({
                selCores: inverted as any
            });
        })
    }

    const withCore = (action: (c: Entity) => void) => {
        selCores.forEach(c => action(c));
    };

    useKeyboardEvent("keydown", e => {
        switch (e.key) {
            case 'Dead': {
                withCore(c => c.setStatePartially(prevState => ({
                    throttle: Math.min(100, prevState.throttle + 10)
                })))
                break;
            }
            case 'ß': {
                withCore(c => c.setStatePartially(prevState => ({
                    throttle: Math.max(0, prevState.throttle - 10)
                })))
                break;
            }
        }
    });

    return (
        <StyledMultiplexedCoreControlPanel>
            <StyledControlDisplayGroup>

                <div className={"core-selection-list"} children={cores?.map(c => (
                    <Button deactivateTransition children={c.id} onClick={() => toggleCoreSelection(c.id)} color={{
                        if: [
                            { cond: () => isCoreSelected(c.id), color: hex("#58A6FF") }
                        ]
                    }}/>
                ))}/>

                <div className={"core-control-panel"}>
                    <Button children={
                        <InvertColorsRounded/>
                    } onClick={() => invertCoreSelection()}/>

                    <Button children={"ON"} onClick={() => withCore(c => c.setStatePartially({
                        active: true
                    }))}/>
                    <Button children={"OFF"} onClick={() => withCore(c => c.setStatePartially({
                        active: false
                    }))}/>

                    <Button children={"0%"} onClick={() => withCore(c => c.setStatePartially({
                        throttle: 0
                    }))}/>
                    <Button children={"50%"} onClick={() => withCore(c => c.setStatePartially({
                        throttle: 50
                    }))}/>
                    <Button children={"100%"} onClick={() => withCore(c => c.setStatePartially({
                        throttle: 100
                    }))}/>

                    <Button children={"+10"} onClick={() => withCore(c => c.setStatePartially(prevState => ({
                        throttle: Math.min(100, prevState.throttle + 10)
                    })))}/>

                    <Button children={"A"} onClick={() => withCore(c => c.getChild("cooler").setStatePartially({
                        manOverruling: !(c.getChild("cooler").state.manOverruling ?? false)
                    }))}/>

                    <Button children={"AC"} onClick={() => withCore(c => c.getChild("cooler").setStatePartially({
                        manActive: !(c.getChild("cooler").state.manActive ?? false)
                    }))}/>
                </div>

            </StyledControlDisplayGroup>
        </StyledMultiplexedCoreControlPanel>
    );
}
