import React, {PropsWithChildren, useContext, useEffect, useRef, useState} from "react";
import {Triton} from "./Triton";
import {TritonState} from "./TritonState";
import {TritonDefaults} from "./TritonDefaults";
import {TritonContext} from "./TritonContext";
import {StateDispatcher} from "../ship/test/core/StateDispatcher";
import {useStaticState} from "../ardai/hooks/StaticStateHook";

export type TritonReactBridgeProps = {
    factory: () => Triton,
    children: () => React.ReactNode
}

export type TritonStaticState = {
    activeThemeID: string
}

export const TritonReactBridge: React.FC<TritonReactBridgeProps> = props => {
    const triton = useRef(props.factory());
    const t = triton.current;

    const [staticState, staticCtx] = useStaticState<TritonStaticState>({
        id: "TritonReactBridge",
        initial: {
            activeThemeID: t.theme.id
        }
    }).stateWithCtx;


    useEffect(() => {
        try {
            if (t.theme.id === staticState.activeThemeID) return;
            t.switchTheme(staticState.activeThemeID);
        } catch (e) {
            console.groupCollapsed("Error switching triton theme");
            console.error(e);
            console.groupEnd();
        }
    }, [staticState.activeThemeID]);

    const [state, setState] = useState<TritonState>({
        activeTheme: TritonDefaults.theme
    });

    useEffect(() => {
        if (t.theme.id === staticState.activeThemeID) return;
        staticCtx.update({
            activeThemeID: state.activeTheme.id
        });
    }, [state.activeTheme]);

    return (
        <TritonStateContext.Provider value={[state, setState]} children={
            <TritonContext.Provider value={triton.current} children={
                <TritonReactBridgeMaster children={
                    props.children()
                }/>
            }/>
        }/>
    );
}

export const TritonStateContext = React.createContext<[TritonState?, StateDispatcher<TritonState>?]>([]);

export const TritonReactBridgeMaster: React.FC<PropsWithChildren> = props => {
    const [state, setState] = useContext(TritonStateContext);
    const triton = useContext(TritonContext);

    if (state && setState) {
        triton.updateState(state);
        triton.updateStateDispatcher(setState);
    }

    return (
        <>{ props.children }</>
    );
}
