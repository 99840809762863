// noinspection RedundantIfStatementJS,SpellCheckingInspection

import {v4} from "uuid";
import _ from "lodash";
import {randomInt} from "mathjs";
import {Std} from "../../Std";
import {IDeflatable} from "./IDeflatable";
import {DeflationContext} from "./DeflationContext";
import {SaveDataLayoutComponent} from "./SaveDataLayout";

export namespace Types {

    export type CurrencyInfo = {
        value: number
    }

    export enum CurrencyType {
        COPPER, SILVER, GOLD
    }

    export const CurrencyTypeInfo: Record<CurrencyType, CurrencyInfo> = {
        [CurrencyType.COPPER]: {
            value: 1
        },
        [CurrencyType.SILVER]: {
            value: 2
        },
        [CurrencyType.GOLD]: {
            value: 3
        }
    }

    export class Amount {
        constructor(
            public readonly amount: number,
            public readonly currency: CurrencyType = CurrencyType.COPPER,
        ) {}

        public asCopper(): number {
            return this.amount;
        }
    }

    export enum CardType {
        ACTION = "ACTION",
        CURRENCY = "CURRENCY",
        PURCHASABLE = "PURCHASABLE",
        VICTORY_POINTS = "VICTORY_POINTS",
        UPGRADABLE = "UPGRADABLE"
    }

    export type CardActionTrait = Action & {}

    export type PriceInfo = {
        genericPrice: number,
        buyerPrice: number
    }

    export type CardPurchasableTrait = {
        calcBuyerPrice: (buyer: DominionV1.Player) => PriceInfo,
    }

    export type CardCurrencyTrait = {
        value: Producer<Amount>
    }

    export type VictoryPointsTrait = {
        calculateVictoryPoints: (player: DominionV1.Player) => number
    }

    export type CardTraitMapping = {
        [CardType.ACTION]: CardActionTrait,
        [CardType.CURRENCY]: CardCurrencyTrait,
        [CardType.PURCHASABLE]: CardPurchasableTrait,
        [CardType.VICTORY_POINTS]: VictoryPointsTrait,
        [CardType.UPGRADABLE]: CardUpgradableTrait
    }

    export interface IDescriptionComponent<PayloadType> {
        readonly type: string,
        readonly payload: PayloadType
    }

    export class StringDescriptionComponent implements IDescriptionComponent<string> {

        readonly type = "string";

        constructor(
            public readonly payload: string
        ) {}
    }

    export interface IExtensionOffer {
        id: string,
        descriptor: Descriptor,
        createBill(buyer: DominionV1.Player): DominionV1.Bill<IExtensionOffer>,
        generateExtension(card: Card<any>): IExtension
    }

    export interface IExtensionEvent<Payload = void> {
        readonly card: ICard,
        readonly payload: Payload
    }

    export interface IExtension {
        id: string,
        descriptor: Descriptor,
        get fieldManager(): FieldManager,
        get actionManager(): LocalActionManager,

        // Events
        onExtensionRegistration?(card: Types.ICard): void,
        onTurnStart?(ev: IExtensionEvent<{
            readonly player: DominionV1.Player,
            readonly turn: DominionV1.Turn
        }>): void,
        onTurnPreCleanup?(ev: IExtensionEvent<{
            readonly player: DominionV1.Player,
            readonly turn: DominionV1.Turn
        }>): void,
        onTurnEnd?(player: DominionV1.Player, turn: DominionV1.Turn): void
    }

    export abstract class AbstractExtension implements IExtension {

        private readonly _fieldManager: FieldManager;
        private readonly _actionManager: LocalActionManager;

        abstract readonly id: string;
        abstract readonly descriptor: Descriptor;

        constructor(card: Card<any>) {
            this._fieldManager = new Types.FieldManager(card);
            this._actionManager = new Types.LocalActionManager();
        }

        get fieldManager(): Types.FieldManager {
            return this._fieldManager;
        }

        get actionManager(): Types.LocalActionManager {
            return this._actionManager;
        }
    }

    export class ExtensionManager {

        private _offers: Array<IExtensionOffer> = []

        private _extensions: Array<IExtension> = []

        constructor(
            private readonly card: Card<any>
        ) {}

        public async buyExtension(offer: IExtensionOffer) {
            if (!this.card.isOwned()) throw new Error();
            const owner = this.card.owner!;
            const buyPhase = owner.turn?.phase.asBuyTurnPhase();
            if (buyPhase === undefined) throw new Error();
            const bill = offer.createBill(owner);
            const invoice = await owner.invoice(bill);
            if (invoice.status !== DominionV1.InvoiceResultStatus.FULFILLED) throw new Error();
            const extension = offer.generateExtension(this.card);
            buyPhase.executeBuyTransaction(() => {
                this.markOfferAsUsed(offer);
                this.registerExtension(extension);
                return {
                    status: DominionV1.PurchaseResultStatus.COMPLETED
                };
            });
        }

        public markOfferAsUsed(offer: IExtensionOffer) {
            this._offers = this.offers.filter(o => o.id !== offer.id);
        }

        public registerExtension(extension: IExtension) {
            this.extensions.push(extension);
            extension.onExtensionRegistration?.(this.card);
        }

        public hasExtension(extensionID: string): boolean {
            return this.extensions.find(ext => ext.id === extensionID) !== undefined;
        }

        get extensions(): Array<Types.IExtension> {
            return this._extensions;
        }

        get offers(): Array<Types.IExtensionOffer> {
            return this._offers;
        }
    }

    export type DescriptorDescription = Array<Types.IDescriptionComponent<any>>;

    export type Descriptor = {
        displayName?: string,
        description?: DescriptorDescription,
        lore?: string
    };

    export interface IField<DataType> {
        name: string,
        _displayName?: string,
        get displayName(): string,
        type: "string" | "number" | string,
        get value(): DataType,
        set value(newValue: DataType),
        updateValue(updater: (prevState: DataType) => DataType | undefined): void
    }

    export class NumericField implements IField<number> {

        type = "number";

        private _value: number = 0;

        constructor(
            public readonly name: string,
            public readonly _displayName?: string
        ) {}

        get displayName(): string {
            return this._displayName ?? this.name;
        }

        updateValue(updater: (prevState: number) => (number | undefined)): void {
            const updated = updater(this.value);
            if (updated !== undefined) {
                this.value = updated;
            }
        }

        get value(): number {
            return this._value;
        }

        set value(newValue: number) {
            this._value = newValue;
        }
    }

    export class FieldManager {

        private readonly _fields: Array<IField<any>> = [];

        constructor(
            private readonly card: Card<any>
        ) {}

        public addField(field: IField<any>): this {
            this._fields.push(field);
            return this;
        }

        public withField<DataType = any, T = any>(name: string, block: (field: IField<DataType>) => T): T | undefined {
            const field = this.getField(name);
            if (field === undefined) return undefined;
            return block(field);
        }

        public getField<DataType = any>(name: string, factory?: () => IField<DataType>): IField<DataType> {
            let field = this._fields.find(f => f.name = name);
            if (field === undefined) {
                field = factory?.()!;
                this.addField(field);
            }
            return field as IField<DataType>;
        }

        get fields(): Array<Types.IField<any>> {
            return this._fields;
        }
    }

    export abstract class LocalAction {
        abstract readonly descriptor: Descriptor
        abstract canFire?(): boolean
        abstract fire(): void
    }

    export class LocalActionManager {
        readonly actions: Array<LocalAction> = []
    }

    export interface ICard {
        id: string,
        name: string,
        mainType?: CardType,
        types: Set<CardType>,
        traits: Partial<CardTraitMapping>,
        description?: Array<IDescriptionComponent<any>>,
        get extensionManager(): ExtensionManager,
        get fieldManager(): FieldManager,
        get displayName(): string,
        set displayName(newDisplayName: string),
        get level(): number,
        get value(): number,
        set value(newValue: number),
        get upgradeInfos(): Map<string, UpgradeInfo<ICard>>,
        get owner(): DominionV1.Player | undefined,
        isOwned(): boolean,
        isUpgradable(): boolean,
        isAtMaxLevel(): boolean,
        setOwner(owner: DominionV1.Player): this,
        setDescription(description: Array<IDescriptionComponent<any>>): this,
        upgrade(upgrade: Upgrade<ICard>): Promise<void>,
        getUpgradeInfo(upgrade: Upgrade<ICard>, infoFactory?: () => UpgradeInfo<ICard>): UpgradeInfo<ICard>,
        updateUpgradeInfo(upgradeID: string, block: (info: UpgradeInfo<ICard>) => UpgradeInfo<ICard> | void): this,
        initCustomizations(): void
    }

    export abstract class Card<DataType = void> implements ICard {
        readonly id: string = v4();
        abstract readonly name: string;
        abstract readonly traits: Partial<Types.CardTraitMapping>;
        abstract readonly types: Set<Types.CardType>;
        readonly _extensionManager: ExtensionManager = new ExtensionManager(this);
        readonly _fieldManager: FieldManager = new FieldManager(this);
        protected _level: number = 1;
        protected _upgradeInfos: Map<string, UpgradeInfo<ICard>> = new Map<string, Types.UpgradeInfo<Types.ICard>>();
        protected _owner: DominionV1.Player | undefined;
        protected _displayName: string | undefined;
        _value: number = 0;
        description: Array<Types.IDescriptionComponent<any>> = [];

        constructor(
            private readonly data: DataType
        ) {
            this.init();
        }

        protected init() {}

        public initCustomizations() {}

        get extensionManager(): Types.ExtensionManager {
            return this._extensionManager
        }

        setDescription(description: Array<Types.IDescriptionComponent<any>>): this {
            this.description = description;
            return this;
        }

        public get displayName(): string {
            return this._displayName ?? this.name;
        }

        get owner(): DominionV1.Player | undefined {
            return this._owner;
        }

        set displayName(newDisplayName: string) {
            this._displayName = newDisplayName;
        }

        get level(): number {
            return this._level;
        }

        set level(newLevel: number) {
            this._level = newLevel;
        }

        get value(): number {
            return this._value;
        }

        set value(newValue: number) {
            this._value = newValue;
        }

        isAtMaxLevel(): boolean {
            if (!this.isUpgradable()) return false;
            return true;
        }

        isUpgradable(): boolean {
            return this.types.has(CardType.UPGRADABLE);
        }

        isOwned(): boolean {
            return this._owner !== undefined;
        }

        public setOwner(owner: DominionV1.Player): this {
            this._owner = owner;
            return this;
        }

        get fieldManager(): Types.FieldManager {
            return this._fieldManager;
        }

        public async upgrade(upgrade: Upgrade<ICard>) {
            if (!this.isOwned() || !this.isUpgradable()) return;
            const owner = this._owner!;
            if (owner.turn?.phase?.data.type !== DominionV1.TurnPhase.Types.ACTION_PHASE) return;
            if (!owner.hasActionCounterLeft()) return;
            const upgradeID = upgrade.id;
            const upgradeInfo = this.getUpgradeInfo(upgrade);
            if (upgradeInfo.useAmount >= upgrade.config.maxUses) {
                // Can't apply upgrade, it's used to it's full amount already
                return;
            }
            // const cardToUpgradeIdx = owner.hand.cards.indexOf(this);
            // const cardToUpgrade = owner.hand.getAtIndex(cardToUpgradeIdx);
            try {
                await owner.invoice(upgrade.bill(upgrade, this));
            } catch (e) {
                // owner.hand.append([cardToUpgrade]);
                return;
            }
            const actionPhase = owner.turn?.phase?.asActionTurnPhase()!;
            const wasActionPerformed = await actionPhase.performGenericAction(async () => {
                upgrade.upgrade(this);
            });
            this.updateUpgradeInfo(upgradeID, info => {
                info.useAmount++;
            });
            if (wasActionPerformed) {
                this._level++;
            }
            // owner.shelf.append([cardToUpgrade]);

            // TODO: Maybe move to Card relay?
            owner.relay.fire(Card.Events.UPGRADED);
        }

        get upgradeInfos(): Map<string, Types.UpgradeInfo<Types.ICard>> {
            return this._upgradeInfos;
        }

        getUpgradeInfo(upgrade: Upgrade<ICard>, infoFactory?: () => Types.UpgradeInfo<Types.ICard>): Types.UpgradeInfo<Types.ICard> {
            let info: Types.UpgradeInfo<Types.ICard> | undefined = this.upgradeInfos.get(upgrade.id);
            if (info !== undefined) return info;
            info = infoFactory?.() ?? {
                for: upgrade,
                useAmount: 0
            };
            this._upgradeInfos.set(upgrade.id, info);
            return info;
        }

        updateUpgradeInfo(upgradeID: string, block: (info: Types.UpgradeInfo<Types.ICard>) => (Types.UpgradeInfo<Types.ICard> | void)): this {
            if (!this.upgradeInfos.has(upgradeID)) return this;
            let info: Types.UpgradeInfo<Types.ICard> = this.upgradeInfos.get(upgradeID)!;
            const infoDelta = block(info);
            if (infoDelta !== undefined) info = infoDelta;
            this.upgradeInfos.set(upgradeID, info);
            // TODO: Fire relay event
            return this;
        }
    }

    export namespace Card {
        export enum Events {
            UPGRADED = "UPGRADED"
        }
    }

    export type Predicate = () => boolean

    export type NullaryFn = () => void

    export type ActionFn = (game: DominionV1.Game, source: DominionV1.Player) => Promise<void>

    export type Action = {
        canFire: Predicate,
        fn: ActionFn,
        prepareFn: ActionFn
    }

    export type Producer<Product> = () => Product

    export type UnaryOperator<Operand> = (x: Operand) => Operand

    export type UnaryFunction<T, V> = (x: T) => V

    export class Transaction {

        private activeTransactions: Set<string> = new Set;

        constructor(
            private readonly config: {
                readonly finalizer: NullaryFn
            }
        ) {}

        public run<T = void>(name: string, block: Producer<T>, localFinalizer: NullaryFn = Defaults.noop): T {
            const isCore = !this.activeTransactions.has(name);
            if (isCore) {
                this.activeTransactions.add(name);
            }
            const ret = block();
            if (isCore) {
                this.activeTransactions.delete(name);
                localFinalizer();
                this.config.finalizer();
            }
            return ret;
        }
    }

    export type UpgradeFn<Upgradable> = (upgradable: Upgradable) => void;

    export type UpgradeInfo<Upgradable> = {
        for: Upgrade<Upgradable>,
        useAmount: number
    }

    export type UpgradeConfig<Upgradable> = {
        maxUses: number
    }

    export class Upgrade<Upgradable> {

        public readonly id: string = v4();

        constructor(
            public readonly displayName: string,
            public readonly bill: (
                upgrade: Upgrade<Upgradable>,
                upgradable: Upgradable) => DominionV1.Bill<Upgrade<Upgradable>>,
            public readonly upgrade: UpgradeFn<Upgradable>,
            public readonly description: Array<Types.IDescriptionComponent<any>> = [],
            public readonly config: UpgradeConfig<Upgradable> = {
                maxUses: 1
            }
        ) {}
    }

    export type CardUpgradableTrait = {
        upgrades: Array<Upgrade<ICard>>
    }
}

export namespace Utils {

    import Predicate = Types.Predicate;
    import UnaryOperator = Types.UnaryOperator;
    import Producer = Types.Producer;

    export const concatPredicates = (...predicates: Predicate[]) => {

        return (() => {
            for (const predicate of predicates) {
                if (!predicate()) return false;
            }
            return true;
        }) as Predicate
    }

    export const concatFunctions = <Fn extends (...args: any[]) => any>(...fns: Fn[]) => {
        return function (...args: any[]) {
            const rt: ReturnType<Fn>[] = [];
            for (const fn of fns) rt.push(fn(...args));
            return rt;
        } as unknown as (...args: Parameters<Fn>) => ReturnType<Fn>[]
    }

    export const concatAsyncFunctions = <Fn extends (...args: any[]) => any>(...fns: Fn[]) => {
        return async function (...args: any[]) {
            for (const fn of fns) await fn(...args);
        } as unknown as (...args: Parameters<Fn>) => Promise<void>
    }

    export const chainFunctions = <Operand>(...operators: UnaryOperator<Operand>[]) => {
        return ((operand: Operand) => {
            let op = operand;
            operators.forEach(operator => op = operator(op));
            return op;
        }) as UnaryOperator<Operand>
    }

    export const repeat = <Object>(n: number, objFactory: Producer<Object>) => {
        const arr = [];
        for (let i = 0; i < n; i++) {
            arr.push(objFactory())
        }
        return arr;
    }
}

export namespace Defaults {

    import concatPredicates = Utils.concatPredicates;
    import Action = Types.Action;
    import Predicate = Types.Predicate;
    import NullaryFn = Types.NullaryFn;
    import CardType = Types.CardType;
    import Amount = Types.Amount;
    import CurrencyTypeInfo = Types.CurrencyTypeInfo;
    import CurrencyType = Types.CurrencyType;
    import Card = Types.ICard;
    import repeat = Utils.repeat;
    import UnaryOperator = Types.UnaryOperator;

    export const truePredicate: Predicate = () => true;

    export const falsePredicate: Predicate = () => false;

    export const noop: NullaryFn = () => {};

    export const asyncNoop = async () => {};

    export const identityOperator: UnaryOperator<any> = x => x

    export const noopAction = (name: string = v4()) => ({
        name: name,
        canFire: truePredicate,
        fn: asyncNoop,
        prepareFn: asyncNoop
    }) as Action

    export enum CardName {
        VILLAGE = "village",
        COPPER = "copper",
        SILVER = "silver",
        GOLD = "gold",
        MARKET = "market",
        FAIR = "fair",
        FORGE = "forge",

        ESTATE = "estate",

        WORKSHOP = "workshop",
        MODIFICATION = "modification",
        COUNCIL_MEETING = "council meeting"
    }

    export namespace Actions {

        import ActionFn = Types.ActionFn;

        export const modifyActionCounter = (offset: number) => ((async (game, source) => {
            source.actionCounter.value += offset;
        }) as ActionFn);

        export const useActionCounter = modifyActionCounter(-1);

        export const draw = (amount: number = 1) => ((async (game, source) => {
            source.draw(amount);
        }) as ActionFn);

        export const modifyBuyCounter = (offset: number) => ((async (game, source) => {
            source.buyCounter.value += offset;
        }) as ActionFn);

        export const modifyMoneyCounter = (offset: number) => ((async (game, source) => {
            source.moneyCounter.value += offset;
        }) as ActionFn);
    }

    export namespace Cards {

        import Card = Types.Card;
        import concatAsyncFunctions = Utils.concatAsyncFunctions;
        import StringCardDescriptionComponent = Types.StringDescriptionComponent;
        import ActionFn = Types.ActionFn;
        import CardUpgradableTrait = Types.CardUpgradableTrait;
        import Upgrade = Types.Upgrade;
        import NumericField = Types.NumericField;

        export class Village extends Card {

            static readonly names: Array<string> = [
                "Fjordland", "Cape Storm", "Last Haven", "Spitzberg", "Wolf Creek",
                "Bad Hanover", "Yellow Spring", "Unterstadt", "Obersbach", "Schüttdorf",
                "Georgeville", "Springdale", "Wichita Falls", "Glazier", "Grafenburg",
                "Reichenau", "Sturzbach", "Stockheim", "Trondheim", "Goldfurt"
            ];

            name = CardName.VILLAGE
            mainType = CardType.ACTION
            types = new Set([CardType.ACTION, CardType.PURCHASABLE, CardType.UPGRADABLE])
            _value = 3

            private cardGainAmount = 1;
            private actionGainAmount = 2;
            private villageName: string | undefined;
            private namePrefix: string = CardName.VILLAGE;

            get displayName(): string {
                let displayName = `${this.namePrefix}`;
                if (this.villageName !== undefined) displayName += ` - ${this.villageName}`;
                return displayName;
            }

            initCustomizations() {
                super.initCustomizations();
                this.villageName = Village.names[randomInt(0, Village.names.length)];
            }

            traits = {
                [CardType.ACTION]: {
                    canFire: concatPredicates(truePredicate),
                    prepareFn: concatAsyncFunctions(
                        Actions.useActionCounter
                    ),
                    fn: concatAsyncFunctions(
                        Actions.modifyActionCounter(this.actionGainAmount),
                        Actions.draw(this.cardGainAmount)
                    )
                },
                [CardType.PURCHASABLE]: {
                    calcBuyerPrice: () => ({
                        genericPrice: 3,
                        buyerPrice: 3
                    }) as Types.PriceInfo
                },
                [CardType.UPGRADABLE]: {
                    upgrades: [
                        // Village -> Large village
                        new Upgrade<Types.ICard>(
                            "Large village",
                            (upgrade) => ({
                                moneyRequired: 3,
                                type: "upgrade",
                                isSatisfied: DominionV1.defaultBillSatisfactionPredicate,
                                for: upgrade
                            }),
                            upgradable => {
                                this.namePrefix = "Large village";
                                const actionTrait = upgradable.traits[CardType.ACTION]!;
                                upgradable.traits[CardType.ACTION] = {
                                    ...actionTrait,
                                    fn: concatAsyncFunctions(
                                        Actions.modifyActionCounter(++this.actionGainAmount),
                                        Actions.draw(++this.cardGainAmount)
                                    )
                                }
                                upgradable.value += 1;

                                upgradable.setDescription([
                                    new StringCardDescriptionComponent(`+${this.cardGainAmount} card`),
                                    new StringCardDescriptionComponent(`+${this.actionGainAmount} actions`)
                                ]);
                            },
                            [
                                new StringCardDescriptionComponent("+1 action (gained)"),
                                new StringCardDescriptionComponent("+1 card (gained)"),
                            ], {
                                maxUses: 2
                            }
                        )
                    ]
                } as CardUpgradableTrait
            }
            description = [
                new StringCardDescriptionComponent(`+${this.cardGainAmount} card`),
                new StringCardDescriptionComponent(`+${this.actionGainAmount} actions`)
            ]

            protected init() {
                super.init();
                // this.extensionManager.offers.push({
                //     id: v4(),
                //     descriptor: {
                //         displayName: "Taxes on public amenities",
                //         description: [
                //             new StringCardDescriptionComponent("Add taxes to public amenities, like parks.")
                //         ]
                //     },
                //     createBill(buyer: DominionV1.Player): DominionV1.Bill<Types.IExtensionOffer> {
                //         return {
                //             type: "extension",
                //             moneyRequired: 1,
                //             isSatisfied: DominionV1.defaultBillSatisfactionPredicate,
                //             for: undefined
                //         };
                //     },
                //     generateExtension(card: Types.Card<any>): Types.IExtension {
                //         return new class extends Types.AbstractExtension {
                //             id = v4()
                //             descriptor = {
                //                 displayName: "Taxes",
                //                 description: [
                //                     new StringCardDescriptionComponent("+1 money (every turn)")
                //                 ]
                //             }
                //             onTurnStart(ev: Types.IExtensionEvent<{
                //                 readonly player: DominionV1.Player;
                //                 readonly turn: DominionV1.Turn
                //             }>) {
                //                 let taxes = 0;
                //                 card.extensionManager.extensions.forEach(ext => {
                //                     ext.fieldManager.withField("tax", field => {
                //                         taxes += field.value
                //                     });
                //                 });
                //                 ev.payload.player.addMoney(taxes);
                //             }
                //         }(card);
                //     }
                // })

                // this.extensionManager.offers.push({
                //     id: "park",
                //     descriptor: {
                //         displayName: "Public park",
                //         description: [
                //             new StringCardDescriptionComponent("Increase taxes by +1 money")
                //         ]
                //     },
                //     createBill(buyer: DominionV1.Player): DominionV1.Bill<Types.IExtensionOffer> {
                //         return {
                //             type: "extension",
                //             moneyRequired: 2,
                //             isSatisfied: DominionV1.defaultBillSatisfactionPredicate,
                //             for: undefined
                //         };
                //     },
                //     generateExtension(card: Types.Card<any>): Types.IExtension {
                //         return new class extends Types.AbstractExtension {
                //             id = "park"
                //             descriptor = {
                //                 displayName: "Public park",
                //                 description: [
                //                     new StringCardDescriptionComponent("Increase taxes by +1 money")
                //                 ]
                //             }
                //             onExtensionRegistration(card: Types.ICard) {
                //                 const taxAmount = new NumericField("tax", "Tax");
                //                 taxAmount.value = 1;
                //                 this.fieldManager.addField(taxAmount);
                //             }
                //         }(card);
                //     }
                // })
            }
        }

        export class CouncilMeeting extends Card {
            name = CardName.COUNCIL_MEETING
            types = new Set([CardType.ACTION, CardType.PURCHASABLE])
            _value = 5
            traits = {
                [CardType.ACTION]: {
                    canFire: concatPredicates(truePredicate),
                    prepareFn: concatAsyncFunctions(
                        Actions.useActionCounter
                    ),
                    fn: concatAsyncFunctions(
                        Actions.draw(4),
                        Actions.modifyBuyCounter(1),
                    )
                },
                [CardType.PURCHASABLE]: {
                    calcBuyerPrice: () => ({
                        genericPrice: 5,
                        buyerPrice: 5
                    }) as Types.PriceInfo
                }
            }
            description = [
                new StringCardDescriptionComponent("+4 card"),
                new StringCardDescriptionComponent("+1 purchase"),
                new StringCardDescriptionComponent("Every other player draws a card"), // TODO: Implement
            ]
        }

        export class Estate extends Card {
            name = CardName.ESTATE
            mainType = CardType.VICTORY_POINTS
            types = new Set([CardType.VICTORY_POINTS, CardType.PURCHASABLE, CardType.UPGRADABLE])
            _value = 2
            traits = {
                [CardType.VICTORY_POINTS]: {
                    calculateVictoryPoints: () => 1
                },
                [CardType.PURCHASABLE]: {
                    calcBuyerPrice: () => ({
                        genericPrice: 2,
                        buyerPrice: 2
                    }) as Types.PriceInfo
                },
                [CardType.UPGRADABLE]: {
                    upgrades: [
                        // Estate -> Large estate
                        new Upgrade<Types.ICard>(
                            "Large estate",
                            (upgrade) => ({
                                moneyRequired: 2,
                                type: "upgrade",
                                isSatisfied: DominionV1.defaultBillSatisfactionPredicate,
                                for: upgrade
                            }),
                            upgradable => {
                                upgradable.displayName = "Large estate";
                                upgradable.types.add(CardType.ACTION);
                                upgradable.traits[CardType.ACTION] = {
                                    canFire: concatPredicates(truePredicate),
                                    prepareFn: concatAsyncFunctions(
                                        Actions.useActionCounter
                                    ),
                                    fn: concatAsyncFunctions(
                                        Actions.modifyActionCounter(1),
                                        Actions.draw(1),
                                        Actions.modifyMoneyCounter(1)
                                    )
                                };
                                upgradable.value += 1;
                                upgradable.setDescription([
                                    new StringCardDescriptionComponent("+1 victory point"),
                                    new StringCardDescriptionComponent(`+1 actions`),
                                    new StringCardDescriptionComponent(`+1 card`),
                                    new StringCardDescriptionComponent(`+1 money`),
                                ]);
                            },
                            [
                                new StringCardDescriptionComponent("+1 action (gained)"),
                                new StringCardDescriptionComponent("+1 card (gained)"),
                                new StringCardDescriptionComponent("+1 money (gained)"),
                            ], {
                                maxUses: 1
                            }
                        )
                    ]
                } as CardUpgradableTrait
            }
            description = [
                new StringCardDescriptionComponent("+1 victory point"),
            ]

            protected init() {
                super.init();
                // Field
                this.extensionManager.offers.push({
                    id: v4(),
                    descriptor: {
                        displayName: "Field",
                        description: [
                            new StringCardDescriptionComponent("+1 money (every turn)"),
                            new StringCardDescriptionComponent("+1 money (every turn) 25% chance")
                        ]
                    },
                    createBill(buyer: DominionV1.Player): DominionV1.Bill<Types.IExtensionOffer> {
                        return {
                            type: "extension",
                            moneyRequired: 2,
                            isSatisfied: DominionV1.defaultBillSatisfactionPredicate,
                            for: undefined
                        };
                    },
                    generateExtension(card: Types.Card<any>): Types.IExtension {
                        return new class extends Types.AbstractExtension {
                            id = v4()

                            descriptor = {
                                displayName: "Field",
                                description: [
                                    new StringCardDescriptionComponent("+1 money (every turn)"),
                                    new StringCardDescriptionComponent("+1 money (every turn) 25% chance")
                                ]
                            }

                            onTurnStart(ev: Types.IExtensionEvent<{
                                readonly player: DominionV1.Player;
                                readonly turn: DominionV1.Turn
                            }>) {
                                let fieldYield = 1;
                                if (Math.random() >= .75) fieldYield += 1;
                                ev.payload.player.addMoney(fieldYield);
                            }
                        }(card);
                    }
                });

                // Shed
                this.extensionManager.offers.push({
                    id: v4(),
                    descriptor: {
                        displayName: "Treasury",
                        description: [
                            new StringCardDescriptionComponent(
                                "Store up to two unused (extra) money for the next turn you have this card in your hand from the beginning."
                            )
                        ]
                    },
                    createBill(buyer: DominionV1.Player): DominionV1.Bill<Types.IExtensionOffer> {
                        return {
                            type: "extension",
                            moneyRequired: 2,
                            isSatisfied: DominionV1.defaultBillSatisfactionPredicate,
                            for: undefined
                        };
                    },
                    generateExtension(card: Types.Card<any>): Types.IExtension {
                        return new class extends Types.AbstractExtension {
                            id = v4()

                            descriptor = {
                                displayName: "Treasury",
                                description: [
                                    new StringCardDescriptionComponent(
                                        "Store up to two unused (extra) money for the next turn you have this card in your hand from the beginning."
                                    )
                                ]
                            }

                            private storedMoney = 0;

                            private shedCapacity = 2;

                            onExtensionRegistration(card: Types.ICard) {
                                const storedMoneyField = new NumericField("stored-money", "Stored money");
                                storedMoneyField.value = this.storedMoney;
                                this.fieldManager.addField(storedMoneyField);

                                this.actionManager.actions.push({
                                    descriptor: {
                                        displayName: "Deposit"
                                    },
                                    canFire: () => {
                                        if (!card.isOwned()) return false;
                                        return this.fieldManager.getField<number>("stored-money")?.value === 0;
                                    },
                                    fire: async () => {
                                        const moneyToStore = this.shedCapacity - this.storedMoney;
                                        const invoice = await card.owner!.invoice({
                                            moneyRequired: moneyToStore,
                                            isSatisfied: DominionV1.defaultBillSatisfactionPredicate,
                                            type: "other"
                                        });

                                        if (invoice.status !== DominionV1.InvoiceResultStatus.FULFILLED) return;

                                        this.storedMoney += moneyToStore;
                                        this.fieldManager.getField<number>("stored-money").value = this.storedMoney;
                                    }
                                })

                                this.actionManager.actions.push({
                                    descriptor: {
                                        displayName: "Withdraw"
                                    },
                                    canFire: () => {
                                        if (!card.isOwned()) return false;
                                        return this.fieldManager.getField<number>("stored-money")?.value > 0;
                                    },
                                    fire: () => {
                                        const storedMoneyCpy =  this.storedMoney
                                        this.storedMoney = 0;
                                        this.fieldManager.getField<number>("stored-money").value = 0;
                                        card.owner!.moneyCounter.value += storedMoneyCpy;
                                    }
                                })
                            }

                            onTurnStart(ev: Types.IExtensionEvent<{
                                readonly player: DominionV1.Player;
                                readonly turn: DominionV1.Turn
                            }>) {
                                // ev.payload.player.addMoney(this.storedMoney);
                                // this.storedMoney = 0;
                                // this.fieldManager.getField<number>("stored-money").value = 0;
                            }

                            onTurnPreCleanup(ev: Types.IExtensionEvent<{
                                readonly player: DominionV1.Player;
                                readonly turn: DominionV1.Turn
                            }>) {
                                const mC = ev.payload.player.moneyCounter;
                                if (this.storedMoney !== 0) return;
                                this.storedMoney = mC.reduceBy(2, 0);
                                this.fieldManager.getField<number>("stored-money").value = this.storedMoney;
                            }
                        }(card);
                    }
                });
            }
        }

        export class Workshop extends Card {
            name = CardName.WORKSHOP
            types = new Set([CardType.ACTION, CardType.PURCHASABLE])
            _value = 4
            traits = {
                [CardType.ACTION]: {
                    canFire: concatPredicates(truePredicate),
                    prepareFn: concatAsyncFunctions(
                        Actions.useActionCounter
                    ),
                    fn: (async (game, source) => {
                        const selected = await game.data.callbacks.selectCard({
                            title: "Choose a card that costs up to 4",
                            from: game.empireShop.offers
                                .map((o, idx) => ({
                                    card: o.getPreview!,
                                    payload: idx
                                })).filter(o => o.card.traits[CardType.PURCHASABLE]!.calcBuyerPrice(source).buyerPrice <= 4)
                        });

                        await game.empireShop.take(source, selected.payload as number);
                    }) as ActionFn
                },
                [CardType.PURCHASABLE]: {
                    calcBuyerPrice: () => ({
                        genericPrice: 4,
                        buyerPrice: 4
                    }) as Types.PriceInfo
                }
            }
            description = [
                new StringCardDescriptionComponent("Choose a card that costs up to 4"),
            ]
        }

        export class Copper extends Card {
            name = CardName.COPPER
            mainType = CardType.CURRENCY
            types = new Set([CardType.CURRENCY, CardType.PURCHASABLE, CardType.UPGRADABLE])
            _value = 0
            traits = {
                [CardType.CURRENCY]: {
                    value: () => new Amount(CurrencyTypeInfo[CurrencyType.COPPER].value)
                },
                [CardType.PURCHASABLE]: {
                    calcBuyerPrice: () => ({
                        genericPrice: 0,
                        buyerPrice: 0
                    }) as Types.PriceInfo
                },
                [CardType.UPGRADABLE]: {
                    upgrades: [
                        // Copper -> Copper Pile
                        new Upgrade<Types.ICard>(
                            "Copper Pile",
                            (upgrade) => ({
                                moneyRequired: 1,
                                type: "upgrade",
                                isSatisfied: DominionV1.defaultBillSatisfactionPredicate,
                                for: upgrade
                            }),
                            upgradable => {
                                upgradable.displayName = "Copper Pile";
                                const currentCurrencyTrait = upgradable.traits["CURRENCY"];
                                const currentValue = currentCurrencyTrait?.value().asCopper() ?? 0;
                                const upgradeValueDelta = 1;
                                upgradable.traits["CURRENCY"] = {
                                    value: () => new Types.Amount(
                                        currentValue + upgradeValueDelta
                                    )
                                }
                                upgradable.value += upgradeValueDelta;
                            },
                            [
                                new StringCardDescriptionComponent("+1 value"),
                            ], {
                                maxUses: 1
                            }
                        )
                    ]
                } as CardUpgradableTrait
            }
            description = [
                new StringCardDescriptionComponent("Used to pay for cards, actions, buildings, upgrades etc."),
            ]
        }

        export class Silver extends Card {
            name = CardName.SILVER
            mainType = CardType.CURRENCY
            types = new Set([CardType.CURRENCY, CardType.PURCHASABLE, CardType.UPGRADABLE])
            _value = 3
            traits = {
                [CardType.CURRENCY]: {
                    value: () => new Amount(CurrencyTypeInfo[CurrencyType.SILVER].value)
                },
                [CardType.PURCHASABLE]: {
                    calcBuyerPrice: () => ({
                        genericPrice: 3,
                        buyerPrice: 3
                    }) as Types.PriceInfo
                },
                [CardType.UPGRADABLE]: {
                    upgrades: [
                        // Silver -> Silver Pile
                        new Upgrade<Types.ICard>(
                            "Silver Pile",
                            (upgrade) => ({
                                moneyRequired: 3,
                                type: "upgrade",
                                isSatisfied: DominionV1.defaultBillSatisfactionPredicate,
                                for: upgrade
                            }),
                            upgradable => {
                                upgradable.displayName = "Silver Pile";
                                const currentCurrencyTrait = upgradable.traits["CURRENCY"];
                                const currentValue = currentCurrencyTrait?.value().asCopper() ?? 0;
                                const upgradeValueDelta = 1;
                                upgradable.traits["CURRENCY"] = {
                                    value: () => new Types.Amount(
                                        currentValue + upgradeValueDelta
                                    )
                                }
                                upgradable.value += upgradeValueDelta;
                            },
                            [
                                new StringCardDescriptionComponent("+2 value"),
                            ], {
                                maxUses: 1
                            }
                        )
                    ]
                } as CardUpgradableTrait
            }
        }

        export class Gold extends Card {
            name = CardName.GOLD
            mainType = CardType.CURRENCY
            types = new Set([CardType.CURRENCY, CardType.PURCHASABLE])
            _value = 6
            traits = {
                [CardType.CURRENCY]: {
                    value: () => new Amount(CurrencyTypeInfo[CurrencyType.GOLD].value)
                },
                [CardType.PURCHASABLE]: {
                    calcBuyerPrice: () => ({
                        genericPrice: 6,
                        buyerPrice: 6
                    }) as Types.PriceInfo
                }
            }
        }

        export class Fair extends Card {
            name = CardName.FAIR
            types = new Set([CardType.ACTION, CardType.PURCHASABLE])
            _value = 5
            traits = {
                [CardType.ACTION]: {
                    canFire: concatPredicates(truePredicate),
                    prepareFn: concatAsyncFunctions(
                        Actions.useActionCounter
                    ),
                    fn: concatAsyncFunctions(
                        Actions.modifyActionCounter(2),
                        Actions.modifyBuyCounter(1),
                        Actions.modifyMoneyCounter(2),
                    )
                },
                [CardType.PURCHASABLE]: {
                    calcBuyerPrice: () => ({
                        genericPrice: 5,
                        buyerPrice: 5
                    }) as Types.PriceInfo
                }
            }
            description = [
                new StringCardDescriptionComponent("+2 actions"),
                new StringCardDescriptionComponent("+1 purchase"),
                new StringCardDescriptionComponent("+2 money")
            ]
        }

        export class Market extends Card {
            name = CardName.MARKET
            types = new Set([CardType.ACTION, CardType.PURCHASABLE])
            _value = 5
            traits = {
                [CardType.ACTION]: {
                    canFire: concatPredicates(truePredicate),
                    prepareFn: concatAsyncFunctions(
                        Actions.useActionCounter
                    ),
                    fn: concatAsyncFunctions(
                        Actions.draw(1),
                        Actions.modifyActionCounter(1),
                        Actions.modifyBuyCounter(1),
                        Actions.modifyMoneyCounter(1),
                    )
                },
                [CardType.PURCHASABLE]: {
                    calcBuyerPrice: () => ({
                        genericPrice: 5,
                        buyerPrice: 5
                    }) as Types.PriceInfo
                }
            }
            description = [
                new StringCardDescriptionComponent("+1 card"),
                new StringCardDescriptionComponent("+1 actions"),
                new StringCardDescriptionComponent("+1 purchase"),
                new StringCardDescriptionComponent("+1 money")
            ]
        }

        export class Forge extends Card {
            name = CardName.FORGE
            types = new Set([CardType.ACTION, CardType.PURCHASABLE])
            _value = 4
            traits = {
                [CardType.ACTION]: {
                    canFire: concatPredicates(truePredicate),
                    prepareFn: concatAsyncFunctions(
                        Actions.useActionCounter
                    ),
                    fn: concatAsyncFunctions(
                        Actions.draw(3),
                    )
                },
                [CardType.PURCHASABLE]: {
                    calcBuyerPrice: () => ({
                        genericPrice: 4,
                        buyerPrice: 4
                    }) as Types.PriceInfo
                }
            }
            description = [
                new StringCardDescriptionComponent("+3 card")
            ]
        }

        export class Modification extends Card {
            name = CardName.MODIFICATION
            types = new Set([CardType.ACTION, CardType.PURCHASABLE])
            _value = 4
            traits = {
                [CardType.ACTION]: {
                    canFire: concatPredicates(truePredicate),
                    prepareFn: concatAsyncFunctions(
                        Actions.useActionCounter
                    ),
                    fn: (async (game, source) => {

                        const cardToDisposeOf = (await game.data.callbacks.selectCard({
                            title: "Choose a card to dispose of",
                            from: source.hand.cards
                                .map((c, idx) => ({
                                    card: c,
                                    payload: idx
                                }))
                        })).card;

                        // const worth = cardToDisposeOf.traits[CardType.PURCHASABLE]!.calcBuyerPrice(source).genericPrice;
                        const worth = cardToDisposeOf.value;

                        const selected = await game.data.callbacks.selectCard({
                            title: "Choose a card that costs up to 4",
                            from: game.empireShop.offers
                                .map((o, idx) => ({
                                    card: o.getPreview!,
                                    payload: idx
                                })).filter(o => o.card.traits[CardType.PURCHASABLE]!.calcBuyerPrice(source).buyerPrice <= worth + 2)
                        });

                        await game.empireShop.take(source, selected.payload as number);

                        const idx = source.deck.cards.indexOf(cardToDisposeOf);
                        source.hand.getAtIndex(idx);

                        game.trash.append([cardToDisposeOf]);

                        // TODO: put disposed card into the trash
                    }) as ActionFn
                },
                [CardType.PURCHASABLE]: {
                    calcBuyerPrice: () => ({
                        genericPrice: 4,
                        buyerPrice: 4
                    }) as Types.PriceInfo
                }
            }
            description = [
                new StringCardDescriptionComponent(
                    "Dispose of a card from your hand. Take a card that costs up two 2 more than the disposed card."
                )
            ]
        }
    }

    export const defaultDeck: Card[] = [
        ...repeat(7, () => new Cards.Copper()),
        ...repeat(3, () => new Cards.Estate()),
        ...repeat(2, () => new Cards.Village()),
    ]
}

export namespace DominionV1 {

    import Card = Types.ICard;
    import CardType = Types.CardType;
    import UnaryFunction = Types.UnaryFunction;
    import also = Std.also;
    import Transaction = Types.Transaction;
    import noop = Defaults.noop;
    import NullaryFn = Types.NullaryFn;
    import truePredicate = Defaults.truePredicate;
    import ICard = Types.ICard;
    import Cards = Defaults.Cards;
    import Producer = Types.Producer;

    export type RelayEventTypeListing = string | number

    export type RelayEvents<RelayEventTypes extends RelayEventTypeListing> = Record<RelayEventTypes | RelayEventTypeListing, RelayFn>

    export type RelayEventConfig = {
        this: any
    }

    export class RelayEvent {

        public readonly id: string;

        public args: any[]

        public config: RelayEventConfig = {
            this: undefined
        }

        constructor(...args: any[]) {
            this.args = args;
            this.id = v4();
        }

        public configure(config: Partial<RelayEventConfig> = {}): this {
            this.config = {
                ...this.config,
                ...config
            }
            return this;
        }
    }

    export type RelayFireFn<RelayEventTypes extends RelayEventTypeListing> = (
        event: RelayEventTypes, e: RelayEvent
    ) => void

    export type RelayFn = (e: RelayEvent, ...args: any[]) => any | void

    export class Relay<This, RelayEventTypes extends RelayEventTypeListing> {

        private relayName?: string;

        private linkedRelays: Array<Relay<any, any>> = [];

        private onFireListeners: Array<RelayFireFn<RelayEventTypes>> = [];

        constructor(
            public readonly events: Partial<RelayEvents<RelayEventTypes>>
        ) {}

        public onFire(listener: RelayFireFn<RelayEventTypes>): this {
            this.onFireListeners.push(listener);
            return this;
        }

        public setName(relayName: string): this {
            this.relayName = relayName;
            return this;
        }

        public fire(event: RelayEventTypes, e: RelayEvent = new RelayEvent) {

            console.group(
                `%c[ ${_.pad(this.relayName?.substring(0, 9) ?? "*", 9)} ]-relay`,
                `color: ${"#efef8d"}`,
                event, e,
            )

            if (this.relayName !== undefined || true) {
                // console.warn(`[ ${_.pad(this.relayName?.substring(0, 9) ?? "*", 9)} ]-relay`, event, e);
            }


            // Fire named listeners on this relay
            this.fireRelayFn(this.events[event], e);
            // Fire linked listeners
            this.linkedRelays.forEach(r => {
                r.fire(event, e);
            })
            // Fire general listeners on this relay
            this.onFireListeners.forEach(l => l(event, e));

            console.groupEnd()
        }

        private fireRelayFn(fn: RelayFn | undefined, e: RelayEvent) {
            if (fn === undefined) return;
            fn.call(e.config.this ?? this, e, ...e.args);
        }

        public link(relay: Relay<any, any>) {
            this.linkedRelays.push(relay);
        }

        public unlink(relay: Relay<any, any>) {
            this.linkedRelays = this.linkedRelays.filter(r => r !== relay);
        }
    }

    export class GameComponent<This, Game, Properties> implements IDeflatable {

        private _rootComponent?: GameComponent<Game, Game, any>

        public children: Array<GameComponent<any, Game, any>> = []

        public readonly relay: Relay<any, any> = new DominionV1.Relay<any, any>({});

        constructor(
            public readonly name: string,
            public data: Properties
        ) {
            this.onInit();
        }

        public typed<Type = This>(): Type {
            return this as unknown as Type;
        }

        public registerComponent(component: GameComponent<any, Game, any>) {
            component.embed(this);
            this.children.push(component);
        }

        public unregisterComponent(component: GameComponent<any, Game, any>) {
            // TODO: remove _rootComponent ref
            this.children = this.children.filter(c => c !== component);
        }

        public getComponent<Type = any, CProperties = any>(name: string): GameComponent<Type, Game, CProperties> {
            return this.children.find(c => c.name === name) as GameComponent<Type, Game, CProperties>;
        }

        public embed(parent: GameComponent<any, Game, any>) {
            this._rootComponent = parent._rootComponent;
            this.relay.setName(this.name);
            if (this._rootComponent !== undefined) {
                this.relay.link(this._rootComponent?.relay)

            }
            this.onEmbed();
        }

        protected onInit() {}

        protected onEmbed() {}

        public get game(): Game {
            if (this._rootComponent === undefined) throw new Error();
            return this._rootComponent.typed();
        }

        protected markAsRootComponent() {
            this._rootComponent = this;
        }

        deflate(ctx: DeflationContext) {}

        inflate(data: SaveDataLayoutComponent) {}
    }

    export type CardCollectionData = {
        readonly cards: Array<ICard>
        readonly name?: string,
    }

    export class CardCollection extends GameComponent<CardCollection, Game, CardCollectionData> {

        public static newEmptyCollection(componentName: string = v4()): CardCollection {
            return new CardCollection(componentName, {
                cards: []
            });
        }

        private transaction: Transaction = new Transaction({
            finalizer: noop
        });

        private contentChangedFinalizer: NullaryFn = () => {
            this.onCardContentChange();
        }

        public get cards(): Array<ICard> {
            return this.data.cards;
        }

        public draw(n: number = 1, subCollectionName: string = v4()): CardCollection {
            return this.transaction.run(CardCollection.Events.CONTENT_CHANGED, () => {
                return new CardCollection(subCollectionName, {
                    cards: this.cards.splice(-n, n)
                });
            }, this.contentChangedFinalizer);
        }

        public transfer(n: number | "all", to: CardCollection): this {
            return this.transaction.run(CardCollection.Events.CONTENT_CHANGED, () => {
                const amount = typeof n === "string" ? this.cards.length : n;
                to.append(this.draw(amount).cards);
                return this;
            }, this.contentChangedFinalizer);
        }

        public append(cards: Array<ICard>): this {
            return this.transaction.run(CardCollection.Events.CONTENT_CHANGED, () => {
                this.cards.unshift(...cards);
                return this;
            }, this.contentChangedFinalizer);
        }

        public clear(): this {
            return this.transaction.run(CardCollection.Events.CONTENT_CHANGED, () => {
                this.cards.length = 0;
                return this;
            }, this.contentChangedFinalizer);
        }

        public replace(cards: Array<Card>): this {
            return this.transaction.run(CardCollection.Events.CONTENT_CHANGED, () => {
                this.clear();
                this.append(cards);
                return this;
            }, this.contentChangedFinalizer);
        }

        public shuffle(): this {
            return this.transaction.run(CardCollection.Events.CONTENT_CHANGED, () => {
                this.replace(_.shuffle(this.cards));
                return this;
            }, this.contentChangedFinalizer);
        }

        public ifLessCardsThan(amount: number, block: (this: this, collection: this) => void) {
            if (this.cards.length < amount) block.call(this, this);
        }

        public getAtIndex(index: number): Card {
            return this.transaction.run(CardCollection.Events.CONTENT_CHANGED, () => {
                return this.cards.splice(index, 1)[0];
            }, this.contentChangedFinalizer);
        }

        public predicateOverCardAt(index: number, predicate: UnaryFunction<Card, boolean>): boolean {
            return predicate(this.cards[index]);
        }

        protected onCardContentChange() {
            this.relay.fire(CardCollection.Events.CONTENT_CHANGED, new RelayEvent({
                name: this.data.name,
                cards: this.cards
            }));
        }
    }

    export namespace CardCollection {
        export enum Events {
            CONTENT_CHANGED = "CONTENT_CHANGED"
        }
    }

    export class Deck extends CardCollection {

        private _owner: Player | undefined;

        public set owner(owner: Player) {
            this._owner = owner;
        }

        public get owner(): Player {
            return this._owner!;
        }

        public prepareForGame() {
            this.cards.forEach(card => {
                card.setOwner(this.owner);
                card.initCustomizations();
            });
            this.shuffle();
        }

        append(cards: Array<Types.ICard>): this {
            cards.forEach(card => {
                card.setOwner(this.owner);
            })
            return super.append(cards);
        }
    }

    export class GameComponentContainer<T extends GameComponent<any, Game, any>> extends GameComponent<Array<T>, Game, Array<T>> {

        typed<Type = Array<T>>(): Type {
            return this.data as Type;
        }

        public push(...items: T[]) {
            items.forEach(item => {
                this.registerComponent(item);
            })
            this.data.push(...items);
        }

        public remove(item: T) {
            this.data = this.data.filter(elem => elem !== item);
            this.unregisterComponent(item);
        }
    }

    export type SelectCardCallbackRequestCardInfo = {
        readonly card: ICard,
        readonly payload?: any
    }

    export type SelectCardCallbackRequest = {
        readonly from: Array<SelectCardCallbackRequestCardInfo>,
        readonly title?: string
    }

    export interface SatisfyBillRequestSignals {
        cancel(): void
    }

    export type SatisfyBillRequest = {
        readonly bill: Bill<any>,
        readonly signals: SatisfyBillRequestSignals
    }

    export interface GameCallbacks {
        satisfyBill(request: SatisfyBillRequest): Promise<PaymentInfo>

        selectCard(request: SelectCardCallbackRequest): Promise<SelectCardCallbackRequestCardInfo>
    }

    export type GameProperties = {
        players: Array<Player>,
        startHandSize: number,
        callbacks: GameCallbacks
    }

    export class Game extends GameComponent<Game, Game, GameProperties> {

        public readonly empireShop = new EmpireShop("empire-shop", {
            offers: [
                ...[Cards.Copper, Cards.Silver, Cards.Gold].map(CardConstructor => ({
                    available: 50,
                    canBuy: truePredicate,
                    getPreview: new CardConstructor(),
                    get: () => new CardConstructor()
                })),

                ...[Cards.Village, Cards.Market, Cards.Fair, Cards.Forge, Cards.Workshop, Cards.Modification, Cards.CouncilMeeting].map(CardConstructor => ({
                    available: 15,
                    canBuy: truePredicate,
                    getPreview: new CardConstructor(),
                    get: () => new CardConstructor()
                })),

                ...[Cards.Estate].map(CardConstructor => ({
                    available: 15,
                    canBuy: truePredicate,
                    getPreview: new CardConstructor(),
                    get: () => new CardConstructor()
                }))
            ]
        });

        public players = new GameComponentContainer<Player>("players", [])

        public activePlayer: Player | undefined;

        public trash: CardCollection = CardCollection.newEmptyCollection("trash")

        protected onInit() {
            super.onInit();
            this.markAsRootComponent();
        }

        public prepare(): this {
            this.registerComponent(this.empireShop);
            this.registerComponent(this.players);
            this.registerComponent(this.trash);

            this.data.players.forEach(player => {
                this.addPlayer(player);
            });

            this.relay.fire(Game.Events.GAME_PREPARED);
            return this;
        }

        public start(): this {
            // Select starting player
            this.chooseInitialPlayer();
            this.relay.fire(Game.Events.GAME_STARTED);
            return this;
        }

        private onPlayerTurnFinished() {
            // TODO: Check if game is finished
            this.chooseNextPlayer();
        }

        public chooseNextPlayer(): this {
            if (this.activePlayer === undefined) return this.chooseInitialPlayer();
            const players = this.players.typed();
            const currentPlayerIndex = players.indexOf(this.activePlayer);
            const nextPlayerIndex = currentPlayerIndex + 1 % players.length;
            return this.setActivePlayer(players[nextPlayerIndex]);
        }

        public chooseInitialPlayer(): this {
            const players = this.players.typed();
            if (players.length === 0) throw new Error();
            const playerIndex = randomInt(0, players.length);
            return this.setActivePlayer(players[playerIndex]);
        }

        private setActivePlayer(player: Player): this {
            this.activePlayer = player;
            player.beginTurn();
            return this;
        }

        public addPlayer(player: Player) {
            player.relay.link(new DominionV1.Relay<any, Player.Events>({
                // [Player.Events.TURN_FINISHED]: () => {
                //     this.onPlayerTurnFinished();
                // },
                [Player.Events.POST_PLAYER_TURN_END]: () => {
                    this.onPlayerTurnFinished();
                }
            }));
            this.players.push(player);
        }
    }

    export namespace Game {
        export enum Events {
            GAME_PREPARED = "GAME_PREPARED", GAME_STARTED = "GAME_STARTED"
        }
    }

    export type PlayerData = {
        deck: Deck
    }

    export type PaymentInfo = {
        moneyProvided: number,
        selectedPaymentCards: Array<ICard>
    }

    export type BillSatisfactionPredicate<GoodsType> = (bill: Bill<GoodsType>, payment: PaymentInfo) => boolean

    export const defaultBillSatisfactionPredicate: BillSatisfactionPredicate<any> = (bill, payment) => {
        if (payment.moneyProvided < bill.moneyRequired) return false;
        return true;
    }

    export type Bill<GoodsType = void> = {
        type: "card" | "upgrade" | "extension" | string,
        for?: GoodsType,
        isSatisfied: BillSatisfactionPredicate<GoodsType>
        moneyRequired: number,
    }

    export enum InvoiceResultStatus {
        FULFILLED = "FULFILLED", CANCELED = "CANCELED"
    }

    export type InvoiceResult = {
        status: InvoiceResultStatus
    }

    export class Player extends GameComponent<Player, Game, PlayerData> {

        public readonly hand: CardCollection = new DominionV1.CardCollection("hand", {
            name: "hand",
            cards: []
        })

        public readonly shelf: CardCollection = new DominionV1.CardCollection("shelf", {
            name: "shelf",
            cards: []
        })

        public readonly actionCounter = new NumericVariable("action-counter", {
            initialValue: 1
        });

        public readonly buyCounter = new NumericVariable("buy-counter", {
            initialValue: 1
        });

        public readonly moneyCounter = new NumericVariable("money-counter", {
            initialValue: 0
        });

        private _turnCount = 0;

        private _turn: Turn | undefined;

        public prepareForGame() {
            this.registerComponent(this.deck);
            this.registerComponent(this.hand);
            this.registerComponent(this.shelf);
            this.registerComponent(this.actionCounter);
            this.registerComponent(this.buyCounter);
            this.registerComponent(this.moneyCounter);
            const handSize = this.game.data.startHandSize;
            this.deck.owner = this;
            this.deck.prepareForGame();
            this.deck.transfer(handSize, this.hand);
            this.actionCounter.value = this.buyCounter.value = 1;
            this.moneyCounter.value = 0;
        }

        public draw(n: number = 1): this {
            this.deck.transfer(n, this.hand);
            return this;
        }

        public get turnCount(): number {
            return this._turnCount;
        }

        public get deck(): Deck {
            return this.data.deck;
        }

        protected onEmbed() {
            super.onEmbed();
            this.prepareForGame();
        }

        public async useActionCardFromHand(cardIdx: number): Promise<boolean> {
            return await this.turn!.phase.asActionTurnPhase().useActionCardFromHand(cardIdx) ?? false;
        }

        public addMoney(amount: number): this {
            this.moneyCounter.value += amount;
            return this;
        }

        public beginTurn(): this {
            if (this._turn !== undefined) throw new Error();
            this.relay.fire(DominionV1.Player.Events.PRE_TURN_START);
            const turn = this.createNewTurnInstance()
            this._turn = turn;
            this.registerComponent(turn);
            this._turnCount++;

            this.hand.cards.map(card => {
                card.extensionManager.extensions.forEach(extension => {
                    extension.onTurnStart?.({
                        card: card,
                        payload: {
                            player: this,
                            turn: this.turn!
                        }
                    });
                });
            });

            turn.start();
            this.relay.fire(DominionV1.Player.Events.POST_TURN_START);
            return this;
        }

        public endTurn(): this {
            if (this._turn === undefined) throw new Error();
            this.relay.fire(DominionV1.Player.Events.PRE_PLAYER_TURN_END);

            this.hand.cards.map(card => {
                card.extensionManager.extensions.forEach(extension => {
                    extension.onTurnEnd?.(this, this._turn!);
                });
            });

            this.unregisterComponent(this._turn);
            this._turn = undefined;
            this.relay.fire(DominionV1.Player.Events.POST_PLAYER_TURN_END);
            return this;
        }

        private createNewTurnInstance(): Turn {
            const turn = new Turn("turn", {
                phaseOrder: this.createNewTurnPhaseOrder()
            });

            turn.relay.link(new DominionV1.Relay<DominionV1.Turn, DominionV1.Turn.Events>({
                [DominionV1.Turn.Events.TURN_FINISHED]: () => this.endTurn()
            }));

            return turn;
        }

        /**
         * TODO: DEFINITELY MAKE SMARTER..
         *
         * @private
         */
        private createNewTurnPhaseOrder(): ((turn: Turn) => TurnPhase)[] {
            const relay = new Relay<TurnPhase, TurnPhase.Events>({ /* TODO: Implement handlers */ });
            return [
                turn => also(new ActionTurnPhase("phase", {
                    type: TurnPhase.Types.ACTION_PHASE,
                    turn: turn
                }), phase => {
                    phase.relay.link(relay);
                }),
                turn => also(new BuyTurnPhase("phase", {
                    type: TurnPhase.Types.BUY_PHASE,
                    turn: turn
                }), phase => {
                    phase.relay.link(relay);
                }),
                turn => also(new CleanupTurnPhase("phase", {
                    type: TurnPhase.Types.CLEANUP_PHASE,
                    turn: turn
                }), phase => {
                    phase.relay.link(relay);
                })
            ];
        }

        public async invoice<GoodsType>(bill: Bill<GoodsType>): Promise<InvoiceResult> {
            let payment: PaymentInfo;
            let cancelInvoice = false;

            // try {
            //     payment = await this.game.data.callbacks.satisfyBill({
            //         bill: bill,
            //         signals: {
            //             cancel() {
            //                 cancelInvoice = true;
            //             }
            //         }
            //     });
            // } catch (e) {
            //     return {
            //         status: InvoiceResultStatus.CANCELED
            //     };
            // }

            payment = await this.game.data.callbacks.satisfyBill({
                bill: bill,
                signals: {
                    cancel() {
                        cancelInvoice = true;
                    }
                }
            });

            if (cancelInvoice) {
                return {
                    status: InvoiceResultStatus.CANCELED
                };
            }

            if (bill.moneyRequired > this.moneyCounter.value) {
                this.moneyCounter.value = 0;
            } else {
                this.moneyCounter.value -= bill.moneyRequired;
            }

            payment.selectedPaymentCards.forEach(usedCard => {
                const handIdx = this.hand.cards.indexOf(usedCard);
                this.shelf.append([
                    this.hand.getAtIndex(handIdx)
                ]);
            });

            const overSpentAmount = payment.moneyProvided - bill.moneyRequired;
            this.moneyCounter.value += overSpentAmount;

            return {
                status: InvoiceResultStatus.FULFILLED
            };
        }

        get turn(): DominionV1.Turn | undefined {
            return this._turn;
        }

        public hasActionCounterLeft(): boolean {
            return this.actionCounter.value > 0;
        }

        inflate(data: SaveDataLayoutComponent) {
            super.inflate(data);
            this._turnCount = data.data.turns ?? 0;
            this.actionCounter.value = data.data.actionCounter ?? 0;
            this.buyCounter.value = data.data.buyCounter ?? 0;
            this.moneyCounter.value = data.data.moneyCounter ?? 0;
        }

        deflate(ctx: DeflationContext) {
            super.deflate(ctx);
            ctx.write("turns", this.turnCount);
            ctx.write("actionCounter", this.actionCounter.value);
            ctx.write("buyCounter", this.buyCounter.value);
            ctx.write("moneyCounter", this.moneyCounter.value);
        }
    }

    export namespace Player {
        export enum Events {
            PRE_TURN_START = "PRE_TURN_START",
            POST_TURN_START = "POST_TURN_START",


            PRE_PLAYER_TURN_END = "PRE_PLAYER_TURN_END",
            POST_PLAYER_TURN_END = "POST_PLAYER_TURN_END",


            TURN_FINISHED = "TURN_FINISHED"
        }
    }

    export type TurnData = {
        phaseOrder: Array<(turn: Turn) => TurnPhase>
    }

    export class Turn extends GameComponent<Turn, Game, TurnData> {

        private _phase?: TurnPhase;

        private _phaseIndex: number = -1;

        // constructor(
        //     public readonly player: Player,
        //     private readonly phaseOrder: Array<(turn: Turn) => TurnPhase>,
        //     public readonly relay: Relay<Turn, Turn.Events>
        // ) {}

        public start() {
            this.relay.fire(Turn.Events.TURN_STARTED);
            this.nextPhase();
        }

        public nextPhase(): this {
            console.debug("calling nextPhase")

            // if (this._phase !== undefined) this._phase.endPhase();

            return this.startNextPhase();
            // return this;
        }

        protected startNextPhase(): this {
            console.debug("calling startNextPhase")

            const nextPhaseIndex = this._phaseIndex + 1;
            if (nextPhaseIndex >= this.data.phaseOrder.length) return this.onTurnFinished();
            this._phase = this.data.phaseOrder[nextPhaseIndex](this);
            this._phaseIndex++;
            this.phase.relay.link(new DominionV1.Relay<any, TurnPhase.Events>({
                [TurnPhase.Events.PHASE_END]: () => {
                    this.startNextPhase();
                }
            }));
            this.registerComponent(this.phase)
            this._phase.startPhase();
            return this;
        }

        private onTurnFinished(): this {
            this.relay.fire(Turn.Events.TURN_FINISHED)
            this.unregisterComponent(this.phase);
            this._phase = undefined;
            // TODO: Unset _phase property
            return this;
        }

        get phase(): TurnPhase {
            return this._phase!;
        }
    }

    export namespace Turn {

        export enum Events {
            TURN_STARTED = "TURN_STARTED",
            TURN_FINISHED = "TURN_FINISHED"
        }
    }

    export type TurnPhaseData = {
        readonly turn: Turn,
        readonly type: TurnPhase.Types | string,
    }

    export class TurnPhase extends GameComponent<TurnPhase, Game, TurnPhaseData> {

        startPhase(): this {
            this.relay.fire(TurnPhase.Events.PHASE_START, new DominionV1.RelayEvent(this.data.type));
            return this;
        }

        endPhase(): this {
            this.relay.fire(TurnPhase.Events.PHASE_END, new DominionV1.RelayEvent(this.data.type));
            return this;
        }

        public asActionTurnPhase(): ActionTurnPhase {
            if (this.data.type !== DominionV1.TurnPhase.Types.ACTION_PHASE) throw new Error()
            return this.as<ActionTurnPhase>();
        }

        public asBuyTurnPhase(): BuyTurnPhase {
            if (this.data.type !== DominionV1.TurnPhase.Types.BUY_PHASE) throw new Error()
            return this.as<BuyTurnPhase>();
        }

        public as<PhaseType extends TurnPhase>(): PhaseType {
            return this as unknown as PhaseType;
        }
    }

    export namespace TurnPhase {
        export enum Events {
            PHASE_START = "PHASE_START",
            PHASE_END = "PHASE_END"
        }

        export enum Types {
            ACTION_PHASE = "ACTION_PHASE",
            BUY_PHASE = "BUY_PHASE",
            CLEANUP_PHASE = "CLEANUP_PHASE"
        }
    }

    export class ActionTurnPhase extends TurnPhase {

        public async useActionCardFromHand(cardIdx: number): Promise<boolean> {
            const player = this.game.activePlayer!;
            const hand = player.hand;

            const isValidActionCard = hand.predicateOverCardAt(cardIdx, card => this.isValidActionCard(card));
            if (!isValidActionCard) return false;
            const card = hand.getAtIndex(cardIdx);
            const action = card.traits[CardType.ACTION]!;
            await action.prepareFn(this.game, player);
            await action.fn(this.game, player);
            player.shelf.append([card]);
            this.relay.fire(DominionV1.ActionTurnPhase.ActionEvents.USE_ACTION_CARD);

            if (player.actionCounter.value === 0) {
                this.endPhase();
            }

            return true;
        }

        public isValidActionCard(card: Card) {
            return card.types.has(CardType.ACTION) && card.traits[CardType.ACTION]!.canFire()
        }

        public async consumeActionCounter(onConsume?: Producer<Promise<void>>): Promise<boolean> {
            const player = this.game.activePlayer!;
            const canConsume = player.hasActionCounterLeft();
            if (!canConsume) return false;
            onConsume?.();
            player.actionCounter.value--;
            if (player.actionCounter.value === 0) {
                this.endPhase();
            }
            return true;
        }

        public async performGenericAction(action: Producer<Promise<void>>): Promise<boolean> {
            return await this.consumeActionCounter(action);
        }
    }

    export enum PurchaseResultStatus {
        COMPLETED, ABORT
    }

    export type PurchaseResult = {
        status: PurchaseResultStatus
    }

    export class BuyTurnPhase extends TurnPhase {

        public executeBuyTransaction(buyBlock: () => PurchaseResult) {
            if (!this.hasPlayerPurchaseLeft()) return;
            const result = buyBlock();
            if (result.status === PurchaseResultStatus.COMPLETED) {
                this.usePlayerPurchase();
            }
        }

        public usePlayerPurchase() {
            const player = this.game.activePlayer!;
            player.buyCounter.value--;
            // I'm a cautious men..
            if (player.buyCounter.value < 0) player.buyCounter.value = 0;
            if (player.buyCounter.value === 0) {
                this.endPhase();
            }
        }

        public hasPlayerPurchaseLeft(): boolean {
            return (this.game.activePlayer?.buyCounter.value ?? 0) > 0;
        }
    }

    export class CleanupTurnPhase extends TurnPhase {
        startPhase(): this {
            super.startPhase();
            const game = this.game;
            const player = game.activePlayer;
            if (player === undefined) throw new Error();

            player.hand.cards.forEach(card => {
                card.extensionManager.extensions.forEach(ext => {
                    ext.onTurnPreCleanup?.({
                        card: card,
                        payload: {
                            player: player,
                            turn: player.turn!
                        }
                    });
                });
            });

            // Used card cleanup
            const cleanupCollection = CardCollection.newEmptyCollection();
            player.shelf.transfer("all", cleanupCollection);
            player.hand.transfer("all", cleanupCollection);
            cleanupCollection.shuffle().transfer("all", player.deck);
            player.deck.transfer(game.data.startHandSize, player.hand);

            // Reset player numeric values
            player.actionCounter.value = 1;
            player.buyCounter.value = 1;
            player.moneyCounter.value = 0;

            this.endPhase();

            return this;
        }
    }

    export namespace ActionTurnPhase {
        export enum ActionEvents {
            USE_ACTION_CARD = "USE_ACTION_CARD"
        }
    }

    export type ShopOffer<BuyerType, GoodsType> = {
        available: number,
        readonly get: (buyer: BuyerType, offer: ShopOffer<BuyerType, GoodsType>) => GoodsType,
        readonly canBuy: (buyer: BuyerType, offer: ShopOffer<BuyerType, GoodsType>) => boolean,
        readonly getPreview?: GoodsType,
    }

    export interface IShop<BuyerType, GoodsType> {
        get offers(): Array<ShopOffer<BuyerType, GoodsType>>,
        buy(buyer: BuyerType, offerIdx: number): Promise<GoodsType>
        take(taker: BuyerType, offerIdx: number): Promise<GoodsType>
    }

    export namespace IShop {
        export enum Events {
            SHOP_OFFER_BOUGHT = "SHOP_OFFER_BOUGHT"
        }
    }

    export type EmpireShopData = {
        readonly offers: Array<ShopOffer<Player, Card>>
    }

    export class EmpireShop extends GameComponent<EmpireShop, Game, EmpireShopData> implements IShop<Player, Card> {

        public async buy(buyer: DominionV1.Player, offerIdx: number): Promise<Types.ICard> {
            const offer = this.offers[offerIdx];
            if (offer === undefined) throw new Error("Offer not found");
            if (offer.available <= 0) throw new Error("Offer not available"); // TODO: Better "no buy" return
            if (!offer.canBuy(buyer, offer)) throw new Error("Buyer can't purchase offer"); // TODO: "
            const card = offer.get(buyer, offer);

            try {
                await buyer.invoice({
                    for: card,
                    type: "card",
                    isSatisfied: DominionV1.defaultBillSatisfactionPredicate,
                    moneyRequired: card.traits[CardType.PURCHASABLE]!.calcBuyerPrice(buyer).buyerPrice
                });
            } catch (e) {
                throw e;
            }

            buyer.turn!.phase.asBuyTurnPhase().executeBuyTransaction(() => {
                offer.available--;
                card.initCustomizations();
                buyer.shelf.append([card]);

                this.relay.fire(IShop.Events.SHOP_OFFER_BOUGHT, new RelayEvent(card, buyer));
                return {
                    status: PurchaseResultStatus.COMPLETED
                };
            })

            return card;
        }

        public async take(taker: DominionV1.Player, offerIdx: number): Promise<Types.ICard> {
            const offer = this.offers[offerIdx];
            if (offer === undefined) throw new Error("Offer not found");
            if (offer.available <= 0) throw new Error("Offer not available"); // TODO: Better "no buy" return
            const card = offer.get(taker, offer);
            offer.available--;
            card.initCustomizations();
            taker.shelf.append([card]);
            return card;
        }

        get offers(): Array<DominionV1.ShopOffer<DominionV1.Player, Types.ICard>> {
            return this.data.offers;
        }
    }

    export type NumericVariableData = {
        initialValue?: number
    }

    export class NumericVariable extends DominionV1.GameComponent<NumericVariable, DominionV1.Game, NumericVariableData>{

        private _value: number = 0;

        private _manipulator: Types.UnaryOperator<number> = Defaults.identityOperator

        protected onEmbed() {
            super.onEmbed();
            this._value = this.data.initialValue ?? 0;
        }

        get value(): number {
            return this._manipulator(this._value);
        }

        set value(newValue) {
            this.relay.fire(NumericVariable.Events.ON_VALUE_CHANGE, new DominionV1.RelayEvent(newValue));
            this._value = newValue;
        }

        public updateRawValue(block: Types.UnaryOperator<number>) {
            this.value = block(this._value);
        }

        public reduceBy(reductionAmount: number, min: number = 0): number {
            this._value -= reductionAmount;
            if (this._value < min) {
                const overReduction = min - this._value;
                this._value = min;
                return reductionAmount - overReduction;
            }
            return reductionAmount;
        }
    }

    export namespace NumericVariable {
        export enum Events {
            ON_VALUE_CHANGE = "ON_VALUE_CHANGE"
        }
    }
}
