import React from "react";
import styled from "styled-components";

const StyledDeckButton = styled.div`
  user-select: none;
  // touch-action: none;
  
  width: 100px;
  height: 100px;
  background-color: red;
`;

export const DeckButton: React.FC = props => {


    return (
        <StyledDeckButton
            onClick={e => {
                navigator.vibrate([5000, 5000]);
            }}
        />
    );
}
