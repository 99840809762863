import {NodeSetupInfo} from "../NodeSetupInfo";
import {Node} from "../../backend/Node";
import {v4} from "uuid";
import {Pin} from "../../backend/Pin";

export const Comparer: NodeSetupInfo = {
    label: "Comparer",
    classname: "eq-comparer",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "eq-comparer",
        label: "comparer",
        // defInPins: ["a", "b"],
        defOutPins: ["eq"],
        reset() {
            this.pins.out("eq").write(false);
        },
        init() {
            const node = this;
            this.pins.out("eq").write(false);


            const targetsBank = this.pins.createPinBank("targets", {
                label: "targets",
                dynamicSizeFlag: true,
                hideLabelFlag: true
            })

            let idx = 0;
            const createNewChannel = () => targetsBank.pin(`${idx++}`);

            targetsBank.attach({
                onPinRead(pin: Pin, value: any) {
                    const pins = Array.from(targetsBank.pins.values());
                    if (pins.length === 0) return;
                    const refPin = pin;
                    let eq = true;
                    pins.filter(p => p.hasInputConnections).forEach(p => {
                        if (p.lastReadState !== refPin.lastReadState) {
                            eq = false;
                        }
                    });

                    node.pins.out("eq").write(eq);
                },

                onPinNewInboundConnection(thisPin: Pin, fromPin: Pin) {
                    createNewChannel();
                }
            });

            createNewChannel();
        },
    })
}
