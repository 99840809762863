import {Theme} from "../../triton/theming/Theme";
import {TritonDefaults} from "../../triton/TritonDefaults";

export const portfolioV1DarkBaseTheme: Theme = {
    ...TritonDefaults.theme,
    id: "portfolio-v1-dark-base-theme",
    title: "Portfolio V1: dark base theme",
    colors: {
        ...TritonDefaults.theme.colors,
        fg_display_primary: "#ffffff",
        fg_display_secondary: "#ffffff",
    }
}

export const portfolioV1LightBaseTheme: Theme = {
    ...TritonDefaults.lightTheme,
    id: "portfolio-v1-light-base-theme",
    title: "Portfolio V1: light base theme",
    colors: {
        ...TritonDefaults.lightTheme.colors,
        fg_display_primary: "#000000",
        fg_display_secondary: "#000000",
        color_gb_main: "#EDF1F2",
    }
}
