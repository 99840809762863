import React, {FC, useContext} from "react";
import styled from "styled-components";
import {Triton} from "../../../triton/Triton";
import {useTriton} from "../../../triton/TritonHooks";
import {ArdaiAPIContext} from "../../webapi/WebAPI";
import {MenuButton} from "../MenuButton";
import {ColorableTag} from "../ColorableTag";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {MainTypography} from "../../../triton/components/typography/MainTypography";

const StyledLayoutSelectorDisplay = styled.div<{
    t: Triton
}>`
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  .layout-selector {
    height: 125px;
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    background-color: ${p => p.t.col("layout_100")};
    display: grid;
    gap: 4px;
    grid-template-rows: 2fr 1fr;
  }
  
  .layout-field {
    border-radius: 4px;
    background-color: ${p => p.t.col("layout_300")};
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: all .2s;
    
    &:hover {
      scale: .95;
    }

    &:active {
      scale: .9;
    }
    
    &[data-active=true] {
      background-color: ${p => p.t.col("color_secondary")};
    }
  }
`;

export type LayoutSelectorDisplayProps = {
    programKey: string,
}

export const LayoutSelectorDisplay: FC<LayoutSelectorDisplayProps> = props => {
    const t = useTriton();
    const api = useContext(ArdaiAPIContext);

    const traysWithProgram = Object.entries(api.state.trayOccupancy)
        .filter(([k, v]) => v === props.programKey)
        .map(kv => kv[0])

    return (
        <StyledLayoutSelectorDisplay t={t}>

            <div className={"layout-selector"}>
                <div style={{
                    display: "grid",
                    gap: 4,
                    gridTemplateColumns: "repeat(2, 15%) auto repeat(2, 15%)"
                }}>
                    { ["left", "left-secondary", "main", "right-secondary", "right"].map(tray => (
                        <div
                            className={"layout-field"}
                            data-active={traysWithProgram.includes(tray)}
                            onClick={(e) => {
                                if (e.ctrlKey) {
                                    api.layoutManager.openProgramInTray(tray, props.programKey, {
                                        single: false
                                    });
                                    return;
                                }
                                api.layoutManager.openProgramInTray(tray, props.programKey, {
                                    single: true
                                });
                                // api.layoutManager.toggleProgram(tray, props.programKey);
                            }}
                        />
                    )) }
                </div>
                <div style={{
                    display: "grid"
                }}>
                    <div
                        className={"layout-field"}
                        data-active={traysWithProgram.includes("bottom")}
                        onClick={(e) => {
                            if (e.ctrlKey) {
                                api.layoutManager.openProgramInTray("bottom", props.programKey, {
                                    single: false
                                });
                                return;
                            }
                            api.layoutManager.openProgramInTray("bottom", props.programKey, {
                                single: true
                            });
                            api.layoutManager.toggleProgram("bottom", props.programKey)
                        }}
                    />
                </div>
            </div>


            <DescriptiveTypography text={"<ctrl>-click allows for a program to be opened in multiple trays"} style={{
                fontStyle: "italic"
            }}/>
        </StyledLayoutSelectorDisplay>
    );
}
