import {NodeSetupInfo} from "../NodeSetupInfo";
import {Node} from "../../backend/Node";
import {v4} from "uuid";

export const NodeFrequencyToPeriodConverter: NodeSetupInfo = {
    label: "NodeFrequencyToPeriodConverter",
    classname: "unit.converter.frequency-to-period",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "unit.converter.frequency-to-period",
        label: "f->p",
        defOutPins: ["pMS", "pS"],
        defInPins: ["f"],
        init: function () {
            const node = this;
            this.pins.in("f").attach({
                read(freq: number) {
                    freq = Number(freq);
                    const pS = 1 / freq;
                    node.pins.out("pMS").write(pS * 1e3);
                    node.pins.out("pS").write(pS);
                }
            })
        }
    })
}
