import {StorybookPage} from "./StorybookPage";
import {StorybookState} from "./Storybook";
import React, {RefObject} from "react";
import {FormikProps} from "formik";
import {NavigateFunction} from "react-router-dom";
import {SignalFunction} from "./SignalFunction";
import {Optional} from "../../../../base/Optional";

export class StorybookAPI {

    private _state: StorybookState | undefined;

    private _setState: React.Dispatch<React.SetStateAction<StorybookState>> | undefined;

    private _fp: FormikProps<any> | undefined;

    private _fpRef: React.RefObject<FormikProps<any>> | undefined;

    private pageData: Map<string, any> = new Map<string, any>();

    private pageTempData: Map<string, any> = new Map<string, any>();

    public updateStateReferences(state: StorybookState, setState: React.Dispatch<React.SetStateAction<StorybookState>>) {
        this._state = state;
        this._setState = setState;
    }

    public updateMasterFormikProps(fp: FormikProps<any>) {
        this._fp = fp;
    }

    public updateMasterFormikRef(fp: RefObject<FormikProps<any>>) {
        this._fpRef = fp;
    }

    public clearTempData() {
        this.pageTempData.clear();
    }

    public getTemp<T>(key: string): T | undefined {
        return this.pageTempData.get(key);
    }

    public setTemp<T>(key: string, val: T): T {
        this.pageTempData.set(key, val);
        return val;
    }

    public hasPageData(pageName: string): boolean {
        return this.pageData.has(pageName);
    }

    public setPageData(pageName: string, data: any) {
        this.pageData.set(pageName, data);
    }

    public getPageData<T = any>(pageName: string, def?: T): T | undefined {
        const data = this.pageData.get(pageName);
        if (data === undefined) return def;
        return data;
    }

    public forward(page: StorybookPage<any>) {
        this.cleanupCurrentPage();
        this.setState(prevState => ({
            ...prevState,
            transitionState: "transitioning",
            transitionDirection: "forward",
            nextPage: page
        }));
    }

    public backward(page: StorybookPage<any>) {
        this.cleanupCurrentPage();
        this.setState(prevState => ({
            ...prevState,
            transitionState: "transitioning",
            transitionDirection: "backward",
            nextPage: page
        }));
    }

    private cleanupCurrentPage() {
        this.state.currentPage?.onPageCleanup?.(this, true);
    }

    public submitPage(onSuccess: (api: StorybookAPI) => void) {
        this._fp?.setSubmitting(true);
        this._fp?.validateForm().then((errors) => {
            this._fp?.submitForm().then((d) => {
                this._fp?.setSubmitting(false);
                if (Object.values(errors).find(err => err !== undefined) !== undefined) {
                    // There are errors, (error object contains a key-value pair with a non-undefined error value)
                    return;
                }
                // No errors found, safe to submit
                onSuccess(this);
            });
        });
    }

    public submitAndForward(page: StorybookPage<any>) {
        this.submitPage(api => {
            api.forward(page);
        });
    }

    public getNavigateFunction(): NavigateFunction {
        return this.state.navigate!;
    }

    public async signal<T, V>(signal: string, param: T, fallback?: SignalFunction): Promise<V | undefined | void> {
        const func = this.state.signals?.get(signal);
        if (func === undefined) return fallback?.(this, param);
        return func(this, param);
    }

    get setState(): React.Dispatch<React.SetStateAction<StorybookState>> {
        return this._setState!;
    }
    get state(): StorybookState {
        return this._state!;
    }

    get fp(): FormikProps<any> {
        return this._fp!;
    }

    get fpRef(): RefObject<FormikProps<any>> {
        return this._fpRef!;
    }
}

export namespace StorybookAPI {

    export type With<T = void, P = void, V = any> = (api: StorybookAPI, fp: FormikProps<V>, data: P) => T

    export function callWith<T, P = void>(func: With<T, P>, api: StorybookAPI, data?: P) {
        return func(api, api.fpRef.current!, data!);
    }
}
