import React, {PropsWithChildren, useEffect, useState} from "react";
import {GuardStrategy} from "./GuardStrategy";

export type GuardedSectorProps = PropsWithChildren<{
    strategy: GuardStrategy,
    notAllowedComponent?: React.ReactNode,
    onAllowed?: () => void
    onNotAllowed?: () => void
}>

export const GuardedSector: React.FC<GuardedSectorProps> = props => {
    const allowed = props.strategy();
    if (allowed) props.onAllowed?.();
    else props.onNotAllowed?.();

    return allowed ?
        <>{ props.children }</> :
        <>{ props.notAllowedComponent }</> ??
        // TODO: Provide proper error page :: Like a 401 Not authorized
        <></>

}
