import React from "react";
import styled from "styled-components";
import {ImageView} from "../../components/ImageView";
import {ModalCompoundContext} from "../../components/ButtonModalCompound";
import {IconButton} from "../../components/IconButton";
import {CloseFullscreenRounded, CloseRounded} from "@mui/icons-material";
import {useKeyboardEvent} from "../../_/keyCommand/test/KeyCommandTest";

const StyledImageViewFullscreenModal = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  
  .image-view-fullscreen-floating-controls {
    --padding: 8px;
    position: absolute;
    top: var(--padding);
    right: var(--padding);
  }
  
  .image-view-fullscreen-wrapper {
    height: 100%;
    
    // Wrapper
    & > * {
      height: 100%;

      // Actual workspace tray
      & > * {
        border-radius: 0 !important;
      }
    }
  }
`;

export type ImageViewFullscreenModalProps = {
    ctx: ModalCompoundContext
}

export const ImageViewFullscreenModal: React.FC<ImageViewFullscreenModalProps> = props => {
    useKeyboardEvent("keydown", e => {
        if (e.key === "F11") {
            e.stopPropagation();
            e.preventDefault();
            props.ctx.close();
        }
    });

    return (
        <StyledImageViewFullscreenModal>
            <div className={"image-view-fullscreen-wrapper"} children={
                <ImageView/>
            }/>
            <div className={"image-view-fullscreen-floating-controls"} children={
                <IconButton size={"small"} tooltip={"Exit fullscreen"} onClick={props.ctx.close} children={
                    <CloseFullscreenRounded/>
                }/>
            }/>
        </StyledImageViewFullscreenModal>
    );
}
