import {Entity} from "../../ecs/entity/Entity";
import {PolarVec2D} from "../../math/PolarVec2D";
import {castEntityFull} from "../../SimStd";
import {ShipMainEntity} from "../ship/ShipMainEntity";
import {Std} from "../../../../../Std";
import range = Std.range;
import {Quadrant} from "../../math/Quadrant";

export type EnvironmentSimulationState = {
    wind: PolarVec2D,
    windMaxDeviationPerTick: number
}

export class EnvironmentSimulationEntity extends Entity<EnvironmentSimulationState> {

    constructor() {
        super("env", {
            initialState: {
                wind: new PolarVec2D(45 + 90, 20),
                windMaxDeviationPerTick: .25
            }
        });
    }

    syncTick() {
        super.syncTick();
        this.recalculateWindVector();
        this.applyWindForceToShip();

        // this.setStatePartially(prevState => ({
        //     wind: prevState.wind.rotate(1)
        // }));
    }

    public recalculateWindVector() {
        const maxDeviation = this.state.windMaxDeviationPerTick;
        let t = (Math.random() * maxDeviation * 2) - maxDeviation;
        this.setStatePartially(prevState => ({
            wind: prevState.wind.rotate(t)
        }));
    }

    public applyWindForceToShip() {
        const sim = this.simulation;
        const ship = castEntityFull<ShipMainEntity>(sim.entity("ship"));
        // calc
        const wind = this.state.wind;
        const attackAngle = wind.theta - ship.state.heading.theta;
        const quadrantAttackAngle = attackAngle % 90;
        let attackForceFacBase = 45 - Math.abs(45 - quadrantAttackAngle); // 0-45
        attackForceFacBase = range(0, 45, 0, 1, attackForceFacBase);

        // apply
        let orientationFac = -1;
        switch (wind.invert().cartesian.quadrant) {
            case Quadrant.NW:
            case Quadrant.SE:
                orientationFac = 1;
        }

        // const
        const maxTurnMovementForce = 2;

        ship.setStatePartially(prevState => ({
            heading: prevState.heading.rotate(
                orientationFac * maxTurnMovementForce * attackForceFacBase
            )
        }));
    }
}
