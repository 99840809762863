import React from "react";
import {Modal} from "../../triton/components/dialogs/Modal";
import {v4} from "uuid";
import {FormElement} from "../../triton/components/forms/FormElement";
import {FormikSingleLineInput} from "../../triton/components/forms/FormikSingleLineInput";
import {ErrorMessage} from "../../triton/components/forms/ErrorMessage";
import {TagListConfigurator} from "./TagListConfigurator";
import {FormikTextArea} from "../../triton/components/forms/FormikTextArea";
import {px} from "../../base/logic/style/DimensionalMeasured";
import {ButtonBase, ButtonVariant} from "../../triton/components/buttons/ButtonBase";
import {BooleanContext} from "../../triton/components/BooleanContext";
import {Formik} from "formik";
import styled from "styled-components";
import {Tag} from "./Tag";
import {MainTypography} from "../../triton/components/typography/MainTypography";

const StyledTagEditor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  .tag-suggestions {
    width: 100%;
    
    .tag-suggestions-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
    }
  }
`;

export type TagEditorProps = {
    suggestions?: (currentTags: Array<string>) => Array<string>,
    open: boolean,
    onClose: () => void,
    onSave: (tags: Array<string>) => void,
    initialTags: Array<string>,
    title?: string
}

export const TagEditor: React.FC<TagEditorProps> = props => {

    return (
        <Formik
            initialValues={{
                tagPrompt: "",
                tags: props.initialTags,
            }}
            onSubmit={(values) => props.onSave(values.tags)}
            children={formikProps => {
                return (
                    <Modal
                        title={props.title ?? "Edit tags"}
                        open={props.open}
                        onClose={() => props.onClose()}
                        onSubmit={(e) => {
                            formikProps.handleSubmit(e);
                        }}
                        children={
                            <StyledTagEditor>
                                { props.suggestions && (
                                    <div className={"tag-suggestions"}>
                                        <div className={"tag-suggestions-list"} children={
                                            props.suggestions(formikProps.values.tags).map(s => (
                                                <Tag tag={s} key={s} onClick={() => {
                                                    const newTags = [...(formikProps.values.tags as string[] ?? [])];
                                                    newTags.push(s);
                                                    formikProps.setFieldValue("tags", Array.from(new Set(newTags)));
                                                }}/>
                                            ))
                                        }/>
                                    </div>
                                ) }
                                <TagListConfigurator formik={formikProps}/>
                            </StyledTagEditor>
                        }
                        footer={
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                gap: "8px",
                                width: "100%"
                            }}>
                                <ButtonBase text={"Save"} baseProps={{
                                    type: "button",
                                    onClick: (e) => formikProps.handleSubmit(e)
                                }}/>
                                <ButtonBase text={"Cancel"} baseProps={{
                                    type: "button",
                                    onClick: () => props.onClose()
                                }}/>
                            </div>
                        }
                    />
                );
            }
        }/>
    );
}
