import {Tab} from "./Tab";
import {TabInfo} from "./TabInfo";
import {v4} from "uuid";
import {TabManagerEvents} from "./TabManagerEvents";
import {TabManagerEvent} from "./TabManagerEvent";

export class TritonTabManager {

    public tabs: Array<Tab> = [];

    constructor(
        private readonly events: TabManagerEvents = {}
    ) {}

    private fireEvent(on: keyof TabManagerEvents, event: TabManagerEvent) {
        if (on === "onPreEvent" || on === "onPostEvent") throw new Error(`cannot manually fire internal event '${on}'`);
        this.events.onPreEvent?.(event, on);
        const fn = this.events[on];
        if (fn !== undefined) fn(event);
        this.events.onPostEvent?.(event, on);
    }

    public create(info: TabInfo): Tab {
        const tab = new Tab(info, v4(), this);
        this.tabs.push(tab);
        this.fireEvent("onTabCreated", new TabManagerEvent(tab));
        return tab;
    }

    public destroy(tabId: string) {
        const tab = this.getTabFromId(tabId);
        if (tab === undefined) throw new Error(`Tab '${tabId}' not found`);
        tab.onDeactivate();
        tab.onDestroy();
        this.tabs = this.tabs.filter(tab => tab.tabId !== tabId);
        // delete this.tabs[this.getTabIndexFromId(tabId)];
        this.fireEvent("onTabDestroyed", new TabManagerEvent(tab));
    }

    public deactivateCurrentTab() {
        const tab = this.activeTab;
        if (tab === undefined) return;
        tab.onDeactivate();
        this.fireEvent("onTabDeactivated", new TabManagerEvent(tab));
    }

    public activate(tabId: string) {
        this.deactivateCurrentTab();
        const nextActiveTab = this.getTabFromId(tabId);
        if (nextActiveTab === undefined) throw new Error(`Tab '${tabId}' not found`);
        nextActiveTab.onActivate();
        this.fireEvent("onTabActivated", new TabManagerEvent(nextActiveTab));
    }

    public get activeTab(): Tab | undefined {
        return this.tabs.find(t => t.isActive);
    }

    public getTabIndexFromId(tabId: string): number {
        const tab = this.getTabFromId(tabId);
        if (tab === undefined) throw new Error(`Tab '${tabId}' not found`);
        return this.tabs.indexOf(tab);
    }

    public getTabFromId(tabId: string): Tab | undefined {
        return this.tabs.find(t => t.tabId === tabId);
    }
}
