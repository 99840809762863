import React, {useEffect, useState} from "react";
import {AnnotationRowConfig} from "./AnnotationRowConfig";
import Collapse from "@mui/material/Collapse";
import {TransitionGroup} from "react-transition-group";
import {useKeyboardEvent} from "../../../../ardai/_/keyCommand/test/KeyCommandTest";

export type AnnotationRowProps = {
    annotation: AnnotationRowConfig
}

export type AnnotationRowState = {
    visible: boolean
}

export const AnnotationRow: React.FC<AnnotationRowProps> = props => {
    const annotation = props.annotation;

    const [state, setState] = useState<AnnotationRowState>({
        visible: !annotation.obfuscated ?? true
    })

    useKeyboardEvent("keydown", e => {
        if (e.shiftKey) setState(prevState => ({
            ...prevState,
            visible: true
        }));
    });

    useKeyboardEvent("keyup", e => {
        if (!e.shiftKey && annotation.obfuscated && state.visible) setState(prevState => ({
            ...prevState,
            visible: false
        }));
    });

    return (
        <TransitionGroup children={state.visible && (
            <Collapse timeout={100}>
                <div children={(() => {
                    switch (typeof annotation.renderer) {
                        case "function":
                            return annotation.renderer();
                        default:
                            return annotation.renderer as unknown as React.ReactNode
                    }
                })()}/>
            </Collapse>
        )}/>
    );
}
