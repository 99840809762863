import React from "react";
import {DoorEntity} from "./DoorEntity";
import {PropsWithEntity} from "../../ecs/entity/PropsWithEntity";
import styled from "styled-components";
import {motion} from "framer-motion";

const StyledDoorEntityDisplay = styled.div`
  height: 50px;
  background-color: black;

  .door-frame {
    --wall-thickness: 3px;
    position: relative;
    display: grid;
    grid-template-rows: min-content auto min-content;
    height: 100%;

    .door-frame-wall {
      width: var(--wall-thickness);
      height: 5px;
      background-color: rgb(33, 38, 45);
    }

    .door-wrapper {
      background-color: rgba(201, 209, 217, .1) !important;
      height: 100%;
      width: var(--wall-thickness);
      display: flex;
      justify-content: center;
      position: relative;
      
      .door {
        width: calc(var(--wall-thickness) - 1px);
        background-color: #ffdf60;
        position: absolute;
        transform-origin: 0 0;
      }
    }
  }
`;

export const DoorEntityDisplay: React.FC<PropsWithEntity<DoorEntity>> = props => {
    const e = props.entity;
    const closed = e?.state?.closed ?? false;

    return (
        <StyledDoorEntityDisplay onClick={() => e.toggleDoor()}>
            <div className={"door-frame"}>
                <div className={"door-frame-wall"}/>
                <div className={"door-wrapper"}>

                    <motion.div
                        className={"door"}
                        animate={closed ? "closed" : "opened"}
                        variants={{
                            closed: {
                                height: "100%",
                                // rotate: -90
                            },
                            opened: {
                                height: 0
                                // height: "100%",
                                // rotate: 0,
                            }
                        }}
                    />
                </div>
                <div className={"door-frame-wall"}/>
            </div>
        </StyledDoorEntityDisplay>
    );
}
