import React from "react";
import styled from "styled-components";
import StackGrid from "react-stack-grid";

const StyledGenericMasonry = styled.div`
  margin: 0 13px;
  
  .masonry-item-container {
    padding: 0 3px;
    
    width: 100%;
    aspect-ratio: 1 / 1;
    // background-color: deeppink;
    
    // overflow: hidden;
    // border: 1px inset red;
    
    
  }

  .masonry-grid-container span {
    opacity: 1 !important;
  }
`;

export type GenericMasonryProps<T> = {
    items: Array<T>,
    keyExtractor: (data: T) => string
    renderer: (data: T, i: number, arr: Array<T>) => React.ReactNode,
    cols?: number
}

export const GenericMasonry = <T extends unknown>(props: GenericMasonryProps<T>) => {
    const cols = props.cols ?? 2;

    // return (
    //     <StyledGenericMasonry children={
    //         <ImageList variant="masonry" sx={{ m: 0 }} cols={props.cols ?? 3} gap={6} children={props.items.map((item, i, arr) => (
    //             <ImageListItem key={props.keyExtractor(item)} children={props.renderer(item, i, arr)}/>
    //         ))}/>
    //     }/>
    // );

    // return (
    //     <StyledGenericMasonry children={
    //         <Masonry
    //             spacing={0}
    //             defaultColumns={props.cols ?? 2}
    //             columns={props.cols ?? 2}
    //             sx={{ m: 0, gap: 0 }}
    //             children={props.items.map((item, i, arr) => (
    //                 <div
    //                     className={"masonry-item-container"}
    //                     key={props.keyExtractor(item)}
    //                     children={
    //                         props.renderer(item, i, arr)
    //                     }
    //                 />
    //             ))
    //         }/>
    //     }/>
    // );

    return (
        <StyledGenericMasonry children={
            <StackGrid
                className={"masonry-grid-container"}
                monitorImagesLoaded={false}

                // columnWidth={"50%"}
                columnWidth={`${100 / cols}%`}

                duration={0}
                children={props.items.map((item, i, arr) => (
                    <div
                        className={"masonry-item-container"}
                        key={props.keyExtractor(item)}
                        children={
                        props.renderer(item, i, arr)
                    }/>
                ))
            }/>
        }/>
    );
}
