import React, {useEffect} from "react";
import {motion, useMotionValue, useSpring, useTime, useTransform} from "framer-motion";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {Interpolate} from "../../../../triton/components/advanced/Interpolate";

const StyledRotaryIndicator = styled.div`
  // width: auto;
  width: 60px;
  
  aspect-ratio: 1 / 1 !important;
  border-radius: 10%;
  // border-radius: 8px;
  // border-radius: 100px;
  box-shadow: rgb(33, 38, 45) 0 0 0 1px;
  background-color: rgb(1, 4, 9);
  position: relative;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  --pipehint-width: 2px;
  cursor: pointer;

  .valve-pipe-hint {
    --pipehint-overflow: 0px;
    width: 100%;
    background-color: rgb(88, 166, 255);
    height: calc(var(--pipehint-width) + var(--pipehint-overflow));
    z-index: 2;
  }

  .valve-pointer-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    .valve-pointer {
      width: 80%;
      aspect-ratio: 1 / 1;
      overflow: hidden;
      border-radius: 50%;
      // background-color: rgba(201, 209, 217, 0.1);
      transition: all 1s ease-in-out;
      box-shadow: rgb(33, 38, 45) 0 0 0 1px;
      
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      
      display: flex;
      justify-content: center;
      align-items: center;
      
      .pointer {        
        z-index: 10;
        // width: 100%;
        width: 0;
        aspect-ratio: 1 / 1;
        // height: var(--pipehint-width);
        border-radius: 50%;
        // height: 100%;
        // min-width: 5px;
        // width: 50%;
        // background-color: #ffdf60;
        background-color: transparent;
        transition: width 4s ease-in-out;

        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        
        // #ffdf60
        --fan-color: rgb(33, 38, 45);
        
        border-bottom: 50px solid var(--fan-color);
        border-top: 50px solid var(--fan-color);
      }
    }
  }
  
  .requested-indicator-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: calc(100% - 20%);
    
    &:before {
      content: '';
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      position: absolute;
      width: 100%;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      box-shadow: rgb(33, 38, 45) 0 0 0 1px;
    }
    
    .requested-indicator {
      // background-color: #d727e0;
      transition: all 1s ease-in-out;
      // width: 80px;
      width: 100%;
      height: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .requested-indicator-pointer {
        transition: all 1s ease-in-out;
        background-color: rgb(33, 38, 45);
        width: 9px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
      }
    }
  }
  
  .indicator-center-pointer {
    position: absolute;
    align-self: center;
    justify-self: center;
    background-color: rgb(33, 38, 45);
    width: 9px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    z-index: 20;
  }
`;

export type RotaryIndicatorProps = {
    rpm: number,
    rotating: boolean
}

export const InterpolatedRotaryIndicator: React.FC<RotaryIndicatorProps> = props => {
    const fac = 10;
    return (
        <Interpolate stepDelay={100} value={props.rpm / fac} children={d => (
            <RotaryIndicator {...props} rpm={d * fac}/>
        )}/>
    );
}

export const RotaryIndicator: React.FC<RotaryIndicatorProps> = props => {

    const rotateMotion = useMotionValue(0);


    useEffect(() => {

        if (props.rpm === 0) {
            rotateMotion.set(Number.MAX_VALUE)
            return;
        }
        rotateMotion.set(1 / props.rpm * 1e3 * 60 );
    }, [props.rpm]);

    const t = useTime();
    const rotate = useTransform(
        t,
        [0, rotateMotion.get()],
        [0, 360],
        { clamp: false }
    );

    return (
        <StyledRotaryIndicator>

            {/*
            <div style={{
                position: "absolute",
                justifySelf: "center",
                alignSelf: "center",
                zIndex: 30
            }} children={
                <DescriptiveTypography text={Math.floor(rotateMotion.get())}/>
            }/>
            */}

            <div className={"indicator-center-pointer"}/>

            <div className={"valve-pipe-hint"}/>


            <div className={"requested-indicator-wrapper"} children={
                <></>
            }/>



            <div className={"valve-pointer-holder"} children={
                <div className={"valve-pointer"}>
                    <motion.div className={"pointer"} style={{
                        // backgroundColor: "red",
                        rotate: rotate
                    }}/>
                </div>
            }/>


            <div className={"valve-pipe-hint"}/>
        </StyledRotaryIndicator>
    );
}
