import React from "react";
import styled from "styled-components";
import {ChevronRightRounded} from "@mui/icons-material";

const StyledBreadcrumbSeparator = styled.div`
  height: 100%;
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1e202a;
  position: relative;
  
  .separator-icon {
    position: absolute;
  }
`;

export type BreadcrumbSeparatorProps = {

}

export const BreadcrumbSeparator: React.FC<BreadcrumbSeparatorProps> = props => {
    return (
        <StyledBreadcrumbSeparator>
            <ChevronRightRounded className={"separator-icon"} fontSize={"small"}/>
        </StyledBreadcrumbSeparator>
    );
}
