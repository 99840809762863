import {MobileBoardingPageContext} from "../../../boarding/MobileBoardingPage";
import {CRH} from "../../../../../shared/ConditionalResponseHandler";
import {SignalFunction} from "../../../../storybook/SignalFunction";
import {BoardingFlowMode} from "../../../boarding/BoardingFlowMode";
import {emailAndUsernamePage} from "./EmailAndUsernameStoryPage";
import {AccountCreationRequestData} from "./AccountCreationData";
import {Storybook} from "../../../../storybook/Storybook";
import {welcomePage} from "./AccountCreatedStoryPage";
import {axiosExo} from "../../../../../shared/Exo";
import {arcURL} from "../../../../../Globals";
import {useNavigate} from "react-router-dom";
import React, {useContext} from "react";
import {verifyEmailPage} from "./VerifyEmailStoryPage";

export const AccountCreationStorybook: React.FC = props => {
    const mobileBoardingPageContext = useContext(MobileBoardingPageContext);
    const navigate = useNavigate();

    return (
        <Storybook
            onExit={result => {}}
            beginning={emailAndUsernamePage}
            signals={new Map<string, SignalFunction>([
                ["switch-to-login", (api, data) => {
                    if (mobileBoardingPageContext !== undefined) {
                        mobileBoardingPageContext?.switchAuthFlow(data as BoardingFlowMode);
                    } else {
                        navigate("boarding/login");
                    }
                }],
                ["create-account", createAccountSignal]
            ])}
        />
    );
}

/**
 * A-RFC 1:
 *  The submitting approach here should work in all typical cases, but isn't working when the verification link gets
 *  clicked before the master-transition to the verification page is completed (master-init).
 *  => Strongly non-deterministic
 *
 * @param api
 * @param data
 */
export const createAccountSignal: SignalFunction = async (api, data) => {
    // Get page data entries
    const emailUserNameData = api.getPageData("emailAndUsername");
    const passwordData = api.getPageData("password");
    const birthdateData = api.getPageData("birthdate");

    // Make REST API post
    const res = (await axiosExo({
        url: arcURL("create-account"),
        method: "post",
        data: {
            email: emailUserNameData.email,
            username: emailUserNameData.username,
            password: passwordData.password,
            birthdate: birthdateData.birthdate
        } as AccountCreationRequestData
    }, {
        crh: new CRH().ok(response => {
            // OK :: Account was created successfully
            // FIXME: ReferenceError: Cannot access 'res' before initialization
            // TODO: Handle API result
        })
        // TODO: Handle all the other cases
    }));

    if (res.data.code === 0) {
        // OK :: Account was created successfully :: Open email verification page
        // TODO: Implement Email-verification
        api.forward(verifyEmailPage)
        // TODO: Implement auto login with credentials
        // TODO: Implement
        // api.forward(welcomePage)
    }
}
