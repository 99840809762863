import {StandaloneObservable} from "./ardai/webapi/pubsub/StandaloneObservable";
import {StateEventTypes} from "./StateEventTypes";
import {Dispatch, SetStateAction} from "react";

export class State<Type = any> {

    public readonly observer: StandaloneObservable<StateEventTypes>
        = new StandaloneObservable<StateEventTypes>();

    constructor(
        public _state: Type
    ) {}

    public set state(nextState: Type) {
        this._state = nextState;
        this.observer.notify(StateEventTypes.STATE_UPDATE, undefined);
    }

    public get state(): Type {
        return this._state;
    }

    public get setState(): Dispatch<SetStateAction<Type>> {
        return u => {
            if (typeof u === "function") {
                this.state = (u as ((prevState: Type) => Type))(this._state);
            } else {
                this.state = u;
            }
        }
    }

    // STD

    public update(updates: Partial<Type> | ((prevState: Type) => Partial<Type>)) {
        this.setState(prevState => ({
            ...prevState,
            ...(typeof updates === "function") ? (<Function>updates)(prevState) : updates
        }));
    }

    public reverseBool(propertyName: keyof Type) {
        this.setState(prevState => ({
            ...prevState,
            [propertyName]: !(prevState[propertyName] as boolean ?? false)
        }));
    }
}
