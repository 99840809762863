
import React, {useContext} from "react";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {Modal} from "../../triton/components/dialogs/Modal";
import {FormElement} from "../../triton/components/forms/FormElement";
import {FormikSingleLineInput} from "../../triton/components/forms/FormikSingleLineInput";
import {ErrorMessage} from "../../triton/components/forms/ErrorMessage";
import {ButtonBase, ButtonVariant} from "../../triton/components/buttons/ButtonBase";
import {BooleanContext} from "../../triton/components/BooleanContext";
import {Formik} from "formik";
import {ControlledBooleanInput} from "./ControlledBooleanInput";
import {FormDivider} from "../../triton/components/forms/FormDivider";
import {ColorSelector} from "../../base/components/base/colorSelector/ColorSelector";
import {ColorField} from "../testing/ColorField";
import {Color} from "../../base/logic/style/Color";
import {AdvancedStringInput} from "./advancedStringProcessor/AdvancedStringInput";
import {ButtonGroup} from "./ButtonGroup";
import {Tag} from "./Tag";
import {useStaticState} from "../hooks/StaticStateHook";
import {MenuGroup} from "./MenuGroup";

export type FolderSetupDialogState = {
    folderTitleInputMode: "basic" | "advanced"
}

export const FolderSetupDialog: React.FC = props => {
    const api = useContext(ArdaiAPIContext);

    const [state, ctx] = useStaticState<FolderSetupDialogState>({
        id: "FolderSetupDialog",
        initial: {
            folderTitleInputMode: "basic"
        }
    }).stateWithCtx;

    return (
        <Formik
            initialValues={{
                title: "",
                isMultiCreationMode: true
            }}
            validate={values => {
                const errors: Partial<typeof values> = {};
                if (values.title.trim().length === 0) errors.title = "Folder name is required";
                return errors;
            }}
            onSubmit={async (values, formikHelpers) => {
                const isMultiCreationMode = values.isMultiCreationMode ?? false;
                const separator = ","
                const foldersToCreate = !isMultiCreationMode ? [values.title] : values.title
                    .split(separator)
                    .map(s => s.trim())

                for (let folderToCreate of foldersToCreate) {
                    await api.createFolder(folderToCreate);
                }

                formikHelpers.setSubmitting(false);
                api.toggleFolderCreationDialog(false);
            }}
            children={formikProps => {
                return (
                    <Modal
                        title={"Create folder"}
                        preventClosingMasterSwitch
                        open={api.state.isFolderCreationDialogOpened}
                        onClose={() => api.toggleFolderCreationDialog(false)}
                        onSubmit={(e) => {
                            formikProps.handleSubmit(e);
                        }}
                        children={
                            <div style={{
                                display: "grid",
                                gap: "8px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: 8
                                }}>
                                    <FormDivider title={"Folder name"}/>

                                    <ButtonGroup>
                                        <Tag
                                            tag={"BSC"}
                                            active={state.folderTitleInputMode === "basic"}
                                            onClick={() => ctx.update({ folderTitleInputMode: "basic" })}
                                        />
                                        <Tag
                                            tag={"ADV"}
                                            active={state.folderTitleInputMode === "advanced"}
                                            onClick={() => ctx.update({ folderTitleInputMode: "advanced" })}
                                        />
                                    </ButtonGroup>
                                </div>



                                <FormElement
                                    // title={"Folder name"}
                                    caption={!formikProps.values.isMultiCreationMode ? undefined : (
                                        `Create multiple folders, separated by ','`
                                    )}
                                    children={
                                        state.folderTitleInputMode === "basic" && (
                                            <>
                                                <FormikSingleLineInput autoFocus={true} formikProps={formikProps} name={"title"}/>
                                                <ErrorMessage formikProps={formikProps} name={"title"}/>
                                            </>
                                        ) || (
                                            <AdvancedStringInput
                                                persistentConfig={{
                                                    id: "folder-setup-dialog-name-input"
                                                }}
                                                onChangeComputed={result => {
                                                    formikProps.setFieldValue("title", result.values.join(", "))
                                                }}
                                            />
                                        )
                                    }
                                />

                                <MenuGroup title={"Advanced"} showIndentationLine={true} indentation={16}>
                                    <FormElement title={"Color"} children={
                                        <>
                                            <ColorField name={"color"} data={Color.black} onChange={data => {

                                            }}/>
                                        </>
                                    }/>

                                    <ControlledBooleanInput
                                        value={formikProps.values.isMultiCreationMode}
                                        title={"Create multiple folders (','-separated)"}
                                        helpText={"Folder titles are separated by ','"}
                                        update={bool => {
                                            formikProps.setFieldValue("isMultiCreationMode", bool)
                                        }}
                                    />
                                </MenuGroup>
                            </div>
                        }
                        footer={
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                gap: "8px",
                                width: "100%"
                            }}>

                                <ButtonBase text={formikProps.isSubmitting ? "Processing.." : "Create"} baseProps={{
                                    type: "button",
                                    onClick: (e) => {
                                        formikProps.handleSubmit(e)
                                    }
                                }}/>

                                <BooleanContext children={(bool, setBool) => (
                                    <>
                                        <Modal open={bool} preventClosingOnBackdropClick onClose={() => { setBool(false) }} title={"Abort"}>
                                            <div style={{
                                                display: "grid",
                                                gridTemplateColumns: "repeat(2, 1fr)",
                                                gap: "8px"
                                            }}>
                                                <ButtonBase text={"Cancel"} baseProps={{
                                                    type: "button",
                                                    onClick: () => {
                                                        setBool(false);
                                                    }
                                                }}/>
                                                <ButtonBase text={"Abort"} variant={ButtonVariant.DANGER} baseProps={{
                                                    type: "button",
                                                    onClick: () => {
                                                        setBool(false);
                                                        api.toggleFolderCreationDialog(false);
                                                    }
                                                }}/>
                                            </div>
                                        </Modal>

                                        <ButtonBase text={"Cancel"} baseProps={{
                                            type: "button",
                                            onClick: () => {
                                                if (formikProps.dirty) {
                                                    setBool(true);
                                                } else {
                                                    api.toggleFolderCreationDialog(false);
                                                }
                                            }
                                        }}/>
                                    </>
                                )}/>
                            </div>
                        }
                    />
                );
            }
            }/>
    );
}
