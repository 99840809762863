import React, {useRef, useState} from "react";
import styled from "styled-components";
import {AnimatePresence, motion, useMotionValue, useTransform} from "framer-motion";
import {Post} from "./models/Post";
import {PostMetricsView} from "./PostMetricsView";
import {PostViewHeader} from "./PostViewHeader";
import {createPortal} from "react-dom";
import {SamplePostMasonryView} from "./SamplePostMasonryView";
import {v4} from "uuid";

const StyledPostFullscreenView = styled.div`
  
`;

export type PostFullscreenViewProps = {
    post: Post,
    expanded: boolean,
    onCloseFullscreenView: () => void
}

export const PostFullscreenView: React.FC<PostFullscreenViewProps> = props => {
    const post = props.post;
    const x = useMotionValue(0);
    const y = useMotionValue(0);
    // const scale = useTransform(x, [-150, 0, 150], [0.5, 1, 0.5]);
    const scale = useTransform(y, [0, 250], [1, .75]);

    const [state, setState] = useState({
        isInExit: false
    });

    // const iRange = 100, oRange = 5;
    // const rotate = useTransform(x, [-iRange, 0, iRange], [-oRange, 0, oRange], {
    //     clamp: true,
    // });

    y.on("change", val => {
        if (val >= 0 && !state.isInExit) {
            setState(prevState => ({
                ...prevState,
                isInExit: true
            }));
        } else if (val < 0 && state.isInExit) {
            setState(prevState => ({
                ...prevState,
                isInExit: false
            }));
        }
    });

    const fullscreenWrapperRef = useRef<HTMLDivElement>(null);

    return createPortal(
        <StyledPostFullscreenView>
            <AnimatePresence children={props.expanded && (
                <motion.div
                    ref={fullscreenWrapperRef}
                    layoutId={post.id}
                    // drag={"x"}
                    drag={true}
                    dragDirectionLock={!state.isInExit}
                    dragSnapToOrigin
                    dragConstraints={{
                        top: 0,
                        // right: 0,
                        // bottom: 0,
                        // left: 0
                    }}
                    variants={{
                        animate: {
                            scale: 1,
                            y: 0,
                            opacity: 1
                        },
                        exit: (custom) => ({
                            x: custom,
                            opacity: 0,
                            scale: 0.5
                        })
                    }}
                    style={{
                        backgroundColor: "black",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        boxShadow: "0 0 0 8px black",
                        borderRadius: 10,
                        zIndex: 20,

                        // TODO: Check
                        overflow: "scroll",
                        // overflow: "hidden",

                        // motion values
                        // rotate: rotate,
                        scale: scale,
                        x,
                        y
                    }}
                    transition={{
                        // stiffness: 300,
                        stiffness: 100,
                        // damping: 10,
                        damping: 0,
                        duration: .2
                    }}
                    onDragEnd={(event, info) => {
                        const threshold = 100;
                        // if (info.offset.x < -threshold || info.offset.x > threshold || info.offset.y < -threshold || info.offset.y > threshold) {
                        //     props.onCloseFullscreenView();
                        // }
                        if (info.offset.y > threshold) {
                            props.onCloseFullscreenView();
                        }
                    }}
                >
                    <motion.div drag={"y"} dragConstraints={fullscreenWrapperRef} style={{
                        // TODO: Reactivate
                        // overflowY: "scroll"
                    }}>

                        <motion.img
                            className={"post-image"}
                            src={`http://192.168.178.20:8080/proto/static/${post.ressourceIDs[0]}.png`}
                            alt={post.ressourceIDs[0]}
                            loading="lazy"
                            style={{
                                width: "100%",
                                objectPosition: "contain",
                                height: "auto",
                            }}
                        />

                        <PostViewHeader/>

                        <PostMetricsView post={post}/>

                        <div style={{
                            backgroundColor: "red",
                            width: "100%",
                            height: "1000px"
                        }}></div>

                        <div style={{
                            backgroundColor: "blanchedalmond",
                            width: "100%",
                            height: "80px"
                        }}></div>
                    </motion.div>
                </motion.div>
            )}/>
        </StyledPostFullscreenView>,
        document.querySelector("#post-fullscreen-portal")!,
    );
}
