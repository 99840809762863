import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import fileDownload from "js-file-download";

export const NodeSimpleFileDownload: NodeSetupInfo = {
    label: "NodeSimpleFileDownload",
    classname: "files.simple-download",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "files.simple-download",
        label: "download",
        defInPins: ["c", "src", "name", "type"],
        init() {
            this.pins.in("c").attach({
                read: () => {
                    const src = this.pins.in("src").lastReadState;
                    const name = this.pins.in("name").lastReadState;
                    const type = this.pins.in("type").lastReadState;
                    const filename = `${name}${type !== undefined ? `.${type}` : ""}`;
                    fileDownload(src, filename, "image/png");
                }
            });
        }
    })
}
