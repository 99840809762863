import React, {useContext} from "react";
import {v4} from "uuid";
import {StyledModal} from "../../components/StyledModal";
import {FoodBankRounded} from "@mui/icons-material";
import {FormElement} from "../../../triton/components/forms/FormElement";
import {FormikSingleLineInput} from "../../../triton/components/forms/FormikSingleLineInput";
import {ErrorMessage} from "../../../triton/components/forms/ErrorMessage";
import {TagListConfigurator} from "../../components/TagListConfigurator";
import {Grid} from "../../../triton/components/layouts/Grid";
import {ButtonBase} from "../../../triton/components/buttons/ButtonBase";
import {Formik} from "formik";
import {TopicSelectorStateContext} from "./TopicSelectorStateContext";
import {FileInput} from "../../../base/components/base/fileInput/FileInput";
import _ from "lodash";

export type SpiceCreationDialogProps = {
    onClose: () => void
}

export const SpiceCreationDialog: React.FC<SpiceCreationDialogProps> = props => {
    const [state, setState] = useContext(TopicSelectorStateContext);

    return (
        <Formik initialValues={{
            title: "",
            tags: [],
            sourceFile: undefined
        }} validate={values => {
            const errors: any = {};
            if (state.spices.filter(s => s.title === values.title.trim()).length > 0) errors.title = "Duplicate title";
            if (values.title.trim().length === 0) errors.title = "Title cannot be empty";
            return errors;
        }} onSubmit={async (values) => {
            let spiceValues: Array<string> = [];
            if (values.sourceFile !== undefined) {
                const file = values.sourceFile as File;
                const text = await file.text();
                const tags = text.split("\n").map(s => s.trim());
                spiceValues.push(...tags);
            }
            if (values.tags.length === 0 && spiceValues.length === 0) {
                spiceValues.push(values.title.trim());
            }
            spiceValues.push(...values.tags);
            setState(prevState => ({
                ...prevState,
                spices: [
                    ...prevState.spices, {
                        id: v4(),
                        title: values.title.trim(),
                        values: _.uniq(spiceValues)
                    }
                ]
            }));
            props.onClose();
        }} children={fp => (
            <StyledModal loading={fp.isSubmitting} icon={<FoodBankRounded/>} title={"Add spice"} onClose={props.onClose} children={
                <>
                    <FormElement title={"Title"} children={
                        <>
                            <FormikSingleLineInput autoFocus placeholder={"Turmeric / Curry / Pepper"} name={"title"} formikProps={fp}/>
                            <ErrorMessage formikProps={fp} name={"title"}/>
                        </>
                    }/>
                    <FormElement title={"Values"} caption={"Leave empty to use title as value"} children={
                        <TagListConfigurator formik={fp}/>
                    }/>
                    <FormElement
                        title={"Additional value source"}
                        caption={"Substitute values. Adds distinct values to the value-pool"}
                        children={
                            <FileInput onSubmit={ctx => {
                                fp.setFieldValue("sourceFile", ctx.file);
                            }}/>
                        }
                    />
                </>
            } footer={(
                <Grid style={{ width: "100%" }}>
                    <ButtonBase text={"Add"} baseProps={{
                        onClick: e => fp.handleSubmit(e)
                    }}/>
                </Grid>
            )}/>
        )}/>
    );
}
