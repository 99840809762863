import React, {useRef, useState} from "react";
import {PromptRendererBaseProps} from "./PromptRendererBaseProps";
import {PromptShardComponent} from "./PromptShardComponent";
import {PromptShardType} from "./PromptShardType";
import styled from "styled-components";
import {PromptShard} from "./PromptShard";
import { ReactMouseSelect } from 'react-mouse-select';
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {Text} from "../../../triton/components/typography/Text";
import {useTriton} from "../../../triton/TritonHooks";
import {ClipboardCopyButton} from "../ClipboardCopyButton";
import {FlatIconButton} from "../FlatIconButton";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    CloseRounded,
    PlusOneRounded,
    RemoveRounded
} from "@mui/icons-material";
import {PromptRenderer} from "./PromptRenderer";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";

const StyledPromptChipRenderer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  .chip-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
  }
  
  .chip-selection-toolbar {
    .selection-header-toolbar {
      display: grid;
      grid-template-columns: auto min-content;
      align-items: center;
      
      .left-section, .right-section {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
      }
      
      .left-section {
        overflow: scroll;
      }
    }
  }
`;

export type PromptChipRendererProps = PromptRendererBaseProps;

export type PromptChipRendererState = {
    selection: Array<number>,
    isShowingAllChips: boolean
}

export const PromptChipRenderer: React.FC<PromptChipRendererProps> = props => {
    const t = useTriton();
    const [state, setState] = useState<PromptChipRendererState>({
        selection: [],
        isShowingAllChips: false
    })

    const isDirtySelection = state.selection.length > 0;
    const promptShards = props.originalPrompt
        .split(",")
        .map(s => s.trim())
        .filter(s => s.length > 0)
        .map(s => stringToPromptShard(s));

    const collapsedVisiblePromptCount = 10;
    const hiddenPrompt = state.isShowingAllChips ? 0 : promptShards.length - collapsedVisiblePromptCount;
    const isHidingShards = !state.isShowingAllChips && hiddenPrompt > 0

    const clearSelection = () => {
        setState(prevState => ({ ...prevState, selection: [] }));
    }

    return (
        <StyledPromptChipRenderer>
            <div className={"chip-container"}>
                { promptShards.slice(0, state.isShowingAllChips ? undefined : collapsedVisiblePromptCount).map((s, i) => {
                    return (
                        <PromptShardComponent
                            key={i}
                            selected={state.selection.includes(i)}
                            shard={s}
                            onClick={event => {
                                const isAlreadySelected = state.selection.includes(i);
                                const isSelectionClick = event.ctrlKey;
                                if (isSelectionClick) {
                                    // Additive click
                                    const crudeNewSelection = [...state.selection, i].sort();
                                    const index = crudeNewSelection.indexOf(i);
                                    const lastIndex = Math.max(index - 1, 0);
                                    const lastSelectedIndex = crudeNewSelection[lastIndex] ?? 0
                                    const range = Array
                                        .from(Array(i - lastSelectedIndex + 1).keys())
                                        .map(n => n + lastSelectedIndex)
                                    console.log("i", index, "l-i", lastIndex)
                                    console.log("range", range)
                                    setState(prevState => ({
                                        ...prevState,
                                        selection: Array.from(new Set([...prevState.selection, ...range].sort()))
                                    }))
                                }
                                else setState(prevState => ({
                                    ...prevState,
                                    selection: isAlreadySelected ?
                                        prevState.selection.filter(v => v != i) :
                                        [...prevState.selection, i].sort()
                                }))
                            }}
                        />
                    );
                }) }

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }} children={
                    isHidingShards ? (
                        <Text fs={13} col={t.col("fg_muted")} fw={"lighter"} t={`+${hiddenPrompt}`} style={{
                            cursor: "pointer"
                        }} onClick={() => {
                            setState(prevState => ({
                                ...prevState,
                                isShowingAllChips: true
                            }))
                        }}/>
                    ) : (
                        promptShards.length - collapsedVisiblePromptCount > 0 && (
                            <Text fs={13} col={t.col("fg_muted")} fw={"lighter"} t={`collapse`} style={{
                                cursor: "pointer",
                                userSelect: "none"
                            }} onClick={() => {
                                setState(prevState => ({
                                    ...prevState,
                                    isShowingAllChips: false
                                }))
                            }}/>
                        )
                    )
                }/>
            </div>

            <TransitionGroup children={isDirtySelection && (
                <Collapse children={
                    <div className={"chip-selection-toolbar"}>

                        {/*
                        <div className={"selection-header-toolbar"}>
                            <div className={"left-section"}>
                                <MainTypography text={"Selection"} noSelect/>
                            </div>

                            <div className={"right-section"}>
                                <FlatIconButton tooltip={"Copy selection"} children={
                                    <ClipboardCopyButton
                                        color={t.col("fg_muted")}
                                        copyValueProducer={() => state.selection
                                            .map(tokenIndex => promptShards[tokenIndex].original)
                                            .join(", ")
                                        }
                                    />
                                }/>

                                <FlatIconButton tooltip={"Clear selection"} children={
                                    <CloseRounded
                                        onClick={clearSelection}
                                    />
                                }/>
                            </div>
                        </div>
                        */}

                        <PromptRenderer
                            title={"Selection"}
                            toolbarAppendix={() => (
                                <FlatIconButton tooltip={"Clear selection"} children={
                                    <CloseRounded
                                        onClick={clearSelection}
                                    />
                                }/>
                            )}
                            originalPrompt={state.selection
                                .map(tokenIndex => promptShards[tokenIndex].original)
                                .join(", ")
                            }
                        />

                        <TransitionGroup children={state.selection.length === 1 && (
                            <Collapse children={
                                <div>
                                    <div className={"selection-header-toolbar"}>
                                        <div className={"left-section"}>
                                            <MainTypography text={"Keyword-info"} noSelect/>
                                        </div>

                                        <div className={"right-section"}>
                                            <FlatIconButton tooltip={"Increase attention"} children={
                                                <ArrowUpwardRounded
                                                    fontSize={"small"}
                                                    onClick={() => {}}
                                                />
                                            }/>
                                            <FlatIconButton tooltip={"Decrease attention"} children={
                                                <ArrowDownwardRounded
                                                    fontSize={"small"}
                                                    onClick={() => {}}
                                                />
                                            }/>
                                        </div>
                                    </div>

                                    <DescriptiveTypography noSelect text={
                                        state.selection
                                            .map(tokenIndex => promptShards[tokenIndex].original)
                                            .join(", ")
                                    }/>
                                </div>
                            }/>
                        )}/>
                    </div>
                }/>
            )}/>

            {/* Selection core :: todo -> implement */}
            {/*
            <ReactMouseSelect
                containerRef={chipContainerRef}
                portalContainer={borderSelectionContainer}
                itemClassName={selectableChipClassName}
                finishSelectionCallback={(items) => {

                }}
            />
            */}
        </StyledPromptChipRenderer>
    );
}

function stringToPromptShard(s: string): PromptShard {
    let text = s;
    let strength = 1;
    let type = PromptShardType.TEXT;

    if (text.startsWith("(")) {
        text = text.slice(1, -1);
        const [displayString, strengthString] = text.split(":");
        text = displayString;
        strength = Number(strengthString);
    } else if (text.startsWith("<")) {
        type = PromptShardType.LORA;
        text = text.slice(1, -1);
        const [displayString, strengthString] = text.split(":").slice(1);
        text = displayString;
        strength = Number(strengthString);
    }

    return ({
        strength: strength,
        type: type,
        text: text,
        original: s
    });
}
