import {FC, useEffect, useRef} from "react";
import {Pos} from "./Pos";
import {Node} from "../backend/Node";
import {useStaticState} from "../../ardai/hooks/StaticStateHook";
import Draggable, {DraggableData} from "react-draggable";
import {NodeDisplay} from "./NodeDisplay";
import {useNodeCanvasBackend} from "./NodeCanvasBackend";
import {NodeCanvasBackendEventTypes} from "./NodeCanvasBackendEventTypes";
import {NodeDragNoticeSyntheticEvent} from "./events/native/synthetic/DragNoticeSyntheticEvent";
import {useEventListener} from "@mantine/hooks";
import {useForceRenderFunc} from "../../ForceRerenderHook";

export type NodeCanvasObjectProps = {
    node: Node
}

export type NodeCanvasObjectState = {
    position: Pos
}

export const NodeCanvasObject: FC<NodeCanvasObjectProps> = props => {
    const backend = useNodeCanvasBackend();
    const node = props.node;
    const id = node.id;

    const forceRender = useForceRenderFunc();

    const [state, ctx] = useStaticState<NodeCanvasObjectState>({
        id: `NodeCanvasObject-${id}`,
        initial: {
            position: {
                x: 0,
                y: 0
            }
        }
    }).stateWithCtx;

    useEffect(() => {
        backend.events.notify(NodeCanvasBackendEventTypes.NODE_POSITION_UPDATE, undefined);
    }, [state.position]);

    const htmlWrapperNode = useRef<HTMLSpanElement>(null);

    // TODO: Rerender on width change
    useEffect(() => {
        const rect = htmlWrapperNode.current!.getBoundingClientRect();
        let w = rect.width, h = rect.height;
        const snapInterval = 10;
        const bleedW = w % snapInterval, bleedH = h % snapInterval;
        if (bleedW > 0) w = w - bleedW + snapInterval;
        if (bleedH > 0) h = h - bleedH + snapInterval;

        // htmlWrapperNode.current!.style.width = `${w}px`;
        // TODO: Reactivate
        // htmlWrapperNode.current!.style.minWidth = `${w}px`;

        // TODO: Check if viable
        // htmlWrapperNode.current!.style.height = `${h}px`;
    }, [])

    // const nodeRef = useRef(document.getElementById("_node-canvas"));

    const dragNoticeEventListenerRef = useRef<any>((evt: CustomEvent<DraggableData>) => {
        const data = evt.detail;
        const isThisATarget = htmlWrapperNode.current?.classList.contains("group-selected");
        if (!isThisATarget) return;
        ctx.update(prevState => ({
            position: {
                x: prevState.position.x + data.deltaX,
                y: prevState.position.y + data.deltaY
            }
        }));
    });

    useEffect(() => {
        window.addEventListener(NodeDragNoticeSyntheticEvent.name, dragNoticeEventListenerRef.current);
        return () => {
            window.removeEventListener(NodeDragNoticeSyntheticEvent.name, dragNoticeEventListenerRef.current);
        }
    }, [])

    return (
        <Draggable
            handle={".handle"}
            // nodeRef={nodeRef}
            // defaultPosition={state.position}
            position={state.position}
            // position={{
            //     x: 0,
            //     y: 0
            // }}
            grid={[10, 10]}

            // enableUserSelectHack={true}

            onDrag={(e, data) => {
                ctx.update(prevState => ({
                    position: {
                        x: prevState.position.x + data.deltaX,
                        y: prevState.position.y + data.deltaY
                    }
                }));

                backend.events.notify(NodeCanvasBackendEventTypes.NODE_POSITION_UPDATE_DELTA, undefined);
                window.dispatchEvent(NodeDragNoticeSyntheticEvent.create(data));
            }}
            onStop={(e, data) => {
                ctx.update({
                    position: {
                        x: data.x,
                        y: data.y
                    }
                })
            }}
            children={
                <span ref={htmlWrapperNode} className={"trait-selectable"} style={{
                    position: "absolute",
                    backgroundColor: "transparent"
                }} children={
                    <NodeDisplay node={node}/>
                }/>
            }
        />
    );
}
