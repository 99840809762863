import {IImage} from "./IImage";
import {webDB} from "./WebAPIDB";

export class DexieImage implements IImage<DexieImage> {

    constructor(
        private readonly _id: string
    ) {}

    get id(): string {
        return this._id;
    }

    async src(): Promise<Blob> {
        const img = await webDB.images.get(this.id);
        return img?.data!;
    }

    writeSrc(newSrc: Blob): DexieImage {
        webDB.images.update(this.id, {
            data: newSrc
        });
        return this;
    }
}
