import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {StateEventTypes} from "../../../../StateEventTypes";
import _ from "lodash";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {ButtonGroup} from "../../../../ardai/components/ButtonGroup";
import {Tag} from "../../../../ardai/components/Tag";
import {v4} from "uuid";

export const BitField: NodeSetupInfo = {
    label: "",
    classname: "input.bit-field-input-debug",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "input.bit-field-input-debug",
        label: `${parameters.get("length")}-Bit field`,
        defOutPins: ["b"],
        state: {
            bits: parameters.get("initial")
        },
        init: function () {
            this.state.observer.observe(StateEventTypes.STATE_UPDATE).on(() => {
                this.pins.out("b").write(this.state.state.bits);
            });
        },
        customRenderer: node => (
            <div style={{
                padding: "8px 0",
                gap: 4,
                display: "grid"
            }}>
                <div style={{
                    width: "100%",
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                    gridTemplateColumns: `repeat(${parameters.get("length")}, 1fr)`
                }} children={
                    _.range(0, parameters.get("length")).map(idx => {
                        return (
                            <DescriptiveTypography text={`${1 << idx}`} style={{
                                whiteSpace: "nowrap",
                                fontSize: 8,
                                textAlign: "center"
                            }}/>
                        );
                    }).reverse()
                }/>
                <ButtonGroup children={
                    _.range(0, parameters.get("length")).map(idx => {
                        const bit = node.state.state.bits >> idx & 0x1;
                        return (
                            <Tag tag={`${bit}`} active={Boolean(bit)} onClick={() => {
                                node.state.update({
                                    bits: node.state.state.bits ^ (1 << idx)
                                })
                            }}/>
                        );
                    }).reverse()
                }/>

                <DescriptiveTypography text={`= 0x${node.state.state.bits}`} style={{
                    whiteSpace: "nowrap"
                }}/>
            </div>
        )
    })
}
