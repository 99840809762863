import {SetStateAction, useEffect, useRef, useState} from "react";
import {StateDispatcher} from "../../ship/test/core/StateDispatcher";
import {StandaloneObservable} from "../webapi/pubsub/StandaloneObservable";
import _ from "lodash";
import {v4} from "uuid";

export type StaticStateExposedConfigProps<T = {}> = T & {
    componentExposedStateConfig?: {
        static?: {
            enabled?: boolean,
            id: string
        }
    }
}

export type StaticStateMiddlewareEventTypes =
    "received_influx_update" | "triggered_influx_update"

export interface IStaticStateMiddleware<T> {
    readonly id: string;
    loadState(): Promise<T>;
    saveState(state: T): Promise<void>,

    observer: StandaloneObservable<StaticStateMiddlewareEventTypes>
}

interface ISerializationAdapter<LiveType = any, SerializedType = string> {
    serialize(live: LiveType): SerializedType
    deserialize(serialized: SerializedType): LiveType
}

class JsonSerializationAdapter implements ISerializationAdapter {

    serialize(live: any): string {
        return JSON.stringify(live, null, 0)
    }

    deserialize(serialized: string): any {
        return JSON.parse(serialized);
    }
}

export type LocalStorageStaticStateMiddlewareConfig = {
    storeInitialValueOnStorageMiss: boolean
}

const defaultLocalStorageStaticStateMiddlewareConfig: LocalStorageStaticStateMiddlewareConfig = {
    storeInitialValueOnStorageMiss: false
}

class LocalStorageStaticStateMiddleware<T> implements IStaticStateMiddleware<T> {

    private readonly config: LocalStorageStaticStateMiddlewareConfig;

    public readonly observer: StandaloneObservable<StaticStateMiddlewareEventTypes>
        = new StandaloneObservable<StaticStateMiddlewareEventTypes>;

    constructor(
        readonly id: string,
        private initial: T,
        private readonly serializationAdapter: ISerializationAdapter<T> = new JsonSerializationAdapter,

        config: Partial<LocalStorageStaticStateMiddlewareConfig> = {}
    ) {
        this.config = {
            ...defaultLocalStorageStaticStateMiddlewareConfig,
            ...config
        };
    }

    async loadState(): Promise<T> {
        const retrieved = window.localStorage.getItem(this.id);
        if (retrieved === null) {
            if (this.config.storeInitialValueOnStorageMiss) {
                await this.saveState(this.initial);
            }
            return this.initial;
        }
        return this.serializationAdapter.deserialize(retrieved);
    }

    async saveState(state: T): Promise<void> {
        const prev = window.localStorage.getItem(this.id);
        const next = this.serializationAdapter.serialize(state);
        if (prev === next) return;
        window.localStorage.setItem(this.id, next);

        // TODO: Check if better be removed
        // this.handleStateChange("triggered", state);
    }

    private handleStateChange(type: "triggered" | "received", liveState: T) {
        switch (type) {
            case "triggered":
                this.observer.notify("triggered_influx_update", liveState);
                break;
            case "received":
                this.observer.notify("received_influx_update", liveState);
                break;
        }
    }
}

export interface IStaticStateCtx<T> {
    readonly id: string;
    get state(): T;
    readonly setState: StateDispatcher<T>;
    get react(): [T, StateDispatcher<T>];
    get stateWithCtx(): [T, IStaticStateCtx<T>];
    update(updates: Partial<T> | ((prevState: T) => Partial<T>)): void;
    reverseBool(propertyName: keyof T): void,

    std: {
        array: {
            push<V = any>(propertyName: keyof T, ...elements: Array<V>): void,
            toggleElementPresence<V = any>(propertyName: keyof T, element: V): void,
        }
    },

    updateNumber(propertyName: keyof T, update: number | ((prevNumber: number) => number), options?: Partial<{
        min: number,
        max: number
    }>): void
}

export type StaticStateConfig<T> = {
    readonly id?: string, // TODO: Maybe need to rename id to staticId
    readonly initial: T,
    readonly staticMode?: boolean
    readonly middleware?: IStaticStateMiddleware<T>
}

export type StaticStateHookState<T> = {
    settled: boolean,
    middleware: IStaticStateMiddleware<T>
}

export const useStaticState: <T = any>(cfg: StaticStateConfig<T>) => IStaticStateCtx<T> = <T = any>(cfg: StaticStateConfig<T>) => {
    const [state, setState] = useState<T>(cfg.initial);
    const isStatic = (cfg.staticMode && cfg.id !== undefined) ?? true; // TODO: make true again ~ false is just for debugging

    const idRef = useRef(cfg.id ?? v4());
    const id = idRef.current;

    // TODO: Convert to ref object
    const [hookState, setHookState] = useState<StaticStateHookState<T>>({
        settled: false,
        middleware: cfg.middleware ?? new LocalStorageStaticStateMiddleware(id, cfg.initial)
    });

    // patch initial state (if in static mode)
    useEffect(() => {(async () => {
        if (isStatic) {
            const loadedState = await hookState.middleware.loadState();
            setState(loadedState);
        }

        setHookState(prevState => ({
            ...prevState,
            settled: true
        }));
    })()}, []);

    // update backend
    useEffect(() => {
        if (!isStatic || !hookState.settled) return;
        // this might not be in correct order?
        hookState.middleware.saveState(state).then(() => {});
    }, [state]);

    // TODO: Handle duplex updates from the middleware->frontend

    return {
        id,
        get state(): T {
            return state;
        },
        setState: (value: SetStateAction<T>) => setState(value),
        get react(): [T, StateDispatcher<T>] {
            return [state, setState as StateDispatcher<T>]
        },
        get stateWithCtx(): [T, IStaticStateCtx<T>] {
            return [state, this];
        },
        update(updates: Partial<T> | ((prevState: T) => Partial<T>)) {
            setState(prevState => ({
                ...prevState,
                ...(typeof updates === "function") ? (<Function>updates)(prevState) : updates
            }));
        },
        reverseBool(propertyName: keyof T) {
            // TODO: Perform type check
            setState(prevState => ({
                ...prevState,
                [propertyName]: !(prevState[propertyName] as boolean ?? false)
            }));
        },
        updateNumber(propertyName: keyof T, update: number | ((prevNumber: number) => number), options?: Partial<{
            min: number;
            max: number
        }>) {
            setState(prevState => {
                let currentNumber = prevState[propertyName] as number ?? 0;
                if (typeof update === "number") {
                    currentNumber = currentNumber + update;
                } else {
                    currentNumber = update(currentNumber);
                }

                currentNumber = _.clamp(currentNumber, options?.min ?? Number.MIN_VALUE, options?.max ?? Number.MAX_VALUE);

                return ({
                    ...prevState,
                    [propertyName]: currentNumber
                });
            });
        },
        std: {
            array: {
                push<V = any>(propertyName: keyof T, ...elements: Array<V>) {
                    setState(prevState => ({
                        ...prevState,
                        [propertyName]: [...elements, ...prevState[propertyName] as Array<V>]
                    }))
                },
                toggleElementPresence<V = any>(propertyName: keyof T, element: V) {
                    setState(prevState => {
                        const targetArray = prevState[propertyName] as Array<V>;
                        if (targetArray.includes(element)) {
                            // Remove element
                            return ({
                                ...prevState,
                                [propertyName]: targetArray.filter(e => e !== element)
                            });
                        } else {
                            // Add element
                            return ({
                                ...prevState,
                                [propertyName]: [element, ...targetArray]
                            });
                        }
                    })
                }
            }
        }
    } as IStaticStateCtx<T>;
}

/**
 * Yeah... I know...
 * I'll make "useAdvancedState" its own thing in the **near** future...
 * *(hopefully)*
 */
export {
    useStaticState as useAdvancedState
}
