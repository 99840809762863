import React, {useState} from "react";
import styled from "styled-components";
import {motion} from "framer-motion";
import {NotificationsRounded} from "@mui/icons-material";
import {Link, useLocation, useNavigate} from "react-router-dom";

const StyledNavigationComponent = styled.div`
  // background-color: rgba(16, 16, 22, 0.66);
  background-color: #0B0B0E;
  width: 100%;
  min-height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-items: center;
  --iOS-navigation-bleed: 8px;
  padding: 16px 0 calc(16px + var(--iOS-navigation-bleed)) 16px;
`;

export type NavigationComponentProps = {

}

export const NavigationComponent: React.FC<NavigationComponentProps> = props => {
    const location = useLocation()
    const urlBase = "/b/";
    const locationSection = location.pathname.replace(urlBase, "").split("/")[0] ?? "";
    const navigate = useNavigate();

    return (
        <StyledNavigationComponent>

            {/* Feed / home button */}
            <span/>

            {/* Search button */}
            <span/>

            {/* '+' button ~ upload new content to the platform */}
            <span/>

            <motion.div
                whileTap={{ scale: .9 }}
                onClick={() => navigate("notifications")}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 4
                }}
            >
                <NotificationsRounded/>
                <motion.div
                    variants={{
                        open: {
                            scale: 1,
                            y: 0
                        },
                        closed: {
                            scale: 0,
                            y: -10
                        }
                    }}
                    animate={locationSection === "notifications" ? "open" : "closed"}
                    style={{
                        width: 6,
                        aspectRatio: "1 / 1",
                        borderRadius: 10,
                        backgroundColor: "white"
                    }}
                />
            </motion.div>

            <motion.div
                whileTap={{ scale: .9 }}
                onClick={() => navigate("me")}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 4
                }}
            >
                <img src={"https://pbs.twimg.com/profile_images/1661034678751510528/BGUMi8Sd_400x400.jpg"} style={{
                    width: 30,
                    aspectRatio: "1 / 1",
                    borderRadius: 80
                }} alt={"pfp"}/>
                <motion.div
                    variants={{
                        open: {
                            scale: 1,
                            y: 0
                        },
                        closed: {
                            scale: 0,
                            y: -10
                        }
                    }}
                    animate={locationSection === "me" ? "open" : "closed"}
                    style={{
                        width: 6,
                        aspectRatio: "1 / 1",
                        borderRadius: 10,
                        backgroundColor: "white"
                    }}
                />
            </motion.div>

        </StyledNavigationComponent>
    );
}
