import React, {useContext} from "react";
import styled from "styled-components";
import {BoltRounded, CloseRounded, CommitRounded, CopyAllRounded, InfoRounded} from "@mui/icons-material";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {IconButton} from "../../components/IconButton";
import {DerbyshireStateContext} from "./DerbyshireMain";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import ReactJson from "react-json-view";
import {MetaBasicEntry} from "../../components/MetaBasicEntry";
import {MenuDivider} from "@szhsin/react-menu";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {ButtonGroup} from "../../components/ButtonGroup";
import {Utils} from "../../../base/Utils";
import {Menu} from "../../components/Menu";
import {CheckMenuButton, MenuButton} from "../../components/MenuButton";

const StyledLogEventViewer = styled.div`
  background-color: rgb(16, 16, 22);
  border-radius: 8px;
  padding: 1rem;
  display: grid;
  grid-template-rows: min-content auto min-content;
  width: 100%;
  height: 100%;
  gap: 8px;
  user-select: none;
  
  .log-event-viewer-header {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    
    .header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
    
    .header-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }

  .log-event-viewer-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;
  }

  .log-event-viewer-footer {
    
  }
`;

export const LogEventViewer: React.FC = props => {
    const [state, setState] = useContext(DerbyshireStateContext);
    const log = state.openLogEntry;

    if (log === undefined) {
        return (
            <>
            </>
        );
    }

    return (
        <StyledLogEventViewer>
            <div className={"log-event-viewer-header"}>
                <div className={"header-left"}>
                    <CommitRounded style={{ width: "20px" }}/>
                    <MainTypography text={"Log event"}/>
                </div>
                <div className={"header-right"}>
                    <ButtonGroup>
                        <IconButton size={"small"} tooltip={"Copy log"} children={<CopyAllRounded/>} onClick={() => {
                            Utils.copyTextToClipboard(JSON.stringify(log, null, 2));
                        }}/>
                        <IconButton size={"small"} tooltip={"Toggle additional info"} variant={state.showInfosInLogEventViewer ? "primary" : "default"} children={<InfoRounded/>} onClick={() => {
                            setState(prevState => ({
                                ...prevState,
                                showInfosInLogEventViewer: !prevState.showInfosInLogEventViewer
                            }));
                        }}/>
                    </ButtonGroup>

                    <Menu>
                        <MenuButton icon={<CopyAllRounded/>} text={"Copy message"} onSelect={() => Utils.copyTextToClipboard(log?.message)}/>
                        <MenuButton icon={<CopyAllRounded/>} text={"Copy beautified log"} onSelect={() => Utils.copyTextToClipboard(JSON.stringify(log, null, 2))}/>
                        <MenuButton icon={<CopyAllRounded/>} text={"Copy log"} onSelect={() => Utils.copyTextToClipboard(JSON.stringify(log))}/>
                        <MenuDivider/>
                        <CheckMenuButton text={"View additional info"} checked={state.showInfosInLogEventViewer} onSelect={() => {
                            setState(prevState => ({
                                ...prevState,
                                showInfosInLogEventViewer: !prevState.showInfosInLogEventViewer
                            }));
                        }}/>
                    </Menu>

                    <IconButton size={"small"} children={<CloseRounded/>} onClick={() => {
                        setState(prevState => ({
                            ...prevState,
                            openLogEntry: undefined
                        }));
                    }}/>
                </div>
            </div>

            <div className={"log-event-viewer-body"}>
                <TransitionGroup>
                    { state.showInfosInLogEventViewer && (
                        <Collapse>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px"
                            }}>
                                <MainTypography text={"Info"}/>
                                <MetaBasicEntry enableCopy={false} title={"Timestamp"} value={log.timestamp}/>
                                <MetaBasicEntry enableCopy={false} title={"Level"} value={`${log.level}`}/>
                            </div>
                        </Collapse>
                    ) }
                </TransitionGroup>

                <MainTypography text={"Message"}/>

                { (typeof log.message === "object") ? (
                    <ReactJson
                        src={JSON.parse(JSON.stringify(log.message))}
                        theme={"twilight"}
                        style={{ backgroundColor: "transparent" }}
                    />
                ) : (
                    <div style={{
                        borderRadius: ".3rem",
                        backgroundColor: "#1e202a",
                        padding: "4px 8px",
                        userSelect: "text"
                    }}>
                        <DescriptiveTypography text={`${log.message}`}/>
                    </div>
                ) }
            </div>

            <div className={"log-event-viewer-footer"}>

            </div>
        </StyledLogEventViewer>
    );
}
