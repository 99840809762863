import React, {useContext, useState} from "react";
import {TopicSelectorStateContext} from "./TopicSelectorStateContext";
import {AddRounded, FoodBankRounded} from "@mui/icons-material";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {IconButton} from "../../components/IconButton";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {ButtonModalCompound} from "../../components/ButtonModalCompound";
import {v4} from "uuid";
import {SpicePreviewCard} from "./SpicePreviewCard";
import {Menu} from "../../components/Menu";
import {CheckMenuButton, MenuButton} from "../../components/MenuButton";
import {SpiceCreationDialog} from "./SpiceCreationDialog";
import {MenuDivider} from "@szhsin/react-menu";

type SpiceViewState = {
    showExcluded: boolean
}

export const SpiceView: React.FC = props => {
    const [state, setState] = useContext(TopicSelectorStateContext);

    const [ls, setLs] = useState<SpiceViewState>({
        showExcluded: true
    });

    let visibleSpices = state.spices;
    if (!ls.showExcluded) {
        visibleSpices = visibleSpices.filter(s => !s.excluded);
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: 8
        }}>
            <div style={{
                display: "flex",
                gap: 8,
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <div style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "center"
                }}>
                    <FoodBankRounded style={{ width: 18 }}/>
                    <MainTypography text={"Spices"} noSelect/>
                </div>
                <div style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "center"
                }}>
                    <ButtonModalCompound
                        button={
                            <IconButton size={"small"} children={<AddRounded/>}/>
                        }
                        modalContent={ctx => (
                            <SpiceCreationDialog onClose={ctx.close}/>
                        )}
                    />
                    <Menu>
                        <CheckMenuButton text={"Show excluded spices"} checked={ls.showExcluded} onSelect={() => {
                            setLs(prevState => ({
                                ...prevState,
                                showExcluded: !prevState.showExcluded
                            }))
                        }}/>
                        <MenuDivider/>
                        <MenuButton text={"Load default spices"} onSelect={async () => {
                            // TODO: Import mediums
                            const flavors = await import("./assets/flavors.json");
                            const values: Array<string> = [];
                            for (let i = 0; i < flavors.default.length; i++) values.push(flavors.default[i]);

                            setState(prevState => ({
                                ...prevState,
                                spices: [...prevState.spices, {
                                    id: v4(),
                                    values: [...values],
                                    mandatory: false,
                                    title: "Default flavors"
                                }]
                            }));
                        }}/>
                        <MenuDivider/>
                        <MenuButton text={"Include all"} onSelect={() => {
                            setState(prevState => ({
                                ...prevState,
                                spices: prevState.spices.map(s => {
                                    s.excluded = false;
                                    return s;
                                })
                            }))
                        }}/>
                        <MenuButton text={"Exclude all"} onSelect={() => {
                            setState(prevState => ({
                                ...prevState,
                                spices: prevState.spices.map(s => {
                                    s.excluded = true;
                                    return s;
                                })
                            }))
                        }}/>
                        <MenuButton text={"Exclude all non-mandatory"} onSelect={() => {
                            setState(prevState => ({
                                ...prevState,
                                spices: prevState.spices.map(s => {
                                    if (!s.mandatory) {
                                        s.excluded = true;
                                    }
                                    return s;
                                })
                            }))
                        }}/>
                    </Menu>
                </div>
            </div>

            <div style={{
                display: "flex",
                gap: 4,
                flexDirection: "column"
            }}>
                <TransitionGroup children={visibleSpices.map(spice => (
                    <Collapse key={spice.id} children={
                        <div style={{ marginBottom: 8 }} children={
                            <SpicePreviewCard spice={spice}/>
                        }/>
                    }/>
                ))}/>
            </div>
        </div>
    );
}
