import {StateDispatcher} from "../../../test/core/StateDispatcher";

export class StateProxy<T> {

    private _state: T;

    private stateDispatcher: StateDispatcher<T>;

    private stateProxy?: T

    private stateDispatcherProxy?: StateDispatcher<T>;

    private isBranched: boolean = false;

    constructor(state: T, stateDispatcher: StateDispatcher<T>) {
        this._state = state;
        this.stateDispatcher = stateDispatcher;
    }

    public syncToReact(state: T, stateDispatcher: StateDispatcher<T>) {
        this._state = state;
        this.stateDispatcher = stateDispatcher;
    }

    /**
     *
     * ((prevState: T) => T) := function
     * T := object
     */
    public branch(): StateProxy<T> {
        this.stateProxy = { ...this._state };
        this.stateDispatcherProxy = (v: ((prevState: T) => T) | T) => {
            switch (typeof v) {
                case "object":
                    this.stateProxy = v as T;
                    break;
                case "function":
                    const pS = this.stateProxy as T;
                    this.stateProxy = (v as (prevState: T) => T)(pS);
                    break;
            }
        }
        this.isBranched = true;
        return this;
    }

    public commit(): StateProxy<T> {
        this.stateDispatcher(this.stateProxy as T);
        this.stateProxy = undefined;
        this.stateDispatcherProxy = undefined;
        this.isBranched = false;
        return this;
    }

    public get state(): T {
        return this.isBranched ? this.stateProxy as T: this._state;
    }

    public get setState(): StateDispatcher<T> {
        return this.isBranched ? this.stateDispatcherProxy as StateDispatcher<T> : this.stateDispatcher;
    }
}
