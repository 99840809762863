import React, {useState} from "react";
import styled from "styled-components";
import {RoundedMainTextButton} from "../../components/RoundedMainTextButton";
import {BoardingActionHint} from "../../components/BoardingActionHint";
import {Flex} from "../../Flex";
import {useParams} from "react-router-dom";
import {TypoHeaderVisual} from "../../components/TypoHeaderVisual";
import {BoardingPageHeader} from "../../components/BoardingPageHeader";
import Dialog from "@mui/material/Dialog";
import {TransitionProps} from "react-transition-group/Transition";
import Slide from "@mui/material/Slide";
import {MobileSignupPage} from "../signup/MobileSignupPage";
import {BoardingFlowMode} from "./BoardingFlowMode";
import {MobileLoginPage} from "../login/MobileLoginPage";

const StyledMobileBoardingPage = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  
  & > div {
    transition: opacity 100ms;
    opacity: 1;
    &[data-hidden=true] {
      opacity: 0;
    }
  }
`;

const StyledForeground = styled.div`
  position: absolute;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 27px;
  display: flex;
  justify-content: space-between;
  gap: 39px;
  flex-direction: column;
  user-select: none;
`;

const StyledBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  user-select: none;
`;

const AuthorizationTrayTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} children={props.children} />;
});

type MobileBoardingPageState = {
    mode: BoardingFlowMode,
    modeChangedManually: boolean
}

type MobileBoardingPageContextData = {
    switchAuthFlow: (mode: BoardingFlowMode) => void
}

export const MobileBoardingPage: React.FC = props => {
    const { mode } = useParams();
    const bfmLiteral = (mode ?? BoardingFlowMode.INITIAL).toUpperCase() as keyof typeof BoardingFlowMode;
    const bfm = BoardingFlowMode[bfmLiteral];
    const pathWasUsed = mode !== undefined && bfm !== undefined;
    const [state, setState] = useState<MobileBoardingPageState>({
        mode: bfm,
        modeChangedManually: false
    });

    const isBoardingVisible = state.mode === BoardingFlowMode.INITIAL;

    const triggerAuth = (mode: BoardingFlowMode) => {
        let url = window.location.toString();
        window.history.pushState('', '', url.replace(
            BoardingFlowMode[state.mode].toLowerCase(), BoardingFlowMode[mode].toLowerCase()
        ));

        setState(prevState => ({
            ...prevState,
            mode: mode,
            modeChangedManually: true
        }));

    };

    const contextData: MobileBoardingPageContextData = {
        switchAuthFlow: am => triggerAuth(am)
    };

    return (
        <MobileBoardingPageContext.Provider value={contextData}>
            {/* Signup dialog page */}
            <Dialog
                TransitionComponent={AuthorizationTrayTransition}
                transitionDuration={pathWasUsed && !state.modeChangedManually ? 0 : undefined}
                open={state.mode === BoardingFlowMode.SIGNUP}
                children={<MobileSignupPage/>}
                fullScreen
            />

            {/* Login dialog page */}
            <Dialog
                TransitionComponent={AuthorizationTrayTransition}
                transitionDuration={pathWasUsed && !state.modeChangedManually ? 0 : undefined}
                open={state.mode === BoardingFlowMode.LOGIN}
                children={<MobileLoginPage/>}
                fullScreen
            />

            {/* Boarding page default layer */}
            <StyledMobileBoardingPage>
                <StyledForeground data-hidden={!isBoardingVisible}>
                    <Flex g={53} baseProps={{ style: { width: "100%", flexDirection: "column" } }}>
                        <BoardingPageHeader/>
                    </Flex>

                    <Flex g={46} baseProps={{
                        style: {
                            flexDirection: "column",
                        }
                    }}>
                        <TypoHeaderVisual
                            header={"Let's share art"}
                            descriptiveSubheader={"The largest ai-art community worldwide"}
                            text={"Join more than 1 million art enthusiasts and express your creativity freely"}
                        />

                        <Flex g={39} baseProps={{
                            style: {
                                width: "100%",
                                flexDirection: "column",
                            }
                        }}>
                            <RoundedMainTextButton
                                onClick={() => triggerAuth(BoardingFlowMode.SIGNUP)}
                                text={"Continue"}
                            />
                            <BoardingActionHint
                                onClick={() => triggerAuth(BoardingFlowMode.LOGIN)}
                                question={"Already have an account?"}
                                action={"Log in"}
                            />
                        </Flex>
                    </Flex>
                </StyledForeground>

                <StyledBackground data-hidden={!isBoardingVisible}>

                </StyledBackground>
            </StyledMobileBoardingPage>
        </MobileBoardingPageContext.Provider>
    );
}

export const MobileBoardingPageContext = React.createContext<MobileBoardingPageContextData | undefined>(undefined);
