import React, {CSSProperties, FC, ReactNode, useState} from "react";
import styled from "styled-components";
import {AppletApplication} from "./AppletApplication";
import {useStaticState} from "../../hooks/StaticStateHook";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {useKeyboardEvent} from "../../_/keyCommand/test/KeyCommandTest";
import {useTriton} from "../../../triton/TritonHooks";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {FlatIconButton} from "../FlatIconButton";
import {
    DisplaySettingsRounded,
    DragHandleRounded,
    FullscreenRounded,
    LanguageRounded,
    MoreHorizRounded, VisibilityOffRounded
} from "@mui/icons-material";
import {Tag} from "../Tag";
import {TabBar} from "../TabBar";
import {AnimatePresence} from "framer-motion";
import {motion} from "framer-motion";
import {Workspace} from "../Workspace";
import {DefaultButton} from "../DefaultButton";
import {ButtonGroup} from "../ButtonGroup";

const StyledApplet = styled.div`
  width: 400px;
  height: 100%;
  overflow-y: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0;
`;

export type AppletProps = {
    application: AppletApplication,
    style?: CSSProperties
}

export type AppletState = {
    viewMode: "normal" | "zen" | "fullscreen",
    settingsTab: string,
    active: boolean,
    blur: boolean
}

export const Applet: FC<AppletProps> = props => {
    const t = useTriton();

    const [state, ctx] = useStaticState<AppletState>({
        id: "applet-development",
        initial: {
            viewMode: "normal",
            settingsTab: "dashboard",
            active: true,
            blur: false
        }
    }).stateWithCtx;

    useKeyboardEvent("keydown", e => {
        if (e.key === "1") {
            ctx.update({
                viewMode: "normal"
            })
        } else if (e.key === "2") {
            ctx.update({
                viewMode: "zen"
            })
        }
    });

    return (
        <StyledApplet style={t.styled("layout_box", {
            ...props.style,
            // border: `1px solid`,
            ...t.styled("glass_layout_box")
        })}>

            <AppletQuickActionPane/>

            <TransitionGroup>
                { state.viewMode === "normal" && (
                    <Collapse>
                        <div style={{
                            padding: "1rem",
                            paddingBottom: 0,
                            gap: 4,
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "space-between"
                            }}>
                                {/* Left toolbar section */}
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 4
                                }}>
                                    <FlatIconButton children={
                                        <DisplaySettingsRounded sx={{ fontSize: 20 }}/>
                                    }/>
                                    <MainTypography noSelect text={"Applet"}/>

                                    <Tag tag={"#1"}/>
                                </div>
                                {/* Right toolbar section */}
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2
                                }}>
                                    <ButtonGroup>
                                        <Tag
                                            tag={state.blur ? "Blurred" : "Un-blurred"}
                                            applyActiveTextStyle
                                            active={state.blur}
                                            onClick={() => ctx.reverseBool("blur")}
                                        />
                                        <Tag
                                            tag={state.active ? "Active" : "Inactive"}
                                            applyActiveTextStyle
                                            active={state.active}
                                            onClick={() => ctx.reverseBool("active")}
                                        />
                                    </ButtonGroup>

                                    <FlatIconButton children={
                                        <LanguageRounded sx={{ fontSize: 20 }}/>
                                    }/>
                                    <FlatIconButton children={
                                        <FullscreenRounded sx={{ fontSize: 20 }}/>
                                    }/>
                                    <FlatIconButton children={
                                        <MoreHorizRounded sx={{ fontSize: 20 }}/>
                                    }/>
                                </div>
                            </div>

                            <TabBar
                                activeBar={state.settingsTab}
                                onTabChange={tab => ctx.update({ settingsTab: tab })}
                                tabs={[
                                    { id: "applet", title: "Applet" },
                                    { id: "dashboard", title: "Dashboard" },
                                    { id: "preferences", title: "Preferences" },
                                    { id: "hooks", title: "Hooks" },
                                ]}
                            />
                        </div>
                    </Collapse>
                ) }
            </TransitionGroup>


            <div style={{
                padding: state.viewMode === "normal" ? "1rem" : 0,
                paddingTop: state.viewMode === "normal" ? 8 : 0,
                height: "100%",
                transition: "all 150ms"
            }}>
                <div style={{
                    borderRadius: state.viewMode === "normal" ? 16 : 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: t.col("layout_300"),
                    overflow: "hidden",
                    position: "relative"
                }}>
                    <div style={{
                        opacity: state.blur ? 1 : 0,
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        transition: "opacity .2s",
                        zIndex: 20,
                        userSelect: "none",
                        pointerEvents: state.blur ? "all" : "none",
                        background: "linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))",
                        backdropFilter: "blur(50px)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 16
                    }} children={
                        <VisibilityOffRounded style={{
                            fontSize: 40,
                            opacity: .75
                        }}/>
                    }/>

                    { state.active && (
                        props.application.children()
                    ) || (
                        <div style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <DescriptiveTypography text={":zzz:"} noSelect style={{
                                fontFamily: "monospace"
                            }}/>
                        </div>
                    ) }
                </div>
            </div>
        </StyledApplet>
    );
}

export const AppletQuickActionPane: FC = props => {

    const t = useTriton();

    const [state, setState] = useState({
        open: false
    });

    useKeyboardEvent("keydown", e => {
        if (e.altKey) {
            setState(prevState => ({ ...prevState, open: true }));
        }
    })

    useKeyboardEvent("keyup", e => {
        if (state.open && !e.altKey) {
            setState(prevState => ({ ...prevState, open: false }));
        }
    })

    return (
        <div style={{
            position: "absolute",
            zIndex: 10,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "all .25s",
            pointerEvents: state.open ? "auto" : "none",
            backgroundColor: state.open ? "rgba(0,0,0,0.5)" : "transparent"
        }}>
            <AnimatePresence>
                { state.open && (
                    <motion.div initial={{
                        scale: 0
                    }} animate={{
                        scale: 1
                    }} exit={{
                        scale: 0
                    }} style={{
                        width: "75%",
                        maxHeight: "75%",
                        height: "auto",
                        aspectRatio: "1 / 1",
                    }}>
                        <div style={t.styled("glass_layout_box", {
                            width: "100%",
                            height: "100%",
                            padding: "1rem",
                            display: "flex",
                        })}>
                            <MainTypography text={"Applet Quick Actions"} noSelect/>
                        </div>
                    </motion.div>
                ) }
            </AnimatePresence>
        </div>
    );
}
