import Dexie, {Table} from "dexie";
import {Project} from "../data/Project";
import {VFSElement} from "../data/VFSElement";
import {Image} from "../data/Image";
import {ISADBSettingsEntry} from "../data/ISADBSettingsEntry";
import {StyleData} from "../data/StyleData";
import {MixinData} from "../../imageSorter/sdInterface/MixinData";

export class WebAPIDB extends Dexie {

    projects!: Table<Project>;

    vfsElements!: Table<VFSElement>;

    images!: Table<Image>;

    sdInterfaceResults!: Table<Image>;

    settings!: Table<ISADBSettingsEntry>;

    styles!: Table<StyleData>

    mixins!: Table<MixinData>

    constructor() {
        super("ImageSorterAppDB");
        this.version(15).stores({
            projects: 'id, title, tags, resources, meta, previewImageID, description, metaProperties',
            vfsElements: 'id, parentID, title, targetID, subElements, projects, type, path, fullPath',
            images: 'id, data, previewData, tags, favourite, creationTimestamp, description, contentSensitivity',
            sdInterfaceResults: 'id, data, tags',
            settings: 'id, value',
            styles: 'id, title, description, meta, previewID, additionalPreviewIDs, note',
            mixins: 'id, key, type, target'
        });

        this.vfsElements.get("root").then(vfsElem => {
            if (vfsElem !== undefined) return;

            this.vfsElements.add({
                id: "root",
                subElements: [],
                parentID: "",
                title: "Root",
                type: "folder",
                path: "",
                fullPath: "Root"
            });
        });
    }
}

export const webDB = new WebAPIDB();
