import {Entity} from "../../ecs/entity/Entity";
import {PIDController} from "../../math/PIDController";
import {Std} from "../../../../../Std";
import {castEntity, castEntityFull} from "../../SimStd";
import {AzypodState} from "../azypod/AzypodEntity";
import {ShipMainEntity} from "../ship/ShipMainEntity";
import {MasterWorkerField} from "../../MasterWorkerField";
import {NamedGenerator} from "../../NamedGenerator";
import {EnvironmentSimulationEntity} from "../ambient/EnvironmentSimulationEntity";
import {newStatusTrait, StatusUpdateMode} from "../../traits/status/AbstractStatusTrait";
import also = Std.also;
import {StateWithStatus} from "../../traits/status/StateWithStatus";
import {PIDTrait} from "../../traits/PIDTrait";

export type SteeringAPEntityState = StateWithStatus<{
    engaged: boolean,
    target: MasterWorkerField<NamedGenerator<number>>,
    manualHeading: number,
}>

export type StdSteeringTargetGeneratorNames = "manual" | "wind" | "current";

export class SteeringAPEntity extends Entity<SteeringAPEntityState> {

    static newFallbackTargetGenerator(heading: number = 0): NamedGenerator<number> {
        return {
            name: "fallback",
            get: () => heading
        }
    }

    constructor() {
        super("auto", {
            loggerEnabled: true,
            initialState: {
                manualHeading: 0,
                engaged: true,
                target: new MasterWorkerField<NamedGenerator<number>>(SteeringAPEntity.newFallbackTargetGenerator())
            },
            traits: [
                newStatusTrait(trait => {
                    const controller = this.getHeadingPIDController();
                    const err = controller.error ?? 0;
                    const errorMag = Math.abs(err);
                    if (errorMag === 0) return "On course";
                    else if (errorMag > 0 && errorMag <= .5) return "Minor course correcting";
                    else return "Correcting course";
                }, {
                    statusUpdateMode: StatusUpdateMode.PROACTIVE
                })
            ]
        });
    }

    init() {
        super.init();

        // this.switchTargetGenerator("manual");

        this.registerTrait("pid", new PIDTrait(also(new PIDController, function () {
            this.setGains(3, 2, 1);
            this.setOutputs(-10, 10, -360)
            this.setTarget(-16);
            this.tolerance = .25;
        })));
    }

    public getHeadingPIDController(): PIDController {
        return this.getTrait<PIDTrait>("pid").controller;
    }

    syncTick() {
        super.syncTick();



        const newTarget = this.state.target.master.get();

        // const controller: PIDController = this.state.headingController;
        const controller: PIDController = this.getHeadingPIDController();


        if (newTarget !== controller.target) {
            controller.setTarget(newTarget);
        }

        if (!this.state.engaged) return;
        const azypod = castEntity<AzypodState>(this.simulation.entity("port-azypod"));
        const ship = castEntityFull<ShipMainEntity>(this.simulation.entity("ship"));
        this.logger.log(ship.state.heading.theta)
        const courseCorrection = controller.run(ship.state.heading.theta);
        azypod.setStatePartially({
            requestedDeg: -(courseCorrection % 360)
        });
    }

    public offsetManualHeading(offset: number = 0) {
        this.setStatePartially(prevState => ({
            manualHeading: prevState.manualHeading + offset
        }));
    }

    public setManualHeading(heading: number = 0) {
        this.setStatePartially({
            manualHeading: heading
        });
    }

    private getTargetGenerator(genName: StdSteeringTargetGeneratorNames | string): NamedGenerator<number> {
        switch (genName) {
            case "manual": {
                return {
                    name: genName,
                    get: () => this.state.manualHeading
                };
            }
            case "wind": {
                return {
                    name: genName,
                    get: () => castEntityFull<EnvironmentSimulationEntity>(this.simulation.entity("env"))
                        .state.wind.theta
                };
            }
            case "current": {
                return {
                    name: genName,
                    get: () => 230
                };
            }
            default: {
                return SteeringAPEntity.newFallbackTargetGenerator();
            }
        }
    }

    public switchTargetGenerator(genName: StdSteeringTargetGeneratorNames | string, toggleOffMechanism: boolean = true) {
        const curGenName = this.state.target.master.name;
        const toggleOff = toggleOffMechanism && curGenName === genName;

        if (toggleOff) {
            this.state.target
                .update(() => SteeringAPEntity.newFallbackTargetGenerator())
                .commit();
        } else {
            this.state.target
                .update(() => this.getTargetGenerator(genName))
                .commit();
        }
    }

    public get currentTargetGeneratorName(): string {
        return this.state.target?.master?.name ?? "fallback";
    }
}
