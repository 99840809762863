import React, {CSSProperties, FC} from "react";
import styled from "styled-components";
import {Pin} from "../backend/Pin";
import {useTriton} from "../../triton/TritonHooks";
import {Triton} from "../../triton/Triton";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {IconButton} from "../../ardai/components/IconButton";
import {OutletRounded} from "@mui/icons-material";
import {SxProps} from "@mui/material";
import {FlatIconButton} from "../../ardai/components/FlatIconButton";
import {PinConnectionInfoDisplay} from "./PinConnectionInfoDisplay";
import {MenuGroup} from "../../ardai/components/MenuGroup";

const StyledPinInfoDisplay = styled.div<{
    t: Triton
}>`
  max-width: 300px;
  
  direction: initial;
  --padding: 8px;
  position: relative;
  display: grid;
  grid-template-rows: min-content auto min-content;
  border-radius: 8px;
  background-color: ${p => p.t.col("bg_modal")};
  padding-right: 8px;
  
  .header {
    padding: var(--padding);
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .main {
    padding: 0 var(--padding);
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  
  .footer {
    padding: var(--padding);
    display: flex;
    gap: 4px;
    align-items: center;
  }
`;

export type PinInfoDisplayProps = {
    pin: Pin
}

export const PinInfoDisplay: FC<PinInfoDisplayProps> = props => {
    const t = useTriton();
    const pin = props.pin;
    const dye = pin.config.dye;

    const pinIconStyle: SxProps & CSSProperties = {
        fontSize: 14,
        color: dye === undefined ? "unset" : dye
    }

    return (
        <StyledPinInfoDisplay t={t} onClick={e => e.stopPropagation()}>
            <div className={"header"}>

                <FlatIconButton children={
                    <OutletRounded sx={pinIconStyle}/>
                }/>

                <DescriptiveTypography noSelect text={`${pin.config.label}`} style={{ whiteSpace: "nowrap" }}/>
            </div>
            <div className={"main"}>

                { pin.inputConnections.length > 0 && (
                    <div className={"connections"}>
                        <MenuGroup title={"Input connections"}>
                            { pin.inputConnections.map(destPin => (
                                <PinConnectionInfoDisplay
                                    fromPin={pin}
                                    destPin={destPin}
                                    connectionDirectionContext={"out"}
                                />
                            )) }
                        </MenuGroup>
                    </div>
                ) }



                { pin.outputConnections.length > 0 && (
                    <div className={"connections"}>
                        <MenuGroup title={"Output connections"}>
                            { pin.outputConnections.map(destPin => (
                                <PinConnectionInfoDisplay
                                    fromPin={pin}
                                    destPin={destPin}
                                    connectionDirectionContext={"out"}
                                />
                            )) }
                        </MenuGroup>
                    </div>
                ) }



            </div>
            <div className={"footer"}>

            </div>
        </StyledPinInfoDisplay>
    );
}
