import React from "react";
import {Post} from "./models/Post";
import styled from "styled-components";
import {motion} from "framer-motion";
import {PostMetricsViewRow} from "./PostMetricsViewRow";
import {Text} from "../../../triton/components/typography/Text";
import moment from "moment";

const StyledPostMetricsView = styled.div`
  padding: 0 16px;
`;

export type PostMetricsViewProps = {
    post: Post
}

export const PostMetricsView: React.FC<PostMetricsViewProps> = props => {
    const post = props.post;
    const impressions = Math.max(post.impressions ?? 0, 0);
    const postCreationTimestamp = post.creationTimestamp ?? 0;
    const postCreationMoment = moment(postCreationTimestamp);




    return (
        <StyledPostMetricsView>

            <PostMetricsViewRow>
                <motion.h2 children={post.title} style={{
                    margin: 0,
                    fontFamily: "Whitney",
                    fontSize: 14,
                    fontWeight: "lighter"
                }}/>
            </PostMetricsViewRow>

            <PostMetricsViewRow>
                <Text t={postCreationMoment.format("ll")} fs={14} fw={"lighter"}/>
                <Text t={postCreationMoment.format("LT")} fs={14} fw={"lighter"}/>
                <Text t={`${impressions} impressions`} fs={14} fw={"bold"}/>
            </PostMetricsViewRow>

            <PostMetricsViewRow>
                { post.description.trim().length > 0 && (
                    <Text t={post.description} fs={14} fw={"lighter"}/>
                ) }
            </PostMetricsViewRow>
        </StyledPostMetricsView>
    );
}
