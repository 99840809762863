import {VariableFrame} from "./VariableFrame";
import {Variable} from "./Variable";
import {VariableIdentifier} from "./VariableIdentifier";

export class VariablePool {

    private frames: Array<VariableFrame> = [];

    public getVariable(id: VariableIdentifier): Variable | undefined {
        let variable: Variable | undefined = undefined;
        for (let frame of this.frames.reverse()) {
            const candidate = frame.getVariable(id);
            if (candidate === undefined) continue;
            variable = candidate;
            break;
        }
        return variable;
    }

    public getVariableByName(name: string): Variable | undefined {
        return this.getVariable({
            name: name
        });
    }

    public get currentFrame(): VariableFrame {
        return this.frames[this.frames.length];
    }

    public pushFrame(name?: string): VariableFrame {
        const frame = new VariableFrame(name);
        this.frames.push(frame);
        return frame;
    }

    public popFrame(): VariableFrame {
        // return this.frames.pop()!;
        return this.frames.shift()!;
    }

    public generateFlatVariableFrame(): VariableFrame {
        // Gather all available variables
        let variableNames: Array<string> = [];
        this.frames.forEach(frame => {
            variableNames.push(...Array.from(frame.variables.keys()));
        });
        variableNames = Array.from(new Set(variableNames));
        // Resolve variable names to variables in a new VariableFrame
        const flatFrame = new VariableFrame();
        variableNames.forEach(name => {
            flatFrame.setVariable(this.getVariableByName(name)!);
        });
        return flatFrame;
    }
}
