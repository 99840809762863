import React, {useContext} from "react";
import {ArdaiAPIStateContext} from "../../ArdaiMain";
import {SidePanel} from "../../components/SidePanel";
import {LayoutTabButton} from "../../components/LayoutTabButton";
import {
    ApiRounded,
    Close,
    CodeRounded,
    FingerprintRounded,
    FolderRounded,
    ImageRounded,
    KeyboardArrowLeftRounded,
    LightModeRounded,
    ModeNightRounded,
    PaletteRounded,
    ShieldMoonRounded,
    StarsRounded,
    StyleRounded,
    TagRounded
} from "@mui/icons-material";
import {LayoutManagerView} from "../../components/LayoutManagerView";
import {Workspace} from "../../components/Workspace";
import {VFSViewOptions} from "../../components/VFSViewOptions";
import {VFSView} from "../../components/VFSView";
import {StyleLibraryView} from "../../components/StyleLibraryView";
import {StyleDataDisplay} from "../../components/StyleDataDisplay";
import {ImageView} from "../../components/ImageView";
import {SelectionView} from "../../components/SelectionView";
import {ProjectHeaderView} from "../../components/ProjectHeaderView";
import {ProjectView} from "../../components/ProjectView";
import {MetadataView} from "../../components/MetadataView";
import {DerbyshireMain} from "../../derbyshire/components/DerbyshireMain";
import {ButtonModalCompound} from "../../components/ButtonModalCompound";
import {IconButton} from "../../components/IconButton";
import {SDInterfaceMain} from "../../../imageSorter/sdInterface/SDInterfaceMain";
import {SDDefaultInterface} from "../../../imageSorter/sdInterface/SDDefaultInterface";
import {DuplexEventRelay} from "../../utils/DuplexEventRelay";
import {FolderContentTab} from "../../components/folderContentTab/FolderContentTab";
import {MetadataViewV2} from "../../components/MetadataViewV2";
import {ChallengerTab} from "../../_/challenger/ChallengerTab";
import {ButtonBase} from "../../../triton/components/buttons/ButtonBase";
import {Centered} from "../../../base/components/base/PosInCenter";
import {Program} from "../../gaia/v0/Program";
import {ASTNode} from "../../gaia/v0/ASTNode";
import {reactBridgeTest} from "../../../ReactAPIBridgeUtils";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {AppMainBranding} from "../../../../hub/prototype/AppMainBranding";
import {ImageHubBranding} from "../../components/ImageHubBranding";
import {VFSElementCard} from "../../components/VFSElementCard";
import {ListItem} from "../../_/ListItem";
import {ArdaiAPIContext} from "../../webapi/WebAPI";
import {FormDivider} from "../../../triton/components/forms/FormDivider";
import {ScreenSaverNamespace} from "../../components/shared/screenSaver/ScreenSaver";
import {Dot} from "../../components/Dot";
import {ImageViewV2} from "./imageView/v2/ImageViewV2";

export const GalleryView: React.FC = props => {
    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);

    return (
        <div style={{
            display: "grid",
            gap: "8px",
            gridTemplateColumns: "min-content auto min-content",
            height: "calc(100% - 0.3rem)",
            maxHeight: "calc(100% - 0.3rem)",
            width: "100%",
            overflow: "scroll"
        }}>
            <SidePanel start={
                <>
                    <IconButton
                        size={"small"}
                        onClick={() => api.toggleTray("left")}
                        tooltip={"Toggle left tray"}
                        children={
                            <KeyboardArrowLeftRounded style={{
                                transition: "transform .2s",
                                transform: `rotate(${
                                    state.openedTrays.includes("left") ? 0 : 180
                                }deg)`
                            }}/>
                        }
                    />
                    <IconButton
                        size={"small"}
                        onClick={() => api.toggleTray("left-secondary")}
                        tooltip={"Toggle secondary left tray"}
                        children={
                            <KeyboardArrowLeftRounded style={{
                                transition: "transform .2s",
                                transform: `rotate(${
                                    state.openedTrays.includes("left-secondary") ? 0 : 180
                                }deg)`
                            }}/>
                        }
                    />

                    <Dot style={{
                        margin: "6px 0"
                    }}/>

                    <LayoutTabButton defaultTargetTray={"left"} programKey={"vfs-view"} children={<FolderRounded/>}/>
                    <LayoutTabButton defaultTargetTray={"left"} programKey={"vfs-view-v2"} children={<FolderRounded/>} title={"VFS view V2"}/>
                    <LayoutTabButton defaultTargetTray={"left"} programKey={"style-view"} children={<StyleRounded/>}/>
                    <LayoutTabButton defaultTargetTray={"left"} programKey={"current-image-meta-view"} children={<FingerprintRounded/>}/>
                </>
            } end={
                <>
                    <LayoutTabButton defaultTargetTray={"left"} programKey={"challenge-view"} children={<PaletteRounded/>}/>
                    <LayoutTabButton defaultTargetTray={"bottom"} programKey={"derbyshire-view"} children={<CodeRounded/>}/>
                </>
            }/>

            <LayoutManagerView
                layout={{
                    trayOpenStates: new Map<string, boolean>(
                        [
                            "left", "main", "right", "bottom",
                            // TODO: Make better -> Remove mapping
                            "left-secondary", "right-secondary"
                        ].map(tray => [tray, state.openedTrays.includes(tray)])
                    ),
                    occupancy: new Map<string, string | undefined>(
                        Object.entries(state.trayOccupancy)
                    )
                }}
                trayRenderers={new Map<string, () => React.ReactNode>([
                    ["vfs-view", () => (
                        <div style={{
                            display: "grid",
                            height: "100%",
                            gridTemplateRows: "min-content auto",
                            rowGap: "8px"
                        }}>
                            <Workspace children={<VFSViewOptions/>} config={{mode: "desktop", name: "vfs-options"}}/>
                            <Workspace children={<VFSView/>} config={{mode: "desktop", name: "vfs"}}/>
                        </div>
                    )],

                    ["vfs-view-v2", () => (
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "8px",
                            height: "calc(100% - 0px)"
                        }}>
                            <Workspace style={{flexShrink: 0, height: "min-content"}} children={<VFSViewOptions/>} config={{mode: "desktop", name: "project-title"}}/>
                            <Workspace style={{flexShrink: 2, overflow: "hidden"}} children={<VFSView/>} config={{mode: "desktop", name: "project"}}/>
                            <Workspace style={{flexShrink: 0, height: "min-content"}} children={<ProjectHeaderView/>} config={{mode: "desktop", name: "project-metadata"}}/>
                        </div>
                    )],

                    ["style-view", () => (
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "8px",
                            height: "calc(100% - 0px)"
                        }}>
                            <Workspace children={<StyleLibraryView/>} config={{mode: "desktop", name: "style-library"}}/>
                            <Workspace style={{flexShrink: 0, height: "min-content"}} children={<StyleDataDisplay/>} config={{mode: "desktop", name: "project-metadata"}}/>
                        </div>
                    )],

                    ["image-view", () => (
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            width: "100%",
                            overflow: "hidden",
                            // gridTemplateRows: "auto min-content"
                        }}>
                            <ImageView/>
                            <SelectionView/>
                        </div>
                    )],

                    ["image-view-v2", () => (
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            width: "100%",
                            overflow: "hidden",
                            // gridTemplateRows: "auto min-content"
                        }}>
                            <ImageViewV2/>
                            <SelectionView/>
                        </div>
                    )],

                    ["project-view", () => (
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "8px",
                            height: "calc(100% - 0px)"
                        }}>
                            <Workspace style={{flexShrink: 0, height: "min-content"}} children={<ProjectHeaderView/>} config={{mode: "desktop", name: "project-title"}}/>
                            <Workspace style={{flexShrink: 2}} children={<ProjectView/>} config={{mode: "desktop", name: "project"}}/>
                            {/*
                            <Workspace style={{flexShrink: 0, height: "min-content"}} children={<MetadataView/>} config={{mode: "desktop", name: "project-metadata"}}/>
                            */}
                        </div>
                    )],

                    ["fs-folder-view", () => (
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "8px",
                            height: "calc(100% - 0px)"
                        }}>
                            <Workspace
                                children={<FolderContentTab/>}
                                config={{ mode: "desktop", name: "fs-folder-view" }}
                                style={{ flexShrink: 2 }}
                            />
                        </div>
                    )],

                    ["derbyshire-view", () => (
                        <DerbyshireMain/>
                    )],

                    ["current-image-meta-view", () => (
                        <Workspace
                            children={<MetadataViewV2/>}
                            config={{ mode: "desktop", name: "current-image-meta-view" }}
                        />
                    )],

                    ["challenge-view", () => (
                        <Workspace
                            children={<ChallengerTab/>}
                            config={{ mode: "desktop", name: "challenge-view" }}
                        />
                    )],

                    ["debug-gaia-view", () => (
                        <Workspace
                            config={{ mode: "desktop", name: "challenge-view" }}
                            children={
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }} children={
                                    <>
                                        <ButtonBase text={"Run Gaia test"} baseProps={{
                                            onClick: () => {
                                                const program = new Program(new ASTNode(""));
                                                Program.test();
                                            }
                                        }}/>

                                        { reactBridgeTest }
                                    </>
                                }/>
                            }
                        />
                    )],

                    ["experimentation-view", () => {
                        return (
                            <div style={{
                                display: "grid",
                                height: "100%",
                                width: "100%"
                            }}>
                                {/* Experimentation code goes here */}
                            </div>
                        );
                    }],

                    ["_empty", () => (
                        <DescriptiveTypography text={"No program found"}/>
                    )],

                    ["_empty:main", () => (
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 8,
                            height: "100%"
                        }}>
                            <ImageHubBranding centerLayout/>

                            <div style={{
                                display: "grid",
                                width: 300,
                                gap: 4,
                                marginTop: 32
                            }}>
                                <ListItem
                                    title={"Open image view"}
                                    onClick={() => {
                                        api.openProgramInTray("main", "image-view");
                                    }}
                                />
                                <ListItem
                                    title={"Open challenge view"}
                                    onClick={() => {
                                        api.openProgramInTray("main", "challenge-view");
                                    }}
                                />
                                <ListItem
                                    title={"Default layout"}
                                    onClick={() => {
                                        api.openProgramInTray("left", "vfs-view");
                                        api.openProgramInTray("main", "image-view");
                                        api.openProgramInTray("right", "project-view");
                                    }}
                                />
                                <ListItem
                                    title={"Sheet music layout"}
                                    onClick={() => {
                                        api.layoutManager.closeAllTrays();
                                        api.layoutManager.openProgramInTray("left", "vfs-view");
                                        api.layoutManager.openProgramInTray("main", "image-view-v2");
                                    }}
                                />
                                <ListItem
                                    title={"Close all tabs"}
                                    onClick={() => {
                                        api.closeAllProgramsInAllTrays();
                                    }}
                                />

                                <FormDivider/>
                                <ListItem title={"Activate screensaver"} onClick={() => {
                                    api.crudeBroker.notify(ScreenSaverNamespace.SCREEN_SAVER_ACTIVATION_EVENT, undefined);
                                }}/>
                                <ListItem title={"Enter distraction-free mode"}/>

                                <FormDivider/>
                                <ListItem title={"Open Debug/Experimentation view"} onClick={() => {
                                    api.layoutManager.openProgramInTray("main", "experimentation-view");
                                }}/>
                                <ListItem title={"Command palette"}/>
                                <ListItem title={"Settings"}/>

                            </div>
                        </div>
                    )]
                ])}
            />

            <SidePanel start={
                <>
                    <LayoutTabButton defaultTargetTray={"right"} programKey={"project-view"} children={<TagRounded/>}/>
                    <LayoutTabButton defaultTargetTray={"right"} programKey={"fs-folder-view"} children={<FolderRounded/>}/>

                    <Dot style={{
                        margin: "6px 0"
                    }}/>

                    <LayoutTabButton defaultTargetTray={"main"} programKey={"image-view"} children={<ImageRounded/>}/>
                    <LayoutTabButton defaultTargetTray={"main"} programKey={"image-view-v2"} title={"Image View V2"} children={<ImageRounded/>}/>
                </>
            } end={
                <>
                    <LayoutTabButton defaultTargetTray={"main"} programKey={"debug-gaia-view"} children={<ShieldMoonRounded/>}/>

                    <Dot style={{
                        margin: "6px 0"
                    }}/>

                    <ButtonModalCompound preventClosingOnBackdropClick button={<IconButton size={"small"} children={<ApiRounded/>}/>} modalContent={ctx => (
                        <SDInterfaceMain children={
                            <SDDefaultInterface bus={new DuplexEventRelay()} onClose={() => ctx.close()}/>
                        }/>
                    )}/>
                </>
            }/>
        </div>
    );
}
