import React from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {RotatableChevronIcon} from "./RotatableChevronIcon";
import {OrientationMapping} from "./OrientationMapping";

const StyledCategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: rgb(148, 155, 164);
  padding-right: 8px;
  // padding-left: 16px;
  padding-left: 8px;
  height: 24px;
  position: relative;
  
  &:hover .category-header-main * {
    color: #dbdee1 !important;
  }
  
  .category-header-main, .category-header-appendix {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  
  .category-header-main {
    flex-grow: 1;
    
    .expand-icon {
      position: absolute;
      left: -20px;
    }
  }
`;

export type CategoryHeaderProps = {
    title: string,
    appendix?: JSX.Element,
    expanded?: boolean,
    onExpandToggle?: (expansionState: boolean) => void
}

export const CategoryHeader: React.FC<CategoryHeaderProps> = props => {
    return (
        <StyledCategoryHeader onClick={() => props.onExpandToggle?.(!props.expanded)}>
            <div className={"category-header-main"}>
                <div className={"expand-icon"} children={
                    <RotatableChevronIcon collapsed={!props.expanded}/>
                }/>
                <DescriptiveTypography noSelect text={props.title} style={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                    letterSpacing: ".24px"
                }}/>
            </div>

            <div className={"category-header-appendix"} onClick={e => e.stopPropagation()}>
                {props.appendix}
            </div>
        </StyledCategoryHeader>
    );
}
