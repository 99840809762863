import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const NodeObjectAccessor: NodeSetupInfo = {
    label: "NodeObjectAccessor",
    classname: "objects.object-accessor",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "objects.object-accessor",
        label: "obj accessor",
        defOutPins: ["o'"],
        defInPins: ["o", "path"],
        init: function () {
            this.createInternalWire({
                targetInPin: "o",
                targetOutPin: "o'",
                transformer: s => {
                    const path = this.pins.in("path").lastReadState;
                    if (path === undefined) return s;
                    if (typeof path !== "string") throw new Error("path is not a string");

                    const pathElements = path.split(".");
                    pathElements.forEach(pE => {
                        try {
                            s = s[pE];
                        } catch (e) {
                            const subErr = new Error(`Cannot resolve path element '${pE}' on object '${s}'`);
                            subErr.cause = e;
                            throw subErr;
                        }
                    });

                    return s;
                }
            })
        }
    })
}
