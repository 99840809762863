import React, {PropsWithChildren} from "react";
import {StyledModal} from "../StyledModal";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {InfoRounded} from "@mui/icons-material";

export type BasicDocumentationDialogProps = PropsWithChildren<{
    documentation: React.ReactNode,
    onClose: () => void,
    title: string
}>

export const BasicDocumentationDialog: React.FC<BasicDocumentationDialogProps> = props => {

    return (
        <StyledModal icon={<InfoRounded/>} onClose={() => props.onClose()} title={props.title} children={
            typeof props.documentation === "string" ? (
                <DescriptiveTypography text={props.documentation}/>
            ) : (
                props.documentation || props.children
            )
        }/>
    );
}
