import {FC} from "react";
import styled from "styled-components";
import {FlatIconButton} from "../../../ardai/components/FlatIconButton";
import {CommitRounded} from "@mui/icons-material";
import {useNodeCanvasBackend} from "../NodeCanvasBackend";
import {NodeInfoSidebarDisplay} from "./NodeInfoSidebarDisplay";
import {useStaticState} from "../../../ardai/hooks/StaticStateHook";

const StyledNodeCanvasSidebar = styled.div`
  position: relative;
  height: 100%;
  padding: 4px;
  display: flex;
  
  // background-color: tomato;
  
  border-radius: 8px;
  // background-color: crimson;
`;

export type NodeCanvasSidebarProps = {

}

export const NodeCanvasSidebar: FC<NodeCanvasSidebarProps> = props => {
    const backend = useNodeCanvasBackend();

    const [experimentalState, experimentalCtx] = useStaticState({
        id: "NodeCanvasSidebar-local-experimental",
        staticMode: true,
        initial: {
            showInfo: true
        }
    }).stateWithCtx;

    const primeSelectedNodeId = backend.state.ui.selection.prime.selectedNodeId;
    const primeSelectedNode = primeSelectedNodeId !== undefined ? backend.getNodeByID(primeSelectedNodeId) : undefined;

    return (
        <StyledNodeCanvasSidebar>
            <FlatIconButton
                active={experimentalState.showInfo}
                children={<CommitRounded/>}
                tooltip={"Show node info tab"}
                onClick={() => {
                    experimentalCtx.reverseBool("showInfo");
                }}
            />

            {/*  */}

            { (experimentalState.showInfo && primeSelectedNode !== undefined) && (
                <div style={{
                    position: "absolute",
                    height: "100%",
                    right: "calc(100% + 16px)",
                    overflowY: "scroll",
                    width: 308 // This is intentional -> for rendering the preview grid
                }} children={
                    <NodeInfoSidebarDisplay node={primeSelectedNode}/>
                }/>
            ) }




        </StyledNodeCanvasSidebar>
    );
}
