import React from "react";
import {PromptRendererBaseProps} from "./PromptRendererBaseProps";
import {SDPromptField} from "../../../imageSorter/sdInterface/SDPromptField";
import {px} from "../../../base/logic/style/DimensionalMeasured";

export type PromptCodeRendererProps = PromptRendererBaseProps;

export const PromptCodeRenderer: React.FC<PromptCodeRendererProps> = props => {
    return (
        <SDPromptField
            fontSize={12}
            h={px(200)}
            value={props.originalPrompt}
            readonly={true}
        />
    );
}
