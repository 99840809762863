import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {triton} from "../../../../triton/Triton";

export const NumericInput: NodeSetupInfo = {
    label: "NumericInput",
    classname: "input.numeric-input-debug",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "input.numeric-input-debug",
        label: "numeric input *",
        defOutPins: ["n"],
        state: { n: 0 },
        reset: function () {
            this.pins.out("n").write(0);
        },
        init() {
            this.pins.out("n").attach({
                read() {
                    const i = this.node.pins.in("i").lastReadState;
                    this.node.pins.out("o").write(i);
                }
            })
        },
        customRenderer: node => (
            <div style={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
                padding: 0,
                alignItems: "center",
                height: "100%",
                justifyContent: "center"
            }}>
                <input
                    type={"number"}
                    inputMode={"numeric"}
                    value={node.state.state.n}
                    onChange={e => {
                        const n = Number(e.currentTarget.value);
                        node.pins.out("n").write(n);
                        node.state.update({ n });
                    }}
                    style={{
                        maxWidth: 80,
                        fontSize: 12,
                        color: triton().col("fg_muted"),
                        border: "none",
                        backgroundColor: "transparent",
                        outline: "none"
                    }}
                />
            </div>
        )
    })
}
