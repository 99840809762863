import React from "react";
import {useDisk} from "../../webapi/fs/hooks/UseDiskHook";
import {FSManagerEvents} from "../../webapi/fs/FSManager";
import {ListItem} from "../../_/ListItem";

export const FolderContentTab: React.FC = props => {
    const [disk] = useDisk({
        events: [FSManagerEvents.DISK_STATE_UPDATED]
    });

    return (
        <div style={{
            overflow: "scroll"
        }} children={disk.state.state.lsSnapshot?.files.map(fileInfo => (
            <ListItem title={fileInfo.name}/>
        ))}/>
    );
}
