import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {FC} from "react";
import Editor from "@monaco-editor/react";
import {ButtonModalCompound} from "../../../../ardai/components/ButtonModalCompound";
import {Tag} from "../../../../ardai/components/Tag";
import {OpenInNewRounded, PlayArrowRounded} from "@mui/icons-material";
import {useNodeCanvasBackend} from "../../NodeCanvasBackend";
import {NodeCanvasBackendEventTypes} from "../../NodeCanvasBackendEventTypes";

type ProgrammableLogicControllerState = {
    src: string
}

/**
 * @forRemoval
 * @deprecated -> Monaco editor can't work with InfiniteViewer
 */
export const ProgrammableLogicController: NodeSetupInfo<ProgrammableLogicControllerState> = {
    label: "ProgrammableLogicController",
    classname: "orchestration.plc",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "orchestration.plc",
        label: "plc",
        state: {
            src: ""
        },
        init: function () {

        },
        customRenderer: node => {
            return (
                <ProgrammableLogicControllerComponent node={node}/>
            );
        }
    })
}

const ProgrammableLogicControllerComponent: FC<{
    node: Node<ProgrammableLogicControllerState>
}> = props => {
    const { node } = props;
    const backend = useNodeCanvasBackend();

    return (
        <div style={{
            padding: "8px 0",
            display: "flex",
            gap: 4,
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
        }}>
            <ButtonModalCompound
                borderless
                button={
                    <Tag tag={
                        <OpenInNewRounded
                            sx={{ fontSize: 14 }}
                        />
                    }/>
                }
                modalContent={ctx => (
                    <ProgrammableLogicControllerModalComponent
                        node={node}
                    />
                )}
            />

            <Tag tag={<PlayArrowRounded sx={{ fontSize: 14 }}/>} onClick={() => {
                const src = node.state.state.src;
                new Function(src).call(node);
                backend.events.notify(NodeCanvasBackendEventTypes.GENERIC, undefined);
            }}/>
        </div>
    );
}

const ProgrammableLogicControllerModalComponent: FC<{
    node: Node<ProgrammableLogicControllerState>
}> = props => {
    const { node } = props;
    const state = node.state;

    const onSrcChange = (src: string | undefined, ev: any) => {
        state.update({
            src: src ?? ""
        });
    }

    return (
        <div
            style={{
                // padding: "8px 0",
                display: "grid",
                width: "100vw",
                height: "100vh"
            }}
            onClick={event => event.stopPropagation()}
        >
            <Editor
                // height={360}
                // width={720}
                value={state.state.src}
                onChange={onSrcChange}
                saveViewState
                options={{
                    fontSize: 14,
                    cursorStyle: "underline-thin",
                    fontLigatures: true,
                    cursorSmoothCaretAnimation: true,
                    smoothScrolling: true,
                    codeLens: false,
                    autoClosingBrackets: "always",
                    autoClosingQuotes: "always",
                    matchBrackets: "always",
                    scrollbar: {
                        vertical: "hidden",
                        horizontal: "hidden"
                    },
                }}
                theme={"vs-dark"}
                language={"javascript"}
            />
        </div>
    );
}
