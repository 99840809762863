import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {ArdaiAPIContext} from "../../webapi/WebAPI";
import {ArdaiAPIStateContext} from "../../ArdaiMain";
import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../../webapi/WebAPIDB";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {Image} from "../../data/Image";
import {ExifRawData} from "./ExifRawData";
import {FormDivider} from "../../../triton/components/forms/FormDivider";
import {MetaBasicEntry} from "../MetaBasicEntry";
import {MenuDivider} from "@szhsin/react-menu";

const StyledImageDetailsView = styled.div`
  width: calc(100% - 0px);
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: min-content auto;
  gap: 8px;
  
  .image-details-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .meta-container-scroll {
    height: 100%;
    overflow: scroll;
    
    .meta-container {
      display: flex;
      flex-direction: column;
      overflow: visible;
      gap: 8px;
    }
  }
`;

export type ImageDetailsViewState = {
    exif?: ExifRawData
}

export const ImageDetailsView: React.FC = props => {

    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);


    const [ls, setLs] = useState<ImageDetailsViewState>({
        exif: undefined
    });

    const currentImage = useLiveQuery(async () => {
        return webDB.images
            .where("id")
            .equals(state.selectedImageId ?? "_placeholder")
            .first();
    }, [state.selectedImageId]);

    const raw = currentImage?.data;

    function extractChunks(data: Uint8Array) {
        var uint8 = new Uint8Array(4);
        var int32 = new Int32Array(uint8.buffer);
        var uint32 = new Uint32Array(uint8.buffer);

        if (data[0] !== 0x89) throw new Error('Invalid .png file header')
        if (data[1] !== 0x50) throw new Error('Invalid .png file header')
        if (data[2] !== 0x4E) throw new Error('Invalid .png file header')
        if (data[3] !== 0x47) throw new Error('Invalid .png file header')
        if (data[4] !== 0x0D) throw new Error('Invalid .png file header: possibly caused by DOS-Unix line ending conversion?')
        if (data[5] !== 0x0A) throw new Error('Invalid .png file header: possibly caused by DOS-Unix line ending conversion?')
        if (data[6] !== 0x1A) throw new Error('Invalid .png file header')
        if (data[7] !== 0x0A) throw new Error('Invalid .png file header: possibly caused by DOS-Unix line ending conversion?')

        var ended = false
        var chunks = []
        var idx = 8

        while (idx < data.length) {
            // Read the length of the current chunk,
            // which is stored as a Uint32.
            uint8[3] = data[idx++]
            uint8[2] = data[idx++]
            uint8[1] = data[idx++]
            uint8[0] = data[idx++]

            // Chunk includes name/type for CRC check (see below).
            var length = uint32[0] + 4
            var chunk = new Uint8Array(length)
            chunk[0] = data[idx++]
            chunk[1] = data[idx++]
            chunk[2] = data[idx++]
            chunk[3] = data[idx++]

            // Get the name in ASCII for identification.
            var name = (
                String.fromCharCode(chunk[0]) +
                String.fromCharCode(chunk[1]) +
                String.fromCharCode(chunk[2]) +
                String.fromCharCode(chunk[3])
            )

            // The IHDR header MUST come first.
            if (!chunks.length && name !== 'IHDR') {
                throw new Error('IHDR header missing')
            }

            // The IEND header marks the end of the file,
            // so on discovering it break out of the loop.
            if (name === 'IEND') {
                ended = true
                chunks.push({
                    name: name,
                    data: new Uint8Array(0)
                })

                break
            }

            // Read the contents of the chunk out of the main buffer.
            for (var i = 4; i < length; i++) {
                chunk[i] = data[idx++]
            }

            // Read out the CRC value for comparison.
            // It's stored as an Int32.
            uint8[3] = data[idx++]
            uint8[2] = data[idx++]
            uint8[1] = data[idx++]
            uint8[0] = data[idx++]

            var crcActual = int32[0]

            // The chunk data is now copied to remove the 4 preceding
            // bytes used for the chunk name/type.
            var chunkData = new Uint8Array(chunk.buffer.slice(4))

            chunks.push({
                name: name,
                data: chunkData
            })
        }

        if (!ended) {
            throw new Error('.png file ended prematurely: no IEND header was found')
        }

        return chunks
    }

    async function retrieveImageDetails(image: Image): Promise<ExifRawData> {
        const buf = await image.data.arrayBuffer();
        const uint8 = new Uint8Array(buf);
        return extractChunks(uint8) satisfies ExifRawData;
    }

    useEffect(() => {
        if (currentImage === undefined) return;

        retrieveImageDetails(currentImage).then(details => {
            setLs(prevState => ({
                ...prevState,
                exif: details
            }));
        });
    }, [currentImage]);

    return (
        <StyledImageDetailsView>
            <div className={"image-details-header"}>
                <MainTypography text={"Image details"}/>
                <DescriptiveTypography text={`Size: ${raw?.size}`}/>
                <DescriptiveTypography text={`Type: '${raw?.type ?? "undefined type"}'`}/>
            </div>

            <div className={"meta-container-scroll"}>
                <div className={"meta-container"}>
                    { ls.exif && (
                        ls.exif.map(entry => (
                            <>
                                <FormDivider title={entry.name}/>
                                {(() => {
                                    if (entry.name === "IHDR") {
                                        const width = entry.data.slice(0, 4);
                                        const height = entry.data.slice(4, 8);
                                        const bitDepth = entry.data[8];
                                        const colorType = entry.data[9];
                                        const compressionMethod = entry.data[10];
                                        const filterMethod = entry.data[11];
                                        const interlaceMethod = entry.data[12];

                                        return (
                                            <>
                                                <FormDivider title={"Basic"}/>
                                                <MetaBasicEntry title={"Dimensions"} value={`${Buffer.from(width).readInt32BE()}x${Buffer.from(height).readInt32BE()}px`}/>
                                                <MetaBasicEntry title={"Aspect ratio"} value={`${Buffer.from(width).readInt32BE() / Buffer.from(height).readInt32BE()}`}/>
                                                <MetaBasicEntry title={"Width"} value={`${Buffer.from(width).readInt32BE()}px`}/>
                                                <MetaBasicEntry title={"Height"} value={`${Buffer.from(height).readInt32BE()}px`}/>

                                                <FormDivider title={"Advanced"}/>
                                                <MetaBasicEntry title={"Bit depth"} value={`${bitDepth}`}/>
                                                <MetaBasicEntry title={"Color type"} value={`${colorType}`}/>
                                                <MetaBasicEntry title={"Compression method"} value={`${compressionMethod}`}/>
                                                <MetaBasicEntry title={"Filter method"} value={`${filterMethod}`}/>
                                                <MetaBasicEntry title={"Interlace method"} value={`${interlaceMethod}`}/>
                                            </>
                                        );
                                    }

                                    else if (entry.name === "IDAT") {
                                        return (
                                            <DescriptiveTypography text={new TextDecoder().decode(entry.data).length}/>
                                        );
                                    }

                                    return (
                                        <DescriptiveTypography text={new TextDecoder().decode(entry.data)}/>
                                    );
                                })()}
                            </>
                        ))
                    ) }
                </div>
            </div>


        </StyledImageDetailsView>
    );
}
