import React from "react";
import styled from "styled-components";
import {Formik} from "formik";
import {VariableSlider} from "../../../../../imageSorter/sdInterface/VariableSlider";
import {DescriptiveTypography} from "../../../../../triton/components/typography/DescriptiveTypography";

const StyledGenerationSettingsToolWindow = styled.div`
  .generation-settings-form-container {
    
  }
`;

export const GenerationSettingsToolWindow: React.FC = props => {
    return (
        <StyledGenerationSettingsToolWindow>
            <Formik
                initialValues={{}}
                onSubmit={(values, formikHelpers) => {}}
                children={fp => (
                    <div className={"generation-settings-form-container"}>
                        <DescriptiveTypography text={"Sample steps"}/>
                        <VariableSlider title={"asdasd"}/>
                        <DescriptiveTypography text={"Sampling method"}/>
                        <DescriptiveTypography text={"Upscaler"}/>
                        <DescriptiveTypography text={"Hires steps"}/>
                        <VariableSlider value={1.5}/>
                        <DescriptiveTypography text={"Denoising strength"}/>
                        <VariableSlider/>
                        <DescriptiveTypography text={"Upscale by"}/>
                        <VariableSlider/>
                        <DescriptiveTypography text={"Width"}/>
                        <VariableSlider/>
                        <DescriptiveTypography text={"Height"}/>
                        <VariableSlider/>
                        <DescriptiveTypography text={"Batch count"}/>
                        <VariableSlider/>
                        <DescriptiveTypography text={"CFG Scale (Classifier-Free Guidance)"}/>
                        <VariableSlider/>
                        <DescriptiveTypography text={"Seed"}/>
                        <DescriptiveTypography text={"Variation strength"}/>
                        <VariableSlider/>
                    </div>
                )}
            />
        </StyledGenerationSettingsToolWindow>
    );
}
