import {NodeSetupInfo} from "../NodeSetupInfo";
import {Node} from "../../backend/Node";
import {v4} from "uuid";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import _ from "lodash";

export const Counter: NodeSetupInfo = {
    label: "",
    classname: "counter",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "counter",
        label: "counter",
        state: { i: 0, d: 1 },
        defOutPins: ["i"],
        defInPins: ["c", "Δ", "set"],
        init: function () {
            const pushCount = () => {
                this.pins.out("i").write(this.state.state.i);
            }

            this.pins.in("c").attach({
                read(data: any) {
                    this.node.state.update(prevState => ({
                        i: Number(this.node.state.state.i) + prevState.d
                    }));
                    pushCount();
                }
            })

            this.pins.in("Δ").attach({
                read(d: number) {
                    this.node.state.update({
                        d: Number(d)
                    });
                }
            })

            this.pins.in("set").attach({
                read(overwrite: any) {
                    this.node.state.update({
                        i: Number(overwrite) ?? 0
                    });
                    pushCount();
                }
            })
        },
        customRenderer: (node) => (
            <div style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "end",
                padding: "8px 0"
            }}>
                <DescriptiveTypography
                    text={_.padStart(String(node.state.state.i).slice(0, 5), 5, " ")}
                    style={{ whiteSpace: "pre" }}
                />
                <DescriptiveTypography
                    noSelect
                    text={`Δ= ${
                        _.padStart(String(node.state.state.d).slice(0, 4), 4, " ")
                    }`}
                    style={{ whiteSpace: "pre", fontSize: 12 }}
                />
            </div>
        )
    })
}
