import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {CSSProperties, FC} from "react";
import {Tag} from "../../../../ardai/components/Tag";
import {useTriton} from "../../../../triton/TritonHooks";
import {PauseRounded, PlayArrowRounded} from "@mui/icons-material";
import {ShortcutLinkTokenNamespace} from "../../../../triton/theming/ThemeBase";
import {CircularProgress} from "@mui/material";

type NodeRunDispatchState = {
    feedbackLoopType: RunDispatchFeedbackLoopType,
    isControlled: boolean,
    isColored: boolean,
    lifecycleState: RunDispatchExecutionLifecycle
}

export const NodeRunDispatch: NodeSetupInfo<NodeRunDispatchState> = {
    label: "NodeRunDispatch",
    classname: "organization.dispatch-run",
    parameterConfig: [],
    factory: parameters => {
        const isColored = parameters.get("is-colored") === true;
        const isControlled = parameters.get("is-controlled") === true;
        const feedbackLoopType: RunDispatchFeedbackLoopType = parameters.get("feedback-loop-type") ?? RunDispatchFeedbackLoopType.OPEN;

        const dispatchRun = (node: Node<NodeRunDispatchState>) => {
            if (node.state.state.lifecycleState === RunDispatchExecutionLifecycle.RUNNING) return;

            const pins = node.pins;
            node.state.update({
                lifecycleState: RunDispatchExecutionLifecycle.RUNNING
            });
            try {
                const c = node.pins.out("c");
                c.write(1);
            } finally {
                if (node.state.state.feedbackLoopType === RunDispatchFeedbackLoopType.OPEN) {
                    node.state.update({
                        lifecycleState: RunDispatchExecutionLifecycle.INITIAL
                    });
                }
            }
        }

        const dispatchManualReturn = (node: Node<NodeRunDispatchState>) => {
            if (
                node.state.state.lifecycleState !== RunDispatchExecutionLifecycle.RUNNING ||
                node.state.state.feedbackLoopType !== RunDispatchFeedbackLoopType.CLOSED
            ) return;
            node.state.update({
                lifecycleState: RunDispatchExecutionLifecycle.INITIAL
            });
        }

        return new Node<NodeRunDispatchState>({
            id: v4(),
            classname: "organization.dispatch-run",
            label: "run",
            defOutPins: ["c"],
            state: {
                isColored,
                isControlled,
                feedbackLoopType,
                lifecycleState: RunDispatchExecutionLifecycle.INITIAL
            },
            init: function () {
                if (feedbackLoopType === RunDispatchFeedbackLoopType.CLOSED) {
                    const returnPin = this.pins.in("ret");
                    const returnBroadcastPin = this.pins.out("onRet");
                    returnPin.attachOnRead((returnInfo: any) => {
                        this.state.update({
                            lifecycleState: RunDispatchExecutionLifecycle.INITIAL
                        });
                        returnBroadcastPin.write(returnInfo);
                    });
                }

                if (isControlled) {
                    const controlInPin = this.pins.in("c");
                    controlInPin.attachOnRead(signal => {
                        dispatchRun(this)
                    });
                }
            },
            customRenderer: node => {
                return (
                    <RunDispatchComponent
                        node={node}
                        onRequestDispatchRun={() => dispatchRun(node)}
                        onRequestDispatchManualReturn={() => dispatchManualReturn(node)}
                    />
                );
            }
        });
    }
}

const RunDispatchComponent: FC<{
    node: Node<NodeRunDispatchState>,
    onRequestDispatchRun: () => void,
    onRequestDispatchManualReturn: () => void,
}> = props => {
    const { node } = props;
    const state = node.state.state;
    const { isColored } = state;
    const t = useTriton();
    const lifecycle = state.lifecycleState ?? RunDispatchExecutionLifecycle.INITIAL;

    const getLifecycleColor = () => {
        const tokenLookup: { [Key in RunDispatchExecutionLifecycle]: ShortcutLinkTokenNamespace } = {
            [RunDispatchExecutionLifecycle.INITIAL]: "fg_secondary",
            [RunDispatchExecutionLifecycle.RUNNING]: "color_secondary"
        };
        return isColored ? t.col(tokenLookup[lifecycle]) : t.col("fg_secondary");
    }

    const baseIconStyling: CSSProperties = {
        fontSize: 14,
        color: getLifecycleColor()
    }

    const onClick = () => {
        switch (lifecycle) {
            case RunDispatchExecutionLifecycle.INITIAL:
                props.onRequestDispatchRun();
                break;
            case RunDispatchExecutionLifecycle.RUNNING:
                props.onRequestDispatchManualReturn();
                break;
        }
    }

    return (
        <div children={
            <Tag onClick={onClick} tag={{
                [RunDispatchExecutionLifecycle.INITIAL]: (
                    <PlayArrowRounded sx={{ ...baseIconStyling }}/>
                ),
                [RunDispatchExecutionLifecycle.RUNNING]: (
                    // <PauseRounded sx={{ ...baseIconStyling }}/>
                    <div style={{
                        width: 14,
                        display: "flex",
                        justifyContent: "center"
                    }} children={
                        <CircularProgress
                            size={12}
                            thickness={6}
                            variant={"indeterminate"}
                            sx={{
                                color: getLifecycleColor()
                            }}
                        />
                    }/>
                ),
            }[lifecycle]}/>
        }/>
    );
}

export enum RunDispatchExecutionLifecycle {
    INITIAL,
    RUNNING
}

export enum RunDispatchFeedbackLoopType {
    OPEN = "open",
    CLOSED = "closed"
}

/**
 *
 * @param src Code segment in extended instruction JavaScript
 */
function transpile(src: string) {
    let ir: string = src;

    function collapseHashtagComments() {

    }

    function collapseInstructions() {

    }

    function collapsePinReferences() {

    }

}
