import React from "react";
import Editor from "@monaco-editor/react";
import {DimensionalMeasured} from "../../base/logic/style/DimensionalMeasured";
import {Color} from "../../base/logic/style/Color";
import {useTriton} from "../../triton/TritonHooks";

export type SDPromptFieldProps = {
    onChange?: (value: string | undefined, ev: any) => void,
    value?: string,
    h?: string | DimensionalMeasured,
    readonly?: boolean,
    backgroundColor?: Color,
    // onSubmit?: (value: string) => void

    // editor.IStandaloneCodeEditor
    onMount?: (editor: any) => void,
    fontSize?: number
}

export const SDPromptField: React.FC<SDPromptFieldProps> = props => {
    const t = useTriton();
    // const bg = props.backgroundColor?.toHex() ?? t.col("layout_300");
    const bg = props.backgroundColor?.toHex() ?? "#101016";
    // const bg = props.backgroundColor?.toHex() ?? "#101016";
    // const bg = "#00000000";

    return (
        <div style={{
            width: "100%",
            height: props.h === undefined ? "100%" : (typeof props.h === "string" ? props.h : props.h.css()),
            backgroundColor: bg,
            paddingTop: "1rem",
            paddingBottom: "1rem",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            overflow: "scroll"
        }} children={
            <Editor
                className={"searchbar-input"}
                // height={"150px"}
                // height={"calc(100% - 0px)"}
                // width={"100%"}
                saveViewState
                value={props.value ?? ""}
                options={{
                    readOnly: props.readonly ?? false,
                    fontSize: props.fontSize ?? 14,
                    fontLigatures: true,
                    lineNumbers: "off",
                    autoIndent: "full",
                    cursorSmoothCaretAnimation: true,
                    smoothScrolling: true,
                    codeLens: false,
                    // autoSurround: "brackets",
                    automaticLayout: true,
                    autoClosingBrackets: "always",
                    autoClosingQuotes: "always",
                    hideCursorInOverviewRuler: true,
                    lineDecorationsWidth: 0,
                    renderValidationDecorations: "off",
                    overviewRulerBorder: false,
                    renderLineHighlight: "none",
                    cursorStyle: "underline",
                    matchBrackets: "always",
                    scrollbar: {
                        vertical: "hidden",
                        horizontal: "hidden"
                    },
                    minimap: {
                        enabled: false
                    },
                }}
                onChange={(value, ev) => {
                    props.onChange?.(value, ev);
                }}
                onMount={(editor, monaco) => {
                    // editor.updateOptions({
                    //     autoClosingBrackets: "beforeWhitespace",
                    //     // "semanticHighlighting.enabled": true
                    // })

                    props.onMount?.(editor);
                }}
                beforeMount={monaco => {
                    monaco.languages.register({ id: "sd-prompt" });

                    monaco.languages.setLanguageConfiguration("sd-prompt", {
                        brackets: [
                            ["{", "}"],
                            ["(", ")"],
                            ["{", "}"],
                            ["<", ">"],
                            ["'", "'"],
                            ["\"", "\""],
                        ],
                    })

                    monaco.languages.setMonarchTokensProvider("sd-prompt", {
                        tokenizer: {
                            root: [
                                [/<lora:.+>/, "full-keyword"],
                                [/\.\./, "keyword"],
                                [/:[\w<>=]+/, "keyword"],
                                [/varargs/, "keyword"],
                                [/var/, "keyword"],
                                [/val/, "keyword"],
                                [/new/, "keyword"],
                                [/yield/, "keyword"],
                                [/return/, "keyword"],
                                [/true/, "symbol"],
                                [/false/, "symbol"],
                                [/any/, "symbol"],
                                [/void/, "symbol"],
                                [/never/, "symbol"],
                                [/fn/, "keyword"],
                                [/lambda/, "keyword"],
                                [/class/, "keyword"],
                                [/struct/, "keyword"],
                                [/interface/, "keyword"],
                                [/while/, "keyword"],
                                [/for/, "keyword"],
                                [/if/, "keyword"],
                                [/else/, "keyword"],

                                // Experimental keywords
                                [/do/, "keyword"],
                                [/goto/, "keyword"],
                                [/jump/, "keyword"],
                                [/\w+:/, "label"],
                                [/import/, "keyword"],
                                [/as/, "keyword"],
                                [/use/, "keyword"],
                                [/with/, "keyword"],
                                [/implements/, "keyword"],
                                [/native/, "keyword"],
                                [/external/, "keyword"],
                                [/operator/, "keyword"],
                                [/number/, "keyword"],
                                [/bit/, "keyword"],
                                [/bool/, "keyword"],
                                [/this/, "symbol"],
                                [/ctx/, "symbol"],
                                [/macro/, "keyword"],


                                [/\$\w+(\.\w+)*/, "variable"],
                                [/\([\w,\s@]+:(\d|(\d.\d))\)/, "full-keyword"],
                                [/!/, "symbol"],
                                [/#(.)+/, "comment"],
                                [/\/\*.*\*\//, "comment"],
                                [/-\w+/, "param"],
                                [/@\w+/, "annotation"],
                                [/->/, "arrow-right"],
                                [/=>/, "arrow-right"],
                                [/-/, "bullet-point"],
                                [/:/, "double-point"],
                                [/,/, "symbol"],
                                [/;/, "symbol"],
                                [/(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)/, "number"],
                                [/\w+/, "literal"],
                                // units
                                [/'.*'/, "string"],
                                [/".*"/, "string"],
                                [/mb/, "unit"],
                                // keywords
                                [/gb/, "unit"],
                            ]
                        }
                    });

                    monaco.editor.defineTheme("ses-x-dark-tritanopia-notes", {
                        base: "vs-dark",
                        inherit: true,
                        rules: [
                            { token: "label", foreground: "#A782BB" },
                            { token: "arrow-right", foreground: "#A782BB" },
                            { token: "unit", foreground: "#A782BB" },
                            { token: "bullet-point", foreground: "#585858" },
                            { token: "double-point", foreground: "#585858" },
                            { token: "comment", foreground: "#585858" },
                            { token: "param", foreground: "#585858" },
                            { token: "full-keyword", foreground: "#CA7732" },
                            { token: "symbol", foreground: "#CA7732" },
                            { token: "keyword", foreground: "#CA7732" },
                            { token: "semicolon", foreground: "#CA7732" },
                            { token: "method", foreground: "#FFC66D" },
                            { token: "tag", foreground: "#FFC66D" },
                            { token: "macro", foreground: "#FFC66D" },
                            // { token: "variable", foreground: "#FFC66D" },
                            { token: "variable", foreground: "#CA7732" },
                            { token: "annotation", foreground: "#FFC66D" },
                            { token: "number", foreground: "#A782BB" },
                            { token: "literal", foreground: "#FFC66D" },
                            // { token: "string", foreground: "#FFC66D" },
                            { token: "string", foreground: "#6A8759" },
                        ],
                        colors: {
                            "editor.background": bg,
                            "editor.lineHighlightBackground":  bg,
                        }
                    });

                    /**
                     * TODO: Implement
                     *
                     * @param range
                     */
                    function syntacticSugar(range: any): Array<any> {
                        return [];
                    }

                    function createDependencyProposals(range: any): Array<any> {
                        return [
                            {
                                label: '"call"',
                                kind: monaco.languages.CompletionItemKind.Function,
                                documentation: "Generate a call statement",
                                insertText: '@${1:func}(${2});',
                                insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                                range: range,
                            },
                        ];
                    }

                    monaco.languages.registerCompletionItemProvider("sd-prompt", {
                        provideCompletionItems: function (model, position) {
                            const word = model.getWordUntilPosition(position);
                            const range = {
                                startLineNumber: position.lineNumber,
                                endLineNumber: position.lineNumber,
                                startColumn: word.startColumn,
                                endColumn: word.endColumn,
                            };
                            return {
                                suggestions: createDependencyProposals(range),
                            };
                        },
                    });
                }}
                theme={"ses-x-dark-tritanopia-notes"}
                language={"sd-prompt"}
            />
        }/>
    );
}
