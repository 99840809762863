// Images (for background usage)
import FallbackBackgroundImage from "../../assets/gifs/wallpaper.gif";

export class BackgroundImages {

    private static LOCALSTORAGE_ITEM_PREFIX = "__ardai-bg-image__";

    public static SCREENSAVER_STORAGE_ID = `${BackgroundImages.LOCALSTORAGE_ITEM_PREFIX}-screensaver`;

    public static MAIN_APP_STORAGE_ID = `${BackgroundImages.LOCALSTORAGE_ITEM_PREFIX}-main`;

    public static get screensaverBackgroundImage(): string {
        return FallbackBackgroundImage;
    }

    public static get mainAppBackgroundImage(): string {
        return FallbackBackgroundImage;
    }
}
