import React, {useContext, useEffect, useMemo, useState} from "react";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {ArdaiAPIStateContext} from "../ArdaiMain";
import {VFSElement} from "../data/VFSElement";
import {ButtonGroup} from "./ButtonGroup";
import {IconButton} from "./IconButton";
import {
    ArrowLeftRounded,
    CreateNewFolderRounded,
    CreateRounded,
    MoreVertRounded,
    UploadRounded, WarningAmberRounded
} from "@mui/icons-material";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {Menu} from "./Menu";
import {MenuButton} from "./MenuButton";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {BooleanContext} from "../../triton/components/BooleanContext";
import {FileStructureImportModal} from "./FileStructureImportModal";
import {MenuDivider} from "@szhsin/react-menu";
import {webDB} from "../webapi/WebAPIDB";
import {FlatIconButton} from "./FlatIconButton";

export const LocalVFSNav: React.FC = props => {
    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);
    const [currentFolder, setCurrentFolder] = useState<VFSElement | undefined>(undefined);
    useEffect(() => {
        api.getCurrentElement().then(ce => {
            setCurrentFolder(ce);
        });
    }, [api, state]);

    const isAtRoot = useMemo(() => api.state.fvsPath.length === 0, [state.fvsPath]);

    return (
        <div style={{
            display: "grid",
            width: "100%",
            gridTemplateColumns: "auto min-content",
            gap: "8px",
            alignItems: "center"
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "center"
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <IconButton deactivated={isAtRoot} size={"small"} tooltip={"Go to root"} children={<>//</>} onClick={() => {
                        api.goToRoot();
                    }}/>

                    <FlatIconButton
                        deactivated={isAtRoot}
                        tooltip={"Go back"}
                        children={<ArrowLeftRounded fontSize={"small"}/>}
                        onClick={() => {
                            api.goUpOneLevel();
                        }}
                    />
                </div>
                <MainTypography text={currentFolder?.title ?? ".."}/>
            </div>

            <Menu opener={
                <FlatIconButton children={<MoreVertRounded fontSize={"small"}/>}/>
            }>
                <MenuButton text={"Create project"} icon={<CreateRounded/>} appendix={<DescriptiveTypography text={"Ctrl+N"}/>} onSelect={() => {
                    api.toggleProjectCreationDialog(true);
                }}/>

                <MenuButton text={"Create folder"} icon={<CreateNewFolderRounded/>} onSelect={() => {
                    api.toggleFolderCreationDialog(true);
                }}/>

                <BooleanContext children={(bool, setBool) => (
                    <>
                        <FileStructureImportModal open={bool} onClose={() => setBool(false)}/>
                        <MenuButton text={"Import"} icon={<UploadRounded/>} appendix={<DescriptiveTypography text={"Ctrl+U"}/>} onSelect={() => {
                            setBool(true)
                        }}/>
                    </>
                )}/>


                <MenuDivider/>

                <MenuButton text={"Power actions"} icon={<WarningAmberRounded color={"warning"}/>} keepOpenOnClick submenu={<>
                    <MenuButton text={"Delete all projects"} onSelect={async () => {
                        const projects = currentFolder?.projects ?? [];
                        if (projects === undefined) {
                            window.alert("No projects")
                            return;
                        }

                        window.alert(projects.length)

                        const num = await webDB.vfsElements
                            .where("path")
                            .equals(state.fvsPath.join("/"))
                            .and(e => e.type === "project")
                            .delete()

                        window.alert(num)
                    }}/>
                </>}/>

            </Menu>
        </div>
    );
}
