import React, {useContext} from "react";
import styled from "styled-components";
import {CommunityHighlight} from "./CommunityHighlight";
import {Text} from "../../../../triton/components/typography/Text";
import {UserProfileContext} from "./UserProfile";
import {IconBadge} from "./IconBadge";

const StyledCommunityHighlightCard = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  gap: 8px;

  .community-icon-tray {
    .community-icon {
      width: 38px;
      aspect-ratio: 1 / 1;
      border-radius: 12px;
      user-select: none;
      -webkit-user-drag: none;
    }
  }

  .community-stats {
    display: grid;
    grid-template-columns: auto min-content;
    gap: 4px;
    align-items: center;

    .community-info {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .community-title {
        display: grid;
        grid-template-columns: auto minmax(min-content, 1fr);
        gap: 4px;
        align-items: center;
      }
    }

    .community-coa {
      .join-community-btn {
        border: none;
        border-radius: 4px;
        padding: 0 16px;
        height: 30px;
        align-items: center;
        display: flex;
        background-color: rgba(36, 128, 70, 1);
        color: white;
        cursor: pointer;
        user-select: none;
        transition: background-color .17s ease, color .17s ease;
        
        &:hover {
          background-color: rgb(26, 99, 52);
        }
        
        &:active {
          background-color: rgb(21, 86, 43);
        }
      }
    }
  }
`;

export type CommunityHighlightCardProps = {
    communityHighlight: CommunityHighlight
}

export const CommunityHighlightCard: React.FC<CommunityHighlightCardProps> = props => {
    const ctx = useContext(UserProfileContext);
    const fc = ctx.getFontColours();
    const ch = props.communityHighlight;

    return (
        <StyledCommunityHighlightCard>
            <div className={"community-icon-tray"}>
                <img className={"community-icon"} alt={"community-icon"} src={ch.iconUrl}/>
            </div>
            <div className={"community-stats"}>
                <div className={"community-info"}>
                    <div className={"community-title"}>
                        <Text col={fc.main} ff={"Whitney SemiBold"} t={ch.name}/>
                        <IconBadge size={16} children={
                            <img alt={"verified icon badge"} src={require("./verified-icon.png")}/>
                        }/>
                    </div>
                    <Text col={fc.text} fs={12} ff={"Whitney Light"} t={`${ch.memberCount} members`}/>
                </div>
                <div className={"community-coa"}> {/* call-of-action */}
                    <button className={"join-community-btn"} children={
                        "Join"
                    }/>
                </div>
            </div>
        </StyledCommunityHighlightCard>
    );
}
