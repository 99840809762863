import {SSPPPacket} from "./SSPPPacket";

export function sendSSPPPacket(socket: WebSocket, type: string, dataConstructor: (data: Map<string, string>) => void) {
    const data: Map<string, string> = new Map<string, string>();
    dataConstructor(data);
    const packet: SSPPPacket = {
        packetType: type,
        packetPayload: data
    };
    const serializedPacket = JSON.stringify(packet, function replacer(key, value) {
        if (value instanceof Map) return Object.fromEntries(value);
        else return value;
    });
    socket.send(serializedPacket);
}
