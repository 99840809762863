import {Color} from "../../../base/logic/style/Color";
import {ColorSelectorProfile} from "./ColorSelectorProfile";

export class ColorSelector {

    private readonly profile: ColorSelectorProfile;

    private readonly defaultColor: Color;

    constructor(profile: ColorSelectorProfile, defaultColor: Color = Color.black) {
        this.profile = profile;
        this.defaultColor = defaultColor;
    }

    public get color(): Color {
        for (let cond of this.profile.if)
            if (cond.cond())
                return cond.color;

        return this.profile.default ?? this.defaultColor;
    }
}
