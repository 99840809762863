import React, {useRef} from "react";
import styled from "styled-components";
import {RoundedFormikBaseInput, RoundedFormikBaseInputProps} from "./RoundedFormikBaseInput";
import {CalendarTodayRounded} from "@mui/icons-material";

const StyledRoundedFormikDateInput = styled.div`
  position: relative;
  width: 100%;

  input[type=date] {
    -webkit-appearance: none;
    text-align: left;
  }
  
  input[type=date]::-webkit-inner-spin-button,
  input[type=date]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  
  .adornment {
    position: absolute;
    right: 0;
    padding-right: 27px;
    top: 50%;
    transform: translateY(-50%);
    color: black;
    opacity: .6;
    display: flex;
    height: 100%;
    align-items: center;
  }
`;

export type RoundedFormikDateInputProps = RoundedFormikBaseInputProps & {}

export const RoundedFormikDateInput: React.FC<RoundedFormikDateInputProps> = props => {
    const input = useRef<HTMLInputElement>(null);

    return (
        <StyledRoundedFormikDateInput>
            <RoundedFormikBaseInput
                {...props}
                ref={input}
                type={"date"}
            />
            <span className={"adornment"} onClick={() => {
                try {
                    input.current!.showPicker()
                } catch (e) {
                    window.alert(e)
                }
            }} children={
                <CalendarTodayRounded/>
            }/>
        </StyledRoundedFormikDateInput>
    );
}
