import {webDB} from "../webapi/WebAPIDB";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ArdaiAPIStateContext} from "../ArdaiMain";
import {useLiveQuery} from "dexie-react-hooks";
import {VFSElementCard} from "./VFSElementCard";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {Workspace, WorkspaceContext} from "./Workspace";
import {HOCWrapper} from "../../base/components/HOCWrapper";
import {ProjectView} from "./ProjectView";
import {Screen} from "../../base/components/base/Page";
import {MobileProjectWorkspaceLayout} from "./MobileProjectWorkspaceLayout";
import {CategoryHeader} from "../_/CategoryHeader";
import {BooleanContext} from "../../triton/components/BooleanContext";
import {AddRounded, CheckBoxRounded, CreateRounded, PlusOneOutlined} from "@mui/icons-material";
import {ProjectListView} from "../_/ProjectListView";
import {FolderListView} from "../_/FolderListView";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import axios from "axios";
import ReactJson from "react-json-view";
import {ButtonBase} from "../../triton/components/buttons/ButtonBase";
import {FSStateMirror} from "../webapi/fs/FSStateMirror";
import {FSManager} from "../webapi/fs/FSManager";
import {v4} from "uuid";
import styled from "styled-components";
import {LocalVFSNav} from "./LocalVFSNav";
import {Dot} from "./Dot";
import {Tag} from "./Tag";

const StyledVFSFolderContentViewer = styled.div`
  height: 100%;
  overflow-y: hidden;
  display: grid;
  grid-template-rows: min-content auto;
  gap: 8px;
`;

export function VFSFolderContentViewer() {
    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);
    const workspace = useContext(WorkspaceContext);

    const currentFolder = useLiveQuery(async () => await api.getCurrentElement(), [state.fvsPath]);

    const elements = useLiveQuery(async () => {
        const currentFolder = await api.getCurrentElement();
        return webDB.vfsElements
            .where("path")
            .equals(api.getElementPath(currentFolder!))
            .toArray();
    }, [state.fvsPath]);

    // const folders = elements?.filter(e => e.type === "folder") ?? [];
    // const projects = elements?.filter(e => e.type === "project") ?? [];

    enum NetLSState { LOADING, LOADED, ERROR , INITIAL}
    const [netLSState, setNetLSState] = useState<NetLSState>(NetLSState.INITIAL);
    const [netLS, setNetLS] = useState<any>(null);

    const loadNetLS = () => {
        if (netLSState === NetLSState.LOADING) return;
        setNetLSState(NetLSState.LOADING)
        axios.get(`http://127.0.0.1:8080/fs/ls/${"morning coffee"}`).then(res => {
            setNetLS(res.data)
            setNetLSState(NetLSState.LOADED)
        }).catch(() => {
            setNetLSState(NetLSState.ERROR)
        });
    }

    // useEffect(() => loadNetLS(), []);


    if (elements === undefined) {
        return (
            <></>
        );
    }

    return (
        <StyledVFSFolderContentViewer>
            <LocalVFSNav/>

            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                overflowY: "scroll"
            }}>
                <BooleanContext initial={true} children={(bool, setBool) => (
                    <div>
                        <CategoryHeader
                            title={"Folders"}
                            expanded={bool}
                            onExpandToggle={setBool}
                            appendix={<AddRounded sx={{ fontSize: 20 }}/>}
                        />

                        { (currentFolder !== undefined) && (
                            <FolderListView path={api.getElementPath(currentFolder)} expanded={bool}/>
                        ) }
                    </div>
                )}/>

                { (currentFolder !== undefined) && (
                    <ProjectListView
                        path={api.getElementPath(currentFolder)}
                    />
                ) }
            </div>
        </StyledVFSFolderContentViewer>
    );
}
