import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {NodeDisplay} from "../../NodeDisplay";
import {FC, useEffect, useRef, useState} from "react";
import {Pin, PinMode} from "../../../backend/Pin";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {
    ArrowRightRounded,
    CheckBoxOutlineBlankRounded,
    CheckBoxRounded,
    InputRounded,
    LooksOneRounded,
    LooksTwoRounded, MoreHorizRounded, OpenInFullRounded,
    OutputRounded,
    VisibilityRounded
} from "@mui/icons-material";
import styled from "styled-components";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";
import {useTriton} from "../../../../triton/TritonHooks";
import {Triton} from "../../../../triton/Triton";
import {NodeCanvasBackend, useNodeCanvasBackend} from "../../NodeCanvasBackend";
import _ from "lodash";
import {useForceRenderFunc} from "../../../../ForceRerenderHook";
import {Tag} from "../../../../ardai/components/Tag";
import {NodeCanvasBackendEventTypes} from "../../NodeCanvasBackendEventTypes";
import {NodeEvent} from "../../../backend/NodeEvent";
import {SaveStateNode} from "../../../backend/serialization/v2/SaveStateNode";
import {Adder} from "../mathematics/Adder";
import {Multiplier} from "../mathematics/Multiplier";
import {Divider} from "../mathematics/Divider";
import {NumericInput} from "../inputs/NumericInput";
import {AutoSizeDisplay} from "../visualization/AutoSizeDisplay";
import {AdvancedNumericInput} from "../inputs/AdvancedNumericInput";
import {ArithmeticComparer} from "../arithmetic/ArithmeticComparer";
import {IfCondition} from "../logic/IfCondition";
import {NodeHttpRequest} from "../network/NodeHttpRequest";
import {SignalFrequencySensor} from "../sensors/SignalFrequencySensor";
import {NodePort} from "../system/NodePort";
import {NodeSystemStdOut} from "../system/NodeSystemStdOut";
import {OutputPortConfig} from "../system/OutputPortConfig";
import {SystemEnvironmentVariable} from "../system/SystemEnvironmentVariable";
import {AbsoluteNumeric} from "../mathematics/AbsoluteNumeric";
import {AdvancedOscillator} from "../AdvancedOscillator";
import {AdvancedPlotter} from "../visualization/AdvancedPlotter";
import {BitField} from "../inputs/BitField";
import {BitFieldArray} from "../inputs/BitFieldArray";
import {Boolean} from "../inputs/Boolean";
import {Comparer} from "../Comparer";
import {Counter} from "../Counter";
import {CReg} from "../CReg";
import {NodeFrequencyToPeriodConverter} from "../NodeFrequencyToPeriodConverter";
import {HSVtoRGBConvertor} from "../colors/HSVtoRGBConvertor";
import {IEEE754FP32} from "../_deprecated/IEEE754FP32";
import {LED} from "../colors/LED";
import {ManualPulse} from "../ManualPulse";
import {Multiplexer} from "../Multiplexer";
import {NodeStringInput} from "../inputs/NodeStringInput";
import {Oscillator} from "../Oscillator";
import {RandomGen} from "../mathematics/RandomGen";
import {SimplePinLogger} from "../visualization/SimplePinLogger";
import {SineWaveGen} from "../mathematics/SineWaveGen";
import {Switch} from "../Switch";
import {WavePlotter} from "../visualization/WavePlotter";
import {NodeEnumSelector} from "../NodeEnumSelector";
import {NodeMathMap} from "../mathematics/NodeMathMap";
import {NodeTBAdapter} from "../tb/NodeTBAdapter";
import {NodeCableTieSingle} from "../organization/NodeCableTieSingle";
import {Inflatable} from "../../../backend/serialization/v2/Inflatable";
import {InflationContext} from "../../../backend/serialization/v2/InflationContext";
import {NodeEnvironment} from "../../../host/NodeEnvironment";
import {ArrayLinkImpl} from "../../../../../std/Accumulate";
import {Modal} from "../../../../triton/components/dialogs/Modal";
import {ButtonModalCompound} from "../../../../ardai/components/ButtonModalCompound";
import {NodeCanvas} from "../../NodeCanvas";
import {createDevelopmentNodeEnvironment} from "../../../tests/StandaloneNodeSandbox";
import {vw} from "../../../../base/logic/style/DimensionalMeasured";
import {useNodeAppBackend} from "../../app/NodeAppBackend";
import {NodeQueue} from "../data/NodeQueue";
import {Menu} from "../../../../ardai/components/Menu";
import {MenuButton} from "../../../../ardai/components/MenuButton";

export const NodeSubCanvasPrototype: NodeSetupInfo = {
    label: "NodeSubCanvasPrototype",
    classname: "prototypes.sub-canvas",
    parameterConfig: [],
    factory: parameters => new Node<NodeSubCanvasPrototypeDisplayState>({
        id: v4(),
        classname: "prototypes.sub-canvas",
        label: "sub",
        state: {
            isSetupCompleted: false
        },
        init: node => {

        },
        customRenderer: (node) => (
            <NodeSubCanvasPrototypeDisplay
                node={node}
            />
        )
    })
}

type NodeSubCanvasPrototypeDisplayState = {
    isSetupCompleted: boolean
}

const NodeSubCanvasPrototypeDisplay: FC<{
    node: Node<NodeSubCanvasPrototypeDisplayState>
}> = props => {
    const t = useTriton();
    const thisNode = props.node;
    const state = thisNode.state.state;

    const parentBackend = useNodeCanvasBackend();
    const parentEnv = parentBackend.environment;

    const subEnvRef = useRef(createDevelopmentNodeEnvironment());
    const subEnv = subEnvRef.current;

    const app = useNodeAppBackend();

    // Return setup view if not yet set up
    if (!state.isSetupCompleted) {
        const runSetup = () => {
            // Get selection
            const nodes = parentBackend.getAllSecondarySelectedNodes();

            // Deflate selection
            const serializedSave = new ArrayLinkImpl(nodes)
                .forEach(x => console.log("deflating node", x))
                .map(node => (node as unknown as Inflatable).deflate?.())
                .filter(x => x !== undefined)
                .map(x => x!)
                .accumulateAsDispatch<SaveStateNode[]>([], (x1, x2) => x1.push(x2))
                .also(x => console.log(x))
                .run(save => JSON.stringify(save))
                .unboxed

            // Call: inflate
            inflate(subEnv, serializedSave);

            // Bridge output pins
            const outTunnel = subEnv.getNodesOfClass("node.output-configuration")[0];
            const inTunnel = subEnv.getNodesOfClass("node.input-configuration")[0];

            const pins = thisNode.pins;
            inTunnel.pins.allOut.forEach(subInPin => {
                pins.in(subInPin.label).attachOnRead((data: any) => {
                    subInPin.write(data);
                });
            });

            outTunnel.pins.allIn.forEach(subOutPin => {
                const parentOutPin = pins.out(subOutPin.label);
                subOutPin.attachOnRead(data => {
                    parentOutPin.write(data);
                });
            });

            thisNode.state.update({
                isSetupCompleted: true
            });
        }

        const currentSelectionLength = parentBackend.getAllSecondarySelectedNodes().length;
        const isAnySelected = currentSelectionLength > 0;

        return (
            <div style={{
                width: "100%",
                padding: "8px 0",
                display: "grid"
            }}>
                <Tag
                    disabled={!isAnySelected}
                    applyActiveScaling
                    tag={`Setup (${parentBackend.getAllSecondarySelectedNodes().length})`}
                    onClick={runSetup}
                />
            </div>
        );
    }

    // Setup completed, can display main view
    return (
        <div style={{
            width: "100%",
            padding: "8px 0",
            display: "grid"
        }}>
            <Menu opener={
                <Tag tag={
                    <MoreHorizRounded sx={{
                        fontSize: 14
                    }}/>
                }/>
            }>
                <MenuButton text={"Print nodes"} onSelect={() => console.log(subEnv.nodes)}/>
                <MenuButton text={"Reset"} onSelect={() => {
                    thisNode.state.update({
                        isSetupCompleted: false
                    });
                    subEnv.nodes = [];
                }}/>
                <MenuButton text={"Open"} icon={<OpenInFullRounded/>} onSelect={() => app.tabs.manager.create({
                    label: "sub",
                    isActive: false,
                    isClosable: true,
                    tabBodyRenderer: tab => {
                        return (
                            <NodeCanvas
                                // TODO: Add id to canvas
                                env={subEnv}
                                nodes={[]}
                            />
                        );
                    }
                })}/>
            </Menu>
        </div>
    );
}

function inflate(env: NodeEnvironment, serializedSave: string) {
    const save = JSON.parse(serializedSave) as Array<SaveStateNode>;

    const nodeLib = [
        Adder, Multiplier, Divider, NumericInput, AutoSizeDisplay,
        AdvancedNumericInput,
        ArithmeticComparer,
        IfCondition,
        NodeHttpRequest,
        SignalFrequencySensor,
        NodePort,
        NodeSystemStdOut,
        OutputPortConfig,
        SystemEnvironmentVariable,
        AbsoluteNumeric,
        Adder,
        AdvancedOscillator,
        AdvancedPlotter,
        AutoSizeDisplay,
        BitField,
        BitFieldArray,
        Boolean,
        Comparer,
        Counter,
        CReg,
        Divider,
        NodeFrequencyToPeriodConverter,
        HSVtoRGBConvertor,
        IEEE754FP32,
        LED,
        ManualPulse,
        Multiplexer,
        Multiplier,
        NodeStringInput,
        NumericInput,
        Oscillator,
        RandomGen,
        SimplePinLogger,
        SineWaveGen,
        Switch,
        WavePlotter,
        NodeEnumSelector,
        NodeMathMap,
        // tb
        NodeTBAdapter,
        // organization
        NodeCableTieSingle,
        // prototyping
        NodeSubCanvasPrototype,

        NodeQueue
    ];

    function resolveNodeClass(classname: string): NodeSetupInfo {
        const nodeClass = nodeLib.find(node => node.classname === classname);
        if (nodeClass === undefined) throw new Error(`Class '${classname}' not found`);
        return nodeClass;
    }

    const savesWithNodes = new Map<SaveStateNode, Node>();

    save.forEach(nodeSave => {
        const nodeData = nodeSave.data;
        const nodeClass = resolveNodeClass(nodeData.classname as string);
        const parameters = new Map<string, any>();
        const node = nodeClass.factory(parameters);
        node.id = nodeSave.id;
        env.registerNode(node);
        savesWithNodes.set(nodeSave, node);
    });

    // General inflation step
    Array.from(savesWithNodes.entries()).forEach(e => {
        (e[1] as unknown as Inflatable)["inflate"]?.(e[0].data, e[0], new InflationContext({}))
    });

    Array.from(savesWithNodes.entries()).forEach(e => {
        e[1].init();
    });

    // Wiring inflation step
    Array.from(savesWithNodes.entries()).forEach(e => {
        (e[1] as unknown as Inflatable)["inflate"]?.(e[0].data, e[0], new InflationContext({
            phase: "wiring"
        }))
    });
}
