import React from "react";
import styled from "styled-components";
import {ArcText} from "../../ArcText";

const StyledDecimalPinDisplay = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &[data-incorrect=true] {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

const StyledDecimalPinTray = styled.div`
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 78px;
  min-width: 60px;
  border-radius: 500px;
  transition: all 100ms;

  p {
    color: black !important;
  }

  &[data-incorrect=true] {
    background: #ffbaba;
  }

  &.active {
    background-color: #FFEAC4 !important;
  }
`;

export type DecimalPinDisplayProps = {
    len: number,
    pin: string,
    status: "initial" | "processing" | "correct-pin" | "wrong-pin"
}

export const DecimalPinDisplay: React.FC<DecimalPinDisplayProps> = props => {
    return (
        <StyledDecimalPinDisplay data-incorrect={props.status === "wrong-pin"} children={
            Array.from({length: props.len}, (_, i) => i).map(n => (
                <StyledDecimalPinTray data-incorrect={props.status === "wrong-pin"} key={`dec-pin-tray-${n}`} className={props.pin.length === n ? "active" : ""} children={
                    <ArcText variant={"artistic-display"} text={props.pin.at(n)}/>
                }/>
            ))
        }/>
    );
}
