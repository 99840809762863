import React from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {ChevronLeftRounded} from "@mui/icons-material";

const StyledBackButton = styled.button`
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  transition: all 500ms;
  
  &:disabled {
    svg {
      color: rgba(255, 255, 255, .6);
    }
  }
  
  svg {
    width: 20px;
    color: #B55EF9;
  }
`;

export type BackButtonProps = {
    text: string,
    onBack: () => void,
    deactivated?: boolean
}

export const BackButton: React.FC<BackButtonProps> = props => {
    return (
        <StyledBackButton disabled={props.deactivated} onClick={() => {
            if (!props.deactivated) {
                props.onBack()
            }
        }}>
            <ChevronLeftRounded/>
            <DescriptiveTypography text={props.text}/>
        </StyledBackButton>
    );
}
