import {NodeSetupInfo} from "../NodeSetupInfo";
import {Node} from "../../backend/Node";
import {v4} from "uuid";
import {Pin} from "../../backend/Pin";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";

export const Multiplexer: NodeSetupInfo = {
    label: "",
    classname: "multiplexer",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "multiplexer",
        label: "mux",
        defInPins: ["s"],
        defOutPins: ["o"],
        init() {
            const node = this;
            let idx = 0;
            const bank = this.pins.createPinBank("channels", {
                label: "channels",
                dynamicSizeFlag: true,
                hideLabelFlag: true
            });

            const createNewChannel = () => bank.pin(`${idx++}`);
            // const createNewChannel = () => bank.pin(``);

            const pushChannelContentToOutput = (channel: number) => {
                const selPin = Array.from(bank.pins.values())[channel];
                node.pins.out("o").write(selPin.lastReadState);
            }

            bank.attach({
                onPinRead(pin: Pin, value: any) {
                    const selChannel = node.pins.in("s").lastReadState ?? 0;
                    const selPin = Array.from(bank.pins.values())[selChannel];
                    if (selPin !== pin) return;
                    node.pins.out("o").write(value);
                },

                onPinNewInboundConnection(thisPin: Pin, fromPin: Pin) {
                    createNewChannel();
                }
            });

            node.pins.in("s").attach({
                read(selChannel: number) {
                    pushChannelContentToOutput(selChannel);
                }
            })

            createNewChannel();
        },
        customRenderer: node => (
            <div style={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
                padding: 0,
                alignItems: "center",
                height: "100%",
                justifyContent: "center"
            }}>
                <DescriptiveTypography
                    text={`${node.pins.in("s").lastReadState ?? 0}`}
                />
            </div>
        )
    })
}
