import React, {CSSProperties} from "react";
import styled from "styled-components";

const StyledSubHeaderTypography = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: white;
  letter-spacing: 1px;
  text-transform: uppercase;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-family: "Whitney", sans-serif;
  word-wrap: break-word;
  margin: 0;
`;

export type SubHeaderTypographyProps = {
    text?: React.ReactNode,
    style?: CSSProperties,
    id?: string,
    noSelect?: boolean
}

export const SubHeaderTypography: React.FC<SubHeaderTypographyProps> = props => {
    return (
        <StyledSubHeaderTypography
            children={props.text}
            style={{
                userSelect: props.noSelect ?? true ? "none" : "auto",
                ...props.style,
            }}
            id={props.id}
        />
    )
}
