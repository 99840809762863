import {FC, PropsWithChildren, ReactNode} from "react";
import {StaticStateExposedConfigProps, useStaticState} from "../hooks/StaticStateHook";
import {v4} from "uuid";
import {MenuDivider} from "@szhsin/react-menu";
import {FormDivider, FormDividerProps} from "../../triton/components/forms/FormDivider";
import {TransitionGroup} from "react-transition-group";
import {Tag} from "./Tag";
import {ChevronRightRounded} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import {useTriton} from "../../triton/TritonHooks";
import {Color} from "../../base/logic/style/Color";

export type MenuGroupProps = PropsWithChildren<{
    title?: string,
    showIndentationLine?: boolean,
    indentationLineColor?: Color,
    indentation?: string | number
    headerPropsOverwrites?: FormDividerProps,
    appendix?: ReactNode,
    boldTitle?: boolean,

    scrollOnOverflow?: boolean
}> & StaticStateExposedConfigProps

export enum MenuGroupExpansionState {
    COLLAPSED, MINIMAL, EXPANDED
}

export type MenuGroupState = {
    expansionState: MenuGroupExpansionState,
}

export const MenuGroup: FC<MenuGroupProps> = props => {
    const t = useTriton();
    const staticStateCfg = props.componentExposedStateConfig?.static;
    const [state, ctx] = useStaticState<MenuGroupState>({
        staticMode: staticStateCfg !== undefined && staticStateCfg.enabled !== false,
        id: staticStateCfg?.id ?? "__no_id__",
        initial: {
            expansionState: MenuGroupExpansionState.EXPANDED
        }
    }).stateWithCtx;

    return (
        <div>
            <FormDivider
                title={props.title}
                {...props.headerPropsOverwrites ?? {}}
                bold={props.boldTitle ?? false}
                appendix={<>
                    { props.appendix }

                    <Tag tag={
                        <ChevronRightRounded style={{
                            fontSize: 14,
                            transition: "all 200ms",
                            transform: `rotate(${
                                state.expansionState === MenuGroupExpansionState.COLLAPSED ? -90 : 90
                            }deg)`
                        }}/>
                    } onClick={() => ctx.update(prevState => ({
                        expansionState: prevState.expansionState === MenuGroupExpansionState.COLLAPSED ?
                            MenuGroupExpansionState.EXPANDED : MenuGroupExpansionState.COLLAPSED
                    }))}/>
                </>}
            />

            <TransitionGroup>
                { state.expansionState === MenuGroupExpansionState.EXPANDED && (
                    <Collapse>
                        <div style={{
                            overflowY: (props.scrollOnOverflow ?? false) ? "scroll" : undefined,
                            display: "grid",
                            gridTemplateColumns: "min-content auto",
                            gap: 0,
                            paddingTop: 8
                        }}>
                            <div className={"menu-group-body-indentation"} style={{
                                height: "100%"
                            }}>
                                { props.showIndentationLine && (
                                    <div style={{
                                        width: 1,
                                        height: "100%",
                                        backgroundColor:
                                            props.indentationLineColor?.css() ??
                                            // t.col("layout_300"),
                                            "#30363d",
                                        marginRight: props.indentation ?? 0
                                    }}/>
                                ) }
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 8
                            }}>
                                { props.children }
                            </div>
                        </div>

                    </Collapse>
                ) }
            </TransitionGroup>
        </div>
    );
}
