export class MemKVCache {

    private data: Map<any, any> = new Map<any, any>();

    private has<T>(key: T): boolean {
        return this.data.has(key);
    }

    private delete<T>(key: T): boolean {
        return this.data.delete(key)
    }

    private set<T, V>(key: T, value: V, overwrite: boolean = false): MemKVCache {
        if (this.has(key)) {
            if (overwrite) {
                this.data.set(key, value);
            }
            return this;
        } else this.data.set(key, value);
        return this;
    }

    public fetchSync<T, V>(key: T, syncFetcher: (key: T) => V): V {
        if (!this.has(key)) {
            const val = syncFetcher(key);
            this.set(key, val);
            return val;
        }
        else return this.data.get(key)!;
    }

    public async fetchAsync<T, V>(key: T, asyncFetcher: (key: T) => Promise<V>): Promise<V> {
        if (!this.has(key)) {
            const val = await asyncFetcher(key);
            this.set(key, val);
            return val;
        }
        else return this.data.get(key)!;
    }
}
