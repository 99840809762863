import React from "react";
import {Speedometer} from "../../../../test/engine/Speedometer";
import {Orientation} from "../../../../base/logic/style/Orientation";
import styled from "styled-components";
import {DefaultButton} from "../../../../triton/components/buttons/DefaultButton";
import {percent, px} from "../../../../base/logic/style/DimensionalMeasured";
import {
    AcUnit,
    HdrAutoRounded,
    ModeFanOffRounded, PercentRounded,
    PowerOffRounded,
    PowerSettingsNewRounded,
    SyncLockRounded
} from "@mui/icons-material";
import {ButtonBase} from "../../../../triton/components/buttons/ButtonBase";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {Interpolate} from "../../../../triton/components/advanced/Interpolate";
import {CircularProgress, Slider} from "@mui/material";
import {StyledControlDisplayGroup} from "../../components/StyledControlDisplayGroup";
import {Entity} from "../../ecs/entity/Entity";

const StyledCoreCoolerControlDisplay = styled.div`
  display: grid;
  // grid-template-columns: min-content auto;
  gap: 4px;
  width: 100%;
  // aspect-ratio: 1 / 1 !important;

  .core-indicator-panel {
    box-shadow: rgb(33, 38, 45) 0 0 0 1px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
    padding: 8px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
  }
  
  .cooler-controller {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 4px;
    
  }
`;

export type CoreCoolerControlDisplayProps = {
    entity: Entity
}

export const CoreCoolerControlDisplay: React.FC<CoreCoolerControlDisplayProps> = props => {
    const cooler = props.entity;
    const state = cooler?.state;
    const coolingForce = cooler?.state?.coolingForce ?? 0;
    const isCooling = coolingForce > 0;
    const isUnitActive = (cooler?.state?.manActive ?? false) || isCooling;

    return (
        <StyledCoreCoolerControlDisplay>
            {/*
            <Speedometer
                val={coolingForce}
                width={px(10)}
                orientation={Orientation.VERTICAL}
                pure
            />
            <div className={"cooler-controller"}>
                <ButtonBase children={
                    <span style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }} children={
                        <AcUnit sx={{
                            fontSize: 16,
                            transition: "all 100ms",
                            color: state?.manActive ? "#ffdf60" : "rgb(201,209,217)"
                        }}/>
                    }/>
                } baseProps={{
                    // style: { padding: 0, aspectRatio: "1 / 1" },
                    onClick: () => {
                        cooler.setState(prevState => ({
                            ...prevState,
                            manActive: !prevState.manActive,
                            active: true,
                            coolingForce: 50
                        }));
                    }
                }}/>
            </div>
            */}


            <div className={"core-indicator-panel"}>
                <AcUnit sx={{
                    fontSize: 16,
                    transition: "color 1s",
                    // color: !isCoolerActive ? ("rgba(201, 209, 217, 0.1)") : (
                    //     !isUnitActive ? "#f14234" : "#58a6ff"
                    // )
                    color: !isUnitActive ? ("rgba(201, 209, 217, 0.1)") : (
                        "#58a6ff"
                    )
                }}/>
                <Interpolate value={coolingForce}/>


                <HdrAutoRounded sx={{
                    fontSize: 16,
                    transition: "all 100ms",
                    color: state?.manOverruling ? "#ffdf60" : "rgba(201, 209, 217, 0.1)"
                }} onClick={() => {
                    cooler.setState(prevState => ({
                        ...prevState,
                        manOverruling: !prevState.manOverruling,
                    }));
                }}/>
                <CircularProgress size={16} variant={"determinate"} value={coolingForce} sx={{
                    transition: "color 1s",
                    color: !isCooling ? "rgba(201, 209, 217, 0.1)" : (
                        "#60ffc7"
                    ),
                }}/>


                <PowerSettingsNewRounded sx={{
                    fontSize: 16,
                    transition: "all 100ms",
                    color: state?.manActive ? "#60ffc7" : "rgba(201, 209, 217, 0.1)"
                }} onClick={() => {
                    cooler.setState(prevState => ({
                        ...prevState,
                        manActive: !prevState.manActive,
                    }));
                }}/>

                <StyledControlDisplayGroup style={{ padding: "16px 0", justifyContent: "center", alignItems: "center", overflow: "hidden" }} children={
                    <div style={{ height: "100%" }} children={
                        <Slider value={state?.manOverrulingCoolingForce ?? 0} orientation={"vertical"} size={"small"} sx={{
                            transition: "color 1s",
                            color: !(state?.manOverruling ?? false) ? "rgba(201, 209, 217, 0.1)" : (
                                (state?.coolingForce < state.manOverrulingCoolingForce) ? "#ffdf60" : "#60ffc7"
                            ),
                        }} onChange={(e, v) => {
                            cooler.setStatePartially({
                                manOverrulingCoolingForce: v as number
                            });
                        }}/>
                    }/>
                }/>
            </div>
        </StyledCoreCoolerControlDisplay>
    );
}
