import {NodeSetupInfo} from "../NodeSetupInfo";
import {Node} from "../../backend/Node";
import {v4} from "uuid";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {DefaultCharacterSymbols} from "../DefaultCharacterSymbols";

export const CReg: NodeSetupInfo = {
    label: "c-reg",
    classname: "clock-based-register",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "clock-based-register",
        label: "reg",
        // description: "clock-based",
        defInPins: ["i", "c"],
        defOutPins: ["o"],
        init() {
            this.pins.in("c").attach({
                read() {
                    const i = this.node.pins.in("i").lastReadState;
                    this.node.pins.out("o").write(i);
                }
            })
        },
        customRenderer: ((parameters.get("is-show-value") ?? false) === true) ? (node => (
            <div style={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
                padding: 0,
                alignItems: "center",
                height: "100%",
                justifyContent: "center"
            }}>
                <DescriptiveTypography
                    text={`${node.pins.in("i").lastReadState ?? DefaultCharacterSymbols.placeholder}`}
                />
            </div>
        )) : undefined
    })

}
