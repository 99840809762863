import React from "react";
import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";

const StyledBreadcrumb = styled.div`
  cursor: pointer;
  padding: 4px 8px;
  height: 32px;
  color: rgb(148, 155, 164);
  background-color: #DBDEE100;
  border-radius: 6px;
  transition: color, background-color 100ms;
  display: flex;
  position: relative;
  align-items: center;
  
  &:hover {
    background-color: #1e202a;
    
    & > * {
      color: #DBDEE1;
    }
  }
`;

export type BreadcrumbProps = {
    onClick: () => void,
    text: string
}

export const Breadcrumb: React.FC<BreadcrumbProps> = props => {
    return (
        <StyledBreadcrumb onClick={() => props.onClick()}>
            <DescriptiveTypography text={props.text} noSelect/>
        </StyledBreadcrumb>
    );
}
