import React, {FC, InputHTMLAttributes} from "react";
import {FormikInput} from "./FormikInput";
import {useTriton} from "../../TritonHooks";

export type TritonTextAreaProps = {
    name?: string,
    autoFocus?: boolean,
    renderError?: boolean,
    placeholder?: string,
    baseProps?: InputHTMLAttributes<any>,
    value?: string | number,
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>, value: string) => void
}

export const TritonTextArea: FC<TritonTextAreaProps> = props => {
    const t = useTriton();

    return (
        <FormikInput t={t} className={props.renderError ?? false ? "error" : ""} children={
            <textarea
                {...props.baseProps}
                autoFocus={props.autoFocus}
                className={"input"}
                placeholder={props.placeholder}
                name={props.name}
                autoComplete={"off"}
                value={props.value}
                onChange={e => {
                    props.onChange?.(e, e.currentTarget.value);
                }}
                onBlur={event => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
                style={{
                    ...props.baseProps?.style ?? {},
                    resize: "vertical"
                }}
            />
        }/>
    );
}
