import {InflationLibGlobals} from "./InflationLibGlobals";
import "reflect-metadata";

/**
 * Dev note:
 *   Getting 'TypeError: Reflect.getOwnMetadata is not a function'?
 *   Check if this file imports 'reflect-metadata'
 *   <code>import "reflect-metadata";(</code>
 *
 * @param target
 * @param propertyKey
 */
export function transient(target: Object, propertyKey: string | symbol) {
    let existingInflateTargets: (string | symbol)[] = Reflect.getOwnMetadata(InflationLibGlobals.inflateTransientsMetadataKey, target) || [];
    existingInflateTargets.push(propertyKey);
    console.debug("@transient", target, "transients:", existingInflateTargets)
    Reflect.defineMetadata(InflationLibGlobals.inflateTransientsMetadataKey, existingInflateTargets, target);
}
