/**
 * State, StateDispatcher,
 * Main,
 * Master,
 * API
 *
 */
import React, {createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState} from "react";
import {DescriptiveTypography} from "./triton/components/typography/DescriptiveTypography";
import {DefaultButton} from "./ardai/components/DefaultButton";
import {StateDispatcher} from "./ship/test/core/StateDispatcher";

export class ReactBackend<StateType> {

    private _state?: StateType;

    private _stateDispatcher?: StateDispatcher<StateType>;

    get state(): StateType {
        return this._state!;
    }

    get setState(): StateDispatcher<StateType> {
        return this._stateDispatcher!;
    }

    public updateState(state: StateType) {
        this._state = state;
    }

    public updateStateDispatcher(setState: StateDispatcher<StateType>) {
        this._stateDispatcher = setState;
    }
}

export type ReactMasterProps<StateType, BackendType extends ReactBackend<StateType>> = PropsWithChildren<{
    fx: BridgeContext<StateType, BackendType>
}>

export function ReactMaster<StateType, BackendType extends ReactBackend<StateType>>(props: ReactMasterProps<StateType, BackendType>) {
    const backendRef = useRef(props.fx.f.backend());
    const [state, setState] = useState(props.fx.f.state);
    const backend = backendRef.current;
    backend.updateState(state);
    backend.updateStateDispatcher(setState);

    return (
        <props.fx.stateCtx.Provider value={[state, setState]} children={
            <props.fx.backendCtx.Provider value={backend} children={
                props.children
            }/>
        }/>
    );
}

export type BridgeConfig<StateType, BackendType extends ReactBackend<StateType>> = {
    state: StateType,
    backend: () => BackendType
}

export function createBridge<StateType, BackendType extends ReactBackend<StateType>>(config: BridgeConfig<StateType, BackendType>): BridgeContext<StateType, BackendType> {
    const stateCtx = createContext<[StateType, StateDispatcher<StateType>]>([config.state, ((_) => {})]);
    const backendCtx = createContext(config.backend());
    return {
        stateCtx,
        backendCtx,
        f: config,
        useBackend() {
            useContext(stateCtx);
            return useContext(backendCtx);
        }
    }
}

export type BridgeContext<StateType, BackendType extends ReactBackend<StateType>> = {
    stateCtx: React.Context<[StateType, StateDispatcher<StateType>]>,
    backendCtx: React.Context<BackendType>,
    f: BridgeConfig<StateType, BackendType>,
    useBackend: () => BackendType
}

// TEST

type TestState = {
    counter: number
}

class TestBackend extends ReactBackend<TestState> {
    public offsetCounter(offset: number) {
        this.setState(prevState => ({
            ...prevState,
            counter: prevState.counter + offset
        }));
    }
}

const test = createBridge<TestState, TestBackend>({
    state: { counter: 0 },
    backend: () => new TestBackend
});

const TestDisplay: React.FC = props => {
    const backend = test.useBackend();
    return (
        <DescriptiveTypography text={backend.state.counter}/>
    );
}

const TestControls: React.FC = props => {
    const backend = useContext(test.backendCtx)
    return (
        <DefaultButton children={"+1"} onClick={() => backend.offsetCounter(1)}/>
    );
}

export const reactBridgeTest: React.ReactNode = (
    <ReactMaster<TestState, TestBackend> fx={test} children={
        <>
            <TestDisplay/>
            <TestControls/>
        </>
    }/>
)
