import {Pin} from "./Pin";
import {PinStateStatusType} from "./PinStateStatusType";
import {Serializable} from "../../../std/Serializable";
import {StatusRegisterDefaultOperations} from "./StatusRegisterDefaultOperations";

export class StatusRegister {

    constructor(
        private readonly pin: Pin
    ) {}

    public get wholeStatus(): PinStateStatusType {
        return this.pin.state.status;
    }

    public getStatus<T extends Serializable = number>(key: string): T {
        return this.wholeStatus[key] as T;
    }

    public eq(key: string, checkAgainst: Serializable): boolean {
        return this.getStatus(key) === checkAgainst;
    }

    public isSet(key: string): boolean {
        return Object.hasOwn(this.wholeStatus, key);
    }

    public unset(key: string): this {
        this.pin.updateState(state => {
            delete state.status[key]
        });
        return this;
    }

    public set(key: string, value: Serializable): this {
        this.pin.updateState(state => {
            state.status[key] = value;
        });
        return this;
    }

    public get defaults(): StatusRegisterDefaultOperations {
        return new StatusRegisterDefaultOperations(this);
    }
}
