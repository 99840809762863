import React from "react";
import {ColorLibrary} from "./ColorLibrary";
import {ColorPaletteRenderer} from "./ColorPaletteRenderer";
import styled from "styled-components";

const StyledColorPalette = styled.div`
  height: 500px;
  overflow: scroll;
  
  .color-palette {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export type ColorPaletteProps = {
    library: ColorLibrary
}

export const ColorPalette: React.FC<ColorPaletteProps> = props => {
    return (
        <StyledColorPalette>
            <div className={"color-palette"} children={
                props.library.map(value => (
                    <ColorPaletteRenderer colorSection={value}/>
                ))
            }/>
        </StyledColorPalette>
    );
}
