import React from "react";
import styled from "styled-components";
import {Text} from "../../../triton/components/typography/Text";
import {Color} from "../../../base/logic/style/Color";

const StyledImageLoadingErrorAltComponent = styled.div`
  aspect-ratio: 3 / 2;
  border-radius: 10px;
  background-color: rgba(16, 16, 22, 0.66);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  p {
    color: rgba(255, 255, 255, .25) !important;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: lighter;
  }
`;

export const ImageLoadingErrorAltComponent: React.FC = props => {
    return (
        <StyledImageLoadingErrorAltComponent>
            <Text t={"Error"}/>
        </StyledImageLoadingErrorAltComponent>
    );
}
