import React, {CSSProperties, useEffect, useState} from "react";

export type InlineEditablePresets = {
    description: CSSProperties,
    main: CSSProperties
}

export const inlineEditablePresetsImpl: InlineEditablePresets = {
    description: {
        fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
        color: "rgb(139, 148, 158)",
        wordWrap: "break-word",
        fontSize: "12px",
        display: "block",
        lineHeight: 1.5
    },
    main: {
        fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
        color: "rgb(201, 209, 217)",
        wordWrap: "break-word",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: 1.5
    }
}

export type InlineEditableProps = {
    initialValue: string
    onChange: (value: string) => Promise<void>,
    textStyle?: CSSProperties,
    requireControlClick?: boolean,
    useTextStylePreset?: keyof InlineEditablePresets,
    presets?: InlineEditablePresets,
    placeholder?: string
}

export const InlineEditable: React.FC<InlineEditableProps> = props => {
    const requireControlClick = props.requireControlClick ?? false;
    const [isEdit, setEdit] = useState(false);
    const presets = props.presets ?? inlineEditablePresetsImpl;
    const presetStyle = props.useTextStylePreset === undefined ? undefined : presets[props.useTextStylePreset]
    const [value, setValue] = useState<string>(props.initialValue);
    useEffect(() => setValue(props.initialValue), [props.initialValue]);

    if (isEdit) {
        return (
            <input placeholder={props.placeholder} autoFocus style={{
                ...props.textStyle,
                ...presetStyle,
                border: "none",
                padding: 0,
                margin: 0,
                backgroundColor: "transparent",
                outline: "none"
            }} defaultValue={value ?? props.initialValue} onBlur={e => {
                e.preventDefault();
                setEdit(false);
            }} onKeyDown={async e => {
                if (e.key === "Enter") {
                    e.preventDefault();
                    const newValue = e.currentTarget.value;
                    await props.onChange(newValue);
                    setValue(newValue);
                    setEdit(false);
                }

                if (e.key === "Escape") {
                    if (e.currentTarget.value === props.initialValue) setEdit(false);
                    else if (window.confirm("Discard changes")) setEdit(false);
                }
            }}/>
        );
    }

    return (
        <p style={{ ...props.textStyle, ...presetStyle,
            margin: 0,
            padding: 0
        }} children={value ?? props.initialValue} onClick={e => {
            if (requireControlClick && !e.ctrlKey) return;
            setEdit(true);
        }}/>
    );
}
