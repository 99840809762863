import React, {useEffect, useRef, useState} from "react";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {v4} from "uuid";
import {
    ArrowDownwardRounded, BugReportRounded, Circle, CloseRounded, HelpRounded,
    KeyboardAltRounded,
    KeyboardArrowDownRounded,
    KeyboardReturnRounded,
    KeyboardRounded, LockRounded, MoreHorizRounded, VisibilityRounded
} from "@mui/icons-material";
import {Tag} from "../../../components/Tag";
import styled from "styled-components";
import {MainTypography} from "../../../../triton/components/typography/MainTypography";
import {ControlModalCompound, ModalCompoundContext} from "../../../components/ControlModalCompound";
import {MenuDivider} from "@szhsin/react-menu";
import ReactJson from "react-json-view";
import {Color} from "../../../../base/logic/style/Color";
import {KeyRecordingChip} from "./KeyRecordingChip";
import {BasicColorPalette} from "../../../../triton/colors/BasicColorPalette";
import _ from "lodash";
import {Menu} from "../../../components/Menu";
import {CheckMenuButton, MenuButton} from "../../../components/MenuButton";
import { motion } from "framer-motion";
import {KeyCommandOption} from "../../../../atlas/keylogger/KeyCommandOption";
import {useTriton} from "../../../../triton/TritonHooks";
import Slide from "@mui/material/Slide";
import {MenuGroup} from "../../../components/MenuGroup";
import {ButtonModalCompound} from "../../../components/ButtonModalCompound";
import {Modal} from "../../../../triton/components/dialogs/Modal";
import {FormDivider} from "../../../../triton/components/forms/FormDivider";
import {HelpHint} from "../../../components/HelpHint";

const StyledKeyCommandTest = styled.div`
  color: white;
  padding-left: 16px;
  transition: padding-left .2s;
  
  &[data-engaged=false] {
    cursor: pointer;
    
    &:hover {
      padding-left: 50px;
    }
  }
`;

export const useKeyboardEvent = (type: "keydown" | "keyup" | "keypress", callback: (e: KeyboardEvent) => void) => {
    const on = (event: KeyboardEvent) => {
        // event.preventDefault();
        callback(event);
    };

    useEffect(() => {
        document.addEventListener(type, on);
        return () => {
            document.removeEventListener(type, on);
        };
    }, [on, type]);
};

export type CommandNodeOption = {
    value: string,
    title?: string,
    indicatorColor?: Color
}

export type KeyRecording = {
    key: string,
    id: string,
    option?: CommandNodeOption,
    node: CommandNode
}

export interface CommandNodeConfig {
    key?: string;
    title?: string;
    description?: string;
    isRespondingToKeyOverride?(kr: KeyRecording | Omit<KeyRecording, "node">): boolean;
    hasOptions?(): boolean;
    getOptions?(): Array<CommandNodeOption>;
    execute?(ctx: CommandContext): Promise<void> | void;

    onOptionChange?(option: CommandNodeOption): void;
    onLeaveNodeUnExecuted?(): void;
}

export type CommandInstruction = {
    keyPath: Array<KeyRecording>,
    mcc?: ModalCompoundContext

}

export type CommandContext = {
    instruction: CommandInstruction,
}

export class CommandNode<API = void> {

    private readonly _children: Array<CommandNode<API>>;

    private readonly _config: CommandNodeConfig;

    constructor(config: CommandNodeConfig) {
        this._config = config;
        this._children = new Array<CommandNode<API>>();
        this.checkIntegrity();
    }

    private checkIntegrity() {
        const c = this.config;
        if (c.key === undefined && c.isRespondingToKeyOverride === undefined) {
            throw new Error("CommandNode isn't specifying a key respond rule (either 'key' or 'isRespondingToKeyOverride' need to be set)");
        }
    }

    public addSubcommand(command: CommandNode<API>): CommandNode<API> {
        this._children.push(command);
        return this;
    }

    public isRespondingToKey(kr: KeyRecording | Omit<KeyRecording, "node">): boolean {
        if (this.config.isRespondingToKeyOverride !== undefined) return this.config.isRespondingToKeyOverride(kr);
        return this.config.key === kr.key;
    }

    public peekChildNodeViaKeyCode(kc: string): CommandNode {
        return this.getNode({
            id: "_peek_",
            key: kc
        });
    }

    public getNode(keyPath: Array<KeyRecording> | KeyRecording | Omit<KeyRecording, "node">): CommandNode {
        const genCNodeNotFound = () => new CommandNode<void>({
            key: "__error__"
        });

        if (Array.isArray(keyPath)) {
            if (keyPath.length === 0) return this;
            const ident = keyPath[0];
            const node = this._children.find(c => c.isRespondingToKey(ident));
            if (node === undefined) return genCNodeNotFound();
            return node.getNode(keyPath.slice(1));
        } else {
            const node = this._children.find(c => c.isRespondingToKey(keyPath));
            return node ?? genCNodeNotFound();
        }
    }

    public async run(ins: CommandInstruction) {
        await this._run({
            instruction: ins
        }, ins.keyPath);
    }

    private async _run(ctx: CommandContext, pathLeft: Array<KeyRecording>) {
        if (pathLeft.length > 0) {
            // This is an intermediate node // touch it
            this.touch(ctx);
            const node = this.getNode(pathLeft[0]);
            await node._run(ctx, pathLeft.slice(1));
        }
        // This is the final node // execute it
        else await this.execute(ctx);
    }

    private async execute(ctx: CommandContext) {
        // TODO: Implement
        await this.config.execute?.(ctx);
    }

    private touch(ctx: CommandContext) {
        // TODO: Implement
    }

    public hasOptions(): boolean {
        if (this.config.hasOptions === undefined) return (this.config.getOptions?.()?.length ?? 0) > 0;
        return this.config.hasOptions();
    }

    public getOptions(): Array<CommandNodeOption> {
        if (!this.hasOptions()) return [];
        else return this.config.getOptions?.() ?? [];
    }

    get children(): Array<CommandNode<API>> {
        return this._children;
    }

    get config(): CommandNodeConfig {
        return this._config;
    }

    public static newRoot<API = void>(): CommandNode<API> {
        return new CommandNode<API>({
            key: "__root__"
        });
    }
}

export type KeyCommandTestDisplayPreferences = Partial<{
    palette: BasicColorPalette<"main">
}>

export type KeyCommandTestProps = {
    cmdLib: CommandNode,
    displayPreferences?: KeyCommandTestDisplayPreferences
}

export type KeyCommandTestState = {
    engaged: boolean,
    lock: boolean,
    executing: boolean,
    keyRecordings: Array<KeyRecording>,
    cursor: number,
    cachedOptions?: Array<CommandNodeOption>,
    developmentMode: boolean,
    showKeyMap: boolean,
    enableKeyEngage: boolean
}

export const KeyCommandTest: React.FC<KeyCommandTestProps> = props => {
    const displayPreferences = { ...{
        palette: {
            main: Color.palettes.triton.purple
        }
    } as KeyCommandTestDisplayPreferences, ...props.displayPreferences };

    const [state, setState] = useState<KeyCommandTestState>({
        keyRecordings: new Array<KeyRecording>(),
        developmentMode: false,
        engaged: false,
        lock: false,
        cursor: 0,
        showKeyMap: true,
        enableKeyEngage: false,
        executing: false
    });

    const cmdLib = props.cmdLib;
    const currentNode = cmdLib.getNode(state.keyRecordings);

    useEffect(() => {
        const mainNode = new CommandNode<void>({
            key: "KeyK",
            title: "Key-command conf./actions",
            description: "Key-command-based configurations & actions"
        });

        const quickActionNode = new CommandNode<void>({
            key: "KeyK",
            title: "Quick actions",
            getOptions(): Array<CommandNodeOption> {
                return [
                    {
                        title: "Toggle key-map",
                        value: "toggle-key-map"
                    },
                    {
                        title: "Toggle key engage",
                        value: "toggle-key-engage"
                    }
                ];
            },
            execute(ctx: CommandContext) {
                const option = ctx.instruction.keyPath[ctx.instruction.keyPath.length - 1].option!;
                switch (option.value) {
                    case "toggle-key-map": {
                        setState(prevState => ({ ...prevState, showKeyMap: !prevState.showKeyMap }));
                        break;
                    }
                    case "toggle-key-engage": {
                        setState(prevState => ({ ...prevState, enableKeyEngage: !prevState.enableKeyEngage }));
                        break;
                    }
                }
            }
        } as CommandNodeConfig);

        mainNode.addSubcommand(quickActionNode);

        cmdLib.addSubcommand(mainNode);
    }, [cmdLib]);

    const modalRef = useRef<{
        ctx?: ModalCompoundContext
    }>({});

    const generateNodeOptions = () => {
        const n = currentNode;
        if (!n.hasOptions()) return;
        setState(prevState => ({
            ...prevState,
            cachedOptions: n.getOptions()
        }));
    }

    const syncNodeToRecording = () => {
        generateNodeOptions();
    }

    const appendKey = (kr: KeyRecording) => {
        setState(prevState => ({
            ...prevState,
            keyRecordings: [...prevState.keyRecordings, kr],
            cursor: 0
        }));
    }

    const getCurrentOption = () => state.cachedOptions?.[state.cursor];

    const getCurrentOptionAt = (cursor: number) => state.cachedOptions?.[cursor];

    const moveOptionCursor = (delta: number) => {
        const cC = state.cursor;
        let nC = cC + delta;
        const cOLen = state.cachedOptions?.length ?? 1;
        if (nC < 0) nC = cOLen - 1;
        else if (nC >= cOLen) nC = 0;
        setState(prevState => ({
            ...prevState,
            cursor: nC
        }));

        onSelectedOptionChange(cC, nC);
    };

    const setOptionCursor = (to: number) => {
        const cC = state.cursor;
        setState(prevState => ({
            ...prevState,
            cursor: to
        }));
        onSelectedOptionChange(cC, to);
    }

    function onSelectedOptionChange(oldCursorPos: number, newCursorPos: number) {
        const newOpt = getCurrentOptionAt(newCursorPos);
        if (newOpt === undefined) return;
        currentNode.config.onOptionChange?.(newOpt);
    }

    const updateCurrentKeyRecording = (updater: (kr: KeyRecording) => void) => {
        let kr = state.keyRecordings[state.keyRecordings.length - 1];
        if (kr === undefined) return;
        updater(kr);
        setState(prevState => ({
            ...prevState,
            keyRecordings: [...prevState.keyRecordings.slice(0, -1), kr]
        }));
    };

    const lockCurrentKeyRecording = () => {
        if (currentNode.hasOptions()) {
            updateCurrentKeyRecording(kr => {
                kr.option = getCurrentOption()
            });
        }
    }

    // const isTriggerKeyPressed = (ev: KeyboardEvent) => ev.code === "Numpad0";
    // const isTriggerKeyPressed = (ev: KeyboardEvent) => ev.altKey;
    const isTriggerKeyPressed = (ev: KeyboardEvent) => ev.ctrlKey;
    // const isTriggerKeyPressed = (ev: KeyboardEvent) => true;

    useKeyboardEvent("keydown", e => {
        if (state.executing) return;

        const key = e.keyCode || e.charCode;

        //
        if (e.shiftKey) {
            setState(prevState => ({
                ...prevState,
                lock: !prevState.lock
            }));
        }

        // Move option pointer
        if (state.engaged && (key >= 38 && key <= 40)) {
            e.preventDefault();
            switch (key) {
                // ARROW_UP
                case 38: {
                    if (e.altKey) moveOptionCursor(-state.cursor);
                    else moveOptionCursor(-1);
                    break;
                }
                // ARROW_RIGHT
                case 39: {
                    lockCurrentKeyRecording();
                    break;
                }
                // ARROW_DOWN
                case 40: {
                    if (e.altKey) moveOptionCursor(currentNode.getOptions().length - 1);
                    else moveOptionCursor(1);
                }
            }
        }

        if (state.engaged && e.code === "Backspace") {
            const curNode = currentNode;

            setState(prevState => {
                const nextKr = prevState.keyRecordings[prevState.keyRecordings.length - 2];
                const selOpt = nextKr?.option;
                const newPath = prevState.keyRecordings.slice(0, -1);
                const parentNode = props.cmdLib.getNode(newPath);
                const options = parentNode.getOptions();
                const lastCursorPos = options.findIndex(o => o.value === selOpt?.value);

                return {
                    ...prevState,
                    keyRecordings: prevState.keyRecordings.slice(0, -1),
                    cursor: lastCursorPos === -1 ? 0 : lastCursorPos
                };
            });

            curNode.config.onLeaveNodeUnExecuted?.();
        }

        if (state.enableKeyEngage && isTriggerKeyPressed(e) && !state.engaged) {
            e.preventDefault();
            e.stopPropagation();
            setState(prevState => ({
                ...prevState,
                engaged: true,
                lock: e.shiftKey
            }));
        }

        if (e.code === "Escape" && state.engaged && state.lock) {
            e.preventDefault();
            e.stopPropagation();
            const curNode = currentNode;
            setState(prevState => ({
                ...prevState,
                engaged: false,
                lock: false
            }));
            curNode.config.onLeaveNodeUnExecuted?.();
        }
    });

    useKeyboardEvent("keyup", e => {
        if (state.executing) return;

        if (!isTriggerKeyPressed(e) && !state.lock) {
            setState(prevState => ({
                ...prevState,
                engaged: false
            }));
        }
    });

    useKeyboardEvent("keypress", async e => {
        if (state.executing) return;

        if (state.engaged) {
            e.preventDefault();
            e.stopPropagation();

            // if (e.code === "Backspace") {
            //     setState(prevState => {
            //         const nextKr = prevState.keyRecordings[prevState.keyRecordings.length - 2];
            //         const selOpt = nextKr?.option;
            //         const newPath = prevState.keyRecordings.slice(0, -1);
            //         const parentNode = props.cmdLib.getNode(newPath);
            //         const options = parentNode.getOptions();
            //         const lastCursorPos = options.findIndex(o => o.value === selOpt?.value);
            //         return {
            //             ...prevState,
            //             keyRecordings: prevState.keyRecordings.slice(0, -1),
            //             cursor: lastCursorPos === -1 ? 0 : lastCursorPos
            //         };
            //     });
            // }

            if (e.code === "Enter") {
                lockCurrentKeyRecording();

                setState(prevState => ({
                    ...prevState,
                    executing: true
                }));

                try {
                    await props.cmdLib.run({
                        keyPath: state.keyRecordings,
                        mcc: modalRef.current.ctx!
                    });
                } catch (e) {
                    console.error(e);
                }

                setState(prevState => ({
                    ...prevState,
                    keyRecordings: [],
                    cursor: 0,
                    executing: false
                }));
            } else {
                const curNode = currentNode;
                const correspondingNode = currentNode.peekChildNodeViaKeyCode(e.code);
                lockCurrentKeyRecording();
                appendKey({
                    id: v4(),
                    key: e.code,
                    option: undefined,
                    node: correspondingNode
                });
                curNode.config.onLeaveNodeUnExecuted?.();
            }
        }
    });

    useEffect(() => {
        syncNodeToRecording();
        // e slint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNode]);

    const optionsWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {}, [optionsWrapperRef]);

    const t = useTriton();

    return (
        <StyledKeyCommandTest title={state.engaged ? undefined : "Press <ctrl> or click to open "} data-engaged={state.engaged} style={{
            height: "max-content",
            width: "max-content",
            position: "absolute",
            bottom: 0,
            zIndex: 100,
            // paddingLeft: 8 + 8,
        }} onClick={e => {
            if (state.engaged) return;
            setState(prevState => ({
                ...prevState,
                engaged: true,
                lock: true
            }))
        }}>
            {/* Command logic DOM bridge  */}
            <ControlModalCompound onInit={(ctx: ModalCompoundContext) => {
                ctx.setAutoRenderShadows(false);
                modalRef.current.ctx = ctx;
            }}/>

            <motion.div
                style={{
                    position: "absolute",
                    padding: "8px",
                    width: "max-content",
                    bottom: 16 + 8,

                    minWidth: 300
                }}
                animate={state.engaged ? "engaged" : "disengaged"}
                variants={{
                    engaged: {

                    },
                    disengaged: {
                        x: "-100%",
                        // rotate: 90,
                        // scale: 0
                    }
                }}
                children={
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                }}>
                    <TransitionGroup children={
                        state.developmentMode && (
                            <Collapse children={
                                <div style={{
                                    borderRadius: 8,
                                    backgroundColor: "black",
                                    padding: 16,
                                    position: "relative",
                                    maxHeight: "500px",
                                    overflow: "scroll"
                                }} children={
                                    <ReactJson
                                        theme={"grayscale"}
                                        src={state.keyRecordings.map(kr => {
                                            const krCopy: Partial<KeyRecording> = { ...kr };
                                            delete krCopy.node;
                                            return krCopy;
                                        })}
                                        enableClipboard={false}
                                        style={{ backgroundColor: "transparent" }}
                                    />
                                }/>
                            }/>
                        )
                    }/>

                    <TransitionGroup children={state.showKeyMap && state.engaged && (
                        <Slide timeout={3.5e2} direction={"right"}>
                            <div style={{
                                marginBottom: 4,
                                borderRadius: 8,
                                backgroundColor: t.col("bg_modal"),
                                padding: 16
                            }}>
                                <MenuGroup title={"Key-map"} appendix={
                                    <Tag tag={<VisibilityRounded style={{ fontSize: 14 }}/>} onClick={() => {
                                        setState(prevState => ({ ...prevState, showKeyMap: false }));
                                    }}/>
                                }>
                                    <TransitionGroup>
                                        { currentNode.children.map(c => {
                                            const conf = c.config;

                                            return (
                                                <Collapse key={conf.key}>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: 8,
                                                        marginTop: 4,
                                                        justifyContent: "space-between"
                                                    }}>
                                                        <DescriptiveTypography
                                                            // key={conf.key}
                                                            text={`${conf.title}`}
                                                        />
                                                        <Tag tag={conf.key} onClick={() => {
                                                            appendKey({
                                                                key: conf.key!,
                                                                id: v4(),
                                                                node: currentNode
                                                            });
                                                        }}/>
                                                    </div>
                                                </Collapse>
                                            );
                                        }) }

                                        { currentNode.children.length === 0 && (
                                            <Collapse>
                                                <DescriptiveTypography
                                                    style={{ fontStyle: "italic" }}
                                                    text={"No sub commands"}
                                                />
                                            </Collapse>
                                        ) }
                                    </TransitionGroup>
                                </MenuGroup>
                            </div>
                        </Slide>
                    )}/>

                    <TransitionGroup children={
                        currentNode.hasOptions() && (
                            <Collapse children={
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 4,
                                    marginBottom: 4,
                                    borderRadius: 8,

                                    // backgroundColor: "black",
                                    // backgroundColor: t.col("layout_200"),
                                    backgroundColor: t.col("bg_modal"),

                                    padding: 16
                                }}>
                                    <div style={{
                                        display: "grid",
                                        gap: 4,
                                        gridTemplateColumns: "min-content auto"
                                    }}>
                                        <div style={{
                                            height: "100%",
                                            overflow: "hidden",
                                            borderRadius: 10,
                                            position: "relative",
                                            width: 4,

                                            // backgroundColor: "rgb(26, 26, 32)",
                                            backgroundColor: t.col("input_background"),
                                        }}>
                                                <span style={{
                                                    position: "absolute",
                                                    transition: "all 100ms",
                                                    borderRadius: 10,
                                                    width: "100%",
                                                    backgroundColor: getCurrentOption()?.indicatorColor?.css() ?? displayPreferences.palette?.main!.css(),
                                                    top: (() => {
                                                        const e = ((
                                                            document.getElementById("key-command-option-list")
                                                                ?.firstElementChild
                                                                ?.children[state.cursor]
                                                        ) as HTMLElement | undefined);
                                                        if (e === undefined) return 0;

                                                        return e.getBoundingClientRect().top - e.parentElement!.getBoundingClientRect().top;
                                                    })(),
                                                    height: document.getElementById("key-command-option-list")
                                                        ?.firstElementChild
                                                        ?.children[state.cursor]
                                                        ?.clientHeight ?? "100%"
                                                }}/>
                                        </div>

                                        <div ref={optionsWrapperRef} id={"key-command-option-list"} children={
                                            currentNode.hasOptions() && (
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: 4,
                                                }}>
                                                    { currentNode.getOptions().map((o, idx) => (
                                                        <div key={idx} style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: 2,
                                                            position: "relative"
                                                        }} onClick={() => {
                                                            setOptionCursor(idx);
                                                        }}>
                                                            {/* <Tag key={o.value} tag={o.title ?? o.value}/> */}
                                                            <DescriptiveTypography key={o.value} text={o.title ?? o.value} noSelect/>
                                                        </div>
                                                    )) }
                                                </div>
                                            )
                                        }/>
                                    </div>
                                </div>
                            }/>
                        )
                    }/>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0,
                        borderRadius: 8,
                        padding: 16,

                        // backgroundColor: "black",
                        // backgroundColor: t.col("layout_200"),
                        backgroundColor: t.col("bg_modal"),
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 4,
                        }}>
                            <MainTypography text={`${currentNode.config.title ?? "None"} ${state.executing ? " - executing" : ""}`}/>
                        </div>

                        <DescriptiveTypography text={currentNode.config.description ?? "No description"} noSelect style={{
                            fontStyle: currentNode.config.description === undefined ? "italic" : "normal",
                            marginBottom: 8
                        }}/>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 4,
                            height: 24
                        }}>
                            <TransitionGroup children={
                                state.lock && (
                                    <Collapse timeout={100} orientation={"horizontal"} children={
                                        <LockRounded sx={{
                                            fontSize: 20,
                                            // color: "rgb(148, 155, 164)"
                                            color: t.col("icon_default")
                                        }}/>
                                    }/>
                                )
                            }/>

                            <Menu menuProps={{}} opener={
                                <div style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    height: "100%"
                                }} children={
                                    <MoreHorizRounded sx={{
                                        // color: "rgb(148, 155, 164)"
                                        color: t.col("icon_default"),
                                        cursor: "pointer",
                                        fontSize: 20
                                    }}/>
                                }/>
                            }>
                                <CheckMenuButton text={"Development mode"} checked={state.developmentMode} onSelect={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        developmentMode: !prevState.developmentMode
                                    }));
                                }}/>
                                <CheckMenuButton text={"Show key-map"} checked={state.showKeyMap} onSelect={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        showKeyMap: !prevState.showKeyMap
                                    }));
                                }}/>
                                <CheckMenuButton
                                    text={"Enable key-engage"}
                                    checked={state.enableKeyEngage}
                                    appendix={<HelpHint text={"If enabled, pressing <alt> engages the key-command-palette."}/>}
                                    onSelect={() => {
                                        setState(prevState => ({
                                            ...prevState,
                                            enableKeyEngage: !prevState.enableKeyEngage
                                        }));
                                 }}
                                />

                                <FormDivider title={"Helpful resources"} paddingVertical={8}/>
                                <ButtonModalCompound
                                    button={
                                        <MenuButton text={"Introduction"} icon={<HelpRounded/>}/>
                                    }
                                    modalContent={ctx => (
                                        <Modal open={true} onClose={ctx.close}>
                                            <DescriptiveTypography text={
                                                "<arrow-up> move cursor up (<alt> move cursor to top)"
                                            }/>
                                            <DescriptiveTypography text={
                                                "<arrow-down> move cursor down (<alt> move cursor to end)"
                                            }/>
                                        </Modal>
                                    )}
                                />
                            </Menu>

                            <KeyboardReturnRounded sx={{
                                transform: "scaleX(-1)",
                                fontSize: 20,
                                // color: "rgb(148, 155, 164)"
                                color: t.col("icon_default"),
                            }} onClick={async () => {
                                lockCurrentKeyRecording();

                                setState(prevState => ({
                                    ...prevState,
                                    executing: true
                                }));

                                try {
                                    await props.cmdLib.run({
                                        keyPath: state.keyRecordings,
                                        mcc: modalRef.current.ctx!
                                    });
                                } catch (e) {
                                    console.error(e);
                                }

                                setState(prevState => ({
                                    ...prevState,
                                    keyRecordings: [],
                                    cursor: 0,
                                    executing: false
                                }));
                            }}/>

                            { state.keyRecordings.length === 0 && (
                                <DescriptiveTypography text={"Select key shortcut..."} noSelect style={{ fontStyle: "italic" }}/>
                            ) }

                            {/*
                                state.keyRecordings.map(kr => (
                                    <Tag tag={kr.key} key={kr.id} highlightOnHover={false}/>
                                ))
                                */}

                            { state.keyRecordings.map(kr => (
                                <KeyRecordingChip kr={kr} key={kr.id}/>
                            )) }
                        </div>
                    </div>
                </div>
            }/>


            {/*
            <TransitionGroup style={{
                position: "absolute",
                padding: "8px",
                width: "max-content",
                bottom: 16 + 8,
            }} children={ state.engaged && (
                <Collapse timeout={100} children={
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4
                    }}>
                        <TransitionGroup children={
                            state.developmentMode && (
                                <Collapse children={
                                    <div style={{
                                        borderRadius: 8,
                                        backgroundColor: "black",
                                        padding: 16,
                                        position: "relative",
                                        maxHeight: "500px",
                                        overflow: "scroll"
                                    }} children={
                                        <ReactJson
                                            theme={"grayscale"}
                                            src={state.keyRecordings.map(kr => {
                                                const krCopy: Partial<KeyRecording> = { ...kr };
                                                delete krCopy.node;
                                                return krCopy;
                                            })}
                                            enableClipboard={false}
                                            style={{ backgroundColor: "transparent" }}
                                        />
                                    }/>
                                }/>
                            )
                        }/>

                        <TransitionGroup children={
                            currentNode.hasOptions() && (
                                <Collapse children={
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 4,
                                        marginBottom: 4,
                                        borderRadius: 8,
                                        backgroundColor: "black",
                                        padding: 16
                                    }}>
                                        <div style={{
                                            display: "grid",
                                            gap: 4,
                                            gridTemplateColumns: "min-content auto"
                                        }}>
                                            <div style={{
                                                height: "100%",
                                                overflow: "hidden",
                                                borderRadius: 10,
                                                position: "relative",
                                                width: 4,
                                                backgroundColor: "rgb(26, 26, 32)"
                                            }}>
                                                <span style={{
                                                    position: "absolute",
                                                    transition: "all 100ms",
                                                    borderRadius: 10,
                                                    width: "100%",
                                                    backgroundColor: getCurrentOption()?.indicatorColor?.css() ?? displayPreferences.palette?.main!.css(),
                                                    top: (() => {
                                                        const e = ((
                                                            document.getElementById("key-command-option-list")
                                                                ?.firstElementChild
                                                                ?.children[state.cursor]
                                                        ) as HTMLElement | undefined);
                                                        if (e === undefined) return 0;

                                                        return e.getBoundingClientRect().top - e.parentElement!.getBoundingClientRect().top;
                                                    })(),
                                                    height: document.getElementById("key-command-option-list")
                                                        ?.firstElementChild
                                                        ?.children[state.cursor]
                                                        ?.clientHeight ?? "100%"
                                                }}/>
                                            </div>

                                            <div ref={optionsWrapperRef} id={"key-command-option-list"} children={
                                                currentNode.hasOptions() && (
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: 4,
                                                    }}>
                                                        { currentNode.getOptions().map(o => (
                                                            <div style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: 2,
                                                                position: "relative"
                                                            }}>

            <DescriptiveTypography key={o.value} text={o.title ?? o.value} noSelect/>
        </div>
    )) }
</div>
)
}/>
</div>
</div>
}/>
)
}/>



<div style={{
    display: "flex",
    flexDirection: "column",
    gap: 0,
    borderRadius: 8,
    backgroundColor: "black",
    padding: 16
}}>
    <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 4,
    }}>
        <MainTypography text={`${currentNode.config.title ?? "None"}`}/>
    </div>

    <DescriptiveTypography text={currentNode.config.description ?? "No description"} noSelect style={{
        fontStyle: currentNode.config.description === undefined ? "italic" : "normal",
        marginBottom: 8
    }}/>

    <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 4,
        height: 24
    }}>

        <TransitionGroup children={
            state.lock && (
                <Collapse timeout={100} orientation={"horizontal"} children={
                    <LockRounded sx={{
                        fontSize: 20,
                        color: "rgb(148, 155, 164)"
                    }}/>
                }/>
            )
        }/>

        <Menu menuProps={{}} opener={
            <div style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                height: "100%"
            }} children={
                <MoreHorizRounded sx={{
                    color: "rgb(148, 155, 164)",
                    cursor: "pointer",
                    fontSize: 20
                }}/>
            }/>
        } children={
            <CheckMenuButton text={"Development mode"} checked={state.developmentMode} onSelect={() => {
                setState(prevState => ({
                    ...prevState,
                    developmentMode: !prevState.developmentMode
                }));
            }}/>
        }/>

        <KeyboardReturnRounded sx={{
            transform: "scaleX(-1)",
            fontSize: 20,
            color: "rgb(148, 155, 164)"
        }}/>

        { state.keyRecordings.length === 0 && (
            <DescriptiveTypography text={"Select key shortcut..."} noSelect style={{ fontStyle: "italic" }}/>
        ) }



        { state.keyRecordings.map(kr => (
            <KeyRecordingChip kr={kr} key={kr.id}/>
        )) }
    </div>
</div>
</div>
}/>
) }/>
            */}
        </StyledKeyCommandTest>
    );
}
