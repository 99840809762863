import React, {CSSProperties} from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {useTriton} from "../../triton/TritonHooks";
import {Triton} from "../../triton/Triton";
import {Color} from "../../base/logic/style/Color";
import {preprocess, Preprocessable} from "../../triton/Preprocessor";

const StyledTag = styled.button<TagProps & {
    t: Triton,
    calculatedBackgroundColor: string,
    normalBackgroundColor: string,
}>`
  border-radius: .3rem;
  border: none;
  background-color: ${p => p.calculatedBackgroundColor};
  transition: background-color .1s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  cursor: pointer;
  gap: 4px;
  
  height: ${p => {
      const defHeight = "22px";
      if (p.height === undefined) return defHeight;
      if (typeof p.height === "number") return `${p.height}px`;
      return p.height;
  }};
  
  &:disabled {
    cursor: not-allowed;
  }
  
  &:hover {
    // background-color: ${p => p.highlightOnHover ?? true ? "#2e3044" : "#1e202a"};
    // filter: brightness(1.25);
    filter: brightness(1.3);
    // background-color: ${p => p.t.col("layout_300")};
    background-color: ${p => p.normalBackgroundColor};
  }
  
  &:not([disabled]) {
    
    &:active {
      transform: scale(${p => p.applyActiveScaling ? .9 : 1});
    }
  }
`;

export type TagProps = {
    tag: React.ReactNode | ((ctx: TagRenderContext) => React.ReactNode),
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    highlightOnHover?: boolean,
    active?: boolean,

    applyActiveTextStyle?: boolean,
    applyActiveScaling?: boolean
    hideBackgroundOnDefault?: boolean,
    disabled?: boolean
    height?: number | string,
    mainColor?: Color,
    activeColor?: Color,
    style?: CSSProperties
}

export type TagRenderContext = {
    props: TagProps,
    t: Triton
}

export const Tag: React.FC<TagProps> = props => {

    const t = useTriton();

    const style: CSSProperties = {
        color: t.col("fg_muted"),
        ...props.style ?? {}
    }
    const backgroundColor = props.mainColor !== undefined ? props.mainColor.css() : t.col("layout_300");
    const calculatedBackgroundColor = props.hideBackgroundOnDefault ?? false ? "transparent" : backgroundColor;
    const activeColor = props.activeColor !== undefined ? props.activeColor.css() : t.col("color_primary");

    return (
        <StyledTag
            {...props}
            t={t}
            type={"button"}
            style={style}
            normalBackgroundColor={backgroundColor}
            calculatedBackgroundColor={calculatedBackgroundColor}
            children={
                <>
                    {
                        typeof props.tag === "string" && <DescriptiveTypography noSelect text={props.tag} style={{
                            whiteSpace: "nowrap",
                            color: ((props.applyActiveTextStyle ?? true) && props.active) ? activeColor : (
                                props.mainColor !== undefined ? props.mainColor.css() : undefined
                            )
                        }}/> ||
                        typeof props.tag === "function" && props.tag({ props, t }) ||
                        props.tag
                    }
                </>
            }
        />
    );
}
