import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts.scss';
import {Driver, driver} from "./driver/Driver";
import {DriverReactBridge} from "./driver/components/DriverReactBridge";
import {CommonCore, commonCore} from "./libs/ardspace/arc/common/api/CommonCore";

/**
 * TODO: Can this be removed?
 */
// document.addEventListener('touchmove', function (event) {
//     if ((event as any).scale !== 1) { event.preventDefault(); }
// }, { passive: false });

import "./libs/node/backend/Node"

import {testInflatableSystem} from "./libs/node/backend/serialization/v2/Inflatable";
testInflatableSystem();

console.log(`Running react version: ${React.version}`);

/**
 * Create the global command core instance for Ardspace Arc.
 * TODO: Move this to a driver-based pre initiation function:
 *   - Make this a part of the driver() function
 */
commonCore(() => new CommonCore());

driver();

Driver.boot();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <DriverReactBridge />
);

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 *
 * import reportWebVitals from './reportWebVitals';
 * reportWebVitals();
 */


/**
 * 1: inferium
 * 2: prudentium
 * 3: tertium
 * 4: imperium
 * 5: supremium
 */
(window as any).essence = function (level: number, amount: number = 1): number {
    if (level === 1) return amount;
    return (window as any).essence(level - 1, 4) * amount;
};

(window as any).stacked = function (amount: number, stackSize: number = 64): void {
    const stackCount = Math.floor(amount / stackSize);
    const extra = amount % stackSize;
    const singleChestCount = (amount / stackSize) / (3 * 9);
    console.log(`${amount} -> ${stackCount} stacks + ${extra} ~ ${singleChestCount} chests`);
};




