import {APIShard} from "./APIShard";

export class CompressionManager extends APIShard {

    /**
     * Original source: https://github.com/josefrichter/resize/blob/master/public/preprocess.js
     *
     * @param img -
     * @param finalType -
     * @param quality "Compression"-force -> 1.0 for original image
     */
    public resize(img: ImageBitmap, finalType: string = "image/jpeg", quality: number = .92): Promise<Blob> {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;


        // let max_width = img.width;
        let max_width = 100;
        let max_height = img.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > max_width) {
                // height *= max_width / width;
                height = Math.round(height *= max_width / width);
                width = max_width;
            }
        } else {
            if (height > max_height) {
                // width *= max_height / height;
                width = Math.round(width *= max_height / height);
                height = max_height;
            }
        }

        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d")!!;
        ctx.drawImage(img, 0, 0, width, height);

        return new Promise<Blob>(resolve => {
            // get the data from canvas as quality% finalType(PNG) (can be also JPG, etc.)
            return canvas.toBlob(blob => {
                resolve(blob!!)
            }, finalType, quality);
        });
    }
}
