import React, {useContext} from "react";
import {SDInterfaceAPIContext} from "../../../imageSorter/sdInterface/SDInterfaceAPI";
import {SDInterfaceStateContext} from "../../../imageSorter/sdInterface/SDInterfaceMain";
import {DuplexEventRelay} from "../../utils/DuplexEventRelay";
import {TabBar} from "../../components/TabBar";
import {
    AodRounded,
    CodeRounded,
    HistoryRounded, HubRounded, ImageRounded, InfoRounded,
    LiveTvRounded, PlayArrowRounded,
    SettingsApplicationsRounded, StopRounded,
    TokenRounded
} from "@mui/icons-material";
import {TabBodyRenderer} from "../../components/TabBodyRenderer";
import {MainTab} from "../../../imageSorter/sdInterface/MainTab";
import {MixinTab} from "../../../imageSorter/sdInterface/MixinTab";
import {HistoryTab} from "../../../imageSorter/sdInterface/HistoryTab";
import {GenerationConfigTab} from "../../../imageSorter/sdInterface/GenerationConfigTab";
import {LiveProcessTab} from "../../../imageSorter/sdInterface/LiveProcessTab";
import {Menu} from "../../components/Menu";
import {MenuButton} from "../../components/MenuButton";
import {IconButton} from "../../components/IconButton";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {TransitionGroup} from "react-transition-group";
import {LinearProgress, Zoom} from "@mui/material";
import {HelpHint} from "../../components/HelpHint";
import {ArdspaceAIGenerateMain} from "./ArdspaceAIGenerateMain";
import {Workspace} from "../../components/Workspace";
import {SDLivePreview} from "../../../imageSorter/sdInterface/SDLivePreview";
import ReactJson from "react-json-view";
import {OutputConfigurationDisplay} from "../../../imageSorter/sdInterface/output/OutputConfigurationDisplay";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {ISADBImageGrid} from "../../components/ISADBImageGrid";
import {ControlledImagePreview} from "../../components/ControllerImagePreview";
import {StableDiffusionAttachmentRetriever} from "../../webapi/pngProperties/StableDiffusionAttachmentRetriever";
import {SingleOutputImagePreview} from "../../../imageSorter/sdInterface/SingleOutputImagePreview";
import {Tray} from "../../components/Tray";
import {SDInterfaceSidebar} from "../../../imageSorter/sdInterface/SDInterfaceSidebar";

export type DefaultFullscreenSDInterfaceProps = {
    bus: DuplexEventRelay,
}

export const DefaultFullscreenSDInterface: React.FC<DefaultFullscreenSDInterfaceProps> = props => {
    const sdApi = useContext(SDInterfaceAPIContext);
    const state = useContext(SDInterfaceStateContext);

    // TODO: Fix overflow problem
    return (
        <div style={{
            display: "grid",
            gridTemplateRows: "min-content auto min-content",
            gap: "1rem",
            // maxHeight: "70vh",
            height: "100%",
            maxHeight: "calc(100% - 0px)",
            color: "white",

            overflow: "hidden"
        }}>
            {/* HEADER */}
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 8
            }}>
                <TabBar
                    activeBar={state.activeTab}
                    onTabChange={tab => sdApi.setState(prevState => ({ ...prevState, activeTab: tab }))}
                    tabs={[
                        { id: "main", title: "Main", icon: <CodeRounded/> },
                        { id: "prompt", title: "SD Prompt", icon: <CodeRounded/> },
                        { id: "config", title: "Generation config", icon: <SettingsApplicationsRounded/> },
                        { id: "mixins", title: "Mixins", icon: <TokenRounded/> },
                        { id: "history", title: "History", icon: <HistoryRounded/> },
                        { id: "live", title: "Live process preview", icon: <LiveTvRounded/> },
                        { id: "img-info", title: "Image Info", icon: <InfoRounded/> }
                    ]}
                />

                <div style={{
                    width: 350,
                    height: "100%",
                }}>
                    <IconButton
                        tooltip={state.phase === "default" ? "Generate" : "Interrupt"}
                        onClick={() => {
                            if (state.phase === "generating") sdApi.interruptImageGeneration();
                            else if (state.phase === "default") sdApi.generate().then(() => {});
                        }}
                        size={"small"}
                        children={state.phase === "default" ? (
                            <PlayArrowRounded style={{ color: "mediumseagreen" }}/>
                        ) : (
                            <StopRounded style={{ color: "crimson" }}/>
                        )}
                    />
                </div>
            </div>

            <div style={{
                width: "100%",

                // overflow: "hidden",
                overflow: "scroll",

                height: "100%",
                display: "grid",
                gap: 0,
                gridTemplateColumns: "auto min-content"
            }}>
                <div style={{
                    // width: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                }} children={
                    <TabBodyRenderer
                        active={state.activeTab}
                        tabs={new Map<string, () => React.ReactElement>([
                            ["main", () => <ArdspaceAIGenerateMain/>],
                            ["prompt", () => <MainTab/>],
                            ["mixins", () => <MixinTab/>],
                            ["history", () => <HistoryTab/>],
                            ["config", () => <GenerationConfigTab/>],
                            ["live", () => <LiveProcessTab/>],
                        ])}
                    />
                }/>

                <Tray style={{
                    overflow: "scroll",
                    maxHeight: "100%",
                }} config={{
                    name: "out-view",
                    resizable: true,
                    growOrientation: "horizontal",
                    resizablePropertyOverrides: {
                        resizeHandles: ["w"],
                        axis: "x",
                        width: 800
                    }
                }} children={
                    <SDInterfaceSidebar/>
                }/>
            </div>

            {/* FOOTER */}
            <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                alignItems: "center",
                width: "100%",
                gap: "8px"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    gap: "8px"
                }}>
                    <Menu>
                        <MenuButton icon={<StopRounded/>} text={"Try to interrupt SD generation"} onSelect={() => {
                            sdApi.interruptImageGeneration();
                        }}/>
                    </Menu>
                    <IconButton
                        tooltip={state.phase === "default" ? "Generate" : "Interrupt"}
                        onClick={() => {
                            if (state.phase === "generating") sdApi.interruptImageGeneration();
                            else if (state.phase === "default") sdApi.generate().then(() => {});
                        }}
                        size={"small"}
                        children={state.phase === "default" ? (
                            <PlayArrowRounded style={{ color: "mediumseagreen" }}/>
                        ) : (
                            <StopRounded style={{ color: "crimson" }}/>
                        )}
                    />
                    { state.progress && (
                        <DescriptiveTypography
                            text={`${Math.ceil(state.progress.progress * 100)}% ETA: ${Math.floor(state.progress.eta_relative)}`}
                        />
                    ) }
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    gap: "8px"
                }}>
                    <TransitionGroup style={{
                        width: "100%",
                        height: "100%"
                    }} children={
                        (state.progress !== undefined) && (
                            <Zoom children={
                                <LinearProgress
                                    value={Math.ceil(state.progress.progress * 100)}
                                    style={{ width: "100%", borderRadius: "50px", overflow: "hidden" }}
                                    variant={"determinate"}
                                    color={"error"}
                                />
                            }/>
                        )
                    }/>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    width: "100%",
                    gap: "8px"
                }}>
                    <HelpHint text={"Because Ard is sduupid: Is '--cors-allow-origins *' set in the start batch file?"}/>
                </div>
            </div>
        </div>
    );
}
