import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {triton} from "../../../../triton/Triton";
import {Pin} from "../../../backend/Pin";

export const NodeStringFormat: NodeSetupInfo = {
    label: "NodeStringFormat",
    classname: "strings.format",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "strings.format",
        label: "format",
        defInPins: ["f"],
        defOutPins: ["s"],
        state: {
            format: ""
        },
        init() {
            const node = this;
            let idx = 0;

            const bank = this.pins.createPinBank("variables", {
                label: "variables",
                dynamicSizeFlag: true,
                hideLabelFlag: true
            });

            const createNewChannel = () => bank.pin(`${idx++}`);

            const pushFormattedStr = () => {
                let formatted = this.state.state.format;

                bank.allPins.forEach((pin, idx) => {
                    const variableName = `%${idx}`;
                    formatted = formatted.replaceAll(variableName, pin.lastReadState);
                });

                node.pins.out("s").write(formatted);
            }

            bank.attach({
                onPinRead() {
                    pushFormattedStr();
                },

                onPinNewInboundConnection() {
                    createNewChannel();
                },

                // onPinCutInboundConnection(thisPin: Pin, fromPin: Pin) {
                //     bank.allPins.slice(0, -1).forEach(pin => {
                //         if (pin.hasInputConnections) return;
                //         bank.removePinByLabel(pin.label);
                //     });
                //     bank.allPins.forEach((pin, idx) => {
                //         pin.setLabel(String(idx));
                //     })
                // }
            });

            // format
            node.pins.in("f").attach({
                read(format: string) {
                    node.state.update({ format });
                    pushFormattedStr();
                }
            })

            createNewChannel();
        },
        customRenderer: node => (
            <div style={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
                padding: 0,
                alignItems: "center",
                height: "100%",
                justifyContent: "center"
            }}>
                <input
                    type={"text"}
                    value={node.state.state.format}
                    onChange={e => {
                        const format = e.currentTarget.value;
                        node.state.update({format});
                    }}
                    style={{
                        maxWidth: 120,
                        fontSize: 12,
                        color: triton().col("fg_muted"),
                        border: "none",
                        backgroundColor: "transparent",
                        outline: "none"
                    }}
                />
            </div>
        )
    })
}
