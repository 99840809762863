import React, {useContext, useEffect, useRef, useState} from "react";
import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../webapi/WebAPIDB";
import {ArdaiAPIStateContext} from "../ArdaiMain";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {IconButton} from "./IconButton";
import {
    ArrowLeftRounded,
    ArrowRightRounded,
    BlurCircularRounded,
    CloseRounded,
    DeleteRounded,
    DownloadRounded,
    FullscreenRounded,
    InfoRounded,
    PreviewRounded,
    SelectAllRounded, ShareRounded,
    StarRounded,
    StyleRounded,
    TagRounded,
    WarningAmberRounded
} from "@mui/icons-material";
import {ButtonGroup} from "./ButtonGroup";
import {ImageViewFooter} from "./ImageViewFooter";
import {ButtonModalCompound} from "./ButtonModalCompound";
import {StyledModal} from "./StyledModal";
import {TagEditor} from "./TagEditor";
import {ControlModalCompound, ModalRenderer} from "./ControlModalCompound";
import {ButtonBase} from "../../triton/components/buttons/ButtonBase";
import {v4} from "uuid";
import {ImageMetaData} from "../data/ImageMetaData";
import {getMetadata} from "meta-png";
import {TransitionGroup} from "react-transition-group";
import {load} from "../_/NSFW";
import {BooleanContext} from "../../triton/components/BooleanContext";
import {MetaBasicEntry} from "./MetaBasicEntry";
import {FormDivider} from "../../triton/components/forms/FormDivider";
import {ColorableTag} from "./ColorableTag";
import {Menu} from "./Menu";
import {CheckMenuButton, MenuButton} from "./MenuButton";
import {Workspace} from "./Workspace";
import Collapse from "@mui/material/Collapse";
import styled from "styled-components";
import {MetadataViewV2} from "./MetadataViewV2";
import {MenuDivider} from "@szhsin/react-menu";
import _ from "lodash";
import ReactJson from "react-json-view";
import {px} from "../../base/logic/style/DimensionalMeasured";
import {useTriton} from "../../triton/TritonHooks";
import {Text} from "../../triton/components/typography/Text";
import {useKeyboardEvent} from "../_/keyCommand/test/KeyCommandTest";
import Slide from "@mui/material/Slide";
import {Flex} from "../../ardspace/arc/common/Flex";
import {ImageViewFullscreenModal} from "../trays/image/ImageViewFullscreenModal";
import {Orientation} from "../../base/logic/style/Orientation";
import {SelectMenuButton} from "./SelectMenuButton";
import {ContentSensitiveRenderer} from "../webapi/cp/components/ContentSensitiveRenderer";
import {ContentSensitivity} from "../webapi/cp/ContentSensitivity";
import {MenuGroup} from "./MenuGroup";
import {BackgroundImages} from "../BackgroundImages";
import {DefaultButton} from "./DefaultButton";
import {Modal} from "../../triton/components/dialogs/Modal";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {Utils} from "../../base/Utils";
// import * as nsfwjs from 'nsfwjs'

const StyledImageView = styled.div`
  // background-color: blue;
  // height: calc(100% - 0px);
  
  .box {
    height: 100%; 
  }
  
  .click-pad-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-between;
    
    .click-pad {
      height: 100%;
      width: 100%;
    }
  }
`;

export enum ToolbarPosition {
    TOP = "TOP", BOTTOM = "BOTTOM", LEFT = "LEFT", RIGHT = "RIGHT"
}

export type ImageViewState = {
    openImageDetailFooter: boolean,
    openMetaView: boolean,
    enableTouchGestures: boolean,
    orientation: Orientation,
    toolbarPosition: ToolbarPosition,

    transitionState: "initial" | "transitioning",
    transitionDirection?: "forward" | "backward",
    cID?: string,
    nID?: string
}

export const ImageView: React.FC = props => {
    const state = useContext(ArdaiAPIStateContext);
    const api = useContext(ArdaiAPIContext);
    const t = useTriton();

    const [localState, setLocalState] = useState<ImageViewState>({
        openImageDetailFooter: false,
        openMetaView: false,
        transitionState: "initial",
        enableTouchGestures: true,
        orientation: Orientation.HORIZONTAL,
        toolbarPosition: ToolbarPosition.TOP
    });

    const direction = localState.transitionDirection ?? "forward";
    const isTransitioning: boolean = localState.transitionState === "transitioning";
    // const pageTransitionDuration = .5e3;
    const pageTransitionDuration = .4e3;
    // const pageTransitionDeltaDuration = .2e3;
    const pageTransitionDeltaDuration = .1e3;
    const onPageTransitionFinished = () => {
        if (isTransitioning) {
            setLocalState(prevState => ({
                ...prevState,
                transitionState: "initial",
                transitionDirection: "forward",
                cID: prevState.nID,
                nID: undefined
            }));
        }
    }

    const wrapAround = true;
    const selection = state.imageSelection;
    const isNothingSelected = selection === undefined;
    const isSelectionEmpty = !isNothingSelected && selection.imagesIDs.length > 0;
    const selLen = selection?.imagesIDs.length ?? 0;
    const pointer = selection?.pointer ?? 0;

    const modeSelectionPointer = (d: number, wrap: boolean = wrapAround) => {
        let newPointerPos = pointer + d;
        if (newPointerPos < 0) newPointerPos = wrap ? selLen - 1 : 0;
        else if (newPointerPos > selLen - 1) newPointerPos = wrap ? 0 : selLen - 1;
        if (newPointerPos === pointer) return;
        api.setState(prevState => ({
            ...prevState,
            selectedImageId: prevState.imageSelection!.imagesIDs[newPointerPos],
            imageSelection: {
                ...prevState.imageSelection!,
                pointer: newPointerPos
            }
        }));
        setLocalState(prevState => ({
            ...prevState,
            transitionState: "transitioning",
            transitionDirection: newPointerPos < pointer ? "backward" : "forward",
            nID: selection!.imagesIDs[newPointerPos]
        }));
    }

    useKeyboardEvent("keydown", e => {
        if (selection === undefined) return;

        switch (e.key) {
            case "ArrowRight": {
                modeSelectionPointer(1);
                break;
            }
            case "ArrowLeft": {
                modeSelectionPointer(-1);
                break;
            }
        }
    });

    const placeholderImageID = "_placeholder";
    const currentImageID = selection?.imagesIDs[pointer] ?? placeholderImageID;

    const cI = useLiveQuery(async () => webDB.images
        .where("id")
        .equals(currentImageID)
        .first(), [currentImageID]);

    const nI = useLiveQuery(async () => webDB.images
        .where("id")
        .equals(localState.nID ?? placeholderImageID)
        .first(), [localState.nID]);

    const [currentImageMeta, setCurrentImageMeta] = useState<ImageMetaData | undefined>(undefined);
    const data = useRef<{ rawMetaString?: string }>({});
    useEffect(() => {


        // TODO: Remove
        return;

        if (cI === undefined) {
            data.current.rawMetaString = undefined;
            setCurrentImageMeta(undefined);
            return;
        }
        // TODO: Remove the parsing!
        cI?.data.arrayBuffer().then(r => {
            const meta = getMetadata(new Uint8Array(r), "parameters")!;
            data.current.rawMetaString = meta;
            let [prompt, rest] = meta.split("Negative prompt:").map(s => s.trim());
            let promptShards = prompt.split(",").map(s => s.trim());
            let [negPrompt, negRest] = rest.split("Steps:").map(s => s.trim());
            let negPromptShards = negPrompt.split(",").map(s => s.trim());
            const mrBase = negRest.split(",").map(s => s.trim()).map(s => s.split(": "));
            mrBase[0] = ["Steps", mrBase[0][0]]
            let metaRest = new Map(mrBase as [string, string][]);
            setCurrentImageMeta({
                meta: metaRest,
                negativePromptShards: negPromptShards,
                negativePrompt: negPrompt,
                promptShards: promptShards,
                prompt: prompt,

                // TODO: Implement
                netInfos: []
            });
        })
    }, [cI]);

    if (cI === undefined) {
        return (
            <div style={t.styled("layout_box", {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem",
                flexDirection: "column",
                flexShrink: 1,
                flexGrow: 1,
                overflowY: "scroll",
            })}>
                {/* <DescriptiveTypography text={"Select an cI"}/> */}
                {/*
                <iframe
                    title={"SD"}
                    width={"100%"}
                    height={"100%"}
                    src={"http://localhost:7860/"}
                    style={{
                        border: "none",
                        scrollbarWidth: "none"
                    }}
                />
                */}

                {/*
                <ButtonModalCompound
                    button={<IconButton children={
                        <UploadRounded/>
                    }/>}
                    modalContent={ctx => (
                        <ImageSourceSelectionDialog/>
                    )}
                />
                */}

                {/*<ArcAdminScreen/>*/}

                {/*<TestScriptingView/>*/}

                {/*<AtlasMain api={new InDevAtlasAPI()}/>*/}


                {/*<DefaultButton onClick={() => {
                    testFlairSystem()
                }}/>*/}

                {/*
                <MultiTray
                    name={"test"}
                    tabHeaders={[
                        { id: "a", title: "A" },
                        { id: "b", title: "B" }
                    ]}
                    trays={new Map<string, TrayRenderer>([
                        ["a", ctx => <button onClick={() => ctx.switchTray("b")} children={"to 'b'"}/>],
                        ["b", ctx => <button>b</button>],
                    ])}
                />
                */}

                {/*
                <div children={<BArdai/>} style={{
                    overflow: "hidden",
                    borderRadius: 30,
                    height: 844,
                    width: 390
                }}/>
                */}

            </div>
        );
    }

    return (
        <StyledImageView style={{
            display: "grid",
            flexGrow: 1,
            gridTemplateColumns: "auto min-content"
        }}>
            <div style={t.styled("layout_box", {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem",
                gap: ".5rem",
                height: "calc(100% - 0px)",
                flexDirection: (() => {
                    switch (localState.toolbarPosition) {
                        case ToolbarPosition.TOP:
                            return "column";
                        case ToolbarPosition.BOTTOM:
                            return "column-reverse";
                        case ToolbarPosition.LEFT:
                            return "row";
                        case ToolbarPosition.RIGHT:
                            return "row-reverse";
                    }
                })()
            })}>
                <div style={{
                    gap: ".5rem",
                    display: "flex",

                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",

                    flexDirection: (() => {
                        switch (localState.toolbarPosition) {
                            case ToolbarPosition.TOP:
                            case ToolbarPosition.BOTTOM:
                                return "row";
                            case ToolbarPosition.LEFT:
                            case ToolbarPosition.RIGHT:
                                return "column";
                        }
                    })()
                }}>
                    <IconButton tooltip={"Share"} deactivated={!navigator.canShare} children={<ShareRounded/>} onClick={() => {
                        const images = [new File([cI?.data!], `${cI?.id}.png`)];

                        window.navigator.share({
                            files: images,
                            title: 'Ardspace local image',
                            text: 'Photos from September 27 to October 14.',
                        })
                    }}/>


                    <ButtonModalCompound borderless button={
                        <IconButton children={<FullscreenRounded/>}/>
                    } modalContent={ctx => (
                        <ImageViewFullscreenModal ctx={ctx}/>
                    )}/>

                    <ButtonGroup>
                        <IconButton children={<ArrowLeftRounded/>} onClick={() => modeSelectionPointer(-1)}/>
                        {/*
                        <IconButton children={
                            <Text
                                col={t.col("fg_default")}
                                t={`${pointer + 1}/${selLen}`}
                                ff={"monospace"}
                            />
                        }/>
                        */}
                        <DefaultButton style={{
                            aspectRatio: "2 / 1"
                        }} children={
                            <Text
                                col={t.col("fg_default")}
                                t={`${pointer + 1}/${selLen}`}
                                ff={"monospace"}
                            />
                        }/>
                        <IconButton children={<ArrowRightRounded/>} onClick={() => modeSelectionPointer(1)}/>
                    </ButtonGroup>

                    <IconButton variant={api.selectionManager.isSelected(cI?.id) ? "primary" : "default"} tooltip={"Select"} children={<SelectAllRounded/>} onClick={() => {
                        api.selectionManager.toggleSelection(cI?.id);
                    }}/>

                    <Menu defaultMenuButtonSizeVariant={"medium"} children={
                        <>
                            <ButtonModalCompound borderless button={
                                <MenuButton text={"Fullscreen"} icon={<FullscreenRounded/>}/>
                            } modalContent={ctx => (
                                <ImageViewFullscreenModal ctx={ctx}/>
                            )}/>

                            <MenuButton text={cI?.favourite ? "Unstar" : "Star"} icon={
                                <StarRounded sx={{ transition: "color 50ms", color: cI?.favourite ? "gold" : "inherit" }}/>
                            } onSelect={() => {
                                webDB.images.update(cI?.id!, {
                                    "favourite": !cI?.favourite ?? true
                                });
                            }}/>

                            <BooleanContext children={(bool, setBool) => (
                                <>
                                    <MenuButton text={"Edit tags"} icon={<TagRounded/>} onSelect={() => setBool(true)}/>
                                    <TagEditor initialTags={cI?.tags ?? []} open={bool} onClose={() => setBool(false)} onSave={tags => {
                                        webDB.images.update(cI?.id!, {
                                            "tags": tags
                                        });
                                        setBool(false);
                                    }}/>
                                </>
                            )}/>

                            <MenuButton text={"Mark as submitted"} disabled={cI?.tags?.includes("submitted") ?? false} icon={<TagRounded/>} onSelect={() => {
                                webDB.images.update(cI?.id!, {
                                    "tags": _.uniq([...cI?.tags ?? [], "submitted"])
                                });
                            }}/>

                            <MenuButton text={"Clear all tags"} disabled={cI?.tags?.length <= 0 ?? true} onSelect={() => {
                                webDB.images.update(cI?.id!, {
                                    "tags": []
                                });
                            }}/>

                            <MenuDivider/>

                            <MenuButton
                                keepOpenOnClick
                                text={"Preferences"}
                                appendix={<ArrowRightRounded fontSize={"small"}/>}
                                submenu={
                                    <>
                                        <SelectMenuButton
                                            name={"a"}
                                            disableSearchbar
                                            title={"Scroll orientation"}
                                            selected={localState.orientation}
                                            onSelect={o => setLocalState(prevState => ({ ...prevState, orientation: o as Orientation }))}
                                            options={[
                                                { id: Orientation.HORIZONTAL, text: "Horizontal" },
                                                { id: Orientation.VERTICAL, text: "Vertical" }
                                            ]}
                                        />

                                        <SelectMenuButton
                                            name={"b"}
                                            disableSearchbar
                                            title={"Toolbar position"}
                                            selected={localState.toolbarPosition}
                                            onSelect={o => setLocalState(prevState => ({ ...prevState, toolbarPosition: o as ToolbarPosition }))}
                                            options={[
                                                { id: ToolbarPosition.TOP, text: "Top" },
                                                { id: ToolbarPosition.BOTTOM, text: "Bottom" },
                                                { id: ToolbarPosition.LEFT, text: "Left" },
                                                { id: ToolbarPosition.RIGHT, text: "Right" }
                                            ]}
                                        />

                                        <CheckMenuButton text={"Enable touch gestures"} checked={localState.enableTouchGestures} onSelect={async () => {
                                            setLocalState(prevState => ({
                                                ...prevState,
                                                enableTouchGestures: !prevState.enableTouchGestures
                                            }));
                                        }}/>
                                    </>
                                }
                            />

                            <CheckMenuButton text={"Show details"} checked={localState.openImageDetailFooter} onSelect={async () => {
                                setLocalState(prevState => ({
                                    ...prevState,
                                    openImageDetailFooter: !prevState.openImageDetailFooter
                                }));
                            }}/>

                            <CheckMenuButton text={"Show meta view"} checked={localState.openMetaView} onSelect={async () => {
                                setLocalState(prevState => ({
                                    ...prevState,
                                    openMetaView: !prevState.openMetaView
                                }));
                            }}/>

                            <MenuDivider/>

                            <MenuButton text={"Set as cover"} icon={<PreviewRounded/>} disabled onSelect={() => {
                                api.getProjectContext().setPreviewImage(cI?.id);
                            }}/>

                            <ButtonModalCompound button={
                                <MenuButton text={"Create style"} icon={<StyleRounded/>} onSelect={() => {
                                    api.getProjectContext().setPreviewImage(cI?.id);
                                }}/>
                            } modalContent={ctx => (
                                <StyledModal title={"Create style"} onClose={() => ctx.close()} children={
                                    <>asd</>
                                } footer={
                                    <div style={{ display: "grid", width: "100%" }} children={
                                        <ButtonBase text={"Create"} baseProps={{
                                            onClick: () => {
                                                webDB.styles.add({
                                                    id: v4(),
                                                    title: "new style",
                                                    description: "lul",
                                                    previewID: cI?.id,
                                                    additionalPreviewIDs: [],
                                                    meta: data.current.rawMetaString
                                                });
                                                ctx.close();
                                            }
                                        }}/>
                                    }/>
                                }/>
                            )}/>

                            <ButtonModalCompound button={
                                <MenuButton text={"View raw"} icon={<InfoRounded/>}/>
                            } modalContent={ctx => (
                                <StyledModal title={"Image data viewer"} w={px(500)} onClose={ctx.close} children={
                                    <ReactJson
                                        src={cI}
                                        // onEdit={edit => {
                                        //     edit.updated_src
                                        // }}
                                        theme={"grayscale"}
                                        shouldCollapse={false}
                                        displayObjectSize={true}
                                        displayDataTypes={true}
                                        iconStyle={"square"}
                                        enableClipboard={false}
                                        style={{ backgroundColor: "transparent" }}
                                    />
                                }/>
                            )}/>

                            <ButtonModalCompound button={
                                <MenuButton text={"View preview image"}/>
                            } modalContent={ctx => (
                                <Modal title={"Preview image display"} open={true} onClose={ctx.close}>
                                    <img alt={"previewData-image preview"} src={URL.createObjectURL(cI?.previewData!)} style={{
                                        objectFit: "contain",
                                        objectPosition: "center",
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                        height: "auto",
                                        borderRadius: ".5rem",
                                    }}/>

                                    <DescriptiveTypography text={
                                        `${Utils.humanFileSize(cI?.previewData!.size ?? -1)}`
                                    }/>

                                    <DefaultButton size={"small"} children={"Get image dimensions"} onClick={() => {
                                        createImageBitmap(cI?.previewData!).then(map => {
                                            window.alert(`${map.width}w, ${map.height}h`)
                                        })
                                    }}/>
                                </Modal>
                            )}/>

                            <ControlModalCompound controller={ctx => (
                                <BooleanContext children={(running, setRunning) => (
                                    <MenuButton text={"Run NSFW-analyzer"} loading={running} icon={<StyleRounded/>} onSelect={async () => {
                                        if (running) return;
                                        else setRunning(true);
                                        const img = document.getElementById("cI-view-main-cI");
                                        const model = await load();
                                        const predictions = await model.classify(img as any);
                                        ctx.open("nsfw-result-display", predictions);
                                        setRunning(false);
                                    }}/>
                                )}/>
                            )} modals={new Map<string, ModalRenderer>([
                                ["nsfw-result-display", (ctx, param) => (
                                    <StyledModal headerRightAppendix={<ColorableTag tag={"Beta"}/>} title={"Run NSFW content result"} icon={<BlurCircularRounded/>} onClose={() => ctx.close()} children={
                                        <>
                                            {
                                                (param as any[]).filter(a => a.probability >= .2).sort((a, b) => a.probability > b.probability ? -1 : 1).map(clazz => {
                                                    const beautifiedProbability = (clazz.probability * 100).toFixed(1);
                                                    const triggered = clazz.probability > .2;

                                                    return (
                                                        <div style={{ display: "flex", width: "100%", alignItems: "center", gap: 8 }}>
                                                            <div style={{ flexGrow: 1 }}>
                                                                <MetaBasicEntry enableCopy={false} title={clazz.className} value={`${beautifiedProbability}%`}/>
                                                            </div>
                                                            <WarningAmberRounded sx={{
                                                                fontSize: 20,
                                                                color: triggered ? "#5028c8" : "#4E5058"
                                                            }}/>
                                                        </div>
                                                    );
                                                })
                                            }
                                            <FormDivider/>
                                            {
                                                (param as any[]).filter(a => a.probability < .2).sort((a, b) => a.probability > b.probability ? -1 : 1).map(clazz => {
                                                    const beautifiedProbability = (clazz.probability * 100).toFixed(1);
                                                    const triggered = clazz.probability > .2;

                                                    return (
                                                        <div style={{ display: "flex", width: "100%", alignItems: "center", gap: 8 }}>
                                                            <div style={{ flexGrow: 1 }}>
                                                                <MetaBasicEntry enableCopy={false} title={clazz.className} value={`${beautifiedProbability}%`}/>
                                                            </div>
                                                            <WarningAmberRounded sx={{
                                                                fontSize: 20,
                                                                color: triggered ? "#5028c8" : "#4E5058"
                                                            }}/>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </>
                                    }/>
                                )]
                            ])}/>

                            <MenuDivider/>

                            <MenuButton text={"Delete image"} icon={<DeleteRounded/>} onSelect={async () => {
                                await api.removeImageFromCurrentProject(cI?.id!);
                            }}/>
                        </>
                    }/>

                    <IconButton tooltip={"Info"} children={<InfoRounded/>} variant={localState.openImageDetailFooter ? "primary" : "default"} onClick={() => {
                        setLocalState(prevState => ({
                            ...prevState,
                            openImageDetailFooter: !prevState.openImageDetailFooter
                        }));
                    }}/>

                    <ButtonGroup>
                        <IconButton children={<DownloadRounded/>} onClick={() => {
                            api.downloadManager.downloadImage(cI?.id).then(() => {});
                        }}/>

                        <IconButton children={<StarRounded sx={{ transition: "color 50ms", color: cI?.favourite ? "gold" : "inherit" }}/>} onClick={() => {
                            webDB.images.update(cI?.id!, {
                                "favourite": !cI?.favourite ?? true
                            });
                        }}/>

                        <IconButton children={<CloseRounded/>} onClick={() => {
                            api.unselectImage();
                        }}/>
                    </ButtonGroup>
                </div>

                {/*
                https://github.com/prc5/react-zoom-pan-pinch/blob/master/src/stories/examples/image-responsive/example.tsx
                <div style={{
                    flexGrow: 1,
                    overflow: "hidden",
                    height: "0px",
                    position: "relative",
                    display: "flex"
                }} children={
                    <TransformWrapper limitToBounds>
                        <TransformComponent contentStyle={{}}>
                            <img alt={"img"} id={"cI-view-main-cI"} src={URL.createObjectURL(cI.data)} style={{
                                // position: "absolute",
                                objectFit: "contain",
                                objectPosition: "center",
                                maxWidth: "100%",
                                maxHeight: "100%",
                                height: "auto",
                                borderRadius: ".5rem"
                            }}/>
                        </TransformComponent>
                    </TransformWrapper>
                }/>
                */}

                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",

                    // flexDirection: localState.toolbarPosition === ToolbarPosition.TOP ? "column" : "column-reverse",
                    flexDirection: "column",

                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                        position: "relative",
                        flexGrow: 1
                    }}>
                        <Flex g={39} baseProps={{
                            style: {
                                height: "100%",
                                width: "100%",
                                flexDirection: "column",
                                alignItems: "center",
                                position: "relative"
                            }
                        }}>
                            { localState.enableTouchGestures && (
                                <div className={"click-pad-wrapper"}>
                                    <div className={"click-pad"} onClick={() => modeSelectionPointer(-1, false)}/>
                                    <span/>
                                    <div className={"click-pad"} onClick={() => modeSelectionPointer(1, false)}/>
                                </div>
                            ) }

                            <Flex g={0} baseProps={{
                                style: {
                                    width: "100%",
                                    height: "100%",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    position: "relative"
                                }
                            }}>
                                { /* Current page tray */ }
                                <Slide
                                    in={localState.transitionState === "initial" ? true : !isTransitioning}
                                    direction={direction === "forward" ? "right" : "left"}
                                    appear={false}
                                    enter={true}
                                    exit={true}
                                    timeout={{
                                        exit: pageTransitionDuration
                                    }}
                                    style={{
                                        transitionTimingFunction: "ease-in-out",
                                        top: "0",
                                        position: "absolute",
                                        left: "0",
                                        width: "100%"
                                    }}
                                >
                                    <div style={{
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }} children={
                                        // <div style={{
                                        //     position: "absolute",
                                        // }} children={
                                        //     <ContentSensitiveRenderer csa={{ sensitivity: cI.contentSensitivity ?? ContentSensitivity.UNCLASSIFIED }} children={ctx => (
                                        //         <img className={ctx.classes.img} alt={"img"} id={"cI-view-main-cI"} src={URL.createObjectURL(cI.data)} style={{
                                        //             // position: "absolute",
                                        //             objectFit: "contain",
                                        //             objectPosition: "center",
                                        //             maxWidth: "100%",
                                        //             maxHeight: "100%",
                                        //             height: "auto",
                                        //             borderRadius: ".5rem",
                                        //         }}/>
                                        //     )}/>
                                        // }/>
                                        // <TestTransformedImage image={cI}/>

                                        <img alt={"img"} id={"cI-view-main-cI"} src={URL.createObjectURL(cI.previewData!)} style={{
                                            position: "absolute",
                                            objectFit: "contain",
                                            objectPosition: "center",
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            height: "auto",
                                            borderRadius: ".5rem",
                                        }}/>
                                    }/>
                                </Slide>

                                { /* Next page tray */ }
                                <Slide
                                    direction={direction === "forward" ? "left" : "right"}
                                    onTransitionEnd={onPageTransitionFinished}
                                    in={isTransitioning}
                                    appear={false}
                                    exit={false}
                                    timeout={{
                                        enter: pageTransitionDuration + pageTransitionDeltaDuration
                                    }}
                                    style={{
                                        transitionTimingFunction: "ease-in-out",
                                        top: "0",
                                        position: "absolute",
                                        left: "0",
                                        width: "100%"
                                    }}
                                >
                                    <div style={{
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }} children={
                                        nI && (
                                            // <div style={{
                                            //     position: "absolute"
                                            // }} children={
                                            //     <ContentSensitiveRenderer csa={{ sensitivity: nI.contentSensitivity ?? ContentSensitivity.UNCLASSIFIED }} children={ctx => (
                                            //         <img className={ctx.classes.img} alt={"img"} id={"cI-view-main-cI"} /* <- TODO: Why duplicate id?! */ src={URL.createObjectURL(nI.data)} style={{
                                            //             objectFit: "contain",
                                            //             objectPosition: "center",
                                            //             maxWidth: "100%",
                                            //             maxHeight: "100%",
                                            //             height: "auto",
                                            //             borderRadius: ".5rem"
                                            //         }}/>
                                            //     )}/>
                                            // }/>
                                            <img alt={"img"} id={"cI-view-main-cI"} /* <- TODO: Why duplicate id?! */ src={URL.createObjectURL(nI.previewData!)} style={{
                                                position: "absolute",
                                                objectFit: "contain",
                                                objectPosition: "center",
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                height: "auto",
                                                borderRadius: ".5rem",
                                            }}/>
                                        )
                                    }/>
                                </Slide>
                            </Flex>
                        </Flex>
                    </div>

                    <ImageViewFooter open={localState.openImageDetailFooter} image={cI}/>
                </div>


            </div>


            <TransitionGroup style={{ height: "100%" }}>
                { localState.openMetaView && (
                    <Collapse style={{ height: "100%" }} orientation={"horizontal"} key={cI.id}>
                        <Workspace style={{ marginLeft: 8 }} config={{mode: "desktop", name: "project", resizable: true, resizablePropertyOverrides: { resizeHandles: ["w"] }}} children={
                            <MetadataViewV2/>
                        }/>
                    </Collapse>
                ) }
            </TransitionGroup>


            {/*
            { localState.openImageDetailFooter && (
                <Workspace style={{ marginLeft: 8, maxHeight: "calc(100% - 0px)" }} config={{mode: "desktop", name: "project", resizable: true, resizablePropertyOverrides: { resizeHandles: ["w"] }}} children={
                    <MetadataViewV2/>
                }/>
            ) }
            */}

        </StyledImageView>
    );
}
