import {FC} from "react";
import styled from "styled-components";
import {PersonData} from "../PersonData";
import {SocialsIcon} from "./SocialsIcon";
import {GitHub, LinkedIn} from "@mui/icons-material";

const StyledSocialsIconList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

export const SocialsIconList: FC<{
    for: PersonData
}> = props => {
    const socials = props.for.socials;
    return (
        <StyledSocialsIconList>
            <SocialsIcon for={socials.github} children={
                <GitHub/>
            }/>
            <SocialsIcon for={socials.linkedin} children={
                <LinkedIn/>
            }/>
        </StyledSocialsIconList>
    );
}
