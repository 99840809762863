import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const NodeImageBase64ToBlobConvertor: NodeSetupInfo = {
    label: "NodeImageBase64ToBlobConvertor",
    classname: "conversion.image-base64-to-blob",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "conversion.image-base64-to-blob",
        label: "img-base64->blob",
        defOutPins: ["blob"],
        defInPins: ["base64"],
        init: function () {
            this.pins.in("base64").attachOnRead(async base64 => {
                const url = `data:image/png;base64,${base64}`;
                const res = await fetch(url);
                const blob = await res.blob();
                this.pins.out("blob").write(blob);
            });
        }
    })
}
