import {SortingAction} from "./SortingAction";
import {ActionParameters} from "./ActionParameters";
import {Formik} from "formik";
import {TagEditor} from "../TagEditor";

export const sortingActions: Array<SortingAction> = []

sortingActions.push({
    getDescription: ctx => "",
    getTitle: ctx => "",
    previewTitle: "Add tags",
    previewDescription: "Adds specified tags to the image",
    execute: async ctx => {},
    buildActionParams: async (loadDialogDOMContext, ctx) => new Promise<ActionParameters>((resolve, reject) => {
        loadDialogDOMContext.openDirect(ctx1 => (
            <TagEditor title={"Add tags to image"} suggestions={() => ["b-roll", "released", "training-data"]} open={true} onClose={() => {}} initialTags={[]} onSave={tags => {
                resolve({
                    data: new Map<string, any>([
                        ["tags", tags]
                    ])
                });
            }}/>
        ), "sortingActions")
    })
})

sortingActions.push({
    getDescription: ctx => "",
    getTitle: ctx => "",
    previewTitle: "Move to project",
    previewDescription: "Moves the image to a project",
    execute: async ctx => {},
    buildActionParams: async (loadDialogDOMContext, ctx) => new Promise<ActionParameters>(resolve => {
        loadDialogDOMContext.openDirect(ctx1 => (
            <p>HELLO WORLD</p>
        ), "sortingActions")
    })
})
