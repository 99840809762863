import React, {useContext} from "react";
import {
    CommandContext,
    CommandNode,
    CommandNodeOption,
    KeyCommandTest
} from "../../../ardai/_/keyCommand/test/KeyCommandTest";
import {SimulationWindowAPIContext} from "../window/VesselSimulationMain";
import {ofHex} from "../../../base/logic/style/Color";

export const SimulationKeyCommandMainComponent: React.FC = props => {
    const api = useContext(SimulationWindowAPIContext);

    return (
        <KeyCommandTest cmdLib={CommandNode.newRoot()
            .addSubcommand(new CommandNode({
                key: "KeyZ",
                title: "Toggle zen mode",
                getOptions(): Array<CommandNodeOption> {
                    return [
                        { title: "Tristate", value: "tristate", indicatorColor: ofHex("#ffdf60") },
                        { title: "True", value: "true" },
                        { title: "False", value: "false" },
                    ]
                },
                execute(ctx: CommandContext) {
                    const head = ctx.instruction.keyPath[ctx.instruction.keyPath.length - 1];
                    const val = head.option?.value;
                    let typedVal: boolean | undefined = undefined;
                    switch (val) {
                        case "true": {
                            typedVal = true;
                            break;
                        }
                        case "false": {
                            typedVal = false;
                        }
                    }
                    api.toggleZenMode(typedVal);
                }
            }))
        }/>
    );
}
