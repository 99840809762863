import React, {useState} from "react";
import {Flex} from "../../../Flex";
import {DecimalPinDisplay} from "../../../components/form/DecimalPinDisplay";
import {Numpad} from "../../../components/form/Numpad";

export type EmailVerifyPinCompoundProps = {
    verify: (pin: number) => Promise<boolean>
}

export type EmailVerifyPinCompoundState = {
    pin: string,
    blocked: boolean,
    processing: boolean,
    error: boolean
}

export const EmailVerifyPinCompound: React.FC<EmailVerifyPinCompoundProps> = props => {
    const [state, setState] = useState<EmailVerifyPinCompoundState>({
        pin: "",
        blocked: false,
        processing: false,
        error: false
    });

    const verify = async (pin: string) => {
        setState(prevState => ({
            ...prevState,
            blocked: true,
            processing: true
        }));
        const valid = await props.verify(Number(pin));
        if (!valid) {
            setState(prevState => ({
                ...prevState,
                error: true,
            }));
            setTimeout(() => {
                setState(prevState => ({
                    ...prevState,
                    blocked: false,
                    error: false,
                    pin: ""
                }));
            }, 820);
        } else {
            setState(prevState => ({
                ...prevState,
                error: false,
            }));
        }
    }

    return (
        <Flex g={30} baseProps={{
            style: {
                width: "100%",
                flexDirection: "column",
                alignItems: "center"
            }
        }}>
            <DecimalPinDisplay
                pin={state.pin}
                len={4}
                status={state.error ? "wrong-pin" : "initial"}
            />
            <Numpad
                onKeyStroke={n => {
                    if (state.pin.length === 4 || state.blocked) return;
                    const pin = state.pin + n;
                    setState(prevState => ({
                        ...prevState,
                        pin: pin
                    }));
                    if (state.pin.length >= 3) verify(pin).then(() => {});
                }}
                onDelete={() => {
                    if (state.pin.length === 0 || state.blocked) return;
                    setState(prevState => ({
                        ...prevState,
                        pin: prevState.pin.slice(0, -1)
                    }));
                }}
            />
        </Flex>
    );
}
