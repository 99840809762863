import React, {useContext, useState} from "react";
import {GenericMasonry} from "./GenericMasonry";
import {identity} from "lodash";
import styled from "styled-components";
import {ArdaiAPIContext} from "../../webapi/WebAPI";
import {webDB} from "../../webapi/WebAPIDB";
import {useLiveQuery} from "dexie-react-hooks";
import {BImage} from "./BImage";
import {MasonryImageElement} from "./MasonryImageElement";
import {Text} from "../../../triton/components/typography/Text";
import {Badge} from "./Badge";
import {Flex} from "../../../ardspace/arc/common/Flex";
import moment from "moment";
import {BoardListView} from "./BoardListView";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {SamplePostMasonryView} from "./SamplePostMasonryView";
import {AnimatePresence, motion} from "framer-motion";
import {Page} from "framer";
import {NavigationComponent} from "./NavigationComponent";

const StyledBArdai = styled.div`
  overflow: scroll;
  position: relative;
  background-color: black;
  padding: 6px 0;
  width: 100%;
  height: 100%;
  
  * {
    color: white;
  }
`;

// Create a client
const queryClient = new QueryClient()

export const BArdai: React.FC = props => {

    const images = useLiveQuery(() => webDB.images.toArray()) ?? [];

    const boards = useLiveQuery(() => webDB.projects.toArray()) ?? [];

    const [dOpen, setDOpen] = useState(false)

    return (
        <StyledBArdai>

            {/*
            <GenericMasonry cols={2} items={boards} keyExtractor={data => data.id} renderer={data => (
                <div>
                    {data.title}
                    {data.resources.length}
                </div>
            )}/>
            */}

            <QueryClientProvider client={queryClient}>
                <Text t={"Posts"}/>
                <SamplePostMasonryView/>

                {/*
                <Text t={"Boards"}/>
                <BoardListView/>
                */}
            </QueryClientProvider>

            <NavigationComponent/>
        </StyledBArdai>
    );
}
