import React, {PropsWithChildren, useContext} from "react";
import {SDInterfaceAPIContext} from "./SDInterfaceAPI";
import {
    SDInterfaceDynStateContext,
    SDInterfaceRequestContext,
    SDInterfaceStateContext,
    SDInterfaceStateCtxContext
} from "./SDInterfaceMain";
import {SDInterfaceState} from "./SDInterfaceState";
import {IStaticStateCtx} from "../../ardai/hooks/StaticStateHook";

export const SDInterfaceMaster: React.FC<PropsWithChildren> = props => {
    const sdApi = useContext(SDInterfaceAPIContext);
    const sdStateCtx = useContext(SDInterfaceStateCtxContext);
    const sdDynStateCtx = useContext(SDInterfaceDynStateContext);
    const rcd = useContext(SDInterfaceRequestContext)!;

    sdApi.updateReactStateInfo(sdStateCtx as IStaticStateCtx<SDInterfaceState>);
    sdApi.updateReactDynStateInfo(sdDynStateCtx[0], sdDynStateCtx[1]);

    sdApi.updateRequestContextData(rcd);

    return (
        <>{ props.children }</>
    );
}
