import {Theme} from "./theming/Theme";
import {useContext, useEffect, useState} from "react";
import {TritonDefaults} from "./TritonDefaults";
import {TritonContext} from "./TritonContext";
import {Triton} from "./Triton";

export function useTriton(): Triton {
    return useContext(TritonContext);
}

export function useTheme(): Theme {
    const [theme, setTheme] = useState<Theme>(TritonDefaults.theme);
    const triton = useContext(TritonContext);

    useEffect(() => {
        setTheme(triton.theme);
    }, [triton.theme]);

    return theme;
}
