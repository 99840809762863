import React, {useContext} from "react";
import {Topic} from "./Topic";
import styled from "styled-components";
import {TopicSelectorStateContext} from "./TopicSelectorStateContext";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {IconButton} from "../../components/IconButton";
import {DeleteRounded, VisibilityOffRounded, VisibilityRounded} from "@mui/icons-material";

const StyledTopicPreviewCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #1e202a;
  padding: 8px;
  border-radius: 8px;
  
  &[data-disabled=true] {
    opacity: .5;
  }
  
  .topic-preview-title {    
    &:hover {
      filter: brightness(1.2);
      text-decoration-line: underline;
    }
  }
  
  &:hover .topic-preview-appendix {
    opacity: 1;
  }
  
  .topic-preview-appendix {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    transition: opacity 50ms;
    opacity: 0;
  }
`;

export type TopicPreviewCardProps = {
    topic: Topic
}

export const TopicPreviewCard: React.FC<TopicPreviewCardProps> = props => {
    const [state, setState] = useContext(TopicSelectorStateContext);
    const topic = props.topic;
    const toggleExclusion = () => setState(prevState => ({
        ...prevState,
        topic: prevState.topics.map(t => {
            if (t !== topic) return t;
            t.excluded = !topic.excluded;
            return t;
        })
    }));

    return (
        <StyledTopicPreviewCard data-disabled={topic.excluded} onClick={e => {
            if (e.altKey) {
                e.preventDefault();
                e.stopPropagation();
                toggleExclusion();
            }
        }}>
            <div>
                <DescriptiveTypography noSelect text={topic.title} style={{ cursor: "pointer" }} baseProps={{
                    className: "spice-preview-title"
                }}/>
            </div>

            <div className={"topic-preview-appendix"}>
                { topic.excluded ? (
                    <IconButton size={"small"} children={<VisibilityOffRounded/>} tooltip={"Include"} onClick={() => toggleExclusion()}/>
                ) : (
                    <IconButton size={"small"} children={<VisibilityRounded/>} tooltip={"Exclude"} onClick={() => toggleExclusion()}/>
                ) }
                <IconButton size={"small"} children={<DeleteRounded/>} onClick={() => {
                    setState(prevState => ({
                        ...prevState,
                        topics: prevState.topics.filter(t => t.id !== topic.id)
                    }));
                }}/>
            </div>
        </StyledTopicPreviewCard>
    );
}
