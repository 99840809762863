import {FC} from "react";
import {Pin} from "../backend/Pin";
import styled from "styled-components";
import {Tag} from "../../ardai/components/Tag";
import {ColorableTag} from "../../ardai/components/ColorableTag";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {Color} from "../../base/logic/style/Color";
import {ButtonGroup} from "../../ardai/components/ButtonGroup";
import {PinLinkTag} from "./PinLinkTag";
import {NodeLinkTag} from "./NodeLinkTag";

const StyledPinConnectionInfoDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export type PinConnectionInfoDisplayProps = {
    fromPin: Pin,
    destPin: Pin,

    // onRequest
    connectionDirectionContext?: "in" | "out" | "duplex"
}

export const PinConnectionInfoDisplay: FC<PinConnectionInfoDisplayProps> = props => {
    const { fromPin, destPin } = props;
    const dir = props.connectionDirectionContext ?? "in";
    const value = destPin.lastWriteState;

    return (
        <StyledPinConnectionInfoDisplay>
            <div style={{
                display: "flex"
            }}>
                <NodeLinkTag node={destPin.node}/>
                <PinLinkTag pin={destPin}/>
            </div>
            <DescriptiveTypography noSelect text={`-> ${String(value ?? "?")}`} style={{
                whiteSpace: "nowrap"
            }}/>
        </StyledPinConnectionInfoDisplay>
    );
}
