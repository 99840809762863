import {NodeSetupInfo} from "../../NodeSetupInfo";
import {v4} from "uuid";
import {Node} from "../../../backend/Node";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {MenuGroup} from "../../../../ardai/components/MenuGroup";

export type NodeQueueState = {
    queue: Array<any>,
    capacity: number
}

export const NodeQueue: NodeSetupInfo = {
    label: "NodeQueue",
    classname: "data.queue",
    parameterConfig: [],
    factory: parameters => {

        const isSimplistic = parameters.has("is-simplistic");

        if (isSimplistic) {
            return createSimplisticQueueNode();
        }

        return new Node<NodeQueueState>({
            id: v4(),
            classname: "data.queue",
            label: "queue",
            state: {
                queue: [],
                capacity: Number.MAX_VALUE
            },
            defInPins: [
                "add",
                "pop",
                "peek",
                "size",
                "void",
                "drain"
            ],
            defOutPins: [
                "out",
                "peek",
                "stat",
                "len"
            ],
            init() {
                const pins = this.pins;
                const state = this.state;

                const out = pins.out("out");
                const peek = pins.out("peek");
                const length = pins.out("len");

                const onContentChange = () => {
                    length.write(state.state.queue.length);
                }

                const pop = () => {
                    state.update(prevState => {
                        const first = prevState.queue.shift();
                        out.write(first);
                        return prevState;
                    });
                }

                const drain = () => {
                    while (state.state.queue.length > 0) {
                        pop();
                    }
                }

                const preventOverflow = () => {
                    while (state.state.queue.length > state.state.capacity) {
                        pop();
                    }
                }

                pins.in("size").attachOnRead(capacity => {
                    state.update({
                        capacity: Number(capacity)
                    });
                    preventOverflow();
                });

                pins.in("void").attachOnRead(capacity => {
                    state.update({
                        queue: []
                    });
                    onContentChange();
                });

                pins.in("drain").attachOnRead(capacity => {
                    drain();
                    onContentChange();
                });

                pins.in("add").attachOnRead(data => {
                    state.update(prevState => ({
                        queue: [data, ...prevState.queue]
                    }));
                    preventOverflow();
                    onContentChange();
                });

                pins.in("pop").attachOnRead(data => {
                    pop();
                    onContentChange();
                });

                pins.in("peek").attachOnRead(() => {
                    peek.write(state.state.queue[0])
                })
            },
            customRenderer: (node) => {
                if (parameters.has("no-display")) return undefined;

                return (
                    <div style={{
                        padding: "8px 0",
                        display: "grid",
                        gridTemplateRows: "min-content auto",
                        width: 100,
                        height: "100%"
                    }}>
                        <DescriptiveTypography text={
                            `${node.state.state.queue.length} of ${
                                node.state.state.capacity === Number.MAX_VALUE ? "∞" : node.state.state.capacity
                            }`
                        }/>

                        {/*
                        <MenuGroup children={
                            <div style={{
                                maxHeight: 100,
                                width: "100%",
                                overflowY: "scroll"
                            }}>
                                { node.state.state.queue.map(entry => (
                                    <DescriptiveTypography text={JSON.stringify(entry)} style={{
                                        whiteSpace: "nowrap",
                                        maxWidth: "100%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}/>
                                )) }
                            </div>
                        }/>
                        */}
                    </div>
                );
            }
        })
    }
}

function createSimplisticQueueNode(): Node {
    return new Node<NodeQueueState>({
        id: v4(),
        classname: "data.queue",
        label: "queue",
        state: {
            queue: [],
            capacity: Number.MAX_VALUE
        },
        defInPins: [
            "pop",
            "peek",
            "add",
        ],
        defOutPins: [
            "pop",
            "peek",
            "len"
        ],
        init() {
            const pins = this.pins;
            const state = this.state;
            const out = pins.out("pop");
            const peek = pins.out("peek");
            const length = pins.out("len");

            const onContentChange = () => {
                length.write(state.state.queue.length);
            }

            pins.in("add").attachOnRead(data => {
                state.update(prevState => ({
                    queue: [data, ...prevState.queue]
                }));
                onContentChange();
            });

            pins.in("pop").attachOnRead(data => {
                state.update(prevState => {
                    const first = prevState.queue.shift();
                    out.write(first);
                    return prevState;
                });
                onContentChange();
            });

            pins.in("peek").attachOnRead(() => {
                peek.write(state.state.queue[0])
            });
        }
    })
}
