import React, {useContext} from "react";
import styled from "styled-components";
import {Activity} from "./Activity";
import {Text} from "../../../../triton/components/typography/Text";
import {formatWithOptions} from "util";
import moment from "moment";
import {UserProfileContext} from "./UserProfile";

const StyledActivityFieldCard = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  
  .activity-info {
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 8px;
    
    .activity-icon {
      width: 60px;
      aspect-ratio: 1 / 1;
      position: relative;
      
      .activity-large-image {
        border-radius: 6px;
        background: transparent;
        width: 60px;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        object-position: center;
        user-select: none;
        -webkit-user-drag: none;
      }
    }
    
    .activity-stats {
      display: flex;
      flex-direction: column;
      gap: 1px;
    }
  }

  .activity-actions {

  }
`;

export type ActivityFieldCardProps = {
    activity: Activity
}

export const ActivityFieldCard: React.FC<ActivityFieldCardProps> = props => {
    const ctx = useContext(UserProfileContext);
    const a = props.activity;
    const largeImageSizePx = 60;
    const smallImageSizePx = 20;
    const smallImageSizeOutlineSizePx = 2;
    const fc = ctx.getFontColours();

    return (
        <StyledActivityFieldCard>
            <div className={"activity-info"}>
                <div className={"activity-icon"}>
                    <img
                        className={"activity-large-image"}
                        alt={"activity-large-image"}
                        src={"https://cdn.discordapp.com/avatars/1083071731427717120/8e9b2fe477b17509776e1f8446962bf0.png"}
                    />
                </div>

                <div className={"activity-stats"}>
                    <Text col={fc.main} t={a.name} fs={14} ff={"Whitney Semibold"}/>
                    <Text col={fc.text} ff={"Whitney Light"} t={a.details} fs={14}/>
                    <Text col={fc.text} ff={"Whitney Light"} t={a.state} fs={14}/>
                    <Text col={fc.text} ff={"Whitney Light"} t={`${moment.duration(Date.now() - a.startTimestampUnix, "milliseconds").humanize()} elapsed`} fs={14}/>
                </div>

            </div>
            <div className={"activity-actions"}>

            </div>
        </StyledActivityFieldCard>
    );
}
