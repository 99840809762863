import {StorybookPage} from "../../../../storybook/StorybookPage";
import {emailAndUsernamePage} from "./EmailAndUsernameStoryPage";
import {Flex} from "../../../../Flex";
import {StorybookStaticStepIndicator} from "../../../../components/StorybookStaticStepIndicator";
import {TypoHeaderVisual} from "../../../../components/TypoHeaderVisual";
import {FormElement} from "../../../../../../../triton/components/forms/FormElement";
import {PasswordStrengthIndicator} from "../../components/PasswordStrengthIndicator";
import {RoundedFormikPasswordInput} from "../../../../components/form/RoundedFormikPasswordInput";
import {ErrorMessage} from "../../../../../../../triton/components/forms/ErrorMessage";
import {RoundedMainTextButton} from "../../../../components/RoundedMainTextButton";
import {CircularProgress} from "@mui/material";
import {BoardingActionHint} from "../../../../components/BoardingActionHint";
import React from "react";
import {birthdatePage} from "./BirthdatePage";

export type PasswordStoryPageData = {
    password: string
}

export const passwordPage: StorybookPage<PasswordStoryPageData> = {
    name: "password",
    title: "Password",
    initialValues: { password: "" },
    backPage: () => emailAndUsernamePage,
    validate: (values, errors) => {
        const pw = values.password;
        if (pw.length < 8) errors.password = "A password should at least be 8 characters long";
        if (pw.trim().length !== pw.length) errors.password = "A password cannot start or end with a whitespace";
        if (pw.length === 0) errors.password = "Password cannot be blank";
    },
    onSubmit: (api, values) => {
        api.setPageData("password", values);
    },
    header: (api, fp) => (
        <Flex g={30} baseProps={{
            style: {
                flexDirection: "column",
                alignItems: "center"
            }
        }}>
            <StorybookStaticStepIndicator step={2}/>
            <TypoHeaderVisual
                header={"Create a password"}
                descriptiveSubheader={"Please create a secure password you haven't used anywhere else"}
                text={"Please create a secure password you haven't used anywhere else"}
            />
        </Flex>
    ),
    page: (api, fp) => (
        <FormElement>
            <PasswordStrengthIndicator password={fp.values.password}/>
            <RoundedFormikPasswordInput
                // autoFocus
                fp={fp}
                name={"password"}
            />
            <ErrorMessage formikProps={fp} name={"password"}/>
        </FormElement>
    ),
    bottomActionPanel: (api, fp, isSubmitting) => (
        <RoundedMainTextButton
            type={"submit"}
            text={!fp.isSubmitting ? "Next" : (
                <CircularProgress size={22} thickness={5} style={{ color: "black" }}/>
            )}
            onClick={() => api.submitAndForward(birthdatePage)}
        />
    ),
    footer: (api) => (
        <BoardingActionHint question={"Already have an account?"} action={"Log In"} onClick={() => {}}/>
    )
}
