import React, {useContext} from "react";
import {IconButton} from "./IconButton";
import {
    CodeRounded, DataArrayRounded, DeveloperBoard, DnsRounded, FormatPaintRounded,
    InboxRounded,
    KeyboardCommandKeyRounded, LightModeRounded, LockRounded,
    ModelTrainingRounded, ModeNightRounded, MoreHorizRounded, PaletteRounded,
    PhotoAlbumRounded, SecurityRounded,
    SettingsRounded, SourceRounded, TagRounded, TopicRounded,
} from "@mui/icons-material";
import {TabBar} from "./TabBar";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Menu} from "./Menu";
import {MenuButton} from "./MenuButton";
import axios from "axios/index";
import {ControlModalCompound, ModalRenderer} from "./ControlModalCompound";
import {ButtonModalCompound} from "./ButtonModalCompound";
import {TopicSelectorMain} from "../tools/topicSelector/TopicSelectorMain";
import {
    LineArrayToJsonArrayConvertorMain
} from "../tools/lineArrayToJsonArrayConvertor/LineArrayToJsonArrayConvertorMain";
import {Tag} from "./Tag";
import {ColorableTag} from "./ColorableTag";
import {Color, ofHex} from "../../base/logic/style/Color";
import {HelpHint} from "./HelpHint";
import {CategoryHeader} from "../_/CategoryHeader";
import {Separator} from "../../base/components/base/Separator";
import {MenuDivider} from "@szhsin/react-menu";
import {FormDivider} from "../../triton/components/forms/FormDivider";
import {PowerActionMenuSection} from "./navbar/PowerActionMenuSection";
import {triton} from "../../triton/Triton";
import {TritonDefaults} from "../../triton/TritonDefaults";
import theme = TritonDefaults.theme;
import lightTheme = TritonDefaults.lightTheme;
import {useTriton} from "../../triton/TritonHooks";
import {ThemeSelectorMenuPart} from "./ThemeSelectorMenuPart";
import {FlatIconButton} from "./FlatIconButton";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {ScreenSaverNamespace} from "./shared/screenSaver/ScreenSaver";
import {Modal} from "../../triton/components/dialogs/Modal";
import {px} from "../../base/logic/style/DimensionalMeasured";
import {ChallengeTemplateCreator} from "../_/challengeTemplateCreator/ChallengeTemplateCreator";
import {CopyStudyTemplateCreator} from "../_/copyStudyTemplateCreator/CopyStudyTemplateCreator";
import {SingleImageSelectorView} from "../_/imageSelectionView/SingleImageSelectorView";

export const Navbar: React.FC = props => {
    const location = useLocation();
    const shards = location.pathname.split("/").filter(s => s.trim().length > 0);
    const fallbackTab = "generate";
    const currentTab = shards.length > 1 ? shards[1] : fallbackTab;
    const navigate = useNavigate();

    const api = useContext(ArdaiAPIContext);

    return (
        <div style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
            alignItems: "center",
            gap: "8px"
        }}>

            <Menu opener={
                <FlatIconButton children={
                    <PaletteRounded sx={{
                        fontSize: 16
                    }}/>
                }/>
            }>
                <ThemeSelectorMenuPart/>
            </Menu>

            <Menu menuProps={{
                overflow: "auto"
            }} opener={
                <FlatIconButton children={
                    <MoreHorizRounded fontSize={"small"}/>
                }/>
            }>
                <MenuButton
                    icon={<LockRounded/>}
                    text={"Activate screensaver"}
                    onSelect={() => {
                        api.crudeBroker.notify(ScreenSaverNamespace.SCREEN_SAVER_ACTIVATION_EVENT, undefined);
                    }}
                    appendix={
                        <ColorableTag tag={"BETA"} c={Color.ofHex("#8561ff")}/>
                    }
                />

                <FormDivider title={"Toolbox"} paddingVertical={4}/>

                <ButtonModalCompound button={
                    <MenuButton text={"Challenge template creator"}/>
                } modalContent={ctx => (
                    <Modal
                        open={true}
                        onClose={ctx.close}
                        title={"Challenge template creator"}
                        w={px(1200)}
                        children={
                            <ChallengeTemplateCreator/>
                        }
                    />
                )}/>

                <ButtonModalCompound button={
                    <MenuButton text={"Copy study template creator"}/>
                } modalContent={ctx => (
                    <Modal
                        open={true}
                        onClose={ctx.close}
                        title={"Copy study template creator"}
                        w={px(1200)}
                        children={
                            <CopyStudyTemplateCreator/>
                        }
                    />
                )}/>

                <ButtonModalCompound button={
                    <MenuButton text={"Image selector"}/>
                } modalContent={ctx => (
                    <Modal
                        open={true}
                        onClose={ctx.close}
                        title={"Image selector"}
                        // w={px(1200)}
                        children={
                            <SingleImageSelectorView/>
                        }
                    />
                )}/>

                <ButtonModalCompound
                    button={
                        <MenuButton icon={<DataArrayRounded/>} text={"Line-array to json-array convertor"} appendix={<>
                            <ColorableTag tag={"BETA"} c={Color.ofHex("#8561ff")}/>
                            <HelpHint text={"Convert a line-array file (typically .txt) to a json-array file (.json)"}/>
                        </>}/>
                    }
                    modalContent={ctx => (
                        <LineArrayToJsonArrayConvertorMain onClose={ctx.close}/>
                    )}
                />
                <ButtonModalCompound
                    button={
                        <MenuButton icon={<TagRounded/>} text={"Topic selector"}/>
                    }
                    modalContent={ctx => (
                        <TopicSelectorMain onClose={ctx.close}/>
                    )}
                />

                <Link to={"/ai/development/component"} style={{ textDecoration: "none" }} children={
                    <MenuButton icon={<DeveloperBoard/>} text={"Component testing space"}/>
                }/>

                <ThemeSelectorMenuPart/>

                <PowerActionMenuSection/>
            </Menu>

            <TabBar
                activeBar={currentTab}
                onTabChange={tab => navigate(tab)}
                tabs={[
                    // { id: "generate", title: "Generate", icon: <CodeRounded/> },
                    { id: "generate", title: "Create", icon: <CodeRounded/> },
                    // { id: "training", title: "Training", icon: <ModelTrainingRounded/> },
                    { id: "gallery", title: "Gallery", icon: <PhotoAlbumRounded/> },
                ]}
            />

            <FlatIconButton children={
                <LockRounded fontSize={"small"}/>
            } onClick={() => {
                api.crudeBroker.notify(ScreenSaverNamespace.SCREEN_SAVER_ACTIVATION_EVENT, undefined);
            }}/>

            <FlatIconButton children={<InboxRounded fontSize={"small"}/>} tooltip={"Notifications"}/>
            <FlatIconButton children={<KeyboardCommandKeyRounded fontSize={"small"}/>} tooltip={"Open command pallet"}/>

            {/*
            <IconButton size={"small"} children={<DnsRounded/>} tooltip={"Server"}/>
            <IconButton size={"small"} children={<SettingsRounded/>} tooltip={"Settings"}/>
            */}
        </div>
    );
}
