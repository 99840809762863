import React, {PropsWithChildren} from "react";
import styled from "styled-components";

const StyledIconBadge = styled.div<IconBadgeProps>`
  --size: ${p => p.size ?? 22}px;
  width: var(--size);
  aspect-ratio: 1 / 1;
  position: relative;
  cursor: pointer;
  user-select: none;
  
  & > * {
    --icon-size: calc(var(--size) + ${p => p.iconSizeOffset ?? -0}px);
    width: var(--icon-size);
    aspect-ratio: 1 / 1;
    user-select: none;
    -ms-user-select: none;
    // Just-in-case
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
`;

export type IconBadgeProps = PropsWithChildren<{
    size?: number,
    iconSizeOffset?: number
}>

export const IconBadge: React.FC<IconBadgeProps> = props => {
    return (
        <StyledIconBadge {...props}/>
    );
}
