import React, {useContext, useState} from "react";
import {VariableSlider} from "./VariableSlider";
import styled from "styled-components";
import {Workspace} from "../../ardai/components/Workspace";
import {FormDivider} from "../../triton/components/forms/FormDivider";
import {StateDispatcher} from "../../ship/test/core/StateDispatcher";
import {BasicSingleSelect} from "../../triton/components/forms/BasicSingleSelect";
import {SDInterfaceAPIContext} from "./SDInterfaceAPI";
import {SDDefaultSamplers} from "./SDDefaultSamplers";
import {MenuGroup} from "../../ardai/components/MenuGroup";
import {DefaultButton} from "../../ardai/components/DefaultButton";
import {ButtonBase} from "../../triton/components/buttons/ButtonBase";

const StyledGenerationConfigTab = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 100%;
  gap: 8px;
`;

const DebugVariableSliderWrapper: React.FC<{
    initial: number,
    children: (val: number, setVal: StateDispatcher<number>, initial: number) => React.ReactNode
}> = props => {
    const [val, setVal] = useState(props.initial);
    return (
        <>{
            props.children(val, setVal, props.initial)
        }</>
    );
}

export const GenerationConfigTab: React.FC = props => {

    const sdApi = useContext(SDInterfaceAPIContext);

    return (
        <StyledGenerationConfigTab>

            <Workspace config={{ mode: "desktop", name: "generation-config-main" }}>

                {/*
                <DebugVariableSliderWrapper initial={1} children={(val, setVal, initial) => (
                    <VariableSlider
                        title={"Batch count"}
                        minVal={1}
                        maxVal={100}
                        value={val}
                        defaultValue={initial}
                        onChange={value => setVal(value)}
                        suggestions={[
                            { value: 1, label: "single" },
                            { value: 3, label: "small batch" },
                            { value: 9, label: "default batch" },
                            { value: 100, label: "large batch" },
                        ]}
                    />
                )}/>
                */}

                {/*
                <VariableSlider
                    title={"Batch size"}
                    defaultValue={1}
                    minVal={1}
                    maxVal={100}
                />
                */}

                <FormDivider
                    paddingVertical={16}
                />

                <VariableSlider
                    title={"Clip skip"}
                    defaultValue={2}
                    minVal={1}
                    maxVal={12}
                    value={sdApi.req.clipSkip}
                    onChange={value => sdApi.updateRequest({
                        clipSkip: value
                    })}
                />

                <VariableSlider
                    title={"CFG scale"}
                    defaultValue={7}
                    minVal={1}
                    maxVal={20}
                    stepSize={.5}
                    value={sdApi.req.cfgScale}
                    onChange={value => sdApi.updateRequest({
                        cfgScale: value
                    })}
                />

                <MenuGroup title={"Sampling"} indentation={16} showIndentationLine>
                    <BasicSingleSelect
                        name={"sampler"}
                        selected={sdApi.req.sampler}
                        title={"Method (Sampler)"}
                        placeholder={"Select a sampler method"}
                        onSelect={value => sdApi.updateRequest({
                            sampler: value
                        })}
                        options={[
                            { id: SDDefaultSamplers.EULER, text: "Euler" },
                            { id: SDDefaultSamplers.EULER_A, text: "Euler A" },
                            { id: SDDefaultSamplers.HEUN, text: "Heun" },
                            // { id: SDDefaultSamplers.DPM_PP_2M_KARRAS, text: "DPM++ 2M Karras" },
                            { id: SDDefaultSamplers.UNI_PC, text: "UniPC" },
                        ]}
                    />
                    <VariableSlider
                        title={"Steps (Sampling steps)"}
                        defaultValue={20}
                        minVal={1}
                        maxVal={150}
                        stepSize={1}
                        value={sdApi.req.samplingSteps}
                        onChange={value => sdApi.updateRequest({
                            samplingSteps: value
                        })}
                        suggestions={[
                            { value: 20, label: "concept" },
                            { value: 50, label: "main" },
                        ]}
                    />
                </MenuGroup>
            </Workspace>

            <Workspace config={{ mode: "desktop", name: "generation-config-secondary" }}>
                <MenuGroup title={"Size"} indentation={16} showIndentationLine>
                    <VariableSlider
                        title={"Width"}
                        defaultValue={512}
                        minVal={1}
                        maxVal={512 * 4}
                        stepSize={1}
                        value={sdApi.req.width}
                        onChange={value => sdApi.updateRequest({
                            width: value
                        })}
                        suggestions={[
                            { value: 512, label: "default" },
                            { value: 768, label: "768" },
                        ]}
                    />
                    <VariableSlider
                        title={"Height"}
                        defaultValue={512}
                        minVal={1}
                        maxVal={512 * 4}
                        stepSize={1}
                        value={sdApi.req.height}
                        onChange={value => sdApi.updateRequest({
                            height: value
                        })}
                        suggestions={[
                            { value: 512, label: "default" },
                            { value: 768, label: "768" },
                        ]}
                    />
                    <VariableSlider
                        title={"Scale"}
                        defaultValue={1}
                        minVal={.5}
                        maxVal={4}
                        stepSize={.1}
                        value={sdApi.req.sizeScale}
                        onChange={value => sdApi.updateRequest({
                            sizeScale: value
                        })}
                        suggestions={[
                            { value: .5, label: "half size" },
                            { value: 1, label: "original" },
                            { value: 2, label: "double size" },
                            { value: 4, label: "quadruple size" },
                        ]}
                    />

                    <div style={{
                        display: "grid",
                        gap: 8,
                        gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))"
                    }}>
                        <DefaultButton children={"512x768"} size={"small"} onClick={() => {
                            sdApi.updateRequest({
                                width: 512,
                                height: 768
                            })
                        }}/>
                        <DefaultButton children={"1/1-ratio"} size={"small"} onClick={() => {
                            // noinspection JSSuspiciousNameCombination
                            sdApi.updateRequest(prevState => ({
                                width: 512,
                                height: prevState.width
                            }))
                        }}/>
                        <DefaultButton children={"flip w-h"} size={"small"} onClick={() => {
                            // noinspection JSSuspiciousNameCombination
                            sdApi.updateRequest(prevState => ({
                                width: prevState.height,
                                height: prevState.width
                            }))
                        }}/>
                    </div>
                </MenuGroup>
            </Workspace>

            <Workspace config={{ mode: "desktop", name: "generation-config-tertiary" }}>
                <MenuGroup title={"StableDiffusion kernel"} indentation={16} showIndentationLine>
                    <VariableSlider
                        title={"Batch size"}
                        defaultValue={1}
                        minVal={1}
                        maxVal={100}
                        stepSize={1}
                        value={sdApi.req.batchSize}
                        onChange={value => sdApi.updateRequest({
                            batchSize: value
                        })}
                        suggestions={[
                            { value: 1, label: "single" },
                            { value: 3, label: "3" },
                            { value: 9, label: "9" },
                        ]}
                    />
                </MenuGroup>
            </Workspace>
        </StyledGenerationConfigTab>
    );
}
