import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";
import axios from "axios";

export const NodeHttpRequest: NodeSetupInfo = {
    label: "Node Http Request",
    classname: "utils.http.request",
    parameterConfig: [],
    factory: parameters => new Node<{
        url?: string,
        payload?: string,
        requestTime?: number,
        method?: string,

        lastResponse?: {
            status: number,
            statusText: string,
            data: any
        }
    }>({
        id: v4(),
        classname: "utils.http.request",
        label: "http req.",
        defOutPins: ["body", "stat", "time"],
        defInPins: ["c", "url", "body", "method"],
        state: {},
        init: function () {
            const node = this;
            // Input ports
            const urlPort = this.pins.in("url");
            const payloadPort = this.pins.in("body");
            const methodPort = this.pins.in("method");
            // Output ports
            const bodyPort = this.pins.out("body");
            const statPort = this.pins.out("stat");
            const requestTimePort = this.pins.out("time");

            // Update the url via pin port input
            urlPort.attach({
                read(url: string) {
                    node.state.update({url});
                }
            });

            // Update the request body (payload) via pin port input
            payloadPort.attach({
                read(payload: string) {
                    node.state.update({payload});
                }
            });

            // Update the request method via pin port input
            methodPort.attach({
                read(method: string) {
                    node.state.update({ method });
                }
            });

            // Poll the url on every clock signal
            this.pins.in("c").attach({
                async read(clock: any) {
                    // const url = node.state.state.url;
                    // if (url === undefined) return; // TODO: Maybe send an error?
                    const startUnixTime = new Date().getTime();
                    try {
                        const reqPayload = node.state.state.payload;
                        const reqMethod = node.state.state.method;

                        const result = await axios.post("http://localhost:7860/sdapi/v1/txt2img", {
                            "prompt": "asdasd",
                            "negative_prompt": "asdasdasd",
                            "steps": 20,
                            "sampler_index": "UniPC",
                            "cfg_scale": 7,
                            "width": 512,
                            "height": 512,
                            "batch_size": 1,
                            "n_iter": 1,
                            "denoising_strength": 0.4,
                            "enable_hr": false,
                            "hr_scale": 1.5,
                            "hr_upscaler": "R-ESRGAN 4x+ Anime6B",
                            "hr_second_pass_steps": 50
                        }, {
                            headers: {
                                "Content-Type": "application/json",
                            }
                        })
                        const requestTimeInMS = new Date().getTime() - startUnixTime;
                        // Gather response data
                        const { status, statusText } = result;
                        const body = result.data;
                        // Update node state
                        node.state.update({
                            requestTime: requestTimeInMS,
                            lastResponse: {
                                statusText,
                                status,
                                data: body
                            }
                        });
                        // Write to output pins
                        bodyPort.write(body);
                        statPort.write(status);
                        requestTimePort.write(requestTimeInMS);
                    } catch (e) {
                        console.error(e);
                        node.state.update({
                            lastResponse: undefined,
                            requestTime: undefined
                        });
                    }
                }
            });
        },
        customRenderer(node) {
            const state = node.state.state;
            const url = state.url;
            const lastResponse = state.lastResponse;

            return (
                <div style={{
                    overflow: "hidden",
                    width: 100
                }}>
                    <DescriptiveTypography text={url ?? DefaultCharacterSymbols.placeholder} style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        overflow: "hidden",
                        fontSize: 10
                    }}/>
                    <DescriptiveTypography
                        text={`(${lastResponse?.status ?? DefaultCharacterSymbols.placeholder}) ${lastResponse?.statusText ?? DefaultCharacterSymbols.placeholder}`}
                        style={{
                            fontSize: 10
                        }}/>
                    <DescriptiveTypography text={`${state.requestTime ?? DefaultCharacterSymbols.placeholder} ms`}
                                           style={{
                                               fontSize: 10
                                           }}/>
                </div>
            );
        }
    })
}
