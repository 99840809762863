import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const NodeObjectToJSON: NodeSetupInfo = {
    label: "NodeObjectToJSON",
    classname: "objects.object-to-json",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "objects.object-to-json",
        label: "to json",
        defOutPins: ["s"],
        defInPins: ["o"],
        init: function () {
            this.createInternalWire({
                targetInPin: "o",
                targetOutPin: "s",
                transformer: s => JSON.stringify(s)
            })
        }
    })
}
