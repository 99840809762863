import React, {ReactNode, useEffect} from "react";
import styled from "styled-components";
import {Node} from "../backend/Node";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {useForceRenderFunc} from "../../ForceRerenderHook";
import {Triton} from "../../triton/Triton";
import {useTriton} from "../../triton/TritonHooks";
import {NodePinDisplay} from "./NodePinDisplay";
import {useStaticState} from "../../ardai/hooks/StaticStateHook";
import {useKeyboardEvent} from "../../ardai/_/keyCommand/test/KeyCommandTest";
import {useNodeCanvasBackend} from "./NodeCanvasBackend";
import {Alignment} from "./Alignment";
import {DragIndicatorRounded} from "@mui/icons-material";
import {PinBankRenderer} from "./PinBankRenderer";
import {Tag} from "../../ardai/components/Tag";
import {AutoSizeDisplay} from "./nodes/visualization/AutoSizeDisplay";
import {AttachmentGroupMember} from "../backend/attachments/defaults/AttachmentGroupMember";
import {Dot} from "../../ardai/components/Dot";

const StyledNodeDisplay = styled.div<{
    t: Triton,
    showWestPinBank: boolean,
    showEastPinBank: boolean,
    dye?: string
}>`
  --padding: 8px;
  --border-radius: 8px;
  background-color: ${p => p.t.col("bg_modal")}; // TODO: make abstraction *1
  // background-color: ${p => p.dye ?? p.t.col("bg_modal")}; // TODO: make abstraction *1
  
  z-index: 10; // TODO: Needs rel positioning
  
  border-radius: var(--border-radius);
  overflow: visible;
  height: 100%;
  
  position: relative;
  
  .foreground-layer {
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-rows: min-content auto min-content;
  }
  
  // TODO: Remove
  &.group-selected {
    box-shadow: 0 0 0 1px ${p => p.t.colObj("color_primary").withAlpha(.5).css()};
    // box-shadow: 0 0 0 1px crimson;
  }
  
  &[data-selected=true] {
    box-shadow: 0 0 0 1px ${p => p.t.colObj("color_primary").withAlpha(.5).css()};
  }
  
  &[data-soft-selected=true] {
    &:before {
      --bleed-h: 8px;
      --bleed-v: 8px;
      pointer-events: none;
      content: '';
      width: calc(100% + var(--bleed-h));
      height: calc(100% + var(--bleed-v));
      position: absolute;
      left: 50%;
      top: 50%;
      transform-origin: center;
      transform: translateY(-50%) translateX(-50%);
      border-radius: 8px;
      box-shadow: 0 0 0 1.5px ${p => p.t.colObj("color_primary").withAlpha(.5).css()};
    }
  }
  
  * {
    font-family: "JetBrains Mono", monospace;
  }
  
  .background-layer {
    touch-action: none;
    border-radius: var(--border-radius);
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${p => p.dye ?? "transparent"};
    
    opacity: .10;
  }
  
  .handle {
    cursor: move;
  }
  
  .header {
    padding: var(--padding);
    display: grid;
    grid-template-columns: auto min-content;
    gap: 4px;
    
    .header-title {
      display: grid;
      // gap: 4px;
      align-items: center;
      grid-template-columns: min-content auto;
      
      // Node title text (DescriptiveText)
      .node-title {
        cursor: pointer;
        width: min-content;
        white-space: nowrap;
        
        &:hover {
          text-decoration: underline;
        }
      }
      
      .node-title-appendix-container {
        --dot-gap: 6px;
        display: flex;
        align-items: center;
        padding-left: var(--dot-gap);
        gap: var(--dot-gap);

        .node-title-appendix-separator {
          opacity: 1;
        }
        
        .node-title-appendix {
          white-space: nowrap;
          opacity: .5;
          font-size: 10px;
        }
      }
    }
    
    .header-right {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }
  
  .main {
    display: grid;
    // grid-template-columns: ${p => p.showWestPinBank ? "1fr" : "min-content"} auto ${p => p.showEastPinBank ? "1fr" : "min-content"};
    
    grid-template-columns: min-content auto min-content;
    
    .pin-bank {
      display: flex;
      flex-direction: column;
      // background-color: ${p => p.t.col("bg_modal")};
      // background-color: ${p => p.dye ?? p.t.col("bg_modal")}; // TODO: make abstraction *1
      
      &.left {
        padding: var(--padding) var(--padding) var(--padding) 0;
      }

      &.right {
        padding: var(--padding) 0 var(--padding) var(--padding);
      }
    }
    
    .main-display {
      // width: 100%;
      width: 100%;
      border-radius: 8px;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      
      &[data-has-inner-display=true] {
        padding: 0 8px;
      }

      &[data-has-inner-display=false] {
        // display: none;
        opacity: 0;
      }
    }
  }
  
  .footer {
    padding: var(--padding);
    min-height: var(--padding);
  }
`;

export type NodeDisplayProps<StateType = any> = {
    node: Node<StateType>,
    children?: (node: Node<StateType>) => ReactNode,
    isPreview?: boolean
}

export type NodeDisplayState = {
    selected: boolean
}

export const NodeDisplay = <NodeStateType,>(props: NodeDisplayProps<NodeStateType>) => {
    const t = useTriton();
    const backend = useNodeCanvasBackend();
    const forceRender = useForceRenderFunc();
    const isPreview = props.isPreview ?? false;

    const node = props.node;
    const nodeCfg = node.config;
    const nodeVisCfg = nodeCfg.visualConfig;

    const pinLayout = node.pins;

    const isWestSideBlank =
        pinLayout.allDefaultIn.length === 0 &&
        pinLayout.filterAllBanks(bank => bank.isInGroup("input") || bank.isGroupless).length === 0;

    const isEastSideBlank =
        pinLayout.allDefaultOut.length === 0 &&
        pinLayout.filterAllBanks(bank => bank.isInGroup("output")).length === 0;

    // display status flags
    let shouldShowHeader = true;
    let isNodeGroupMember = false;

    const attachments = node.attachments;

    // handle group membership
    nodeGroupLogic: {
        const groupMembershipAttachmentKey = "group-membership";
        isNodeGroupMember = attachments.has(groupMembershipAttachmentKey);
        const nodeGroupMembership = attachments.get<AttachmentGroupMember>(groupMembershipAttachmentKey);
        const nodeGroupParentId = nodeGroupMembership?.groupParentNodeId;
        if (nodeGroupParentId !== undefined) {
            let nodeGroupParent = node.env.getNodeByID(nodeGroupParentId);
            if (nodeGroupParent === undefined) break nodeGroupLogic;
            // node is member of a valid node grouping
            const areOfEqualClass = node.classname === nodeGroupParent.classname;

            if (areOfEqualClass) {
                shouldShowHeader = false;
            }
        }
    }

    const [state, ctx] = useStaticState<NodeDisplayState>({
        id: "NodeDisplay-local",
        staticMode: false,
        initial: {
            selected: false
        }
    }).stateWithCtx;

    useEffect(() => node.observer.observeGlobally().on(() => {
        forceRender();
    }).destructor, []);

    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!e.ctrlKey) return;
        ctx.reverseBool("selected");
    }

    const duplicateNode = () => {
        backend.duplicateNode(node.id);
    }

    useKeyboardEvent("keydown", e => {
        if (!state.selected) return;

        if (e.key === "Delete") {
            backend.deleteNode(node.id);
        }

        if (e.key === "d") {
            e.preventDefault();
            duplicateNode();
        }
    })

    // const nodeInnerDisplay = node.config.customRenderer === undefined ? (
    //     props.children?.(props.node)
    // ) : (
    //     node.config.customRenderer(props.node)
    // )
    let nodeInnerDisplay = undefined;
    const areWidgetsEnabled = backend.state.ui.canvas.nodes.widgets.enabled;
    if (areWidgetsEnabled) {
        nodeInnerDisplay = node.config.customRenderer === undefined ? (
            props.children?.(props.node)
        ) : (
            node.config.customRenderer(props.node)
        )
    }

    return (
        <StyledNodeDisplay
            t={t}
            dye={nodeVisCfg?.dye}
            data-selected={state.selected}
            showEastPinBank={!isEastSideBlank}
            showWestPinBank={!isWestSideBlank}
            onClick={onClick}
            data-node-target-id={!isPreview ? node.id : ""}
            // TODO: Remove
            className={!isPreview ? "trait-selectable" : ""}
            style={{
                borderTopLeftRadius: isNodeGroupMember ? 0 : undefined,
                borderTopRightRadius: isNodeGroupMember ? 0 : undefined
            }}
        >
            <div className={"foreground-layer"}>
                { (shouldShowHeader) && (
                    <div className={"header"}>
                        <div className={`header-left ${!isPreview ? "handle" : ""}`}>
                            <div className={"header-title"}>
                                <DescriptiveTypography
                                    text={`${node.config.label}`}
                                    noSelect
                                    baseProps={{
                                        className: "node-title",
                                        onClick: e => {
                                            e.stopPropagation();
                                            const nodeId = node.id;
                                            const isPrimeSelected = backend.isNodePrimeSelected(nodeId);
                                            backend.setPrimeSelectedNode(isPrimeSelected ? undefined : nodeId);
                                        }
                                    }}
                                />

                                { (node.config.visualConfig?.labelAppendix !== undefined) && (
                                    <div className={"node-title-appendix-container"}>

                                        <div className={"node-title-appendix-separator"}>
                                            <Dot/>
                                        </div>

                                        <DescriptiveTypography
                                            text={`${node.config.visualConfig!.labelAppendix}`}
                                            noSelect
                                            baseProps={{
                                                className: "node-title-appendix",
                                                onClick: e => {
                                                    e.stopPropagation();
                                                    const nodeId = node.id;
                                                    const isPrimeSelected = backend.isNodePrimeSelected(nodeId);
                                                    backend.setPrimeSelectedNode(isPrimeSelected ? undefined : nodeId);
                                                }
                                            }}
                                        />
                                    </div>
                                ) }


                            </div>

                            { node.config.description && (
                                <div style={{
                                    overflow: "hidden",
                                    height: 20,
                                    maxHeight: 20,
                                    maxWidth: "calc(100% - 0px)",
                                    marginTop: 2
                                }}>
                                    <DescriptiveTypography
                                        text={`${node.config.description}`}
                                        noSelect
                                        style={{
                                            fontSize: 10,
                                            overflow: "hidden",
                                            maxWidth: "calc(100% - 0px)",
                                            textOverflow: "ellipsis"
                                        }}
                                    />
                                </div>
                            ) }
                        </div>
                        <div className={"header-right"}>
                            {/*
                            <Menu opener={
                                <MoreHorizRounded sx={{fontSize: 16, color: t.col("fg_muted"), cursor: "pointer"}}/>
                            }>
                                <MenuButton text={"Delete node"} onSelect={() => {
                                    backend.deleteNode(node.id);
                                }}/>
                            </Menu>
                            */}

                            { !isPreview && (
                                <DragIndicatorRounded className={"handle"} sx={{
                                    fontSize: 16,
                                    color: t.col("fg_muted"),
                                    opacity: .3,
                                    cursor: "drag"
                                }}/>
                            ) }


                        </div>
                    </div>
                ) }

                <div className={"main"}>

                    { !isWestSideBlank && (
                        <div className={"pin-bank left"}>
                            { Array.from(node.pins.defInBank.pins.entries()).map(([key, pin]) => {
                                return (
                                    <NodePinDisplay pin={pin} position={"left"} key={key}/>
                                );
                            }) }

                            {
                                Array
                                    .from(node.pins.pinBanks.entries())
                                    .map(kv => kv[1])
                                    .filter(bank => bank.isInGroup("input") || bank.isGroupless)
                                    .map((bank) => (
                                        <PinBankRenderer
                                            bank={bank}
                                            key={bank.config.label}
                                        />
                                    ))
                            }
                        </div>
                    ) || <div className={"pin-bank left"}/> }

                    <div className={"main-display"} data-has-inner-display={nodeInnerDisplay !== undefined} children={
                        nodeInnerDisplay
                    }/>

                    { !isEastSideBlank && (
                        <div className={"pin-bank right"}>
                            { Array.from(node.pins.defOutBank.pins.entries()).map(([key, pin]) => {
                                return (
                                    <NodePinDisplay pin={pin} position={"right"} key={key}/>
                                );
                            }) }

                            {
                                Array
                                    .from(node.pins.pinBanks.entries())
                                    .map(kv => kv[1])
                                    .filter(bank => bank.isInGroup("output"))
                                    .map((bank) => (
                                        <PinBankRenderer
                                            bank={bank}
                                            align={Alignment.RIGHT}
                                            key={bank.config.label}
                                        />
                                    ))
                            }
                        </div>
                    ) || <div className={"pin-bank right"}/>}
                </div>

                <div className={"footer"}>
                    {/*<Tag tag={`rerender`} onClick={forceRender}/>*/}

                    {/*
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: -8
                    }}>
                        <svg height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <polygon points={"0,20 10,10 20,20"} style={{
                                fill: "transparent",
                                stroke: t.col("fg_muted"),
                                strokeWidth: 1,
                                strokeDasharray: 3,
                                strokeDashoffset: -.75,
                                // additional configuration
                                cursor: "pointer"
                            }}/>
                        </svg>
                    </div>
                    */}
                </div>

                {/*
                <div className={"footer"}>
                    <Tag tag={`add sub node`} onClick={() => {
                        const parameters = new Map<string, any>();
                        const subNode = AutoSizeDisplay.factory(parameters);
                        node._testSubNode = subNode.id;

                        backend.environment.registerNode(subNode);
                    }}/>
                </div>
                */}

                {(() => {
                    const subNodeId = node._testSubNode;
                    if (subNodeId === undefined) return undefined;
                    const subNode = node.env.getNodeByID(subNodeId);
                    if (subNode === undefined) return undefined;

                    return (
                        <NodeDisplay node={subNode}/>
                    );
                })()}
            </div>



            <div className={"background-layer"}/>
        </StyledNodeDisplay>
    );
}
