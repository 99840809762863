import React, {PropsWithRef, useState} from "react";
import styled from "styled-components";
import {Post} from "./models/Post";
import moment from "moment/moment";
import {Badge} from "./Badge";
import {MasonryImageElement} from "./MasonryImageElement";
import {Flex} from "../../../ardspace/arc/common/Flex";
import {BImage} from "./BImage";
import {motion} from "framer-motion";
import {Text} from "../../../triton/components/typography/Text";
import {ImageLoadingErrorAltComponent} from "./ImageLoadingErrorAltComponent";

const StyledPostCard = styled.div`
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  // background-color: #E2E2E4;
  // transition: all .15s linear;
  // transform: scale(1);
  
  margin-bottom: 16px;
  // background-color: white;
  box-shadow: 0 0 0 black;
  
  .post-image {
    aspect-ratio: 1 / 1;
    background-color: #0B0B0E;
  }
  
  .motion-wrapper {
    
  }
  
  img {
    // cursor: pointer;
    // width: 100%;
    object-fit: contain;
    height: auto;
    border-radius: 10px;
    // pointer-events: none;
    -webkit-user-drag: none;
    
    // transition: all .15s linear;
    // &:hover {
    //   transform: scale(1.05);
    // }
  }

  // &:hover {
  //   transform: scale(0.95);
  // }
`;

export type PostCardProps = {
    post: Post,
    onPostSelect?: (e: React.MouseEvent<HTMLDivElement>, post: Post) => void
}

export const PostCard: React.FC<PostCardProps> = props => {
    const post = props.post;
    const imageCreatedXDaysAgo = moment().diff(post.creationTimestamp, 'days');
    const imageGlowUpDuration = 7;
    const isNew = imageCreatedXDaysAgo <= imageGlowUpDuration;
    const renderedBadges: React.ReactNode[] = [];
    if (isNew) renderedBadges.push(<Badge children={"new"}/>);

    const [hasImageLoadingError, setImageLoadingError] = useState(false);

    const onPostSelect = (e: React.MouseEvent<HTMLDivElement>) => props.onPostSelect?.(e, post);

    return (
        <StyledPostCard>

            <motion.div
                // transition={{
                //     type: "tween",
                //     duration: .2
                // }}
                // whileTap={{ scale: 0.9 }}
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{
                    once: true,
                    margin: "0px 0px -50px 0px",
                    // amount: "all"
            }} className={"motion-wrapper"} layoutId={post.id} onClick={onPostSelect}>
                <MasonryImageElement
                    header={<Flex children={renderedBadges}/>}
                    children={
                        hasImageLoadingError ? (<ImageLoadingErrorAltComponent/>) : (
                            <motion.img
                                className={"post-image"}
                                src={`http://192.168.178.20:8080/proto/static/${post.ressourceIDs[0]}.png`}
                                // src={`http://localhost:8080/proto/static/${post.ressourceIDs[0]}.png`}
                                alt={post.ressourceIDs[0]}
                                loading="lazy"
                                onError={e => setImageLoadingError(true)}
                            />
                        )
                    }
                />
                <div className={"post-card-info"}>
                    <motion.h2 children={post.title} style={{
                        margin: 0,
                        fontFamily: "Whitney",
                        fontSize: 13,
                        // fontWeight: "lighter"
                        fontWeight: "normal"
                    }}/>
                </div>
            </motion.div>
        </StyledPostCard>
    );
}
