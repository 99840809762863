import React from "react";
import styled from "styled-components";
import {Link, useNavigate} from "react-router-dom";
import {Text} from "../libs/triton/components/typography/Text";
import {Color} from "../libs/base/logic/style/Color";
import {MenuButton} from "../libs/ardai/components/MenuButton";
import {
    AutoGraphRounded,
    BugReportRounded,
    CommentRounded,
    InputRounded,
    OpenInBrowserRounded, PhoneRounded, SmartphoneRounded,
    SpaceBarRounded, WavesRounded
} from "@mui/icons-material";
import {ListItem} from "../libs/ardai/_/ListItem";
import {Formik} from "formik";
import {FormikSingleLineInput} from "../libs/triton/components/forms/FormikSingleLineInput";
import {ButtonModalCompound} from "../libs/ardai/components/ButtonModalCompound";
import {Modal} from "../libs/triton/components/dialogs/Modal";
import {DefaultButton} from "../libs/triton/components/buttons/DefaultButton";
import {BasicSingleSelect} from "../libs/triton/components/forms/BasicSingleSelect";
import {Tag} from "../libs/ardai/components/Tag";
import {MenuDivider} from "@szhsin/react-menu";
import {FormDivider} from "../libs/triton/components/forms/FormDivider";
import {AppHubPrototypeV2} from "./prototype/AppHubPrototypeV2";
import {CLIMain} from "../libs/ardai/components/cli/CLIMain";
import {BackgroundType} from "../libs/ardai/background/BackgroundType";
import {as} from "../libs/atlas/Lang";
import {BackgroundImageInformation} from "../libs/ardai/background/BackgroundImageInformation";
import {ImageRenderingMode} from "../libs/atlas/hyperion/datatypes/ImageRenderingMode";
import {Background} from "../libs/ardai/background/Background";
import {AppInfo} from "./prototype/AppInfo";
import {BackgroundConfig} from "../libs/ardai/background/BackgroundConfig";
import {BackgroundColorConfig} from "../libs/base/components/props/configs/BackgroundColorConfig";
import {BackgroundSolidInformation} from "../libs/ardai/background/BackgroundSolidInformation";

const StyledAppHub = styled.div`
  // background-color: black;
  color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const appList: Array<AppInfo> = [
    // {
    //     title: "Com. Test Space",
    //     spark: function () {
    //         this.navigate("ai/development/component");
    //     }
    // },
    // {
    //     title: "Ardspace AI",
    //     about: "Ardspace AI is a creative image creation and sorting tool",
    //     spark: function () {
    //         this.navigate("ai");
    //     }
    // },
    {
        title: "Dominion",
        spark: function () {
            this.navigate("dominion");
        }
    },
    {
        title: "Gallery",
        spark: function () {
            this.navigate("ai/gallery");
        }
    },
    // {
    //     title: "Ardspace AI Generate",
    //     spark: function () {
    //         this.navigate("ai/generate");
    //     }
    // },
    // {
    //     title: "AS. Media Mobile",
    //     spark: function () {
    //         this.navigate("boarding");
    //     }
    // },
    // {
    //     title: "Mini",
    //     spark: function () {
    //         this.navigate("b");
    //     }
    // },
    // {
    //     title: "Carbide",
    //     spark: function () {
    //         this.navigate("ai/development");
    //     }
    // },
    // {
    //     title: "Sim A",
    //     icon: <WavesRounded sx={{ fontSize: 20 }}/>,
    //     spark: function () {
    //         this.navigate("sim-a");
    //     }
    // },
    // {
    //     title: "Sim B",
    //     icon: <AutoGraphRounded sx={{ fontSize: 20 }}/>,
    //     spark: function () {
    //         this.navigate("sim-b");
    //     }
    // },
    {
        title: "Sim C",
        icon: <AutoGraphRounded sx={{ fontSize: 20 }}/>,
        spark: function () {
            this.navigate("sim-c");
        }
    },
]

const debugBackgroundMode: "img" | "color" = "color";

const imgBackgroundConfig: BackgroundConfig<BackgroundImageInformation> = {
    type: BackgroundType.IMAGE,
    data: as<BackgroundImageInformation>({
        opacity: .2,
        blur: 0,
        srcType: "url",
        renderingMode: ImageRenderingMode.AUTO,
        srcLink: "https://mdm-watches.com/wp-content/uploads/2023/03/ezgif.com-video-to-gif.gif"
    })
}

const colorBackgroundConfig: BackgroundConfig<BackgroundSolidInformation> = {
    type: BackgroundType.SOLID,
    data: as<BackgroundSolidInformation>({
        color: Color.black.css(),
    })
}

/**
 * <ListItem title={"Ardspace Component suite"} onClick={() => navigate("/ai/development/component")} icon={
 *     <CommentRounded sx={{ fontSize: 20 }}/>
 * }/>
 *
 * @param props
 * @constructor
 */
export const AppHub: React.FC = props => {
    const backgroundConfig: BackgroundConfig = debugBackgroundMode === "color" ? colorBackgroundConfig : imgBackgroundConfig;
    return (
        <StyledAppHub>
            <AppHubPrototypeV2 apps={appList}/>
            <CLIMain/>
            <Background config={backgroundConfig}/>
        </StyledAppHub>
    );
}
