import {FQExtend, NodeId, PinId} from "./serialization/FullyQualified";
import {FQNodeId} from "./FQNodeId";
import {v4} from "uuid";

export type FQBankId = FQExtend<FQNodeId, {
    bank: string,
    bankLabel?: string
}>

export const mkFQBankId = (node: NodeId = v4(), bank: string = v4(), bankLabel: string | undefined = undefined): FQBankId => ({
    node, bank, bankLabel
});
