import {FC} from "react";
import {ReactComponent as Logo} from "../assets/logo.svg";
import styled from "styled-components";

const StyledNodeFrontendBrandingWatermarkLayer = styled.div`
  position: relative;
  touch-action: none;
  width: 100%;
  height: 100%;
  // background-color: crimson;
  
  svg {
    opacity: .15;
    // fill: white !important;
    // color: white !important;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: min-content;
    max-height: 35%;
    max-width: 35%;
    transform-origin: center;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export const NodeFrontendBrandingWatermarkLayer: FC = props => {
    return (
        <StyledNodeFrontendBrandingWatermarkLayer>
            <Logo  />
        </StyledNodeFrontendBrandingWatermarkLayer>
    );
}
