import React from "react";
import styled from "styled-components";
import {useStaticState} from "../../hooks/StaticStateHook";
import {v4} from "uuid";
import {CodeEditor} from "../../../base/components/base/CodeEditor";
import {SDPromptField} from "../../../imageSorter/sdInterface/SDPromptField";
import {px} from "../../../base/logic/style/DimensionalMeasured";
import {AdvancedStringInputProcessor} from "./AdvancedStringInputProcessor";
import {AdvancedStringInputProcessorResult} from "./AdvancedStringInputProcessorResult";
import ReactJson from "react-json-view";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {ButtonGroup} from "../ButtonGroup";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {Tag} from "../Tag";
import {MenuDivider} from "@szhsin/react-menu";
import {FormDivider} from "../../../triton/components/forms/FormDivider";
import {ChevronRightRounded, ExpandCircleDownRounded} from "@mui/icons-material";

export class AdvancedStringInputGeneralContext {
    public constructor(
        public readonly src: string
    ) {}
}

export type AdvancedStringInputProps = {
    initialSrc?: string,
    onSubmit?: () => void,
    autoFocus?: boolean,
    onChangeRaw?: (this: AdvancedStringInputGeneralContext, src: string) => void,
    onChangeComputed?: (this: AdvancedStringInputGeneralContext, result: AdvancedStringInputProcessorResult) => void,
    persistentConfig?: {
        id: string
    }
}

export type AdvancedStringInputState = {
    src: string,
    outputExpanded: boolean,
    latestProcessorResult?: AdvancedStringInputProcessorResult
}

const StyledAdvancedStringInput = styled.div`

`;

export const AdvancedStringInput: React.FC<AdvancedStringInputProps> = props => {
    const persistentConfig = props.persistentConfig;
    const id = persistentConfig?.id ?? v4();
    const [state, ctx] = useStaticState<AdvancedStringInputState>({
        id: `AdvancedStringInput-${id}`,
        staticMode: persistentConfig !== undefined,
        initial: {
            src: props.initialSrc ?? "",
            outputExpanded: false
        }
    }).stateWithCtx;

    const onSrcChange = (src: string) => {
        props.onChangeRaw?.call(new AdvancedStringInputGeneralContext(src), src);
        const processorResult = new AdvancedStringInputProcessor().process({
            src: src
        });
        ctx.update({
            src: src,
            latestProcessorResult: processorResult
        });
        props.onChangeComputed?.call(new AdvancedStringInputGeneralContext(src), processorResult);
    };

    return (
        <StyledAdvancedStringInput>
            <SDPromptField
                value={state.src}
                h={px(52)}
                // autoFocus={props.autoFocus ?? false}
                onChange={src => onSrcChange(src ?? "")}
            />

            <TransitionGroup>
                { state.latestProcessorResult && (
                    <Collapse>
                        <div style={{
                            maxHeight: 150,
                            overflowY: "scroll",
                            marginTop: 8
                        }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: 8
                            }}>
                                <FormDivider title={"Output"}/>

                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 4
                                }}>
                                    <Tag
                                        highlightOnHover={false}
                                        tag={`${state.latestProcessorResult.values.length} variants`}
                                    />

                                    <Tag
                                        onClick={() => ctx.reverseBool("outputExpanded")}
                                        tag={
                                            <ChevronRightRounded style={{
                                                transition: "all 200ms",
                                                fontSize: 16,
                                                transform: `rotate(${state.outputExpanded ? 90 : -90}deg)`
                                            }}/>
                                        }
                                    />
                                </div>
                            </div>


                            { state.latestProcessorResult.values.slice(0, state.outputExpanded ? 10 : 1).map(variant => (
                                <DescriptiveTypography key={variant} text={variant}/>
                            )) }
                        </div>
                    </Collapse>
                ) || (
                    <DescriptiveTypography text={"No processor result"}/>
                ) }
            </TransitionGroup>

        </StyledAdvancedStringInput>
    );
}
