import {UpdateFieldFunc} from "./UpdateFieldFunc";
import {DType} from "./DType";
import {FieldRenderFunc} from "./FieldRenderFunc";
import {NumberField} from "./NumberField";
import React from "react";
import {re} from "mathjs";
import {StringField} from "./StringField";
import {BooleanField} from "./BooleanField";
import {ColorField} from "./ColorField";

export class FieldRenderer {

    private readonly updateFieldFunc: UpdateFieldFunc;

    private fieldRenderFuncMap: Map<DType, FieldRenderFunc> = new Map<DType, FieldRenderFunc>();

    constructor(updateFieldFunc: UpdateFieldFunc) {
        this.updateFieldFunc = updateFieldFunc;
        this.initNumberFieldRenderer();
        this.initStringFieldRenderer();
        this.initBooleanFieldRenderer();
        this.initColorFieldRenderer();
    }

    private initNumberFieldRenderer() {
        this.fieldRenderFuncMap.set(DType.NUMBER, (fiendName, value, postUpdate) => (
            <NumberField name={fiendName} data={value} onChange={data => postUpdate(data)}/>
        ));
    }

    private initStringFieldRenderer() {
        this.fieldRenderFuncMap.set(DType.STRING, (fiendName, value, postUpdate) => (
            <StringField name={fiendName} data={value} onChange={data => postUpdate(data)}/>
        ));
    }

    private initBooleanFieldRenderer() {
        this.fieldRenderFuncMap.set(DType.BOOLEAN, (fiendName, value, postUpdate) => (
            <BooleanField name={fiendName} data={value} onChange={data => postUpdate(data)}/>
        ));
    }

    private initColorFieldRenderer() {
        this.fieldRenderFuncMap.set(DType.COLOR, (fiendName, value, postUpdate) => (
            <ColorField name={fiendName} data={value} onChange={data => postUpdate(data)}/>
        ));
    }

    public renderField(fieldName: string, dt: DType, value: any): JSX.Element {
        const renderer = this.fieldRenderFuncMap.get(dt);
        if (renderer === undefined) return (<>no renderer</>);
        return renderer(fieldName, value, newValue => this.updateFieldFunc(fieldName, newValue));
    }
}
