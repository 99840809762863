import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../webapi/WebAPIDB";
import {VFSViewSettings} from "../settings/VFSViewSettings";
import {useContext} from "react";
import {ArdaiAPIContext} from "../webapi/WebAPI";

export function useSettings<T = any>(key: string, def: T | undefined = undefined) {
    return useLiveQuery(() => {
        return webDB.settings.get(key)
            .then(val => val?.value)
            .then(val => val === undefined ? def : JSON.parse(val!) as T)
    });
}

export function useAutoSettings<T = any>(key: string, def: T | undefined = undefined, autoInit: boolean = true) {
    const api = useContext(ArdaiAPIContext);
    return useLiveQuery(() => {
        return webDB.settings.get(key)
            .then(val => {
                console.debug(`[useAutoSettings] fetched "${key}". Value is:`, val);
                return val;
            })
            .then(val => val?.value)
            .then(val => val === undefined ? (() => {
                console.debug(`[useAutoSettings] "${key}" is undefined, initiating settings object to:`, def)
                if (autoInit) api.settingsManager.initSettingsObject<T>(key, def!);
                return def;
            })() : JSON.parse(val!) as T)
    });
}
