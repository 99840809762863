import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {PinMode} from "../../../backend/Pin";

export const OutputPortConfig: NodeSetupInfo = {
    label: "Output port config",
    classname: "node.output-configuration",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "node.output-configuration",
        // visualConfig: {
        //     dye: triton().col("color_primary")
        // },
        label: "out",
        init: function () {
            this.pins.createPinBank("parameter config", {
                label: "parameter config",
                // groups: ["output"],
                defaultPinMode: PinMode.IN,
                dynamicSizeFlag: true,
                visualConfig: {
                    binBankRendererResourceName: "manual-port-configuration"
                }
            });
        }
    })
}
