import React from "react";
import {F} from "../../Simulation2Main";
import styled from "styled-components";
import {StyledControlDisplayGroup} from "../../components/StyledControlDisplayGroup";
import {Speedometer} from "../../../../test/engine/Speedometer";
import {Orientation} from "../../../../base/logic/style/Orientation";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {ButtonBase} from "../../../../triton/components/buttons/ButtonBase";
import {Interpolate} from "../../../../triton/components/advanced/Interpolate";
import {px} from "../../../../base/logic/style/DimensionalMeasured";
import {Entity} from "../../ecs/entity/Entity";

const StyledEngineMasterControlDisplay = styled.div`
  display: grid;
  
  .engine-master-controls-wrapper {
    // grid-template-columns: repeat(2, 60px);
    // grid-template-columns: min-content 60px;
    
    
    .engine-master-controls {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      // grid-template-columns: repeat(1, 1fr);
      height: 100%;
      gap: 4px;
    }
  }
`;

export type EngineMasterControlDisplayProps = {
    entity: Entity
}

export const EngineMasterControlDisplay: React.FC<EngineMasterControlDisplayProps> = props => {
    const engine = props.entity;
    const s = engine?.state ?? {};
    // Get engine variables
    const ksu = s.ksu ?? 0;
    const fuelBufferTank = s[F] ?? 0;


    // Engine master orders
    const orderAllEnginesOff = () => engine.getChildrenFromGroup("cores").forEach(c => c.setStatePartially({ active: false }));
    const orderAllEnginesOn = () => engine.getChildrenFromGroup("cores").forEach(c => c.setStatePartially({ active: true }));

    return (
        <StyledEngineMasterControlDisplay>
            <StyledControlDisplayGroup className={"engine-master-controls-wrapper"}>
                {/*
                <div style={{ height: 60 }} children={
                    <Speedometer
                        orientation={Orientation.VERTICAL}
                        displayWarnings={false}
                        val={ksu}
                    />
                }/>
                */}

                <div className={"engine-master-controls"}>
                    <ButtonBase children={<DescriptiveTypography text={"OFF"}/>} baseProps={{
                        onClick: () => orderAllEnginesOff(),
                        // style: { padding: 0, aspectRatio: "1 / 1"}
                    }}/>

                    <ButtonBase children={<DescriptiveTypography text={"ON"}/>} baseProps={{
                        onClick: () => orderAllEnginesOn(),
                        // style: { padding: 0, aspectRatio: "1 / 1"}
                    }}/>

                    <Speedometer
                        orientation={Orientation.HORIZONTAL}
                        displayWarnings={false}
                        val={fuelBufferTank}
                        cap={1e3}
                        width={px(10)}
                        pure
                    />

                    <Interpolate value={fuelBufferTank} appendix={"L"}/>
                </div>
            </StyledControlDisplayGroup>
        </StyledEngineMasterControlDisplay>
    );
}
