import React from "react";
import styled from "styled-components";

const StyledPropsDeclarationPrefix = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  margin: 0;
  white-space: nowrap;
  font-family: Consolas, "Courier New", monospace;
  font-size: 13px;
  user-select: none;

  .prefix-name {
    color: #FFEF9B;
  }

  .prefix-type {
    color: #FFFBE6;
    user-select: none;
  }
`;

export type PropsDeclarationPrefixProps = {
    name: string,
    type: string
}

export const PropsDeclarationPrefix: React.FC<PropsDeclarationPrefixProps> = props => {
    return (
        <StyledPropsDeclarationPrefix>
            <span className={"prefix-name"} children={props.name}/>
            :&nbsp;
            <span className={"prefix-type"} children={props.type}/>
            &nbsp;=
        </StyledPropsDeclarationPrefix>
    );
}
