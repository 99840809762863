import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const NodeArrayAccessor: NodeSetupInfo = {
    label: "NodeArrayAccessor",
    classname: "objects.array-accessor",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "objects.array-accessor",
        label: "arr accessor",
        defOutPins: ["e"],
        defInPins: ["arr", "idx"],
        init: function () {
            this.createInternalWire({
                targetInPin: "arr",
                targetOutPin: "e",
                transformer: arr => {
                    let idx = this.pins.in("idx").lastReadState;
                    idx = Number(idx);
                    return arr[idx];
                }
            })
        }
    })
}
