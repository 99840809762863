import {FC, useState} from "react";
import styled from "styled-components";
import {MainTypography} from "../../../../triton/components/typography/MainTypography";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";

import {ReactComponent as TUDLogo} from "../../assets/connections/tu-da-logo-2023.svg";
import {ReactComponent as GILogo} from "../../assets/connections/gi-logo-2023.svg";

// noinspection SpellCheckingInspection
import {ReactComponent as FeuerroseText} from "../../assets/feuerrose.svg";

import {PrimaryDisplayTypography} from "../typography/PrimaryDisplayTypography";
import {SocialsIconList} from "../SocialsIconList";
import {usePortfolioBackend} from "../../UsePortfolioBackendHook";
import {MainPersonQuickInfoSection} from "../sections/MainPersonQuickInfoSection";
import {Section} from "../sections/Section";
import {LinkDisplayTypography} from "../typography/LinkDisplayTypography";
import {Color} from "../../../../base/logic/style/Color";
import {SectionTray} from "../sections/SectionTray";
import {TypeAnimation} from 'react-type-animation';
import {useTriton} from "../../../../triton/TritonHooks";
import {Triton} from "../../../../triton/Triton";
import {MainFooterSection} from "../sections/MainFooterSection";
import {NodeCanvas} from "../../../../node/frontend/NodeCanvas";

import {ReactComponent as Planetary1} from "../../assets/planetary-1.svg";
import NodeSandboxThumbnail from "../../assets/images/abstract/node-sandbox-thumbnail-1.png";
import ASampleImg from "../../assets/images/abstract/Screenshot_16.png";
import {MainNavigationHeader} from "../navigation/header/MainNavigationHeader";

const StyledLandingPage = styled.div<{
    t: Triton
}>`
  background-color: ${p => p.t.col("bg_main")};
  
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  
  .landing-page-main-section {
    // background-color: crimson;
    
    height: 100vh;
    width: 100vw;
    flex-shrink: 0;
    position: relative;
    
    .header-container {
      z-index: 100;
      opacity: 1;

      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transform-origin: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
      scroll-snap-align: start;

      * {
        font-family: 'DM Serif Text', serif;
        font-weight: lighter;
        font-size: 48px;
        white-space: nowrap;
        line-height: 65px;
      }
      
      .header-planetary-decoration-wrapper {
        width: 0;
        height: 0;
        position: relative;
        
        svg {
          width: 65px;
          aspect-ratio: 1 / 1;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      
      .what-am-i-section {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
    
    .complications-container {
      position: relative;
      padding: 100px;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: min-content auto min-content;
      
      .north-complications {
        width: 100%;
        display: grid;
        grid-template-columns: min-content auto min-content;
        
        .who-am-i-section {
          display: flex;
          align-items: center;
          gap: 8px;
          
          * {
            font-family: Nebula, "JetBrains Mono", monospace;
            font-size: 12px;
            white-space: nowrap;
            color: ${p => p.t.col("fg_display_secondary")};
          }
          
          .line {
            height: 1px;
            width: 50px;
            background-color: ${p => p.t.col("fg_display_secondary")};
          }
        }
      }
      
      .south-complications {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        gap: 20px;
        align-items: end;
        
        .showcase-main-card {
          transition: all .1s;
          
          &:hover {
            scale: 1.02;
          }
        }
        
        .language-hints {
          width: min-content;
          display: flex;
          align-items: center;
          gap: 50px;

          * {
            --font-size: 24px;
            font-family: 'DM Serif Text', serif;
            font-weight: lighter;
            white-space: nowrap;
            font-size: var(--font-size);
            line-height: var(--font-size);
            // color: white;
          }
        }
        
        .connections-wrapper {
          display: flex;
          flex-direction: row;
          gap: 45px;
          
          .connection-logo {
            svg {
              color: white;
              height: 60px;
            }
          }
        }
      }
    }
  }
`;

export const LandingPage: FC = props => {
    const portfolio = usePortfolioBackend();
    const t = useTriton();

    const [debugState, setDebugState] = useState({
        showNodeEditorPlayground: false
    });

    // noinspection SpellCheckingInspection
    return (
        <StyledLandingPage t={t}>
            <SectionTray enableSnapping={false}>
                <MainNavigationHeader/>

                <div className={"landing-page-main-section"}>
                    <div className={"header-container"}>

                        <div className={"header-planetary-decoration-wrapper"}>
                            <Planetary1 onClick={() => setDebugState(prevState => ({
                                ...prevState,
                                showNodeEditorPlayground: !prevState.showNodeEditorPlayground
                            }))}/>
                        </div>

                        <PrimaryDisplayTypography text={"Christian Bernstein"}/>
                        <div className={"what-am-i-section"}>
                            <PrimaryDisplayTypography className={"main-typographic-header"} sx={{
                                ["& > u"]: {
                                    textDecorationThickness: 2,
                                    textDecorationSkip: "edges",
                                    textDecorationStyle: "solid",
                                    color: t.col("fg_display_primary")
                                }
                            }}>
                                <u>Software Engineer</u> / <u>
                                    <TypeAnimation
                                        cursor
                                        preRenderFirstString
                                        repeat={Infinity}
                                        speed={{ type: 'keyStrokeDelayInMs', value: 200 }}
                                        deletionSpeed={{ type: 'keyStrokeDelayInMs', value: 75 }}
                                        sequence={[
                                            "UI Designer", 7.5e3,
                                            "Art enthusiast", 7.5e3,
                                        ]}
                                    />
                                </u>
                            </PrimaryDisplayTypography>


                        </div>
                        <PrimaryDisplayTypography text={"Based in Germany"}/>


                        <LinkDisplayTypography
                            text={"Visit my linkedin"}
                            highlightColor={Color.white.rgba}
                            s={{
                                marginTop: 50,
                                textTransform: "uppercase"
                            }}
                        />
                    </div>
                    <div className={"complications-container"}>
                        <div className={"north-complications"}>

                            <SocialsIconList for={portfolio.state.person}/>

                            <span/>

                            <div className={"who-am-i-section"}>
                                {/* Replacement text: Sol III */}
                                <DescriptiveTypography noSelect text={"Christian Bernstein / 2003-2024 / Germany"}/>
                                <span className={"line"}/>
                            </div>
                        </div>
                        <span style={{
                            pointerEvents: "none"
                        }}/>
                        <div className={"south-complications"}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 40
                            }}>
                                <div className={"showcase-main-card"} style={{
                                    width: 300,
                                    height: 150,
                                    borderRadius: 23,
                                    // background: "linear-gradient(180deg, #ffffff 0%, rgba(160, 209, 220, 0) 100%)",
                                    position: "relative",
                                    overflow: "hidden",
                                    cursor: "pointer"
                                    // opacity: .17,
                                }} onClick={() => {
                                    window.location.pathname = "nodes/";
                                }}>
                                    <img src={NodeSandboxThumbnail} alt={"highlight card thumbnail"} style={{
                                        width: "100%",
                                        height: "calc(100% + 25px)",
                                        objectFit: "cover",
                                        objectPosition: "top",
                                        position: "absolute",
                                        top: -25,
                                        left: 0,
                                        userSelect: "none"
                                    }}/>
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        display: "grid",
                                        gridTemplateRows: "auto min-content",
                                        background: "linear-gradient(180deg, #00000000 0%, #000000c0 100%)",
                                        padding: 16
                                    }}>
                                        <span/>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 2
                                        }}>
                                            <PrimaryDisplayTypography fontSize={22} text={"Node editor sandbox"} s={{
                                                lineHeight: "22px"
                                            }}/>
                                            <MainTypography noSelect text={"Try out my latest project"}/>
                                        </div>
                                    </div>
                                </div>

                                <div className={"language-hints"}>
                                    { ["de", "en"].map(lang => (
                                        <PrimaryDisplayTypography
                                            fontSize={22}
                                            text={lang.toUpperCase()}
                                        />
                                    )) }
                                </div>
                            </div>

                            <div className={"connections-wrapper"}>
                                {/*
                                <div className={"connection-logo"}>
                                    <GILogo style={{
                                        // TODO: There needs to be a solution.. -> text not visible
                                        // filter: "brightness(1.5)",
                                    }}/>
                                </div>
                                */}

                                <div className={"connection-logo"}>
                                    <TUDLogo style={{
                                        color: t.col("fg_display_primary")
                                    }}/>
                                </div>
                            </div>

                            <div>
                                <PrimaryDisplayTypography fontSize={22} text={<>
                                    7149-bernie ("Bernie"),{"\n"}
                                    Dr. Hans-Heinrich Bernstein{"\n"}
                                    (born 1953), German astronomer{"\n"}
                                    at ARI in Heidelberg
                                </>} sx={{
                                    textAlign: "right",
                                    whiteSpace: "pre-line !important",
                                    backgroundClip: "text",
                                    color: "transparent !important",
                                    backgroundImage: `linear-gradient(180deg, ${t.col("fg_display_secondary")} 0%, rgba(160, 209, 220, 0.2) 100%)`,
                                    WebkitBackgroundClip: "text"
                                }}/>
                            </div>
                        </div>

                        {/* TODO: Remove */}
                        <div children={<FeuerroseText style={{
                            width: "100%",
                            objectFit: "contain"
                        }}/>} style={{
                            // backgroundColor: "red",
                            opacity: 0,

                            position: "absolute",
                            padding: "0 100px",
                            overflow: "hidden",
                            zIndex: -1,
                            top: "50%",
                            left: "50%",
                            transform: "translateX(-50%) translateY(-50%)",
                            width: "100%"
                        }}/>
                    </div>
                </div>

                <Section>
                    <MainPersonQuickInfoSection/>
                </Section>

                { debugState.showNodeEditorPlayground && (
                    <Section dense={false} snap={false}>
                        <div style={{
                            position: "relative",
                            width: "100%",
                            height: "100vh",
                            display: "grid",
                            gridTemplateRows: "min-content auto",
                            gap: 16,
                            padding: "32px 0"
                        }}>
                            <div>
                                <PrimaryDisplayTypography text={"Node editor sandbox"}/>
                            </div>

                            <DescriptiveTypography text={"this is intentional... sry... cannot render NodeCanvas right now -> head over to sub site: 'nodes/'"}/>
                            {/*
                            <NodeCanvas nodes={[]}/>
                            */}

                        </div>
                    </Section>
                ) }




                <Section>
                    <MainTypography
                        text={"// THE END //"}
                        noSelect
                        style={{
                            display: "grid",
                            width: "100%",
                            textAlign: "center",
                            fontFamily: "Rubik Mono One"
                        }}
                    />
                </Section>

                <Section>
                    <MainFooterSection/>
                </Section>
            </SectionTray>
        </StyledLandingPage>
    );
}
