import React from "react";
import {StyledControlDisplayGroup} from "../../components/StyledControlDisplayGroup";
import {ValveIcon} from "./ValveIcon";
import {ButtonBase} from "../../../../triton/components/buttons/ButtonBase";
import {Interpolate} from "../../../../triton/components/advanced/Interpolate";
import {clamp} from "lodash";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import styled from "styled-components";
import {PercentRounded, SettingsBackupRestore} from "@mui/icons-material";
import {Entity} from "../../ecs/entity/Entity";

const StyledValveControlDisplay = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: auto min-content;
  
  .valve-controls {
    display: grid;
    gap: 4px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export type ValveControlDisplayProps = {
    entity: Entity
}

export const ValveControlDisplay: React.FC<ValveControlDisplayProps> = props => {
    const setValveOpenPercentage = (percent: number, isDelta: boolean = false) => {
        props.entity.setState(prevState => ({
            ...prevState,
            throughput: clamp((isDelta ? prevState.throughput ?? 0 : 0) + percent, 0, 100)
        }));
    }

    return (
        <StyledValveControlDisplay>
            <StyledControlDisplayGroup style={{ gridTemplateColumns: "repeat(2, 60px)", }}>
                <StyledControlDisplayGroup style={{ gridTemplateColumns: "repeat(2, 1fr)", }}>
                    <PercentRounded sx={{
                        color: "rgba(201, 209, 217, 0.1)",
                        transition: "color 1s",
                        fontSize: 16
                    }}/>
                    <Interpolate value={props.entity?.state?.throughput ?? 0}/>
                </StyledControlDisplayGroup>

                <ValveIcon entity={props.entity}/>
            </StyledControlDisplayGroup>

            <StyledControlDisplayGroup style={{ padding: 4 }}>
                <div className={"valve-controls"}>
                    <ButtonBase children={<DescriptiveTypography text={"- 10"}/>} baseProps={{
                        onClick: () => setValveOpenPercentage(-10, true),
                        style: { padding: 0, aspectRatio: "1 / 1"}
                    }}/>

                    <ButtonBase children={<DescriptiveTypography text={"+ 10"}/>} baseProps={{
                        onClick: () => setValveOpenPercentage(10, true),
                        style: { padding: 0, aspectRatio: "1 / 1"}
                    }}/>

                    <ButtonBase children={<DescriptiveTypography text={0}/>} baseProps={{
                        onClick: () => setValveOpenPercentage(0),
                        style: { padding: 0, aspectRatio: "1 / 1"}
                    }}/>

                    <ButtonBase children={<DescriptiveTypography text={100}/>} baseProps={{
                        onClick: () => setValveOpenPercentage(100),
                        style: { padding: 0, aspectRatio: "1 / 1"}
                    }}/>
                </div>
            </StyledControlDisplayGroup>
        </StyledValveControlDisplay>
    );
}
