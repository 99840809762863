import React, {FC} from "react";
import styled from "styled-components";
import {TextWithDescriptiveHeader} from "../TextWithDescriptiveHeader";
import {PrimaryDisplayTypography} from "../typography/PrimaryDisplayTypography";
import {Alignment} from "../../../../node/frontend/Alignment";
import {LandingPageFocalPointContent} from "../LandingPageFocalPointContent";
import {SecondaryTextTypography} from "../typography/SecondaryTextTypography";

const StyledMainPersonQuickInfoSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 50% 1fr;
  grid-column-gap: 100px;
  
  .main-panel {    
    width: 100%;
    padding: 0 10%;
  }
  
  .info-panel {
    height: 100%;
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    
    .right {
      justify-content: end;
      text-align: end;
    }
  }
`;

export const MainPersonQuickInfoSection: FC = props => {
    // noinspection SpellCheckingInspection
    return (
        <StyledMainPersonQuickInfoSection>
            <div className={"info-panel left"}>
                <TextWithDescriptiveHeader subtitle={"About me"} children={
                    <PrimaryDisplayTypography s={{
                        whiteSpace: "normal",
                        marginTop: 10
                    }} fontSize={20} text={`
                        My passion is 
                        designing and developing 
                        frontend and backend 
                        applications  
                    `}/>
                }/>

                <TextWithDescriptiveHeader subtitle={"Current"} children={
                    <PrimaryDisplayTypography s={{
                        whiteSpace: "normal",
                        marginTop: 10
                    }} fontSize={20} text={`
                        Studying Computer Science at 
                        Technische Universität Darmstadt
                    `}/>
                }/>

                <TextWithDescriptiveHeader subtitle={"Contact"} children={
                    <PrimaryDisplayTypography s={{
                        whiteSpace: "nowrap",
                        marginTop: 10
                    }} fontSize={20} text={`
                        info@christian-bernstein.de
                    `}/>
                }/>
            </div>
            <div className={"main-panel"}>
                <LandingPageFocalPointContent/>
            </div>
            <div className={"info-panel right"}>
                <TextWithDescriptiveHeader align={Alignment.RIGHT} subtitle={"Experience"} children={
                    <PrimaryDisplayTypography s={{
                        whiteSpace: "normal",
                        marginTop: 10
                    }} text={`
                        5+
                    `}/>
                }/>

                <TextWithDescriptiveHeader align={Alignment.RIGHT} subtitle={"Github commits"}>
                    <PrimaryDisplayTypography s={{
                        whiteSpace: "normal",
                        marginTop: 10
                    }} text={`
                        3,526+
                    `}/>
                    <SecondaryTextTypography s={{
                        lineHeight: "normal"
                    }} fontSize={12} text={"Member since 2019"}/>
                </TextWithDescriptiveHeader>

                <TextWithDescriptiveHeader align={Alignment.RIGHT} subtitle={"Projects"} children={
                    <PrimaryDisplayTypography s={{
                        whiteSpace: "normal",
                        marginTop: 10
                    }} text={`
                        48
                    `}/>
                }/>
            </div>
        </StyledMainPersonQuickInfoSection>
    );
}
