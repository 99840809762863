import React, {useContext, useState} from "react";
import {ArdaiAPIContext} from "../../webapi/WebAPI";
import {ArdaiAPIStateContext} from "../../ArdaiMain";
import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../../webapi/WebAPIDB";
import styled from "styled-components";
import {FormikSingleLineInput} from "../../../triton/components/forms/FormikSingleLineInput";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import ReactJson from "react-json-view";
import {as} from "../../../atlas/Lang";
import {SearchContext} from "../../search/SearchContext";
import {SearchEngine} from "../../search/SearchEngine";
import {Formik} from "formik";
import {SearchResult} from "../../search/SearchResult";
import {ButtonBase} from "../../../triton/components/buttons/ButtonBase";
import {FolderRounded} from "@mui/icons-material";
import {ListItem} from "../../_/ListItem";

const StyledSearchTrayTab = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: min-content auto;
  gap: 8px;
  
  .search-tab-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
  
  .search-tab-body {
    overflow-x: visible;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
`;

export type SearchTrayTabState = {
    search: string,
    res?: SearchResult
}

export const SearchTrayTab: React.FC = props => {
    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);
    const project = useLiveQuery(() => webDB.projects.get(state.selectedProject ?? ""));

    const [ls, setLs] = useState<SearchTrayTabState>({
        search: ""
    });

    const search = async () => {
        setLs(prevState => ({ ...prevState, res: undefined }));
        if (ls.search.trim().length === 0) return;
        const search = ls.search;
        let ctx = as<SearchContext>({
            input: "",
            filters: {}
        });
        search.split(/;/).forEach(cmdlet => {
            if (/.*:.*/.test(cmdlet)) {
                // This is a key-value pair
                const [k, v] = cmdlet.split(/:/);
                ctx.filters[k] = v;
            } else {
                // This is a regular input shard
                ctx.input += ` ${cmdlet}`;
            }
        });
        ctx.input = ctx.input.trim();
        const res = await new SearchEngine().search(ctx);
        setLs(prevState => ({
            ...prevState,
            res: res
        }));
    };

    return (
        <StyledSearchTrayTab>
            <div className={"search-tab-header"}>
                <Formik initialValues={{ s: ls.search }} onSubmit={v => {}} validate={v => setLs(prevState => ({ ...prevState, search: v.s }))} children={fp => (
                    <FormikSingleLineInput name={"s"} formikProps={fp}/>
                )}/>
                <ButtonBase text={"Search"} baseProps={{
                    onClick: async () => search()
                }}/>
            </div>

            <div className={"search-tab-body"}>

                <TransitionGroup children={ ls.res && (
                    <Collapse children={
                        <div style={{
                            display: "grid",
                            gap: "2px",
                            height: "min-content"
                        }}>
                            {
                                ls.res?.projects?.map(p => (
                                    <ListItem
                                        title={p.title}
                                        icon={<FolderRounded sx={{ fontSize: 20 }}/>}
                                        onClick={() => api.selectProject(p.id)}
                                        active={state.selectedProject === p.id}
                                        // activeAppendix={generateFolderListItemAppendix(f)}
                                        // hoverAppendix={generateFolderListItemAppendix(f)}
                                    />
                                ))
                            }
                        </div>
                    }/>
                ) }/>

                <TransitionGroup children={ ls.res && (
                    <Collapse children={
                        <ReactJson enableClipboard={false} theme={"grayscale"} src={ls.res ?? {}}/>
                    }/>
                ) }/>


            </div>
        </StyledSearchTrayTab>
    );
}
