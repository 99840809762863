import {IToCSSConvertable} from "./IToCSSConvertable";
import {as} from "../../../atlas/Lang";
import {BasicColorPalette} from "../../../triton/colors/BasicColorPalette";
import {TritonColorNamespace} from "../../../triton/colors/TritonColorNamespace";
import {RGBValue} from "../../../utils/color/RGBValue";

export class Color implements IToCSSConvertable {

    public static readonly black = Color.ofHex("#000000");

    public static readonly white = Color.ofHex("#ffffff");

    public static readonly transparent = Color.ofHex("#ffffff", 0);

    public static readonly palettes = {

        /**
         * Triton color system
         */
        triton: as<BasicColorPalette<TritonColorNamespace>>({
            purple: ofHex("#5028c8")
        }),

        /**
         * X11-like
         * color name overview: https://en.wikipedia.org/wiki/X11_color_names
         */
        x11Like: as<BasicColorPalette<"crimson">>({
            crimson: ofHex("#ed143d")
        })
    }

    private _r: number;

    private _g: number;

    private _b: number;

    private _alpha: number;

    constructor(r: number, g: number, b: number, alpha: number = 1) {
        this._r = r;
        this._g = g;
        this._b = b;
        this._alpha = alpha;
    }

    public toHex(prefix: string = '#', useAlpha: boolean = true): string {
        const alpha8Bit = this.alpha * 255;
        return prefix + ((1 << 24) + (this._r << 16) + (this._g << 8) + this._b).toString(16).substr(1) + (
            useAlpha ? alpha8Bit.toString(16).padStart(2, '0') : ''
        );
    }

    public get hex(): string {
        return this.toHex("#", false);
    }

    public get hexWithAlpha(): string {
        return this.toHex("#", true);
    }

    public get rgba(): string {
        return this.css(false)
    }

    public css(asCSSValue?: boolean): string {
        const css = `rgba(${this.r}, ${this.g}, ${this.b}, ${this.alpha})`;
        if (asCSSValue) {
            return `"${css}"`
        } else {
            return css;
        }
    }

    public copy(): Color {
        return new Color(
            this.r,
            this.g,
            this.b,
            this.alpha
        );
    }

    public withAlpha(a: number): Color {
        const color = this.copy();
        color.alpha = a;
        return color;
    }

    get b(): number {
        return this._b;
    }

    set b(value: number) {
        this._b = value;
    }

    get g(): number {
        return this._g;
    }

    set g(value: number) {
        this._g = value;
    }

    get r(): number {
        return this._r;
    }

    set r(value: number) {
        this._r = value;
    }

    get alpha(): number {
        return this._alpha;
    }

    set alpha(value: number) {
        this._alpha = value;
    }

    public static fromRGBValueCompound(compound: RGBValue): Color {
        return new Color(compound.r, compound.g, compound.b);
    }

    public static ofHex(hex: string, alpha: number = 1): Color {
        try {
            const shards: RegExpExecArray = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.trim()) as RegExpExecArray;
            return new Color(
                parseInt(shards[1], 16),
                parseInt(shards[2], 16),
                parseInt(shards[3], 16),
                alpha
            );
        } catch (e) {
            console.group(`Error while parsing hex color (${hex})`)
            console.error(e);
            console.groupEnd();
            return Color.ofHex("#FF0000");
        }
    }

    public static ofHexOrFallback(hex: string, alpha: number = 1, errorFallback: Color = new Color(0, 0, 0)): Color  {
        try {
            return this.ofHex(hex, alpha)
        } catch (e) {
            return errorFallback;
        }
    }
}

function ofHex(hex: string, alpha: number = 1): Color {
    return Color.ofHex(hex, alpha);
}

export {
    // sugar
    ofHex,
    ofHex as hex
}
