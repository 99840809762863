import {Color} from "../../../base/logic/style/Color";
import {useTriton} from "../../TritonHooks";

export type ColorLike = Color | string;

export function colorLikeToColorObject(cl: ColorLike): Color {
    if (typeof cl === "string") {
        return Color.ofHex(cl);
    } else return cl;
}
