export namespace StdTypeConversionUtils {

    export function booleanishToBoolean(booleanish: any, fallback: boolean = false): boolean {
        const trueLookup = [true, "true".toUpperCase(), "true", "yes", "on", "1", 1];
        if (trueLookup.includes(booleanish)) {
            return true;
        }
        return fallback;
    }
}
