import React, {PropsWithChildren, useRef} from "react";
import styled from "styled-components";
import {CircularProgress} from "@mui/material";
import {ITooltipWrapper, PlacesType, Tooltip} from "react-tooltip";
import {v4} from "uuid";
import {Triton} from "../../triton/Triton";
import {useTriton} from "../../triton/TritonHooks";

const StyledFlatIconButton = styled.div<{
    t: Triton
}>`
  padding: 6px;
  height: 32px;
  aspect-ratio: 1 / 1;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  // color: rgb(148, 155, 164);
  color: ${p => p.t.col("icon_default")};
  background-color: #DBDEE100;

  &:active {
    .list-item-icon-container > svg {
      // color: white;
      color: #DBDEE1;
    }
  }

  &:hover[data-deactivated=false] {
    background-color: #1e202a;

    .list-item-title
      // , svg
    {
      color: #DBDEE1;
    }

    .list-item-appendix-hover {
      opacity: 1 !important;
    }

    .list-item-appendix-default {
      opacity: 0 !important;
    }
  }

  &[data-deactivated=true] {
    cursor: not-allowed;

    .list-item-icon-container > svg {
      // color: rgb(148, 155, 164) !important;
      color: #1e202a !important;
    }
  }

  &[data-active=true] {
    // background-color: #404249;
    background-color: #1e202a;

    .list-item-title {
      color: white;
    }
  }

  .list-item-icon-container {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;

    & > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }

  .task-list-main {
    flex-grow: 1;
  }

  .list-item-appendix {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 20px;

    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 4px;
    }

    .list-item-appendix-hover {
      opacity: 0;
    }

    .list-item-appendix-default {
      opacity: 1;
    }
  }
`;

export type FlatIconButtonProps = PropsWithChildren<{
    active?: boolean,
    deactivated?: boolean,
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void,
    loading?: boolean,
    tooltip?: React.ReactNode,
    clickableTooltip?: boolean,
    tooltipProps?: ITooltipWrapper,
    tooltipPlace?: PlacesType
}>

export const FlatIconButton: React.FC<FlatIconButtonProps> = props => {
    const isLoading = props.loading ?? false;
    const internalID = useRef(v4());
    const t = useTriton();

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.ctrlKey) return; // TODO: Why?
        if (props.deactivated) return;
        props.onClick?.(e);
    }

    return (
        <StyledFlatIconButton t={t} data-deactivated={props.deactivated ?? false} data-active={props.active ?? false} onClick={handleClick}>
            <div className={"list-item-icon-container"} data-tooltip-id={internalID.current}>
                { !isLoading && (
                    props.children
                ) }

                { isLoading && (
                    <div children={
                        <CircularProgress
                            variant={"indeterminate"}
                            thickness={5}
                            size={14}
                            sx={{
                                color: "rgb(148, 155, 164)"
                            }}
                        />
                    }/>
                ) }
            </div>

            {
                props.tooltip && (
                    <Tooltip place={props.tooltipPlace ?? "top"} delayShow={5e2} {...props.tooltipProps} className={"tooltip"} clickable={props.clickableTooltip ?? false} style={{
                        fontSize: "12px",
                        zIndex: 2,
                        fontFamily: "-apple-system,BlinkMacSystemFont,Segoe UI,Noto Sans,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
                        lineHeight: 1.5,
                        backgroundColor: "#1a1a20"
                    }} id={internalID.current}>
                        { props.tooltip }
                    </Tooltip>
                )
            }
        </StyledFlatIconButton>
    );
}
