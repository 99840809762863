import {SSPPMessageHandler} from "./SSPPMessageHandler";
import {SSPPPacket} from "./SSPPPacket";

export class SSPPEngine {

    private readonly handlers: Map<string, SSPPMessageHandler> = new Map<string, SSPPMessageHandler>();

    private socket?: WebSocket

    public on(type: string, handler: SSPPMessageHandler): SSPPEngine {
        this.handlers.set(type, handler);
        return this;
    }

    public bind(socket: WebSocket): SSPPEngine {
        this.socket = socket;
        socket.onmessage = (ev) => {
            const packet: SSPPPacket = JSON.parse(ev.data);

            console.debug(`[Socket] Received packet: ${packet.packetType}`)

            const handler = this.handlers.get(packet.packetType);
            handler?.({
                packet: packet,
                engine: this,
                socket: this.socket!,
                getBool(key: string): boolean | undefined {
                    return this.packet.packetPayload.has(key)
                        ? Boolean(this.packet.packetPayload.get(key))
                        : undefined
                },
                isFlagged(flag: string): boolean {
                    return this.getBool(flag) ?? false;
                }
            });
        }
        return this;
    }
}
