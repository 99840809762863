import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import _ from "lodash";
import {Std} from "../../../../../Std";

export type NodeMathMapState = {
    iMin: number,
    iMax: number,
    oMin: number,
    oMax: number
}

export const NodeMathMap: NodeSetupInfo = {
    label: "NodeMathMap",
    classname: "arithmetic.map",
    parameterConfig: [],
    factory: parameters => new Node<NodeMathMapState>({
        id: v4(),
        classname: "arithmetic.map",
        label: "map",
        state: {
            iMin: 0,
            iMax: 0,
            oMin: 0,
            oMax: 0
        },
        defOutPins: [
            "o"
        ],
        defInPins: [
            "i",
            "iMin",
            "iMax",
            "oMin",
            "oMax"
        ],
        init: function () {

            const calcMapped = (i: number = 0): number => {
                const s = this.state.state;
                return Std.range(s.iMin, s.iMax, s.oMin, s.oMax, i);
            }

            const reCalcMapped = () => {
                const i = this.pins.in("i").lastReadState ?? 0;
                const mapped = calcMapped(i);
                this.pins.out("o").write(mapped);
            }

            this.pins.in("i").attachOnRead(reCalcMapped);

            const updateMapInfo = (key: keyof NodeMathMapState, val: number) => {
                this.state.update({
                    [key]: val,
                });
            }

            (["iMin", "iMax", "oMin", "oMax"] as (keyof NodeMathMapState)[]).forEach(port => {
                this.pins.in(port).attachOnRead(data => {
                    updateMapInfo(port, data);
                    reCalcMapped();
                });
            });
        }
    })
}
