export namespace SimNodeUtils {

    /**
     * Transfers fluid from a source to a destination
     * @param cfg
     * @return actual amount transferred
     */
    export const transferFluid = (cfg: {
        maxTransferAmount: number
        getSrcAvailable: () => number,
        getDestAvailableCapacity: () => number,
        doReduceSrcLevel: (by: number) => void,
        doIncreaseDestLevel: (by: number) => void,
    }): number => {
        const srcAvailable = cfg.getSrcAvailable();
        const destAvailable = cfg.getDestAvailableCapacity();
        const transferAmount = Math.min(cfg.maxTransferAmount, srcAvailable, destAvailable);
        cfg.doReduceSrcLevel(transferAmount);
        cfg.doIncreaseDestLevel(transferAmount);
        return transferAmount;
    };
}
