import React, {PropsWithChildren, useContext, useRef, useState} from "react";
import styled from "styled-components";
import {SimulationContext} from "../Simulation2Main";
import {AzypodControlPanel} from "../entities/azypod/AzypodControlPanel";
import {as} from "../../../atlas/Lang";
import {ShipMovementPanel} from "../entities/ship/ShipMovementPanel";
import {SteeringAPControlPanel} from "../entities/ap/SteeringAPControlPanel";
import {ClutchControlDisplay} from "../entities/clutch/ClutchControlDisplay";
import {StyledControlDisplayGroup} from "../components/StyledControlDisplayGroup";
import {Speedometer} from "../../../test/engine/Speedometer";
import {Orientation} from "../../../base/logic/style/Orientation";
import {percent} from "../../../base/logic/style/DimensionalMeasured";
import {CoreControlDisplay} from "../entities/engine/CoreControlDisplay";
import {MultiplexedCoreControlPanel} from "../entities/engine/MultiplexedCoreControlPanel";
import {EngineMasterControlDisplay} from "../entities/engine/EngineMasterControlDisplay";
import {ValveControlDisplay} from "../entities/valve/ValveControlDisplay";
import {VesselSimulationToolbar} from "./components/VesselSimulationToolbar";
import {VesselSimulationWidgetBar} from "./components/VesselSimulationWidgetBar";
import {TabSelectionView} from "./components/TabSelectionView";
import {SimulationWindowAPI} from "./SimulationWindowAPI";
import {SimulationWindowState} from "./SimulationWindowState";
import {StateDispatcher} from "../../test/core/StateDispatcher";
import {TabBodyRenderer} from "./tab/TabBodyRenderer";
import {allTab} from "./tab/tabs/AllTab";
import {StdTabLibrary} from "./tab/StdTabLibrary";
import {EntityListView} from "./components/EntityListView";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {SimulationKeyCommandMainComponent} from "../keyCommands/SimulationKeyCommandMainComponent";
import {Drawer} from "../../../triton/components/advanced/drawer/Drawer";
import {DrawerHeader} from "../../../triton/components/advanced/drawer/DrawerHeader";

const StyledVesselSimulationMain = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  // background-color: lawngreen;
  background-color: #010409;

  display: grid;
  grid-template-rows: repeat(2, min-content) auto min-content;

  .vs-center-view {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: min-content auto min-content;
    
    .vs-main {
      width: available;
      position: relative;
      background-color: #060c10;
    }
  }
`;

export const generateSimulationWindowState = () => as<SimulationWindowState>({
    tab: "_main",
    tabLibrary: StdTabLibrary.tabs,
    zenMode: false
});

export const VesselSimulationMain: React.FC = props => {
    const sim = useContext(SimulationContext);
    const [state, setState] = useState<SimulationWindowState>(generateSimulationWindowState());
    const windowAPIRef = useRef<SimulationWindowAPI>(new SimulationWindowAPI(state));

    return (
        <SimulationWindowStateContext.Provider value={[state, setState]} children={
            <SimulationWindowAPIContext.Provider value={windowAPIRef.current} children={
                <VesselSimulationMainMaster children={
                    <StyledVesselSimulationMain>

                        <SimulationKeyCommandMainComponent/>

                        <div className={"vs-toolbar-container"} children={
                            <VesselSimulationToolbar/>
                        }/>


                        <div className={"vs-widgets-container"} children={
                            <VesselSimulationWidgetBar/>
                        }/>

                        <div className={"vs-center-view"}>
                            <TransitionGroup style={{
                                display: "flex",
                                overflow: "hidden"
                            }} children={(!state.zenMode) && (
                                <Collapse style={{
                                    display: "flex",
                                    overflow: "hidden"
                                }} orientation={"horizontal"} children={
                                    <TabSelectionView/>
                                }/>
                            )}/>

                            <TabBodyRenderer tab={windowAPIRef.current.currentTab}/>


                            <TransitionGroup style={{
                                display: "flex",
                                overflow: "hidden"
                            }} children={(!state.zenMode) && (
                                <Collapse style={{
                                    display: "flex",
                                    overflow: "hidden"
                                }} orientation={"horizontal"} children={
                                    <EntityListView/>
                                }/>
                            )}/>

                        </div>


                        <div className={"vs-footer-container"}/>
                    </StyledVesselSimulationMain>
                }/>
            }/>
        }/>
    );
}

export const SimulationWindowAPIContext = React.createContext<SimulationWindowAPI>(
    new SimulationWindowAPI(generateSimulationWindowState())
);

export const SimulationWindowStateContext = React.createContext<[SimulationWindowState, StateDispatcher<SimulationWindowState>]>([
    generateSimulationWindowState(),
    () => {}
]);

export const VesselSimulationMainMaster: React.FC<PropsWithChildren> = props => {
    const api = useContext(SimulationWindowAPIContext);
    const [state, setState] = useContext(SimulationWindowStateContext);
    api.state = state;
    api.setState = setState;
    return (
        <>{ props.children }</>
    );
}


