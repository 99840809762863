import React, {PropsWithChildren, useContext} from "react";
import styled from "styled-components";
import {Text} from "../../../../triton/components/typography/Text";
import {UserProfile, UserProfileContext} from "./UserProfile";

const StyledProfileField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  
  .profile-field-main-container {
    font-family: "Whitney Light", sans-serif;
    font-size: 12px;
  }
`;

export type ProfileFieldProps = PropsWithChildren<{
    title: string
}>

export const ProfileField: React.FC<ProfileFieldProps> = props => {
    const ctx = useContext(UserProfileContext);
    const p = ctx.mainProps;

    return (
        <StyledProfileField>
            <Text t={props.title} col={p.darkThemed ? p.darkThemeFontColor : p.fontColor} fs={12} fw={"bold"} style={{
                textTransform: "uppercase",
                userSelect: "none"
            }}/>
            <div className={"profile-field-main-container"} children={props.children}/>
        </StyledProfileField>
    );
}
