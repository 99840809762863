import React, {PropsWithChildren} from "react";
import styled from "styled-components";

const StyledBrandedIconLinkButton = styled.a`
  width: content-box;
  
  .link-button-container {
    border-radius: 100px;
    position: relative;
    width: 35px;
    aspect-ratio: 1 / 1;
    background-color: #1e202a;
    transition: background-color 100ms;
    
    & > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 20px;
      height: 20px;
      color: white;
    }
  }

  &:hover .link-button-container {
    background-color: #5028c8;
  }
`;

export type BrandedIconLinkButtonProps = PropsWithChildren<{
    href?: string
}>

export const BrandedIconLinkButton: React.FC<BrandedIconLinkButtonProps> = props => {
    return (
        <StyledBrandedIconLinkButton href={props.href}>
            <div className={"link-button-container"} children={props.children}/>
        </StyledBrandedIconLinkButton>
    );
}
