import {SaveStateNode} from "./SaveStateNode";
import {SerializableObject} from "../../../../../std/SerializableObject";
import {Inflatable} from "./Inflatable";
import {InflateObjectAttachment} from "./InflateObjectAttachment";
import {InflationContext} from "./InflationContext";

export namespace InflatableHelpers {

    export const getInflatablesOf = (root: object, requiredFnImpl: "inflate" | "deflate"): Array<Inflatable> => {
        const attachment = (<any>root)["_inflate"] as InflateObjectAttachment;
        let transients: Array<string | symbol> = [];

        if (attachment !== undefined) {
            transients = attachment.getTransients();
        }



        console.groupCollapsed("getInflatablesOf", root, Object
            .entries(root)
            .filter(e => e[1] !== undefined)
            .filter(e => !transients.includes(e[0]))
            .filter(e => Object.hasOwn(e[1], "id"))
        )

        console.log("transients", transients)

        console.groupEnd()

        return Object
            .entries(root)
            .filter(e => e[1] !== undefined)
            .filter(e => !transients.includes(e[0]))
            .filter(e => Object.hasOwn(e[1], "id"))
            .filter(e => Object.hasOwn(e[1], requiredFnImpl))
            .map(e => e[1] as Inflatable);
    };

    export const inflateRecursively = (host: Inflatable, hostNode: SaveStateNode, ctx: InflationContext) => getInflatablesOf(host, "inflate")
        .forEach(e => {
            const eId = e["id"];
            const eNode = hostNode.children.find(n => n.id === eId);
            if (eNode === undefined) return;
            e.inflate?.(eNode.data, eNode, ctx);
        });

    export const deflateRecursively: (host: Inflatable, hostDeflatedData: SerializableObject) => SaveStateNode = (host, hostDeflatedData) => ({
        id: host.id,
        data: hostDeflatedData,
        children: getInflatablesOf(host, "deflate").map(deflatable => deflatable.deflate!())
    });
}
