import {PortfolioBackendState} from "../PortfolioBackendState";

export namespace Globals {

    export function createDefaultState(): PortfolioBackendState {
        return {
            person: {
                socials: {
                    linkedin: {
                        handle: "cool linkedin handle",
                        link: "https://linkedin.com/"
                    },
                    github: {
                        handle: "cool github handle",
                        link: "https://github.com/"
                    }
                }
            }
        }
    }
}

