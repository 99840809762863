import React from "react";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {ClipboardCopyButton} from "./ClipboardCopyButton";
import styled from "styled-components";

const StyledKVEntryDisplay = styled.div<KVEntryDisplayProps>`
  display: grid;
  grid-template-columns: ${p => (p.enableCopy ?? true) ? `30% auto min-content` : `30% auto`};
  gap: 8px;
  align-items: center;
  
  .value-display {
    width: 100%;
    display: flex;
    height: 26px;
    align-items: center;
    justify-content: space-between;
    background-color: #1e202a;
    border-radius: 0.4rem;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    overflow-y: hidden;
    overflow-x: scroll;
    margin: 0;
    outline: none;
  }
`;

export type KVEntryDisplayProps = {
    title: string,
    displayValue?: string,
    value: string,
    enableCopy?: boolean,

    monospace?: boolean
}

/**
 * TODO: Implement
 *
 * @param props
 * @constructor
 */
export const KVEntryDisplay: React.FC<KVEntryDisplayProps> = props => {
    const fontFam = props.monospace ? "monospace" : "unset";

    return (
        <StyledKVEntryDisplay {...props}>
            <div style={{
                display: "flex",
                gap: "2px",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <DescriptiveTypography text={`${props.title}:`} style={{
                    fontWeight: "bold",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "calc(100% - 0px)",
                    whiteSpace: "nowrap",
                    width: "100%",
                    cursor: "default",
                    fontFamily: fontFam
                }}/>
            </div>

            <div className={"value-display"}>
                <DescriptiveTypography text={props.displayValue ?? props.value} style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "calc(100% - 0px)",
                    whiteSpace: "nowrap",
                    width: "100%",
                    fontFamily: fontFam
                }}/>
            </div>

            { (props.enableCopy ?? true) && (
                <ClipboardCopyButton copyValueProducer={() => props.value}/>
            ) }

        </StyledKVEntryDisplay>
    );
}
