import React, {useContext} from "react";
import {Image} from "../data/Image";
import styled from "styled-components";
import {DeleteRounded} from "@mui/icons-material";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {ContentSensitiveRenderer} from "../webapi/cp/components/ContentSensitiveRenderer";
import {ContentSensitivity} from "../webapi/cp/ContentSensitivity";
import {ContentSensitivityAttachment} from "../webapi/cp/ContentSensitivityAttachment";
import {Triton} from "../../triton/Triton";
import {useTriton} from "../../triton/TritonHooks";

export type ImagePreviewProps = {
    for: Image,
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
    onRequestDelete: () => void,
    square?: boolean
}

const StyledImagePreview = styled.button<{
    isOpenedInMainView: boolean,
    t: Triton
}>`
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  transition: .1s all;
  padding: 0 !important;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .delete-icon {
    transition: .1s all;
    position: absolute;
    opacity: 0;
    color: white;
    top: 5px;
    right: 5px;
    
    &:hover {
      color: crimson;
    }
  }
  
  &:hover {
    filter: brightness(1.2);
    
    .delete-icon {
      opacity: 1;
    }
  }
  
  &.view-selected, &.view-selected.selected {
    box-shadow: 0 0 0 2px #101016, 0 0 0 3.5px ${p => p.t.col("color_secondary") ?? "#5028c8"};
  }

  &.selected {
    transform: scale(.9);
    // box-shadow: 0 0 0 2px #101016, 0 0 0 3.5px lightgreen;
    box-shadow: 0 0 0 2px #101016, 0 0 0 3.5px ${p => p.t.col("color_primary") ?? "#DC143C"}; // (#DC143C ~ crimson)
  }
`;

export const ImagePreview: React.FC<ImagePreviewProps> = props => {
    const api = useContext(ArdaiAPIContext);
    const t = useTriton();
    const isOpenedInMainView = api.state.selectedImageId === props.for.id;
    const isSelected = api.state.selectedImages.includes(props.for.id);
    const square = props.square ?? true;

    const image = props.for;
    const imageSensitivity = image.contentSensitivity ?? ContentSensitivity.UNCLASSIFIED;
    const csa: ContentSensitivityAttachment = {
        sensitivity: imageSensitivity
    };

    return (
        <StyledImagePreview t={t} className={`${isOpenedInMainView ? "view-selected" : ""} ${isSelected ? "selected" : ""}`} isOpenedInMainView={isOpenedInMainView} style={{
            borderRadius: "8px",
            overflow: "hidden",
            cursor: "pointer",
            aspectRatio: square ? "1 / 1" : "unset",
        }} type={"button"} onClick={(event) => {
            props.onClick(event);
        }}>
            <ContentSensitiveRenderer csa={csa} children={ctx => (
                <img className={ctx.classes.img} loading={"lazy"} alt={"img"} src={URL.createObjectURL(props.for.data)} style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    height: "auto",
                    aspectRatio: square ? "1 / 1" : "unset",
                }}/>
            )}/>

            <DeleteRounded className={"delete-icon"} fontSize={"small"} onClick={event => {
                if (!event.shiftKey) return;
                event.preventDefault();
                event.stopPropagation();
                props.onRequestDelete();
            }}/>
        </StyledImagePreview>
    );
}
