import React from "react";
import styled from "styled-components";
import {ArcText} from "../../ArcText";
import {BackspaceRounded} from "@mui/icons-material";

const StyledNumpad = styled.div`
  width: 100%;
  display: grid;
  column-gap: 21px;
  row-gap: 13px;
  grid-template-columns: repeat(3, 1fr);
  user-select: none;
`;

const StyledNumpadKeyBase = styled.button`
  border: none;
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: white;
  transition: all 80ms ease-in-out;
  
  * {
    font-weight: 600 !important;
    color: black !important;
  }
  
  &:active {
    transform: scale(.95);
    // filter: brightness(.98);
  }
`;

export type NumpadProps = {
    onKeyStroke: (n: number) => void,
    onDelete: () => void
}

export const Numpad: React.FC<NumpadProps> = props => {

    return (
        <StyledNumpad>
            { Array.from({length: 9}, (_, i) => i + 1).map(n => (
                <StyledNumpadKeyBase onContextMenu={e => e.preventDefault()} key={n} onClick={() => props.onKeyStroke(n)} children={
                    <ArcText text={n} variant={"normal-title"}/>
                }/>
            )) }
            <span/>
            <StyledNumpadKeyBase key={0} onClick={() => props.onKeyStroke(0)} children={
                <ArcText text={0} variant={"normal-title"}/>
            }/>
            <StyledNumpadKeyBase key={"del"} onClick={() => props.onDelete()} children={
                <BackspaceRounded/>
            }/>
        </StyledNumpad>
    );
}
