import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {PinMode} from "../../../backend/Pin";

export const NodePort: NodeSetupInfo = {
    label: "Input port config",
    classname: "node.input-configuration",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "node.input-configuration",
        // visualConfig: {
        //     dye: triton().col("color_primary")
        // },
        label: "in",
        init: function () {
            this.pins.createPinBank("parameter config", {
                label: "parameter config",
                groups: ["output"],
                defaultPinMode: PinMode.OUT,
                dynamicSizeFlag: true,
                visualConfig: {
                    binBankRendererResourceName: "manual-port-configuration"
                }
            });
        },
        // customRenderer: node => (
        //     <div style={{
        //         display: "flex",
        //         gap: 0,
        //         flexDirection: "row",
        //         padding: 0,
        //         alignItems: "center",
        //         height: "100%",
        //         justifyContent: "center"
        //     }}>
        //         <input
        //             type={"text"}
        //             value={node.state.state.parameterName}
        //             onChange={e => {
        //                 const parameterName = e.currentTarget.value;
        //                 node.state.update({ parameterName });
        //             }}
        //             style={{
        //                 maxWidth: 80,
        //                 fontSize: 12,
        //                 color: triton().col("fg_muted"),
        //                 border: "none",
        //                 backgroundColor: "transparent",
        //                 outline: "none",
        //                 textAlign: "end"
        //             }}
        //             placeholder={"port name"}
        //         />
        //         <DescriptiveTypography text={": "} style={{ whiteSpace: "pre" }}/>
        //         <input
        //             type={"text"}
        //             value={node.state.state.typeName}
        //             onChange={e => {
        //                 const typeName = e.currentTarget.value;
        //                 node.state.update({ typeName });
        //             }}
        //             style={{
        //                 maxWidth: 60,
        //                 fontSize: 12,
        //                 color: triton().col("color_secondary"),
        //                 border: "none",
        //                 backgroundColor: "transparent",
        //                 outline: "none",
        //                 fontWeight: "lighter"
        //             }}
        //             placeholder={"type"}
        //         />
        //     </div>
        // )
    })

}
