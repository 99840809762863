import React, {useContext} from "react";
// import Logo from "../assets/image-hub-logo.png";
import Logo from "../../../assets/logos/ardspace-logo-1.png";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {Tooltip} from "react-tooltip";
import {DefaultButton} from "./DefaultButton";
import ReactJson from "react-json-view";
import {CodeRounded, InfoRounded} from "@mui/icons-material";
import {CopyIcon} from "../../base/components/base/CopyIcon";
import {ClipboardCopyButton} from "./ClipboardCopyButton";
import {MetaBasicEntry} from "./MetaBasicEntry";
import {motion} from "framer-motion";

export type ImageHubBrandingProps = {
    centerLayout?: boolean
}

/**
 * TODO: Meta display is not fading out correctly
 *
 * @param props
 * @constructor
 */
export const ImageHubBranding: React.FC<ImageHubBrandingProps> = props => {
    const api = useContext(ArdaiAPIContext);
    const meta = api.getBuildMetadata();
    const timestamp = new Date()
    timestamp.setTime(meta.buildUnixTime);
    const formattedTimestamp = timestamp.toUTCString();

    return (
        <div style={{
            display: "flex",
            flexDirection: props.centerLayout ?? false ? "column" : "row",
            alignItems: "center",
            gap: ".7rem",
            // paddingLeft: ".5rem"
        }}>
            <img src={Logo} width={40} height={40} alt={"Hub logo"} style={{
                borderRadius: 12,
                userSelect: "none"
            }}/>
            {/*
            <img src={Logo} width={40} height={40} alt={"Hub logo"} style={{
                borderRadius: 12
            }}/>
            */}


            {/*
            <motion.div
                initial={{ scale: 0, rotate: -90 }}
                animate={{ scale: 1, rotate: 0 }}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                }}
                children={
                    <img src={Logo} width={40} height={40} alt={"Hub logo"} style={{
                        borderRadius: 12
                    }}/>
                }
            />
            */}

            {/*
            <motion.div
                animate={{
                    scale: [1, 2, 2, 1, 1],
                    rotate: [0, 0, 270, 270, 0],
                    borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                }}
                children={
                    <img src={Logo} width={40} height={40} alt={"Hub logo"} style={{
                        borderRadius: 12
                    }}/>
                }
            />
            */}


            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: ".15rem",
            }}>
                <MainTypography text={"."} style={{
                    fontSize: "20px",
                    userSelect: "none"
                }}/>
                <MainTypography text={"continuum"} style={{
                    fontSize: "20px",
                    // fontWeight: "bold",
                    fontWeight: "lighter",
                    userSelect: "none"
                }}/>
            </div>

            <span data-tooltip-id={"build-meta-tooltip"}>
                <DescriptiveTypography text={api.getVersionString()} style={{
                    // fontWeight: "bolder",
                    userSelect: "none"
                }}/>
            </span>

            <Tooltip
                id={"build-meta-tooltip"}
                className={"tooltip"}
                openOnClick
                clickable

                style={{
                    // TODO: Maybe set conditionally
                    opacity: 1,

                    zIndex: 100,
                    fontSize: "12px",
                    fontFamily: "-apple-system,BlinkMacSystemFont,Segoe UI,Noto Sans,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
                    lineHeight: 1.5,
                    backgroundColor: "#1a1a20",
                    boxShadow: "0 0 0 1px #30363d, 0 16px 32px rgba(1,4,9,0.85)",
                    borderRadius: 12
                }}
                children={
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        minWidth: "350px"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            gap: "8px"
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "8px"
                            }}>
                                <CodeRounded style={{ width: "18px" }}/>
                                <MainTypography text={"Build metadata"}/>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "8px"
                            }}>
                                <ClipboardCopyButton copyValueProducer={() => JSON.stringify(meta, null, 2)}/>
                            </div>
                        </div>

                        <MetaBasicEntry title={"Build"} value={api.getVersionString()} enableCopy/>
                        <MetaBasicEntry title={"Timestamp"} value={formattedTimestamp} enableCopy/>
                        <ReactJson
                            src={api.getBuildMetadata()}
                            theme={"grayscale"}
                            shouldCollapse={false}
                            displayObjectSize={false}
                            displayDataTypes={false}
                            iconStyle={"square"}
                            enableClipboard={false}
                            style={{ backgroundColor: "transparent" }}
                        />
                    </div>
                }
            />


        </div>
    );
}
