import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const RandomGen: NodeSetupInfo = {
    label: "",
    classname: "random-gen",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "random-gen",
        label: "random",
        state: { i: 0 },
        defOutPins: ["i"],
        defInPins: ["c"],
        init: function () {
            this.pins.in("c").attach({
                read(data: any) {
                    this.node.pins.out("i").write(Math.random());
                }
            })
        }
    })
}
