import {FC, ReactNode} from "react";
import styled from "styled-components";
import {useTriton} from "../../../TritonHooks";
import {Triton} from "../../../Triton";
import {Color} from "../../../../base/logic/style/Color";
import {DescriptiveTypography} from "../../typography/DescriptiveTypography";
import {useStaticState} from "../../../../ardai/hooks/StaticStateHook";
import {CloseRounded} from "@mui/icons-material";
import {ReactComponent as ShapeQuarterCircleInverse} from "../../../assets/shapes/shape-quarter-circle-inverse.svg";

const StyledTabHeader = styled.div<{
    t: Triton
}>`
  --border-radius-main: 10px;
  --background-color: ${p => p.t.col("bg_tray")};
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 7.5px;
  border-radius: var(--border-radius-main);
  background-color: var(--background-color);
  cursor: auto;
  height: 33px;
  
  
  * {
    font-family: "JetBrains Mono", monospace;
  }
  
  &:hover {
    filter: brightness(1.25);

    .tab-header-closing-icon-wrapper {
      opacity: 1;
    }
  }
  
  // TODO: Make this a global style
  &[data-is-processing=true] {
    cursor: progress;
  }
  
  .tab-header-closing-icon-wrapper {
    opacity: 0;
    position: relative;
    aspect-ratio: 1 / 1;
    padding: 0;
    width: 15px;
    
    .tab-header-closing-icon {
      z-index: 5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    
    &:before {
      opacity: 0;
      z-index: 1;
      width: 100%;
      aspect-ratio: 1 / 1;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 50%;
      background-color: ${p => p.t.col("bg_modal")};
    }
    
    &:hover:before {
      opacity: 1;
    }
  }
  
  .tab-active-decoration {
    z-index: 1;
    position: absolute;
    width: 100%;
    color: var(--background-color);
    background-color: currentColor;
    bottom: calc(.75 * -1 * var(--border-radius-main));
    left: 0;
    height: calc(2 * var(--border-radius-main));
    
    .bevel {
      position: absolute;
      bottom: 0;
      height: var(--border-radius-main);
      aspect-ratio: 1 / 1;
      color: currentColor;
    }
    
    .left-bevel {
      left: calc(-1 * var(--border-radius-main));
    }

    .right-bevel {
      right: calc(-1 * var(--border-radius-main));
      transform: rotate(90deg);
    }
  }
`;

export type TabHeaderCallable<T = void> = (
    this: TabHeaderContext, ctx: TabHeaderContext
) => T;

export interface TabHeaderContext {
    props: TabHeaderProps
}

export type TabHeaderProps = {
    title?: ReactNode,
    isActive?: boolean,
    isClosable?: boolean,
    dye?: Color,
    prefix?: TabHeaderCallable<ReactNode>,
    onRequestSetActive?: () => Promise<void>
    onRequestClose?: () => Promise<void>
}

export type TabHeaderState = {
    isClosing: boolean,
    isSettingActive: boolean
}

export const TabHeader: FC<TabHeaderProps> = props => {
    const t = useTriton();
    const getTHCtx = () => ({ props } as TabHeaderContext);
    const callTHFunc = <T,>(func?: TabHeaderCallable<T>, def?: T) => {
        const ctx = getTHCtx();
        if (func === undefined) return def;
        return func.call(ctx, ctx);
    }

    const [state, ctx] = useStaticState<TabHeaderState>({
        initial: {
            isClosing: false,
            isSettingActive: false
        }
    }).stateWithCtx;

    const isProcessing = state.isSettingActive || state.isClosing;

    const renderTitle = () => {
        const title = props.title;
        if (title === undefined) return undefined;
        if (typeof title === "string") return (
            <DescriptiveTypography text={title} noSelect/>
        );
        return title;
    }

    const renderClosingIcon = () => {
        const isClosable = props.isClosable ?? false;
        if (!isClosable) return undefined;
        const canClick = !state.isClosing;
        return (
            <div className={"tab-header-closing-icon-wrapper"} onClick={e => {
                if (!canClick) return;

                props.onRequestClose?.();
            }}>
                <CloseRounded className={"tab-header-closing-icon"} style={{
                    color: t.col("fg_secondary"),
                    cursor: canClick ? "pointer" : "not-allowed"
                }} sx={{
                    fontSize: 14
                }}/>
            </div>
        );
    }

    const onRequestSetActive = async () => {
        await props.onRequestSetActive?.();
    }

    return (
        <StyledTabHeader t={t} data-is-processing={isProcessing} onClick={onRequestSetActive}>
            { callTHFunc(props.prefix) }
            { renderTitle() }
            { renderClosingIcon() }

            { props.isActive && (
                <div className={"tab-active-decoration"}>
                    <ShapeQuarterCircleInverse className={"bevel left-bevel"}/>
                    <ShapeQuarterCircleInverse className={"bevel right-bevel"}/>
                </div>
            ) }
        </StyledTabHeader>
    );
}
