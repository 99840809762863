import React, {useContext, useState} from "react";
import styled from "styled-components";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {TagEditor} from "../../../ardai/components/TagEditor";
import {SDInterfaceAPIContext} from "../SDInterfaceAPI";
import {useStaticState} from "../../../ardai/hooks/StaticStateHook";
import {FlatIconButton} from "../../../ardai/components/FlatIconButton";
import {EditRounded} from "@mui/icons-material";
import {Tag} from "../../../ardai/components/Tag";
import {FormDivider} from "../../../triton/components/forms/FormDivider";
import {BooleanField} from "../../../ardai/testing/BooleanField";
import {ControlledBooleanInput} from "../../../ardai/components/ControlledBooleanInput";
import {MenuGroup} from "../../../ardai/components/MenuGroup";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";

const StyledOutputConfigurationDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export type OutputConfigurationDisplayState = {
    isEditingTabs: boolean
}

export const OutputConfigurationDisplay: React.FC = props => {
    const sdApi = useContext(SDInterfaceAPIContext);
    const stateCtx = sdApi.stateCtx;
    const state = stateCtx.state;
    const outputConfig = state.outputConfig;

    const [ls, lsCtx] = useStaticState<OutputConfigurationDisplayState>({
        id: "OutputConfigurationDisplay",
        staticMode: true,
        initial: {
            isEditingTabs: false
        }
    }).stateWithCtx;

    return (
        <StyledOutputConfigurationDisplay>
            <MainTypography text={"Output configuration"} noSelect/>

            <MenuGroup title={"General"} indentation={8} showIndentationLine={true}>
                <ControlledBooleanInput
                    title={"Save images to history"}
                    value={true}
                    update={() => {}}
                />
            </MenuGroup>

            <MenuGroup title={"Tags"} indentation={8} showIndentationLine={true} appendix={
                <Tag
                    tag={<EditRounded style={{
                        fontSize: 14
                    }}/>}
                    onClick={() => lsCtx.update({
                        isEditingTabs: true
                    })}
                />
            }>
                <div style={{
                    gap: 2,
                    display: "flex"
                }} children={outputConfig.applyTags.map(tag => (
                    <Tag tag={tag} key={tag} onClick={() => {
                        stateCtx.update(prevState => ({
                            outputConfig: {
                                ...prevState.outputConfig,
                                applyTags: prevState.outputConfig.applyTags.filter(applyTag => applyTag !== tag)
                            }
                        }));
                    }}/>
                ))}/>

                <ControlledBooleanInput
                    title={"Apply tags after generation"}
                    value={outputConfig.applyTagsEnabled}
                    update={bool => {
                        stateCtx.update(prevState => ({
                            outputConfig: {
                                ...prevState.outputConfig,
                                applyTagsEnabled: bool
                            }
                        }));
                    }}
                />
            </MenuGroup>

            <MenuGroup title={"Folders"} indentation={8} showIndentationLine={true} appendix={
                <Tag
                    tag={<EditRounded style={{
                        fontSize: 14
                    }}/>}
                    onClick={() => lsCtx.update({
                        isEditingTabs: true
                    })}
                />
            }>
                <DescriptiveTypography text={"This feature will be implemented shortly."} noSelect/>

                <ControlledBooleanInput
                    title={"Add to folder after generation"}
                    value={false}
                    update={() => {}}
                />
            </MenuGroup>

            <TagEditor
                open={ls.isEditingTabs}
                onClose={() => lsCtx.update({
                    isEditingTabs: false
                })}
                onSave={tags => {
                    stateCtx.update(prevState => ({
                        outputConfig: {
                            ...prevState.outputConfig,
                            applyTags: tags
                        }
                    }));
                    lsCtx.update({
                        isEditingTabs: false
                    });
                }}
                initialTags={outputConfig.applyTags}
                suggestions={() => ["concept"]}
            />
        </StyledOutputConfigurationDisplay>
    );
}
