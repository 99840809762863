import React from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {MainTypography} from "../../../triton/components/typography/MainTypography";

const StyledKeyFolderLinkHint = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  
  .key-hint {
    border-radius: 8px;
    width: 50px;
    height: 50px;
    background-color: #1e202a;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    transition: all 150ms;
    
    &:hover {
      filter: brightness(1.2);
    }
    
    &:active {
      transform: scale(.95);
    }
  }
`;

export type KeyFolderLinkHintProps = {
    folderName?: string,
    folderID: string,
    keyHint: React.ReactNode,
    footer?: React.ReactNode
}

export const KeyFolderLinkHint: React.FC<KeyFolderLinkHintProps> = props => {

    return (
        <StyledKeyFolderLinkHint>
            <div className={"key-hint"}>
                <MainTypography text={props.keyHint}/>
            </div>
            { props.folderName && (
                <DescriptiveTypography text={props.folderName}/>
            ) }
            { props.footer }
        </StyledKeyFolderLinkHint>
    );
}
