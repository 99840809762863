import React, {useState} from "react";
import {useLiveQuery} from "dexie-react-hooks";
import {WebAPIDB, webDB} from "../webapi/WebAPIDB";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {ButtonBase} from "../../triton/components/buttons/ButtonBase";
import {Image} from "../data/Image";
import {ArrowLeftRounded, ArrowRightRounded, MoreVertRounded, RefreshRounded, UploadRounded} from "@mui/icons-material";
import {IconButton} from "./IconButton";
import {Menu} from "./Menu";
import {MenuButton} from "./MenuButton";
import {FormikSingleSelectInput} from "../../triton/components/forms/FormikSingleSelectInput";
import {Formik} from "formik";
import {MenuDivider} from "@szhsin/react-menu";
import {Table} from "dexie";
import {ImageUtils} from "../utils/ImageUtils";
import {FlatIconButton} from "./FlatIconButton";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {useTriton} from "../../triton/TritonHooks";
import {BasicSingleSelect} from "../../triton/components/forms/BasicSingleSelect";
import {MenuGroup} from "./MenuGroup";

export type ImageGridProps = {
    imageIDs?: Array<string>,
    isaTable?: "images" | "sdInterfaceResults",
    preferPreviewImage?: boolean
    imageRenderer?: (this: ISADBImageGridRenderContext, data: Image, ctx: ISADBImageGridRenderContext) => React.ReactNode,
}

export enum AspectRatioMode {
    SQUARE = "1 / 1",
    NATURAL = "auto"
}

export type ImageGridState = {
    page: number,
    itemsPerPage: number | undefined,
    aspectRatioMode: AspectRatioMode,
    selection?: ISADBImageGridSelectionState,
    preferredRenderer?: ISADBImageGridPreferredRenderer
}

export type ISADBImageGridSelectionState = {
    imageIDs: Array<string>
}

export enum ISADBImageGridPreferredRenderer {
    NATIVE = "NATIVE", USER_RENDERER = "USER_RENDERER"
}

export class ISADBImageGridRenderContext {
    constructor(
        public readonly state: ImageGridState
    ) {}
}

export const ISADBImageGrid: React.FC<ImageGridProps> = props => {
    const t = useTriton();
    const preferPreviewImage = props.preferPreviewImage ?? false;
    const [state, setState] = useState<ImageGridState>({
        page: 1,
        itemsPerPage: 16,
        aspectRatioMode: AspectRatioMode.SQUARE,
        preferredRenderer: ISADBImageGridPreferredRenderer.USER_RENDERER,
        selection: undefined
    });
    const tableLen = useLiveQuery(() => (webDB[props.isaTable ?? "images"] as Table<Image>).count()) ?? 0;
    const dataLen = props.imageIDs?.length ?? tableLen;

    const images = useLiveQuery(async () => {
        const table = webDB[props.isaTable ?? "images"] as Table<Image>;

        if (props.imageIDs === undefined) {
            return table
                .offset(state.itemsPerPage === undefined ? 0 : ((state.page - 1) * state.itemsPerPage))
                .limit(state.itemsPerPage ?? -1)
                .toArray();
        }

        return table
            .where("id")
            .anyOfIgnoreCase(props.imageIDs)
            .offset(state.itemsPerPage === undefined ? 0 : ((state.page - 1) * state.itemsPerPage))
            .limit( state.itemsPerPage === -1 ? props.imageIDs.length : (state.itemsPerPage ?? props.imageIDs.length))
            .toArray();
    }, [props, state]);

    function isImageSelected(imageID: string) {
        return state.selection && state.selection.imageIDs.includes(imageID);
    }

    function closeSelection() {
        setState(prevState => ({
            ...prevState,
            selection: undefined
        }));
    }

    function unselectImage(imageID: string) {
        const sel = state.selection;
        if (sel === undefined) {
            return;
        }
        const newImageSel = sel.imageIDs.filter(id => id !== imageID);
        if (newImageSel.length === 0) {
            closeSelection();
            return;
        }
        setState(prevState => ({
            ...prevState,
            selection: {
                ...prevState.selection,
                imageIDs: newImageSel
            }
        }));
    }

    function openSelection(sel: Partial<ISADBImageGridSelectionState> = {}) {
        setState(prevState => ({
            ...prevState,
            selection: {
                imageIDs: [],
                ...sel
            }
        }));
    }

    function selectImage(image: Image) {
        const sel = state.selection;
        if (sel === undefined) {
            return;
        }
        setState(prevState => ({
            ...prevState,
            selection: {
                ...prevState.selection,
                imageIDs: [...prevState.selection?.imageIDs ?? [], image.id]
            }
        }));
    }

    function toggleImageSelect(image: Image) {
        const imageID = image.id;
        if (isImageSelected(imageID)) {
            unselectImage(imageID);
        } else {
            if (!state.selection) {
                openSelection({
                    imageIDs: [image.id]
                });
                return;
            }
            selectImage(image);
        }
    }

    const selectionColorCss = t.col("color_secondary");

    const renderContext = new ISADBImageGridRenderContext(state);

    const renderImage = (image: Image) => {
        if (props.imageRenderer !== undefined && state.preferredRenderer === ISADBImageGridPreferredRenderer.USER_RENDERER) {
            return (props.imageRenderer.call(renderContext, image, renderContext));
        }

        return (
            <div children={
                <img loading={"lazy"} alt={"img"} src={
                    URL.createObjectURL(
                        ImageUtils.loadImageData(image, preferPreviewImage)
                    )
                } style={{
                    objectFit: "cover",
                    borderRadius: "8px",
                    aspectRatio: state.aspectRatioMode,
                    objectPosition: "center",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    height: "auto",
                    border: `0px solid ${selectionColorCss}`,
                    ...(!isImageSelected(image.id) ? {} : {
                        border: `2px solid ${selectionColorCss}`
                    })
                }} onClick={() => {
                    toggleImageSelect(image);
                }}/>
            }/>
        );
    }

    return (
        <div style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            height: "100%",
            justifyContent: "space-between"
        }}>
            <div style={{
                width: "100%",
                display: "grid",
                alignItems: "center",
                position: "relative",
                gridTemplateColumns: "min-content auto min-content"
            }}>
                <DescriptiveTypography
                    noSelect
                    text={`${dataLen} images`}
                    style={{
                        textAlign: "center",
                        whiteSpace: "nowrap"
                    }}
                />
                <span/>
                <Menu opener={
                    <FlatIconButton children={<MoreVertRounded fontSize={"small"}/>}/>
                }>
                    <MenuButton text={"Select all"} disabled={props.imageIDs?.length === 0} onSelect={() => {
                        openSelection({
                            imageIDs: props.imageIDs ?? []
                        })
                    }}/>
                    <MenuButton text={"Close selection"} disabled={!state.selection} onSelect={() => {
                        closeSelection()
                    }}/>

                    <MenuButton text={"Invert selection"} disabled/>
                    <MenuButton text={"Perform action on selection"} disabled/>

                    <MenuGroup title={"Advanced"}>
                        <Formik initialValues={{ itemsPerPage: state.itemsPerPage }} onSubmit={values => {
                            setState(prevState => ({ ...prevState, itemsPerPage: Number(values.itemsPerPage) ?? 16 }))
                        }} children={fp => (
                            <FormikSingleSelectInput centerSelectedElementBadge placeholder={"Items per page"} disableSearchbar onPreSubmit={val => {
                                fp.setFieldValue("itemsPerPage", val);
                                fp.handleSubmit();
                            }} formikProps={fp} name={"itemsPerPage"} options={[
                                { id: "8", text: "8" },
                                { id: "16", text: "16" },
                                { id: "32", text: "32" },
                                { id: "48", text: "48" },
                                { id: "64", text: "64" },
                                { id: "-1", text: "All" },
                            ]}/>
                        )}/>

                        <BasicSingleSelect
                            keepOpenOnSelect
                            name={"aspectRatioMode"}
                            selected={state.aspectRatioMode}
                            title={"Display mode"}
                            disableSearchbar
                            options={[
                                { id: AspectRatioMode.SQUARE, text: "Square" },
                                { id: AspectRatioMode.NATURAL, text: "Natural" }
                            ]}
                            onSelect={value => {
                                setState(prevState => ({
                                    ...prevState,
                                    aspectRatioMode: value as AspectRatioMode
                                }))
                            }}
                        />

                        <BasicSingleSelect
                            keepOpenOnSelect
                            name={"preferredRenderer"}
                            selected={state.preferredRenderer}
                            title={"Preferred renderer"}
                            disableSearchbar
                            options={[
                                { id: ISADBImageGridPreferredRenderer.NATIVE, text: "Native" },
                                { id: ISADBImageGridPreferredRenderer.USER_RENDERER, text: "Custom" }
                            ]}
                            onSelect={value => {
                                setState(prevState => ({
                                    ...prevState,
                                    preferredRenderer: value as ISADBImageGridPreferredRenderer
                                }))
                            }}
                        />
                    </MenuGroup>
                </Menu>


                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translateX(-50%) translateY(-50%)"
                }} children={
                    <DescriptiveTypography noSelect style={{ textAlign: "center" }} text={`${images?.length ?? "0"} loaded`}/>
                }/>
            </div>

            <TransitionGroup>
                { state.selection && (
                    <Collapse>
                        <div>
                            <DescriptiveTypography text={"Images are selected"}/>
                        </div>
                    </Collapse>
                ) }
            </TransitionGroup>



            {
                (images !== undefined && images.length > 0) ? (
                    <div style={{
                        display: "flex",
                        overflowY: "scroll",
                        padding: "4px",
                        height: "100%",
                    }}>
                        <div style={{
                            display: "grid",
                            gap: "8px",
                            height: "fit-content",
                            gridTemplateColumns: "repeat(auto-fill, minmax(64px, auto))"
                        }}>
                            {
                                images?.map(image => renderImage(image))
                            }
                        </div>
                    </div>
                ) : undefined
            }

            <div style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <FlatIconButton
                    deactivated={state.page < 2}
                    children={<ArrowLeftRounded fontSize={"small"}/>}
                    onClick={() => {
                        if (state.page > 1) {
                            setState(prevState => ({
                                ...prevState,
                                page: prevState.page - 1
                            }));
                        }
                    }}
                />

                <DescriptiveTypography noSelect style={{
                    textAlign: "center"
                }} text={`${state.page} / ${Math.ceil(dataLen / (state.itemsPerPage ?? 1))}`}/>

                <FlatIconButton
                    deactivated={state.page >= Math.ceil(dataLen / (state.itemsPerPage ?? 1))}
                    children={<ArrowRightRounded fontSize={"small"}/>}
                    onClick={() => {
                        if (state.page < Math.ceil(dataLen / (state.itemsPerPage ?? 1))) {
                            setState(prevState => ({
                                ...prevState,
                                page: prevState.page + 1
                            }));
                        }
                    }}
                />
            </div>
        </div>
    );
}
