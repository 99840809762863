import {FC, useRef} from "react";
import styled from "styled-components";
import {v4} from "uuid";
import {Triton} from "../../../../triton/Triton";
import {useTriton} from "../../../../triton/TritonHooks";
import {DataManipulatorBaseProps} from "./DataManipulatorBaseProps";
import {Checkbox} from "@mui/material";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";

const StyledStringManipulationDisplay = styled.div<{
    t: Triton
}>`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  // color: ${p => p.t.col("color_primary")};
  color: ${p => p.t.col("fg_secondary")};

  .string-input-wrapper {
    display: flex;
    align-items: center;
    
    input[type=text] {
      background-color: transparent;
      border: none;
      outline: none;
      color: currentColor;
      text-align: end;
    }
  }
`;

export type StringManipulationDisplayProps = DataManipulatorBaseProps<string, {}>

export const StringManipulationDisplay: FC<StringManipulationDisplayProps> = props => {
    const t = useTriton();
    const value = props.value;

    const changeValueTo = (value: string): void => {
        props.onUpdate(value);
    }

    return (
        <StyledStringManipulationDisplay t={t}>
            <div className={"string-input-wrapper"}>
                <input
                    type={"text"}
                    value={value}
                    onChange={e => changeValueTo(e.currentTarget.value)}
                    placeholder={DefaultCharacterSymbols.placeholder}
                />
            </div>
        </StyledStringManipulationDisplay>

    );
}
