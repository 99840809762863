import React, {useContext, useEffect, useState} from "react";
import {VFSElement} from "../data/VFSElement";
import {
    CheckBoxOutlineBlankRounded,
    CheckBoxRounded,
    DeleteRounded,
    EditRounded,
    LibraryAddCheckRounded,
    MoreHorizRounded,
    TagRounded
} from "@mui/icons-material";
import {ListItem} from "./ListItem";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {ArdaiAPIStateContext} from "../ArdaiMain";
import {Menu} from "@szhsin/react-menu";
import {ButtonModalCompound} from "../components/ButtonModalCompound";
import {MenuButton} from "../components/MenuButton";
import {Formik} from "formik";
import {webDB} from "../webapi/WebAPIDB";
import {StyledModal} from "../components/StyledModal";
import {FormElement} from "../../triton/components/forms/FormElement";
import {FormikSingleLineInput} from "../../triton/components/forms/FormikSingleLineInput";
import {Grid} from "../../triton/components/layouts/Grid";
import {ButtonBase, ButtonVariant} from "../../triton/components/buttons/ButtonBase";
import styled from "styled-components";
import {useLiveQuery} from "dexie-react-hooks";
import {mapToTreeListElements, TreeListElement, TreeListElementRole} from "./TreeListElement";
import {Project} from "../data/Project";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {VirtualFileDriverOpeningResult} from "../webapi/virtualFileDriverManager/VirtualFileDriverOpeningResult";
import {v4} from "uuid";
import {Image} from "../data/Image";
import {ISAImage} from "../components/ISAImage";
import {useTriton} from "../../triton/TritonHooks";
import {Checkbox} from "@mui/material";

const StyledMenu = styled.div`
  .szh-menu__item {
    padding: 0 !important;
    
    &:hover {
      background-color: initial;
    }
  }
  
  .szh-menu__divider {
    margin-top: 2px;
    background-color: #30363d;
    margin-bottom: 2px;
  }
`;

export type ProjectListItemProps = {
    vfsElem: VFSElement,
    selected?: boolean
    onToggleSelect?: () => void,
    isInSelectionMode?: boolean
}

export type ProjectListItemState = {
    processing: boolean,
    openingResult?: VirtualFileDriverOpeningResult,
    subItems: Array<Image>
}

export const ProjectListItem: React.FC<ProjectListItemProps> = props => {
    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);
    const t = useTriton();
    const e = props.vfsElem;

    const isSelected = props.selected ?? false;
    const isInSelectionMode = props.isInSelectionMode ?? false;

    const project = useLiveQuery(() => {
        return api.getProject(e.id)
    });

    const [ls, setLS] = useState<ProjectListItemState>({
        processing: false,
        openingResult: undefined,
        subItems: []
    });

    // const subElements = useLiveQuery(() => {
    //     if (project === undefined) return [];
    //     return webDB.images.bulkGet(project.resources).then(res => {
    //         return res.filter(sI => sI !== undefined) as Image[]
    //     });
    // }, [project]);

    useLiveQuery(() => {
        if (project === undefined) return [];
        webDB.images.bulkGet(project.resources).then(res => {
            setLS(prevState => ({
                ...prevState,
                subItems: res.filter(sI => sI !== undefined) as Image[]
            }));
        })
    }, [project]);

    // useEffect(() => {
    //     if (project === undefined) return;
    //     webDB.images.bulkGet(project.resources).then(res => {
    //         setLS(prevState => ({
    //             ...prevState,
    //             subItems: res.filter(sI => sI !== undefined) as Image[]
    //         }));
    //     })
    // }, [project]);

    function generateProjectListItemAppendix(project: VFSElement): JSX.Element {
        return (
            <>
                <Menu
                    children={
                        <StyledMenu>
                            <ButtonModalCompound button={<MenuButton text={"Rename"} icon={<EditRounded/>}/>} modalContent={ctx => (
                                <Formik initialValues={{ title: project.title }} onSubmit={values => {
                                    webDB.vfsElements.update(project.id, values).then(() => ctx.close());
                                }} children={fp => (
                                    <StyledModal title={"Rename folder"} icon={<EditRounded/>} onClose={() => ctx.close()} children={
                                        <FormElement title={"New folder name"} children={
                                            <FormikSingleLineInput autoFocus name={"title"} formikProps={fp}/>
                                        }/>
                                    } footer={
                                        <Grid style={{ width: "100%" }} gTC={"repeat(2, 1fr)"}>
                                            <ButtonBase text={"Save"} baseProps={{
                                                onClick: e => fp.handleSubmit(e)
                                            }}/>
                                            <ButtonBase text={"Cancel"} baseProps={{
                                                onClick: () => ctx.close()
                                            }}/>
                                        </Grid>
                                    }/>
                                )}/>
                            )}/>

                            <ButtonModalCompound button={<MenuButton text={"Delete"} icon={<DeleteRounded/>}/>} modalContent={ctx => (
                                <StyledModal title={"Do you really want to delete the project"} footer={
                                    <Grid style={{ width: "100%" }} gTC={"repeat(2, 1fr)"}>
                                        <ButtonBase text={"Yes, delete"} variant={ButtonVariant.DANGER} baseProps={{
                                            onClick: () => webDB.vfsElements.delete(project.id).then(() => ctx.close())
                                        }}/>
                                        <ButtonBase text={"No, cancel"} baseProps={{
                                            onClick: () => ctx.close()
                                        }}/>
                                    </Grid>
                                }/>
                            )}/>
                        </StyledMenu>
                    }
                    menuButton={
                        <MoreHorizRounded sx={{ fontSize: 20 }}/>
                    }
                    theming={"dark"}
                    transition={true}
                    menuStyle={{
                        backgroundColor: "rgb(22, 27, 34)",
                        borderRadius: "12px",
                        boxShadow: "rgba(1, 4, 9, 0.85) 0px 16px 32px",
                        padding: "8px",
                        border: "1px solid rgb(48, 54, 61)"
                    }}
                    arrowStyle={{
                        borderLeftColor: "rgb(48, 54, 61)",
                        borderTopColor: "rgb(48, 54, 61)",
                        backgroundColor: "rgb(22, 27, 34)"
                    }}
                />

                {/* <SettingsRounded sx={{ fontSize: 16 }}/> */}
            </>
        );
    }

    function getItemIcon() {
        const fontSize = 20;
        if (isSelected) {
            return (
                <CheckBoxRounded sx={{ fontSize }}/>
            );
        } else {
            if (isInSelectionMode) {
                return (
                    <CheckBoxOutlineBlankRounded sx={{ fontSize }}/>
                );
            }
            return (
                <TagRounded sx={{ fontSize }}/>
            );
        }
    }

    return (
        <>
            {/*
            <ListItem
                title={e.title}
                icon={<TagRounded sx={{ fontSize: 20 }}/>}
                onClick={() => api.selectProject(e.id)}
                active={state.selectedProject === e.id}
                activeAppendix={generateProjectListItemAppendix(e)}
                hoverAppendix={generateProjectListItemAppendix(e)}
            />
            */}

            <ListItem
                title={e.title}
                icon={getItemIcon()}
                onClick={async (ev) => {
                    if (ev.ctrlKey || isInSelectionMode) {
                        props.onToggleSelect?.();
                        return;
                    }
                    if (ls.processing) return;

                    setLS(prevState => ({
                        ...prevState,
                        processing: true,
                        openingResult: undefined
                    }));

                    const res = await api.virtualFileDriverManager.open(e);
                    setLS(prevState => ({
                        ...prevState,
                        processing: false,
                        openingResult: res
                    }));
                }}
                loading={ls.processing}
                active={state.selectedProject === e.id}
                activeAppendix={generateProjectListItemAppendix(e)}
                hoverAppendix={generateProjectListItemAppendix(e)}
            />


            {/*
            <TransitionGroup children={ project?.resources !== undefined && (
                <Collapse children={
                    mapToTreeListElements(project.resources.slice(0, 2), (x, i) => (
                        <ListItem
                            title={`${i}`}
                            onClick={() => api.selectImageByID(x)}
                            active={state.selectedImageId === x}
                        />
                    ))
                }/>
            ) }/>
            */}


            {/*
            <TransitionGroup children={ project?.resources !== undefined && (
                <Collapse children={
                    mapToTreeListElements(subElements?.filter(sI => sI.favourite) ?? [], (x, i) => (
                        <ListItem
                            title={`${i}`}
                            onClick={() => api.selectImageByID(x.id)}
                            active={state.selectedImageId === x.id}
                        />
                    ))
                }/>
            ) }/>
            */}

            {/*
            <TransitionGroup children={ project?.resources !== undefined && (
                <Collapse children={
                    mapToTreeListElements(ls.subItems.filter(sI => sI.favourite) ?? [], (x, i) => (
                        <ListItem
                            title={`${i}`}
                            onClick={() => api.selectImageByID(x.id)}
                            active={state.selectedImageId === x.id}
                        />
                    ))
                }/>
            ) }/>
            */}


            {/*
            <div style={{
                display: "flex",
                width: "100%",
                overflowX: "scroll",
                padding: "4px 0"
            }}>
                <div style={{
                    display: "flex",
                    gap: 2,
                    width: "min-content"
                }}>
                    {
                        ls.subItems.filter(sI => sI.favourite).map(img => {
                            const isSelected = state.selectedImageId === img.id;
                            return (
                                <ISAImage imageID={img.id} isaTable={"images"} style={{
                                    height: 32,
                                    aspectRatio: "1 / 1",
                                    borderRadius: 4,
                                    cursor: "pointer",
                                    border: isSelected ? `1px solid ${t.col("color_secondary")}` : "none"
                                }} onClick={() => {
                                    api.selectImageByID(img.id)
                                }}/>
                            );
                        })
                    }
                </div>
            </div>
            */}



            {
                // mapToTreeListElements(ls.subItems.filter(sI => sI.favourite) ?? [], (x, i) => (
                //     <ListItem
                //         title={`${i}`}
                //         onClick={() => api.selectImageByID(x.id)}
                //         active={state.selectedImageId === x.id}
                //     />
                // ), lE => (
                //     <Collapse in children={lE}/>
                // ))
            }
        </>
    );
}
