import {APIShard} from "../APIShard";
import {StableDiffusionAttachment} from "./attachments/StableDiffusionAttachment";
import {IImage} from "../IImage";
import {StableDiffusionAttachmentRetriever} from "./StableDiffusionAttachmentRetriever";

export class PNGPropertiesManager extends APIShard {

    public async getStableDiffusionAttachment(image: IImage): Promise<StableDiffusionAttachment> {
        const retriever = new StableDiffusionAttachmentRetriever(await image.src());
        return await retriever.retrieveMetaData();
    }
}
