import React from "react";
import {LogLevel} from "./LogLevel";
import {ColorableTag} from "../../components/ColorableTag";
import {Color} from "../../../base/logic/style/Color";

export type LogLevelIndicatorProps = {
    level: LogLevel
}

export const LogLevelIndicator: React.FC<LogLevelIndicatorProps> = props => {
    switch (props.level) {
        case LogLevel.TRACE:
            return (
                <ColorableTag c={Color.ofHex("#7e4ae7")} tag={`${props.level}`}/>
            );
        case LogLevel.DEBUG:
            return (
                <ColorableTag c={Color.ofHex("#9f7edc")} tag={`${props.level}`}/>
            );
        case LogLevel.INFO:
            return (
                <ColorableTag c={Color.ofHex("#60ffc7")} tag={`${props.level}`}/>
            );
        case LogLevel.WARN:
            return (
                <ColorableTag c={Color.ofHex("#ffdf60")} tag={`${props.level}`}/>
            );
        case LogLevel.ERROR:
            return (
                <ColorableTag c={Color.ofHex("#f14234")} tag={`${props.level}`}/>
            );
    }
}
