import React from "react";
import {useDisk} from "../webapi/fs/hooks/UseDiskHook";
import {ButtonBase} from "../../triton/components/buttons/ButtonBase";
import {FlatIconButton} from "./FlatIconButton";
import {ArrowBackRounded, HomeRounded, RefreshRounded, StorageRounded} from "@mui/icons-material";
import {DefaultButton} from "./DefaultButton";
import {IconButton} from "./IconButton";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {BreadcrumbsNavigation} from "./breadcrumbs/BreadcrumbsNavigation";
import {MainTypography} from "../../triton/components/typography/MainTypography";

export const FSPathDisplay: React.FC = props => {
    const [disk] = useDisk();
    const path = disk.state.state.currentPath;
    const currentFolder = path.path.length === 0 ? "/" : path.path[path.path.length - 1];

    return (
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 4,
        }}>
            <div style={{
                width: "100%",
                display: "flex",
                gap: 2,
            }}>
                <FlatIconButton
                    children={<RefreshRounded fontSize={"small"}/>}
                    tooltip={"Reload"}
                    onClick={() => disk.reloadLSSnapshot()}
                />

                <FlatIconButton
                    children={<ArrowBackRounded fontSize={"small"}/>}
                    deactivated={disk.isAtRootLevel()}
                    onClick={() => disk.cdUp(1)}
                />
            </div>

            <BreadcrumbsNavigation
                showHome={true}
                goHome={() => disk.cdRoot()}
                isAtHome={disk.isAtRootLevel()}
                path={path.path}
                cd={path => disk.cd(...path)}
            />

            <MainTypography text={currentFolder} noSelect/>
        </div>
    );
}
