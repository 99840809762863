import {RGBValue} from "./RGBValue";
import {HSVToRGBUtils} from "./HSVToRGBUtils";

export namespace RGBUtils {

    export function createRandomRGBValues(saturation: number = 1, value: number = 1): RGBValue {
        const hue = Math.random();
        return HSVToRGBUtils.HSVtoRGB(hue, saturation, value);
    }
}
