import React, {PropsWithChildren, useContext, useRef, useState} from "react";
import {CarbideTestComponent} from "../CarbideTestComponent";
import {CarbideFrontendAPIContext} from "./CarbideFrontendAPIContext";
import {CarbideFrontendStateContext, CarbideFrontendStateContextType} from "./CarbideFrontendStateContext";
import {CarbideFrontendState} from "./CarbideFrontendState";
import {CarbideFrontendAPI} from "./CarbideFrontendAPI";

const createInitialCarbideFrontendState = (): CarbideFrontendState => ({

});

export const CarbideFrontendAPIBridge: React.FC<PropsWithChildren> = props => {
    const api = useContext(CarbideFrontendAPIContext);
    const stateCtx = useContext(CarbideFrontendStateContext);
    api.updateCarbideFrontendStateContextType(stateCtx);

    return (
        <>{ props.children }</>
    );
}

export const CarbideFrontendMain: React.FC = props => {
    const [state, setState] = useState<CarbideFrontendState>(createInitialCarbideFrontendState());
    const stateCtx: CarbideFrontendStateContextType = { state: state, setState: setState };
    const apiRef = useRef<{ api: CarbideFrontendAPI }>({
        api: new CarbideFrontendAPI()
    });

    return (
        <CarbideFrontendStateContext.Provider value={stateCtx} children={
            <CarbideFrontendAPIContext.Provider value={apiRef.current.api} children={
                <CarbideFrontendAPIBridge children={
                    <CarbideTestComponent/>
                }/>
            }/>
        }/>
    );
}


