import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const AbsoluteNumeric: NodeSetupInfo = {
    label: "AbsoluteNumeric",
    classname: "arithmetic.abs",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "arithmetic.abs",
        label: "abs",
        defOutPins: ["u"],
        defInPins: ["s"],
        init: function () {
            this.createInternalWire({
                targetInPin: "s",
                targetOutPin: "u",
                transformer: s => Math.abs(Number(s))
            })
        }
    })
}
