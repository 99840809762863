import React, {useMemo, useState} from "react";
import {PromptRendererBaseProps} from "./PromptRendererBaseProps";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {ClipboardCopyButton} from "../ClipboardCopyButton";
import {PromptChipRenderer} from "./PromptChipRenderer";
import styled from "styled-components";
import {PromptRenderMethod} from "./PromptRenderMethod";
import {PromptTextRenderer} from "./PromptTextRenderer";
import {PromptCodeRenderer} from "./PromptCodeRenderer";
import {FlatIconButton} from "../FlatIconButton";
import {
    CodeRounded,
    EmailRounded,
    LinkRounded,
    MoreHorizRounded, QuestionMarkRounded,
    TagRounded,
    TextSnippetRounded, TokenRounded
} from "@mui/icons-material";
import {MenuButton} from "../MenuButton";
import {Menu} from "../Menu";
import {useTriton} from "../../../triton/TritonHooks";
import {useStaticState} from "../../hooks/StaticStateHook";
import Collapse from "@mui/material/Collapse";
import {TransitionGroup} from "react-transition-group";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {Tag} from "../Tag";
import {ColorableTag} from "../ColorableTag";
import {ofHex} from "../../../base/logic/style/Color";
import {ButtonGroup} from "../ButtonGroup";
import {FormDivider} from "../../../triton/components/forms/FormDivider";
import {Link} from "react-router-dom";
import {BooleanContext} from "../../../triton/components/BooleanContext";

const StyledPromptRenderer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export type PromptRendererProps = PromptRendererBaseProps & {
    title: string,
    rendererName?: string,

    toolbarAppendix?: () => React.ReactNode
}

export type PromptRendererState = {
    renderMethod: PromptRenderMethod
}

export const PromptRenderer: React.FC<PromptRendererProps> = props => {
    const t = useTriton();

    const [state, ctx] = useStaticState<PromptRendererState>({
        id: `prompt-renderer-${props.rendererName}`,
        staticMode: props.rendererName !== undefined,
        initial: {
            renderMethod: PromptRenderMethod.CHIPS
        }
    }).stateWithCtx;

    function renderPrompt(): React.ReactNode {
        switch (state.renderMethod) {
            case PromptRenderMethod.RAW:
                return (
                    <PromptTextRenderer {...props}/>
                );
            case PromptRenderMethod.CODE:
                return (
                    <PromptCodeRenderer {...props}/>
                );
            case PromptRenderMethod.CHIPS:
                return (
                    <PromptChipRenderer {...props}/>
                );
        }
    }

    function switchRenderMethod(method: PromptRenderMethod) {
        ctx.update({
            renderMethod: method
        });
    }

    const promptLen = props.originalPrompt.length;
    const promptWordCount = useMemo(
        () => props.originalPrompt.split(/\s/).length,
        [props.originalPrompt]
    );

    const tokens = useMemo(() => {
        // TODO: Use better tokenized...
        return props.originalPrompt.split(/\s/);
    }, [props.originalPrompt]);

    const chunkCount = useMemo(() => {
        let chunks = 1, currentChunkTokenCount = 0;
        const breakTokenText = "BREAK";
        const CLIPTokenizerChunkLimit = 75;
        const submitChunk = () => {
            chunks++;
            currentChunkTokenCount = 0;
        }
        tokens.forEach(token => {
            if (token === breakTokenText) {
                submitChunk();
                return;
            }
            currentChunkTokenCount++;
            if (currentChunkTokenCount > CLIPTokenizerChunkLimit) {
                submitChunk();
            }
        })
        return chunks;
    }, [tokens]);

    return (
        <StyledPromptRenderer>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                <MainTypography noSelect text={props.title}/>

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2
                }}>

                    <ButtonGroup>
                        <ColorableTag
                            tag={`${promptLen}`}
                        />
                        <ColorableTag
                            tag={`${promptWordCount}`}
                        />
                    </ButtonGroup>

                    <DescriptiveTypography text={"-"}/>

                    <Menu opener={
                        <ColorableTag
                            tag={`${chunkCount}`}
                            c={t.colObj("color_secondary")}
                        />
                    } children={
                        <>
                            <MenuButton keepOpenOnClick text={
                                `CLIP-Tokenizer estimate`
                            } icon={
                                <TokenRounded/>
                            } appendix={
                                <ColorableTag
                                    tag={`${chunkCount} chunk${chunkCount > 1 ? "s" : ""}`}
                                />
                            }/>

                            <DescriptiveTypography text={
                                `(CLIP tokenizer chunk count estimated at ${chunkCount} chunk${chunkCount > 1 ? "s" : ""})`
                            } style={{
                                width: "300px",
                                textAlign: "center",
                                marginBottom: 16
                            }}/>

                            <BooleanContext children={(expanded, setExpanded) => (
                                <>
                                    <MenuButton keepOpenOnClick text={
                                        `What are chunks + BREAK keyword?`
                                    } icon={
                                        <QuestionMarkRounded/>
                                    } onSelect={() => setExpanded(prevState => !prevState)}/>

                                    <TransitionGroup children={expanded && (
                                        <Collapse children={
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 8
                                            }}>
                                                <FormDivider title={"What are chunks"} paddingVertical={4}/>
                                                <DescriptiveTypography style={{ maxWidth: "300px" }} text={
                                                    `If a prompt contains more than 75 tokens, the limit of the CLIP tokenizer, 
                                                it will start a new chunk of another 75 tokens, so the new “limit” becomes 150. 
                                                The process can continue forever or until your computer runs out of memory.`
                                                }/>
                                                <a href={"https://stable-diffusion-art.com/prompt-guide/"} children={
                                                    <MenuButton keepOpenOnClick text={
                                                        `SD prompt: a definitive guide`
                                                    } icon={
                                                        <LinkRounded/>
                                                    }/>
                                                }/>

                                                <FormDivider title={"BREAK keyword"} paddingVertical={4}/>
                                                <DescriptiveTypography style={{ maxWidth: "300px" }} text={
                                                    `Sometimes you want to do that because the token in the beginning of a chunk can be more effective, 
                                                and you may want to group related keywords in a chunk.`
                                                }/>
                                                <a href={"https://github.com/AUTOMATIC1111/stable-diffusion-webui/wiki/Features#break-keyword"} children={
                                                    <MenuButton keepOpenOnClick text={
                                                        `SDWUI Wiki: BREAK keyword`
                                                    } icon={
                                                        <LinkRounded/>
                                                    }/>
                                                }/>
                                            </div>
                                        }/>
                                    )}/>
                                </>
                            )}/>
                        </>
                    }/>

                    <Menu
                        opener={
                            <FlatIconButton
                                children={<MoreHorizRounded fontSize={"small"}/>}
                            />
                        }
                        children={<>
                            <FlatIconButton
                                active={state.renderMethod === PromptRenderMethod.RAW}
                                children={<TextSnippetRounded fontSize={"small"}/>}
                                onClick={() => switchRenderMethod(PromptRenderMethod.RAW)}
                            />
                            <FlatIconButton
                                active={state.renderMethod === PromptRenderMethod.CHIPS}
                                children={<TagRounded fontSize={"small"}/>}
                                onClick={() => switchRenderMethod(PromptRenderMethod.CHIPS)}
                            />
                            <FlatIconButton
                                active={state.renderMethod === PromptRenderMethod.CODE}
                                children={<CodeRounded fontSize={"small"}/>}
                                onClick={() => switchRenderMethod(PromptRenderMethod.CODE)}
                            />
                        </>}
                    />

                    <FlatIconButton tooltip={"Copy prompt"} children={
                        <ClipboardCopyButton
                            color={t.col("fg_muted")}
                            copyValueProducer={() => props.originalPrompt}
                        />}
                    />

                    <TransitionGroup children={
                        props.toolbarAppendix && (
                            <Collapse orientation={"horizontal"} children={props.toolbarAppendix()}/>
                        )
                    }/>
                </div>
            </div>

            { renderPrompt() }
        </StyledPromptRenderer>
    );
}
