import React from "react";
import {ClutchIcon} from "./ClutchIcon";
import styled from "styled-components";
import {StyledControlDisplayGroup} from "../../components/StyledControlDisplayGroup";
import {Interpolate} from "../../../../triton/components/advanced/Interpolate";
import {CoreHeatState} from "../engine/CoreHeatState";
import {LocalFireDepartmentRounded, SettingsBackupRestore, SportsMotorsportsRounded} from "@mui/icons-material";
import {Entity} from "../../ecs/entity/Entity";

const StyledClutchControlDisplay = styled.div`
  // width: calc(60px + 2 * 8px);
  
  .clutch-visualizer {
    height: 100%;
    aspect-ratio: 1 / 1;
  }
`;

export type ClutchControlDisplayProps = {
    entity: Entity
}

export const ClutchControlDisplay: React.FC<ClutchControlDisplayProps> = props => {
    const clutch = props.entity;
    const s = clutch?.state ?? {};
    const engaged = s.engaged ?? false;
    const ksuI = s.ksuI ?? 0;
    const ksuO = s.ksuO ?? 0;

    return (
        <StyledClutchControlDisplay>
            <StyledControlDisplayGroup style={{
                gridTemplateColumns: "repeat(2, 60px)",
                gap: 8
            }}>
                <StyledControlDisplayGroup style={{
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 4
                }}>
                    <SettingsBackupRestore sx={{
                        color: "rgba(201, 209, 217, 0.1)",
                        transition: "color 1s",
                        fontSize: 16
                    }}/>
                    <Interpolate value={ksuI}/>

                    <SettingsBackupRestore sx={{
                        color: engaged ? "#60ffc7" : "rgba(201, 209, 217, 0.1)",
                        transition: "color 1s",
                        fontSize: 16
                    }}/>
                    <Interpolate value={ksuO}/>
                </StyledControlDisplayGroup>

                <div className={"clutch-visualizer"} children={
                    <ClutchIcon entity={clutch}/>
                }/>
            </StyledControlDisplayGroup>
        </StyledClutchControlDisplay>
    );
}
