import React, {PropsWithChildren} from "react";
import styled from "styled-components";

const StyledRoundedButton = styled.button`
  width: 100%;
  min-height: 46px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 500px;
  cursor: pointer;
  user-select: none;
  transition: background-color, filter 100ms;
  -webkit-appearance: none;
  padding-left: 22px;
  padding-right: 22px;
  color: black;
  opacity: 1;
  
  &:active {
    opacity: 1 !important;
    filter: brightness(.95);
    background-color: white;
  }

  &:focus {
    opacity: 1 !important;
    filter: brightness(.95);
    background-color: white;
  }
`;

export type RoundedButtonProps = PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement> & {
}>

export const RoundedButton: React.FC<RoundedButtonProps> = props => {
    return (
        <StyledRoundedButton {...props}/>
    );
}
