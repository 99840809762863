import {Variable} from "./Variable";
import {v4} from "uuid";
import {VariableIdentifier} from "./VariableIdentifier";

export class VariableFrame {

    constructor(
        public readonly name: string = v4(),
        public readonly variables: Map<string, Variable> = new Map<string, Variable>(),
    ) {}

    /**
     * todo: better VariableIdentifier~string check
     * @param id
     */
    public getVariable(id: VariableIdentifier): Variable | undefined {
        return this.variables.get(id.name);
    }

    public set(name: string, value?: any): Variable {
        return this.setVariable({
            readonly: false,
            name: name,
            value: value
        });
    }

    public setVariable(variable: Variable): Variable {
        this.variables.set(variable.name, variable);
        return variable;
    }
}
