// export const backendGateURL = "http://2.59.135.242:25564"
export const backendGateURL = "192.168.178.22:8080"

export function arcURL(url: string, protocol: string = "http"): string {
    return `${protocol}://${backendGateURL}/${url}`
}

export function arcSocketURL(url: string): string {
    return arcURL(url, "ws");
}
