import React from "react";
import {FormDivider} from "../../../triton/components/forms/FormDivider";
import {ButtonModalCompound} from "../ButtonModalCompound";
import {MenuButton} from "../MenuButton";
import {SecurityRounded, TagRounded, VisibilityOffRounded, VisibilityRounded} from "@mui/icons-material";
import {ColorableTag} from "../ColorableTag";
import {Color, ofHex} from "../../../base/logic/style/Color";
import {TopicSelectorMain} from "../../tools/topicSelector/TopicSelectorMain";
import styled from "styled-components";
import {useStaticState} from "../../hooks/StaticStateHook";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {MenuDivider} from "@szhsin/react-menu";
import {webDB} from "../../webapi/WebAPIDB";

const StyledPowerActionMenuSection = styled.div`
`;

export type PowerActionMenuSectionState = {
    unlocked: boolean,
    showPowerActions: boolean
}

export const PowerActionMenuSection: React.FC = props => {

    const [state, ctx] = useStaticState<PowerActionMenuSectionState>({
        id: "PowerActionMenuSection",
        initial: {
            unlocked: false,
            showPowerActions: false
        }
    }).stateWithCtx;

    const unlock = () => {
        ctx.update({
            unlocked: true
        });
    }

    const toggleActionLatch = () => {
        ctx.update(prevState => ({
            unlocked: !prevState.unlocked
        }));
    }

    const toggleActionVisibility = () => {
        const isCurrentlyShowing = state.showPowerActions;
        if (isCurrentlyShowing) {
            ctx.update({
                showPowerActions: false,
                unlocked: false
            });
        } else {
            ctx.update({
                showPowerActions: true
            });
        }
    }

    return (
        <StyledPowerActionMenuSection>
            <FormDivider title={"Operational level"} paddingVertical={8}/>

            {/*
            <ButtonModalCompound
                button={
                    <MenuButton
                        icon={<SecurityRounded style={{
                            color: state.unlocked ? Color.ofHex("#f14234") : undefined
                        }}/>}
                        text={"Power action latch"}
                        appendix={
                            <ColorableTag
                                tag={state.unlocked ? "active" : "latched"}
                                c={state.unlocked ? Color.ofHex("#f14234") : undefined}
                            />
                        }
                        onSelect={() => {
                            unlock()
                        }}
                    />
                }
                modalContent={ctx => (
                    <TopicSelectorMain onClose={ctx.close}/>
                )}
            />
            */}

            <MenuButton
                keepOpenOnClick
                icon={state.showPowerActions ? <VisibilityOffRounded/> : <VisibilityRounded/>}
                text={state.showPowerActions ? "Hide power actions" : "Show power actions"}
                onSelect={() => {
                    toggleActionVisibility()
                }}
            />



            <TransitionGroup children={state.showPowerActions && (
                <Collapse children={
                    <div style={{
                        width: "100%",
                        display: "grid",
                    }}>

                        <FormDivider title={"Power actions"} paddingVertical={8}/>

                        <MenuButton
                            keepOpenOnClick
                            icon={<SecurityRounded style={{
                                color: state.unlocked ? "#f14234" : undefined
                            }}/>}
                            text={"Power action latch"}
                            appendix={
                                <ColorableTag
                                    tag={state.unlocked ? "active" : "latched"}
                                    c={state.unlocked ? Color.ofHex("#f14234") : undefined}
                                />
                            }
                            onSelect={() => {
                                toggleActionLatch()
                            }}
                        />

                        <div>
                            <MenuButton
                                icon={<TagRounded/>}
                                disabled={!state.unlocked}
                                text={"Clear local storage"}
                            />
                            <MenuButton
                                icon={<TagRounded/>}
                                disabled={!state.unlocked}
                                text={"Clear dexie database"}
                                onSelect={() => {
                                    webDB.tables.forEach(table => {
                                        table.clear()
                                    });
                                }}
                            />
                        </div>

                        <TransitionGroup children={state.unlocked && (
                            <Collapse children={
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "8px"
                                }}>
                                    <DescriptiveTypography noSelect text={
                                        "All safety latches are inactive. Power actions will be performed without without protections & alerts."
                                    } style={{
                                        textAlign: "center",
                                        maxWidth: "300px"
                                    }}/>
                                </div>
                            }/>
                        )}/>
                    </div>
                }/>
            )}/>
        </StyledPowerActionMenuSection>
    );
}
