import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {Color} from "../../base/logic/style/Color";

const StyledCompassAnnotation = styled.div`
  position: absolute;
  height: 100%;
  
  .annotation-container {
    * {
      font-family: monospace;
      font-size: 10px;
    }
  }
`;

export type CompassAnnotationProps = PropsWithChildren<{
    rotation: number,
    bgColor?: Color,
    disableAnimation?: boolean
}>

export const CompassAnnotation: React.FC<CompassAnnotationProps> = props => {
    return (
        <StyledCompassAnnotation style={{
            transition: props.disableAnimation ? "none" : "rotate 1s ease-in-out",
            rotate: `${-(props.rotation)}deg`
        }} children={
            <div className={"annotation-container"} style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <div style={{
                    // width: 5,
                    // aspectRatio: "1 / 1",
                    // borderRadius: "50%",

                    width: 2,
                    height: 9,
                    borderRadius: 2,

                    marginTop: 5,
                    transition: "all .5s ease-in-out",
                    backgroundColor: props.bgColor?.css() ?? "#ffdf60"
                }}/>
                { props.children }
            </div>
        }/>
    );
}
