import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import _ from "lodash";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {ButtonGroup} from "../../../../ardai/components/ButtonGroup";
import {Tag} from "../../../../ardai/components/Tag";
import {PinMode} from "../../../backend/Pin";

export const BitFieldArray: NodeSetupInfo = {
    label: "",
    classname: "input.bit-field-array-input-debug",
    parameterConfig: [],
    factory: parameters => {
        const wordLen = parameters.get("word-len") ?? 8;
        const blockLen = parameters.get("block-len") ?? 8;
        const synchronous = parameters.get("synchronous") ?? false;

        let blockUpdatePusher: ((blockId: string, block: number) => void) | undefined = undefined;

        return new Node<{
            blocks: number[]
        }>({
            id: v4(),
            classname: "input.bit-field-array-input-debug",
            label: `${wordLen}x${blockLen}-Bit ${synchronous ? "S" : ""}SRAM block`,
            description: "direct-access",
            state: {
                blocks: _.range(blockLen).map(() => 0b0)
            },
            init: function () {
                const readBank = this.pins.createPinBank("read", {
                    label: "read",
                    defaultPinMode: PinMode.OUT,
                    groups: ["output"]
                });

                blockUpdatePusher = (blockId, block) => {
                    // this.pins.out(`${blockId}`).write(block);

                    readBank.pin(`${blockId}`).write(block);
                };

                for (let blockId = 0; blockId < blockLen; blockId++) {
                    // this.pins.out(`${blockId}`);
                    readBank.pin(`${blockId}`);
                }

                const writeBank = this.pins.createPinBank("write", {
                    label: "write"
                });

                for (let blockId = 0; blockId < blockLen; blockId++) {
                    writeBank.pin(`${blockId}`).attach({
                        read(block: number) {
                            this.node.state.update(prevState => {
                                const newBlocks = prevState.blocks;
                                newBlocks[blockId] = block;
                                blockUpdatePusher?.(`${blockId}`, block)
                                return {
                                    blocks: newBlocks
                                }
                            });
                        }
                    })
                }

            },
            customRenderer: node => (
                <div style={{
                    padding: "8px 0",
                    gap: 4,
                    display: "grid",
                    gridTemplateColumns: "repeat(2, min-content)"
                }}>
                    <span/>

                    <div style={{
                        width: "100%",
                        display: "grid",
                        justifyContent: "center",
                        alignItems: "center",
                        gridTemplateColumns: `repeat(${wordLen}, 1fr)`
                    }} children={
                        _.range(0, wordLen).map(idx => {
                            return (
                                <DescriptiveTypography noSelect text={`${1 << idx}`} style={{
                                    whiteSpace: "nowrap",
                                    fontSize: 8,
                                    textAlign: "center"
                                }}/>
                            );
                        }).reverse()
                    }/>
                    { node.state.state.blocks.map((block, blockId) => (
                        <>
                            <div style={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <DescriptiveTypography noSelect text={`${blockId}`} style={{
                                    whiteSpace: "nowrap",
                                    fontSize: 8,
                                    textAlign: "right"
                                }}/>
                            </div>

                            <ButtonGroup children={
                                _.range(0, wordLen).map(idx => {
                                    const bit = block >> idx & 0x1;
                                    return (
                                        <Tag tag={`${bit}`} active={Boolean(bit)} onClick={() => {
                                            node.state.update(prevState => {
                                                let block = prevState.blocks[blockId];
                                                block = block ^ (1 << idx);
                                                const newBlocks = prevState.blocks;
                                                newBlocks[blockId] = block;
                                                blockUpdatePusher?.(`${blockId}`, block)
                                                return {
                                                    blocks: newBlocks
                                                }
                                            });
                                        }}/>
                                    );
                                }).reverse()
                            }/>
                        </>
                    )) }
                </div>
            )
        })
    }
}
