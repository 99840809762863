import {VariablePool} from "./VariablePool";
import {Std} from "../../../../Std";
import also = Std.also;
import {Variable} from "./Variable";
import {ASTNode} from "./ASTNode";

export class Program {

    constructor(
        code: ASTNode
    ) {}

    public static test() {

        const pool = new VariablePool;

        also(pool.pushFrame(), function () {
            this.set("a", 7);
            this.set("b", 2);
            this.set("c");
        });

        const ctx = {
            set: (name: string, value?: any) => {
                pool.getVariableByName(name)!.value = value;
            }
        }

        const fnBody = `this.set("c", "Hello Gaia!")`;

        const flatFrame = Array.from(pool.generateFlatVariableFrame().variables.values());
        const compiled = new Function(...flatFrame.map(v => v.name), fnBody);
        const thisCtx = ctx;
        const result = compiled.call(thisCtx, ...flatFrame.map(v => v.value));

        console.log(result);
        console.log(pool.getVariableByName("c"))
    }
}
