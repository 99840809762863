import {NodeSetupInfo} from "../NodeSetupInfo";
import {Node} from "../../backend/Node";
import {v4} from "uuid";
import {Tag} from "../../../ardai/components/Tag";

export const Switch: NodeSetupInfo = {
    label: "",
    classname: "logic.switch-debug",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "logic.switch-debug",
        label: "switch",
        defInPins: ["i"],
        defOutPins: ["o"],
        state: {
            pass: true
        },
        init() {
            this.createInternalWire({
                targetInPin: "i",
                targetOutPin: "o",
                filter() {
                    return this.state.state.pass
                }
            })
        },
        customRenderer: node => (
            <div style={{
                display: "flex",
                gap: 4,
                flexDirection: "column",
                padding: 0,
                alignItems: "center",
                height: "100%",
                justifyContent: "center"
            }}>
                <Tag
                    tag={"Pass"}
                    active={node.state.state.pass}
                    onClick={() => node.state.reverseBool("pass")}
                />
            </div>
        )
    })
}
