export class SRCReader {

    public static readonly EOF = '';

    private readonly code: string

    private cursor: number;

    constructor(code: string) {
        this.code = code;
        this.cursor = 0;
    }

    public also(block: (r: SRCReader) => void): SRCReader {
        block(this);
        return this;
    }

    public hasNext(len: number = 1): boolean {
        return (this.cursor + len) <= this.code.length;
    }

    public peek(len: number = 1): string {
        return this.code.substring(this.cursor, this.cursor + len);
    }

    public read(len: number = 1): string {
        const s = this.code.substring(this.cursor, this.cursor + len);
        this.cursor += len;
        return s;
    }

    public skipWhitespace(): SRCReader {
        const whitespaceRegex = /\s/;
        while (this.hasNext()) {
            const next = this.peek();
            if (whitespaceRegex.test(next)) {
                this.read();
            } else {
                break;
            }
        }
        return this;
    }

    /**
     * Positive lookahead
     *
     * FIXME: This affects the cursor.. why tho..
     *
     * @param takeIt
     */
    public pLookAhead(takeIt: (s: string) => boolean): string {
        let buf = "";
        while (true) {
            const s = this.read();
            if (s === SRCReader.EOF) break;
            if (takeIt(s)) {
                buf += s;
            } else {
                return buf;
            }
        }
        return buf;
    }

    /**
     * Negative lookahead
     *
     * FIXME: This affects the cursor.. why tho..
     *
     * @param stopIf
     */
    public nLookAhead(stopIf: (s: string) => boolean): string {
        let buf = "";
        while (this.cursor < this.code.length) {
            const s = this.read();
            if (stopIf(s)) {
                this.cursor--;
                break;
            } else {
                buf += s;
            }
        }
        return buf;
    }

    public readUntilNextClosingToken(tokens: [string, string] = ["(", ")"]): string {
        const [opToken, clToken] = tokens;
        let stack = 0;
        let buffer = "";

        while (true) {
            const char = this.read();
            if (char === SRCReader.EOF) break;
            if (char === opToken) stack++;
            if (char === clToken) {
                stack--;
                if (stack < 0) {
                    break;
                }
            }
            buffer += char;
        }

        return buffer;
    }

    public remainder(): string {
        return this.code.slice(this.cursor);
    }
}
