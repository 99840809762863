import {as} from "../../../../../atlas/Lang";
import {TabConfig} from "../TabConfig";
import {AzypodControlPanel} from "../../../entities/azypod/AzypodControlPanel";
import {ShipMovementPanel} from "../../../entities/ship/ShipMovementPanel";
import {SteeringAPControlPanel} from "../../../entities/ap/SteeringAPControlPanel";
import {ClutchControlDisplay} from "../../../entities/clutch/ClutchControlDisplay";
import {StyledControlDisplayGroup} from "../../../components/StyledControlDisplayGroup";
import {Speedometer} from "../../../../../test/engine/Speedometer";
import {Orientation} from "../../../../../base/logic/style/Orientation";
import {percent} from "../../../../../base/logic/style/DimensionalMeasured";
import {CoreControlDisplay} from "../../../entities/engine/CoreControlDisplay";
import {MultiplexedCoreControlPanel} from "../../../entities/engine/MultiplexedCoreControlPanel";
import {EngineMasterControlDisplay} from "../../../entities/engine/EngineMasterControlDisplay";
import {ValveControlDisplay} from "../../../entities/valve/ValveControlDisplay";
import React from "react";

export const steeringTab = as<TabConfig>({
    id: "steering",
    staticTabName: "Steering",
    renderer: ctx => {
        const sim = ctx.sim;
        return (
            <div style={{ display: "flex", flexDirection: "row", gap: "8px", justifyContent: "center", alignItems: "center" }}>
                <div style={{ gap: 8, display: "grid", gridTemplateColumns: "1fr" }}>
                    <AzypodControlPanel
                        entity={as<any>(
                            sim.entity("port-azypod")
                        )}
                    />
                </div>

                <div style={{ display: "flex", flexDirection: "column", gap: "8px", justifyContent: "center", alignItems: "center" }}>
                    <ShipMovementPanel/>

                    <SteeringAPControlPanel/>
                </div>


                <div style={{ gap: 8, display: "grid", gridTemplateColumns: "1fr" }}>
                    <AzypodControlPanel
                        entity={as<any>(
                            sim.entity("port-azypod")
                        )}
                    />
                </div>
            </div>
        );
    }
})
