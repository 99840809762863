import React, {useContext} from "react";
import {Formik} from "formik";
import {ArdaiAPIStateContext} from "../../ArdaiMain";
import {Grid} from "../../../triton/components/layouts/Grid";
import {ImageSelectionPreview} from "./ImageSelectionPreview";
import {StyledModal} from "../StyledModal";
import {px, vw} from "../../../base/logic/style/DimensionalMeasured";
import {AddRounded, BugReportRounded, KeyboardReturnRounded, SortRounded} from "@mui/icons-material";
import {DefaultButton} from "../DefaultButton";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {Workspace} from "../Workspace";
import {KeyFolderLinkHint} from "./KeyFolderLinkHint";
import {Separator} from "../../../base/components/base/Separator";
import {Orientation} from "../../../base/logic/style/Orientation";
import {ColorableTag} from "../ColorableTag";
import {Color} from "../../../base/logic/style/Color";
import {Tag} from "../Tag";
import {ImageSelectionOutputCard} from "./ImageSelectionOutputCard";
import {Menu} from "../Menu";
import {IconButton} from "../IconButton";
import {ButtonModalCompound} from "../ButtonModalCompound";
import {AddActionDialog} from "./AddActionDialog";

export const ImageSortingSetupDialog: React.FC = props => {
    const state = useContext(ArdaiAPIStateContext);

    return (
        <Formik initialValues={{}} onSubmit={values => {}} children={fp => (
            <StyledModal w={vw(50)} title={"Prepare image sorting"} icon={<SortRounded/>}>

                <div style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "1rem"
                    }}>
                        <KeyFolderLinkHint keyHint={"0"} folderID={""} footer={
                            <ColorableTag highlightOnHover={false} tag={"Primary"} c={Color.ofHex("#ffdf60")}/>
                        }/>

                        <div style={{
                            height: "50px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Separator orientation={Orientation.VERTICAL} style={{
                                backgroundColor: "white",
                                opacity: .6
                            }}/>
                        </div>

                        <KeyFolderLinkHint folderName={"asd d"} folderID={""} keyHint={1}/>
                        <KeyFolderLinkHint folderName={"ad"} folderID={""} keyHint={2}/>
                        <KeyFolderLinkHint folderName={"asdd dwef"} folderID={""} keyHint={3}/>
                        <KeyFolderLinkHint folderName={"asdd"} folderID={""} keyHint={4}/>
                        <KeyFolderLinkHint folderName={"ad"} folderID={""} keyHint={5}/>

                        <div style={{
                            height: "50px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Separator orientation={Orientation.VERTICAL} style={{
                                backgroundColor: "white",
                                opacity: .6
                            }}/>
                        </div>

                        <KeyFolderLinkHint keyHint={","} folderID={""} footer={
                            <Tag highlightOnHover={false} tag={"#b-roll"}/>
                        }/>

                        <KeyFolderLinkHint keyHint={<KeyboardReturnRounded/>} folderID={""} footer={
                            <Tag highlightOnHover={false} tag={"skip"}/>
                        }/>
                    </div>
                </div>

                <Grid gTC={"repeat(2, 1fr)"}>
                    <ImageSelectionPreview/>
                    <Workspace config={{ name: "Settings", mode: "desktop" }} children={
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            flexDirection: "column"
                        }}>


                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "space-between"
                            }}>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px"
                                }}>
                                    <BugReportRounded/>
                                    <MainTypography text={"Sorting settings"}/>
                                </div>

                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px"
                                }}>
                                    <ButtonModalCompound button={
                                        <IconButton size={"small"} children={
                                            <AddRounded/>
                                        }/>
                                    } modalContent={ctx => (
                                        <AddActionDialog/>
                                    )}/>
                                    <Menu/>
                                </div>
                            </div>

                            <ImageSelectionOutputCard/>
                            <ImageSelectionOutputCard/>
                            <ImageSelectionOutputCard/>
                            <ImageSelectionOutputCard/>
                        </div>
                    }/>
                </Grid>

                <DefaultButton variant={"primary"} size={"small"} children={
                    <MainTypography text={"Start"}/>
                }/>
            </StyledModal>
        )}/>
    );
}
