import React, {useContext} from "react";
import {TopicSelectorStateContext} from "./TopicSelectorStateContext";
import {Formik} from "formik";
import {FormikBasicSlider} from "../../../triton/components/forms/FormikBasicSlider";
import styled from "styled-components";
import {
    CallMergeRounded,
    DownloadRounded,
    ImportExportRounded,
    SettingsRounded,
    UploadRounded
} from "@mui/icons-material";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {Menu} from "../../components/Menu";
import {FormikAdvancedFormCheckbox} from "../../components/FormikAdvancedFormCheckbox";
import {MenuDivider} from "@szhsin/react-menu";
import {FormDivider} from "../../../triton/components/forms/FormDivider";
import {IconButton} from "../../components/IconButton";
import {FormikSingleLineInput} from "../../../triton/components/forms/FormikSingleLineInput";
import {MenuButton} from "../../components/MenuButton";
import {FileInput} from "../../../base/components/base/fileInput/FileInput";
import {FileInputSubmissionMode} from "../../../base/components/base/fileInput/FileInputSubmissionMode";
import fileDownload from "js-file-download";
import {ButtonModalCompound} from "../../components/ButtonModalCompound";
import {StyledModal} from "../../components/StyledModal";
import {Tag} from "../../components/Tag";

const StyledDishConfigView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  .header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    
    .header-left, .header-right {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }
`;

export const DishConfigView: React.FC = props => {
    const [state, setState] = useContext(TopicSelectorStateContext);

    function importTSConfig(s: string) {
        const config = JSON.parse(s);
        setState(config);
    }

    return (
        <Formik initialValues={{
            spiceCount: state.spiceCount,
            useSpices: state.useSpices,
            seed: state.seed,
        }} onSubmit={(values, formikHelpers) => {
            setState(prevState => {
                formikHelpers.resetForm({
                    values: {
                        spiceCount: values.spiceCount,
                        useSpices: values.useSpices,
                        seed: values.seed
                    }
                });
                return ({
                    ...prevState,
                    spiceCount: values.spiceCount,
                    useSpices: values.useSpices,
                    seed: values.seed?.trim().length === 0 ? undefined : values.seed
                })
            });
            formikHelpers.setSubmitting(false);
        }} children={fp => (
            <StyledDishConfigView>
                <div className={"header"}>
                    <div className={"header-right"}>
                        <SettingsRounded style={{ width: 18 }}/>
                        <MainTypography text={"Settings"}/>
                    </div>
                    <div className={"header-left"}>
                        <IconButton size={"small"} tooltip={"Apply changes"} variant={fp.dirty ? "primary" : "default"} children={
                            <CallMergeRounded/>
                        } onClick={() => fp.handleSubmit()}/>
                        <Menu>

                            <MenuButton text={"Export"} icon={<DownloadRounded/>} onSelect={() => {
                                const fileContent = JSON.stringify(state, null, 2);
                                fileDownload(fileContent, "topics-selection-config.json")
                            }}/>

                            <ButtonModalCompound
                                button={
                                    <MenuButton text={"Overwrite"} icon={<UploadRounded/>}/>
                                }
                                modalContent={ctx => (
                                    <StyledModal onClose={ctx.close} icon={<UploadRounded/>} title={"Import file"} headerRightAppendix={<Tag tag={"beta"}/>} children={
                                        <FileInput submissionMode={FileInputSubmissionMode.AUTO_SUBMIT} renderDetails onSubmit={ctx => {
                                            ctx.file.text().then(s => {
                                                if (ctx.dataURL) importTSConfig(s);
                                            });
                                        }}/>
                                    }/>
                                )}
                            />



                            <MenuDivider/>
                            <MenuButton text={"Discard changes"} onSelect={() => {
                                fp.resetForm({
                                    values: {
                                        spiceCount: fp.values.spiceCount,
                                        useSpices: fp.values.useSpices,
                                        seed: fp.values.seed
                                    }
                                });
                            }}/>
                        </Menu>
                    </div>
                </div>

                <FormDivider title={"Spices"}/>
                <FormikAdvancedFormCheckbox formik={fp} title={"Use additional spices"} name={"useSpices"}/>
                <FormikBasicSlider title={"Additional spice count"} fp={fp} name={"spiceCount"} minVal={1} defaultValue={1} maxVal={5}/>

                <FormDivider title={"RNG"}/>
                <FormikSingleLineInput name={"seed"} formikProps={fp} placeholder={"Empty for random seed"}/>
            </StyledDishConfigView>
        )}/>
    );
}
