import React from "react";
import {CarbideFrontendState} from "./CarbideFrontendState";
import {StateDispatcher} from "../../ship/test/core/StateDispatcher";

export type CarbideFrontendStateContextType = {
    state?: CarbideFrontendState,
    setState?: StateDispatcher<CarbideFrontendState>
}

export const CarbideFrontendStateContext = React.createContext<CarbideFrontendStateContextType>({});
