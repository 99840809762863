import {Observable} from "./Observable";
import {DataType, layers} from "@tensorflow/tfjs";
import {Observer} from "./Observer";

export class StandaloneObservable<
    // Event type
    KeyType = string,
    // Event body data type
    DataType = any
> implements Observable<KeyType, DataType> {

    private _observers: Array<Observer<KeyType, DataType>> = [];

    notify(key: KeyType, data: DataType): void {
        this.observers.forEach(observer => {
            try {
                observer.notify(key, data)
            } catch (e) {
                // TODO: Log in better way
                console.error(e)
            }
        });
    }

    observe(...keys: Array<KeyType>): Observer<KeyType, DataType> {
        const obs = new Observer<KeyType, DataType>(
            this, keys
        );
        this._observers.push(obs)
        return obs;
    }

    observeGlobally(): Observer<KeyType, DataType> {
        const obs = new Observer<KeyType, DataType>(
            this, [], {
                global: true
            }
        );
        this._observers.push(obs)
        return obs;
    }

    // relayGlobally(target: Observable<KeyType, DataType>): this {
    relayGlobally(target: Observable<any>): this {
        this.observeGlobally().on((key, data) => {
            target.notify(key, data);
        });
        return this;
    }

    get observers(): Array<Observer<KeyType>> {
        return this._observers;
    }

    unsubscribe(observer: Observer<any>): void {
        this._observers = this.observers.filter(o => o !== observer);
    }

    unsubscribeAll(): void {
        this._observers = [];
    }

}
