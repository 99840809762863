import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {FC} from "react";

export const LED: NodeSetupInfo = {
    label: "",
    classname: "visualization.led",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "visualization.led",
        defInPins: ["r", "g", "b"],
        label: "led",
        customRenderer: node => {
            const r = node.pins.in("r").lastReadState as number ?? 0;
            const g = node.pins.in("g").lastReadState as number ?? 0;
            const b = node.pins.in("b").lastReadState as number ?? 0;
            return (
                <NodeLEDDisplay
                    r={r} g={g} b={b}
                />
            );
        }
    })
}

const NodeLEDDisplay: FC<{
    r: number
    g: number
    b: number
}> = props => {
    const { r, g, b } = props;
    return (
        <div style={{
            padding: "8px 0",
            height: "100%",
            width: "100%"
        }}>
            <div style={{
                backgroundColor: `rgb(${r}, ${g}, ${b})`,
                minWidth: 32,
                height: "100%",
                borderRadius: 8
            }}/>
        </div>
    );
}
