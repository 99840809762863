export type BlendMode =
    | "color"
    | "color-burn"
    | "color-dodge"
    | "darken"
    | "difference"
    | "exclusion"
    | "hard-light"
    | "hue"
    | "lighten"
    | "luminosity"
    | "multiply"
    | "normal"
    | "overlay"
    | "saturation"
    | "screen"
    | "soft-light";

export const blendModes: Array<string> = [
    "color",
    "color-burn",
    "color-dodge",
    "darken",
    "difference",
    "exclusion",
    "hard-light",
    "hue",
    "lighten",
    "luminosity",
    "multiply",
    "normal",
    "overlay",
    "saturation",
    "screen",
    "soft-light"
]
