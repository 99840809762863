import {elementPropertiesForRadialGradient} from "framer/build/render/utils/elementPropertiesForGradient";
import {op} from "@tensorflow/tfjs";

type UnaryOperation<In1, Out1, OpCtx = any> = (x: In1, ctx: OpCtx) => Out1

type Dispatch<In1, OpCtx = any> = (x: In1, ctx: OpCtx) => void

type Dispatch2<In1, In2, OpCtx = any> = (x1: In1, x2: In2, ctx: OpCtx) => void

export interface Linkable<UnboxedType> {
    run<Out>(transformer: UnaryOperation<UnboxedType, Out>): Link<Out>
    get unboxed(): UnboxedType
}

export interface ArrayLink<In> extends Linkable<Array<In>> {
    map<Out>(transformer: UnaryOperation<In, Out>): ArrayLink<Out>

    filter(predicate: UnaryOperation<In, boolean>): ArrayLink<In>
    forEach(dispatch: Dispatch<In>): this
    accumulateAsDispatch<Acc>(accumulator: Acc, dispatch: Dispatch2<Acc, In>): Link<Acc>
}

export interface Link<In> extends Linkable<In> {
    also(dispatch: Dispatch<In>): Link<In>
}

export class LinkImpl<In> implements Link<In> {

    constructor(private operand: In) {}

    also(dispatch: Dispatch<In>): Link<In> {
        dispatch(this.operand, this);
        return this;
    }

    run<Out>(transformer: UnaryOperation<In, Out>): Link<Out> {
        return new LinkImpl(transformer(this.operand, this));
    }

    get unboxed(): In {
        return this.operand;
    }
}

export class ArrayLinkImpl<In> implements ArrayLink<In> {

    constructor(private readonly operands: Array<In>) {}

    accumulateAsDispatch<Acc>(accumulator: Acc, dispatch: Dispatch2<Acc, In>): Link<Acc> {
        this.operands.forEach(operand => dispatch(accumulator, operand, this));
        return new LinkImpl(accumulator);
    }

    forEach(dispatch: Dispatch<In>): this {
        this.operands.forEach(operand => dispatch(operand, this));
        return this;
    }

    map<Out>(transformer: UnaryOperation<In, Out>): ArrayLink<Out> {
        return new ArrayLinkImpl(this.operands.map(operand => transformer(operand, this)));
    }

    filter(predicate: UnaryOperation<In, boolean>): ArrayLink<In> {
        return new ArrayLinkImpl(this.operands.filter(operand => predicate(operand, this)));
    }

    run<Out>(transformer: UnaryOperation<Array<In>, Out>): Link<Out> {
        return new LinkImpl(transformer(this.operands, this))
    }

    get unboxed(): Array<In> {
        return this.operands;
    }
}
