import React, {useContext} from "react";
import styled from "styled-components";
import {SDInterfaceAPIContext} from "../../../imageSorter/sdInterface/SDInterfaceAPI";
import {SDInterfaceStateContext} from "../../../imageSorter/sdInterface/SDInterfaceMain";
import {CodeRounded, HistoryRounded, SettingsApplicationsRounded} from "@mui/icons-material";
import {TabBar} from "../../components/TabBar";
import {Menu} from "../../components/Menu";

const StyledMobileGenerationViewNavigation = styled.div`
  padding: 8px 16px;
  display: grid;
  grid-template-columns: auto min-content;
  background-color: rgb(6, 6, 7);
  border-radius: 20px 20px 0 0;
  
  .navigation-appendix {
    margin-left: 8px;
  }
`;

export const MobileGenerationViewNavigation: React.FC = props => {
    const sdApi = useContext(SDInterfaceAPIContext);
    const state = useContext(SDInterfaceStateContext);

    return (
        <StyledMobileGenerationViewNavigation>
            <TabBar
                activeBar={state.activeTab}
                onTabChange={tab => sdApi.setState(prevState => ({ ...prevState, activeTab: tab }))}
                tabs={[
                    { id: "main", title: "Main", icon: <CodeRounded/> },
                    { id: "prompt", title: "Prompt", icon: <CodeRounded/> },
                    { id: "config", title: "Generation config", icon: <SettingsApplicationsRounded/> },
                    { id: "history", title: "History", icon: <HistoryRounded/> },
                ]}
            />

            <div className={"navigation-appendix"} children={
                <Menu/>
            }/>


        </StyledMobileGenerationViewNavigation>
    );
}
