import {VFSElement} from "../../data/VFSElement";
import {VirtualFileDriverOpeningResult} from "./VirtualFileDriverOpeningResult";
import {VFDContext} from "./VFDContext";

export interface VirtualFileDriver {
    canOpen?: (file: VFSElement) => boolean
    open: (file: VFSElement, ctx: VFDContext) => Promise<VirtualFileDriverOpeningResult>
}

/**
 * try {
 *     ctx.api.selectProject(file.id);
 *     return ({
 *         success: false
 *     });
 * } catch (e) {
 *     return ({
 *         success: false
 *     });
 * }
 */
export const legacyProjectVFD: VirtualFileDriver = {
    canOpen: file => file.type === "project",
    // open: async (file, ctx) => {
    //     return await new Promise<any>((resolve, reject) => {
    //         setTimeout(args => {
    //             try {
    //                 ctx.api.selectProject(file.id);
    //                 resolve({
    //                     success: true
    //                 });
    //             } catch (e) {
    //                 resolve({
    //                     success: false
    //                 });
    //             }
    //         }, 0);
    //     });
    // }
    open: async (file, ctx) => {
        try {
            ctx.api.selectProject(file.id);
            return ({
                success: false
            });
        } catch (e) {
            return ({
                success: false
            });
        }
    }
}
