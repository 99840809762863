import {Observable} from "./Observable";

export type ObserverFunc<EventKey, EventData> = (
    key: EventKey,
    data: EventData,
    observer: Observer<EventKey, EventData>
) => void;

export type ObserverConfig = {
    global: boolean
}

export class Observer<EventKey = string, EventData = any> {

    private subscribers: Array<ObserverFunc<EventKey, EventData>> = []

    private readonly isGlobal: boolean;

    constructor(
        private readonly observable: Observable<EventKey, EventData>,
        public readonly keys: Array<EventKey>,

        public readonly config: Partial<ObserverConfig> = {
            global: false
        }
    ) {
        this.isGlobal = config.global ?? false;
    }

    public unsubscribe() {
        this.observable.unsubscribe(this);
    }

    private shouldHandleNotification(key: EventKey): boolean {
        if (this.isGlobal) return true;
        return this.keys.includes(key);
    }

    public notify(key: EventKey, data: EventData): void {
        if (!this.shouldHandleNotification(key)) return;

        this.subscribers.forEach(sub => {
            try {
                sub(key, data, this)
            } catch (e) {
                // TODO: Log in better way
                console.error(e)
            }
        })
    }

    public on(func: ObserverFunc<EventKey, EventData>): this {
        this.subscribers.push(func);
        return this;
    }

    /**
     * useEffect sanity check:
     * Did you check that the destructor wasn't called:
     *
     * error: <code>\`.destructor()\`</code><br/>
     * ok: <code>\`.destructor\`</code>
     */
    public get destructor(): () => void {
        return () => {
            // TODO: Log "destructor call" in debug/verbose mode
            this.unsubscribe()
        };
    }
}
