import React, {PropsWithChildren, useContext, useEffect, useRef, useState} from "react";
import {SDInterfaceState} from "./SDInterfaceState";
import {SDInterfaceAPI, SDInterfaceAPIContext} from "./SDInterfaceAPI";
import {SDInterfaceMaster} from "./SDInterfaceMaster";
import {Mobile} from "../../base/components/logic/Media";
import {SDAPIRequestData} from "./SDAPIRequestData";
import _ from "lodash";
import {ArdaiAPIContext} from "../../ardai/webapi/WebAPI";
import {useAutoSettings} from "../../ardai/hooks/SettingsHook";
import {StateDispatcher} from "../../ship/test/core/StateDispatcher";
import {SDDefaultSamplers} from "./SDDefaultSamplers";
import {SDInterfaceDynamicState} from "./SDInterfaceDynamicState";
import {IStaticStateCtx, useStaticState} from "../../ardai/hooks/StaticStateHook";

export type SDInterfaceMainProps = PropsWithChildren<{
    mobile?: React.ReactNode
}>

export type SDApiData = {
    sdApi: SDInterfaceAPI
}

export const SDInterfaceMain: React.FC<SDInterfaceMainProps> = props => {
    const api = useContext(ArdaiAPIContext);

    // Initial state -> Retrieved from the local database
    const initialRequestData = useAutoSettings<SDAPIRequestData>("SDAPIRequestData", generateBaseSDAPIRequest());

    // Local updates -> Get mixed in to the database mirror
    const deltaRequestData: React.MutableRefObject<SDAPIRequestData> = useRef<SDAPIRequestData>(generateBaseSDAPIRequest());

    // TODO: Rename to dynamic state
    const [state, stateCtx]: [SDInterfaceState, IStaticStateCtx<SDInterfaceState>] = useStaticState<SDInterfaceState>({
        id: "sd-interface-main",
        staticMode: true,
        initial: {
            phase: "default",
            activeTab: "main",
            request: generateBaseSDAPIRequest(),
            outputConfig: {
                outputTarget: undefined,
                applyTags: [],
                applyTagsEnabled: true
            }
        }
    }).stateWithCtx;

    const [dynState, setDynState] = useState<SDInterfaceDynamicState>({
        debouncedRequestSaver: _.debounce((req: SDAPIRequestData) => {
            api.settingsManager.updateSettingsObject("SDAPIRequestData", () => req).then(() => {});
        }, 2e2),
        updateRequest: delta => {
            const newRequest: SDAPIRequestData = { ...deltaRequestData.current, ...delta };
            deltaRequestData.current = newRequest;
            dynState.debouncedRequestSaver(newRequest);
        },
    })

    const sdApiData = useRef<SDApiData>({
        sdApi: new SDInterfaceAPI(stateCtx, dynState, setDynState, {
            initialRequestData: initialRequestData,
            deltaRequestData: deltaRequestData.current
        })
    });

    sdApiData.current.sdApi.updateReactStateInfo(stateCtx);
    sdApiData.current.sdApi.updateReactDynStateInfo(dynState, setDynState);

    sdApiData.current.sdApi.updateRequestContextData({
        initialRequestData: initialRequestData,
        deltaRequestData: deltaRequestData.current
    });

    const sDInterfaceRequestContextVal: SDInterfaceRequestContextData = {
        initialRequestData: initialRequestData,
        deltaRequestData: deltaRequestData.current
    };

    return (
        <SDInterfaceRequestContext.Provider value={sDInterfaceRequestContextVal} children={
            <SDInterfaceStateContext.Provider value={state} children={
                <SDInterfaceStateCtxContext.Provider value={stateCtx} children={
                    <SDInterfaceDynStateContext.Provider value={[dynState, setDynState]} children={
                        <SDInterfaceMaster children={
                            <SDInterfaceAPIContext.Provider value={sdApiData.current.sdApi} children={

                                // TODO: What is that?!
                                props.mobile === undefined ? (props.children) : (
                                    <>
                                        {/* Render mobile layout */}
                                        <Mobile children={props.mobile}/>
                                        {/* Render default layout */}
                                        <Mobile children={props.children}/>
                                    </>
                                )
                            }/>
                        }/>
                    }/>
                }/>
            }/>
        }/>
    );
}

export const SDInterfaceStateContext = React.createContext<SDInterfaceState>({
    phase: "default",
    activeTab: "main",
    request: generateBaseSDAPIRequest(),
    outputConfig: {
        outputTarget: undefined,
        applyTags: [],
        applyTagsEnabled: true
    }
});

export const SDInterfaceDynStateContext = React.createContext<[
    SDInterfaceDynamicState, StateDispatcher<SDInterfaceDynamicState>
]>([{
    debouncedRequestSaver: () => console.error("Uninitialized SD interface (main api was missing during state generation)")
}, () => console.error("Uninitialized SD interface")]);

function generateBaseSDAPIRequest(): SDAPIRequestData {
    return ({
        prompt: "",
        negativePrompt: "",
        samplingSteps: 20,
        cfgScale: 7,
        clipSkip: 2,
        sampler: SDDefaultSamplers.EULER_A,
        height: 512,
        width: 512,
        sizeScale: 1,
        batchSize: 1
    });
}

export const SDInterfaceStateCtxContext = React.createContext<IStaticStateCtx<SDInterfaceState> | undefined>(undefined);

export type SDInterfaceRequestContextData = {
    initialRequestData: SDAPIRequestData | undefined,
    deltaRequestData: SDAPIRequestData | undefined
}

export const SDInterfaceRequestContext = React.createContext<SDInterfaceRequestContextData | undefined>(undefined);
