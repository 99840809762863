import React, {useContext} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {GalleryView} from "./views/gallery/GalleryView";
import {GenerationView} from "./views/generate/GenerationView";
import {ArdaiDevelopmentRoutesComponent} from "./ArdaiDevelopmentRoutesComponent";
import {NotFoundPage} from "./NotFoundPage";

export const ArdaiRoutesComponent: React.FC = props => {
    const location = useLocation();

    return (
        <Routes location={location}>
            {/* main app routes */}
            <Route index element={<GalleryView/>}/>
            <Route path={"/gallery"} element={<GalleryView/>}/>
            <Route path={`/generate`} element={<GenerationView/>}/>

            {/* development/debugging routes - will be hidden in production mode */}
            <Route path={`/development/*`} element={
                <ArdaiDevelopmentRoutesComponent/>
            }/>

            <Route path={"*"} element={<NotFoundPage/>}/>
        </Routes>
    );
}
