import {ResourceContainer} from "./ResourceContainer";
import {ResourceParamsType} from "./ResourceParamsType";

export class ResourceManager {

    private readonly containers: Map<string, ResourceContainer<any>> = new Map<string, ResourceContainer<any>>();

    public getContainer<T, Params extends ResourceParamsType = void>(id: string): ResourceContainer<T, Params> {
        const isContainerPresent = this.containers.has(id);
        if (!isContainerPresent) {
            this.containers.set(id, new ResourceContainer<any>());
        }
        return this.containers.get(id)! as ResourceContainer<T, Params>;
    }
}
