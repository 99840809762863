import { readAndCompressImage } from 'browser-image-resizer';

export class ImageResizer {

    public async compressImage(image: Blob): Promise<Blob> {
        return await readAndCompressImage(new File([image], ""), {
            quality: undefined,
            maxWidth: undefined
        });
    }
}
