import {StateDispatcher} from "../../ship/test/core/StateDispatcher";
import {ProjectContext} from "../data/ProjectContext";
import {webDB} from "./WebAPIDB";
import {Collection, PromiseExtended} from "dexie";
import {VFSElement} from "../data/VFSElement";
import {Project} from "../data/Project";
import {v4} from "uuid";
import {ArdaiAppState} from "../ArdaiMain";
import React from "react";
import {ImportManager} from "./ImportManager";
import {DownloadManager} from "./DownloadManager";
import {SelectionManager} from "./SelectionManager";
import {ExportManager} from "./export/ExportManager";
import {SettingsManager} from "./SettingsManager";
import {CompressionManager} from "./CompressionManager";
import {Image} from "../data/Image";
import BuildMetaData from "../../../metadata-build.json";
import {BuildMetadata} from "../data/BuildMetadata";
import {VirtualFileDriverManager} from "./virtualFileDriverManager/VirtualFileDriverManager";
import {ImageSelection} from "../data/ImageSelection";
import {FSManager} from "./fs/FSManager";
import {ContentPolicyManager} from "./cp/ContentPolicyManager";
import {ContentSensitivity} from "./cp/ContentSensitivity";
import {PNGPropertiesManager} from "./pngProperties/PNGPropertiesManager";
import {Observer} from "./pubsub/Observer";
import {Observable} from "./pubsub/Observable";
import {StandaloneObservable} from "./pubsub/StandaloneObservable";
import {ImageResizer} from "../logic/imageResizing/v1/ImageResizer";
import {LayoutManager} from "./LayoutManager";

export class WebAPI {

    private _state: ArdaiAppState | undefined;

    private readonly _setState: StateDispatcher<ArdaiAppState> | undefined;

    private readonly _importManager: ImportManager;

    private readonly _downloadManager: DownloadManager

    private readonly _FSManager: FSManager;

    private readonly _selectionManager: SelectionManager

    private readonly _exportManager: ExportManager;

    private readonly _settingsManager: SettingsManager;

    private readonly _compressionManager: CompressionManager;

    private readonly _virtualFileDriverManager: VirtualFileDriverManager;

    private readonly _contentPolicyManager: ContentPolicyManager;

    private readonly _pngPropertiesManager: PNGPropertiesManager;

    private readonly _layoutManager: LayoutManager;

    public readonly crudeBroker: StandaloneObservable = new StandaloneObservable;

    constructor(state?: ArdaiAppState, setState?: StateDispatcher<ArdaiAppState>) {
        this._state = state;
        this._setState = setState;
        // Initialize all api managers
        this._virtualFileDriverManager = new VirtualFileDriverManager();
        this._virtualFileDriverManager.setApi(this);

        this._importManager = new ImportManager();
        this._importManager.setApi(this);

        this._downloadManager = new DownloadManager();
        this._downloadManager.setApi(this);

        this._selectionManager = new SelectionManager();
        this._selectionManager.setApi(this);

        this._exportManager = new ExportManager();
        this._exportManager.setApi(this);

        this._settingsManager = new SettingsManager();
        this._settingsManager.setApi(this);

        this._compressionManager = new CompressionManager();
        this._compressionManager.setApi(this);

        this._FSManager = new FSManager;
        this._FSManager.setApi(this);
        this._FSManager.init();

        this._contentPolicyManager = new ContentPolicyManager;
        this._contentPolicyManager.setApi(this);
        this._contentPolicyManager.init();

        this._pngPropertiesManager = new PNGPropertiesManager;
        this._pngPropertiesManager.setApi(this);
        this._pngPropertiesManager.init();

        this._layoutManager = new LayoutManager();
        this._layoutManager.setApi(this);
        this._layoutManager.init();
    }

    public get contentPolicyManager(): ContentPolicyManager {
        return this._contentPolicyManager;
    }

    public getVersionString(): string {
        return `${BuildMetaData.buildMajor}.${BuildMetaData.buildMinor}.${BuildMetaData.buildRevision}${BuildMetaData.buildTag.trim().length === 0 ? "" : `-${BuildMetaData.buildTag}`}`
    }

    public getBuildMetadata(): BuildMetadata {
        return BuildMetaData
    }

    public updateState(state: ArdaiAppState) {
        this._state = state;
    }

    public openProgramInTray(tray: string, program: string) {
        this.setState(prevState => ({
            ...prevState,
            trayOccupancy: {
                ...prevState.trayOccupancy,
                [tray]: program,
            },
            openedTrays: [tray, ...prevState.openedTrays]
        }));
    }

    public toggleTray(tray: string) {
        const isTrayOpen = this.state.openedTrays.includes(tray);

        if (isTrayOpen) {
            this.setState(prevState => ({
                ...prevState,
                openedTrays: prevState.openedTrays.filter(opened => opened !== tray)
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                openedTrays: [tray, ...prevState.openedTrays]
            }));
        }
    }

    public closeAllProgramsInAllTrays() {
        this.setState(prevState => ({
            ...prevState,
            trayOccupancy: {},
            openedTrays: []
        }));
    }

    public closeProgramInTray(tray: string) {
        this.setState(prevState => ({
            ...prevState,
            trayOccupancy: {
                ...prevState.trayOccupancy,
                [tray]: undefined
            },
            openedTrays: prevState.openedTrays.filter(s => s !== tray)
        }))
    }

    public toggleFolderCreationDialog(open?: boolean) {
        this.setState(prevState => ({
            ...prevState,
            isFolderCreationDialogOpened: open ?? !prevState.isProjectCreationDialogOpened
        }));
    }

    public getProjectContext(): ProjectContext {
        const pID = this.state.selectedProject;
        if (pID === undefined) throw new Error("Cannot create project contest: No project is selected.");
        return new ProjectContext(this, pID);
    }

    public async getFolder(folderID: string) {
        return webDB.vfsElements.get(folderID);
    }

    public async removeImageFromCurrentProject(imageID: string) {
        // Remove from project resources
        const current = await this.getCurrentProject();
        const resources = (current?.resources ?? []).filter(res => res !== imageID);
        await webDB.projects.update(current?.id!, {
            resources: resources
        });
        // Remove from image db
        await webDB.images.delete(imageID);
    }

    public async getElementsInCurrentFolder(): Promise<Collection<VFSElement>> {
        const current = await this.getCurrentElement();
        const subElementIDs = current?.subElements ?? [];
        return webDB.vfsElements.where("id").anyOfIgnoreCase(subElementIDs);
    }

    public toggleProjectCreationDialog(open?: boolean) {
        this.setState(prevState => ({
            ...prevState,
            isProjectCreationDialogOpened: open ?? !prevState.isProjectCreationDialogOpened
        }));
    }

    public appendToFilePath(id: string) {
        this.setState(prevState => ({
            ...prevState,
            fvsPath: [...prevState.fvsPath, id]
        }));
    }

    public getCurrentProject(): PromiseExtended<Project | undefined> {
        // TODO: Very bad line... don't do that
        if (this.state.selectedProject === undefined) return Promise.resolve(undefined) as PromiseExtended;

        return this.getProject(this.state.selectedProject);
    }

    public getProject(projectID: string): PromiseExtended<Project | undefined> {
        return webDB.projects.get(projectID);
    }

    public async appendFilesToCurrentProject(files: File[]) {
        const imageIDs: Array<string> = [];
        const images: Array<Image> = []
        const timestamp = new Date();

        for (let f of files) {
            const id = v4();
            imageIDs.push(id);
            const imageRawData = f.slice();

            images.push({
                data: imageRawData,
                id: id,
                tags: [],
                favourite: false,
                creationTimestamp: timestamp,
                contentSensitivity: ContentSensitivity.UNCLASSIFIED
            });
        }

        for (let image of images) {
            const imageRawData = image.data;
            // const map = await createImageBitmap(imageRawData);
            // image.previewData = await this.compressionManager.resize(map, "image/jpeg", .5);
            // image.previewData = await this.compressionManager.resize(map, "image/jpeg", .1);

            image.previewData = await new ImageResizer().compressImage(imageRawData);
        }

        webDB.images.bulkAdd(images);

        const project = await this.getCurrentProject();
        try {
            await webDB.projects.update(project?.id!, {
                "resources": [...project?.resources ?? [], ...imageIDs]
            });
        } catch (e) {
            console.error(e)
        }
    }

    /**
     * TODO: rename
     */
    public goUpOneLevel() {
        this.setState(prevState => ({
            ...prevState,
            fvsPath: prevState.fvsPath.filter((value, index, array) => index < array.length - 1)
        }));
    }

    public goToRoot() {
        this.setState(prevState => ({
            ...prevState,
            fvsPath: []
        }));
    }



    public selectImages(selection: ImageSelection) {
        this.setState(prevState => ({
            ...prevState,
            imageSelection: selection
        }));
    }

    public clearImageSelection() {
        this.setState(prevState => ({
            ...prevState,
            imageSelection: undefined
        }));
    }

    public selectImageByID(imageID: string, toggleMode: boolean = true) {
        if (toggleMode) {
            if (this.state.selectedImageId === imageID) {
                this.setState(prevState => ({
                    ...prevState,
                    selectedImageId: undefined
                }));
                return;
            }
        }

        this.setState(prevState => ({
            ...prevState,
            selectedImageId: imageID
        }));

        this.getCurrentProject().then(project => {
            if (!project) return;
            const selection = this.state.imageSelection;

            if (selection?.name === project?.id) {
                // The currently open selection is related to the currently opened project
                this.setState(prevState => {
                    const newPointerInCurrentImageSelection = prevState.imageSelection!.imagesIDs!.indexOf(imageID);
                    const isPointerValid = newPointerInCurrentImageSelection !== -1;
                    if (isPointerValid) return ({
                        ...prevState,
                        imageSelection: {
                            ...prevState.imageSelection!,
                            pointer: prevState.imageSelection!.imagesIDs!.indexOf(imageID)
                        }
                    });

                    else return ({
                        ...prevState,
                        imageSelection: {
                            imagesIDs: [imageID],
                            name: imageID,
                            pointer: 0
                        }
                    });
                });
            }

            // The opened image is not related to the current image
            else this.setState(prevState => ({
                ...prevState,
                imageSelection: {
                    imagesIDs: [imageID],
                    name: imageID,
                    pointer: 0
                }
            }));
        }).catch(reason => {
            console.error(reason);
            this.setState(prevState => ({
                ...prevState,
                imageSelection: {
                    imagesIDs: [imageID],
                    name: imageID,
                    pointer: 0
                }
            }));
        });
    }

    public unselectImage() {
        if (this.state.selectedImageId === undefined && this.state.imageSelection === undefined) return;
        this.setState(prevState => ({
            ...prevState,
            selectedImageId: undefined,
            imageSelection: undefined
        }));
    }

    public selectStyleByID(styleID: string) {
        this.setState(prevState => ({
            ...prevState,
            selectedStyleId: styleID
        }));
    }

    public async getCurrentElement(): Promise<VFSElement | undefined> {
        const folderID = this.state.fvsPath.length === 0 ? "root" : this.state.fvsPath[this.state.fvsPath.length - 1];
        // console.debug("Loading folder info", folderID)
        return webDB.vfsElements.get(folderID);
    }

    public async getProjectFromPath(projectFolderAddress: Array<string>, projectName: string) {
        const folder = (await this.getFolderFromPath(projectFolderAddress))!;
        const projectVFSElement = await webDB.vfsElements
            .where("id")
            .anyOfIgnoreCase(folder.subElements ?? [])
            .and(el => el.title === projectName)
            .first();
        return this.getProject(projectVFSElement?.targetID!);
    }

    public async addImageToProject(projectFolderAddress: Array<string>, projectName: string, files: File[]) {
        const imageIDs: Array<string> = [];
        const timestamp = new Date();
        webDB.images.bulkAdd(files.map(f => {
            const id = v4();
            imageIDs.push(id)
            return ({
                data: f.slice(),
                id: id,
                tags: [],
                favourite: false,
                creationTimestamp: timestamp,
                contentSensitivity: ContentSensitivity.UNCLASSIFIED
            });
        }));

        const project = await this.getProjectFromPath(projectFolderAddress, projectName);

        try {
            await webDB.projects.update(project?.id!, {
                "resources": [...project?.resources ?? [], ...imageIDs]
            });
        } catch (e) {
            console.error(e)
        }
    }

    public async mkdir(path: Array<string>, title: string) {
        if (await this.checkFolderExistence(path.concat(title))) return;
        const absolutePath = path.filter(shard => shard.trim().length > 0).join("/");
        const fullPath = absolutePath + "/" + title;
        const element: VFSElement = {
            subElements: [],
            projects: [],

            // TODO: Set
            parentID: "",

            targetID: undefined,
            title: title,
            type: "folder",
            id: v4(),
            path: absolutePath,
            fullPath: fullPath
        }
        webDB.vfsElements.add(element);
    }

    public getElementPath(element: VFSElement) {
        let path = "";
        if (element.path.length > 0) path = element.path + "/";
        path += element.title;
        return path;
    }

    public async mkdirs(path: Array<string>) {
        let walked: Array<string> = [];
        for (const shard of path.filter(shard => shard.length > 0)) {
            await this.mkdir(walked, shard);
            walked.push(shard);
        }
    }

    public async checkFolderExistence(path: Array<string>): Promise<boolean> {
        const absolutePath = path.join("/");
        return (await webDB.vfsElements
            .where("fullPath")
            .equals(absolutePath)
            .and(el => el.type === "folder")
            .count()) > 0;
    }

    public async createFolder(title: string) {
        const currentFolder = (await this.getCurrentElement())!;
        // Create vfs object
        const absolutePath = `${currentFolder.path.length === 0 ? "" : `${currentFolder.path}/`}${currentFolder.title}`;
        const fullPath = absolutePath + "/" + title;
        const element: VFSElement = {
            subElements: [],
            projects: [],
            parentID: currentFolder?.id ?? "",
            targetID: undefined,
            title: title,
            type: "folder",
            id: v4(),
            path: absolutePath,
            fullPath: fullPath
        }
        // Add new folder's id to list of sub elements in current folder object
        const current = await this.getCurrentElement();
        // TODo: This should be an object not an array
        webDB.vfsElements.update(current?.id!, [
            "subElements", [...current?.subElements ?? [], element.id]
        ]);
        webDB.vfsElements.add(element);
    }

    public async createProject(project: Project) {
        webDB.projects.add(project);
        // Create folder object
        // const currentFolder = (await this.getCurrentElement())!;
        const currentFolderVFSElement = (await this.getCurrentElement())!;

        const projectVFSElement: VFSElement = {
            subElements: [],
            projects: [],
            parentID: currentFolderVFSElement.id ?? "",
            targetID: project.id,
            title: project.title,
            type: "project",
            id: project.id,
            path: currentFolderVFSElement.fullPath,
            fullPath: currentFolderVFSElement.fullPath + "/" + currentFolderVFSElement.title
        }
        // Add new folder's id to list of sub elements in currentFolderVFSElement folder object
        // const currentFolderVFSElement = await this.getCurrentElement();
        // TODo: This should be an object not an array
        webDB.vfsElements.update(currentFolderVFSElement?.id!, {
            "subElements": [...currentFolderVFSElement?.subElements ?? [], projectVFSElement.id]
        });
        webDB.vfsElements.add(projectVFSElement);
    }

    public async duplicateProject(request: {
        projectID: string,
        titleTransformer?: (title: string) => string
    }): Promise<{
        duplicateProject: Project,
        duplicateVFSElem: VFSElement
    }> {
        const targetProject = await webDB.projects.get(request.projectID);
        if (targetProject === undefined) throw new Error("Can't duplicate project. Target project wasn't found.");
        const currentFolderVFSElement = (await this.getCurrentElement())!;
        const duplicateID = v4();
        const duplicateTitle = request.titleTransformer !== undefined ? request.titleTransformer(targetProject.title) : `${targetProject.title}-duplicate`;
        const duplicatedProject: Project = {
            ...targetProject,
            id: duplicateID,
            title: duplicateTitle
        };
        const targetProjectVFSElement = await webDB.vfsElements.get(request.projectID);
        if (targetProjectVFSElement === undefined) throw new Error("Can't duplicate project. Target project's VFS element wasn't found.");
        const duplicatedTargetProjectVFSElement: VFSElement = {
            ...targetProjectVFSElement,
            targetID: duplicateID,
            id: duplicateID,
            title: duplicateTitle
        }
        webDB.projects.add(duplicatedProject);
        webDB.vfsElements.add(duplicatedTargetProjectVFSElement);
        webDB.vfsElements.update(currentFolderVFSElement?.id!, {
            "subElements": [...currentFolderVFSElement?.subElements ?? [], duplicateID]
        });

        return {
            duplicateProject: duplicatedProject,
            duplicateVFSElem: duplicatedTargetProjectVFSElement
        };
    }

    public async getFolderFromPath(path: Array<string>) {
        return webDB.vfsElements
            .where("fullPath")
            .equals(path.join("/"))
            .first();
    }

    public async createProjectInFolder(fullPath: Array<string>, project: Project) {
        webDB.projects.add(project);
        // Create folder object
        // const currentFolder = (await this.getCurrentElement())!;
        const current = (await this.getFolderFromPath(fullPath))!;
        const element: VFSElement = {
            subElements: [],
            projects: [],
            parentID: current.id ?? "",
            targetID: project.id,
            title: project.title,
            type: "project",
            id: project.id,
            // path: current.fullPath,
            path: current.fullPath + "/" + project.title,
            fullPath: current.fullPath + "/" + project.title
        }
        // Add new folder's id to list of sub elements in current folder object
        // const current = await this.getCurrentElement();
        // TODo: This should be an object not an array
        webDB.vfsElements.update(current?.id!, {
            "subElements": [...current?.subElements ?? [], element.id]
        });
        webDB.vfsElements.add(element);
    }

    public selectProject(projectID: string) {
        if (this.state.selectedProject === projectID) return;
        this.setState(prevState => ({
            ...prevState,
            selectedProject: projectID
        }));

        this.getImageSelectionFromProjectID(projectID).then(selection => {
            this.setState(prevState => ({
                ...prevState,
                selectedImageId: prevState.selectedImageId === undefined ? selection.imagesIDs[0] : prevState.selectedImageId,
                imageSelection: selection
            }));
        });
    }

    public async getImageSelectionFromProjectID(projectID: string): Promise<ImageSelection> {
        const p = await this.getProject(projectID);
        return {
            name: p?.id!,
            pointer: 0,
            imagesIDs: p?.resources ?? []
        };
    }

    public closeProject() {
        if (this.state.selectedProject === undefined) return;
        const isSelectionTheCurrentProject = this.state.selectedProject === this.state.imageSelection?.name;
        this.setState(prevState => ({
            ...prevState,
            selectedProject: undefined,
            imageSelection: isSelectionTheCurrentProject ? undefined : prevState.imageSelection
        }));
    }

    public getCurrentSelectedImageIDInView(): string {
        const sel = this.state.imageSelection;
        if (sel === undefined) throw new Error;
        return sel.imagesIDs[sel.pointer];
    }

    public async getCurrentSelectedImageInView(): Promise<Image> {
        const curImgID = this.getCurrentSelectedImageIDInView();
        return (await webDB.images.get(curImgID))!!;
    }

    get setState(): StateDispatcher<ArdaiAppState> {
        return this._setState!;
    }

    get state(): ArdaiAppState {
        return this._state!;
    }

    get importManager(): ImportManager {
        return this._importManager;
    }

    get downloadManager(): DownloadManager {
        return this._downloadManager;
    }

    get FSManager(): FSManager {
        return this._FSManager;
    }

    get selectionManager(): SelectionManager {
        return this._selectionManager;
    }

    get exportManager(): ExportManager {
        return this._exportManager;
    }

    get settingsManager(): SettingsManager {
        return this._settingsManager;
    }

    get compressionManager(): CompressionManager {
        return this._compressionManager;
    }

    get virtualFileDriverManager(): VirtualFileDriverManager {
        return this._virtualFileDriverManager;
    }

    get pngPropertiesManager(): PNGPropertiesManager {
        return this._pngPropertiesManager;
    }

    get layoutManager(): LayoutManager {
        return this._layoutManager;
    }
}

export const ArdaiAPIContext = React.createContext<WebAPI>(new WebAPI())
