import {FC, useRef} from "react";
import {Pin, PinMode} from "../backend/Pin";
import {getPinHtmlID} from "./NodeFrontendUtils";
import Draggable from "react-draggable";
import {useNodeCanvasBackend} from "./NodeCanvasBackend";
import {NodeCanvasBackendEventTypes} from "./NodeCanvasBackendEventTypes";
import {useStaticState} from "../../ardai/hooks/StaticStateHook";
import styled from "styled-components";
import {Triton} from "../../triton/Triton";
import {useTriton} from "../../triton/TritonHooks";
import {PortWireDisplay} from "./PortWireDisplay";
import {Pos} from "./Pos";
import {StdWire} from "./components/wires/StdWire";
import {useForceRenderFunc} from "../../ForceRerenderHook";

const StyledNodePinExplorer = styled.div<{
    t: Triton,
    s: NodePinExplorerState
}>`
  .explorer-pin-visualization {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 300;
    aspect-ratio: 1 / 1;
    width: 10px;
    border-radius: 50%;
    border: 1px solid ${p => false ? p.t.col("color_primary") : p.t.col("fg_muted")};
    background-color: ${p => false ? p.t.colObj("color_primary").withAlpha(.1).css() : p.t.colObj("fg_muted").withAlpha(.1).css()};
    
    &[data-active=false] {
      opacity: 0;
    }
  }
`;

export type NodePinExplorerState = {
    active: boolean,
    isOnTarget: boolean,
    explorerPositionDelta: {
        x: number,
        y: number
    }
}

export type NodePinExplorerProps = {
    pin: Pin
}

export const NodePinExplorer: FC<NodePinExplorerProps> = props => {
    const pin = props.pin;
    const portClass = "port";
    const backend = useNodeCanvasBackend();
    const t = useTriton();

    const forceRender = useForceRenderFunc();

    const [state, ctx] = useStaticState<NodePinExplorerState>({
        id: "NodePinExplorer-local",
        staticMode: false,
        initial: {
            active: false,
            isOnTarget: false,
            explorerPositionDelta: {
                x: 0,
                y: 0
            }
        }
    }).stateWithCtx;

    const resetExplorer = () => {
        ctx.update({
            active: false,
            explorerPositionDelta: {
                x: 0,
                y: 0
            }
        });
    }

    const srcRef = useRef<HTMLDivElement>(null);
    const dstRef = useRef<HTMLDivElement>(null);

    const pinDiameter = 10;
    const pinRadius = pinDiameter / 2;

    return (
        <StyledNodePinExplorer s={state} t={t} ref={srcRef}>

            <Draggable
                enableUserSelectHack={true}
                position={state.explorerPositionDelta}
                onStart={e => {
                    e.stopPropagation();
                    ctx.update({
                        active: true
                    });
                }}
                onDrag={(e, data) => {
                    ctx.update(prevState => ({
                        explorerPositionDelta: {
                            x: prevState.explorerPositionDelta.x + data.deltaX,
                            y: prevState.explorerPositionDelta.y + data.deltaY
                        }
                    }));

                    const portExplorer = document.getElementById(`${getPinHtmlID(pin)}-port-explorer`)
                    const pERect = portExplorer!.getBoundingClientRect();

                    // const discoveredElement = document.elementFromPoint(pERect.x, pERect.y);
                    const discoveredElement = document.elementFromPoint(
                        pERect.left,
                        pERect.top
                    );
                    let isOnTarget = false;
                    explorerTargetIdentificationLogic: {


                        if (discoveredElement === null) break explorerTargetIdentificationLogic;
                        if (!discoveredElement.classList.contains(portClass)) break explorerTargetIdentificationLogic;
                        const targetNodeIdAttr = discoveredElement.attributes.getNamedItem("data-node-id")
                        const targetPinIdAttr = discoveredElement.attributes.getNamedItem("data-pin-id")
                        const targetNode = backend.environment.getNodeByID(targetNodeIdAttr!.value);
                        const pinId = targetPinIdAttr!.value;
                        const targetPin = targetNode.pins.getPinById(pinId);
                        if (targetPin === undefined) break explorerTargetIdentificationLogic;
                        if (pin === targetPin) break explorerTargetIdentificationLogic;
                        if (targetPin.mode !== PinMode.IN) break explorerTargetIdentificationLogic;
                        isOnTarget = true;
                    }
                    ctx.update(prevState => ({
                        isOnTarget
                    }));
                }}
                onStop={(e, data) => {
                    const portExplorer = document.getElementById(`${getPinHtmlID(pin)}-port-explorer`)
                    const pERect = portExplorer!.getBoundingClientRect();
                    const discoveredElement = document.elementFromPoint(pERect.x, pERect.y);
                    connectionLogic: if (discoveredElement?.classList.contains(portClass)) {
                        const targetNodeIdAttr = discoveredElement.attributes.getNamedItem("data-node-id")
                        const targetPinIdAttr = discoveredElement.attributes.getNamedItem("data-pin-id")
                        const targetNode = backend.environment.getNodeByID(targetNodeIdAttr!.value);
                        const pinId = targetPinIdAttr!.value;
                        const targetPin = targetNode.pins.getPinById(pinId);
                        if (targetPin === undefined) break connectionLogic; // TODO: Check if auto-creation is allowed
                        if (pin === targetPin) break connectionLogic; // TODO: Check if self-connecting is allowed
                        if (targetPin.mode !== PinMode.IN) break connectionLogic;
                        pin.connect(targetPin);
                        backend.events.notify(NodeCanvasBackendEventTypes.GRAPH_UPDATE, undefined);
                    }
                    resetExplorer();
                }}
                children={
                    <div
                        ref={dstRef}
                        className={"explorer-pin-visualization"}
                        data-active={state.active}
                        id={`${getPinHtmlID(pin)}-port-explorer`}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    />
                }
            />

            <div style={{
                pointerEvents: "none",
                position: "absolute",
                top: 0,
                left: 0
            }}>
                <StdWire points={[{
                    x: pinRadius,
                    y: pinRadius
                }, {
                    x: state.explorerPositionDelta.x + pinRadius,
                    y: state.explorerPositionDelta.y + pinRadius
                }]} lineStyle={{
                    stroke: state.isOnTarget ? t.col("color_primary") : "currentcolor"
                }}/>
            </div>
        </StyledNodePinExplorer>
    );
}

