import React from "react";
import styled from "styled-components";
import {Flex} from "../../Flex";
import {BoardingPageHeader} from "../../components/BoardingPageHeader";
import {AccountCreationStorybook} from "./stories/account/AccountCreationStorybook";

const StyledMobileSignupPage = styled.div`
  background-color: black;
  width: 100vw;
  height: 100vh;
  padding: 27px;
  overflow-x: hidden;
  display: flex;
  gap: 35px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MobileSignupPage: React.FC = props => {
    return (
        <StyledMobileSignupPage>
            <Flex g={53} baseProps={{ style: { width: "100%", flexDirection: "column" } }}>
                <BoardingPageHeader/>
            </Flex>

            <AccountCreationStorybook/>
        </StyledMobileSignupPage>
    );
}
