import React, {useEffect, useState} from "react";
import {DescriptiveTypography} from "../triton/components/typography/DescriptiveTypography";
import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import {Std} from "../../Std";
import lerp = Std.lerp;
import range = Std.range;

const StyledNotFoundPage = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: min-content auto min-content;
  
  .center-container-404 {
    position: absolute;
    top: 50%;
    left: 50%;
    // overflow: hidden;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;

export const NotFoundPage: React.FC = props => {

    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0,
    });

    const w = window.innerWidth;

    useEffect(() => {
        const updateMousePosition = (e: MouseEvent) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY,
            });
        };

        window.addEventListener("mousemove", updateMousePosition);

        return () => {
            window.removeEventListener("mousemove", updateMousePosition);
        };
    }, []);

    const tX = range(0, w/2, 0, w, Math.abs(mousePosition.x - w/2))

    const damping = range(0, w/2, 1, 0, Math.abs(mousePosition.x - w/2))

    return (
        <StyledNotFoundPage>
            <div>
                {/*
                <DescriptiveTypography text={`${mousePosition.x}, dmp=${damping} d=${Math.abs(mousePosition.x - w/2)}, ${tX * damping}`}/>
                */}
            </div>

            <div className={"center-container-404"}>
                <motion.div
                    transition={{
                        delay: 1,
                        duration: 1,
                        type: "spring"
                    }}
                    initial={{
                        scale: 0
                    }}
                    animate={{
                        scale: 1
                    }}
                >
                    {/*
                    <AnimatePresence children={
                        <motion.div dragConstraints={{
                            top: -50,
                            left: -50,
                            right: 50,
                            bottom: 50,
                        }} style={{
                            backgroundColor: "red",
                            position: "absolute",
                            transformOrigin: "50% 50%",
                            translateX: tX,
                            // translateY: -mousePosition.y,
                        }} children={
                            <DescriptiveTypography noSelect text={"404"} style={{
                                fontSize: 200,
                                fontFamily: "DM Serif Display",
                                fontWeight: "bolder",
                                color: "black",
                                textShadow: "-1px -1px 0 #fff"
                            }}/>
                        }/>
                    }/>
                    */}
                    <DescriptiveTypography noSelect text={"404"} style={{
                        fontSize: 200,
                        fontFamily: "DM Serif Display",
                        fontWeight: "bolder",
                        color: "#fff"
                    }}/>
                </motion.div>
            </div>
            <div>
            </div>
        </StyledNotFoundPage>
    );
}
