export enum Dimension {
    px = "px",
    em = "em",
    rem = "rem",
    percentage = "%",
    vw = "vw",
    vh = "vh",
    fr = "fr",
    NOT_SPECIFIED = ""
}
