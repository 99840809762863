import React, {CSSProperties, PropsWithChildren, useContext} from "react";
import {WorkspaceConfig} from "../data/WorkspaceConfig";
import {ResizableBox} from "react-resizable";
import {useTheme} from "../../triton/TritonHooks";
import {TritonContext} from "../../triton/TritonContext";

export type WorkspaceProps = PropsWithChildren<{
    config: WorkspaceConfig,
    style?: CSSProperties
}>

export const Workspace: React.FC<WorkspaceProps> = props => {
    const resizable = props.config.resizable ?? false;
    const triton = useContext(TritonContext);

    if (resizable) {
        return (
            <ResizableBox
                resizeHandles={["e"]}
                {...props.config.resizablePropertyOverrides}
                className={"box"}
                width={350}
                axis={"x"}
                style={{
                    borderRadius: ".5rem",
                    overflow: "hidden",
                    ...props.style
                }}
                handle={(h, ref) => (
                    <span
                        className={`react-resizable-handle react-resizable-handle-${h}`}
                        ref={ref}
                        style={{
                            backgroundColor: "rgba(200, 200, 200, .16)",
                            height: "100%",
                            top: 0,
                            margin: 0,
                            transformOrigin: 0,
                            transform: "none",
                            width: 5
                        }}
                    />
                )}
                minConstraints={[200, 0]}
                maxConstraints={[800, 0]}
                children={
                    // <div style={{
                    //     height: "100%",
                    //     width: "100%",
                    //     padding: "1rem",
                    //     overflow: "hidden",
                    //     // backgroundColor: triton.col("bg_tray"),
                    //     // borderRadius: ".5rem",
                    //     // TODO: Remove
                    //     background: "linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))",
                    //     backdropFilter: "blur(10px)",
                    //     borderRadius: "20px",
                    //     border: "1px solid rgba(255, 255, 255, 0.18)",
                    //     boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.37)",
                    // }} children={props.children}/>
                    <div children={props.children} style={triton.styled("layout_box", {
                        height: "100%",
                        width: "100%",
                        padding: "1rem",
                        overflow: "hidden",
                    })}/>
                }
            />
        );
    }

    return (
        <WorkspaceContext.Provider value={props.config}>
            {/*
            // <div style={{
            //     height: "100%",
            //     width: "100%",
            //     overflow: "hidden",
            //     boxSizing: "border-box",
            //     padding: "1rem",
            //     // backgroundColor: triton.col("bg_tray"),
            //     // borderRadius: ".5rem",
            //     // backgroundColor: "transparent",
            //     // TODO: Remove
            //     background: "linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))",
            //     backdropFilter: "blur(10px)",
            //     borderRadius: "20px",
            //     border: "1px solid rgba(255, 255, 255, 0.18)",
            //     boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.37)",
            //     ...props.style
            // }} children={props.children}/>
            */}

            <div children={props.children} style={triton.styled("layout_box", {
                height: "100%",
                width: "100%",
                boxSizing: "border-box",
                padding: "1rem",

                // TODO: Check if okay -> Bad overflowing behaviour
                // overflow: "hidden",
                overflow: "visible",

                ...props.style
            })}/>
        </WorkspaceContext.Provider>
    );
}

export const WorkspaceContext = React.createContext<WorkspaceConfig>({
    name: "n/a",
    mode: "desktop"
});
