import React, {useContext, useEffect, useRef, useState} from "react";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {Formik} from "formik";
import {FormikSingleLineInput} from "../../../../triton/components/forms/FormikSingleLineInput";
import {ErrorMessage} from "../../../../triton/components/forms/ErrorMessage";
import {FormElement} from "../../../../triton/components/forms/FormElement";
import {Checkbox, Zoom} from "@mui/material";
import {Fade} from '@mui/material';
import {ImageHubBranding} from "../../ImageHubBranding";
import {
    ArrowLeftRounded,
    CalendarViewDayRounded,
    ChevronRightRounded,
    CloseRounded,
    CommentRounded,
    ExitToAppRounded, ImageRounded,
    KeyboardCommandKeyRounded,
    LockOpenRounded,
    LockRounded,
    LoginRounded, MoreHorizRounded,
    PanoramaHorizontalRounded,
    PlayArrowRounded
} from "@mui/icons-material";
import {IconButton} from "../../IconButton";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {FormikFormCheckbox} from "../../FormikFormCheckbox";
import styled from "styled-components";
import {ScreenSaverLeftWidget} from "./ScreenSaverLeftWidget";
import {ScreenSaverRightWidget} from "./ScreenSaverRightWidget";
import Slide from "@mui/material/Slide";
import {BackgroundType} from "../../../background/BackgroundType";
import {as} from "../../../../atlas/Lang";
import {BackgroundImageInformation} from "../../../background/BackgroundImageInformation";
import {ImageRenderingMode} from "../../../../atlas/hyperion/datatypes/ImageRenderingMode";
import {Background} from "../../../background/Background";
import {BackgroundSolidInformation} from "../../../background/BackgroundSolidInformation";
import {Color} from "../../../../base/logic/style/Color";
import {ButtonBase} from "../../../../triton/components/buttons/ButtonBase";
import {useKeyboardEvent} from "../../../_/keyCommand/test/KeyCommandTest";
import { motion } from "framer-motion"
import {useNavigate} from "react-router-dom";
import {ListItem} from "../../../_/ListItem";
import {FlatIconButton} from "../../FlatIconButton";
import {SDPromptField} from "../../../../imageSorter/sdInterface/SDPromptField";
import {FormikAdvancedFormCheckbox} from "../../FormikAdvancedFormCheckbox";
import {ControlledBooleanInput} from "../../ControlledBooleanInput";
import {DefaultButton} from "../../DefaultButton";
import {BackgroundImages} from "../../../BackgroundImages";
import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../../../webapi/WebAPIDB";
import {useStaticState} from "../../../hooks/StaticStateHook";
import {Menu} from "../../Menu";
import {MenuGroup} from "../../MenuGroup";
import {MenuButton} from "../../MenuButton";
import {ArdaiAPIStateContext} from "../../../ArdaiMain";
import {ArdaiAPIContext} from "../../../webapi/WebAPI";

const StyledScreenSaver = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  .view-opener {
    color: white;
    cursor: pointer;
    transition: all ease-in-out .1s;
    rotate: 90deg;

    &[data-opened=true] {
      rotate: -90deg;
    }
  }
`;

export type ScreenSaverState = {
    active: boolean,
    unlocked: boolean,

    activationReason?: "manual" | "timeout",
    advancedSettingsTrayOpened: boolean,
    openedSidePanels: boolean,
    openCommandPanel: boolean,
    disableGuardingProcedure: boolean,
    autoExitScreenSaverOnGuardProcedureSatisfied: boolean,

    distractionFreeMode: boolean
}

export namespace ScreenSaverNamespace {

    export const SCREEN_SAVER_ACTIVATION_EVENT = "activate-screensaver";

}

export const ScreenSaver: React.FC = props => {

    const navigate = useNavigate();

    // const wingsAnimationTimeoutMS = 500;
    const wingsAnimationTimeoutMS = 250;

    const [state, ctx] = useStaticState<ScreenSaverState>({
        id: "ScreenSaver",
        staticMode: true,
        initial: {
            active: true,
            unlocked: false,
            advancedSettingsTrayOpened: false,
            openedSidePanels: false,
            openCommandPanel: false,
            disableGuardingProcedure: false,
            autoExitScreenSaverOnGuardProcedureSatisfied: false,

            distractionFreeMode: false
        }
    }).stateWithCtx;

    const setState = ctx.setState;

    const cERef = useRef<any>(), passwordInputRef = useRef<HTMLInputElement>();

    const lock = () => {
        setState(prevState => ({
            ...prevState,
            active: true,
            unlocked: false,
            disableGuardingProcedure: false
        }));
    }

    useEffect(() => {
        if (!cERef.current) return;
        const editor = cERef.current;
        editor.setValue("");
        if (state.openCommandPanel) {
            // Command pallet is opened
            editor.focus();
        } else {
            // Command pallet is closed
            passwordInputRef.current?.focus();
        }
    }, [state.openCommandPanel]);

    useEffect(() => api.crudeBroker.observe(ScreenSaverNamespace.SCREEN_SAVER_ACTIVATION_EVENT).on(() => {
        ctx.update({
            active: true,
            activationReason: "manual"
        });
    }).destructor, []);

    const idleRef = useRef<any>();

    useKeyboardEvent("keydown", e => {
        if (e.altKey) {
            switch (e.key) {
                case "s":
                    setState(prevState => ({
                        ...prevState,
                        openedSidePanels: !prevState.openedSidePanels
                    }));
                    break;
                case "k":
                    setState(prevState => ({
                        ...prevState,
                        openCommandPanel: !prevState.openCommandPanel
                    }));
                    break;
                case "l":
                    lock();
                    break;
            }
        }

        escapeExitLogic:
        if (e.key === "Escape") {
            if (state.active) {
                if (!state.unlocked) {
                    break escapeExitLogic;
                }
                setState(prevState => ({
                    ...prevState,
                    active: false,
                    activationReason: undefined
                }));
            } else {
                setState(prevState => ({
                    ...prevState,
                    active: true,
                    activationReason: "manual"
                }));
            }
        }

        // distractionFreeToggleLogic:
        if (e.key === "Escape" && state.active && !state.unlocked) {
            ctx.reverseBool("distractionFreeMode");
        }
    })

    useEffect(() => {
        idleRef.current = setInterval(() => {
            if (data.current.idle > (60e3 * 3)) {
                if (!state.active) {
                    setState(prevState => ({
                        ...prevState,
                        active: true,
                        activationReason: "timeout"
                    }));
                }
            } else {
                data.current.idle += 5e3;
            }
        }, 5e3);

        return () => clearInterval(idleRef.current);
    }, []);

    const data = useRef<any>({
        idle: 0,
        listener: (event: KeyboardEvent) => {
            if (event.key === "Pause" && !state.active) {
                setState(prevState => ({
                    ...prevState,
                    active: true,
                    activationReason: "manual"
                }));
            }
        },
        mouseIdleListener: (event: MouseEvent) => {
            data.current.idle = 0;
        },
        keyboardIdleListener: (event: KeyboardEvent) => {
            data.current.idle = 0;
        },
    });

    useEffect(() => {
        window.addEventListener("keydown", data.current.listener, false);
        window.addEventListener("mousemove", data.current.mouseIdleListener, false);
        window.addEventListener("keydown", data.current.keyboardIdleListener, false);
        return () => {
            window.removeEventListener("keydown", data.current.listener, false);
            window.removeEventListener("mousemove", data.current.mouseIdleListener, false);
            window.removeEventListener("keydown", data.current.keyboardIdleListener, false);
        }
    }, []);

    const absRotationDeg = 45;
    const passcode = "123";

    const checkPasscode = (candidate: string, silent: boolean = true) => {
        if (candidate === passcode) {
            setState(prevState => ({
                ...prevState,
                active: prevState.autoExitScreenSaverOnGuardProcedureSatisfied ? false : prevState.active,
                unlocked: true,
            }));
        }
    }

    type BGState = {
        bgImageID: string | undefined
    }

    const [bgState, bgStateCtx] = useStaticState<BGState>({
        id: "ScreenSaver-bg-state",
        initial: {
            bgImageID: undefined
        }
    }).stateWithCtx;

    const backgroundIMG = useLiveQuery(async () => {
        if (bgState.bgImageID === undefined) {
            return undefined;
        }
        return webDB.images
            .where("id")
            .equals(bgState.bgImageID)
            .first();
    }, [bgState.bgImageID]);

    const apiState = useContext(ArdaiAPIStateContext);
    const api = useContext(ArdaiAPIContext);

    function renderUI() {
        return (
            <div style={{
                display: "grid",
                gridTemplateColumns: "250px 400px 250px",
                width: "100%",
                alignItems: "center",
                gap: "1rem",
                flexDirection: "row"
            }}>
                <motion.div
                    animate={state.openedSidePanels ? "open" : "closed"}
                    style={ !state.openedSidePanels ? {
                        scale: 0,
                    } : undefined}
                    variants={{
                        open: {
                            scale: 1,
                        },
                        closed: {
                            scale: 0,
                            x: "100%",
                            rotate: `${absRotationDeg}deg`
                        }
                    }}
                    children={
                        <ScreenSaverLeftWidget/>
                    }
                />

                <div style={{
                    width: "400px",
                    position: "relative",
                    zIndex: 2
                }}>
                    <StyledScreenSaver style={{
                        // borderRadius: "8px",
                        // backgroundColor: "rgb(16, 16, 22)",
                        width: "400px",
                        padding: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        zIndex: 2,

                        background: "linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))",
                        backdropFilter: "blur(10px)",
                        borderRadius: "20px",
                        border: "1px solid rgba(255, 255, 255, 0.18)",
                        boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.37)",
                    }}>


                        <div className={"screensaver-main-header"} style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",

                            // flexDirection: "row",
                            // justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",


                        }}>


                            <ListItem title={"Exit"} icon={
                                <ArrowLeftRounded sx={{ fontSize: 20 }}/>
                            } onClick={() => navigate("/")}/>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 8,
                                margin: "0 auto",
                            }}>
                                { state.unlocked ? (
                                    // Unlocked
                                    <FlatIconButton
                                        tooltip={"Unlocked"}
                                        children={<LockOpenRounded sx={{ fontSize: 20 }}/>}
                                        onClick={() => lock()}
                                    />
                                ) : (
                                    // Locked
                                    <FlatIconButton
                                        tooltip={"Locked"}
                                        children={<LockRounded sx={{ fontSize: 20 }}/>}
                                        // onClick={() => {
                                        //     setState(prevState => ({
                                        //         ...prevState,
                                        //         openCommandPanel: !prevState.openCommandPanel
                                        //     }));
                                        // }}
                                    />
                                )}
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 4,
                                marginLeft: "auto"
                            }}>
                                <Menu opener={
                                    <FlatIconButton
                                        children={<MoreHorizRounded sx={{
                                            fontSize: 20
                                        }}/>}
                                    />
                                }>
                                    <MenuGroup title={"Experimental / Debug"}>
                                        <MenuButton
                                            text={"Use selected image as bg"}
                                            icon={
                                                <ImageRounded sx={{
                                                    fontSize: 20
                                                }}/>
                                            }
                                            onSelect={() => {
                                                bgStateCtx.update({
                                                    bgImageID: apiState.selectedImageId
                                                });
                                            }}
                                        />
                                    </MenuGroup>
                                </Menu>

                                <FlatIconButton
                                    tooltip={"Toggle command panel"}
                                    active={state.openCommandPanel}
                                    children={<KeyboardCommandKeyRounded sx={{
                                        fontSize: 20
                                    }}/>}
                                    onClick={() => {
                                        setState(prevState => ({
                                            ...prevState,
                                            openCommandPanel: !prevState.openCommandPanel
                                        }));
                                    }}
                                />

                                <FlatIconButton
                                    tooltip={"Toggle panels"}
                                    children={<CalendarViewDayRounded sx={{
                                        fontSize: 20,
                                        transform: "rotate(90deg) translateX(-50%) translateY(50%) !important"
                                    }}/>}
                                    onClick={() => {
                                        setState(prevState => ({
                                            ...prevState,
                                            openedSidePanels: !prevState.openedSidePanels
                                        }));
                                    }}
                                />
                            </div>


                        </div>


                        <span style={{
                            marginTop: "1.5rem",
                            marginBottom: "1.5rem"
                        }} children={
                            <ImageHubBranding centerLayout/>
                        }/>

                        { state.activationReason === "timeout" ? (
                            <DescriptiveTypography style={{
                                textAlign: "center",
                                marginTop: "1rem",
                                marginBottom: "2rem"
                            }} text={"Screen saver turned on due to inactivity of at least one minute. "}/>
                        ) : undefined}

                        <Formik initialValues={{ passcode: "" }} validate={values => {
                            if (values.passcode.length >= 3 || values.passcode !== "123") {
                                return {
                                    passcode: "Incorrect passcode"
                                }
                            }
                        }} validateOnChange onSubmit={values => {}} children={fp => (
                            <div style={{
                                display: "flex",
                                width: "100%",
                                gap: "8px",
                                alignItems: "center",
                                flexDirection: "column"
                            }}>
                                { state.unlocked ? (
                                    <DefaultButton fullwidth variant={"primary"} children={
                                        "Go"
                                    } onClick={() => {
                                        setState(prevState => ({
                                            ...prevState,
                                            active: false
                                        }))
                                    }}/>
                                ) : (
                                    <span style={{
                                        width: "100%"
                                    }} children={
                                        <FormElement title={"Passcode"} children={
                                            <>
                                                <FormikSingleLineInput ref={passwordInputRef} autoFocus placeholder={"numerical passcode"} formikProps={fp} name={"passcode"} baseProps={{
                                                    type: "password",
                                                    onKeyDown: event => {
                                                        if (event.key === "Enter") fp.handleSubmit();
                                                    },
                                                    onChangeCapture: event => {
                                                        if (passcode.length === event.currentTarget.value.length) {
                                                            checkPasscode(event.currentTarget.value, true);
                                                        }
                                                    }
                                                }}/>
                                                <ErrorMessage formikProps={fp} name={"passcode"}/>

                                                {/*
                                                                <SDPromptField h={"60px"}/>
                                                                */}
                                            </>
                                        }/>
                                    }/>
                                ) }


                                <ChevronRightRounded
                                    onClick={() => setState(prevState => ({ ...prevState, advancedSettingsTrayOpened: !prevState.advancedSettingsTrayOpened }))}
                                    data-opened={state.advancedSettingsTrayOpened}
                                    className={"view-opener"}
                                />

                                <TransitionGroup style={{ width: "100%" }} children={
                                    !state.advancedSettingsTrayOpened ? undefined : (
                                        <Collapse children={
                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "8px"
                                            }}>

                                                <ControlledBooleanInput
                                                    title={"Disable passcode"}
                                                    value={state.disableGuardingProcedure}
                                                    helpText={"Disables the guarding procedure"}
                                                    update={bool => {
                                                        setState(prevState => ({
                                                            ...prevState,
                                                            disableGuardingProcedure: bool
                                                        }));
                                                    }}
                                                />

                                                <ControlledBooleanInput
                                                    title={"Auto close screensaver on unlock"}
                                                    value={state.autoExitScreenSaverOnGuardProcedureSatisfied}
                                                    helpText={"Closes the screen saver once the application gets unlocked"}
                                                    update={bool => {
                                                        setState(prevState => ({
                                                            ...prevState,
                                                            autoExitScreenSaverOnGuardProcedureSatisfied: bool
                                                        }));
                                                    }}
                                                />

                                                <ControlledBooleanInput
                                                    title={"Persist lock state across sessions"}
                                                    value={true}
                                                    update={() => {
                                                        throw new Error("Unimplemented")
                                                    }}
                                                />

                                                <FormikFormCheckbox
                                                    formik={fp}
                                                    title={"Disable automatic screen saver"}
                                                    name={"disableAutomaticScreensaverForSession"}
                                                    helpText={"After reloading the screensaver will be turned on again"}
                                                />

                                                <DescriptiveTypography text={`
                                                                        This is a footnote.. something important could stand here, but right now
                                                                        only this replacement text does.
                                                                    `}/>
                                            </div>
                                        }/>
                                    )
                                }/>

                            </div>
                        )}/>
                    </StyledScreenSaver>

                    <motion.div
                        animate={state.openCommandPanel ? "open" : "closed"}
                        variants={{
                            open: {
                                scale: 1,
                            },
                            closed: {
                                scale: 0,
                                y: "-100%",
                            }
                        }}
                        style={{
                            width: "400px",
                            // borderRadius: "8px",
                            padding: "1rem",
                            // backgroundColor: "rgb(16, 16, 22)",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            zIndex: -1,
                            position: "absolute",
                            marginTop: 16,


                            background: "linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0))",
                            backdropFilter: "blur(10px)",
                            borderRadius: "20px",
                            border: "1px solid rgba(255, 255, 255, 0.18)",
                            boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.37)",
                        }}
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%"
                        }}>
                            <ListItem title={"Run"} icon={
                                <PlayArrowRounded sx={{
                                    fontSize: 20
                                }}/>
                            }/>


                            <FlatIconButton children={
                                <CloseRounded sx={{
                                    fontSize: 20
                                }} onClick={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        openCommandPanel: false
                                    }))
                                }}/>
                            }/>
                        </div>

                        <SDPromptField h={"60px"} backgroundColor={Color.transparent} onMount={e => {
                            cERef.current = e;
                        }} onChange={(val, ev) => {
                            if (!cERef.current) return;
                            if (val?.endsWith("\n")) {
                                // window.alert(val?.trim());
                                const command = val?.trim();

                                // TODO Trigger command
                                switch (command) {
                                    case "f11": {
                                        ctx.reverseBool("distractionFreeMode");
                                        break;
                                    }
                                    case "unlock": {
                                        ctx.reverseBool("unlocked");
                                        break;
                                    }
                                    case "lock": {
                                        ctx.reverseBool("unlocked");
                                    }
                                }

                                cERef.current.setValue("");
                            }
                        }}/>
                    </motion.div>
                </div>

                <motion.div
                    animate={state.openedSidePanels ? "open" : "closed"}
                    style={ !state.openedSidePanels ? {
                        scale: 0,
                    } : undefined}
                    variants={{
                        open: {
                            scale: 1,
                        },
                        closed: {
                            scale: 0,
                            x: "-100%",
                            rotate: `-${absRotationDeg}deg`
                        }
                    }}
                    children={
                        <ScreenSaverRightWidget/>
                    }
                />
            </div>
        );
    }

    return (
        <TransitionGroup style={{
            position: "absolute",
            zIndex: 10,
            width: "100%",
            left: 0,
            bottom: 0
        }} children={
            !state.active ? undefined : (
                <Collapse children={
                    <div style={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        // backgroundColor: "rgb(26, 26, 32)",
                    }}>
                        <TransitionGroup children={
                            state.active && (
                                <Fade children={
                                    <div>
                                        <Background config={{
                                            type: BackgroundType.IMAGE,
                                            data: as<BackgroundImageInformation>({
                                                opacity: .2,
                                                srcType: "url",
                                                renderingMode: ImageRenderingMode.AUTO,
                                                srcLink: backgroundIMG?.data !== undefined
                                                    ? URL.createObjectURL(backgroundIMG?.data)
                                                    : BackgroundImages.screensaverBackgroundImage,

                                                // ABSTRACT
                                                // srcLink: "https://mdm-watches.com/wp-content/uploads/2023/03/ezgif.com-video-to-gif.gif"

                                                // PIXEL
                                                // srcLink: "https://i.pinimg.com/originals/b8/2f/28/b82f28a7e9c8fcb3868d3d94652c107c.gif"

                                                // srcLink: "https://i.pinimg.com/originals/55/d6/8e/55d68ed5419f4a6ea1d9cfe2fec06d40.gif"
                                                // srcLink: "https://gifdb.com/images/high/sad-anime-hyouka-houtarou-oreki-rhgamvxt06uc2reg.gif"
                                                // srcLink: "https://i.pinimg.com/originals/89/2d/96/892d96a721d939f6fab7c20d91439177.gif"
                                                // srcLink: "https://ipfs.io/ipfs/QmRJSHhBd46gKPnzz4GP4Dv1KyVuHX1hrDLiyHSq6pqS56/nft.gif"
                                            })
                                            // type: BackgroundType.SOLID,
                                            // data: as<BackgroundSolidInformation>({
                                            //     color: Color.black.toHex()
                                            // })
                                        }}/>
                                        <div style={{
                                            position: "absolute",
                                            width: "100vw",
                                            height: "100vh",
                                            top: 0,
                                            left: 0,
                                            zIndex: -2,
                                            backgroundColor: "black",
                                            // backgroundColor: "rgb(26, 26, 32)",
                                            // backgroundColor: "green",
                                        }}/>
                                    </div>
                                }/>
                            )
                        }/>

                        <TransitionGroup children={ (!state.distractionFreeMode) &&
                            <Zoom children={
                                renderUI()
                            }/>
                        }/>
                    </div>
                }/>
            )
        }/>
    );
}
