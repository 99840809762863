import {NodeSetupInfo} from "../../../NodeSetupInfo";
import {Node} from "../../../../backend/Node";
import {v4} from "uuid";

export const NodePLC: NodeSetupInfo = {
    label: "NodePLC",
    classname: "logic.plc",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "logic.plc",
        label: "plc",
        defInPins: [
            "c",
            //
            "ins",
            "ret"
        ],
        defOutPins: [
            "run"
        ],
        init: function () {

        }
    })
}
