export enum OrientationMapping {
    NORTH,
    EAST,
    SOUTH,
    WEST
}

export function orientationToDegrees(orientation: OrientationMapping): number {
    return orientation * 90
}
