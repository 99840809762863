import {FC, useEffect} from "react";
import {useNodeCanvasBackend} from "./NodeCanvasBackend";
import styled from "styled-components";
import {NodeCanvasObject} from "./NodeCanvasObject";

const StyledNodeCanvasNodeRenderer = styled.div`
  position: relative;
  z-index: 100;
`;

export const NodeCanvasNodeRenderer: FC = props => {
    const backend = useNodeCanvasBackend();

    return (
        <StyledNodeCanvasNodeRenderer className={"layer node-layer"}>
            { backend.environment.visibleNodes.map(node => {
                return (
                    <NodeCanvasObject
                        node={node}
                        key={node.id}
                    />
                );
            }) }
        </StyledNodeCanvasNodeRenderer>
    );
}
