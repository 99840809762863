import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {HSVToRGBUtils} from "../../../../utils/color/HSVToRGBUtils";

export const HSVtoRGBConvertor: NodeSetupInfo = {
    label: "",
    classname: "color.hsv-rgb-convertor",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "color.hsv-rgb-convertor",
        label: "hsv -> rgb",
        defInPins: ["h", "s", "v"],
        defOutPins: ["r", "g", "b"],
        init: function () {

            const recalculate = () => {
                const rgb = HSVToRGBUtils.HSVtoRGB(
                    this.pins.in("h").lastReadState,
                    this.pins.in("s").lastReadState,
                    this.pins.in("v").lastReadState
                )

                this.pins.out("r").write(rgb.r);
                this.pins.out("g").write(rgb.g);
                this.pins.out("b").write(rgb.b);
            }
            ["h", "s", "v"].forEach(key => {
                this.pins.in(key).attach({
                    read(data: any) {
                        recalculate();
                    }
                })
            })
        }
    })
}
