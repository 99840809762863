import {StorybookPageFactory} from "../storybook/StorybookPageFactory";
import {Flex} from "../Flex";
import {StorybookStaticStepIndicator} from "../components/StorybookStaticStepIndicator";
import {TypoHeaderVisual} from "../components/TypoHeaderVisual";
import {arcSocketURL, arcURL} from "../../Globals";
import {SSPPEngine} from "../../shared/SSPPEngine";
import {welcomePage} from "../pages/signup/stories/account/AccountCreatedStoryPage";
import {sendSSPPPacket} from "../../shared/SSPPUtils";
import {EmailVerifyPinCompound} from "../pages/signup/components/EmailVerifyPinCompound";
import {axiosExo} from "../../shared/Exo";
import {CRH} from "../../shared/ConditionalResponseHandler";
import React from "react";
import {StorybookAPI} from "../storybook/StorybookAPI";
import callWith = StorybookAPI.callWith;
import {BoardingFlowMode} from "../pages/boarding/BoardingFlowMode";
import {BoardingActionHint} from "../components/BoardingActionHint";
import axios from "axios";
import {commonCore} from "../api/CommonCore";
import {LoginStatusMode} from "../../shared/LoginStatusMode";

export type EmailVerificationStoryPageFactoryProps = {
    email: string,
    password: string
}

export const emailVerificationStoryPageFactory: StorybookPageFactory<EmailVerificationStoryPageFactoryProps> = params => ({
    name: "verifyEmail",
    header: (api, fp) => (
        <Flex g={30} baseProps={{
            style: {
                flexDirection: "column",
                alignItems: "center"
            }
        }}>
            <StorybookStaticStepIndicator step={5} processingAnimation={fp.isSubmitting}/>
            <TypoHeaderVisual
                header={"Verify email"}
                descriptiveSubheader={"We sent you a verification email"}
                text={"Please enter the verification code we’ve sent you"}
            />
        </Flex>
    ),
    onPageInit: (api, isMaster) => {
        if (!isMaster) return;
        callWith(startVerificationListenerSocket, api, params);
    },
    page: (api, fp) => (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            width: "100%",
            color: "white",
            alignItems: "center"
        }}>
            <BoardingActionHint
                question={"Didn't get the email?"}
                action={"Resent code"}
                onClick={() => {
                    axios({
                        url: arcURL(`resend-email-verification-code/${params.email}`),
                        method: "post"
                    }).then(r => {
                        const emailWasSentSuccessfully = Boolean(r.data);

                        window.alert("emailWasSentSuccessfully " + emailWasSentSuccessfully);
                    })
                }}
            />

            <EmailVerifyPinCompound verify={pin => new Promise<boolean>(resolve => {
                fp.setSubmitting(true);
                axiosExo({
                    method: "post",
                    url: arcURL("account/verifyByCode"),
                    data: {
                        email: params.email,
                        code: pin.toString()
                    }
                }, {
                    crh: new CRH()
                        .ok(() => resolve(true))
                        .when(-200, () => resolve(false))
                        .when(-201, () => resolve(false))
                        .when(-202, () => resolve(false))
                        .when(-203, () => resolve(false))
                }).then(() => {})
            })}/>
        </div>
    )
});

const startVerificationListenerSocket: StorybookAPI.With<any, EmailVerificationStoryPageFactoryProps> = (api, fp, params) => {
    new WebSocket(arcSocketURL("email-verification")).onopen = function (this, ev) {
        console.debug("[Socket] Connection opened")

        // Create response handler & register handshake response packet handler
        new SSPPEngine()
            // Handshake @ response
            .on("response@handshake", ctx => {
                if (ctx.isFlagged("alreadyVerified")) {
                    // The email was already verified :: Skip verification page
                    commonCore().loginByBasicCredentials(params.email, params.password).then(r => {
                        // End loading animation
                        fp.setSubmitting(false);
                        // Reroute if the automated, internal login procedure worked correctly
                        if (r.mode === LoginStatusMode.OK) {
                            api.submitAndForward(welcomePage);
                            return;
                        }
                        window.alert(`[Debug] auto login after account creation went wrong :: ${r.mode}`);
                    });
                }
            })
            // Verification success notification
            .on("email-verified-success", ctx => {
                commonCore().loginByBasicCredentials(params.email, params.password).then(r => {
                    // End loading animation
                    fp.setSubmitting(false);
                    // Reroute if the automated, internal login procedure worked correctly
                    if (r.mode === LoginStatusMode.OK) {
                        api.submitAndForward(welcomePage);
                        return;
                    }
                    window.alert(`[Debug] auto login after account creation went wrong :: ${r.mode}`);
                });
            })
            .bind(this);

        // Send handshake packet
        sendSSPPPacket(this, "handshake", data => {
            data.set("email", params.email);
        });
    };
}
