import {Entity} from "../../ecs/entity/Entity";
import {KSU} from "../../Simulation2Main";
import {VesselMetrics} from "../../VesselMetrics";
import {StdTraits} from "../../traits/StdTraits";
import {ThrustProducerTrait} from "../../traits/ThrustProducerTrait";
import {PolarVec2D} from "../../math/PolarVec2D";
import {Std} from "../../../../../Std";
import clamp = Std.clamp;
import {PIDController} from "../../math/PIDController";
import also = Std.also;
import {castEntityFull} from "../../SimStd";
import {EnvironmentSimulationEntity} from "../ambient/EnvironmentSimulationEntity";

export type ShipMainState = {
    heading: PolarVec2D,
    metrics: VesselMetrics
}

export class ShipMainEntity extends Entity<ShipMainState> {

    constructor() {
        super("ship", {
            initialState: {
                heading: new PolarVec2D(),
                metrics: {
                    mass: 1
                }
            }
        });
    }

    async asyncTick(): Promise<void> {
        this.processCourse();
        return super.asyncTick();
    }

    public getThrustVector(): PolarVec2D {
        return this.simulation.entitiesWithTrait(StdTraits.THRUST_PRODUCER).map(e => {
            const thrustTrait = e.getTrait<ThrustProducerTrait>(StdTraits.THRUST_PRODUCER);
            return thrustTrait.getThrust();
        }).reduceRight((p, c) => p.plus(c))
    }

    public getAmbientWindForce(): PolarVec2D {
        return castEntityFull<EnvironmentSimulationEntity>(this.simulation.entity("env")).state.wind;
    }

    private processCourse() {
        const portAzypod = this.simulation.entity("port-azypod");
        const azyMag = .5 * portAzypod?.state[KSU] ?? 0;
        const azyRot = portAzypod?.state.actualDeg ?? 0;

        const heading = this.state.heading;
        const course = this.getThrustVector()
        const uniformCourseDamping = .1;


        let deltaVel = 0;
        const slowdownSummand = -5;
        const grossPropellerEfficiency = .1;
        deltaVel += slowdownSummand;
        deltaVel += grossPropellerEfficiency * course.r;

        // calc direction
        const newDirection = heading.theta + (course.theta * uniformCourseDamping);

        const newHeading = new PolarVec2D(
            newDirection % 360,
            clamp(heading.r + deltaVel, 0, Math.max(course.r, heading.r))
        );

        this.setStatePartially({
            heading: newHeading
        });
    }
}
