import {WebAPI} from "./WebAPI";
import {webDB} from "./WebAPIDB";
import {Observable} from "./pubsub/Observable";
import {Observer} from "./pubsub/Observer";
import {StateMirror} from "./StateMirror";
import {Optional} from "../../base/Optional";

export abstract class APIShard<
    StateMirrorType extends StateMirror<any> = StateMirror<{}>,
    EventKey = string,
    EventData = any
> implements Observable<EventKey, EventData> {

    private _api: WebAPI | undefined;

    private _observers: Array<Observer<EventKey, EventData>> = [];

    private _stateMirror?: StateMirrorType;

    constructor() {
        this._stateMirror = this.getInitialStateMirror();
    }

    protected getInitialStateMirror(): StateMirrorType | undefined {
        return undefined;
    }

    public init() {}

    public setApi(api: WebAPI) {
        if (this._api === undefined) {
            this._api = api;
        }
    }

    public api(): WebAPI {
        return this._api!;
    }

    protected set stateMirror(mirror: StateMirrorType) {
        this._stateMirror = mirror;
    }

    public get stateMirror(): StateMirrorType {
        return this._stateMirror!;
    }

    notify(key: EventKey, data: EventData): void {
        this.observers.forEach(observer => {
            try {
                observer.notify(key, data)
            } catch (e) {
                // TODO: Log in better way
                console.error(e)
            }
        });
    }

    observe(...keys: Array<EventKey>): Observer<EventKey, EventData> {
        const obs = new Observer<EventKey, EventData>(
            this, keys
        );
        this._observers.push(obs)
        return obs;
    }

    get observers(): Array<Observer<EventKey>> {
        return this._observers;
    }

    unsubscribe(observer: Observer<EventKey, EventData>): void {
        this._observers = this.observers.filter(o => o !== observer);
    }

    unsubscribeAll(): void {
        this._observers = [];
    }
}
