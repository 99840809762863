import {ResourceParamsType} from "./ResourceParamsType";

export class Resource<T, Params extends ResourceParamsType = void> {

    constructor(
        private readonly _get: (params: Params) => T
    ) {}

    public get(params: Params): T {
        return this._get(params);
    }
}
