import React, {ReactNode} from "react";
import styled from "styled-components";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {Color} from "../../base/logic/style/Color";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {MenuItem, SubMenu} from "@szhsin/react-menu";
import {CheckRounded} from "@mui/icons-material";
import {CircularProgress} from "@mui/material";
import {Triton} from "../../triton/Triton";
import {useTriton} from "../../triton/TritonHooks";

const StyledMenu = styled.div`
  .szh-menu__item {
    padding: 0 !important;
    
    &:hover {
      background-color: initial;
    }
  }
  
  .szh-menu__divider {
    margin-top: 2px;
    background-color: #30363d;
    margin-bottom: 2px;
  }
`;

const StyledMenuButton = styled.span<{
    t: Triton,
    iconColor?: string
}>`
  padding: 6px 8px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  // color: rgb(201, 209, 217);
  color: ${p => p.iconColor !== undefined ? p.iconColor : p.t.col("icon_default")};
  transition: background 33.333ms linear 0s;
  text-decoration: none;
  justify-content: space-between;
  gap: 8px;
  
  &[data-disabled=true] {
    opacity: .3;
  }
  
  .menu-main {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 8px;
  }
  
  &[data-disabled=false]:active, &:focus {
    background: rgba(177, 186, 196, 0.2) !important;
  }

  &[data-disabled=false]:hover, &.pointed {
    background: rgba(177, 186, 196, 0.12);
  }
  
  &:hover, &.pointed {
    cursor: pointer;
  }
  
  .menu-icon-tray {
    svg {
      width: 16px;
      height: 16px;
    }
  }
  
  .menu-appendix {
    margin-left: 1rem;
    align-items: center;
    display: flex;
    gap: 4px;
  }
`;

const StyledSubmenuHandler = styled.span`
  width: 100%;
  
  .submenu-handler {    
    width: 100%;
    display: grid;
    
    .szh-menu__item {
      display: block;
      width: 100%;
      padding: 0 !important;
    }
    
    .szh-menu__item:after {
      display: none;
      content: none;
    }
  }
`;

export type MenuButtonProps = {
    text: string | ReactNode,
    color?: Color | string,
    selected?: boolean,
    isPointedTo?: boolean,
    onSelect?: () => void,
    onHover?: () => void,
    icon?: React.ReactNode,
    appendix?: React.ReactNode,
    disabled?: boolean,
    compact?: boolean,
    submenu?: React.ReactNode,
    loading?: boolean,

    keepOpenOnClick?: boolean
}

export const MenuButton: React.FC<MenuButtonProps> = props => {
    const iconSize = "16px"
    const disabled = props.disabled ?? false;
    const mainClassName = `${props.isPointedTo ? "pointed" : ""}`;
    const compact = props.compact ?? false;
    const keepOpen = props.keepOpenOnClick ?? false;
    const t = useTriton();

    if (props.submenu !== undefined) {
        return (
            <StyledSubmenuHandler>
                <SubMenu arrow={true} className={"submenu-handler"} menuStyle={{
                    backgroundColor: "rgb(22, 27, 34)",
                    borderRadius: "12px",
                    boxShadow: "rgba(1, 4, 9, 0.85) 0px 16px 32px",
                    padding: "8px",
                    border: "1px solid rgb(48, 54, 61)"
                }} arrowStyle={{
                    borderLeftColor: "rgb(48, 54, 61)",
                    borderTopColor: "rgb(48, 54, 61)",
                    backgroundColor: "rgb(22, 27, 34)"
                }} label={
                    <MenuButton
                        keepOpenOnClick={keepOpen}
                        appendix={props.appendix}
                        compact={props.compact}
                        icon={props.icon}
                        text={props.text}
                    />
                }>
                    <StyledMenu children={props.submenu}/>
                </SubMenu>
            </StyledSubmenuHandler>
        );
    }

    return (
        <MenuItem disabled={disabled} onClick={e => {
            if (!disabled) {
                props.onSelect?.();
                // Keep open or close

                // https://szhsin.github.io/react-menu#event-handling
                // e.stopPropagation = true;

                e.keepOpen = keepOpen;
            }
        }} children={
            <StyledMenuButton t={t} style={{
                paddingTop: compact ? "2px" : "6px",
                paddingBottom: compact ? "2px" : "6px"
            }} iconColor={props.color !== undefined ? (
                typeof props.color === "string" ? props.color :
                    props.color.css()
            ) : undefined} data-disabled={disabled} className={mainClassName} onMouseEnter={() => props.onHover?.()} onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                // if (!disabled) {
                //     props.onSelect?.(e);
                // }
            }}>
                <div className={"menu-main"}>
                <span className={"menu-icon-tray"} style={{
                    width: iconSize,
                    height: iconSize,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }} children={
                    props.loading ? (
                        <CircularProgress size={16} color={"inherit"}/>
                    ) : props.icon
                }/>
                    { typeof props.text === "string" ? (
                        <MainTypography noSelect text={props.text} style={{
                            flexWrap: "nowrap",
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "400",
                            textDecoration: "none"
                        }}/>
                    ) : props.text}
                </div>

                { props.appendix === undefined ? undefined : (
                    <div className={"menu-appendix"} children={
                        typeof props.appendix !== "string" ? props.appendix : (
                            <DescriptiveTypography text={props.appendix}/>
                        )
                    }/>
                ) }

            </StyledMenuButton>
        }/>

    );
}

export const CheckMenuButton: React.FC<MenuButtonProps & {
    checked?: boolean
}> = props => {
    return (
        <MenuButton {...props} selected={props.checked} icon={
            !props.checked ? undefined : (
                <CheckRounded/>
            )
        }/>
    );
}
