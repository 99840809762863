import React, {PropsWithChildren, useContext, useEffect, useRef, useState} from "react";
import {StateDispatcher} from "../test/core/StateDispatcher";
import {v4} from "uuid";
import {ValveControlDisplay} from "./entities/valve/ValveControlDisplay";
import {CoreControlDisplay} from "./entities/engine/CoreControlDisplay";
import {StyledControlDisplayGroup} from "./components/StyledControlDisplayGroup";
import {StateProxy} from "./ecs/entity/StateProxy";
import {SimulationLayerConfig} from "./SimulationLayerConfig";
import {as} from "../../atlas/Lang";
import {engineCSimulation} from "./EngineCSimulation";
import {ClutchControlDisplay} from "./entities/clutch/ClutchControlDisplay";
import {EngineMasterControlDisplay} from "./entities/engine/EngineMasterControlDisplay";
import {Orientation} from "../../base/logic/style/Orientation";
import {Speedometer} from "../../test/engine/Speedometer";
import {percent} from "../../base/logic/style/DimensionalMeasured";
import {MultiplexedCoreControlPanel} from "./entities/engine/MultiplexedCoreControlPanel";
import {AzypodControlPanel} from "./entities/azypod/AzypodControlPanel";
import {useKeyboardEvent} from "../../ardai/_/keyCommand/test/KeyCommandTest";
import {ShipMovementPanel} from "./entities/ship/ShipMovementPanel";
import {Entity} from "./ecs/entity/Entity";
import {ThrustProducerTrait} from "./traits/ThrustProducerTrait";
import {Trait, TraitIdentifier} from "./ecs/entity/trait/Trait";
import {StdTraits} from "./traits/StdTraits";
import {ButtonBase} from "../../triton/components/buttons/ButtonBase";
import {PIDController} from "./math/PIDController";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {
    AutoModeRounded,
    InvertColorsRounded,
    NorthEastRounded,
    NorthRounded,
    SouthEastRounded
} from "@mui/icons-material";
import {Button} from "./components/Button";
import {hex, ofHex} from "../../base/logic/style/Color";
import {SteeringAPControlPanel} from "./entities/ap/SteeringAPControlPanel";
import {time} from "@tensorflow/tfjs";
import {duration} from "moment";
import {VesselSimulationMain} from "./window/VesselSimulationMain";
import {castEntityFull} from "./SimStd";
import {SteeringAPEntity} from "./entities/ap/SteeringAPEntity";

function reduceAmount(available: number, use: number): [number, number] {
    const x = available - use;
    const next = Math.max(0, x);
    return [next, next >= 0 ? use : use + x];
}

export type EntityData<T = { [K: string]: any }> = T & {
    id: string
}

export type EntityState = {
    entities: {
        [K: string]: EntityData
    }
}

export type GenericEntityState = { [K: string]: any };

export interface TickAble<T = void, V = void> {
    tick(ctx: T): Promise<V>;
}

export type StdEntityPipelineNamespace = "e" | "f" | "h" | "ksu";

/**
 * KSU <=> kinetic-stress-unit
 */
export const [F, KSU] = ["f", "ksu"];

export interface EntityConfig<T = GenericEntityState> {
    syncTick: (e: Entity<T>) => void,
    asyncTick: (e: Entity<T>) => Promise<void>,
    children: Array<Entity>,
    initialState?: T,
    groups: Array<string>,
    loggerEnabled?: boolean,

    namedTraits?: Map<TraitIdentifier, Trait>,
    traits?: Array<Trait>,

    description?: string
}

export class Logger {

    private entity: Entity;

    private _enabled: boolean = false;

    constructor(entity: Entity<any>) {
        this.entity = entity;
    }

    public log(message?: any, ...optionalParams: any[]): void {
        if (this._enabled) {
            console.log(`[${this.entity.id}]`, message, ...optionalParams);
        }
    }

    set enabled(value: boolean) {
        this._enabled = value;
    }
}

export class SimulationLayer implements TickAble {

    private readonly _entities: Map<string, Entity> = new Map<string, Entity>();

    private es?: EntityState;

    private setES?: StateDispatcher<EntityState>;

    private config: SimulationLayerConfig<EntityState>;

    private proxy: StateProxy<EntityState>;

    constructor(config: SimulationLayerConfig<EntityState>) {
        this.config = config;
        this.es = config.state;
        this.setES = config.dispatcher;
        this.proxy = new StateProxy<EntityState>(this.es, this.setES);
    }

    public init() {
        this.config.simulation.init(this);
    }

    public updateEntityStateBridge(es: EntityState, setES: StateDispatcher<EntityState>) {
        this.es = es;
        this.setES = setES;
        this.proxy.syncToReact(es, setES);
    }

    public addEntities(controlled: boolean, ...entities: Entity<any>[]): SimulationLayer {
        entities.forEach(e => {
            const id = e.id ?? v4();
            e.setID(id);
            const simID = v4();
            e.setSimulationID(simID);
            e.integrateIntoSimulation(this);
        });

        if (controlled) entities.forEach(e => this._entities.set(e.id, e));
        this.initEntityStates(...entities);
        return this;
    }

    public initEntityStates(...entities: Entity[]) {
        let newEntityStateSet: { [K: string]: EntityData } = {};
        entities.forEach(e => {
            newEntityStateSet[e.simulationID] = {
                id: e.id,
                simID: e.simulationID,
                ...e.getInitialState()
            };
        });
        this.setState(prevState => ({
            ...prevState,
            entities: {
                ...prevState.entities,
                ...newEntityStateSet
            }
        }));
    }

    public async tick() {
        this.proxy.branch();
        for (let e of Array.from(this._entities.values())) await e.tick();
        this.proxy.commit();
    }

    public get state(): EntityState {
        // return this.es!;
        return this.proxy.state;
    }

    public get setState(): StateDispatcher<EntityState> {
        // return this.setES!;
        return this.proxy.setState;
    }

    public entity(id: string): Entity {
        return this._entities.get(id)!;
    }

    public entitiesWithTrait(traitName: TraitIdentifier): Array<Entity> {
        return Array
            .from(this._entities.values())
            .filter(e => e.hasTrait(traitName));
    }

    public getAllEntities(): Array<Entity> {
        return Array.from(this._entities.values());
    }
}

export class Pipe<T = any> {

    private readonly _arr: Array<T> = [];

    public link(that: T): T {
        this._arr.push(that);
        return that;
    }

    public first(): T {
        return this.arr[0];
    }

    get arr(): Array<T> {
        return this._arr;
    }
}

export const generateInitialSimulationState = () => as<EntityState>({
    entities: {}
})

export const Simulation2Main: React.FC = props => {
    const [es, setES] = useState<EntityState>(generateInitialSimulationState());

    const sl = useRef(new SimulationLayer({
        simulation: engineCSimulation,
        dispatcher: setES,
        state: es
    }));

    // Test-bench
    useEffect(() => {
        // ...
    }, []);

    // Handle simulation loop
    useEffect(() => {
        const tps = 1;
        const tickInterval = 1e3 / tps;
        const loop = setInterval(async () => {
            const kernelStart = Date.now();
            await sl.current.tick();
            const kernelEnd = Date.now();

            console.log("tick kernel:", kernelEnd - kernelStart, "ms")

        }, tickInterval);
        return () => clearInterval(loop);
    }, []);

    // Init simulation
    useEffect(() => {
        sl.current.init();
    }, []);

    useKeyboardEvent("keydown", e => {
        switch (e.key) {
            case 'ArrowLeft': {
                const sim = sl.current;
                const steeringAP = castEntityFull<SteeringAPEntity>(sim.entity("auto"));
                // const headingController = as<PIDController>(steeringAP?.state["headingController"]);
                const headingController = steeringAP?.getHeadingPIDController();
                const offset = 5;
                if (steeringAP.state.engaged) {
                    steeringAP.offsetManualHeading(offset);
                } else {
                    sl.current.entity("port-azypod").setStatePartially(prevState => ({
                        requestedDeg: prevState.requestedDeg + offset
                    }));
                }
                break;
            }
            case 'ArrowRight': {
                const sim = sl.current;
                const steeringAP = castEntityFull<SteeringAPEntity>(sim.entity("auto"));
                // const headingController = as<PIDController>(steeringAP?.state["headingController"]);
                const headingController = steeringAP?.getHeadingPIDController();
                const offset = -5;
                if (steeringAP.state.engaged) {
                    steeringAP.offsetManualHeading(offset);
                } else {
                    sl.current.entity("port-azypod").setStatePartially(prevState => ({
                        requestedDeg: prevState.requestedDeg + offset
                    }));
                }
                break;
            }
        }
    });

    return (
        <SimulationStateContext.Provider value={[es, setES]} children={
            <SimulationContext.Provider value={sl.current} children={
                <SimulationStateMaster children={
                    <VesselSimulationMain/>
                }/>
            }/>
        }/>
    );
}

export const SimulationContext = React.createContext<SimulationLayer>(new SimulationLayer({
    simulation: engineCSimulation,
    state: generateInitialSimulationState(),
    dispatcher: () => {}
}));

export const SimulationStateContext = React.createContext<[
    EntityState?, StateDispatcher<EntityState>?
]>([]);

export const SimulationStateMaster: React.FC<PropsWithChildren> = props => {
    const [es, setES] = useContext(SimulationStateContext);
    const sl = useContext(SimulationContext);
    useEffect(() => {
        if (es === undefined || setES === undefined) return;
        sl.updateEntityStateBridge(es, setES);
    }, [es, setES, sl]);
    return (<>{ props.children }</>);
}
