import {NodeDependent} from "./NodeDependent";
import {Node} from "./Node";
import {Pin, PinMode} from "./Pin";
import {PinListener} from "./PinListener";
import {PinBankListener} from "./PinBankListener";
import {PinBankVisualConfig} from "./PinBankVisualConfig";
import {v4} from "uuid";
import {FQBankId, mkFQBankId} from "./FQBankId";

export type PinBankConfig = {
    label: string,
    dynamicSizeFlag?: boolean,
    hideLabelFlag?: boolean,
    groups?: Array<string>,
    defaultPinMode?: PinMode,

    visualConfig?: PinBankVisualConfig
}

export class PinBank<Key = string> extends NodeDependent {

    public readonly pins: Map<Key, Pin> = new Map<Key, Pin>();

    public readonly listeners: Array<PinBankListener> = [];

    constructor(
        node: Node,
        public readonly config: PinBankConfig
    ) { super(node) }

    public isInGroup(group: string): boolean {
        if (this.config.groups === undefined) return false;
        return this.config.groups.includes(group);
    }

    public get isGroupless(): boolean {
        if (this.config.groups === undefined) return true;
        return this.config.groups.length === 0;
    }

    public pin(key: Key): Pin {
        if (!this.pins.has(key)) {
            const newPin = new Pin(this.node, this, {
                label: String(key),
                mode: this.config.defaultPinMode,
            });
            this.pins.set(key, newPin);
            // TODO: Notify about new pin
            return newPin;
        }
        return this.pins.get(key)!;
    }

    public removePinByLabel(key: Key): this {
        if (!this.pins.has(key)) return this;
        this.pins.delete(key);
        return this;
    }

    public onPinRead(targetPin: Pin, value: any) {
        this.listeners.forEach(l => {
            l.onPinRead(targetPin, value);
        });
    }

    public attach(listener: PinBankListener): this {
        this.listeners.push(listener);
        return this;
    }

    public get allPins(): Array<Pin> {
        return Array.from(this.pins.values());
    }

    public get fqId(): FQBankId {
        return mkFQBankId(this.node.fqId.node, this.config.label, this.config.label);
    }
}
