import React, {FC, useEffect} from "react";
import {Node} from "../../backend/Node";
import styled from "styled-components";
import {NodeDisplay} from "../NodeDisplay";
import {useTriton} from "../../../triton/TritonHooks";
import {Triton} from "../../../triton/Triton";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {Menu} from "../../../ardai/components/Menu";
import {AbcRounded, MoreHorizRounded, NumbersRounded, ToggleOn} from "@mui/icons-material";
import {MenuButton} from "../../../ardai/components/MenuButton";
import {useNodeCanvasBackend} from "../NodeCanvasBackend";
import {useForceRenderFunc} from "../../../ForceRerenderHook";
import {PinDetailsListItem} from "../PinDetailsListItem";
import {MenuDivider} from "@szhsin/react-menu";
import {Tag} from "../../../ardai/components/Tag";
import {NodeCanvasBackendEventTypes} from "../NodeCanvasBackendEventTypes";
import {NodeEvent} from "../../backend/NodeEvent";
import {AttachmentGroupMember} from "../../backend/attachments/defaults/AttachmentGroupMember";
import {TBPinDetails} from "../nodes/tb/NodeTBAdapter";
import {Separator} from "../../../base/components/base/Separator";
import {MenuGroup} from "../../../ardai/components/MenuGroup";
import {DataManipulationDisplayWrapper} from "./dataManipulation/DataManipulationDisplayWrapper";
import {Boolean} from "../nodes/inputs/Boolean";
import {BooleanManipulationDisplay} from "./dataManipulation/BooleanManipulationDisplay";
import {NumericManipulationDisplay} from "./dataManipulation/NumericManipulationDisplay";
import {StringManipulationDisplay} from "./dataManipulation/StringManipulationDisplay";

const StyledNodeInfoSidebarDisplay = styled.div<{
    t: Triton
}>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content min-content auto min-content;
  gap: 8px;
  padding: 16px;
  background-color: ${p => p.t.col("bg_main")};
  
  border-radius: 8px;
  
  .node-preview-container {    
    overflow: scroll;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    // This is intentional -> for rendering the preview grid
    min-height: 301px;
    
    // background-color: ${p => p.t.col("bg_menu")};

    .node-preview-wrapper {
      z-index: 2;
    }
    
    &:before {
      --grid-spacing: 25px;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      background: conic-gradient(
              from 90deg at 1px 1px, #0000 90deg, ${p => p.t.col("bg_modal")} 0
      ) 0 0/var(--grid-spacing) var(--grid-spacing);
    }
  }
  
  .node-preview-info-header {
    padding: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    .title-line {
      width: 100%;
      display: grid;
      grid-template-columns: auto min-content;
      gap: 4px;
      align-items: center;
    }
  }
`;

export type NodeInfoSidebarDisplayProps = {
    node: Node
}

export const NodeInfoSidebarDisplay: FC<NodeInfoSidebarDisplayProps> = props => {
    const { node } = props;
    const backend = useNodeCanvasBackend();
    const t = useTriton();

    const forceRender = useForceRenderFunc();

    useEffect(() => node.observer.observeGlobally().on(forceRender).destructor, []);

    return (
        <StyledNodeInfoSidebarDisplay t={t}>
            <div className={"node-preview-container"}>
                <div className={"node-preview-wrapper"}>
                    <NodeDisplay node={node} isPreview/>
                </div>
            </div>

            <div className={"node-preview-info-header"}>
                <div className={"title-line"}>
                    <DescriptiveTypography text={node.config.label} noSelect/>
                    <Menu opener={
                        <MoreHorizRounded sx={{
                            fontSize: 20,
                            color: t.col("fg_muted"),
                            cursor: "pointer"
                        }}/>
                    }>
                        <MenuButton text={"Delete node"} onSelect={() => {
                            backend.deleteNode(node.id);
                        }}/>

                        <MenuDivider/>

                        <MenuButton text={"Log node state"} onSelect={() => {
                            console.log(node.state)
                        }}/>
                    </Menu>
                </div>
                <DescriptiveTypography text={node.config.description}/>
            </div>

            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 8
            }}>

                <MenuGroup title={"Pins"}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4
                    }}>
                        { node.pins.filterAllPins(() => true).map(pin => (
                            <TBPinDetails
                                key={pin.config.id}
                                pin={pin}
                            />
                        )) }
                    </div>
                </MenuGroup>

                {/*
                <MenuGroup title={"Pins"}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4
                    }}>
                        { node.pins.filterAllPins(() => true).map(pin => (
                            <PinDetailsListItem
                                key={pin.config.id}
                                pin={pin}
                            />
                        )) }
                    </div>
                </MenuGroup>
                */}

                <MenuGroup title={"State"}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8
                    }}>
                        { node.state?.state && Object.entries(node.state.state).map((e, idx) => {
                            return (
                                <StateEntryDisplay
                                    key={`${node.id}-${e[0]}-${idx}`}
                                    objectKey={e[0]}
                                    value={e[1]}
                                    type={typeof e[1]}
                                    onUpdate={(updated, at) => {
                                        if (at.length > 1) throw new Error("Nested updates not supported yet.")
                                        node.state.update({
                                            [at[0]]: updated
                                        });
                                    }}
                                />
                            );
                        }) || <DescriptiveTypography text={"This node is stateless"}/> }
                    </div>
                </MenuGroup>
            </div>

            {/*  */}
            <div className={"trait-prevent-selection-collapse"} style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                overflow: "scroll"
            }}>
                {/* TODO: transfer logic to backend -> in Node maybe? */}
                <Tag tag={`debug: mark secondary selected node as sub-node`} onClick={() => {
                    const nodeTargetIdAttr = "data-node-target-id";
                    const secondarySelectedNodeHtml = backend.state.ui.selection.secondary?.htmlNodes?.filter(e => e.hasAttribute(nodeTargetIdAttr))?.[0];
                    if (secondarySelectedNodeHtml === undefined) return;
                    const attr = secondarySelectedNodeHtml.getAttributeNode(nodeTargetIdAttr);
                    if (attr === null) return;
                    const newSubNodeId = attr.value;
                    // Store 'group-membership' attachment on the sub node
                    const subNode = backend.environment.getNodeByID(newSubNodeId);
                    subNode.isHidden = true;
                    subNode.attachments.set("group-membership", {
                        groupParentNodeId: node.id
                    } satisfies AttachmentGroupMember);
                    // Store sub node reference -> todo: why not add an attachment? -> 'group-parentship'
                    node._testSubNode = newSubNodeId;

                    backend.events.notify(NodeCanvasBackendEventTypes.GENERIC, new NodeEvent());
                }}/>

                {/* TODO: transfer logic to backend -> in Node maybe? */}
                <Tag tag={`debug: remove sub-node`} onClick={() => {
                    if (node._testSubNode === undefined) return;
                    const subNode = backend.environment.getNodeByID(node._testSubNode);
                    subNode.isHidden = false;
                    subNode.attachments.delete("group-membership");
                    node._testSubNode = undefined;

                    backend.events.notify(NodeCanvasBackendEventTypes.GENERIC, new NodeEvent());
                }}/>
            </div>

            {/*
            <div>
                <ReactJson src={node.state?.state || {}} theme={"grayscale"}/>
            </div>
            */}
        </StyledNodeInfoSidebarDisplay>
    );
}

type BasicStateTypes = "undefined" | "object" | "boolean" | "number" | "string" | "function" | "symbol" | "bigint";

const StateEntryDisplay: FC<{
    objectKey: string,
    value: any,
    type: BasicStateTypes,
    onUpdate: (updated: any, at: string[]) => void
}> = props => {

    if (props.type === "object") {

        if (Array.isArray(props.value)) {
            const arr = props.value as any[];

            return (
                <MenuGroup title={props.objectKey} showIndentationLine indentation={8}>
                    {
                        arr.map((e, idx) => {
                            return (
                                <StateEntryDisplay
                                    key={idx} // TODO: Refactor
                                    objectKey={String(idx)}
                                    value={e}
                                    type={typeof e}
                                    onUpdate={() => { /* TODO: Implement */ }}
                                />
                            );
                        })
                    }
                </MenuGroup>
            );
        }

        return (
            <MenuGroup title={props.objectKey} showIndentationLine indentation={8}>
                {
                    Object.entries(props.value).map(e => {
                        return (
                            <StateEntryDisplay
                                objectKey={e[0]}
                                value={e[1]}
                                type={typeof e[1]}
                                onUpdate={() => { /* TODO: Implement */ }}
                            />
                        );
                    })
                }
            </MenuGroup>
        );
    }

    else if (props.type === "boolean") {
        return (
            <DataManipulationDisplayWrapper<boolean>
                label={props.objectKey}
                icon={<ToggleOn/>}
                children={ctx => (
                    <BooleanManipulationDisplay
                        value={props.value}
                        onUpdate={value => {
                            props.onUpdate(value, [props.objectKey]);
                        }}
                    />
                )}
            />
        );
    }

    else if (props.type === "number") {
        return (
            <DataManipulationDisplayWrapper<number>
                label={props.objectKey}
                icon={<NumbersRounded/>}
                children={ctx => (
                    <NumericManipulationDisplay
                        value={props.value}
                        onUpdate={value => {
                            props.onUpdate(value, [props.objectKey]);
                        }}
                    />
                )}
            />
        );
    }

    else if (props.type === "string") {
        return (
            <DataManipulationDisplayWrapper<string>
                label={props.objectKey}
                icon={<AbcRounded/>}
                children={ctx => (
                    <StringManipulationDisplay
                        value={props.value}
                        onUpdate={value => {
                            props.onUpdate(value, [props.objectKey]);
                        }}
                    />
                )}
            />
        );
    }

    // TODO: Can be removed?
    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "min-content auto",
            alignItems: "center",
            width: "100%",
            gap: 8
        }}>
            <DescriptiveTypography
                text={`${props.objectKey}: ${props.type}`}
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }}
            />
            <DescriptiveTypography
                text={String(props.value)}
                style={{
                    textAlign: "end",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }}
            />
        </div>
    );
}
