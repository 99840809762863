import React, {useRef, useState} from "react";
import axios from "axios";
import {Post} from "./models/Post";
import styled from "styled-components";
import {useInfiniteQuery} from "@tanstack/react-query";
import {useIntersection} from "@mantine/hooks";
import {GenericMasonry} from "./GenericMasonry";
import {PostCard} from "./PostCard";
import {motion, MotionConfig, useMotionValue, useTransform} from "framer-motion"
import {AnimatePresence} from "framer-motion";
import {Page} from "framer";
import {PostView} from "./PostView";

const StyledSamplePostMasonryView = styled.div`
  
`;

const fetchArcPosts = async (page: number = 0) => {
    const coercedPageNum = Math.max(page, 0);
    console.log(`Fetching posts page: ${coercedPageNum}`)
    // const res = await axios.get(`http://192.168.178.20:8080/proto/posts/${coercedPageNum}`);
    const res = await axios.get(`http://localhost:8080/proto/posts/${coercedPageNum}`);
    // const res = await axios.get(`http://192.168.178.20:8080/proto/all-posts/`);
    return res.data as Array<Post>;
}

export type SamplePostMasonryViewProps = {
    queryKey?: Array<string>
}

export const SamplePostMasonryView: React.FC<SamplePostMasonryViewProps> = props => {

    const [exhausted, setExhausted] = useState(false);

    const queryKey = props.queryKey ?? ["posts"];

    // noinspection SpellCheckingInspection
    const {
        data,
        error,
        fetchNextPage,
        isFetching,
        isFetchingNextPage,
        refetch,
        status
    } = useInfiniteQuery({
        queryKey: queryKey,
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => pages.length,
        queryFn: async context => {
            const page = context.pageParam as number;
            const coercedPageNum = Math.max(page, 0);
            // console.log(`Fetching posts page: ${coercedPageNum}`)
            const res = await axios.get(`http://192.168.178.20:8080/proto/posts/${coercedPageNum}`);
            // const res = await axios.get(`http://localhost:8080/proto/posts/${coercedPageNum}`);
            const posts = res.data as Array<Post>;
            if (posts.length === 0) setExhausted(true);
            else if (exhausted) setExhausted(false);
            return posts;
        }
    });

    const lastPostRef = useRef<HTMLElement>(null);

    const { ref, entry } = useIntersection({
        root: lastPostRef.current,
        threshold: 1
    });


    const posts = data?.pages.flatMap((t) => t as Post) ?? [];


    const rowWidth = 2;
    const triggerOffset = rowWidth * 8;
    if (entry?.isIntersecting && !exhausted && !isFetching) {
        const triggerElem = entry.target;
        if (triggerElem.id === posts[posts.length - 1 - triggerOffset].id) {
            fetchNextPage().then(() => {});
        }
    }


    const [selectedId, setSelectedId] = useState<string | null>(null);
    const x = useMotionValue(0);
    const scale = useTransform(x, [-150, 0, 150], [0.5, 1, 0.5])
    const iRange = 100, oRange = 5;
    const rotate = useTransform(x, [-iRange, 0, iRange], [-oRange, 0, oRange], {
        clamp: true,
    })

    return (
        <StyledSamplePostMasonryView>
            {
                status === 'pending' ? (
                    <p>Loading...</p>
                ) : status === 'error' ? (
                    <p>Error: {error.message}</p>
                ) : (
                    <>
                        <div id={"post-fullscreen-portal"} style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            zIndex: 10
                        }}/>

                        <GenericMasonry cols={2} items={posts} keyExtractor={d => d.id} renderer={(post, i, arr) => {
                            const isLast = i === arr.length - 1 - triggerOffset;
                            const card = (
                                <PostView post={post}/>
                            );

                            // if (isLast) window.alert(`rendering a 'last item' (i=${i})`)

                            if (isLast) return (
                                <div ref={ref} id={post.id} children={card} style={{
                                    boxShadow: "0 0 0 3px red",
                                    backgroundColor: "rgba(255, 0, 0, 0.25)"
                                }}/>
                            )
                            return card;
                        }}/>



                        <AnimatePresence children={selectedId && (() => {
                            const post = posts.filter(p => p.id === selectedId)[0];
                            return (
                                <motion.div layoutId={selectedId} drag dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }} variants={{
                                    animate: { scale: 1, y: 0, opacity: 1 },
                                    exit: (custom) => ({ x: custom, opacity: 0, scale: 0.5 })
                                }} style={{
                                    backgroundColor: "white",
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    boxShadow: "0 0 0 8px white",
                                    borderRadius: 10,
                                    zIndex: 10,
                                    rotate,
                                    scale,
                                    x,
                                }} transition={{
                                    // type: "spring",
                                    stiffness: 300,
                                    // stiffness: 100,
                                    // stiffness: 10,
                                    damping: 10,
                                    // duration: 10
                                }} onDragEnd={(event, info) => {
                                    const threshold = 100;
                                    if (info.offset.x < -threshold || info.offset.x > threshold || info.offset.y < -threshold || info.offset.y > threshold) {
                                        setSelectedId(null);
                                    }
                                }}>
                                    <motion.img
                                        className={"post-image"}
                                        src={`http://192.168.178.20:8080/proto/static/${post.ressourceIDs[0]}.png`}
                                        // rc={`http://localhost:8080/proto/static/${post.ressourceIDs[0]}.png`}
                                        alt={post.ressourceIDs[0]}
                                        loading="lazy"
                                        style={{
                                            width: "100%",
                                            objectPosition: "contain",
                                            height: "auto",
                                        }}
                                    />

                                    <motion.h2 style={{
                                        margin: 0,
                                        fontFamily: "Whitney",
                                        // color: "#000000",
                                        fontSize: 14,
                                        fontWeight: 700
                                    }}>{post.title}</motion.h2>

                                    <motion.button onClick={() => setSelectedId(null)} children={"<- back"} />

                                    {/*
                                        <Page directionLock direction={"horizontal"} style={{ position: "relative", width: "100%" }}>
                                            <motion.div>
                                                <motion.img
                                                    key={post.id}
                                                    src={`http://192.168.178.20:8080/proto/static/${post.ressourceIDs[0]}.png`}
                                                    alt={post.ressourceIDs[0]}
                                                    loading="lazy"
                                                    style={{
                                                        width: "100%",
                                                        objectPosition: "contain",
                                                        height: "auto",
                                                    }}
                                                />
                                            </motion.div>
                                            <motion.div style={{ backgroundColor: "red", width: "100%", height: 100 }}>B</motion.div>
                                            <motion.div style={{ backgroundColor: "red", width: "100%", height: 100 }}>C</motion.div>
                                        </Page>
                                        */}

                                </motion.div>
                            );
                        })()}/>

                        {/*
                        <div>
                            <button onClick={() => fetchNextPage()} disabled={exhausted || isFetchingNextPage} children={
                                isFetchingNextPage ? 'Loading more...' : 'Load More'
                            }/>

                            <button onClick={() => refetch()} disabled={isFetching} children={
                                isFetchingNextPage ? 'Loading more...' : 'Refresh'
                            }/>
                        </div>

                        <div>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
                        */}
                    </>
                )
            }
        </StyledSamplePostMasonryView>
    );
}
