import React, {PropsWithChildren, useRef} from "react";
import styled from "styled-components";
import {PolarVec2D} from "../../math/PolarVec2D";
import {AnnotationRowConfig} from "./AnnotationRowConfig";
import {AnnotationRow} from "./AnnotationRow";
import {TransitionGroup} from "react-transition-group";

const StyledAnnotatedElementBase = styled.div`
  position: relative;
  
  .element-tray {
    z-index: 1;
  }
  
  .annotation-tray {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // aspect-ratio: 1 / 1;
    // height: 100px;
    gap: 4px;
    text-align: center;
    // top: 50%;
    // left: 50%;
    // transform: translateX(-50%) translateY(-50%);
    
    transform-origin: center;
    
    color: white;
    font-size: 11px;
    // border: 1px solid crimson;
    offset-rotate: 0deg;
    offset-position: center;

    // animation: moveImg 5s infinite;
    

    @keyframes moveImg {
      100%  { offset-distance: 100%; }
    }
  }
`;

export type AnnotatedElementBaseProps = PropsWithChildren<{
    annotations?: Array<AnnotationRowConfig>,
    annotationPosition?: PolarVec2D
}>

export const AnnotatedElementBase: React.FC<AnnotatedElementBaseProps> = props => {
    const annotations = props.annotations ?? [];
    const elemRef = useRef<HTMLDivElement>(null);

    return (
        <StyledAnnotatedElementBase>

            <div ref={elemRef} className={"element-tray"} children={props.children}/>


            <div className={"annotation-tray"} style={{
                offsetPath: `circle(${(() => {
                    const rec = elemRef.current?.getBoundingClientRect();
                    const size = !rec ? 0 : Math.max(rec.height, rec.height);
                    const gap = props.annotationPosition?.r ?? 30;
                    return size + gap;
                })()}px)`
            }} children={
                annotations.map(ann => (
                    <AnnotationRow annotation={ann}/>
                ))
            }/>
        </StyledAnnotatedElementBase>
    );
}
