import {as} from "../../../../../atlas/Lang";
import {TabConfig} from "../TabConfig";
import React from "react";
import {DoorEntityDisplay} from "../../../entities/door/DoorEntityDisplay";
import {castEntityFull} from "../../../SimStd";
import {DoorEntity} from "../../../entities/door/DoorEntity";
import {AnnotatedElementBase} from "../../components/AnnotatedElementBase";
import {ElementAnnotationUtils} from "../../components/ElementAnnotationUtils";
import {Std} from "../../../../../../Std";
import {FlatIconButton} from "../../../../../ardai/components/FlatIconButton";
import {UnfoldLessRounded, UnfoldMoreRounded} from "@mui/icons-material";
import {Vec2D} from "../../../math/Vec2D";
import {PolarVec2D} from "../../../math/PolarVec2D";

export const mainTab = as<TabConfig>({
    id: "_main",
    staticTabName: "Main",
    renderer: ctx => {
        const sim = ctx.sim;
        const door = sim.entity("ship-structure")?.getChild("deck-0")?.getChild("stairway-a-door");
        const doorTyped = castEntityFull<DoorEntity>(door);

        return (
            <>
                { door && (

                    <AnnotatedElementBase annotationPosition={new PolarVec2D(0, 0)} annotations={[
                        {
                            renderer: () => (
                                <div style={{ rotate: "90deg" }} children={
                                    <FlatIconButton onClick={() => doorTyped.toggleDoor()} children={
                                        !doorTyped.state.closed ? <UnfoldLessRounded/> : <UnfoldMoreRounded/>
                                    }/>
                                }/>
                            )
                        }
                    ]} children={
                        <DoorEntityDisplay entity={doorTyped}/>
                    }/>
                ) }
            </>
        );
    }
})
