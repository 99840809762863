import {StorybookPage} from "../../../../storybook/StorybookPage";
import {birthdatePage} from "./BirthdatePage";
import {Flex} from "../../../../Flex";
import {StorybookStaticStepIndicator} from "../../../../components/StorybookStaticStepIndicator";
import {TypoHeaderVisual} from "../../../../components/TypoHeaderVisual";
import {Grid} from "../../../../../../../triton/components/layouts/Grid";
import {LinkLikeButton} from "../../../../components/LinkLikeButton";
import {FormElement} from "../../../../../../../triton/components/forms/FormElement";
import {RoundedFormikCheckboxInput} from "../../../../components/form/RoundedFormikCheckboxInput";
import {ErrorMessage} from "../../../../../../../triton/components/forms/ErrorMessage";
import {RoundedMainTextButton} from "../../../../components/RoundedMainTextButton";
import {CircularProgress} from "@mui/material";
import {BoardingActionHint} from "../../../../components/BoardingActionHint";
import React from "react";

export const tosPage: StorybookPage<{ accept: boolean }> = {
    name: "tos",
    title: "Terms & Privacy",
    backPage: () => birthdatePage,
    initialValues: { accept: false },
    validate: (values, errors) => {
        if (!values.accept) errors.accept = "You have to agree to the Terms & Privacy to create an account";
    },
    header: (api, fp) => (
        <Flex g={30} baseProps={{
            style: {
                flexDirection: "column",
                alignItems: "center"
            }
        }}>
            <StorybookStaticStepIndicator step={4}/>
            <TypoHeaderVisual
                header={"Almost there"}
                descriptiveSubheader={"Please accept your Terms of Service"}
                text={"We need to know how old you are, to provide you with the best and safest content"}
            />
        </Flex>
    ),
    page: (api, fp) => (
        <>
            <Grid style={{ width: "100%" }} gTC={"repeat(2, 1fr)"}>
                <LinkLikeButton text={"Terms"} onClick={() => {}}/>
                <LinkLikeButton text={"Privacy"} onClick={() => {}}/>
            </Grid>

            <FormElement>
                <RoundedFormikCheckboxInput
                    fp={fp}
                    name={"accept"}
                />
                <ErrorMessage formikProps={fp} name={"accept"}/>
            </FormElement>
        </>
    ),
    bottomActionPanel: (api, fp, isSubmitting) => (
        <RoundedMainTextButton
            type={"submit"}
            text={!fp.isSubmitting ? "Create account" : (
                <CircularProgress size={22} thickness={5} style={{ color: "black" }}/>
            )}
            style={{
                backgroundColor: "#FFEAC4"
            }}
            // onClick={() => api.submitAndForward(verifyEmailPage)}
            onClick={() => api.submitPage(() => {})}
        />
    ),
    footer: (api) => (
        <BoardingActionHint question={"Already have an account?"} action={"Log In"} onClick={() => {}}/>
    ),
    onSubmit: async (api, values) => {
        await api.signal("create-account", undefined);
    }
}
