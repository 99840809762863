import {Entity} from "../Entity";
import {v4} from "uuid";
import {as} from "../../../../../atlas/Lang";
import {StdTraits} from "../../../traits/StdTraits";
import {TickAble} from "../../../Simulation2Main";

export type TraitIdentifier = string | StdTraits

export type TraitConfig = {
    traitName: TraitIdentifier
}

const newDefaultTraitConfig = () => as<TraitConfig>({
    traitName: v4()
})

export class Trait implements TickAble {

    private config: TraitConfig;

    constructor(config: Partial<TraitConfig> | undefined = undefined) {
        const defConf = newDefaultTraitConfig();
        this.config = {
            ...defConf,
            ...config
        };
    }

    public get traitName(): TraitIdentifier {
        return this.config.traitName;
    }

    private _entity?: Entity<any>;

    get entity(): Entity<any> {
        return this._entity!!;
    }

    set entity(value: Entity<any>) {
        this._entity = value;
    }

    async tick(ctx: void) {
        this.syncTick();
    }

    public syncTick() {}
}

