import React, {useRef, useState} from "react";
import {DescriptiveTypography} from "../triton/components/typography/DescriptiveTypography";
import {Canvas, ThreeElements, useFrame} from "@react-three/fiber";
import {Euler, Mesh} from "three";
import {Html, OrbitControls} from '@react-three/drei'
import {ArdaiMain} from "../ardai/ArdaiMain";
import {AuthTestMain} from "../test/auth/AuthTestMain";
import {AppHub} from "../../hub/AppHub";
import {BrowserRouter, Router, Routes} from "react-router-dom";
import {ArdspaceRoutingMain} from "../ardspace/ArdspaceRoutingMain";
import {DefaultButton} from "../ardai/components/DefaultButton";

export namespace ShuttleTest {

    export const ShuttleTestMain: React.FC = props => {
        return (
            <ShuttleMain/>
        );
    }

    const ShuttleMain: React.FC = props => {
        return (
            <Canvas camera={{ position: [0, 0, -30], fov: 55 }}>
                <ambientLight />
                <pointLight position={[10, 10, 10]} />
                <TestHtmlContent/>
                <Box position={[-1.2, 0, 0]} />
                <Box position={[1.2, 0, 0]} />
                <OrbitControls enablePan={false} enableZoom={false} minPolarAngle={Math.PI / 2.2} maxPolarAngle={Math.PI / 2.2} />
            </Canvas>
        );
    }

    function TestHtmlContent() {
        return (
            <Html position={[0, 0, 0]} transform occlude>
                <div style={{
                    width: 414,
                    height: 896,
                    borderRadius: 20,
                    overflow: "hidden",
                    backgroundColor: "rgba(16,16,22,0.66)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <DefaultButton children={"Hello world"}/>
                </div>
            </Html>
        );
    }

    function Box(props: ThreeElements['mesh']) {
        const ref = useRef<Mesh>(null!)
        const [hovered, hover] = useState(false)
        const [clicked, click] = useState(false)
        useFrame((state, delta) => (ref.current.rotation.x += delta))
        return (
            <mesh
                {...props}
                ref={ref}
                scale={clicked ? 1.5 : 1}
                onClick={(event) => click(!clicked)}
                onPointerOver={(event) => hover(true)}
                onPointerOut={(event) => hover(false)}>
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
            </mesh>
        )
    }
}
