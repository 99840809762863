import {StatusRegister} from "./StatusRegister";

export class StatusRegisterDefaultOperations {

    constructor(
        private readonly status: StatusRegister
    ) {}

    public deactivated(isDeactivated: boolean): this {
        this.status.set("deactivated", isDeactivated);
        return this;
    }

    public get isDeactivated(): boolean {
        return this.status.eq("deactivated", true);
    }

    public error(isError: boolean): this {
        this.status.set("error", isError);
        return this;
    }

    public get isError(): boolean {
        return this.status.eq("error", true);
    }
}
