import React, {useContext} from "react";
import styled from "styled-components";
import {Workspace} from "../Workspace";
import {ISADBImageGrid} from "../ISADBImageGrid";
import {ArdaiAPIStateContext} from "../../ArdaiMain";

const StyledImageSelectionPreview = styled.div`
`;

export const ImageSelectionPreview: React.FC = props => {
    const state = useContext(ArdaiAPIStateContext);
    return (
        <StyledImageSelectionPreview>
            <Workspace config={{ mode: "desktop", name: "ImageSelectionPreview" }} children={
                <ISADBImageGrid imageIDs={state.selectedImages} preferPreviewImage/>
            }/>
        </StyledImageSelectionPreview>
    );
}
