import {CSSProperties, FC} from "react";
import {Pos} from "../../Pos";
import {ControlPoint} from "./ControlPoint";
import {getPinWireHtmlID} from "../../NodeFrontendUtils";
import styled from "styled-components";
import {useTriton} from "../../../../triton/TritonHooks";

const StyledStdWire = styled.svg` 
    .wire {
        
    }
    
    .wire-bleed-area {
        stroke: transparent;
        
        &:hover {
            // stroke: rebeccapurple;
        }
    }
`;

export type StdWireProps = {
    points: Array<ControlPoint>,
    scale?: number,
    lineStyle?: CSSProperties
}

export const StdWire: FC<StdWireProps> = props => {
    const t = useTriton();
    const scale = props.scale ?? 1;
    const points = props.points;
    const wireBleedArea = 10;

    return (
        <StyledStdWire xmlns="http://www.w3.org/2000/svg" style={{
            pointerEvents: "none",
            overflow: "visible",
            scale: scale,
            // width: "100vw",
            // height: "100vh",
            position: "absolute",
            left: 0,
            top: 0,
            // zIndex: 1,
            color: t.col("fg_muted"),
        }}>
            <polyline
                points={points.map(p => `${p.x},${p.y}`).join(" ")}
                className={"wire"}
                strokeLinejoin={"round"}
                // strokeDasharray={doesAPortHaveAnError ? 5 : 0}
                color={"currentcolor"}
                style={{

                    strokeDasharray: 5,

                    fill: "none",
                    stroke: "currentcolor",
                    strokeWidth: 1,
                    pointerEvents: "stroke",
                    ...props.lineStyle ?? {}
                }}
            />

            <polyline
                className={"wire-bleed-area"}
                points={points.map(p => `${p.x},${p.y}`).join(" ")}
                strokeLinejoin={"round"}
                style={{
                    fill: "none",
                    // stroke: "transparent",
                    strokeWidth: wireBleedArea,
                    pointerEvents: "stroke",
                    cursor: "pointer"
                }}
                onClick={() => {

                }}
            />
        </StyledStdWire>
    );
}
