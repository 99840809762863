import {TestRequest} from "./TestRequest";
import {TestContext} from "./TestContext";
import {TestResult} from "./TestResult";
import {TestResultElement} from "./TestResultElement";
import {TestResultState} from "./TestResultState";
import {TestResultElementAnnotationType} from "./TestResultElementAnnotationType";
import {TestExitSignal} from "./TestExitSignal";

export class TestReactor {

    constructor(
        private readonly test: TestRequest
    ) {}

    public run(): TestResult {
        const result: TestResult = {
            results: []
        };

        this.test.suite.tests.forEach((test, i) => {
            let elem: TestResultElement = {
                test: test,
                state: TestResultState.PASSED,
                annotations: []
            };
            const ctx = new TestContext({
                updateElement: updater => {
                    const res = updater(elem);
                    if (res !== undefined) elem = res;
                }
            });
            try {
                test.body.call(ctx);
            } catch (e) {
                if (e !instanceof TestExitSignal) {
                    console.log("exited manually")
                } else {
                    elem.annotations.push({
                        type: TestResultElementAnnotationType.ERROR,
                        subtype: "exception",
                        data: String(e)
                    });
                    ctx.increaseLevelTo(TestResultState.FAILED);
                }
            }
            result.results.push(elem);
        });

        return result;
    }
}
