import React, {useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {PromptType} from "./PromptType";
import {useTriton} from "../../../../../triton/TritonHooks";
import {PromptEditingMode} from "./PromptEditingMode";
import {MobilePromptTabState} from "./MobilePromptTab";
import {SDInterfaceAPIContext} from "../../../../../imageSorter/sdInterface/SDInterfaceAPI";
import {SDInterfaceRequestContext, SDInterfaceStateContext} from "../../../../../imageSorter/sdInterface/SDInterfaceMain";
import {SDPromptField} from "../../../../../imageSorter/sdInterface/SDPromptField";
import {Color} from "../../../../../base/logic/style/Color";

const StyledMobilePromptCodeEditor = styled.div`
  display: flex;
  flex-direction: column;
  // grid-template-rows: auto min-content;
  position: relative;
  overflow: hidden;
  height: 100%;
  gap: 8px;
  
  .mobile-prompt-code-editor-main-wrapper {
    border-radius: 8px;
    background-color: black;
    margin: 0 16px;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
    overflow: scroll;
    
    .mobile-prompt-code-editor-main {
      width: 100%;
      height: 1000px;
    }
  }

  /*
  .mobile-prompt-code-editor-toolbar {
    border-radius: 8px;
    background-color: black;
    margin: 0 16px;
    padding: 0 16px;
    height: 46px;
    display: grid;
    flex-shrink: 0;
    grid-template-columns: auto min-content;
    gap: 8px;
    align-items: center;
  }
  */
`;

export type MobilePromptCodeEditorProps = {
    promptType: PromptType
}

export const MobilePromptCodeEditor: React.FC<MobilePromptCodeEditorProps> = props => {
    const t = useTriton();
    const api = useContext(SDInterfaceAPIContext);
    const pCol = t.col("button_primary");
    const state = useContext(SDInterfaceStateContext);
    const code = props.promptType === PromptType.POSITIVE ? api.req.prompt : api.req.negativePrompt;

    const ref = useRef({
        updatePrompt: (val: string, type: PromptType) => {
            console.log(`${props.promptType} :: 'code '${code}' -> update '${val}'`);
            api.updateRequest({
                [type === PromptType.POSITIVE ? "prompt" : "negativePrompt"]: val ?? ""
            });
        }
    });

    return (
        <StyledMobilePromptCodeEditor>
            <div className={"mobile-prompt-code-editor-main-wrapper"}>
                <div className={"mobile-prompt-code-editor-main"}>
                    <SDPromptField
                        onChange={value => ref.current.updatePrompt(value ?? "", props.promptType)}
                        key={`p-editor-for-${props.promptType}`}
                        backgroundColor={Color.black}
                        value={code}
                    />
                </div>
            </div>

            {/*
            <div className={"mobile-prompt-code-editor-toolbar"}>

            </div>
            */}
        </StyledMobilePromptCodeEditor>
    );
}
