import React from "react";
import {StyledModal} from "../StyledModal";
import {FunctionsRounded} from "@mui/icons-material";
import {ButtonBase} from "../../../triton/components/buttons/ButtonBase";
import {ControlModalCompound, ModalRenderer} from "../ControlModalCompound";
import styled from "styled-components";
import {sortingActions} from "./SortingActions";

export const StyledAddActionDialog = styled.div`
  .actions-preview-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
`;

export const AddActionDialog: React.FC = props => {
    return (
        <StyledModal title={"Add sorting action"} icon={<FunctionsRounded/>} footer={
            <ButtonBase text={"Add action"} baseProps={{
                style: {
                    width: "100%"
                }
            }}/>
        }>
            <ControlModalCompound controller={ctx => (
                <div className={"actions-preview-list"} children={sortingActions.map(sa => (
                    <ButtonBase text={sa.previewTitle} baseProps={{
                        onClick: async () => {
                            const params = await sa.buildActionParams(ctx, {
                                images: [],
                            });
                            ctx.close();
                        }
                    }}/>
                ))}/>
            )}/>

        </StyledModal>
    );
}
