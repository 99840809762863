import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import _ from "lodash";

export const SignalFrequencySensor: NodeSetupInfo = {
    label: "",
    classname: "utils.frequency-sensor",
    parameterConfig: [],
    factory: parameters => new Node<{
        freq: number,
        movingAvgFreq: number,
        probe: number,
        movingAvgFreqProbes: Array<number>
        movingAvgFreqProbeMaxAmount: number
    }>({
        id: v4(),
        classname: "utils.frequency-sensor",
        defInPins: ["fIn"],
        defOutPins: ["f", "fAvg"],
        label: "freq probe",
        state: {
            freq: 0,
            movingAvgFreq: 0,
            probe: 0,
            movingAvgFreqProbes: [],
            movingAvgFreqProbeMaxAmount: 10
        },
        init: function () {
            const node = this;

            this.pins.in("fIn").attach({
                read(signal: any) {
                    node.state.update(prevState => {
                        return {
                            probe: prevState.probe + 1
                        }
                    });
                }
            });

            const probeIntervalInS = 1;
            setInterval(() => {
                node.state.update(prevState => {
                    const freq = prevState.probe === 0 ? 0 : prevState.probe / probeIntervalInS;
                    const movingAvgFreqProbes = [freq, ...prevState.movingAvgFreqProbes].slice(0, prevState.movingAvgFreqProbeMaxAmount ?? 10);

                    const movingAvgFreq = movingAvgFreqProbes.reduceRight((p, c) => p + c) / movingAvgFreqProbes.length;

                    node.pins.out("f").write(freq);
                    node.pins.out("fAvg").write(movingAvgFreq);

                    return {
                        freq,
                        movingAvgFreqProbes,
                        movingAvgFreq,
                        probe: 0
                    }
                });
            }, probeIntervalInS * 1e3)
        },
        customRenderer: node => {
            return (
                <div style={{
                    padding: "8px 0",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    // justifyContent: "start",
                    alignItems: "end"
                }}>
                    <DescriptiveTypography text={`${_.padStart(String(node.state.state.freq), 5, " ").slice(0, 5)} Hz`} style={{
                        whiteSpace: "pre"
                    }}/>
                    <DescriptiveTypography text={`moving avg = ${_.padStart(String(node.state.state.movingAvgFreq), 5, " ").slice(0, 5)} Hz`} style={{
                        whiteSpace: "pre",
                        fontSize: 10
                    }}/>
                </div>
            );
        }
    })
}
