import {StorybookPage} from "../../../../storybook/StorybookPage";
import axios from "axios";
import {arcURL} from "../../../../../Globals";
import {Flex} from "../../../../Flex";
import {TypoHeaderVisual} from "../../../../components/TypoHeaderVisual";
import {FormElement} from "../../../../../../../triton/components/forms/FormElement";
import {RoundedFormikBaseInput} from "../../../../components/form/RoundedFormikBaseInput";
import {ErrorMessage} from "../../../../../../../triton/components/forms/ErrorMessage";
import {RoundedMainTextButton} from "../../../../components/RoundedMainTextButton";
import {CircularProgress} from "@mui/material";
import {BoardingActionHint} from "../../../../components/BoardingActionHint";
import React from "react";
import {RoundedFormikPasswordInput} from "../../../../components/form/RoundedFormikPasswordInput";
import {StandardizedAPIResponse} from "../../../../../shared/StandardizedAPIResponse";
import {StorybookAPI} from "../../../../storybook/StorybookAPI";
import {DescriptiveTypography} from "../../../../../../../triton/components/typography/DescriptiveTypography";
import {LoginStatus} from "../../../../../shared/LoginStatus";
import {LoginStatusMode} from "../../../../../shared/LoginStatusMode";
import {BoardingFlowMode} from "../../../boarding/BoardingFlowMode";
import {ButtonBase} from "../../../../../../../triton/components/buttons/ButtonBase";
import {axiosExo} from "../../../../../shared/Exo";
import {CRH} from "../../../../../shared/ConditionalResponseHandler";
import {Menu} from "../../../../../../../ardai/components/Menu";
import {MenuButton} from "../../../../../../../ardai/components/MenuButton";
import {Grid} from "../../../../../../../triton/components/layouts/Grid";
import {emailVerificationStoryPageFactory} from "../../../../commonStoryPages/EmailVerificationStoryPage";

const loginFlow = (email: string, password: string) => new Promise<LoginStatus>((resolve, reject) => {
    axios({
        url: arcURL("login"),
        method: "post",
        data: {
            password: password,
            email: email
        }
    }).then(res => {
        const data: StandardizedAPIResponse = res.data;
        // OK
        if (data.code === 0 && data.payload !== null) {
            const crudeToken = data.payload.refreshToken;
            const jwt = data.payload.jwt;
            // Set crudeToken & and jwt to the local-store
            localStorage.setItem("master-token", crudeToken);
            localStorage.setItem("session-token", jwt);
            // Set Bearer token
            axios.defaults.headers.common = {'Authorization': `Bearer ${jwt}`}
            // Login was fully successful :: Redirect to origin page
            resolve({
                mode: LoginStatusMode.OK
            });
        }

        // INCORRECT CREDENTIALS
        if (data.code === -100) {
            resolve({
                mode: LoginStatusMode.INCORRECT_CREDENTIALS
            });
        }

        // EMAIL NOT VERIFIED
        if (data.code === -101) {
            resolve({
                mode: LoginStatusMode.VERIFY_IP
            });
        }
    }).catch(reason => reject(reason));
})

const handleLogin = async (api: StorybookAPI) => {
    const fp = api.fpRef.current!;
    fp.setSubmitting(true);
    const values = fp.values;
    if (fp.errors.email || fp.errors.password) return;
    const status = await loginFlow(values.email, values.password);
    fp.setSubmitting(false);
    switch (status.mode) {
        case LoginStatusMode.OK:
            api.forward({
                name: "lul",
                page: () => (
                    <Grid gTC={"repeat(2, 1fr) min-content"} style={{ width: "100%" }}>
                        <ButtonBase text={"Get my resOwnID"} baseProps={{
                            onClick: async () => {
                                await axiosExo({
                                    url: arcURL("pong-my-resource-id"),
                                    method: "get"
                                }, {
                                    crh: new CRH().ok(response => {
                                        window.alert(`resourceOwnerID: '${response.payload}'`)
                                    })
                                })
                            }
                        }}/>
                        <ButtonBase text={"Logout"} baseProps={{
                            onClick: async () => {
                                await axiosExo({
                                    url: arcURL("logout"),
                                    method: "post"
                                }, {
                                    crh: new CRH().ok(response => {
                                        window.localStorage.removeItem("master-token");
                                        window.localStorage.removeItem("session-token");
                                        window.location.reload();
                                    })
                                })
                            }
                        }}/>
                        <Menu>
                            <MenuButton text={"Clear all tokens"} onSelect={() => {
                                localStorage.removeItem("master-token");
                                localStorage.removeItem("session-token");
                                window.location.reload();
                            }}/>

                            <MenuButton text={"Purge all accounts"} onSelect={async () => {
                                await axiosExo({
                                    url: arcURL("purge-accounts"),
                                    method: "delete"
                                })
                                localStorage.removeItem("master-token");
                                localStorage.removeItem("session-token");
                                window.location.reload();
                            }}/>
                        </Menu>
                    </Grid>
                )
            });
            break;
        case LoginStatusMode.INCORRECT_CREDENTIALS:
            api.fp.setErrors({
                email: "Incorrect username or password",
                password: "Incorrect username or password"
            })
            break;
        case LoginStatusMode.VERIFY_IP:
            api.forward(emailVerificationStoryPageFactory({
                email: values.email,
                password: values.password
            }));
            break;
        case LoginStatusMode.ACCOUNT_BLOCKED:
            api.forward({
                name: "ACCOUNT_BLOCKED",
                page: () => <DescriptiveTypography text={"ACCOUNT_BLOCKED"}/>
            });
            break;
    }
}

export const credentialsPage: StorybookPage<{ email: string, password: string }> = {
    name: "credentials",
    title: "Credentials",
    initialValues: { email: "", password: "" },
    validateOnChange: true,
    validate: async (values, errors) => {
        // Preflight checks
        if (!values.email.includes("@")) errors.email = "Invalid email (Email must include an '@')"
        if (values.email.trim().length === 0) errors.email = "Email cannot be blank";
        if (values.password.trim().length === 0) errors.password = "Password cannot be blank";
        // Prevent unnecessary traffic
        if (errors.email || errors.password) return;
    },
    header: (api, fp) => (
        <Flex g={30} baseProps={{
            style: {
                flexDirection: "column",
                alignItems: "center"
            }
        }}>
            <TypoHeaderVisual
                header={"Welcome back!"}
                descriptiveSubheader={"We’re happy to see you again. Please log in to your account"}
                text={"We’ll get you scrolling in under one minute!"}
            />
        </Flex>
    ),
    page: (api, fp) => (
        <>
            <FormElement>
                <RoundedFormikBaseInput
                    fp={fp}
                    name={"email"}
                    type={"email"}
                    placeholder={"Email"}
                    autoCorrect={"false"}
                />
                <ErrorMessage formikProps={fp} name={"email"}/>
            </FormElement>
            <FormElement>
                <RoundedFormikPasswordInput
                    fp={fp}
                    name={"password"}
                    placeholder={"Password"}
                />
                <ErrorMessage formikProps={fp} name={"password"}/>
            </FormElement>
        </>
    ),
    bottomActionPanel: (api, fp, isSubmitting) => (
        <RoundedMainTextButton
            text={!fp.isSubmitting ? "Log In" : (
                <CircularProgress size={22} thickness={5} style={{ color: "black" }}/>
            )}
            onClick={() => api.submitPage(() => handleLogin(api))}
        />
    ),
    footer: (api) => (
        <BoardingActionHint
            question={"Don’t have an account?"}
            action={"Sign up"}
            onClick={() => api.signal(
                "switch-to-signup",
                BoardingFlowMode.SIGNUP,
                () => api.getNavigateFunction()("signup")
            )}
        />
    )
}
