import React, {useContext, useState} from "react";
import {Color} from "../../../base/logic/style/Color";
import {PropsDeclarationPrefix} from "../../../ardai/testing/PropsDeclarationPrefix";
import {CCPContext} from "./ControlledColorPicker";

export type ColorPickerManualViewState = {
    hexFloatingValue: string | undefined,
}

export const ColorPickerManualView: React.FC = props => {
    const ccp = useContext(CCPContext)!;

    const [state, setState] = useState<ColorPickerManualViewState>({
        hexFloatingValue: undefined
    });

    const update = (color: Color) => {
        ccp.updateColor(color);
        // state.debouncedChangeHandler(color);
    }

    return (
        <>
            <input
                className={"color-input"}
                type={"color"}
                value={ccp.color.toHex()}
                onChange={e => ccp.updateColor(Color.ofHex(e.target.value))}
            />

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}>
                <PropsDeclarationPrefix name={"color"} type={"hex"}/>
                &nbsp;
                <span children={"#"} style={{
                    fontSize: 13,
                    fontFamily: "Consolas, \"Courier New\", monospace"
                }}/>
                &nbsp;
                <input
                    spellCheck={false}
                    className={"color-input"}
                    value={state.hexFloatingValue ?? ccp.color.toHex("")}
                    onChange={e => {
                        try {
                            const color = Color.ofHex(e.target.value);
                            setState(prevState => ({ ...prevState, hexFloatingValue: undefined }));
                            ccp.updateColor(color);
                        } catch (_) {
                            setState(prevState => ({ ...prevState, hexFloatingValue: e.target.value }));
                        }
                    }}
                />
            </div>

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}>
                <PropsDeclarationPrefix name={"color"} type={"rgb"}/>
                &nbsp;
                <span children={"rgb("} style={{
                    fontSize: 13,
                    fontFamily: "Consolas, \"Courier New\", monospace"
                }}/>
                &nbsp;
                <input
                    spellCheck={false}
                    className={"color-input rgb-shard"}
                    value={ccp.color.r}
                    min={0}
                    max={255}
                    type={"number"}
                    onChange={e => ccp.updateChannel(Number(e.target.value), "r")}
                />
                ,
                <input
                    spellCheck={false}
                    className={"color-input rgb-shard"}
                    value={ccp.color.g}
                    min={0}
                    max={255}
                    type={"number"}
                    onChange={e => ccp.updateChannel(Number(e.target.value), "g")}
                />
                ,
                <input
                    spellCheck={false}
                    className={"color-input rgb-shard"}
                    value={ccp.color.b}
                    min={0}
                    max={255}
                    type={"number"}
                    onChange={e => ccp.updateChannel(Number(e.target.value), "b")}
                />
                &nbsp;
                <span children={")"} style={{
                    fontSize: 13,
                    fontFamily: "Consolas, \"Courier New\", monospace"
                }}/>
            </div>
        </>
    );
}
