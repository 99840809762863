/**
 * TODO: Move to folder std/
 */
export namespace Std {

    // Math

    export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
    export const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));
    export const invlerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x));
    export const range = (x1: number, y1: number, x2: number, y2: number, a: number) => lerp(x2, y2, invlerp(x1, y1, a));

    // Lang

    export function also<T>(obj: T, fun: (this: T, o: T) => void | T): T {
        const res = fun.call(obj, obj);
        return res === undefined ? obj : res;
    }

    export const using = <T, V = void>(obj: T, fun: (this: T, o: T) => V): V => fun.call(obj, obj);
}
