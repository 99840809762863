import React, {CSSProperties, PropsWithChildren, ReactNode} from "react";
import styled from "styled-components";
import {TextSettings} from "./TextSettings";
import {colorLikeToColorObject} from "./ColorLike";
import {Color} from "../../../base/logic/style/Color";

const defaultFallbackTextSettings: TextSettings = {
    ff: "Whitney",
    fw: "normal",
    fs: 16,
    col: Color.black
}

const StyledText = styled.p<TextSettings>`
  padding: 0;
  margin: 0;
  font-weight: ${p => p.fw};
  font-size: ${p => p.fs}px;
  font-family: ${p => p.ff};
  color: ${p => colorLikeToColorObject(p.col!).css()};
`;

export type TextProps = TextSettings & PropsWithChildren<{
    base?: TextSettings,
    // text
    t?: ReactNode
    // style
    style?: CSSProperties,
    // handlers
    onClick?: () => void
}>

export const Text: React.FC<TextProps> = props => {
    const overrides: TextSettings = props;
    const base = props.base;
    const computed: TextSettings = {
        ...defaultFallbackTextSettings,
        ...base ?? {},
        ...overrides
    };

    return (
        <StyledText {...computed} children={props.t ?? props.children} style={props.style} onClick={props.onClick}/>
    );
}
