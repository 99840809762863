import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {NodeDisplay} from "../../NodeDisplay";
import {FC, useEffect, useState} from "react";
import {Pin, PinMode} from "../../../backend/Pin";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {
    ArrowRightRounded,
    CheckBoxOutlineBlankRounded,
    CheckBoxRounded,
    InputRounded,
    LooksOneRounded,
    LooksTwoRounded,
    OutputRounded,
    VisibilityRounded
} from "@mui/icons-material";
import styled from "styled-components";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";
import {useTriton} from "../../../../triton/TritonHooks";
import {Triton} from "../../../../triton/Triton";
import {useNodeCanvasBackend} from "../../NodeCanvasBackend";
import _ from "lodash";
import {useForceRenderFunc} from "../../../../ForceRerenderHook";
import {Tag} from "../../../../ardai/components/Tag";
import {NodeCanvasBackendEventTypes} from "../../NodeCanvasBackendEventTypes";
import {NodeEvent} from "../../../backend/NodeEvent";

export const NodeTBAdapter: NodeSetupInfo = {
    label: "NodeTBAdapter",
    classname: "tb.adapter",
    parameterConfig: [],
    factory: parameters => new Node<NodeTBAdapterDisplayState>({
        id: v4(),
        classname: "tb.adapter",
        label: "tb adapter",
        state: {
            renderTargetNodePreview: false
        },
        customRenderer: (node) => (
            <NodeTBAdapterDisplay node={node}/>
        )
    })
}

const StyledNodeTBAdapterDisplay = styled.div<{
    t: Triton
}>`
  .node-preview-container {
    width: 99%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    border-radius: 8px;
    border: 1px solid ${p => p.t.col("bg_modal")};
    overflow: hidden;
    box-sizing: border-box;
    
    .node-preview-wrapper {
      z-index: 2;
    }

    &:before {
      --grid-spacing: 25px;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: -1px;
      top: -1px;
      z-index: 1;
      background: conic-gradient(
              from 90deg at 1px 1px, #0000 90deg, ${p => p.t.col("bg_modal")} 0
      ) 0 0/var(--grid-spacing) var(--grid-spacing);
    }
  }
`;

type NodeTBAdapterDisplayState = {
    renderTargetNodePreview: boolean,
    attachedToNodeId?: string,
    boundToNodeId?: string
}

const NodeTBAdapterDisplay: FC<{
    node: Node<NodeTBAdapterDisplayState>
}> = props => {
    const t = useTriton();
    const backend = useNodeCanvasBackend();
    const thisNode = props.node;
    const state = thisNode.state.state;

    const isAttached = state.attachedToNodeId !== undefined;
    const isBound = state.boundToNodeId !== undefined;
    const isFree = !isAttached && !isBound;

    const targetNodeId = isAttached ? state.attachedToNodeId! : (
        isBound ? state.boundToNodeId! : (
            backend.state.ui.selection.prime.selectedNodeId
        )
    );

    if (targetNodeId === undefined) {
        return (
            <></>
        );
    }

    const targetNode = backend.environment.getNodeByID(targetNodeId);

    if (targetNode === undefined) {
        return (
            <>Cannot resolve node</>
        );
    }

    // Safety measure to not run into an endless render loop
    if (targetNode === props.node) return <></>;

    const renderTargetNodePreview = state.renderTargetNodePreview ?? false;

    return (
        <StyledNodeTBAdapterDisplay t={t} style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "8px 0",
            gap: 8
        }}>
            { renderTargetNodePreview && (
                <div className={"node-preview-container"}>
                    <div className={"node-preview-wrapper"}>
                        <NodeDisplay node={targetNode} isPreview/>
                    </div>
                </div>
            ) }

            {/*
            <NodeDisplay isPreview node={targetNode}/>
            */}

            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 4
            }}>
                { targetNode.pins.filterAllPins(() => true).map(pin => (
                    <TBPinDetails
                        key={pin.config.id}
                        pin={pin}
                    />
                )) }
            </div>

            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                width: "100%",
            }}>
                { !isAttached && (
                    <Tag tag={<VisibilityRounded sx={{ fontSize: 14 }}/>} onClick={() => {
                        thisNode.state.update(prevState => ({
                            renderTargetNodePreview: !prevState.renderTargetNodePreview
                        }));
                    }}/>
                ) }

                { isAttached ? (
                    <Tag tag={"detach"} onClick={() => {
                        targetNode._testSubNode = undefined;
                        thisNode.isHidden = false;
                        thisNode.state.update(prevState => ({
                            renderTargetNodePreview: true,
                            attachedToNodeId: undefined
                        }));
                        backend.events.notify(NodeCanvasBackendEventTypes.GENERIC, new NodeEvent())
                    }}/>
                ) : (
                    <Tag tag={"attach"} onClick={() => {
                        targetNode._testSubNode = thisNode.id;
                        thisNode.isHidden = true;
                        thisNode.state.update(prevState => ({
                            renderTargetNodePreview: false,
                            attachedToNodeId: targetNode.id
                        }));
                        backend.events.notify(NodeCanvasBackendEventTypes.GENERIC, new NodeEvent())
                    }}/>
                ) }

                { isFree && (
                    <Tag tag={"bind"} onClick={() => {
                        if (thisNode.config.visualConfig === undefined) {
                            thisNode.config.visualConfig = {};
                        }
                        thisNode.config.visualConfig!.labelAppendix = targetNode.config.label;
                        thisNode.state.update(prevState => ({
                            boundToNodeId: targetNode.id
                        }));
                    }}/>
                ) }

                { isBound && (
                    <Tag tag={"unbind"} onClick={() => {
                        if (thisNode.config.visualConfig !== undefined) {
                            thisNode.config.visualConfig!.labelAppendix = undefined;
                        }
                        thisNode.state.update(prevState => ({
                            boundToNodeId: undefined
                        }));
                    }}/>
                ) }
            </div>
        </StyledNodeTBAdapterDisplay>
    );
}

const StyledTBPinDetails = styled.div`
  * {
    font-family: "JetBrains Mono", monospace;
  }
`;

export const TBPinDetails: FC<{
    pin: Pin
}> = props => {
    const t = useTriton();
    const p = props.pin;
    const label = p.label;

    // TODO: Be more fine grained with the updates -> Does it even work?
    const forceRender = useForceRenderFunc();
    useEffect(() => p.node.observer.observeGlobally().on(() => {
        forceRender();
    }).destructor, []);

    const [state, setState] = useState({
        localValue: "",
        useLocalValue: false
    });

    const resolvePinValue = (): any => {
        if (p.mode === PinMode.OUT) {
            return p.lastWriteState;
        } else {
            return p.lastReadState;
        }
    }

    const resolveValue = (): any => {
        if (state.useLocalValue) {
            return state.localValue;
        } else {
            return resolvePinValue();
        }
    }

    const pushValue = () => {
        const value = resolveValue();
        if (p.mode === PinMode.OUT) {
            p.write(value);
        } else {
            p.onRead(value)
        }
    }

    const isOutputMode = p.mode === PinMode.OUT;

    return (
        <StyledTBPinDetails style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            backgroundColor: t.col("bg_menu"),
            color: t.col("fg_secondary"),
            gap: 8,
            borderRadius: 8,
            paddingLeft: 4,
        }}>
            { isOutputMode ? (
                <OutputRounded sx={{
                    fontSize: 14
                }}/>
            ) : (
                <InputRounded sx={{
                    fontSize: 14
                }}/>
            ) }

            <DescriptiveTypography text={_.padStart(label.slice(0, 3), 3, " ")} style={{
                whiteSpace: "pre",
                width: 22
            }}/>

            <div style={{
                display: "grid",
                gap: 4,
                gridTemplateColumns: "repeat(2, 1fr)",
                alignItems: "center"
            }}>
                <TBPinValue
                    value={p.mode === PinMode.OUT ? p.lastWriteState : p.lastReadState}
                    channelActive={!state.useLocalValue}
                />
                <TBPinValue
                    value={state.localValue}
                    channelActive={state.useLocalValue}
                    writable
                    onEdit={value => {
                        setState(prevState => ({
                            ...prevState,
                            localValue: value
                        }));
                    }}
                />
            </div>

            <div onClick={() => {
                setState(prevState => ({
                    ...prevState,
                    useLocalValue: !prevState.useLocalValue
                }));
            }}>
                { state.useLocalValue && (
                    <LooksTwoRounded sx={{
                        fontSize: 14,
                        cursor: "pointer"
                    }}/>
                ) || (
                    <LooksOneRounded sx={{
                        fontSize: 14,
                        cursor: "pointer"
                    }}/>
                ) }
            </div>

            <ArrowRightRounded sx={{
                cursor: "pointer"
            }} onClick={() => {
                pushValue();
            }}/>
        </StyledTBPinDetails>
    );
}

const StyledTBPinValue = styled.div<{
    t: Triton
}>`
  width: 80px;
  
  .active-channel-indicator {
    aspect-ratio: 1 / 1;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .flat-looking-input {
    background-color: transparent;
    outline: none;
    border: none;
    // color: ${p => p.t.col("fg_secondary")};
    color: currentColor;
    font-size: 12px;
  }
  
  &[data-active=false] {
    color: ${p => p.t.colObj("fg_secondary").withAlpha(.5).css()} !important;
  }

  &[data-active=true] {
    color: ${p => p.t.col("color_secondary")} !important;
  }
`;

export function TBPinValue(props: {
    value: string,
    channelActive?: boolean,
    writable?: boolean,
    onEdit?: (value: string) => void
}) {
    const t = useTriton();
    return (
        <StyledTBPinValue data-active={props.channelActive} t={t} style={{
            display: "grid",
            gridTemplateColumns: "min-content auto",
            gap: 4,
            alignItems: "center"
        }}>
            <div className={"active-channel-indicator"}>
                { (props.channelActive ?? false) && (
                    <CheckBoxRounded sx={{
                        fontSize: 14
                    }}/>
                ) || (
                    <CheckBoxOutlineBlankRounded sx={{
                        fontSize: 14
                    }}/>
                ) }
            </div>

            {(props.writable ?? false) && (
                <input
                    className={"flat-looking-input"}
                    value={props.value}
                    onChange={e => props.onEdit?.(e.currentTarget.value)}
                    placeholder={DefaultCharacterSymbols.placeholder}
                />
            ) || (
                <DescriptiveTypography
                    text={String(props.value ?? DefaultCharacterSymbols.placeholder)}
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                />
            )}
        </StyledTBPinValue>
    );
}
