import React, {useState} from "react";
import {StyledModal} from "../../components/StyledModal";
import {PlayArrowRounded, TagRounded, UploadFileRounded, UploadRounded} from "@mui/icons-material";
import {Grid} from "../../../triton/components/layouts/Grid";
import {vw} from "../../../base/logic/style/DimensionalMeasured";
import {Workspace} from "../../components/Workspace";
import {TopicSelectorState} from "./TopicSelectorState";
import {generateTopicSelectorBaseState, TopicSelectorStateContext} from "./TopicSelectorStateContext";
import {SpiceView} from "./SpiceView";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {TopicView} from "./TopicView";
import {IconButton} from "../../components/IconButton";
import {DishConfigView} from "./DishConfigView";
import {v4} from "uuid";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {DishView} from "./DishView";
import {DishGenerationResult} from "./DishGenerationResult";
import {Spice} from "./Spice";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {DefaultButton} from "../../components/DefaultButton";

/**
 * https://stackoverflow.com/a/47593316
 * @param str seed
 */
function cyrb128(str: string) {
    let h1 = 1779033703, h2 = 3144134277, h3 = 1013904242, h4 = 2773480762;
    for (let i = 0, k; i < str.length; i++) {
        k = str.charCodeAt(i);
        h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
        h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
        h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
        h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
    }
    h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
    h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
    h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
    h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
    return [(h1^h2^h3^h4) >>> 0, (h2^h1) >>> 0, (h3^h1) >>> 0, (h4^h1) >>> 0];
}

/**
 * https://stackoverflow.com/a/47593316
 * @param a
 */
function mulberry32(a: number) {
    return function() {
        let t = a += 0x6D2B79F5;
        t = Math.imul(t ^ t >>> 15, t | 1);
        t ^= t + Math.imul(t ^ t >>> 7, t | 61);
        return ((t ^ t >>> 14) >>> 0) / 4294967296;
    }
}

function rndInterval(rnd: () => number, minInc: number, maxInc: number) {
    return Math.floor(Math.random() * (maxInc - minInc + 1) + minInc)
}

export function collapseSpice(state: TopicSelectorState, spice: Spice): string {
    const seed = state.seed ?? v4();
    const rnd = mulberry32(cyrb128(seed)[0]);
    const valueCount = spice.values.length;
    return spice.values[rndInterval(rnd, 0, valueCount - 1)];
}

export function generateDish(state: TopicSelectorState): DishGenerationResult {
    const useSpices = state.useSpices;
    const spiceCount = state.spiceCount;
    const seed = state.seed ?? v4();
    const id = v4();
    const rnd = mulberry32(cyrb128(seed)[0]);
    let topics = state.topics;
    topics = topics.filter(t => !t.excluded);
    const topicCount = topics.length;
    const errRes = (msg: string) => ({ errorMessage: msg });
    if (topicCount === 0) return errRes("No topics to choose from");
    const topic = topics[rndInterval(rnd, 0, topicCount - 1)];
    const spices = [];
    const spiceValues = [];

    if (useSpices) {
        let availableSpices = state.spices;
        availableSpices = availableSpices.filter(s => !s.excluded);
        for (let i = 0; i < Math.min(spiceCount, availableSpices.length + 1); i++) {
            const selectedSpice = availableSpices[rndInterval(rnd, 0, availableSpices.length - 1)];
            spices.push(selectedSpice);
            spiceValues.push(collapseSpice(state, selectedSpice));
            availableSpices = availableSpices.filter(s => s !== selectedSpice);
        }
    }

    return {
        dish: {
            seed: seed,
            spices: spices,
            spiceValues: spiceValues,
            id: id,
            topic: topic
        }
    }
}

export type TopicSelectorMainProps = {
    onClose: () => void
}

export const TopicSelectorMain: React.FC<TopicSelectorMainProps> = props => {
    const state = useState<TopicSelectorState>(generateTopicSelectorBaseState());

    return (
        <TopicSelectorStateContext.Provider value={state} children={
            <StyledModal onClose={props.onClose} headerRightAppendix={
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                }}>
                    <TransitionGroup children={
                        state[0].errorMessage && (
                            <Collapse orientation={"horizontal"} children={
                                <DescriptiveTypography text={state[0].errorMessage!!} style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden"
                                }}/>
                            }/>
                        )
                    }/>
                    <DefaultButton
                        children={<>
                            <PlayArrowRounded/>
                            <MainTypography text={"Get topic"}/>
                        </>}
                        variant={"primary"}
                        tooltip={"Generate"}
                        size={"small"}
                        onClick={() => {
                            const dgr = generateDish(state[0]);
                            if (dgr.dish) {
                                state[1](prevState => ({
                                    ...prevState,
                                    dish: dgr.dish!!,
                                    errorMessage: dgr.errorMessage
                                }));
                                return;
                            }

                            state[1](prevState => ({
                                ...prevState,
                                errorMessage: dgr.errorMessage ?? "Error: N/A"
                            }));
                        }}
                    />
                </div>
            } w={vw(100 * .5)} title={"Topic selector"} icon={<TagRounded/>}>
                <div style={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <Grid gTC={"repeat(3, 1fr)"}>
                        <Workspace config={{ mode: "desktop", name: "main" }} children={<>
                            <DishConfigView/>
                        </>}/>
                        <Workspace config={{ mode: "desktop", name: "editor" }} children={<>
                            <TopicView/>
                        </>}/>
                        <Workspace config={{ mode: "desktop", name: "editor" }} children={<>
                            <SpiceView/>
                        </>}/>
                    </Grid>
                    <TransitionGroup>
                        { state[0].dish && (
                            <Collapse style={{ paddingTop: 8 }} key={state[0].dish.id} children={
                                <Workspace config={{ mode: "desktop", name: "main" }} children={<>
                                    <DishView/>
                                </>}/>
                            }/>
                        ) }
                    </TransitionGroup>
                </div>
            </StyledModal>
        }/>
    )
}
