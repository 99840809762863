import React, {useContext} from "react";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {FileStructureImportModal} from "./FileStructureImportModal";
import {BooleanContext} from "../../triton/components/BooleanContext";
import {
    CalculateRounded,
    CreateNewFolderRounded,
    CreateRounded, ExtensionRounded, FolderRounded, ImageRounded,
    MoreVertRounded, RouteRounded,
    SelectAllOutlined, TagRounded,
    UploadRounded
} from "@mui/icons-material";
import {IconButton} from "./IconButton";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {ProjectCreationDialog} from "./ProjectCreationDialog";
import {FolderSetupDialog} from "./FolderSetupDialog";
import {Menu} from "./Menu";
import {CheckMenuButton, MenuButton} from "./MenuButton";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {MenuDivider} from "@szhsin/react-menu";
import {VFSViewSettings} from "../settings/VFSViewSettings";
import {ArdaiAPIStateContext} from "../ArdaiMain";
import {DefaultButton} from "./DefaultButton";
import {useStaticState} from "../hooks/StaticStateHook";

export type VFSViewOptionsState = {
    showSecondaryToolbar: boolean
}

export const VFSViewOptions: React.FC = props => {
    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);

    const [localState, localCtx] = useStaticState<VFSViewOptionsState>({
        id: "VFSViewOptions",
        initial: {
            showSecondaryToolbar: false
        }
    }).stateWithCtx;

    return (
        <>
            <ProjectCreationDialog/>
            <FolderSetupDialog/>

            <div style={{
                display: "grid",
                gap: "8px"
            }}>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "min-content auto repeat(1, min-content)",
                    gap: "8px"
                }}>
                    <IconButton variant={api.state.selectionMode ? "primary" : "default"} children={<SelectAllOutlined/>} onClick={() => {
                        api.setState(prevState => ({
                            ...prevState,
                            selectionMode: !prevState.selectionMode
                        }));
                    }}/>

                    {/*
                    <IconButton variant={"primary"} fullwidth children={<MainTypography text={"Create project"} style={{
                        color: "white",
                        fontSize: ".9rem",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "calc(100% - 0px)"
                    }}/>} onClick={() => {
                        api.toggleProjectCreationDialog(true);
                    }}/>
                    */}


                    <Menu opener={
                        <IconButton variant={"primary"} fullwidth children={<MainTypography text={"New"} style={{
                            color: "white",
                            fontSize: ".9rem",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            width: "calc(100% - 0px)"
                        }}/>}/>
                    }>
                        {/* IMPLEMENTED ACTIONS */}
                        <MenuButton text={"Create project"} icon={<TagRounded/>} onSelect={() => {
                            api.toggleProjectCreationDialog(true);
                        }}/>
                        <MenuButton text={"Create folder"} icon={<FolderRounded/>} onSelect={() => {
                            api.toggleFolderCreationDialog(true);
                        }}/>

                        {/* YET TO BE IMPLEMENTED // TODO: Implement */}
                        <MenuDivider/>
                        <MenuButton text={"Create link"} disabled icon={<RouteRounded/>}/>
                        <MenuButton text={"Create reference"} disabled icon={<ImageRounded/>}/>
                        <MenuButton text={"Create generation script"} disabled/>
                        <MenuButton text={"Create macro"} disabled/>
                        <MenuButton text={"Create plugin script"} disabled icon={<CalculateRounded />}/>
                        <MenuButton text={"Create extension script"} disabled icon={<ExtensionRounded />}/>
                    </Menu>

                    <Menu defaultMenuButtonSizeVariant={"medium"}>
                        <CheckMenuButton checked={state.showMainNavbar} text={"Show main navigation"} onSelect={() => {
                            api.setState(prevState => ({
                                ...prevState,
                                showMainNavbar: !prevState.showMainNavbar
                            }));
                        }}/>

                        <CheckMenuButton checked={localState.showSecondaryToolbar} text={"Show secondary navigation"} onSelect={() => {
                            localCtx.update(prevState => ({
                                showSecondaryToolbar: !prevState.showSecondaryToolbar
                            }));
                        }}/>
                    </Menu>
                </div>

                { localState.showSecondaryToolbar && (
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "8px"
                    }}>
                        <DefaultButton size={"small"} children={"New project"} onClick={() => {
                            api.toggleProjectCreationDialog(true);
                        }}/>
                        <DefaultButton size={"small"} children={"New folder"} onClick={() => {
                            api.toggleFolderCreationDialog(true);
                        }}/>
                    </div>
                ) }
            </div>
        </>
    );
}
