import {CarbideFrontendStateContextType} from "./CarbideFrontendStateContext";
import {CarbideFrontendState} from "./CarbideFrontendState";
import {StateDispatcher} from "../../ship/test/core/StateDispatcher";

export class CarbideFrontendAPI {

    constructor(
        public state?: CarbideFrontendState,
        public setState?: StateDispatcher<CarbideFrontendState>
    ) {}

    public updateCarbideFrontendStateContextType(stateContext: CarbideFrontendStateContextType): void {
        if (stateContext.state) this.state = stateContext.state;
        if (stateContext.setState) this.setState = stateContext.setState
    }
}
