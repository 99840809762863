import React from "react";
import {F} from "../../Simulation2Main";
import styled from "styled-components";
import {Speedometer} from "../../../../test/engine/Speedometer";
import {Orientation} from "../../../../base/logic/style/Orientation";
import {percent, px} from "../../../../base/logic/style/DimensionalMeasured";
import {CircularProgress, Slider} from "@mui/material";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {CoreCoolerControlDisplay} from "./CoreCoolerControlDisplay";
import {
    AcUnit,
    LinkOffRounded,
    LocalFireDepartmentRounded,
    LocalGasStation,
    OilBarrelRounded, PedalBikeRounded, PercentRounded, PowerSettingsNewRounded
} from "@mui/icons-material";
import {CoreHeatState} from "./CoreHeatState";
import {s} from "../../../../base/logic/misc/TimeMeasured";
import {Interpolate} from "../../../../triton/components/advanced/Interpolate";
import {ButtonBase} from "../../../../triton/components/buttons/ButtonBase";
import {Button} from "../../components/Button";
import {hex} from "../../../../base/logic/style/Color";
import {SliderPicker} from "react-color";
import {StyledControlDisplayGroup} from "../../components/StyledControlDisplayGroup";
import {ValveIcon} from "../valve/ValveIcon";
import {GenericValveIndicator, GenericValveIndicatorProps} from "../../GenericValveIndicator";
import {Entity} from "../../ecs/entity/Entity";

const StyledCoreControlDisplay = styled.div`
  width: 100%;
  // background-color: rgb(1, 4, 9);
  position: relative;
  // border: 1px solid rgb(33, 38, 45);
  // border-radius: 8px;
  // padding: 8px;
  display: grid;
  gap: 8px;
  
  .speedometer-container {
    height: 80px;
    display: grid;
    gap: 4px;
    grid-template-columns: min-content auto min-content;
  }
  
  .core-indicator-panel {
    box-shadow: rgb(33, 38, 45) 0 0 0 1px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
    padding: 8px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
  }
`;

export type CoreControlDisplayProps = {
    entity: Entity
}

export const CoreControlDisplay: React.FC<CoreControlDisplayProps> = props => {
    const core = props.entity;
    const fuel = core?.state[F] ?? 0;
    const fuelTankCapacity = 100;
    const thrust = core?.state?.thrust ?? 0;
    const fuelEconomy = core?.state?.fuelEconomy ?? 0;
    const calculatedFuelIntake = core?.state?.calculatedFuelIntake ?? 0;
    const temp = core?.state?.temp ?? 0;
    const maxTempRating = 500;
    const maxThrustRating = 20;
    const thrustPercentage = thrust * 100 / maxThrustRating;
    const noFuel = /* fuel */ fuelEconomy <= 0;
    const notEnoughFuel = /* fuel */ fuelEconomy < calculatedFuelIntake;
    const requiresFuelInCurrentSettings = calculatedFuelIntake > 0;

    const cooler = core?.getChild("cooler");
    const isCoolerActive = cooler?.state?.active ?? false;
    const coolingForce = cooler?.state?.coolingForce ?? 0;

    // EXPERIMENTAL
    const active = core?.state?.active ?? false;
    const throttle = core?.state?.throttle ?? 0;

    const isRunningDeficient = thrustPercentage < throttle;


    return (
        <StyledCoreControlDisplay>
            <div className={"speedometer-container"}>
                <Speedometer transition={s(.5)} pure val={fuelEconomy} displayWarnings width={px(10)} cap={maxTempRating} orientation={Orientation.VERTICAL}/>
                <Speedometer transition={s(1)} val={thrustPercentage} displayWarnings width={percent(100)} cap={maxThrustRating} orientation={Orientation.VERTICAL}/>
                <Speedometer transition={s(.5)} pure val={temp} displayWarnings width={px(10)} cap={maxTempRating} orientation={Orientation.VERTICAL}/>
            </div>

            <div className={"core-indicator-panel"}>
                <LocalFireDepartmentRounded sx={{
                    fontSize: 16,
                    transition: "color 1s",
                    color: core?.state?.heatState === CoreHeatState.OK ? (
                        temp > 75 ? "#ffdf60" : (
                            temp < 0 ? "#58a6ff" : "rgba(201, 209, 217, 0.1)"
                        )
                    ) : "#f14234"
                }}/>
                <Interpolate value={Number(temp.toFixed())} stepDelay={100}/>

                {/*
                <AcUnit sx={{
                    fontSize: 16,
                    transition: "color 1s",
                    color: !isCoolerActive ? ("rgba(201, 209, 217, 0.1)") : (
                        coolingForce === 0 ? "#f14234" : "#58a6ff"
                    )
                }}/>
                <Interpolate value={coolingForce} stepDelay={50}/>
                */}
            </div>

            <div className={"core-indicator-panel"}>

                {/* ACTUAL FUEL INTAKE */}
                <LocalGasStation sx={{
                    fontSize: 16,
                    transition: "color 1s",
                    color: !requiresFuelInCurrentSettings ? "rgba(201, 209, 217, 0.1)" : (
                        // Fuel is required in current core settings
                        (noFuel) ? ("#f14234") : (
                            // There is fuel currently being supplied to the engine (it may be an insufficient amount)
                            notEnoughFuel ? "#ffdf60" : "rgba(201, 209, 217, 0.1)"
                        )
                    )
                }}/>
                <Interpolate value={Number(fuelEconomy)} stepDelay={50}/>

                {/* REQUESTED FUEL */}
                <CircularProgress size={16} variant={"determinate"} value={calculatedFuelIntake} sx={{
                    transition: "color 1s",
                    color: (noFuel && active) ? ("#f14234") : (
                        notEnoughFuel ? "#ffdf60" : "rgba(201, 209, 217, 0.1)"
                    )
                }}/>
                <Interpolate value={calculatedFuelIntake} stepDelay={50}/>

                {/* FUEL BUFFER */}
                <CircularProgress size={16} variant={"determinate"} value={Number(fuel)} sx={{
                    transition: "color .25s",
                    color: (fuel === fuelTankCapacity) ? ("#9148e1") : (
                        (fuelTankCapacity / 100 * fuel < 30)  ? "#ffdf60" : "rgba(201, 209, 217, 0.1)"
                    )
                }}/>
                <Interpolate value={Number(fuel)} stepDelay={25}/>


                <GenericValveIndicator valuePercentage={calculatedFuelIntake}/>
            </div>

            <CoreCoolerControlDisplay entity={core?.getChild("cooler")}/>

            <div className={"core-indicator-panel"}>
                {/* REQUESTED THROTTLE */}
                <PercentRounded sx={{
                    fontSize: 16,
                    transition: "color 1s",
                    color: "rgba(201, 209, 217, 0.1)"
                }}/>
                {/*<Interpolate value={throttle}/>*/}
                <DescriptiveTypography text={throttle}/>

                {/* ACTUAL THROTTLE (ENGINE OUTPUT) */}
                <PercentRounded sx={{
                    fontSize: 16,
                    transition: "color 1s",
                    color: !isRunningDeficient ? "rgba(201, 209, 217, 0.1)" : (
                        // Core runs deficient (yields less than set expected/required) -> An indicator should go off
                        "#ffdf60"
                    )
                }}/>
                <Interpolate value={thrustPercentage}/>

                <PowerSettingsNewRounded sx={{
                    fontSize: 16,
                    transition: "color 1s",
                    color: active ? "#60ffc7": "rgba(201, 209, 217, 0.1)"
                }} onClick={() => {
                    core.setStatePartially({
                        active: !active
                    });
                }}/>

                <CircularProgress size={16} variant={"determinate"} value={thrustPercentage} sx={{
                    transition: "color 1s",
                    // color: !active ? "rgba(201, 209, 217, 0.1)" : (
                    //     isRunningDeficient ? "#ffdf60" : "#60ffc7"
                    // ),
                    color: !active ? "rgba(201, 209, 217, 0.1)" : (
                        isRunningDeficient ? "#ffdf60" : (
                            thrustPercentage === 100 ? "#9148e1" : "#60ffc7"
                        )
                    ),
                }}/>
            </div>

            <div>

                <div className={"speedometer-container"} style={{ height: 120 }}>
                    <Speedometer transition={s(.5)} pure val={fuelEconomy} displayWarnings width={px(10)} cap={maxTempRating} orientation={Orientation.VERTICAL}/>
                    <div style={{
                        display: "grid",
                        gridTemplateRows: "auto min-content",
                        gap: 4
                    }}>
                        <StyledControlDisplayGroup style={{ padding: "16px 0", justifyContent: "center", alignItems: "center", overflow: "hidden" }} children={
                            <div style={{ height: "100%" }} children={
                                <Slider value={throttle} orientation={"vertical"} size={"small"} sx={{
                                    transition: "color 1s",
                                    color: (!active || throttle === 0) ? "rgba(201, 209, 217, 0.1)" : (
                                        isRunningDeficient ? "#ffdf60" : (
                                            throttle === 100 ? "#9148e1" : "#60ffc7"
                                        )
                                    ),
                                }} onChange={(e, v) => {
                                    core.setStatePartially({
                                        throttle: v as number
                                    });
                                }}/>
                            }/>
                        }/>

                        <Button square children={<PowerSettingsNewRounded/>} color={{
                            if: [
                                { cond: () => active, color: hex("#60ffc7") }
                            ]
                        }} onClick={() => {
                            core.setStatePartially({
                                active: !active
                            })
                        }}/>
                    </div>
                    <Speedometer transition={s(.5)} pure val={temp} displayWarnings width={px(10)} cap={maxTempRating} orientation={Orientation.VERTICAL}/>
                </div>
            </div>


        </StyledCoreControlDisplay>
    );
}
