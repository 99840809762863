import React from "react";
import {TabConfig} from "../data/TabConfig";
import {DefaultButton} from "./DefaultButton";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";

export type TabHeaderProps = {
    config: TabConfig,
    active: boolean,
    deactivated?: boolean,
    onSelect: () => void,
    variant?: "primary" | "muted",
    bold?: boolean
}

export const TabHeader: React.FC<TabHeaderProps> = props => {
    const variant = props.variant ?? "muted";
    const bold = props.bold ?? true;

    return (
        <DefaultButton
            flatIconMode={!props.active && variant === "muted"}
            size={"small"}
            tooltip={props.config.tooltip}
            deactivated={props.deactivated ?? false}
            onClick={() => props.onSelect()}
            variant={props.active && variant === "primary" ? "primary" : "default"}
            children={
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px"
                }}>
                    { props.config.icon }
                    { props.config.title.trim().length > 0 && (
                        bold ? (
                            <MainTypography noSelect text={props.config.title} style={{
                                whiteSpace: "nowrap"
                            }}/>
                        ) : (
                            <DescriptiveTypography noSelect text={props.config.title} style={{
                                whiteSpace: "nowrap"
                            }}/>
                        )
                    ) }
                </div>
            }
        />
    );
}
