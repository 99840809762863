import React from "react";
import {DuplexEventRelay} from "../../utils/DuplexEventRelay";
import {SDInterfaceMain} from "../../../imageSorter/sdInterface/SDInterfaceMain";
import {DefaultFullscreenSDInterface} from "./DefaultFullscreenSDInterface";
import {MobileGenerationView} from "../../mobile/views/MobileGenerationView";
import {isMobile} from "react-device-detect";
import {Default, Mobile} from "../../../base/components/logic/Media";
import {MobileArdaiMain} from "../../mobile/MobileArdaiMain";
import {DefaultImageGalleryView} from "../../DefaultImageGalleryView";

export const GenerationView: React.FC = props => {

    return (
        <SDInterfaceMain>

            <Mobile children={
                <MobileGenerationView/>
            }/>

            <Default children={
                <DefaultFullscreenSDInterface
                    bus={new DuplexEventRelay()}
                />
            }/>



        </SDInterfaceMain>
    );

    /**
     * { !isMobile && (
     *     // Default desktop view
     *     <DefaultFullscreenSDInterface
     *         bus={new DuplexEventRelay()}
     *     />
     * ) }
     *
     * { isMobile && (
     *     // Mobile view
     *     <MobileGenerationView/>
     * ) }
     */
}
