import {FC} from "react";
import {TypographyProps} from "./TypographyProps";
import { Typography as MUITypography } from '@mui/material';

export const Typography: FC<TypographyProps> = props => {
    return (
        <MUITypography
            sx={props.sx}
            style={props.s}
            children={props.children ?? props.text}
            className={props.className}
        />
    );
}
