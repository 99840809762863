import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const Divider: NodeSetupInfo = {
    label: "",
    classname: "arithmetic.divider",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "arithmetic.divider",
        label: "div",
        state: { i: 0 },
        defOutPins: ["y"],
        defInPins: ["a", "b"],
        init: function () {
            const calc = () => {
                this.pins.out("y").write(
                    this.pins.in("a").lastReadState / this.pins.in("b").lastReadState
                );
            }

            this.pins.allDefaultIn.map(pin => {
                pin.attach({
                    read(data: any) {
                        calc();
                    }
                })
            })
        }
    })
}
