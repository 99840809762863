import React from "react";
import {StyledModal} from "../../components/StyledModal";
import {Formik} from "formik";
import {FileInput} from "../../../base/components/base/fileInput/FileInput";
import {ButtonBase} from "../../../triton/components/buttons/ButtonBase";
import {FormElement} from "../../../triton/components/forms/FormElement";
import {ErrorMessage} from "../../../triton/components/forms/ErrorMessage";
import fileDownload from "js-file-download";
import {DataArrayRounded} from "@mui/icons-material";
import {Grid} from "../../../triton/components/layouts/Grid";
import {FormikAdvancedFormCheckbox} from "../../components/FormikAdvancedFormCheckbox";

export type LineArrayToJsonArrayConvertorMainProps = {
    onClose: () => void
}

export const LineArrayToJsonArrayConvertorMain: React.FC<LineArrayToJsonArrayConvertorMainProps> = props => {
    return (
        <Formik<{ file?: File, humanFormatted: boolean}> initialValues={{ file: undefined, humanFormatted: false }} validate={values => {
            const errors: any = {};
            if (values.file === undefined) errors.file = "File cannot be empty";
            return errors;
        }} onSubmit={async (values, formikHelpers) => {
            const file = values.file!!;
            const text = await file.text();
            const arrayElements = text.split("\n").map(s => s.trim());
            const out = JSON.stringify(arrayElements, null, values.humanFormatted ? 2 : 0);
            fileDownload(out, `${file.name}.json`);
        }} children={fp => (
            <StyledModal title={"Line-array to json-array convertor"} icon={<DataArrayRounded/>} onClose={props.onClose} loading={fp.isSubmitting} footer={
                <Grid style={{ width: "100%" }}>
                    <ButtonBase text={"Convert"} baseProps={{
                        onClick: e => fp.handleSubmit(e)
                    }}/>
                </Grid>
            }>
                <FormElement title={".txt-file for conversion"} caption={"Select a line-separated array file (typically .txt). Each line will be converted to a json array entry"}>
                    <FileInput onSubmit={ctx => fp.setFieldValue("file", ctx.file)}/>
                    <ErrorMessage formikProps={fp} name={"file"}/>
                </FormElement>

                <FormikAdvancedFormCheckbox formik={fp} title={"Prettify converted array"} name={"humanFormatted"}/>
            </StyledModal>
        )}/>
    );
}
