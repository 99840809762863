import React, {useState} from "react";
import {VFSElementIcon} from "./VFSElementIcon";
import styled from "styled-components";
import {Modal} from "../../libs/triton/components/dialogs/Modal";
import {
    AirplayRounded,
    AlignHorizontalCenter,
    AppsRounded,
    AutoGraphRounded,
    BackspaceRounded,
    BugReportRounded,
    ChevronLeftOutlined,
    ChevronLeftRounded,
    DisplaySettingsRounded,
    ExpandLess,
    ExpandLessRounded,
    ExpandMoreRounded,
    FullscreenExitRounded,
    FullscreenRounded,
    GamesRounded,
    InputRounded,
    LanguageRounded,
    MoreHorizRounded,
    MovieFilterRounded,
    OpenInBrowserRounded,
    OpenInFullRounded,
    PlayArrowRounded,
    RoomPreferencesRounded,
    SettingsRounded,
    SmartphoneRounded,
    SpaceBarRounded,
    WavesRounded
} from "@mui/icons-material";
import {Formik} from "formik";
import {FormDivider} from "../../libs/triton/components/forms/FormDivider";
import {ListItem} from "../../libs/ardai/_/ListItem";
import {FormikSingleLineInput} from "../../libs/triton/components/forms/FormikSingleLineInput";
import {ButtonModalCompound} from "../../libs/ardai/components/ButtonModalCompound";
import {useNavigate} from "react-router-dom";
import {useTriton} from "../../libs/triton/TritonHooks";
import {AppInfo} from "./AppInfo";
import {AnimatePresence, motion} from "framer-motion";
import {MainTypography} from "../../libs/triton/components/typography/MainTypography";
import {FlatIconButton} from "../../libs/ardai/components/FlatIconButton";
import {DescriptiveTypography} from "../../libs/triton/components/typography/DescriptiveTypography";
import {DefaultButton} from "../../libs/ardai/components/DefaultButton";
import {ButtonBase} from "../../libs/triton/components/buttons/ButtonBase";
import {TabHeader} from "../../libs/ardai/components/TabHeader";
import {TabBar} from "../../libs/ardai/components/TabBar";
import {useKeyboardEvent} from "../../libs/ardai/_/keyCommand/test/KeyCommandTest";
import {AppMainBranding} from "./AppMainBranding";
import {Flex} from "../../libs/ardspace/arc/common/Flex";
import {ButtonGroup} from "../../libs/ardai/components/ButtonGroup";
import {Tag} from "../../libs/ardai/components/Tag";
import {Applet} from "../../libs/ardai/components/applet/Applet";

const StyledAppHubPrototypeV2 = styled.div`
  // width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

export type AppHubPrototypeV2State = {
    selectedApp?: AppInfo,
    mode: "compact" | "full"
}

export type AppHubPrototypeV2Props = {

    apps: Array<AppInfo>
}

export const AppHubPrototypeV2: React.FC<AppHubPrototypeV2Props> = props => {
    const navigate = useNavigate();
    const t = useTriton();

    const [state, setState] = useState<AppHubPrototypeV2State>({
        mode: "compact"
    });
    const app = state.selectedApp;

    const selectApp = (appToSelect: AppInfo) => {
        setState(prevState => {
            if (prevState.selectedApp === appToSelect) {
                return ({
                    ...prevState,
                    selectedApp: undefined
                });
            } else {
                return ({
                    ...prevState,
                    selectedApp: appToSelect
                });
            }
        });
    }

    const deselectApp = () => {
        setState(prevState => ({ ...prevState, selectedApp: undefined }));
    }

    useKeyboardEvent("keydown", e => {
        if (e.key === "Escape") {
            deselectApp();
        }
    });

    return (
        <StyledAppHubPrototypeV2>
            <div style={{
                display: "flex",
                flexDirection: "row",
                // height: "650px",
                height: state.mode === "full" ? "100vh" : "650px",
                width: state.mode === "full" ? "100vw" : "auto",
                gap: "1rem",
                maxHeight: "calc(100vh - 32px)",
                maxWidth: "calc(100vw - 32px)"
                // overflowY: "scroll"
            }}>
                {/* MAIN CONTAINER */}
                <div style={t.styled("glass_layout_box", {
                    padding: "1rem",
                    width: "400px",
                    height: "100%",
                    display: "grid",
                    gridTemplateRows: "min-content auto min-content",
                    gap: "16px"
                })}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "auto",
                        alignItems: "center",
                        gap: "8px",
                        width: "100%"
                    }}>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            alignItems: "center",
                            gap: "8px",
                            width: "100%"
                        }}>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                marginRight: "auto",
                                alignItems: "center",
                                gap: "8px"
                            }}>
                                <ButtonModalCompound button={
                                    <FlatIconButton tooltip={"Legacy app selector"} children={
                                        <AppsRounded sx={{ fontSize: 20 }}/>
                                    }/>
                                } modalContent={ctx => (
                                    <Modal open onClose={ctx.close} title={"Navigator"} icon={<InputRounded/>} children={
                                        <Formik initialValues={{ url: "" }} onSubmit={v => navigate(v.url)} children={fp => (
                                            <>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: 8
                                                }}>
                                                    <div className={"route-list"}>
                                                        <FormDivider title={"AS."}/>
                                                        <div style={{ width: "100%", height: 4 }}/>
                                                        <ListItem icon={<SpaceBarRounded sx={{ fontSize: 20 }}/>} title={"AS. Gallery"} onClick={() => navigate("/ai/gallery")}/>
                                                        <ListItem icon={<SpaceBarRounded sx={{ fontSize: 20 }}/>} title={"AS. Generate"} onClick={() => navigate("/ai/generate")}/>
                                                        {/*
                                                        <ListItem icon={<SmartphoneRounded sx={{ fontSize: 20 }}/>} title={"Mini"} onClick={() => navigate("/b")}/>
                                                        <ListItem icon={<BugReportRounded sx={{ fontSize: 20 }}/>} title={"Ardspace AI Component suite"} onClick={() => navigate("/ai/development/component")}/>
                                                        <ListItem icon={<SmartphoneRounded sx={{ fontSize: 20 }}/>} title={"Ardspace Media mobile"} onClick={() => navigate("/boarding")}/>
                                                        */}
                                                    </div>

                                                    <div className={"route-list"}>
                                                        <FormDivider title={"Development"}/>
                                                        <div style={{ width: "100%", height: 4 }}/>
                                                        <ListItem icon={<BugReportRounded sx={{ fontSize: 20 }}/>} title={"Development main"} onClick={() => navigate("/ai/development")}/>
                                                    </div>

                                                    <div className={"route-list"}>
                                                        <FormDivider title={"Simulations"}/>
                                                        <div style={{ width: "100%", height: 4 }}/>
                                                        <ListItem icon={<WavesRounded sx={{ fontSize: 20 }}/>} title={"Sim A"} onClick={() => navigate("/sim-a")}/>
                                                        <ListItem icon={<AutoGraphRounded sx={{ fontSize: 20 }}/>} title={"Sim B"} onClick={() => navigate("/sim-b")}/>
                                                        <ListItem icon={<AutoGraphRounded sx={{ fontSize: 20 }}/>} title={"Sim C"} onClick={() => navigate("/sim-c")}/>
                                                    </div>

                                                    <div className={"route-list"}>
                                                        <FormDivider title={"Other"}/>
                                                        <div style={{ width: "100%", height: 4 }}/>
                                                        <ListItem icon={<GamesRounded sx={{ fontSize: 20 }}/>} title={"Dominion"} onClick={() => navigate("/dominion")}/>
                                                    </div>
                                                </div>

                                                <FormikSingleLineInput name={"url"} formikProps={fp} placeholder={"/ai/generate"} autoFocus/>
                                                <ButtonBase text={"Go"} baseProps={{ onClick: fp.handleSubmit }}/>
                                            </>
                                        )}/>
                                    }/>
                                )}/>

                                <DescriptiveTypography noSelect text={"v2.0.0"}/>
                            </div>

                            <MainTypography text={"App selector"} noSelect style={{
                                textAlign: "center"
                            }}/>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "auto",
                                alignItems: "center",
                                gap: "8px"
                            }}>
                                <FlatIconButton children={
                                    <MoreHorizRounded sx={{ fontSize: 20 }}/>
                                }/>

                                <FlatIconButton tooltip={"Preferences"} children={
                                    <SettingsRounded sx={{ fontSize: 20 }}/>
                                }/>

                                { state.mode === "compact" && (
                                    <FlatIconButton onClick={() => {
                                        setState(prevState => ({ ...prevState, mode: "full" }));
                                    }} children={
                                        <FullscreenRounded sx={{ fontSize: 20 }}/>
                                    }/>
                                ) || (
                                    <FlatIconButton children={
                                        <FullscreenExitRounded onClick={() => {
                                            setState(prevState => ({ ...prevState, mode: "compact" }));
                                        }} sx={{ fontSize: 20 }}/>
                                    }/>
                                ) }
                            </div>
                        </div>

                        <div children={<AppMainBranding/>} style={{
                            padding: "1.5rem"
                        }}/>
                    </div>



                    <div style={{
                        overflow: "scroll"
                    }}>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            width: "100%",
                            rowGap: "24px"
                        }} children={props.apps.map(appInfo => (
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer"
                            }} onClick={e => {
                                if (e.ctrlKey) {
                                    appInfo.spark?.call({
                                        navigate: navigate
                                    })
                                } else {
                                    selectApp(appInfo);
                                }
                            }}>

                                <FlatIconButton
                                    children={<AutoGraphRounded sx={{ fontSize: 20 }}/>}
                                    active={appInfo.title === state.selectedApp?.title}
                                />

                                <DescriptiveTypography noSelect text={appInfo.title} style={{
                                    textAlign: "center"
                                }}/>
                            </div>
                        ))}/>
                    </div>

                    <div>
                        <DescriptiveTypography
                            noSelect
                            text={`Bernie Enterprises, 2023-${new Date().getFullYear()}`}
                            style={{
                                textAlign: "center"
                            }}
                        />
                    </div>
                </div>

                {/* APP INFO CONTAINER */}
                <AnimatePresence children={app && (
                    <motion.div style={{
                        height: "100%",
                        flexGrow: 2
                    }} initial={{
                        width: 0,
                        scale: 0
                    }} animate={{
                        // width: 400,
                        // width: 600,
                        width: "50vw",
                        scale: 1
                    }} exit={{
                        width: 0,
                        scale: 0
                    }} transition={{
                        type: "tween",
                        duration: .2
                    }}>
                        <div style={{
                            display: "grid",
                            gridTemplateRows: "min-content auto",
                            gap: "1rem",
                            overflow: "hidden",
                            // marginLeft: "1rem",
                            width: "100%",
                            height: "100%",
                        }}>
                            <div style={t.styled("glass_layout_box", {
                                padding: "1rem",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                gap: "8px",
                                flexDirection: "column"
                            })}>

                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    gap: "8px",
                                    justifyContent: "space-between"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px"
                                    }}>
                                        <FlatIconButton
                                            // TODO: Make the "app deselecting" more clear
                                            onClick={() => selectApp(app)}
                                            children={
                                                <ChevronLeftRounded sx={{ fontSize: 20 }}/>
                                            }
                                        />

                                        <MainTypography text={app.title}/>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                    }}>
                                        <ButtonGroup>
                                            <DefaultButton
                                                variant={"primary"}
                                                size={"small"}
                                                style={{
                                                    fontFamily: "monospace"
                                                }}
                                                onClick={() => app?.spark?.call({
                                                    navigate: navigate
                                                })}
                                                children={
                                                    <Flex>
                                                        <LanguageRounded style={{
                                                            fontSize: 14
                                                        }}/>
                                                        Launch in new tab
                                                    </Flex>
                                                }
                                            />

                                            <DefaultButton
                                                variant={"default"}
                                                size={"small"}
                                                style={{
                                                    fontFamily: "monospace"
                                                }}
                                                onClick={() => app?.spark?.call({
                                                    navigate: navigate
                                                })}
                                                children={
                                                    <Flex>
                                                        <AirplayRounded style={{
                                                            fontSize: 14
                                                        }}/>
                                                        As applet
                                                    </Flex>
                                                }
                                            />
                                        </ButtonGroup>

                                        <FlatIconButton children={
                                            <MoreHorizRounded sx={{ fontSize: 20 }}/>
                                        }/>
                                    </div>
                                </div>


                                <DescriptiveTypography text={app.about}/>

                                {/*
                            <iframe src={"http://localhost:3000/ai"} style={{
                                border: "none",
                                height: "100%",
                                borderRadius: 16
                            }}/>
                            */}
                            </div>

                            <div style={{
                                width: "100%",
                                height: "100%",
                                display: "grid"
                            }}>
                                <Applet style={{
                                    width: "100%"
                                }} application={{
                                    children: () => (
                                        <iframe src={"http://localhost:3000/"} style={{
                                            border: "none",
                                            width: "100%",
                                            height: "100%"
                                        }}/>
                                    )
                                }}/>
                            </div>
                        </div>
                    </motion.div>
                )}/>

                <div style={{
                    height: "100%",
                    display: "grid",
                    gap: "1rem",
                    // overflow: "hidden",
                    // gridTemplateRows: "repeat(2, 1fr)",
                    // marginLeft: "1rem",
                }}>
                    <Applet application={{
                        children: () => (
                            <iframe src={"http://localhost:3000"} style={{
                                border: "none",
                                height: "100%",
                                width: "100%"
                            }}/>
                        )
                    }}/>
                    {/*
                    <Applet application={{
                        children: () => (
                            <iframe src={"http://localhost:3000/dominion"} style={{
                                border: "none",
                                height: "100%",
                                width: "100%"
                            }}/>
                        )
                    }}/>
                    */}
                </div>
            </div>
        </StyledAppHubPrototypeV2>
    );
}
