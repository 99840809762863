import {FC} from "react";
import styled from "styled-components";
import {useTriton} from "../../../../triton/TritonHooks";
import {Triton} from "../../../../triton/Triton";
import {MainFooterFinalSection} from "./MainFooterFinalSection";

const StyledMainFooterSection = styled.div<{
    t: Triton
}>`
  display: grid;
`;

export type MainFooterSectionProps = {};

export const MainFooterSection: FC<MainFooterSectionProps> = props => {
    const t = useTriton();

    return (
        <StyledMainFooterSection t={t}>



            <MainFooterFinalSection/>
        </StyledMainFooterSection>
    );
}
