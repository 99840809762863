import React, {ReactNode} from "react";
import styled from "styled-components";
import {MainTypography} from "../typography/MainTypography";
import {DescriptiveTypography} from "../typography/DescriptiveTypography";

export const StyledFormDivider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  
  .form-divider-line {
    width: 100%;
    background-color: #30363d;
    height: 1px;
    flex-shrink: 1;
  }
  
  .form-divider-appendix-container {
    display: flex;
    align-items: center;
    gap: 4px;
    overflow-x: scroll;
    flex-shrink: 0;
  }
`;

export type FormDividerProps = {
    title?: string,
    bold?: boolean,
    paddingVertical?: string | number,
    appendix?: ReactNode
}

export const FormDivider: React.FC<FormDividerProps> = props => {
    const paddingVertical = props.paddingVertical ?? 0;
    return (
        <StyledFormDivider style={{
            paddingTop: paddingVertical,
            paddingBottom: paddingVertical
        }}>
            { props.title && (
                (props.bold ?? false) ? (
                    <MainTypography text={props.title} noSelect style={{
                        flexShrink: 0,
                        maxWidth: "calc(100% - 50px)",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                    }}/>
                ) : (
                    <DescriptiveTypography text={props.title} noSelect style={{
                        flexShrink: 0,
                        maxWidth: "calc(100% - 50px)",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                    }}/>
                )
            ) }
            <span className={"form-divider-line"}/>

            { props.appendix && (
                <div className={"form-divider-appendix-container"}>
                    { props.appendix }
                </div>
            ) }
        </StyledFormDivider>
    );
}
