import {NodeSetupInfo} from "../../../NodeSetupInfo";
import {Node} from "../../../../backend/Node";
import {v4} from "uuid";
import {SRCReader} from "../../../../../ardai/unifiedGraphicsLanguage/SRCReader";

export const NodePromptTranspiler: NodeSetupInfo = {
    label: "NodePromptTranspiler",
    classname: "tests.sdwui.prompt-transpiler",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "tests.sdwui.prompt-transpiler",
        label: "prompt transpiler",
        defOutPins: ["prompt"],
        defInPins: ["src"],
        init: function () {
            this.createInternalWire<string>({
                targetInPin: "src",
                targetOutPin: "prompt",
                transformer: src => {
                    return transpileMain({
                        src
                    }).prompt;
                }
            })
        }
    })
}

type TranspileInput = {
    src: string
}

type TranspileOutput = {
    prompt: string
}

// noinspection SpellCheckingInspection
const transpileMain = (input: TranspileInput): TranspileOutput => {
    const ctx = new TranspilationContext(input);

    // "sdwui" special keywords
    ctx.variables.set("|", () => "BREAK")
    ctx.variables.set("&", () => "AND")

    // Instruction: set/create variable
    ctx.instructions.set("set", (instruction, parameter, ctx) => {
        const reader = new SRCReader(parameter);
        const name = reader.nLookAhead(s => s === " ");
        reader.skipWhitespace();
        const value = reader.remainder();
        ctx.variables.set(name, () => value);
    });

    const specialInstructionSymbol = ".";
    const variableStartingSymbol = "$";
    const lines = input.src.split("\n");

    /**
     * % set col red
     * $col hair, $col eyes
     */
    const handleLiteralLine = (line: string) => {
        const reader = new SRCReader(line);
        while (reader.hasNext()) {
            const char = reader.read();
            if (char === variableStartingSymbol) {
                handleVariable(reader);
            } else {
                ctx.prompt += char;
            }
        }
        // ctx.prompt += line + "\n";
        ctx.prompt += "\n";
    }

    const handleVariable = (reader: SRCReader) => {
        let bracketMode = false;
        let variableName = "";
        if (reader.peek() === "{") {
            reader.read();
            bracketMode = true;
        }
        if (bracketMode) {
            variableName = reader.readUntilNextClosingToken(["{", "}"]);
        } else {
            const variableNameCharTestRegex = /[\w|&]/;
            while (reader.hasNext()) {
                const char = reader.peek();
                if (variableNameCharTestRegex.test(char)) {
                    reader.read();
                    variableName += char;
                } else break
            }
        }

        // process the variable
        const variableValue = ctx.variables.get(variableName)?.call(undefined, ctx);
        ctx.prompt += variableValue;
    }

    const handleInstructionLine = (line: string) => {
        const reader = new SRCReader(line);
        reader.read();
        reader.skipWhitespace();
        const instruction = reader.nLookAhead(s => s === " ");
        const parameter = reader.remainder().trim();
        ctx.instructions.get(instruction)?.call(undefined, instruction, parameter, ctx);
    }

    lines.forEach(line => {
        if (line.startsWith(specialInstructionSymbol)) handleInstructionLine(line);
        else handleLiteralLine(line);
    });

    return {
        prompt: ctx.prompt
    };
}

// noinspection SpellCheckingInspection
class TranspilationContext {
    constructor(
        // mandatory configuration
        public readonly input: TranspileInput,
        // internals / auto-configured
        public prompt: string = "",
        public readonly instructions: Map<string, InstructionResolver> = new Map<string, InstructionResolver>(),
        public readonly variables: Map<string, VariableResolver> = new Map<string, VariableResolver>()
    ) {}
}

type InstructionResolver = (instruction: string, parameter: string, ctx: TranspilationContext) => void

type VariableResolver = (ctx: TranspilationContext) => string
