import React, {useContext, useState} from "react";
import styled from "styled-components";
import {PromptEditingMode} from "./PromptEditingMode";
import {PromptType} from "./PromptType";
import {useTriton} from "../../../../../triton/TritonHooks";
import {CodeRounded, DeleteRounded, MoreHorizRounded, Subtitles} from "@mui/icons-material";
import {Text} from "../../../../../triton/components/typography/Text";
import {MobilePromptCodeEditor} from "./MobilePromptCodeEditor";
import {Menu} from "../../../../components/Menu";
import {MenuButton} from "../../../../components/MenuButton";
import {SDInterfaceAPIContext} from "../../../../../imageSorter/sdInterface/SDInterfaceAPI";
import {MetadataViewV2} from "../../../../components/MetadataViewV2";
import {PromptRenderer} from "../../../../components/promptRenderer/PromptRenderer";

const StyledMobilePromptTab = styled.div`
  padding: 16px 0 8px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  
  .mobile-prompt-tab-header {
    border-radius: 8px;
    background-color: black;
    height: 46px;
    margin: 0 16px;
    flex-shrink: 0;
    display: grid;
    grid-template-columns: auto min-content;
    gap: 8px;
    align-items: center;
    padding: 0 16px;
    
    .tab-header-appendix, .tab-header-prompt-selector {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      user-select: none;
      
      .MuiSvgIcon-root {
        transition: .1s;
      }
    }
  }
  
  .mobile-prompt-main {
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
  }
`;

export type MobilePromptTabState = {
    editingMode: PromptEditingMode,
    promptType: PromptType
}

export const MobilePromptTab: React.FC = props => {
    const t = useTriton();
    const api = useContext(SDInterfaceAPIContext);
    const pCol = t.col("button_primary");
    const [ls, setLs] = useState<MobilePromptTabState>({
        editingMode: PromptEditingMode.TEXT,
        promptType: PromptType.POSITIVE,
    });

    const code = ls.promptType === PromptType.POSITIVE ? api.req.prompt : api.req.negativePrompt;

    const currentPromptIsEmpty = api.req[ls.promptType === PromptType.POSITIVE ? "prompt" : "negativePrompt"].length === 0;

    return (
        <StyledMobilePromptTab>
            <div className={"mobile-prompt-tab-header"}>
                <div className={"tab-header-prompt-selector"}>
                    <Text fs={14} t={"Prompt"} onClick={() => setLs(prevState => ({ ...prevState, promptType: PromptType.POSITIVE }))} style={{
                        color: ls.promptType === PromptType.POSITIVE ? pCol : "white",
                        transition: "color .1s"
                    }}/>
                    <Text fs={14} t={"Negative prompt"} onClick={() => setLs(prevState => ({ ...prevState, promptType: PromptType.NEGATIVE }))} style={{
                        color: ls.promptType === PromptType.NEGATIVE ? pCol : "white",
                        transition: "color .1s"
                    }}/>
                </div>

                <div className={"tab-header-appendix"}>
                    <Subtitles onClick={() => setLs(prevState => ({ ...prevState, editingMode: PromptEditingMode.CHIPS }))} sx={{
                        color: ls.editingMode === PromptEditingMode.CHIPS ? pCol : "white",
                        fontSize: 20
                    }}/>
                    <CodeRounded onClick={() => setLs(prevState => ({ ...prevState, editingMode: PromptEditingMode.TEXT }))} sx={{
                        color: ls.editingMode === PromptEditingMode.TEXT ? pCol : "white",
                        fontSize: 20
                    }}/>
                    <Menu opener={
                        <span style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }} children={
                            <MoreHorizRounded sx={{
                                fontSize: 20,
                                color: "white",
                            }}/>
                        }/>
                    }>
                        <MenuButton icon={<DeleteRounded/>} disabled={currentPromptIsEmpty} text={`Clear ${ls.promptType === PromptType.POSITIVE ? "" : "negative "}prompt`} onSelect={() => api.updateRequest({
                            [ls.promptType === PromptType.POSITIVE ? "prompt" : "negativePrompt"]: ""
                        })}/>
                    </Menu>
                </div>
            </div>

            <div className={"mobile-prompt-main"}>
                { ls.editingMode === PromptEditingMode.CHIPS && (
                    <div style={{
                        borderRadius: 8,
                        backgroundColor: "black",
                        height: "100%",
                        padding: 16,
                        margin: "0 16px",
                        flexGrow: 1,
                        flexShrink: 1,
                        minHeight: 0,
                        overflow: "scroll"
                    }}>
                        <PromptRenderer
                            title={ls.promptType === PromptType.POSITIVE ? "Prompt" : "Negative prompt"}
                            rendererName={"mobile-prompt-main"}
                            originalPrompt={code}
                        />
                    </div>
                ) }

                { ls.editingMode === PromptEditingMode.TEXT && (
                    <MobilePromptCodeEditor promptType={ls.promptType}/>
                ) }
            </div>
        </StyledMobilePromptTab>
    );
}
