import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {ButtonGroup} from "../../../../ardai/components/ButtonGroup";
import {Tag} from "../../../../ardai/components/Tag";

export const Boolean: NodeSetupInfo = {
    label: "",
    classname: "input.boolean-input-debug",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "input.boolean-input-debug",
        label: "bool",
        state: { bit: false },
        defOutPins: ["b"],
        reset() {
            this.pins.out("b").write(false);
        },
        customRenderer: (node) => (
            <ButtonGroup>
                <Tag tag={"1"} active={node.state.state.bit} onClick={() => {
                    node.pins.out("b").write(true);
                    node.state.update({
                        bit: true
                    })
                }}/>
                <Tag tag={"0"} active={!node.state.state.bit} onClick={() => {
                    node.pins.out("b").write(false);
                    node.state.update({
                        bit: false
                    })
                }}/>
            </ButtonGroup>
        )
    })
}
