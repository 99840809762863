import {NodeSetupInfo} from "../NodeSetupInfo";
import {Node} from "../../backend/Node";
import {v4} from "uuid";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import _ from "lodash";
import {StdTypeConversionUtils} from "../../std/StdTypeConversionUtils";

export const Oscillator: NodeSetupInfo = {
    label: "Oscillator",
    classname: "oscillator",
    parameterConfig: [],
    factory: parameters => new Node<{
        interval?: NodeJS.Timer
        hertz: number,
        enabled: boolean
    }>({
        id: v4(),
        classname: "oscillator",
        label: "oscillator",
        defInPins: ["λ", "e"],
        defOutPins: ["c"],
        state: {
            hertz: 0,
            enabled: true
        },
        init: function () {
            const node = this;

            const onClockSignal = () => {
                node.pins.out("c").write(0b1);
            };

            function updateInterval() {
                if (node.state.state.interval !== undefined) {
                    clearInterval(node.state.state.interval);
                }
                const enabled = node.state.state.enabled;
                const hz = node.state.state.hertz;
                const period = 1 / hz;
                const interval = (hz >= 0 && enabled) ? setInterval(onClockSignal, period * 1e3) : undefined;
                node.state.update({
                    interval
                });
            }

            this.pins.in("λ").attach({
                read(hertz: number | string) {
                    this.node.state.update({
                        hertz: Number(hertz)
                    });
                    updateInterval();
                }
            })

            this.pins.in("e").attach({
                read(enabled: boolean | number | string) {
                    enabled = StdTypeConversionUtils.booleanishToBoolean(enabled);
                    this.node.state.update({
                        enabled
                    });
                    updateInterval();
                }
            })
        },
        customRenderer: (node) => (
            <div style={{
                padding: "8px 0",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                alignItems: "end"
            }}>
                <DescriptiveTypography text={`${_.padStart(String(
                    node.state.state.hertz === 0 ? "∞" : node.state.state.hertz
                ), 3, " ")} Hz`} style={{
                    whiteSpace: "pre",
                }}/>
                <DescriptiveTypography noSelect text={node.state.state.enabled ? "on" : "off"} style={{
                    whiteSpace: "pre",
                    fontSize: 10
                }}/>
            </div>
        )
    })
}
