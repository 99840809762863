import {FC} from "react";
import styled from "styled-components";
import {SocialsIconList} from "../SocialsIconList";
import {usePortfolioBackend} from "../../UsePortfolioBackendHook";
import {PrimaryDisplayTypography} from "../typography/PrimaryDisplayTypography";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";

import {ReactComponent as Planetary1} from "../../assets/planetary-1.svg";
import {Dot} from "../../../../ardai/components/Dot";

const StyledMainFooterFinalSection = styled.div`
  display: grid;
  
  .brand-section {
    margin: 24px 0;
    
    .branding {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }
  
  .legal-section {
    .legal-links {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }

  .brand-section, .legal-section {
    display: grid;
    grid-template-columns: auto min-content;
    gap: 16px;
    width: 100%;
    align-items: center;
    
    .left-align {}
  }
`;

export const MainFooterFinalSection: FC = props => {
    const portfolio = usePortfolioBackend();

    // noinspection SpellCheckingInspection
    return (
        <StyledMainFooterFinalSection>
            <div className={"brand-section"}>
                <div className={"left-align"}>
                    <div className={"branding"}>
                        <Planetary1 style={{
                            width: 32
                        }}/>

                        <PrimaryDisplayTypography
                            fontSize={24}
                            text={"Christian Bernstein"}
                        />
                    </div>
                </div>
                <div className={"right-align"}>
                    <SocialsIconList for={portfolio.state.person}/>
                </div>
            </div>

            <div className={"legal-section"}>
                <div className={"left-align"}>
                    <div className={"legal-links"}>
                        { ["Privacy Policy", "Cookie Policy", "Impressum – Legal Disclosure"].map(placeholder => (
                            <DescriptiveTypography noSelect text={placeholder} style={{
                                whiteSpace: "nowrap",
                                cursor: "pointer"
                            }}/>
                        )) }

                        <Dot/>

                        <DescriptiveTypography noSelect text={"Login"} style={{
                            whiteSpace: "nowrap",
                            cursor: "pointer"
                        }} baseProps={{
                            onClick: e => {
                                const isDirectLink = e.ctrlKey;
                                window.location.pathname = isDirectLink ? "ai/gallery/" : "apps/";
                            }
                        }}/>
                    </div>
                </div>
                <div className={"right-align"}>
                    <DescriptiveTypography noSelect text={`© ${new Date().getFullYear()} Christian Bernstein. All rights reserved`} style={{
                        whiteSpace: "nowrap"
                    }}/>
                </div>
            </div>
        </StyledMainFooterFinalSection>
    );
}
