import React from "react";
import {TabConfig} from "../data/TabConfig";
import {TabHeader} from "./TabHeader";
import styled from "styled-components";

const StyledTabBar = styled.div`
  overflow: scroll;
  
  .tab-bar-body {
    width: min-content;  
    display: flex;
    align-items: center;
    gap: 8px;
    
    &[data-equal-size-mode=true] {
      width: 100%;
      display: grid;
      grid-auto-columns: minmax(0, 1fr); 
      grid-auto-flow: column;
      gap: 6px;
    }
  }
`;

export type TabBarProps = {
    activeBar: string,
    onTabChange: (tab: string) => void
    tabs: Array<TabConfig>,

    equalSizeMode?: boolean,

    variant?: "primary" | "muted",
    bold?: boolean
}

export const TabBar: React.FC<TabBarProps> = props => {
    const equalSizeMode = props.equalSizeMode ?? false;

    return (
        <StyledTabBar children={
            <div className={"tab-bar-body"} data-equal-size-mode={equalSizeMode} children={props.tabs.map(cfg => {
                return (
                    <TabHeader
                        bold={props.bold}
                        variant={props.variant}
                        key={cfg.id}
                        config={cfg}
                        active={cfg.id === props.activeBar}
                        onSelect={() => props.onTabChange(cfg.id)}
                    />
                );
            })}/>
        }/>
    );
}
