import React from "react";
import styled from "styled-components";
import {ArcText} from "../ArcText";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";

const StyledTypoHeaderVisual = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 17px;
`;

const StyledTypeHeaderDescription = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  
  p, span {
    color: white;
    opacity: 60%;
    margin: 0 !important;
    text-align: center;
  }
`;

export type TypoHeaderVisualProps = {
    header: string,
    descriptiveSubheader?: string,
    text?: string
}

export const TypoHeaderVisual: React.FC<TypoHeaderVisualProps> = props => {
    return (
        <StyledTypoHeaderVisual>
            <ArcText variant={"artistic-display"} text={props.header}/>
            <StyledTypeHeaderDescription>
                { props.descriptiveSubheader && (
                    <DescriptiveTypography text={props.descriptiveSubheader} style={{
                        fontWeight: 600
                    }}/>
                ) }
                { props.text && (
                    <DescriptiveTypography text={props.text}/>
                ) }
            </StyledTypeHeaderDescription>
        </StyledTypoHeaderVisual>
    );
}
