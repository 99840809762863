import {TestResultElement} from "./TestResultElement";
import {TestExitSignal} from "./TestExitSignal";
import {TestResultElementAnnotationType} from "./TestResultElementAnnotationType";
import {TestResultState} from "./TestResultState";

export class TestContext {

    constructor(
        private readonly config: {
            updateElement: (
                updater: (current: TestResultElement) => TestResultElement | void
            ) => void
        }
    ) {}

    public increaseLevelTo(level: TestResultState) {
        this.config.updateElement(current =>{
            current.state = Math.max(current.state, level);
        });
    }

    public exitTest(markAsFailed: boolean = true) {
        if (markAsFailed) {
            this.increaseLevelTo(TestResultState.FAILED);
        }
        throw new TestExitSignal;
    }

    public assertEq(actual: any, expected: any) {
        if (expected === actual) return;
        this.config.updateElement(current => {
            current.annotations.push({
                type: TestResultElementAnnotationType.ERROR,
                subtype: "assert_eq",
                data: {
                    expected: expected,
                    actual: actual
                }
            });
        });
        this.exitTest();
    }
}
