import React from "react";
import {PromptShard} from "./PromptShard";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import styled from "styled-components";
import {useTriton} from "../../../triton/TritonHooks";
import {PromptShardType} from "./PromptShardType";

const StyledPromptShardComponent = styled.div`
  background-color: #1e202a;
  padding: 4px 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 4px;
  align-items: center;
  transition: all 100ms;
  cursor: pointer;

  &[data-selected=true] {
    box-shadow: inset 0 0 0 1px rgb(255, 223, 96);
  }

  &:hover {
    filter: brightness(1.25);
  }

  &:active {
    scale: .98;
  }

  & > * {
    font-family: monospace;
  }
`;

export type PromptShardComponent = {
    shard: PromptShard,
    selected?: boolean
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
};

export const PromptShardComponent: React.FC<PromptShardComponent> = props => {
    const shard = props.shard;
    const t = useTriton();

    return (
        <StyledPromptShardComponent onClick={props.onClick} data-selected={props.selected ?? false}>
            { shard.type !== PromptShardType.TEXT && (
                <DescriptiveTypography text={
                    PromptShardType[shard.type]
                } noSelect style={{
                    color: t.col("color_secondary")
                }}/>
            ) }
            <DescriptiveTypography text={shard.text} noSelect/>
            { shard.strength !== 1 && (
                <DescriptiveTypography text={shard.strength.toLocaleString("en-GB", {
                    compactDisplay: "short",
                    notation: "compact",
                })} noSelect style={{
                    color: t.col("button_primary")
                }}/>
            ) }

        </StyledPromptShardComponent>
    );
}
