import React, {PropsWithChildren, useContext} from "react";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {IconButton} from "./IconButton";
import {Menu} from "./Menu";
import {MenuButton} from "./MenuButton";
import {MenuDivider} from "@szhsin/react-menu";
import {FormDivider} from "../../triton/components/forms/FormDivider";
import ReactJson from "react-json-view";
import {ColorableTag} from "./ColorableTag";
import {LayoutSelectorDisplay} from "./layout/LayoutSelectorDisplay";

export type LayoutTabButtonProps = PropsWithChildren<{
    programKey: string,
    defaultTargetTray: string,
    title?: string,

    isTrayOverridable?: boolean
}>

export const LayoutTabButton: React.FC<LayoutTabButtonProps> = props => {
    const api = useContext(ArdaiAPIContext);
    const tray = Object.entries(api.state.trayOccupancy).find(([k, v]) => v === props.programKey);
    const isOpened = tray !== undefined;

    const traysWithProgram = Object.entries(api.state.trayOccupancy)
        .filter(([k, v]) => v === props.programKey)
        .map(kv => kv[0])


    const openProgramInTray = (tray: string, program: string) => {
        api.setState(prevState => ({
            ...prevState,
            trayOccupancy: {
                ...prevState.trayOccupancy,
                [tray]: program,
            },
            openedTrays: [tray, ...prevState.openedTrays]
        }));
    }

    const closeProgramInTray = (tray: string) => {
        api.setState(prevState => ({
            ...prevState,
            trayOccupancy: {
                ...prevState.trayOccupancy,
                [tray]: undefined
            },
            openedTrays: prevState.openedTrays.filter(s => s !== tray)
        }))
    }

    const toggleProgram = (tray: string, program: string) => {
        if (isOpened) {
            closeProgramInTray(tray);
        } else {
            openProgramInTray(tray, program);
        }
    }

    const onClick = () => {
        if (isOpened) {
            closeProgramInTray(tray[0]);
        } else {
            openProgramInTray(props.defaultTargetTray, props.programKey);
        }
    }

    return (
        <Menu opener={
            <IconButton
                tooltipPlace={"right"}
                // TODO: Display title + Opening shortcut
                tooltip={props.title}
                size={"small"}
                children={props.children}
                variant={isOpened ? "primary" : "default"}
                onClick={e => {
                    if (e.ctrlKey) return;
                    onClick();
                    e.stopPropagation();
                    e.preventDefault();
                }}
            />
        }>
            <LayoutSelectorDisplay programKey={props.programKey}/>
        </Menu>
    );

    return (
        <IconButton
            tooltipPlace={"right"}
            // TODO: Display title + Opening shortcut
            tooltip={props.title}
            size={"small"}
            children={props.children}
            variant={isOpened ? "primary" : "default"}
            onClick={onClick}
        />
    );
}
