import React from "react";
import styled from "styled-components";
import {Menu} from "../Menu";
import {IconButton} from "../IconButton";
import {CloseRounded, FolderRounded} from "@mui/icons-material";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {ColorableTag} from "../ColorableTag";
import {Color} from "../../../base/logic/style/Color";

const StyledImageSelectionOutputCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  background-color: #1e202a;
  user-select: none;
  
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  
  &:hover .options, &:focus-within .options {
    opacity: 1;
  }
  
  .options {
    opacity: 0;
    transition: opacity 80ms;
  }
`;

export type ImageSelectionOutputCardProps = {

}

export const ImageSelectionOutputCard: React.FC<ImageSelectionOutputCardProps> = props => {
    return (
        <StyledImageSelectionOutputCard>
            <div className={"title"}>
                <FolderRounded/>
                <MainTypography text={"Heööp"}/>
                <ColorableTag tag={"Primary"} c={Color.ofHex("#ffdf60")}/>
            </div>

            <div className={"options"}>
                <Menu/>
                <IconButton children={<CloseRounded/>} size={"small"}/>
            </div>
        </StyledImageSelectionOutputCard>
    );
}
