import React from "react";
import {ContentSensitivityAttachment} from "../ContentSensitivityAttachment";
import styled from "styled-components";

const StyledContentSensitiveRenderer = styled.div<{
    ctx: ContentSensitiveRendererContext
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  width: 100%;
  height: 100%;
  
  overflow: hidden;
  
  position: relative;
  
  // Content-policy mandates to blur content withing this render context
  &[data-cp-blurred=true] {
    .${p => p.ctx.classes.img} {
      // filter: blur(${p => p.ctx.blurIntensityInPx}px);
      filter: blur(${p => p.ctx.blurIntensityInPx}rem);
    }
  }
`;

export type ContentSensitiveRendererContext = {
    blurIntensityInPx: number,
    classes: {
        img: string
    }
}

export type ContentSensitiveRendererProps = {
    csa: ContentSensitivityAttachment,
    children: (ctx: ContentSensitiveRendererContext) => React.ReactNode
}

export const ContentSensitiveRenderer: React.FC<ContentSensitiveRendererProps> = props => {

    const ctx: ContentSensitiveRendererContext = {
        blurIntensityInPx: 0, // TODO: Fix: Now in REM not PX
        // blurIntensityInPx: 1,
        classes: {
            img: "img-cp-target",
        }
    };

    return (
        <StyledContentSensitiveRenderer ctx={ctx} data-cp-blurred={true}
            children={props.children(ctx)}
        />
    );
}
