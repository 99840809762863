import React, {useContext, useEffect, useRef, useState} from "react";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {FSStateMirror} from "../webapi/fs/FSStateMirror";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {ButtonBase} from "../../triton/components/buttons/ButtonBase";
import ReactJson from "react-json-view";
import {FSManagerEvents} from "../webapi/fs/FSManager";
import {ListItem} from "../_/ListItem";
import {IDisk} from "../webapi/fs/IDisk";
import {useDisk} from "../webapi/fs/hooks/UseDiskHook";

export const VFSFolderContentViewerV2: React.FC = props => {
    const api = useContext(ArdaiAPIContext);

    const [fsState, setFsState] = useState<FSStateMirror>(api.FSManager.stateMirror);
    useEffect(() => api.FSManager.observe(FSManagerEvents.STATE_UPDATED).on((key, data) => {
        setFsState(data);
    }).destructor, []);

    const [disk] = useDisk();

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            height: "100%",
            overflowY: "scroll"
        }}>
            <div style={{ display: "grid", "gap": 8 }}>

                {
                    disk.state.state.lsSnapshot && (
                        disk.state.state.lsSnapshot.folders.map(folderSnapshot => (
                            <ListItem
                               title={folderSnapshot.name}
                               onClick={() => disk.cdDown(folderSnapshot.name)}
                            />
                            // <ButtonBase
                            //     text={folderSnapshot.name}
                            //     baseProps={{
                            //         onClick: () => api.FSManager.disk.cdDown(folderSnapshot.name)
                            //     }}
                            // />
                        ))
                    )
                }
            </div>
        </div>
    );
}
