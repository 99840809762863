import React, {PropsWithChildren, useEffect, useRef, useState} from "react";
import {DescriptiveTypography} from "../typography/DescriptiveTypography";
import {v4} from "uuid";

export type InterpolateProps<T> = {
    value: T,
    stepDelay?: number,
    appendix?: string,
    children?: (d: T) => React.ReactNode,

    disableRounding?: boolean

}

export type InterpolateState<T> = {
    delta: T,
    interpolationStartingVal?: T
}

export const Interpolate: React.FC<InterpolateProps<number>> = props => {
    const stepDelay = props.stepDelay ?? 10;

    const [state, setState] = useState<InterpolateState<number>>({
        delta: props.value
    });

    useEffect(() => {
        let timer: NodeJS.Timeout;

        const reqStep = () => {
            timer = setTimeout(() => {
                setState(prevState => {
                    let delta = 0;
                    if (prevState.delta === props.value) return prevState;

                    // TODO: Introduce better error margin handling
                    if (Math.abs(prevState.delta - props.value) < 1) return prevState;
                    else delta = prevState.delta < props.value ? 1 : -1;

                    const nextDelta = prevState.delta + delta;
                    const interpolationFinished = nextDelta === props.value;
                    if (!interpolationFinished) reqStep();
                    return ({
                        ...prevState,
                        delta: nextDelta
                    });
                });
            }, stepDelay);
        }
        if (props.value !== state.delta) reqStep();
        return () => clearTimeout(timer);
    }, [props.value, state.delta, stepDelay]);

    const value = props.disableRounding ? state.delta : (
        Math.round(state.delta)
    );

    if (props.children) {
        return (
            <>{
                props.children(value)
            }</>
        );
    }

    return (
        <DescriptiveTypography text={`${value}${props.appendix ?? ""}`}/>
    );
}
