import {createBridge, ReactBackend} from "../../ReactAPIBridgeUtils";
import {StandaloneObservable} from "../../ardai/webapi/pubsub/StandaloneObservable";
import {NodeCanvasBackendEventTypes} from "./NodeCanvasBackendEventTypes";
import {useContext} from "react";
import {Node, NodeConfig} from "../backend/Node";
import {NodeEvent} from "../backend/NodeEvent";
import {v4} from "uuid";
import {NodeEnvironment} from "../host/NodeEnvironment";
import {SecondarySelectionState} from "./SecondarySelectionState";

export type NodeCanvasBackendUIBackgroundState = {
    grid: {
        show: boolean
    }
}

export type NodeCanvasBackendUICanvasState = {
    wiring: {
        /**
         * "Flickering" color animation whenever a value is transmitted over a wire
         */
        enableFlickering: boolean
    }

    nodes: {
        widgets: {
            enabled: boolean
        }
    }
}

export type NodeCanvasBackendState = {
    system: {
        canvasId: string
    },
    ui: {
        selection: {
            prime: {
                selectedNodeId?: string
            },
            secondary?: SecondarySelectionState
        }

        canvas: NodeCanvasBackendUICanvasState

        background: NodeCanvasBackendUIBackgroundState
    }
}

export class NodeCanvasBackend extends ReactBackend<NodeCanvasBackendState> {
    public readonly events: StandaloneObservable<NodeCanvasBackendEventTypes>
        = new StandaloneObservable<NodeCanvasBackendEventTypes>();

    public _environment?: NodeEnvironment;

    // public nodes: Array<Node> = []

    public set environment(env: NodeEnvironment) {
        this._environment = env;
    }

    public get environment(): NodeEnvironment {
        return this._environment!;
    }

    /**
     * TODO: remove
     * @deprecated for removal
     * @param nodeID
     */
    public getNodeByID(nodeID: string): Node {
        return this.environment.nodes.find(node => node.id === nodeID)!;
    }

    public deleteNode(nodeID: string) {
        this.environment.deleteNode(nodeID);
        this.events.notify(NodeCanvasBackendEventTypes.GRAPH_UPDATE, new NodeEvent());
    }

    public duplicateNode(nodeId: string): Node {
        const srcNode = this.environment.getNodeByID(nodeId);
        if (srcNode === undefined) throw new Error(`Cannot duplicate node '${nodeId}': node wasn't found`);

        const config: NodeConfig<any> = { ...srcNode.config };
        config.id = v4();
        const destNode = new Node(config);

        // todo: this may cause problems... fix it or remove it... -> maybe add a "duplicateState"-fn to Node class
        // destNode.state.setState(srcNode.state.state);

        this.environment.registerNode(destNode);

        this.events.notify(NodeCanvasBackendEventTypes.GRAPH_UPDATE, new NodeEvent());
        return destNode;
    }

    /*
     * Source-section: Selection
     */

    public setPrimeSelectedNode(target: string | undefined) {
        this.setState(prevState => {
            const newState = { ...prevState };
            newState.ui.selection.prime.selectedNodeId = target;
            return newState;
        });
    }

    /**
     * TODO: Make it work on a api-based class like SecondarySelection -> seSe.setSelected(...)
     *
     * @param selectedHtmlElements
     */
    public setSecondarySelection(selectedHtmlElements: Array<HTMLElement> = []) {
        if (selectedHtmlElements.length === 0) {
            // Clear selection
            this.setState(prevState => {
                const newState = { ...prevState };
                newState.ui.selection.secondary = undefined;
                return newState;
            });
            return;
        }

        // Create new selection or update existing selection
        this.setState(prevState => {
            const newState = { ...prevState };
            let secondarySelection = newState.ui.selection.secondary;
            if (secondarySelection === undefined) {
                // Create new selection
                newState.ui.selection.secondary = {
                    htmlNodes: selectedHtmlElements
                };
            } else {
                // Update existing selection
                newState.ui.selection.secondary!.htmlNodes = selectedHtmlElements;
            }

            return newState;
        });
    }

    public endSecondarySelection() {
        this.setState(prevState => {
            const newState = { ...prevState };
            newState.ui.selection.secondary = undefined;
            return newState;
        });
    }

    public isNodePrimeSelected(target: string): boolean {
        return this.state.ui.selection.prime.selectedNodeId === target;
    }

    public getCanvasID(): string {
        return this.state.system.canvasId;
    }

    public getCanvasIDBasedID(subID: string): string {
        return `${this.getCanvasID()}-${subID}`;
    }

    public getAllSecondarySelectedNodes(): Array<Node> {
        if (this.state.ui.selection.secondary?.htmlNodes === undefined) return [];
        const nodeIdAttrName = "data-node-target-id";
        return this.state.ui.selection.secondary!.htmlNodes
            .filter(e => e.hasAttribute(nodeIdAttrName))
            .map(e => {
                const nodeId = e.getAttributeNode(nodeIdAttrName)?.value;
                if (nodeId === undefined) throw new Error(`Attribute '${nodeIdAttrName}' doesn't have a value`);
                return this.environment.getNodeByID(nodeId);
            });
    }
}

export const nodeCanvasBackendBridge = createBridge<NodeCanvasBackendState, NodeCanvasBackend>({
    state: {
        system: {
            canvasId: v4() // TODO: Convert to parameter
        },
        ui: {
            selection: {
                prime: {
                    selectedNodeId: undefined
                }
            },
            canvas: {
                wiring: {
                    enableFlickering: true
                },

                nodes: {
                    widgets: {
                        enabled: true
                    }
                }
            },
            background: {
                grid: {
                    show: false
                }
            }
        }
    },
    backend() {
        return new NodeCanvasBackend();
    }
});

export function useNodeCanvasBackend(): NodeCanvasBackend {
    return useContext(nodeCanvasBackendBridge.backendCtx);
}
