import React, {useEffect, useState} from "react";
import {Image} from "../data/Image";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {Utils} from "../../base/Utils";
import {Tag} from "./Tag";
import {DataObjectRounded, StarRounded, TagRounded} from "@mui/icons-material";
import {TagEditor} from "./TagEditor";
import {webDB} from "../webapi/WebAPIDB";
import {IconButton} from "./IconButton";
import {FlatIconButton} from "./FlatIconButton";
import {Menu} from "./Menu";
import ReactJson from "react-json-view";
import {MenuButton} from "./MenuButton";

export type ImageViewFooterProps = {
    open: boolean,
    image: Image | undefined
}

export const ImageViewFooter: React.FC<ImageViewFooterProps> = props => {
    const image = props.image;

    const [localState, setLocalState] = useState<{
        estFileSizeInBytes?: number,
        openTagEditor: boolean
    }>({
        openTagEditor: false
    });

    useEffect(() => {
        if (image === undefined) return;

        image.data.arrayBuffer().then(r => {
            // TODO: Add timing analysis
            // console.debug("Image meta", ExifReader.load(r))

            setLocalState(prevState => ({
                ...prevState,
                estFileSizeInBytes: r.byteLength
            }));
        })
    }, [image]);

    return (
        <TransitionGroup style={{ width: "100%" }} children={
            !props.open ? undefined : (
                <Collapse key={"image-view-footer-tray"} children={
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                        width: "100%",

                        marginTop: ".5rem"
                    }}>
                        {/* LEFT SECTION :: TAGS */}
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            alignItems: "center",
                        }}>
                            <IconButton size={"small"} children={<StarRounded sx={{ transition: "color 50ms", color: image?.favourite ? "gold" : "inherit" }}/>} onClick={() => {
                                webDB.images.update(image?.id!, {
                                    "favourite": !image?.favourite ?? true
                                });
                            }}/>
                            <IconButton size={"small"} tooltip={"Edit tags"} children={<TagRounded/>} onClick={() => {
                                setLocalState(prevState => ({
                                    ...prevState,
                                    openTagEditor: true
                                }));
                            }}/>

                            { image?.tags?.map(tag => (
                                <Tag key={tag} tag={tag}/>
                            )) }

                            { image?.tags === undefined || image.tags.length === 0 ? (
                                <DescriptiveTypography text={"No tags"} noSelect/>
                            ) : undefined}

                            <TagEditor initialTags={image?.tags ?? []} open={localState.openTagEditor} onClose={() => {
                                setLocalState(prevState => ({
                                    ...prevState,
                                    openTagEditor: false
                                }));
                            }} onSave={tags => {
                                webDB.images.update(image?.id!, {
                                    "tags": tags
                                });
                                setLocalState(prevState => ({
                                    ...prevState,
                                    openTagEditor: false
                                }));
                            }}/>
                        </div>

                        {/* RIGHT SECTION :: PNG  META */}
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            alignItems: "center",
                        }}>
                            <DescriptiveTypography text={
                                `${Utils.humanFileSize(localState.estFileSizeInBytes ?? -1)}, ${props.image?.id}`
                            }/>


                            <Menu opener={
                                <Tag tag={<DataObjectRounded style={{ fontSize: 14 }}/>}/>
                            }>
                                {(() => {
                                    if (image === undefined) return undefined;
                                    const originalBlobSize = image!.data.size;
                                    const previewBlobSize = image!.previewData?.size;
                                    const compressionFactor = originalBlobSize / (previewBlobSize ?? 0);
                                    const compressionSizePercentage = (previewBlobSize ?? 0) / originalBlobSize * 100;

                                    return (
                                        <>
                                            <MenuButton text={`Original blob factor`} appendix={
                                                <DescriptiveTypography text={
                                                    Utils.humanFileSize(originalBlobSize ?? -1)
                                                }/>
                                            }/>
                                            <MenuButton text={`Preview blob size`} appendix={
                                                <DescriptiveTypography text={
                                                    Utils.humanFileSize(previewBlobSize ?? -1)
                                                }/>
                                            }/>

                                            <MenuButton text={`Preview compression factor`} appendix={
                                                <DescriptiveTypography text={`${compressionFactor.toFixed(1)} (${compressionSizePercentage.toFixed(1)}%)`}/>
                                            }/>
                                            <ReactJson src={image!} theme={"grayscale"} enableClipboard={false}/>
                                        </>
                                    );
                                })()}
                            </Menu>


                        </div>
                    </div>
                }/>
            )
        }/>
    );
}
