import {TabInfo} from "./TabInfo";
import {TritonTabManager} from "./TritonTabManager";

export class Tab {

    public info: TabInfo;

    public isActive: boolean = false;

    constructor(
        info: TabInfo,
        public readonly tabId: string,
        private readonly manager: TritonTabManager
    ) {
        this.info = info;
    }

    public onActivate() {
        this.isActive = true;
    }

    public onDeactivate() {
        this.isActive = false;
    }

    public onDestroy() {

    }
}
