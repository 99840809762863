import React from "react";
import styled from "styled-components";
import {ArcText} from "../../ardspace/arc/common/ArcText";

const StyledIOButton = styled.button`
  background-color: #252525;
  // background-color: #1e202a;
  display: flex;
  flex-direction: row;
  gap: 0;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 8px;
  transition: all 120ms;
  height: 32px;
  overflow: hidden;
  cursor: pointer;
  
  .side {
    aspect-ratio: 1 / 1;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 120ms;
    // background-color: #252525;
    background-color: #1e202a;
    
    &:hover, &:focus {
      filter: brightness(1.2); 
    }
    
    &:active {
      filter: brightness(1.4);
    }
    
    &.off[data-on=false] {
      // background-color: #F94F4F;
      background-color: #B55EF9;
    }

    &.on[data-on=true] {
      background-color: #F09F3F;
    }
  }
`;

export const IOButton: React.FC<{
    on: boolean,
    set: (on: boolean) => void
}> = props => {
    return (
        <StyledIOButton onClick={() => props.set(!props.on)}>
            <div className={"side off"} data-on={props.on} children={
                <ArcText text={"0"}/>
            }/>
            <div className={"side on"} data-on={props.on} children={
                <ArcText text={"I"}/>
            }/>
        </StyledIOButton>
    );
}
