import {Test} from "./carbide/Test";
import {v4} from "uuid";
import {TestSuite} from "./carbide/TestSuite";
import {TestReactor} from "./carbide/TestReactor";
import {TestResult} from "./carbide/TestResult";

export function carbideTest(): TestResult {

    const t1: Test = {
        id: v4(),
        body() {
            this.assertEq(1, 2);
        }
    }

    const t2: Test = {
        id: v4(),
        body() {
            const a: any = undefined;
            a!.b = ""
        }
    }

    const t3: Test = {
        id: v4(),
        body() {}
    }

    const tSuite1: TestSuite = {
        id: v4(),
        tests: [t1, t2, t3]
    }

    return new TestReactor({
        suite: tSuite1
    }).run();
}
