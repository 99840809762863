import React from "react";
import {Speedometer} from "./Speedometer";
import {Orientation} from "../../base/logic/style/Orientation";
import {BasicSingleSelect} from "../../triton/components/forms/BasicSingleSelect";
import {ButtonBase} from "../../triton/components/buttons/ButtonBase";
import {HdrAutoRounded, SettingsRounded} from "@mui/icons-material";
import {Modal} from "../../triton/components/dialogs/Modal";
import {Formik, FormikProps} from "formik";
import {EngineState} from "./EngineState";
import {
    authoritySelectionOption,
    EngineArrayControlUI,
    MANUAL_AUTHORITY,
    SYSTEM_AUTHORITY,
    thrusterStates
} from "../TestScreen";
import {BooleanContext} from "../../triton/components/BooleanContext";
import {EngageSwitch} from "./EngageSwitch";
import {EngineControlUnit} from "./EngineControlUnit";
import {ShipAPIContext} from "../../ship/test/core/ShipAPI";
import {CircularProgress} from "@mui/material";

export type EngineControlUIProps = {

    // TODO: Remove
    formikProps: FormikProps<any>,

    id: string,
    displayName: string,
    engineState: EngineState,
    controlUnit: EngineControlUnit,

    eProdCap?: number
}

export const EngineControlUI: React.FC<EngineControlUIProps> = props => {
    const engagementState = props.controlUnit.getEngine().engagementState;

    return (
        <ShipAPIContext.Consumer children={api => {
            return (
                <div style={{
                    width: "100%",
                    backgroundColor: "rgb(1, 4, 9)",
                    position: "relative",
                    border: "1px solid rgb(33, 38, 45)",
                    borderRadius: "8px",
                    padding: "8px",
                    display: "grid",
                    gap: "8px"
                }}>
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "min-content auto",
                        gap: "8px"
                    }}>
                        <div children={
                            <Speedometer
                                displayWarnings
                                orientation={Orientation.VERTICAL}
                                key={`${props.id}-speedometer`}
                                val={props.controlUnit.getEngineEProd()}
                                // val={props.engineState.selectedEProd}
                            />
                        }/>

                        {/*
                        <CircularProgress variant={"determinate"} value={props.controlUnit.getEngineEProd()} size={16}/>
                        */}

                        <div style={{
                            display: "grid",
                            gap: "8px",
                            gridTemplateColumns: "auto",
                            width: "100%",
                        }}>



                            <div style={{
                                display: "grid",
                                gap: "8px",
                                // gridTemplateColumns: "repeat(2, 1fr)"
                                gridTemplateRows: "repeat(2, 1fr)"
                            }}>

                                <EngageSwitch state={engagementState} onStateChange={to => {
                                    props.controlUnit.toggleEngagement().then(() => {});
                                }}/>

                                <ButtonBase children={
                                    <span style={{
                                        display: "flex",
                                        height: "100%",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }} children={
                                        <HdrAutoRounded sx={{
                                            width: 16,
                                            height: 16,
                                            // color: props.controlUnit.getEngine().authority === MANUAL_AUTHORITY ? "white" : "#ffdf60"
                                            color: props.engineState.authority === MANUAL_AUTHORITY ? "white" : "#ffdf60"
                                        }}/>
                                    }/>
                                } baseProps={{
                                    type: "button",
                                    onClick: () => props.controlUnit.setEngineAuthority(props.engineState.authority === MANUAL_AUTHORITY ? SYSTEM_AUTHORITY : MANUAL_AUTHORITY),
                                    style: {
                                        width: "auto",
                                        padding: 0,
                                        aspectRatio: "1 / 1"
                                    }
                                }}/>

                                <BooleanContext children={(bool, setBool) => (
                                    <>
                                        <ButtonBase children={
                                            <span style={{
                                                display: "flex",
                                                height: "100%",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }} children={
                                                <SettingsRounded sx={{
                                                    width: 16,
                                                    height: 16,
                                                }}/>
                                            }/>
                                        } baseProps={{
                                            type: "button",
                                            onClick: () => setBool(!bool),
                                            style: {
                                                width: "auto",
                                                padding: 0,
                                                aspectRatio: "1 / 1"
                                            }
                                        }}/>
                                        <Modal open={bool} title={props.displayName} onClose={() => setBool(false)}>
                                            <Formik initialValues={{}} onSubmit={() => {
                                            }} children={fp => (
                                                <EngineArrayControlUI id={"test"} displayName={"Test"} formikProps={props.formikProps} controlUnit={props.controlUnit} engineState={props.engineState}/>

                                            )}/>
                                        </Modal>
                                    </>
                                )}/>
                            </div>
                        </div>


                        {/*<EngineArrayControlUI id={"test"} displayName={"Test"} formikProps={props.formikProps} controlUnit={props.controlUnit} engineState={props.engineState}/>*/}

                    </div >




                    {/*
                    <BasicSingleSelect
                        selected={props.engineState.authority}
                        centerSelectedElementBadge
                        placeholder={"Authority"}
                        disableSearchbar
                        name={`authority-${props.id}`}
                        options={authoritySelectionOption}
                        onSelect={authority => props.controlUnit.setEngineAuthority(authority)}
                    />
                    */}

                    <BasicSingleSelect
                        selected={props.engineState.manualSelectedProgram}
                        centerSelectedElementBadge
                        placeholder={"Program"}
                        disableSearchbar
                        name={`program-${props.id}`}
                        options={thrusterStates}
                        onSelect={program => props.controlUnit.selectEngineProgram(program)}
                    />
                </div>
            );
        }}/>
    );
}
