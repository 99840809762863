import React, {useState} from "react";
import styled from "styled-components";
import {StyledModal} from "../../ardai/components/StyledModal";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import IconButton from "@mui/material/IconButton";
import {Menu} from "../../ardai/components/Menu";
import {
    Circle,
    FormatBoldRounded,
    InfoRounded,
    OfflineBoltRounded,
    RawOnRounded,
    RefreshRounded
} from "@mui/icons-material";
import {DefaultButton} from "../../ardai/components/DefaultButton";
import {FALSE} from "sass";
import {Dot} from "../../base/components/base/Dot";
import {IOButton} from "./IOButton";
import {MenuButton} from "../../ardai/components/MenuButton";
import {Tag} from "../../ardai/components/Tag";
import {ColorableTag} from "../../ardai/components/ColorableTag";
import {Color} from "../../base/logic/style/Color";
import {CircleLoader} from "react-spinners";
import {CircularProgress} from "@mui/material";

const StyledBoolSense = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgb(22, 27, 34);
  align-items: center;
  width: min-content;
  gap: 30px;
  height: auto;
  border-radius: 12px;
  opacity: 1;
  padding: 8px;
  border: 1px solid rgb(48, 54, 61);
  
  .title, .tools {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .title {
    gap: 7px;

    .title-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
    }
  }

.tools {
  gap: 7px;
}
`;

export type BoolSenseProps = {
    on: boolean,
    onChange: (on: boolean) => Promise<void>,
    title: string
}

export const BoolSense: React.FC<BoolSenseProps> = props => {

    const [on, setOn] = useState(props.on);
    const [loading, setLoading] = useState(true)

    return (
        <StyledBoolSense>
            <div className={"title"}>
                <div className={"title-icon"}>
                    { loading ? <CircularProgress size={20} style={{
                        position: "absolute",
                        color: "#cccccc"
                    }}/> : undefined}
                    <Circle style={{
                        height: "9px",
                        width: "9px",
                        transition: "color 100ms",
                        color: on ? "#ffc66d" : "#B55EF9"
                    }}/>
                </div>

                <Tag tag={"boolean"} highlightOnHover={false}/>
                <DescriptiveTypography style={{ whiteSpace: "nowrap" }} text={props.title}/>
            </div>

            <div className={"tools"}>
                <IOButton on={on} set={nOn => {
                    // setOn(nOn)
                    setLoading(true)
                    props.onChange(nOn).then(() => {
                        setLoading(false)
                    })
                }}/>
                <Menu>
                    <MenuButton text={"Reset to default"} appendix={<ColorableTag c={Color.ofHex("#ffc66d")} tag={"false"} highlightOnHover={false}/>} icon={<RefreshRounded/>}/>
                    <MenuButton text={"Info"} icon={<InfoRounded/>}/>
                </Menu>
            </div>


        </StyledBoolSense>
    );
}
