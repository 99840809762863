import {InflationLibGlobals} from "./InflationLibGlobals";
import {MarkInflatableConfig} from "./DecoratorMarkInflatable";

export class InflateObjectAttachment {
    constructor(
        private readonly obj: object,
        private readonly cfg: MarkInflatableConfig
    ) {}

    public getTransients(): Array<string | symbol> {
        return [
            ...this.cfg.additionalTransients,
            ...(Reflect.getMetadata(InflationLibGlobals.inflateTransientsMetadataKey, this.obj) || [])
        ];
    }
}
