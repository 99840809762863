import React from "react";
import {BackgroundConfig} from "./BackgroundConfig";
import {BackgroundType} from "./BackgroundType";
import {BackgroundSolidInformation} from "./BackgroundSolidInformation";
import {BackgroundGradientInformation} from "./BackgroundGradientInformation";
import {BackgroundImageInformation} from "./BackgroundImageInformation";
import {BackgroundDataShape} from "./BackgroundDataShape";
import {ImageRenderingMode} from "../../atlas/hyperion/datatypes/ImageRenderingMode";

export type BackgroundProps<DShape extends BackgroundDataShape = any> = {
    config: BackgroundConfig<DShape>
}

export const Background: React.FC<BackgroundProps> = props => {
    switch (props.config.type) {
        case BackgroundType.SOLID:
            return <SolidBackground {...props}/>;
        case BackgroundType.GRADIENT:
            return <GradientBackground {...props}/>;
        case BackgroundType.IMAGE:
            return <ImageBackground {...props}/>;
    }

    return (
        <>not implemented</>
    );
}

export const SolidBackground: React.FC<BackgroundProps<BackgroundSolidInformation>> = props => {
    const d = props.config.data;
    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
            backgroundColor: d.color,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed"
        }}/>
    );
}

export const GradientBackground: React.FC<BackgroundProps<BackgroundGradientInformation>> = props => {
    const d = props.config.data;
    const bg = `linear-gradient(${d.deg}deg, ${d.colors.map(c => `${c.color} ${c.position}%`).join(", ")})`;
    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
            background: bg,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed"
        }}/>
    );
}

/**
 * TODO: Add support for ISA images
 *
 * @param props
 * @constructor
 */
export const ImageBackground: React.FC<BackgroundProps<BackgroundImageInformation>> = props => {
    const d = props.config.data;
    const src = d.srcLink;

    // return (
    //     <ISAImage imageID={"6b8f510b-4161-4566-9ee7-8d0ff94642c4"} isaTable={"images"} style={{
    //         position: "absolute",
    //         top: 0,
    //         left: 0,
    //         width: "100%",
    //         height: "100%",
    //         zIndex: -1,
    //         opacity: d.opacity ?? 1,
    //         filter: `blur(${d.blur ?? 0}px)`,
    //         imageRendering: d.renderingMode ?? ImageRenderingMode.AUTO
    //     }}/>
    // );

    return (
        <img alt={"background"} src={src} style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            // height: "100%",
            height: "100vh",
            zIndex: -1,

            objectFit: "cover",
            // objectFit: "contain",
            objectPosition: "top",

            opacity: d.opacity ?? 1,
            filter: `blur(${d.blur ?? 0}px)`,
            imageRendering: d.renderingMode ?? ImageRenderingMode.AUTO
        }}/>
    );
}
