import {ResourceManager} from "../backend/ressources/ResourceManager";
import {Node} from "../backend/Node";
import {NodeCanvasBackendEventTypes} from "../frontend/NodeCanvasBackendEventTypes";
import {NodeEvent} from "../backend/NodeEvent";
import {v4} from "uuid";
import {IKVStore, LocalStorageKVStore} from "./IKVStore";
import {NodeSetupInfo} from "../frontend/NodeSetupInfo";
import {SaveStateNode} from "../backend/serialization/v2/SaveStateNode";
import {ArrayLinkImpl} from "../../../std/Accumulate";
import {Inflatable} from "../backend/serialization/v2/Inflatable";
import {NodeEnvironmentSnapshot} from "./NodeEnvironmentSnapshot";

export class NodeEnvironment {

    private static readonly KV_KEY_SAVE = "save";

    public readonly resources = new ResourceManager();

    public nodes: Array<Node> = [];

    constructor(
        public readonly id: string = v4(),
        private readonly internalKVStore: IKVStore = new LocalStorageKVStore(
            `internal-${id}`
        )
    ) {}

    public get visibleNodes(): Array<Node> {
        return this.nodes.filter(n => !n.isHidden);
    }

    public getNodesOfClass(classname: string): Array<Node> {
        return this.nodes.filter(n => n.classname === classname);
    }

    public registerNode(node: Node): this {
        node.env = this;
        this.nodes.push(node);
        return this;
    }

    public getNodeByID(nodeID: string): Node {
        return this.nodes.find(node => node.id === nodeID)!;
    }

    public deleteNode(nodeID: string) {
        const node = this.getNodeByID(nodeID);
        if (node === undefined) throw new Error(`Cannot delete node '${nodeID}': node wasn't found`);
        node.cutLoose();
        node.onDelete();
        this.nodes = this.nodes.filter(n => n.id !== nodeID);
    }

    /**
     *
     * - retrieve inflate info from locally provided KV store : key (string) -> string
     * - perform default inflate-algo : string, this -> this (initiated)
     *
     */
    public inflate() {
        const serializedSnapshot = this.internalKVStore.get(NodeEnvironment.KV_KEY_SAVE);

        // TODO: Better err handling
        if (serializedSnapshot === undefined) throw new Error();

        const snapshot: NodeEnvironmentSnapshot = JSON.parse(serializedSnapshot);

        const deflatedNodes: SaveStateNode[] = snapshot.nodes;

        // TODO: Continue implementing
    }

    /**
     *
     * - perform default deflate-algo : this -> string
     * - store deflate info into locally provided KV store : string -> void
     */
    public deflate() {

        const deflatedNodes: SaveStateNode[] = new ArrayLinkImpl(this.nodes)
            .map(node => (node as unknown as Inflatable).deflate?.())
            .filter(x => x !== undefined)
            .map(x => x!)
            .accumulateAsDispatch<SaveStateNode[]>([], (x1, x2) => x1.push(x2))
            .unboxed

        const snapshot: NodeEnvironmentSnapshot = {
            nodes: deflatedNodes
        }
        const serializedSnapshot = JSON.stringify(snapshot);
        this.internalKVStore.set(NodeEnvironment.KV_KEY_SAVE, serializedSnapshot);
    }

    public get nodeLib(): NodeSetupInfo[] {
        return []
    }
}
