import {Observable} from "./pubsub/Observable";
import {Observer} from "./pubsub/Observer";

export enum StateHubEvents {
    STATE_UPDATE
}

export class StateHub<StateType> implements Observable<
    StateHubEvents, StateType
> {

    private _observers: Array<Observer<StateHubEvents>> = [];

    constructor(
        private _state: StateType
    ) {}

    public get state(): StateType {
        return this._state;
    }

    public set state(state: StateType) {
        this._state = state;
    }

    public updateState(updater: (prevState: StateType) => Partial<StateType>) {
        this.state = {
            ...this.state,
            ...updater(this.state)
        };
        this.indicateStateChange()
    }

    private indicateStateChange() {
        this.state = {...this.state};
        this.notify(StateHubEvents.STATE_UPDATE, this.state)
    }

    notify(key: StateHubEvents, data: StateType): void {
        this.observers.forEach(observer => {
            try {
                observer.notify(key, data)
            } catch (e) {
                // TODO: Log in better way
                console.error(e)
            }
        })
    }

    observe(...keys: StateHubEvents[]): Observer<StateHubEvents, StateType> {
        const obs = new Observer<StateHubEvents>(this, keys);
        this._observers.push(obs)
        return obs;
    }

    get observers(): Array<Observer<StateHubEvents>> {
        return this._observers;
    }

    unsubscribe(observer: Observer<any>): void {
        this._observers = this.observers.filter(o => o !== observer);
    }

    unsubscribeAll(): void {
        this._observers = []
    }
}
