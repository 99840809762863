import React from "react";
import {StyledModal} from "../StyledModal";
import {Grid} from "../../../triton/components/layouts/Grid";
import {ButtonBase, ButtonVariant} from "../../../triton/components/buttons/ButtonBase";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";

export type DangerConfirmationDialogProps = {
    confirmText?: string,
    cancelText?: string,
    onConfirmation: () => void,
    onCancel: () => void,
    title: string,
    description?: string
}

export const DangerConfirmationDialog: React.FC<DangerConfirmationDialogProps> = props => {
    return (
        <StyledModal title={props.title} children={
            props.description && (
                <DescriptiveTypography text={props.description}/>
            )
        } footer={
            <Grid style={{ width: "100%" }} gTC={"repeat(2, 1fr)"}>
                <ButtonBase variant={ButtonVariant.DANGER} text={props.confirmText ?? "Confirm"} baseProps={{
                    onClick: () => props.onConfirmation()
                }}/>
                <ButtonBase text={props.cancelText ?? "Cancel"} baseProps={{
                    onClick: () => props.onCancel()
                }}/>
            </Grid>
        }/>
    );
}
