import React from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {Workspace} from "./components/Workspace";
import {TestScriptingView} from "./unifiedGraphicsLanguage/TestScriptingView";
import {TestingViewMain} from "./testing/TestingViewMain";
import {default2MockupUserProfileProps, UserProfile, UserProfileProps} from "./testing/tests/profile/UserProfile";
import {DType} from "./testing/DType";
import {GalleryView} from "./views/gallery/GalleryView";
import {GenerationView} from "./views/generate/GenerationView";
import {DescriptiveTypography} from "../triton/components/typography/DescriptiveTypography";
import {NotFoundPage} from "./NotFoundPage";
import {DefaultButton} from "./components/DefaultButton";
import {ButtonBase} from "../triton/components/buttons/ButtonBase";
import {carbideTest} from "../carbide/Carbide";
import {CarbideTestComponent} from "../carbide/CarbideTestComponent";
import {CarbideFrontendMain} from "../carbide/frontend/CarbideFrontendMain";

export const ArdaiDevelopmentRoutesComponent: React.FC = props => {
    // const location = useLocation();
    const showDevRoutes = true;

    if (!showDevRoutes) return <></>;

    return (
        <Routes>
            <Route index element={
                <CarbideFrontendMain/>
            }/>

            <Route path={`/scripting`} element={
                <Workspace config={{ mode: "desktop", name: "scripting-test" }} children={
                    <TestScriptingView/>
                }/>
            }/>

            <Route path={`/component`} element={<TestingViewMain<UserProfileProps> test={{
                defaultProps: default2MockupUserProfileProps,
                generateComponent: p => <UserProfile {...p}/>,
                propertyDTypeLookup: {
                    primaryColor: DType.COLOR,
                    secondaryColor: DType.COLOR,
                    backgroundColor: DType.COLOR,
                    canvasOverlayColor: DType.COLOR,
                    bgSecondaryColor: DType.COLOR,
                    fontColor: DType.COLOR,
                }
            }}/>}/>

            <Route path={"*"} element={<NotFoundPage/>}/>
        </Routes>
    );
}
