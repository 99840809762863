import React from "react";
import {TopicSelectorState} from "./TopicSelectorState";
import {StateDispatcher} from "../../../ship/test/core/StateDispatcher";

export function generateTopicSelectorBaseState(): TopicSelectorState {
    return {
        spiceCount: 1,
        useSpices: true,
        topics: [],
        spices: []
    }
}

export const TopicSelectorStateContext = React.createContext<[
    TopicSelectorState, StateDispatcher<TopicSelectorState>
]>([generateTopicSelectorBaseState(), () => {}]);
