import {IDisk} from "./IDisk";
import {DiskInfo} from "./DiskInfo";
import {FSLSSnapshot} from "./FSLSSnapshot";
import {NetDiskConfig} from "./NetDiskConfig";
import {FSLoadingState} from "./FSLoadingState";
import {FSManager} from "./FSManager";
import {StateHub, StateHubEvents} from "../StateHub";
import {DiskState} from "./DiskState";
import {Path} from "./Path";
import axios from "axios";
import {StandaloneObservable} from "../pubsub/StandaloneObservable";
import {Observer} from "../pubsub/Observer";
import {DiskMountingState} from "./DiskMountingState";

export enum NetDiskEvents {
    DISK_STATE_CHANGE,
    FS_LS_SNAPSHOT_CHANGE
}

export class NetDisk implements IDisk {

    private _state = new StateHub<DiskState>({
        mountingState: DiskMountingState.UNMOUNTED,
        currentPath: this.getInitialPath(),
        fsLoadingState: FSLoadingState.INITIAL,
        lsSnapshot: undefined
    });

    public readonly _observer: StandaloneObservable<NetDiskEvents> = new StandaloneObservable;

    private stateObserver?: Observer<any>;

    constructor(
        private readonly driver: FSManager,
        private _info: DiskInfo,
        public readonly config: NetDiskConfig
    ) {}

    get observable(): StandaloneObservable<NetDiskEvents> {
        return this._observer;
    }

    getInitialPath(): Path {
        return ({
            disk: this.info.id,
            path: []
        });
    }

    isAtRootLevel(): boolean {
        return this.state.state.currentPath.path.length === 0;
    }

    public cdUp(n: number = 1) {
        const cp = this.state.state.currentPath;
        this.state.updateState(pS => ({
            currentPath: {
                disk: pS.currentPath.disk,
                path: pS.currentPath.path.slice(0, -n)
            }
        }));
        this.reloadLSSnapshot();
    }

    public cdDown(...subPath: Array<string>) {
        this.state.updateState(pS => ({
            currentPath: {
                disk: pS.currentPath.disk,
                path: [...pS.currentPath.path, ...subPath]
            }
        }));
        this.reloadLSSnapshot();
    }

    public cd(...path: Array<string>) {
        this.state.updateState(pS => ({
            currentPath: {
                disk: pS.currentPath.disk,
                path: path
            }
        }));
        this.reloadLSSnapshot();
    }

    public cdRoot() {
        this.cd();
    }

    get info(): DiskInfo {
        return this._info;
    }

    public reloadLSSnapshot() {
        const state = this.state.state;
        if (state.fsLoadingState === FSLoadingState.LOADING) return;

        this.state.updateState(() => ({
            fsLoadingState: FSLoadingState.LOADING
        }));
        // TODO make resolver disk-specific
        const diskHost = this.config.url;
        const protocol = "http";
        const path = "fs/ls/";
        axios.get(`${protocol}://${diskHost}/${path}${state.currentPath.path.join("/")}`).then(res => {
            this.state.updateState(() => ({
                fsLoadingState: FSLoadingState.LOADED,
                lsSnapshot: res.data
            }));
        }).catch(() => {
            this.state.updateState(() => ({
                fsLoadingState: FSLoadingState.ERROR,
                lsSnapshot: undefined
            }));
        }).finally(() => {
            this.observable.notify(NetDiskEvents.FS_LS_SNAPSHOT_CHANGE, this.state.state.lsSnapshot);
        });
    }

    async ls(): Promise<FSLSSnapshot> {
        // Get current fs manager path
        const path = this.state.state.currentPath;

        return new Promise(() => {})

        // axios.get(`http://127.0.0.1:8080/fs/ls/${sm.currentPath.path.join("/")}`).then(res => {
        //     this.setState(() => ({
        //         fsLoadingState: FSLoadingState.LOADED,
        //         lsSnapshot: res.data
        //     }));
        // }).catch(() => {
        //     this.setState(() => ({
        //         fsLoadingState: FSLoadingState.ERROR,
        //         lsSnapshot: undefined
        //     }));
        // });
    }

    get state(): StateHub<DiskState> {
        return this._state;
    }

    register() {
        if (this.state.state.mountingState !== DiskMountingState.UNMOUNTED) return;

        this.stateObserver = this.state.observe(StateHubEvents.STATE_UPDATE).on((key, data) => {
            this._observer.notify(NetDiskEvents.DISK_STATE_CHANGE, data);
        });

        this.state.updateState(() => ({
            mountingState: DiskMountingState.MOUNTED
        }));
    }

    unregister() {
        if (this.state.state.mountingState !== DiskMountingState.MOUNTED) return;
        this.stateObserver?.destructor();
        this.state.updateState(() => ({
            mountingState: DiskMountingState.UNMOUNTED
        }));
    }

    mount(): void {

    }

    unmount(): void {

    }
}
