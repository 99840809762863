import {FC} from "react";
import {Pin} from "../backend/Pin";
import {ColorableTag} from "../../ardai/components/ColorableTag";
import {Color} from "../../base/logic/style/Color";
import {Menu} from "../../ardai/components/Menu";
import {NodeDisplay} from "./NodeDisplay";
import {PinInfoDisplay} from "./PinInfoDisplay";

export type PinLinkTagProps = {
    pin: Pin
}

export const PinLinkTag: FC<PinLinkTagProps> = props => {
    const { pin } = props;
    return (
        <div>
            <Menu padding={0} opener={
                <ColorableTag
                    tag={pin.config.label ?? "?"}
                    c={pin.config.dye === undefined
                        ? undefined
                        : Color.ofHex(pin.config.dye)
                    }
                />
            }>
                <div style={{
                    padding: 4
                }}>
                    <PinInfoDisplay pin={pin}/>
                </div>
            </Menu>
        </div>
    );
}
