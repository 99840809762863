import React, {FC} from "react";
import {NodeComponentProps} from "./NodeComponentProps";

/**
 * TODO: Implement
 *
 * @param props
 * @constructor
 */
export function LogDisplayComponent<LogLineType = string>(props: NodeComponentProps<{
    logLines: Array<LogLineType>,
    logLineRenderer: (line: LogLineType) => React.ReactNode
}>) {
    const maxLogLinesDisplayed = 100;
    const renderedLogLines = props.logLines
        .slice(0, maxLogLinesDisplayed)
        .map(logLine => props.logLineRenderer(logLine));

    return (
        <div style={{
            width: 200,
            height: 200,
            maxWidth: 300,
            maxHeight: 300,
            overflowY: "scroll",
            padding: "8px 0"
        }}>
            { renderedLogLines }
        </div>
    );
}
