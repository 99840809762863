import React, {useContext, useEffect, useState} from "react";
import {ArdaiAPIStateContext} from "../ArdaiMain";
import {VFSFolderContentViewer} from "./VFSFolderContentViewer";
import {
    ArrowLeftRounded,
    CheckRounded,
    CreateNewFolderRounded, CreateRounded,
    DownloadRounded,
    FullscreenRounded,
    MoreVertRounded, UploadRounded
} from "@mui/icons-material";
import {VFSElement} from "../data/VFSElement";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {IconButton} from "./IconButton";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {ButtonGroup} from "./ButtonGroup";
import {CheckMenuButton, MenuButton} from "./MenuButton";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {Menu} from "./Menu";
import {MenuDivider} from "@szhsin/react-menu";
import {VFSViewSettings} from "../settings/VFSViewSettings";
import {useAutoSettings, useSettings} from "../hooks/SettingsHook";
import {FileStructureImportModal} from "./FileStructureImportModal";
import {BooleanContext} from "../../triton/components/BooleanContext";
import {TabBar} from "./TabBar";
import {TabBodyRenderer} from "./TabBodyRenderer";
import {VFSTrayTab} from "../trays/vfs/VFSTrayTab";
import {SearchTrayTab} from "../trays/vfs/SearchTrayTab";

export enum VFSViewTab {
    QA = "qa",
    VFS = "vfs",
    SEARCH = "search"
}

export type VFSViewState = {
    tab: VFSViewTab
}

export const VFSView: React.FC = props => {
    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);
    const [currentFolder, setCurrentFolder] = useState<VFSElement | undefined>(undefined);
    useEffect(() => {
        api.getCurrentElement().then(ce => {
            setCurrentFolder(ce);
        });
    }, [api, state]);

    const settings = useAutoSettings<VFSViewSettings>("VFSViewSettings") ?? {
        defaultPreview: false
    };

    const [ls, setLs] = useState<VFSViewState>({
        tab: VFSViewTab.QA
    });

    return (
        <VFSViewSettingsContext.Provider value={settings} children={
            <div style={{
                display: "grid",
                gridTemplateRows: "min-content auto",
                gap: "8px",
                height: "100%",
                borderRadius: ".5rem",
            }}>
                <TabBar equalSizeMode activeBar={ls.tab} onTabChange={tab => setLs(prevState => ({
                    ...prevState,
                    tab: tab as VFSViewTab
                }))} tabs={[
                    { id: "qa", title: "Local" },
                    { id: "vfs", title: "Explorer" },
                    { id: "search", title: "Search" },
                ]}/>

                <div style={{
                    width: "100%",
                    gap: "8px",
                    height: "100%",

                    // overflowY: "scroll",
                    overflowY: "hidden",

                    overflowX: "visible",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <TabBodyRenderer active={ls.tab} tabs={new Map<string, () => React.ReactElement>([
                        [VFSViewTab.QA, () => <VFSFolderContentViewer/>],
                        [VFSViewTab.VFS, () => <VFSTrayTab/>],
                        [VFSViewTab.SEARCH, () => <SearchTrayTab/>],
                    ])}/>
                </div>

            </div>
        }/>
    );

    // return (
    //     <VFSViewSettingsContext.Provider value={settings} children={
    //         <div style={{
    //             display: "flex",
    //             flexDirection: "column",
    //             gap: "8px",
    //             height: "100%",
    //             borderRadius: ".5rem",
    //             overflow: "hidden"
    //         }}>
    //             <div children={
    //                 <TabBar equalSizeMode activeBar={ls.tab} onTabChange={tab => setLs(prevState => ({
    //                     ...prevState,
    //                     tab: tab as VFSViewTab
    //                 }))} tabs={[
    //                     { id: "qa", title: "Quick access" },
    //                     { id: "vfs", title: "Explorer" },
    //                     { id: "search", title: "Search" },
    //                 ]}/>
    //             }/>
    //             <div style={{
    //                 width: "100%",
    //                 gap: "8px",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //                 flexGrow: 1,
    //                 flexShrink: 1,
    //             }}>
    //                 <TabBodyRenderer active={ls.tab} tabs={new Map<string, () => React.ReactElement>([
    //                     [VFSViewTab.VFS, () => <VFSTrayTab/>],
    //                     [VFSViewTab.SEARCH, () => <SearchTrayTab/>],
    //                 ])}/>
    //             </div>
    //         </div>
    //     }/>
    // );
}

export const VFSViewSettingsContext = React.createContext<VFSViewSettings>({
    defaultPreview: false
});
