import React, {useContext} from "react";
import {Storybook} from "../../../../storybook/Storybook";
import {credentialsPage} from "./CredentialsStoryPage";
import {SignalFunction} from "../../../../storybook/SignalFunction";
import {MobileBoardingPageContext} from "../../../boarding/MobileBoardingPage";
import {BoardingFlowMode} from "../../../boarding/BoardingFlowMode";
import {useNavigate} from "react-router-dom";

export const LoginStorybook: React.FC = (props) => {
    const mobileBoardingPageContext = useContext(MobileBoardingPageContext);
    const navigate = useNavigate();

    return (
        <Storybook
            beginning={credentialsPage}
            onExit={() => window.alert("Story finished!")}
            signals={new Map<string, SignalFunction>([
                ["switch-to-signup", (api, data) => {
                    if (mobileBoardingPageContext !== undefined) {
                        mobileBoardingPageContext?.switchAuthFlow(data as BoardingFlowMode);
                    } else {
                        navigate("boarding/signup");
                    }
                }]
            ])}
        />
    );
}
