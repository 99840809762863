import React from "react";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import styled from "styled-components";
import {Menu} from "../../../components/Menu";
import {MenuButton} from "../../../components/MenuButton";
import {IconButton} from "../../../components/IconButton";
import {MinimizeSharp} from "@mui/icons-material";
import {DefaultButton} from "../../../components/DefaultButton";
import {MainTypography} from "../../../../triton/components/typography/MainTypography";
import {NotificationBalloon} from "./NotificationBalloon";

const StyledNotificationCenterTest = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 8px;
  
  .notification-center-toolbox-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: min-content auto min-content;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    // background-color: rgb(16, 16, 22);
    background-color: black;
    gap: 8px;
  }
`;

export const NotificationCenterWrapperTest: React.FC = props => {
    const open = false;

    return (
        <TransitionGroup style={{
            position: "absolute",
            bottom: 8,
            right: 8,
            zIndex: 1
        }} children={
            open && (
                <Collapse children={
                    <NotificationCenterTest/>
                }/>
            )
        }/>
    );
}

export const NotificationCenterTest: React.FC = props => {
    return (
        <StyledNotificationCenterTest>

            <div className={"notification-center-sky-wrapper"} children={
                <NotificationBalloon notification={{
                    message: "Hello world"
                }}/>
            }/>

            <div className={"notification-center-toolbox-wrapper"}>
                <Menu>
                    <MenuButton text={"Clear all"}/>
                </Menu>

                <DefaultButton variant={"primary"} size={"small"} fullwidth children={<MainTypography noSelect text={"Acknowledge"} style={{
                    color: "white",
                    fontSize: ".9rem",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "calc(100% - 0px)"
                }}/>}/>

                <IconButton tooltip={"Minimize"} variant={"default"} size={"small"} children={
                    <MinimizeSharp/>
                }/>
            </div>
        </StyledNotificationCenterTest>
    );
}
