import {StorybookPage} from "../../../../storybook/StorybookPage";
import {Flex} from "../../../../Flex";
import {StorybookStaticStepIndicator} from "../../../../components/StorybookStaticStepIndicator";
import {TypoHeaderVisual} from "../../../../components/TypoHeaderVisual";
import {EmailVerifyPinCompound} from "../../components/EmailVerifyPinCompound";
import React from "react";
import {welcomePage} from "./AccountCreatedStoryPage";
import {arcSocketURL, arcURL} from "../../../../../Globals";
import {SSPPEngine} from "../../../../../shared/SSPPEngine";
import {sendSSPPPacket} from "../../../../../shared/SSPPUtils";
import {axiosExo} from "../../../../../shared/Exo";
import {CRH} from "../../../../../shared/ConditionalResponseHandler";
import {commonCore} from "../../../../api/CommonCore";
import {LoginStatusMode} from "../../../../../shared/LoginStatusMode";
import {EmailAndUsernameStoryPageData} from "./EmailAndUsernameStoryPage";
import {PasswordStoryPageData} from "./PasswordStoryPage";

export const verifyEmailPage: StorybookPage = {
    name: "verifyEmail",
    header: (api, fp) => (
        <Flex g={30} baseProps={{
            style: {
                flexDirection: "column",
                alignItems: "center"
            }
        }}>
            <StorybookStaticStepIndicator step={5} processingAnimation={fp.isSubmitting}/>
            <TypoHeaderVisual
                header={"Verify email"}
                descriptiveSubheader={"We sent you a verification email"}
                text={"Please enter the verification code we’ve sent you"}
            />
        </Flex>
    ),
    onPageInit: (api, isMaster) => {
        if (!isMaster) return;
        const fp = api.fpRef.current!;

        // TODO: Move to page init function
        // TODO: Store in page local meta
        // TODO: Destroy in page destruction method
        new WebSocket(arcSocketURL("email-verification")).onopen = function (this, ev) {
            console.debug("[Socket] Connection opened")

            // Create response handler & register handshake response packet handler
            new SSPPEngine()
                // Handshake @ response
                .on("response@handshake", ctx => {
                    if (ctx.isFlagged("alreadyVerified")) {
                        // The email was already verified :: Skip verification page
                        const email = api.getPageData<EmailAndUsernameStoryPageData>("emailAndUsername")?.email;
                        const password = api.getPageData<PasswordStoryPageData>("password")?.password;
                        if (email === undefined || password === undefined) {
                            window.alert("[Debug] email or password are undefined!")
                        }

                        commonCore().loginByBasicCredentials(email!, password!).then(r => {
                            // End loading animation
                            fp.setSubmitting(false);

                            // Reroute if the automated, internal login procedure worked correctly
                            if (r.mode === LoginStatusMode.OK) {
                                api.submitAndForward(welcomePage);
                                return;
                            }
                            window.alert(`[Debug] auto login after account creation went wrong :: ${r.mode}`);
                        });
                    }
                })
                // Verification success notification
                .on("email-verified-success", ctx => {
                    const email = api.getPageData<EmailAndUsernameStoryPageData>("emailAndUsername")?.email;
                    const password = api.getPageData<PasswordStoryPageData>("password")?.password;
                    if (email === undefined || password === undefined) {
                        window.alert("[Debug] email or password are undefined!")
                    }
                    commonCore().loginByBasicCredentials(email!, password!).then(r => {
                        // End loading animation
                        fp.setSubmitting(false);

                        // Reroute if the automated, internal login procedure worked correctly
                        if (r.mode === LoginStatusMode.OK) {
                            api.submitAndForward(welcomePage);
                            return;
                        }
                        window.alert(`[Debug] auto login after account creation went wrong :: ${r.mode}`);
                    });
                })
                .bind(this);

            // Send handshake packet
            sendSSPPPacket(this, "handshake", data => {
                data.set("email", api.getPageData("emailAndUsername").email);
            });
        };
    },
    page: (api, fp) => (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "center",
            width: "100%"
        }}>
            <EmailVerifyPinCompound verify={pin => new Promise<boolean>(resolve => {
                fp.setSubmitting(true);
                axiosExo({
                    method: "post",
                    url: arcURL("account/verifyByCode"),
                    data: {
                        email: api.getPageData("emailAndUsername").email,
                        code: pin.toString()
                    }
                }, {
                    crh: new CRH()
                        .ok(() => resolve(true))
                        .when(-200, () => resolve(false))
                        .when(-201, () => resolve(false))
                        .when(-202, () => resolve(false))
                        .when(-203, () => resolve(false))
                }).then(() => {})
            })}/>
        </div>
    )
}
