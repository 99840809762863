import React from "react";
import {Formik, FormikProps} from "formik";
import {FormElement} from "./FormElement";
import {Slider} from "@mui/material";
import {IconButton} from "../../../ardai/components/IconButton";
import {InfoRounded, RefreshRounded} from "@mui/icons-material";
import {Menu} from "../../../ardai/components/Menu";
import {MenuButton} from "../../../ardai/components/MenuButton";
import {StyledVariableSlider, VariableSliderProps} from "../../../imageSorter/sdInterface/VariableSlider";
import {useTriton} from "../../TritonHooks";

export type FormikBasicSliderProps = {
    title?: string,
    shortInfoText?: string,
    minVal?: number,
    maxVal?: number,
    // value?: number,
    defaultValue?: number,
    fp: FormikProps<any>,
    name: string
}

export const FormikBasicSlider: React.FC<FormikBasicSliderProps> = props => {
    const t = useTriton();

    return (
        <StyledVariableSlider t={t}>
            <FormElement title={props.title}>
                <div style={{
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                    width: "100%",
                    gap: "8px",
                }}>
                    <div style={{
                        backgroundColor: "#101016",
                        flexDirection: "row",
                        borderRadius: "8px",
                        alignItems: "center",
                        display: "flex",
                        padding: "8px 16px",
                        height: "32px",
                        width: "100%"
                    }}>
                        <Slider
                            onChange={(event, value) => props.fp.setFieldValue(props.name, value as number)}
                            value={props.fp.values[props.name]}
                            valueLabelDisplay={"auto"}
                            min={props.minVal ?? 1}
                            max={props.maxVal ?? 100}
                            sx={{ color: "#5028c8" }}
                            size={"small"}
                            marks={[
                                // { value: 50, label: <DescriptiveTypography text={"default"}/> },
                                // { value: 70 },
                            ]}
                        />
                    </div>


                    <input className={"number-input"} value={props.fp.values[props.name]} inputMode={"numeric"} min={props.minVal ?? 1} max={props.maxVal ?? 100} type={"number"} step={1} onChange={event => {
                        props.fp.setFieldValue(props.name, event.currentTarget.value)
                    }}/>
                    <IconButton tooltip={"Reset"} size={"small"} onClick={() => props.fp.setFieldValue(props.name, props.defaultValue ?? props.minVal ?? 1)} children={
                        <RefreshRounded/>
                    }/>
                    {/*
                    <IconButton size={"small"} children={
                        <InfoRounded/>
                    }/>
                    <Menu>
                        <MenuButton text={"Full documentation"} icon={<InfoRounded/>}/>
                    </Menu>
                    */}
                </div>
            </FormElement>
        </StyledVariableSlider>

    );
}
