import {FC, useRef} from "react";
import styled from "styled-components";
import {v4} from "uuid";
import {Triton} from "../../../../triton/Triton";
import {useTriton} from "../../../../triton/TritonHooks";
import {DataManipulatorBaseProps} from "./DataManipulatorBaseProps";
import {Checkbox} from "@mui/material";

const StyledBooleanManipulationDisplay = styled.div<{
    t: Triton
}>`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  color: ${p => p.t.col("color_primary")};
`;

export type BooleanManipulationDisplayProps = DataManipulatorBaseProps<boolean, {}>

export const BooleanManipulationDisplay: FC<BooleanManipulationDisplayProps> = props => {
    const t = useTriton();
    const value = props.value;

    const tColChecked = t.col("color_secondary");
    const tColUnchecked = t.col("fg_muted");

    const changeValueTo = (value: boolean): void => {
        props.onUpdate(value);
    }

    return (
        <StyledBooleanManipulationDisplay t={t}>

            <div className={"checkbox-wrapper"}>
                <Checkbox
                    disableRipple
                    checked={value}
                    onChange={(event, checked) =>  {
                        changeValueTo(checked)
                    }}
                    size={"small"}
                    sx={{
                        height: 20,
                        color: tColUnchecked,
                        '&.Mui-checked': {
                            color: tColChecked,
                        },
                    }}
                />
            </div>
        </StyledBooleanManipulationDisplay>

    );
}
