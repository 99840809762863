import {APIShard} from "../APIShard";
import {ContentVisibilityRequest} from "./ContentVisibilityRequest";
import {ContentPolicyMandate} from "./ContentPolicyMandate";
import {ContentVisibility} from "./ContentVisibility";

export class ContentPolicyManager extends APIShard {

    public requestMandate(request: ContentVisibilityRequest): ContentPolicyMandate {
        return {
            visibility: ContentVisibility.RESTRICTED
        }
    }
}
