import {as} from "../../../../../atlas/Lang";
import {TabConfig} from "../TabConfig";
import {AzypodControlPanel} from "../../../entities/azypod/AzypodControlPanel";
import {ShipMovementPanel} from "../../../entities/ship/ShipMovementPanel";
import {SteeringAPControlPanel} from "../../../entities/ap/SteeringAPControlPanel";
import {ClutchControlDisplay} from "../../../entities/clutch/ClutchControlDisplay";
import {StyledControlDisplayGroup} from "../../../components/StyledControlDisplayGroup";
import {Speedometer} from "../../../../../test/engine/Speedometer";
import {Orientation} from "../../../../../base/logic/style/Orientation";
import {percent} from "../../../../../base/logic/style/DimensionalMeasured";
import {CoreControlDisplay} from "../../../entities/engine/CoreControlDisplay";
import {MultiplexedCoreControlPanel} from "../../../entities/engine/MultiplexedCoreControlPanel";
import {EngineMasterControlDisplay} from "../../../entities/engine/EngineMasterControlDisplay";
import {ValveControlDisplay} from "../../../entities/valve/ValveControlDisplay";
import React from "react";
import {BackgroundProps} from "../../../../../ardai/background/Background";
import {BackgroundSolidInformation} from "../../../../../ardai/background/BackgroundSolidInformation";
import {BackgroundType} from "../../../../../ardai/background/BackgroundType";

export const allTab = as<TabConfig>({
    id: "all",
    staticTabName: "Deprecated",
    renderer: ctx => {
        const sim = ctx.sim;
        return (
            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "8px", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "8px", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ gap: 8, display: "grid", gridTemplateColumns: "1fr" }}>
                        <AzypodControlPanel
                            entity={as<any>(
                                sim.entity("port-azypod")
                            )}
                        />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: "8px", justifyContent: "center", alignItems: "center" }}>
                        <ShipMovementPanel/>

                        <SteeringAPControlPanel/>
                    </div>


                    <div style={{ gap: 8, display: "grid", gridTemplateColumns: "1fr" }}>
                        <AzypodControlPanel
                            entity={as<any>(
                                sim.entity("port-azypod")
                            )}
                        />
                    </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row", gap: "8px", justifyContent: "center", alignItems: "center" }}>


                    <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }} children={
                        <div style={{ padding: 8, gap: 8, display: "grid", width: 370 }}>

                            <div style={{ gap: 8, display: "grid", gridTemplateColumns: "min-content auto min-content" }}>
                                <ClutchControlDisplay entity={sim.entity("main-shaft-engine-clutch")}/>

                                <StyledControlDisplayGroup>
                                    <Speedometer
                                        orientation={Orientation.VERTICAL}
                                        displayWarnings={false}
                                        width={percent(100)}
                                        val={sim.entity("engine")?.state?.ksu ?? 0}
                                    />
                                </StyledControlDisplayGroup>

                                <ClutchControlDisplay entity={sim.entity("main-shaft-engine-clutch-2")}/>
                            </div>


                            <StyledControlDisplayGroup>
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(5, 1fr)",
                                    gap: 8
                                }}>
                                    <CoreControlDisplay entity={sim.entity("engine")?.getChild("core-0")}/>
                                    <CoreControlDisplay entity={sim.entity("engine")?.getChild("core-1")}/>
                                    <CoreControlDisplay entity={sim.entity("engine")?.getChild("core-2")}/>
                                    <CoreControlDisplay entity={sim.entity("engine")?.getChild("core-3")}/>
                                    <CoreControlDisplay entity={sim.entity("engine")?.getChild("core-4")}/>
                                </div>
                            </StyledControlDisplayGroup>

                            <MultiplexedCoreControlPanel/>

                            <div style={{ gap: 8, display: "grid", gridTemplateColumns: "auto min-content" }}>
                                <EngineMasterControlDisplay entity={sim.entity("engine")}/>
                                <ValveControlDisplay entity={sim.entity("valve")}/>
                            </div>

                        </div>
                    }/>
                </div>
            </div>
        );
    }
})
