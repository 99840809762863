import {Simulation} from "../../Simulation";
import {as} from "../../../../atlas/Lang";
import {Entity} from "../../ecs/entity/Entity";
import {ThrustProducerTrait} from "../../traits/ThrustProducerTrait";

export type AzypodState = {
    requestedDeg: number,
    actualDeg: number,
    maxRotationSpeed: number,
    maxPhysicalRotationSpeed: number
};

export const azypodConfig = {
    maxRotationSpeed: 20,
    maxPhysicalRotationSpeed: 90
};

export const newAzypod = (sim: Simulation, id: string = "azypod", config: typeof azypodConfig = azypodConfig) => new Entity<AzypodState>(id, {
    traits: [
        new ThrustProducerTrait()
    ],
    initialState: as<AzypodState>({
        actualDeg: 0,
        requestedDeg: 0,
        maxRotationSpeed: azypodConfig.maxRotationSpeed,
        maxPhysicalRotationSpeed: azypodConfig.maxPhysicalRotationSpeed
    }),
    async asyncTick(e) {
        const s = e.state;

        // Cap the value of maxRotationSpeed to its physical limits
        if (s.maxRotationSpeed > s.maxPhysicalRotationSpeed) {
            e.setStatePartially({
                maxRotationSpeed: s.maxPhysicalRotationSpeed
            });
        }

        // Guards
        if (s.actualDeg == s.requestedDeg) return;
        // Intervention needed - Calculate deltas
        if (s.requestedDeg < s.actualDeg) {
            e.setStatePartially(prevState => ({
                actualDeg: Math.max(s.actualDeg - prevState.maxRotationSpeed, s.requestedDeg)
            }));
        } else {
            e.setStatePartially(prevState => ({
                actualDeg: Math.min(s.actualDeg + prevState.maxRotationSpeed, s.requestedDeg)
            }));
        }

    }
});
