import {Theme} from "./theming/Theme";
import {TritonDefaults} from "./TritonDefaults";
import {Color, ofHex} from "../base/logic/style/Color";
import {LinkTokenNamespace} from "./theming/ThemeBase";
import {StateDispatcher} from "../ship/test/core/StateDispatcher";
import {TritonState} from "./TritonState";
import {TritonFixtureConfig} from "./TritonFixtureConfig";
import {CSSProperties} from "react";

const defaultTritonFixtureConfig: TritonFixtureConfig = {
    makeGlobal: false
}

export class Triton {

    public static instance?: Triton;

    private _state: TritonState | undefined;

    private _setState: StateDispatcher<TritonState> | undefined;

    private readonly fallbackTheme: Theme;

    public readonly registeredThemes: Array<Theme> = []

    constructor(state?: TritonState, setState?: StateDispatcher<TritonState>) {
        this._state = state;
        this._setState = setState;
        this.fallbackTheme = TritonDefaults.theme;
    }

    /**
     * TODO: Ensure no duplicated get registered.
     *
     * @param theme
     */
    public registerThemes(...theme: Theme[]): this {
        this.registeredThemes.push(...theme);
        return this;
    }

    public switchTheme(themeID: string) {
        const newTheme = this.getTheme(themeID);
        if (newTheme === undefined) throw new Error(`Theme with id '${themeID}' wasn't found.`);
        this.setState(prevState => ({
            ...prevState,
            activeTheme: newTheme
        }));
    }

    /**
     * TODO: Handle case: No theme found with themeID.
     *
     * @param themeID
     */
    public getTheme(themeID: string) {
        return this.registeredThemes.filter(t => t.id === themeID)[0];
    }

    public start(config: Partial<TritonFixtureConfig> = defaultTritonFixtureConfig): this {
        if (config.makeGlobal) {
            Triton.instance = this;
        }
        return this;
    }

    public updateState(state: TritonState) {
        this._state = state;
    }

    public updateStateDispatcher(dispatcher: StateDispatcher<TritonState>) {
        this._setState = dispatcher;
    }

    public resetTheme() {
        this.setState(prevState => ({
            activeTheme: this.fallbackTheme
        }));
    }

    get state(): TritonState {
        return this._state!;
    }

    /**
     * Retrieves the current active theme
     */
    get theme(): Theme {
        return this._state?.activeTheme ?? this.fallbackTheme;
    }

    get setState(): StateDispatcher<TritonState> {
        return this._setState!;
    }

    /**
     * TODO: Rename to colHex
     *
     * Get a color from the currently active theme.
     *
     * @param token color context token
     * @param def CURRENTLY IGNORED - Might be used later on
     */
    public col(token: LinkTokenNamespace, def: string = Color.black.toHex()): string {
        const t = this.theme;
        const colorToken = t.linker[token];
        return t.colors[colorToken];
    }

    /**
     * TODO: Rename to col
     *
     * @param token "
     * @param def "
     */
    public colObj(token: LinkTokenNamespace, def: string = Color.black.toHex()): Color {
        return ofHex(this.col(token, def));
    }

    /**
     * TODO: Add support for baked css-triton-classes
     *
     * @param classname triton css classname
     * @param overwrites -
     */
    public styled(classname: string, overwrites: CSSProperties = {}): CSSProperties {
        const t = this.theme;
        const cssTritonClass = t.cssClasses[classname];
        const cssMixins = cssTritonClass.mixins.map(mixinName => {
            const mixin = t.cssMixins[mixinName];
            if (typeof mixin === "function") {
                return mixin.call(this, /* TODO: Provide args */);
            } else {
                return mixin as CSSProperties;
            }
        });
        let css: CSSProperties = {};
        const overwrite = (o: CSSProperties) => css = {
            // TODO: Check order
            ...css,
            ...o
        };
        cssMixins.forEach(cm => overwrite(cm));
        overwrite(overwrites);
        return css;
    }
}

export function triton(factory: () => Triton = () => new Triton()): Triton {
    return Triton.instance ?? factory();
}
