import {as} from "../../../atlas/Lang";
import {Std} from "../../../../Std";
import lerp = Std.lerp;
import invlerp = Std.invlerp;

export interface Func {
    minX?: number, maxX?: number;
    f(x: number, ...a: any[]): number
}

// Advanced functions

export const newDiscontinuedFunc = (
    ...parts: Array<[
        [number, number],
        Func
    ]>
) => as<Func>({
    f: (x, ...a) => (parts.filter(p => x >= p[0][0] && x < p[0][1])[0]?.[1] ?? newConstantFunc()).f(x, ...a)
})

export const newLinearTransformedLerpFunc = (minX: number, maxX: number, minFX: number = 0, maxFX: number = 0) => as<Func>({
    f: x => lerp(minFX, maxFX, invlerp(minX, maxX, x))
});


// f(x) = ax + b
export const newLinearFunc = (a: number = 1, b: number = 0) => as<Func>({
    f: x => a * x + b
});

export const identityFunc: Func = {
    f: x => x
}

export const newConstantFunc = (c: number = 0) => as<Func>({
    f: () => c
});
