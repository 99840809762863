import React from "react";
import styled from "styled-components";
import {DeckButton} from "./DeckButton";

const StyledStreamdeckTestMain = styled.div`
`;

export const StreamdeckTestMain: React.FC = props => {
    return (
        <StyledStreamdeckTestMain>
            <DeckButton/>
        </StyledStreamdeckTestMain>
    );
}
