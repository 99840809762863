import {FQ, NodeId} from "./serialization/FullyQualified";
import {v4} from "uuid";

export type FQNodeId = FQ<{
    node: NodeId
}>;


export const mkFQNodeId = (node: NodeId = v4()): FQNodeId => ({
    node
});
