import {Trait} from "../ecs/entity/trait/Trait";
import {StdTraits} from "./StdTraits";
import {KSU} from "../Simulation2Main";
import {PolarVec2D} from "../math/PolarVec2D";

export class ThrustProducerTrait extends Trait {

    constructor() {
        super({
            traitName: StdTraits.THRUST_PRODUCER
        });
    }

    public getThrust(): PolarVec2D {
        return new PolarVec2D(
            // Make configurable
            this.entity.state["actualDeg"],

            this.entity.state[KSU] ?? 0
        )
    }
}
