import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import React from "react";
import {NodeImageDisplayState} from "./NodeImageDisplay";
import _ from "lodash";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";

type NodeDisplayState = {
    val: any[]
}

export const NodeDisplay: NodeSetupInfo = {
    label: "NodeDisplay",
    classname: "visualization.display",
    parameterConfig: [],
    factory: parameters => {
        const resX = parameters.get("resX") ?? 20, resY = parameters.get("resY") ?? 1;

        return new Node<NodeDisplayState>({
            id: v4(),
            classname: "visualization.display",
            label: "display",
            state: { val: [] },
            init() {
                for (let y = 0; y < resY; y++) {
                    this.pins.in(`${y}`).attach({
                        read(val: any) {
                            this.node.state.update(p => {
                                p.val[y] = val;
                                return p;
                            });
                        }
                    })
                }
            },
            customRenderer: (node) => (
                <div style={{ width: "min-content" }}>
                    {
                        _.range(0, resY).map(y => {
                            return (
                                <DescriptiveTypography
                                    text={_.padStart(
                                        String(node.state.state.val[y] || DefaultCharacterSymbols.placeholder).slice(0, resX), resX, " ")
                                    }
                                    style={{ whiteSpace: "pre" }}
                                />
                            );
                        })
                    }
                </div>
            )
        });
    }
}
