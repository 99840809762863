import React from "react";
import styled from "styled-components";
import {ArcText} from "../ArcText";
import {CircularProgress} from "@mui/material";

export const StyledStorybookStaticStepIndicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  user-select: none;
  
  .circle {
    border-radius: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    font-size: 38px;
    background: linear-gradient(203.59deg, #B55EF9 12.66%, rgba(181, 94, 249, 0) 91.03%);
  }
  
  p {
    font-size: 14px;
  }
`;

export type StorybookStaticStepIndicatorProps = {
    step: number,
    text?: string,
    processingAnimation?: boolean
}

export const StorybookStaticStepIndicator: React.FC<StorybookStaticStepIndicatorProps> = props => {
    return (
        <StyledStorybookStaticStepIndicator>
            <span className={"circle"}>

                { props.processingAnimation ? (
                    <CircularProgress thickness={6} size={20} style={{ color: "white" }}/>
                ) : (
                    <ArcText text={`${props.step}`} variant={"artistic-display"}/>
                ) }


            </span>

            { props.text && (
                <ArcText text={props.text} variant={"normal-text"}/>
            ) }

        </StyledStorybookStaticStepIndicator>
    );
}
