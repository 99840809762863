import React, {CSSProperties, FC, useContext, useEffect, useRef} from "react";
import {ArdaiAPIContext} from "../../../../webapi/WebAPI";
import {useTriton} from "../../../../../triton/TritonHooks";
import {useStaticState} from "../../../../hooks/StaticStateHook";
import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../../../../webapi/WebAPIDB";
import {DescriptiveTypography} from "../../../../../triton/components/typography/DescriptiveTypography";
import {IconButton} from "../../../../components/IconButton";
import {
    ArrowDownwardRounded,
    ArrowUpwardRounded,
    Circle,
    FullscreenRounded,
    InvertColorsRounded
} from "@mui/icons-material";
import {ButtonModalCompound} from "../../../../components/ButtonModalCompound";
import {StyledModal} from "../../../../components/StyledModal";
import {Modal} from "../../../../../triton/components/dialogs/Modal";
import {Dot} from "../../../../components/Dot";
import {Menu} from "../../../../components/Menu";
import {CheckMenuButton, MenuButton} from "../../../../components/MenuButton";
import {MenuDivider} from "@szhsin/react-menu";
import {MenuGroup} from "../../../../components/MenuGroup";
import {FormDivider} from "../../../../../triton/components/forms/FormDivider";
import Collapse from "@mui/material/Collapse";
import {TransitionGroup} from "react-transition-group";

export type ImageViewV2State = {
    pointer: number,
    fullscreen: boolean,
    enableDistractionFreeMode: boolean
}

export type ImageViewV2PersistentState = {
    enableInverseFilter: boolean,
    enableReducedMotion: boolean,
    backgroundColorOverwrite?: string
}

function pointerToHtmlID(pointer: number) {
    return `scroll-snap-point-${pointer.toFixed(1)}`;
}

export const ImageViewV2: FC = props => {
    const api = useContext(ArdaiAPIContext);
    const t = useTriton();
    const selectedImageIDs = api.state.imageSelection?.imagesIDs ?? [];

    const [state, stateCtx] = useStaticState<ImageViewV2State>({
        id: "ImageViewV2-local",
        staticMode: false,
        initial: {
            pointer: 0,
            fullscreen: false,
            enableDistractionFreeMode: false
        }
    }).stateWithCtx;

    const [staticState, staticStateCtx] = useStaticState<ImageViewV2PersistentState>({
        id: "ImageViewV2-static",
        initial: {
            enableInverseFilter: false,
            enableReducedMotion: false
        }
    }).stateWithCtx;

    // Handle change in selection
    // useEffect(() => {
    //     stateCtx.update({
    //         pointer: 0
    //     });
    // }, [selectedImageIDs]);

    const loadedImages = useLiveQuery(async () => webDB.images
        .where("id")
        .anyOf(selectedImageIDs)
        .toArray(), [selectedImageIDs]
    );

    const areImagesLoaded = loadedImages !== undefined;

    const scrollContainerRef = useRef<HTMLDivElement>(null)

    //
    useEffect(() => {
        document.getElementById(pointerToHtmlID(state.pointer))?.scrollIntoView({
            behavior: staticState.enableReducedMotion ? "auto" : "smooth"
        });
    }, [state.pointer]);

    return (
        <div style={{
            height: "100%",
            overflow: "hidden",
            display: "grid",
            gridTemplateRows: "auto min-content",
            gap: 8,
            backgroundColor: (
                staticState.backgroundColorOverwrite === "$theme-background" ? t.col("bg_tray") : staticState.backgroundColorOverwrite
            ) ?? "black",
            ...(state.fullscreen ? {
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 100
            } as CSSProperties: {})
        }}>
            <div ref={scrollContainerRef} style={{
                width: "100%",
                height: "100%",
                overflowX: "hidden",
                scrollSnapType: "none",
                msScrollSnapType: "mandatory",
                position: "relative",
                overflowY: "scroll",
                display: "grid",
                gridTemplateRows: `repeat(${loadedImages?.length ?? 1}, 100%)`
            }}>
                {
                    loadedImages?.map((img, idx) => {
                        return (
                            <div id={pointerToHtmlID(idx)} style={{
                                scrollSnapAlign: "start",
                                overflow: "hidden",
                                maxHeight: "calc(100% - 0px)",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <img src={URL.createObjectURL(img.data)} alt={img.id} style={{
                                    overflow: "hidden",
                                    filter: staticState.enableInverseFilter ? "invert(1)" : "none",
                                    maxHeight: "calc(100% - 0px)",
                                    height: "100%",
                                    width: "auto",
                                    objectFit: "contain"
                                }}/>
                            </div>
                        );
                    })
                }

                {/* gesture controls */}
                <div style={{
                    position: "sticky",
                    width: "100%",
                    height: "200px",
                    bottom: 0,
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)"
                }}>
                    <div onClick={() => stateCtx.updateNumber("pointer", -1, { min: 0 })} style={{
                        backgroundColor: t.col("color_secondary"),
                        opacity: .05
                    }}/>
                    <div onClick={() => stateCtx.reverseBool("enableDistractionFreeMode")} style={{
                        // backgroundColor: "red",
                        opacity: .1
                    }}/>
                    <div onClick={() => stateCtx.updateNumber("pointer", 1, { max: (loadedImages?.length ?? 0) - 1  })} style={{
                        backgroundColor: t.col("color_secondary"),
                        opacity: .05
                    }}/>
                </div>
            </div>

            <TransitionGroup>
                { state.enableDistractionFreeMode && (
                    <Collapse>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            padding: 8,
                            paddingBottom: 16,
                        }}>
                            <div style={{
                                display: "flex",
                                gap: 4,
                                alignItems: "center",
                            }}>
                                { loadedImages?.map((_, idx) => (
                                    <IconButton size={"small"} variant={state.pointer === idx ? "primary" : "default"} children={idx} onClick={() => {
                                        stateCtx.update({
                                            pointer: idx
                                        });
                                    }}/>
                                )) }

                                <Dot style={{
                                    margin: "0 6px"
                                }}/>

                                <IconButton size={"small"} children={<ArrowUpwardRounded/>} onClick={() => {
                                    // stateCtx.update(prevState => ({
                                    //     pointer: prevState.pointer - 1
                                    // }))

                                    stateCtx.updateNumber("pointer", -1, {
                                        min: 0,
                                        max: selectedImageIDs.length - 1
                                    })
                                }}/>

                                <IconButton size={"small"} children={<ArrowDownwardRounded/>} onClick={() => {
                                    // stateCtx.update(prevState => ({
                                    //     pointer: prevState.pointer + 1
                                    // }))

                                    stateCtx.updateNumber("pointer", 1, {
                                        min: 0,
                                        max: selectedImageIDs.length - 1
                                    })
                                }}/>
                            </div>

                            <div style={{
                                display: "flex",
                                gap: 4,
                                alignItems: "center",
                            }}>
                                <IconButton size={"small"} variant={staticState.enableInverseFilter ? "primary" : "default"} children={<InvertColorsRounded/>} onClick={() => {
                                    staticStateCtx.reverseBool("enableInverseFilter");
                                }}/>

                                <Dot style={{
                                    margin: "0 6px"
                                }}/>

                                <IconButton size={"small"} children={<ArrowUpwardRounded/>} onClick={() => {
                                    scrollContainerRef.current?.scrollBy({
                                        top: -(scrollContainerRef.current.getBoundingClientRect().height * .8),
                                        behavior: staticState.enableReducedMotion ? "auto" : "smooth"
                                    })
                                }}/>

                                <IconButton size={"small"} children={<ArrowDownwardRounded/>} onClick={() => {
                                    scrollContainerRef.current?.scrollBy({
                                        top: scrollContainerRef.current.getBoundingClientRect().height * .8,
                                        behavior: staticState.enableReducedMotion ? "auto" : "smooth"
                                    })
                                }}/>

                                <IconButton size={"small"} children={<FullscreenRounded/>} onClick={() => {
                                    stateCtx.reverseBool("fullscreen");
                                }}/>

                                <Menu>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 8
                                    }}>
                                        <MenuGroup title={"Background color"} >
                                            <div>
                                                { Object.entries({
                                                    Black: "#000000",
                                                    White: "#FFFFFF",
                                                    ["Theme background"]: "$theme-background"
                                                }).map(kv => (
                                                    <MenuButton
                                                        text={kv[0]}
                                                        keepOpenOnClick
                                                        icon={<Circle style={{
                                                            color: kv[1] as string
                                                        }}/>}
                                                        onSelect={() => staticStateCtx.update({
                                                            backgroundColorOverwrite: kv[1] as string
                                                        })
                                                        }/>
                                                )) }
                                                <MenuButton text={"Reset custom background color"} keepOpenOnClick onSelect={() => staticStateCtx.update({
                                                    backgroundColorOverwrite: "$theme-background"
                                                })}/>
                                            </div>
                                        </MenuGroup>

                                        <MenuGroup title={"Preferences"}>
                                            <div>
                                                <CheckMenuButton text={"Fullscreen"} checked={state.fullscreen} onSelect={() => {
                                                    stateCtx.reverseBool("fullscreen");
                                                }}/>
                                                <CheckMenuButton text={"Invert color"} keepOpenOnClick checked={staticState.enableInverseFilter} onSelect={() => {
                                                    staticStateCtx.reverseBool("enableInverseFilter");
                                                }}/>
                                                <CheckMenuButton text={"Reduced motion"} keepOpenOnClick checked={staticState.enableReducedMotion} onSelect={() => {
                                                    staticStateCtx.reverseBool("enableReducedMotion");
                                                }}/>
                                            </div>
                                        </MenuGroup>
                                    </div>
                                </Menu>
                            </div>
                        </div>
                    </Collapse>
                ) }
            </TransitionGroup>
        </div>
    );
}
