import {APIShard} from "./APIShard";
import {webDB} from "./WebAPIDB";
import {VFSViewSettings} from "../settings/VFSViewSettings";
import {StyleLibrarySettings} from "../settings/StyleLibrarySettings";

export class SettingsManager extends APIShard {

    constructor() {
        super();
        this.initSettingsObject<VFSViewSettings>("VFSViewSettings", {
            defaultPreview: false
        });

        this.initSettingsObject<StyleLibrarySettings>("StyleLibrarySettings", {
            previewImage: true
        });
    }

    /**
     * TODO: Make more performant... used very often..
     */
    public initSettingsObject<T = any>(key: string, value: T) {
        // console.debug(`Initiating settings object with key "${key}" to:`, value);
        webDB.settings.get(key).then(setting => {
            if (setting !== undefined) return;
            webDB.settings.put({
                id: key,
                value: JSON.stringify(value)
            });
        });
    }

    public async getSettingsObject<T>(key: string): Promise<T> {
        const isa = await this.getISAEntry(key);
        const obj = JSON.parse(isa!.value);
        return obj as T;
    }

    public async updateSettingsObject<T>(key: string, updater: (prev: T) => T) {
        const obj = await this.getSettingsObject<T>(key);
        const updObj = updater(obj);
        webDB.settings.update(key, {
            value: JSON.stringify(updObj)
        });
    }

    public async getISAEntry(id: string) {
        return webDB.settings.get(id);
    }
}
