import {FC} from "react";
import {TypographyProps} from "./TypographyProps";
import {Typography} from "./Typography";
import {useTriton} from "../../../../triton/TritonHooks";

export const PrimaryDisplayTypography: FC<TypographyProps> = props => {
    const t = useTriton();
    const fontSize = props.fontSize ?? 48;
    return (
        <Typography {...props} s={{
            fontFamily: "'DM Serif Text', serif",
            fontWeight: "lighter",
            whiteSpace: "nowrap",
            userSelect: "none",
            fontSize: fontSize,
            color: t.col("fg_display_primary"),
            ...(props.s ?? {})
        }}/>
    );
}
