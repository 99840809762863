import React, {useContext, useEffect, useRef, useState} from "react";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {ArdaiAPIStateContext} from "../ArdaiMain";
import {Project} from "../data/Project";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import styled from "styled-components";
import {TransitionGroup} from "react-transition-group";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {IconButton} from "./IconButton";
import {
    BarChartRounded,
    ChevronRightRounded,
    CodeRounded,
    EnhancedEncryptionRounded,
    FingerprintRounded,
    FormatQuoteRounded,
    FunctionsRounded,
    InfoRounded,
    NetworkCellRounded,
    OpenInFull,
    PlayArrowRounded,
    SdCardRounded,
    SecurityRounded, StackedBarChartRounded
} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import {MetaBasicEntry} from "./MetaBasicEntry";
import userEvent from "@testing-library/user-event";
import {Image} from "../../base/components/base/Image";
import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../webapi/WebAPIDB";
import {getMetadata} from "meta-png";
import {ImageMetaData} from "../data/ImageMetaData";
import axios from "axios";
import {ControlModalCompound, ModalCompoundContext, ModalPolicy} from "./ControlModalCompound";
import {Menu} from "./Menu";
import {MenuButton} from "./MenuButton";
import {CircularProgress, Zoom} from "@mui/material";
import {Tag} from "./Tag";
import {ExtraNetworkTagIndicator} from "./ExtraNetworkTagIndicator";
import {ExtraNetworkMetaInfo} from "../data/ExtraNetworkMetaInfo";
import {MixinType} from "../../imageSorter/sdInterface/MixinType";
import {TabBar} from "./TabBar";
import {useFormik} from "formik";
import {MainTab} from "../../imageSorter/sdInterface/MainTab";
import {MixinTab} from "../../imageSorter/sdInterface/MixinTab";
import {HistoryTab} from "../../imageSorter/sdInterface/HistoryTab";
import {GenerationConfigTab} from "../../imageSorter/sdInterface/GenerationConfigTab";
import {LiveProcessTab} from "../../imageSorter/sdInterface/LiveProcessTab";
import {TabBodyRenderer} from "./TabBodyRenderer";
import {ClipboardCopyButton} from "./ClipboardCopyButton";
import {SDPromptField} from "../../imageSorter/sdInterface/SDPromptField";
import {auto, percent, px} from "../../base/logic/style/DimensionalMeasured";
import {PromptTextRenderer} from "./promptRenderer/PromptTextRenderer";
import {PromptCodeRenderer} from "./promptRenderer/PromptCodeRenderer";
import {PromptChipRenderer} from "./promptRenderer/PromptChipRenderer";
import {PromptRenderer} from "./promptRenderer/PromptRenderer";
import {StableDiffusionAttachment} from "../webapi/pngProperties/attachments/StableDiffusionAttachment";
import {DexieImage} from "../webapi/DexieImage";

export const StyledMetadataView = styled.section`
  width: 100%;
  // height: available;
  height: fit-content;
  
  // overflow: scroll;
  display: grid;
  grid-template-rows: auto min-content;
  
  .view-opener {
    transition: all ease-in-out .1s;
    rotate: -90deg;
    
    &[data-opened=true] {
      rotate: 90deg;
    }
  }
`;

export type MetadataViewState = {
    expanded: boolean
}

export const MetadataView: React.FC = props => {
    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);
    const [currentProject, setCurrentProject] = useState<Project | undefined>(undefined);
    useEffect(() => {
        api.getCurrentProject().then(cp => setCurrentProject(cp));
    }, [api, state]);

    const currentImage = useLiveQuery(async () => {
        return webDB.images
            .where("id")
            .equals(state.selectedImageId ?? "_placeholder")
            .first();
    }, [state.selectedImageId]);

    const [isMetaLoading, setMetaLoading] = useState(false);
    const [currentImageMeta, setCurrentImageMeta] = useState<StableDiffusionAttachment | undefined>(undefined);

    const data = useRef<{
        rawMetaString?: string
    }>({})

    useEffect(() => {
        if (currentImage === undefined) {
            setMetaLoading(true);
            data.current.rawMetaString = undefined;
            setCurrentImageMeta(undefined);
            setMetaLoading(false)
            return;
        }

        api.pngPropertiesManager.getStableDiffusionAttachment(new DexieImage(currentImage!.id)).then(attachment => {
            setCurrentImageMeta(attachment);
        });
    }, [currentImage]);

    const [viewState, setViewState] = useState<MetadataViewState>({
        expanded: false
    });

    const formik = useFormik({
        onSubmit: values => {},
        initialValues: {
            mainTab: "prompt"
        }
    })

    if (currentProject === undefined) {
        return (
            <div style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "center"
            }}>
                <DescriptiveTypography text={"Select project to view metadata"}/>
            </div>
        );
    }

    return (
        <StyledMetadataView>
            {/* META DATA CONTENT */}
            <TransitionGroup>
                { !viewState.expanded ? undefined : (
                    <Collapse key={"metadata-view-tray"}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                            <TabBar equalSizeMode={true} activeBar={String(formik.values.mainTab)} onTabChange={tab => formik.setFieldValue("mainTab", tab)} tabs={[
                                { id: "prompt", title: "Prompt", icon: <FormatQuoteRounded/> },
                                { id: "advanced", title: "Advanced", icon: <StackedBarChartRounded/> },
                                { id: "encryption", title: "", icon: <SecurityRounded/>, tooltip: "Meta encryption" },
                            ]}/>

                            <div style={{
                                display: "flex",

                                // height: "min-content",
                                height: "370px",
                                maxHeight: "370px",

                                overflow: "scroll",
                                flexDirection: "column",
                                marginBottom: "1rem"
                            }}>
                                <div style={{
                                    display: "flex",
                                    height: "min-content",
                                    gap: "8px",
                                    flexDirection: "column",
                                }}>
                                    <TabBodyRenderer
                                        active={formik.values.mainTab}
                                        tabs={new Map<string, () => React.ReactElement>([
                                            ["prompt", () => <>
                                                { currentImageMeta?.prompt && (
                                                    <PromptRenderer
                                                        title={"Prompt"}
                                                        originalPrompt={currentImageMeta.prompt}
                                                    />
                                                ) }

                                                { currentImageMeta?.negativePrompt && (
                                                    <PromptRenderer
                                                        title={"Negative prompt"}
                                                        originalPrompt={currentImageMeta.negativePrompt}
                                                    />
                                                ) }
                                            </>],
                                            ["advanced", () => <>
                                                <MainTypography text={"Extra networks"}/>
                                                { currentImageMeta?.extraNetworks.length === 0 && (
                                                    <DescriptiveTypography text={"No extra networks used"}/>
                                                ) }
                                                { currentImageMeta?.extraNetworks.map(netInfo => (
                                                    <ExtraNetworkTagIndicator netInfo={netInfo}/>
                                                )) }
                                                <MainTypography text={"Additional info"}/>
                                                <div style={{
                                                    display: "grid",
                                                    gap: "8px"
                                                }}>
                                                    {
                                                        Array.from(currentImageMeta?.kvs?.entries() ?? []).map(e => ({ k: e[0], v: e[1] })).map(kv => (
                                                            <MetaBasicEntry key={kv.k} title={kv.k} value={kv.v}/>
                                                        ))
                                                    }
                                                </div>
                                            </>],
                                            ["encryption", () => <DescriptiveTypography text={"Encryption isn't yet implemented"}/>],
                                        ])}
                                    />
                                </div>
                            </div>
                        </div>
                    </Collapse>
                ) }
            </TransitionGroup>


            {/* CONTROLS */}
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
            }}>


                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20px",
                        height: "20px"
                    }}>
                        { isMetaLoading ? (
                            <CircularProgress size={16} thickness={4} style={{
                                color: "white"
                            }}/>
                        ) : (
                            // CategoryRounded
                            // FingerprintRounded
                            // SdRounded
                            // DataObjectRounded
                            // BarChartRounded
                            <FingerprintRounded style={{
                                color: "white",
                                width: "16px",
                                height: "16px"
                            }}/>
                        )}
                    </div>
                    <MainTypography text={"Metadata"} noSelect/>
                    <TransitionGroup>
                        {
                            (!currentImageMeta && currentImage !== undefined && !isMetaLoading) && (
                                <Zoom key={currentImage?.id ?? ""}>
                                    <span>
                                        <Tag highlightOnHover={false} tag={"No meta"}/>
                                    </span>
                                </Zoom>
                            )
                        }
                    </TransitionGroup>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px"
                }}>
                    <Menu menuProps={{ direction: "top" }}>
                        <ControlModalCompound controller={ctx => (
                            <MenuButton disabled={currentImageMeta === undefined} icon={<PlayArrowRounded style={{ color: "mediumseagreen" }}/>} text={"Recreate"} onSelect={async () => {
                                const conf = {
                                    prompt: currentImageMeta?.prompt ?? "",
                                    negative_prompt: currentImageMeta?.negativePrompt ?? "",
                                    seed: currentImageMeta?.kvs.get("Seed") ?? -1,
                                    steps: currentImageMeta?.kvs.get("Steps") ?? 50,
                                    sampler_index: currentImageMeta?.kvs.get("Sampler") ?? "Euler",
                                    cfg_scale: currentImageMeta?.kvs.get("CFG scale") ?? 7,
                                    width: currentImageMeta?.kvs?.get("Size")?.split("x")[0] ?? 512,
                                    height: currentImageMeta?.kvs?.get("Size")?.split("x")[1] ?? 512,
                                    denoising_strength: currentImageMeta?.kvs.get("Denoising strength") ?? 0,
                                    enable_hr: currentImageMeta?.kvs.get("Hires upscaler") !== undefined,
                                    hr_scale: currentImageMeta?.kvs.get("Hires upscale") ?? 1.5,
                                    hr_upscaler: currentImageMeta?.kvs.get("Hires upscaler") ?? "R-ESRGAN 4x+ Anime6B",
                                    hr_second_pass_steps: currentImageMeta?.kvs.get("Hires steps") ?? 0,
                                };

                                axios.post("http://127.0.0.1:7860/sdapi/v1/txt2img", conf).then(res => {
                                    ctx.open("img-res", res.data.images);
                                });
                            }}/>
                        )} modals={new Map<string, (ctx: ModalCompoundContext, param: any) => (React.ReactNode | [React.ReactNode, ModalPolicy])>([
                            ["img-res", (ctx, param) => (
                                <img
                                    alt={"stable diffusion result"}
                                    src={`data:image/png;base64,${param}`}
                                />
                            )]
                        ])}/>
                        <MenuButton disabled={currentImageMeta === undefined} text={"Get raw metadata"} onSelect={() => {
                            currentImage?.data.arrayBuffer().then(r => {
                                const meta = getMetadata(new Uint8Array(r), "parameters")!;
                                alert(meta);
                            });
                        }}/>
                    </Menu>

                    <IconButton onClick={() => {
                        setViewState(prevState => ({
                            expanded: !prevState.expanded
                        }));
                    }} size={"small"} children={
                        <ChevronRightRounded
                            data-opened={viewState.expanded}
                            className={"view-opener"}
                        />
                    }/>
                </div>
            </div>
        </StyledMetadataView>
    );
}
