import {AdvancedStringInputProcessorRequest} from "./AdvancedStringInputProcessorRequest";
import {AdvancedStringInputProcessorResult} from "./AdvancedStringInputProcessorResult";
import {SRCReader} from "../../unifiedGraphicsLanguage/SRCReader";
import ReactMarkdown from "react-markdown";
import _ from "lodash";

export type InterruptToken = {
    engine?: string,
    parameter?: string
}

export class Node<T> {

    constructor(
        public self: T,
        public readonly children: Node<T>[] = [],
    ) {}

    public newChildNode(childSelf: T): Node<T> {
        const child = new Node<T>(childSelf);
        this.children.push(child);
        return child;
    }

    public isLeaf(): boolean {
        return this.children.length === 0;
    }
}

export class AdvancedStringInputProcessor {

    public process(request: AdvancedStringInputProcessorRequest): AdvancedStringInputProcessorResult {
        const tree = new Node<string>("");
        this.traverse(tree, new SRCReader(request.src));
        const variants = this.collapseNodeTree(tree);
        return {
            values: variants
        };
    }

    private collapseNodeTree(root: Node<string>, prefix: string = ""): Array<string> {
        const variants: Array<string> = [];
        const path = prefix + root.self;
        if (root.isLeaf()) {
            variants.push(path);
        } else {
            for (const child of root.children) {
                variants.push(...this.collapseNodeTree(child, path))
            }
        }
        return variants;
    }

    private traverse(parent: Node<string>, buffer: SRCReader) {
        while (true) {
            const char = buffer.read();
            if (char === SRCReader.EOF) break;
            switch (char) {
                case "$": {
                    const token = this.parseInterrupt(buffer);
                    this.collapseInterrupt(parent, token, buffer);
                    return;
                }
                default: {
                    parent.self += char;
                }
            }
        }
    }

    /**
     * Syntax:
     * <engine?>{<parameter?>}
     *
     * @private
     */
    private parseInterrupt(buffer: SRCReader): InterruptToken {
        const engine = buffer.pLookAhead(c => c !== '{')
        const parameter = buffer.readUntilNextClosingToken(['{', '}']);
        return {
            engine: engine,
            parameter: parameter
        }
    }

    private collapseInterrupt(parent: Node<string>, token: InterruptToken, buffer: SRCReader) {
        const interruptYield: string[] = [];
        const paramRaw = token.parameter;

        // TODO: Handle special calls: @, &, %

        const params = paramRaw?.split(',') ?? [];
        if (params.length === 0) {
            this.traverse(parent.newChildNode(''), new SRCReader(buffer.remainder()))
            return;
        }

        if (/\d/.test(params[0])) {
            // Numeric-range mode
            const [min, max] = params.map(s => s.trim()).map(s => Number(s)) ?? [0, 0];
            for (let i = min; i <= max; i++) interruptYield.push(String(i));
        } else if (/\w/.test(params[0])) {
            // String-select mode
            interruptYield.push(...params.map(s => s.trim()));
        }

        interruptYield.forEach(branchPrefix => {
            const child = parent.newChildNode(branchPrefix);
            this.traverse(child, new SRCReader(buffer.remainder()))
        });
    }
}
