import styled from "styled-components";

export const StyledControlDisplayGroup = styled.div`
  width: 100%;
  background-color: rgb(1, 4, 9);
  position: relative;
  box-shadow: rgb(33,38,45) 0 0 0 1px;
  border-radius: 8px;
  padding: 8px;
  display: grid;
  gap: 8px;
`;
