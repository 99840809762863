import {StorybookPage} from "../../../../storybook/StorybookPage";
import {Flex} from "../../../../Flex";
import {TypoHeaderVisual} from "../../../../components/TypoHeaderVisual";
import {Grid} from "../../../../../../../triton/components/layouts/Grid";
import {RoundedMainTextButton} from "../../../../components/RoundedMainTextButton";
import {tosPage} from "./TermsAndPrivacyPage";
import React from "react";

export const welcomePage: StorybookPage<any> = {
    name: "welcome",
    initialValues: {},
    header: (api, fp) => {
        // Get page data entries
        // const emailUserNameData = api.getPageData("emailAndUsername");

        return (
            <Flex g={30} baseProps={{
                style: {
                    flexDirection: "column",
                    alignItems: "center"
                }
            }}>
                <TypoHeaderVisual
                    header={`Hey, INSERT USERNAME HERE!`}
                    descriptiveSubheader={"Welcome on board"}
                    text={"Your account was created successfully"}
                />
            </Flex>
        );
    },
    page: (api, fp) => (
        <></>
    ),
    bottomActionPanel: (api, fp, isSubmitting) => (
        <Grid style={{ width: "100%" }} gTC={"repeat(2, 1fr)"}>
            <RoundedMainTextButton
                onClick={() => api.submitAndForward(tosPage)}
                text={"Set up profile"}
                type={"submit"}
                style={{
                    backgroundColor: "#FFEAC4"
                }}
            />
            <RoundedMainTextButton
                onClick={() => api.getNavigateFunction()("/")}
                type={"submit"}
                text={"Skip"}
            />
        </Grid>
    )
}
