import React, {PropsWithChildren} from "react";
import {InputGroup} from "./InputGroup";
import {MainTypography} from "../typography/MainTypography";
import {DescriptiveTypography} from "../typography/DescriptiveTypography";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";

export function FormElement(props: PropsWithChildren<{
    title?: string,
    caption?: string,
    bold?: boolean
}>): JSX.Element {
    return (
        <InputGroup>
            {props.title && (
                props.bold ?? false ? (
                    <MainTypography text={props.title} noSelect/>
                ) : (
                    <DescriptiveTypography text={props.title} noSelect/>
                )
            )}

            {props.children}

            <TransitionGroup children={
                props.caption && (
                    <Collapse children={
                        <DescriptiveTypography text={props.caption}/>
                    }/>
                )
            }/>

            {false && props.caption && (
                <DescriptiveTypography text={props.caption}/>
            )}
        </InputGroup>
    );
}
