import React, {useContext} from "react";
import styled from "styled-components";
import {FlatIconButton} from "../../../../ardai/components/FlatIconButton";
import {
    AppsRounded,
    ArrowLeftRounded,
    ComputerRounded, DataObjectRounded, FormatShapesRounded, FullscreenExitRounded,
    FullscreenRounded,
    Functions,
    MonitorRounded, TableRowsRounded
} from "@mui/icons-material";
import {ListItem} from "../../../../ardai/_/ListItem";
import {Menu} from "../../../../ardai/components/Menu";
import {MenuButton} from "../../../../ardai/components/MenuButton";
import {SimulationWindowAPIContext} from "../VesselSimulationMain";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {BooleanContext} from "../../../../triton/components/BooleanContext";
import {DrawerHeader} from "../../../../triton/components/advanced/drawer/DrawerHeader";
import {EntityListView} from "./EntityListView";
import {Drawer} from "../../../../triton/components/advanced/drawer/Drawer";
import {TabSelectionView} from "./TabSelectionView";

const StyledVesselSimulationToolbar = styled.div`
  display: grid;
  grid-template-columns: min-content auto min-content;
  width: 100%;
  padding: 6px;
  
  .toolbar-compartment {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    justify-content: center;
  }
`;

export const VesselSimulationToolbar: React.FC = props => {
    const api = useContext(SimulationWindowAPIContext);

    return (
        <StyledVesselSimulationToolbar>
            <div className={"toolbar-compartment"}>

                <BooleanContext children={(bool, setBool) => (<>
                    <ListItem title={"Entities"} onClick={() => setBool(true)} icon={
                        <DataObjectRounded sx={{ fontSize: 20 }}/>
                    }/>

                    <Drawer open={bool} onClose={() => setBool(false)} children={ctx => (
                        <div>
                            <DrawerHeader title={"Entities"} description={"List of all available entities in the ECS"}/>
                            <EntityListView/>
                        </div>
                    )}/>
                </>)}/>


                <BooleanContext children={(bool, setBool) => (<>
                    <ListItem title={"Tabs"} onClick={() => setBool(true)} icon={
                        <FormatShapesRounded sx={{ fontSize: 20 }}/>
                    }/>

                    <Drawer open={bool} onClose={() => setBool(false)} children={ctx => (
                        <div>
                            <DrawerHeader title={"Tabs"} description={"List of all available tabs & views"}/>
                            <TabSelectionView/>
                        </div>
                    )}/>
                </>)}/>


                <Menu opener={
                    <ListItem title={"View"} icon={
                        <MonitorRounded sx={{ fontSize: 20 }}/>
                    }/>
                } children={
                    <>
                        <MenuButton
                            text={"Toggle zen mode"}
                            icon={api.state.zenMode ? <FullscreenExitRounded/> : <FullscreenRounded/>}
                            appendix={<DescriptiveTypography text={`${api.state.zenMode ? "Active" : "Inactive"}`}/>}
                            keepOpenOnClick
                            onSelect={() => api.toggleZenMode()}
                        />
                    </>
                }/>
            </div>
            <span/>
            <div className={"toolbar-compartment"}>
                <ListItem title={"Exit"} icon={
                    <ArrowLeftRounded sx={{ fontSize: 20 }}/>
                }/>
            </div>
        </StyledVesselSimulationToolbar>
    );
}
