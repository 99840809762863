import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {triton} from "../../../../triton/Triton";
import {warning} from "framer-motion";

export const NodeSystemStdOut: NodeSetupInfo = {
    label: "NodeSystemStdOut",
    classname: "node.std-out",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "node.std-out",
        label: "std out",
        defInPins: ["log"],
        defOutPins: ["onLog", "msg"],
        visualConfig: {
            dye: triton().col("color_secondary")
        },
        init: node => {
            const logFn = console.log;
            console.log = (message, optionalParams) => {
                optionalParams = optionalParams || [];
                // console.warn(typeof optionalParams)
                // node.pins.out("onLog").write([message, ...optionalParams]);
                node.pins.out("msg").write(message);
                // logFn(message, ...optionalParams);
                logFn(message);
            }

            node.pins.in("log").attachOnRead(data => {
                if (Array.isArray(data)) {
                    const msg = data[0];
                    const optionalParams = data.slice(1);
                    // console.log(msg, ...optionalParams);
                    console.log(msg);
                } else {
                    console.log(data);
                }

            })
        }
    })
}
