import React, {FC, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {NodeCanvas} from "../frontend/NodeCanvas";
import {TabHeader} from "../../triton/components/navigation/tabs/TabHeader";
import {Triton, triton} from "../../triton/Triton";
import {TritonDefaults} from "../../triton/TritonDefaults";
import {TritonReactBridge} from "../../triton/TritonReactBridge";
import {NodeEnvironment} from "../host/NodeEnvironment";
import {PinBankRendererFunc} from "../frontend/PinBankRendererFunc";
import {Resource} from "../backend/ressources/Resource";
import {ManualPortConfigurationPinBankDisplay} from "../std/components/pinBanks/ManualPortConfigurationPinBankDisplay";
import {useTriton} from "../../triton/TritonHooks";
import {ReactMaster} from "../../ReactAPIBridgeUtils";
import {nodeAppBackendBridge, useNodeAppBackend} from "../frontend/app/NodeAppBackend";
import {Tag} from "../../ardai/components/Tag";
import {useForceRenderFunc} from "../../ForceRerenderHook";
import {NodeAddBackendEventTypes} from "../frontend/app/NodeAddBackendEventTypes";
import {AnimatePresence, Reorder} from "framer-motion";
import {Dot} from "../../ardai/components/Dot";
import {
    AddRounded,
    HelpRounded,
    PlusOneRounded,
    RunCircleRounded,
    SearchRounded,
    SettingsRounded
} from "@mui/icons-material";
import {motion} from "framer-motion";
import {Theme} from "../../triton/theming/Theme";
import {ImageHubBranding} from "../../ardai/components/ImageHubBranding";
import {NotificationPrototypeStandalone} from "../../triton/notifications/NotificationPrototypeStandalone";

// TODO: Remove
// TypeTest();

const StyledStandaloneNodeSandbox = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 16px;
  display: grid;

  // TODO: Reactivate feature when ready (*1)
  // grid-template-rows: repeat(2, min-content) auto;
  grid-template-rows: repeat(1, min-content) auto;

  background-color: ${() => triton().col("bg_main")};

  .test-filter {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    touch-action: none;
    user-select: none;
    pointer-events: none;
    
    &:before {
      z-index: 4;
      backdrop-filter: hue-rotate(90deg);
      background-color: rgba(0, 0, 0, .5);
      // background-color: rgba(255, 255, 255, 0.5);
      opacity: .5;
      content: "";
      display: block;
      pointer-events: none;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    
    .overlay:after {
      background-image: radial-gradient(#444, #111);
      opacity: .1;
      content: "";
      display: block;
      pointer-events: none;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
    }

    .overlay:before {
      opacity: .5;
      background: linear-gradient(#032817 50%, rgba(16, 16, 16, 0.2) 50%),
      linear-gradient(90deg, rgba(255, 0, 0, 0.03), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.03));
      background-size: 100% 3px, 6px 100%;
      content: "";
      display: block;
      pointer-events: none;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }
`;

export function createDevelopmentNodeEnvironment(): NodeEnvironment {
    const env = new NodeEnvironment;

    const resources = env.resources;

    const pinBankRendererResContainer = resources.getContainer<PinBankRendererFunc>("pin-bank-renderers");

    pinBankRendererResContainer.registerResource("manual-port-configuration", new Resource<PinBankRendererFunc>(() => (bank, props) => (
        <ManualPortConfigurationPinBankDisplay bank={bank} align={props.align}/>
    )));

    return env
}

function initTriton(): Triton {
    const t = useTriton();
    t.registerThemes(
        TritonDefaults.theme,
        // TritonDefaults.lightTheme,
        TritonDefaults.crimsonFutureTheme,
        TritonDefaults.blueFutureTheme,
        TritonDefaults.greenFutureTheme,
        TritonDefaults.purpleFutureTheme,
        {
            ...TritonDefaults.lightTheme,
            id: "light-theme",
            title: "Light theme",
            isDarkTheme: false,
            colors: {
                ...TritonDefaults.lightTheme.colors,
                color_gb_main: "#ffffff",
                color_gb_tray: "#f5f6f8",
                color_primary: "#ff7a00",
                color_secondary: "#ffbd29",
                color_default: "#1e202a",
                fg_default: "#181818",
                fg_muted: "#444444",
                gray_250: "#e8ebee",
                gray_300: "#e8ebee",
                color_gb_modal: "#f0f1f3"
            }
        }
    );
    return t.start({
        makeGlobal: true
    });
}

export const StandaloneNodeSandbox: FC = props => {
    // noinspection SpellCheckingInspection

    const nodeEnvRef = useRef(createDevelopmentNodeEnvironment());

    return (
        <TritonReactBridge factory={initTriton} children={() => (
            <ReactMaster fx={nodeAppBackendBridge}>
                <StyledStandaloneNodeSandbox>

                    {/*
                    <NotificationPrototypeStandalone/>
                    */}

                    {/* TODO: Delete CSS styling for this..
                    <div className={"test-filter"}>
                        <div className={"overlay"}/>
                        <div className={"scanline"}/>
                    </div>
                    */}


                    {/* TODO: Reactivate feature when ready (*1) */}
                    {/* <NodeAppMainNav/> */}
                    <NodeAppMainTabBar/>
                    <NodeAppMainTabBody mainNodeEnv={nodeEnvRef.current}/>
                </StyledStandaloneNodeSandbox>
            </ReactMaster>
        )}/>

    );
}

const StyledNodeAppMainNav = styled.div`
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  
  .app-menu-container {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }
`;

export const NodeAppMainNav: FC<{}> = props => {
    return (
        <StyledNodeAppMainNav>
            <ImageHubBranding/>

            <div className={"app-menu-container"}>
                <Tag applyActiveScaling tag={"Project"}/>
                <Tag applyActiveScaling tag={"File"}/>
                <Tag applyActiveScaling tag={"Edit"}/>
                <Tag applyActiveScaling tag={"View"}/>
                <Tag applyActiveScaling tag={"Run"}/>
                <Tag applyActiveScaling tag={"Help"}/>
                <Tag applyActiveScaling tag={
                    <HelpRounded sx={{
                        fontSize: 14
                    }}/>
                }/>
                <Tag applyActiveScaling tag={
                    <SearchRounded sx={{
                        fontSize: 14
                    }}/>
                }/>
                <Tag applyActiveScaling tag={
                    <SettingsRounded sx={{
                        fontSize: 14
                    }}/>
                }/>
            </div>


        </StyledNodeAppMainNav>
    );
}

export const NodeAppMainTabBody: FC<{
    mainNodeEnv: NodeEnvironment
}> = props => {
    const app = useNodeAppBackend();
    const forceRerender = useForceRenderFunc();

    useEffect(() => app.events
            .observe(NodeAddBackendEventTypes.TAB_SUBSYSTEM_OVERARCHING)
            .on(forceRerender)
            .destructor,
        []
    );

    const tab = app.tabs.manager.activeTab;

    if (tab === undefined) return (
        <NodeCanvas
            // TODO: Add id
            env={props.mainNodeEnv}
            nodes={[]}
        />
    );

    return (
        <>
            {
                tab.info.tabBodyRenderer?.(tab) ?? (
                    // TODO: Make bette error page -> Originally was "ERROR"
                    <NodeCanvas
                        // TODO: Add id
                        env={props.mainNodeEnv}
                        nodes={[]}
                    />
                )
            }
        </>
    );
}

export const NodeAppMainTabBar: FC = props => {
    const app = useNodeAppBackend();
    const forceRerender = useForceRenderFunc();
    const t = useTriton();

    useEffect(() => app.events
        .observe(NodeAddBackendEventTypes.TAB_SUBSYSTEM_OVERARCHING)
        .on(forceRerender)
        .destructor,
        []
    );

    const [orderedTabIds, setOrderedTabIds] = useState<string[]>([]);
    const tabs = app.tabs.manager.tabs;
    const tabIdArr = tabs.map(tab => tab.tabId);

    // FIXME: useEffect is called every component render cycle
    useEffect(() => {
        const notRegisteredTabs: string[] = [];
        tabIdArr.forEach(tabId => {
            if (orderedTabIds.includes(tabId)) return;
            notRegisteredTabs.push(tabId);
        });
        const ghostTabs: string[] = [];
        orderedTabIds.forEach(tabId => {
            if (tabIdArr.includes(tabId)) return;
            ghostTabs.push(tabId);
        });
        if (notRegisteredTabs.length === 0) return;
        setOrderedTabIds(prevState => {
            let newTabs = [...prevState, ...notRegisteredTabs];
            newTabs = newTabs.filter(tabId => !ghostTabs.includes(tabId));
            return newTabs;
        });
    }, [tabIdArr]);

    return (
        <div>
            <div style={{
                display: "flex",
                gap: 10,
                height: 33
            }}>
                <Reorder.Group
                    className="tabs"
                    as={"ul"}
                    axis={"x"}
                    onReorder={setOrderedTabIds}
                    values={orderedTabIds}
                    style={{
                        display: "flex",
                        alignItems: "end",
                        gap: 5,
                        flexWrap: "nowrap",
                        padding: 0,
                        margin: 0
                    }}
                >
                    <AnimatePresence initial={false}>
                        { orderedTabIds
                            .map(tabId => tabs.find(tab => tab.tabId === tabId))
                            .filter(optionalTab => optionalTab !== undefined)
                            .map(optionalTab => optionalTab!) // just for compiler -> will never encounter an undefined element here
                            .map(tab => (
                            <Reorder.Item
                                key={tab.tabId}
                                value={tab.tabId}
                                style={{
                                    listStyle: "none",
                                    padding: 0,
                                    margin: 0
                                }}
                                initial={{
                                    opacity: 0,
                                    y: 30
                                }}
                                animate={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 0.15
                                    }
                                }}
                                exit={{
                                    opacity: 0,
                                    y: 20,
                                    transition: {
                                        duration: 0.3
                                    }
                                }}
                                whileDrag={{
                                    backgroundColor: "#e3e3e3"
                                }}
                                children={
                                    <TabHeader
                                        title={tab.info.label}
                                        isClosable={tab.info.isClosable}
                                        isActive={tab.isActive}
                                        onRequestClose={async () => app.tabs.manager.destroy(tab.tabId)}
                                        onRequestSetActive={async () => app.tabs.manager.activate(tab.tabId)}
                                    />
                                }
                            />
                        )) }
                    </AnimatePresence>
                </Reorder.Group>

                <motion.button
                    className="add-item"
                    onClick={() => {
                        const tab = app.tabs.manager.create({
                            label: "untitled",
                            isClosable: true,
                            isActive: false
                        });

                        forceRerender();
                    }}
                    // disabled={tabs.length === allIngredients.length}
                    whileTap={{ scale: 0.9 }}
                    style={{
                        color: t.col("fg_secondary"),
                        backgroundColor: t.col("bg_tray"),
                        alignSelf: "center",
                        borderRadius: "50%",
                        border: "none",
                        aspectRatio: "1 / 1",
                        // height: 33,
                        height: 28,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer"
                    }}
                >
                    <AddRounded sx={{
                        fontSize: 18
                    }}/>
                </motion.button>

            </div>
            <div style={{
                marginTop: 8,
                width: "100%",
                height: 10,
                backgroundColor: t.col("bg_tray")
            }}/>
        </div>
    );
}
