import React, {FC, useEffect} from "react";
import {LandingPage} from "./components/pages/LandingPage";
import {CLIMain} from "../../ardai/components/cli/CLIMain";
import {
    CommandContext,
    CommandNode,
    CommandNodeOption,
    KeyCommandTest
} from "../../ardai/_/keyCommand/test/KeyCommandTest";
import {hex} from "../../base/logic/style/Color";
import {PortfolioV1Setup} from "./PortfolioV1Setup";
import {TritonReactBridge} from "../../triton/TritonReactBridge";
import {triton} from "../../triton/Triton";
import {ReactMaster} from "../../ReactAPIBridgeUtils";
import {portfolioBackendBridgeContext} from "./PortfolioBackendBridgeContext";
import {useTriton} from "../../triton/TritonHooks";

export const PortfolioV1Main: FC = props => {

    const tritonFactory = () => triton().start({
        makeGlobal: true
    });

    return (
        <TritonReactBridge factory={tritonFactory} children={() => (
            <ReactMaster fx={portfolioBackendBridgeContext} children={
                <PortfolioV1/>
            }/>
        )}/>
    );
}

const PortfolioV1: FC = props => {
    const t = useTriton();

    useEffect(() => {
        PortfolioV1Setup.setup();
    }, []);

    return (
        <div style={{
            touchAction: "none",
            width: "100vw",
            position: "absolute",
            zIndex: 100,
            height: "100vh"
        }}>
            <KeyCommandTest displayPreferences={{
                palette: {
                    main: hex("#ffffff")
                }
            }} cmdLib={
                CommandNode.newRoot()
                    // T :: Theme switcher
                    .addSubcommand(new CommandNode<void>({
                        key: "KeyT",
                        title: "Switch theme",
                        execute(ctx: CommandContext) {
                            t.switchTheme(ctx.instruction
                                .keyPath[ctx.instruction.keyPath.length - 1]
                                .option?.value ?? t.theme.id
                            );
                        },
                        getOptions(): Array<CommandNodeOption> {
                            return t.registeredThemes.map(t => ({
                                title: t.title,
                                value: t.id
                            }))
                        },
                        onOptionChange(option: CommandNodeOption) {
                            typeof t.switchTheme(option.value ?? t.theme.id);
                        },
                        onLeaveNodeUnExecuted() {
                            t.resetTheme();
                        }
                    }))
                    // Q :: Quick actions
                    .addSubcommand(new CommandNode<void>({
                        key: "KeyQ",
                        title: "Goto",
                        description: "Fast quick action chooser",
                        execute(ctx: CommandContext) {
                            return new Promise(resolve => {
                                ctx.instruction.mcc?.openDirect(mccCtx => (
                                    <div children={
                                        <input
                                            autoFocus
                                            onKeyDown={e => {
                                                if (e.key === "Escape") {
                                                    mccCtx.close();
                                                    resolve();
                                                    return;
                                                }
                                                if (e.key !== "Enter") return;
                                                e.stopPropagation();
                                                const location = e.currentTarget.value;
                                                if (location === undefined) return;
                                                window.location.pathname = location;
                                            }}
                                            style={{
                                                border: "none",
                                                outline: "none",
                                                padding: "16px",
                                                fontFamily: "monospace"
                                            }}
                                        />
                                    }/>
                                ), "searchbar");
                            });
                            // const location = ctx.instruction.keyPath[ctx.instruction.keyPath.length - 1].option?.value
                            // if (location === undefined) return;
                            // window.location.pathname = location;
                        },
                        getOptions(): Array<CommandNodeOption> {
                            return ["Apps"].map(a => ({
                                title: a, value: "apps/"
                            }))
                        }
                    }))
            }/>

            <LandingPage/>
        </div>
    );
}
