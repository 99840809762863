import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {Tag} from "../../../../ardai/components/Tag";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import _ from "lodash";
import {ButtonGroup} from "../../../../ardai/components/ButtonGroup";

export const IEEE754FP32: NodeSetupInfo = {
    label: "IEEE754FP32",
    classname: "input.IEEE754FP32-debug",
    parameterConfig: [],
    factory: parameters => new Node<{
        bits: number
    }>({
        id: v4(),
        classname: "input.IEEE754FP32-debug",
        label: "IEEE-754 32 bit",
        defOutPins: ["fp32"],
        state: {
            bits: 0b0
        },
        reset: function () {
            this.pins.out("fp32").write(0);
        },
        init: function () {
            const node = this;
        },
        customRenderer: (node) => (
            <div style={{
                display: "flex",
                gap: 8,
                flexDirection: "column",
                padding: "8px 0"
            }}>
                <div style={{
                    display: "flex",
                    gap: 4,
                }}>
                    <div>
                        <DescriptiveTypography
                            text={"±"}
                            noSelect
                            style={{ fontSize: 10, marginBottom: 4 }}
                        />
                        <Tag tag={"0"}/>
                    </div>

                    <div>
                        <DescriptiveTypography
                            text={"exp"}
                            noSelect
                            style={{ fontSize: 10, marginBottom: 4 }}
                        />
                        <ButtonGroup>
                            { _.range(0, 7).map(idx => (
                                <Tag tag={"0"}/>
                            )) }
                        </ButtonGroup>
                    </div>

                    <div>
                        <DescriptiveTypography
                            text={"mantissa"}
                            noSelect
                            style={{ fontSize: 10, marginBottom: 4 }}
                        />
                        <ButtonGroup>
                            { _.range(0, 11).map(idx => (
                                <Tag tag={"0"}/>
                            )) }
                        </ButtonGroup>
                    </div>
                </div>

                <DescriptiveTypography
                    text={`= 0x${3}`}
                />
            </div>
        )
    })
}
