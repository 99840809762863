import {CSSProperties, FC} from "react";
import styled from "styled-components";
import {Triton} from "../../triton/Triton";
import {useTriton} from "../../triton/TritonHooks";

const StyledDot = styled.div<{
    t: Triton
}>`
  width: 3px;
  height: 3px;
  border-radius: 10px;
  background-color: ${p => p.t.col("fg_muted")};
`;

export type DotProps = {
    style?: CSSProperties
}

export const Dot: FC<DotProps> = props => {
    const t = useTriton();
    return (
        <StyledDot
            style={props.style}
            t={t}
        />
    );
}
