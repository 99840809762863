import React, {useContext} from "react";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {ArdaiAPIStateContext} from "../ArdaiMain";
import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../webapi/WebAPIDB";
import {ListItem} from "./ListItem";
import {DeleteRounded, EditRounded, FolderRounded, MoreHorizRounded, TagRounded} from "@mui/icons-material";
import {VFSElement} from "../data/VFSElement";
import {Menu} from "@szhsin/react-menu";
import {ButtonModalCompound} from "../components/ButtonModalCompound";
import {MenuButton} from "../components/MenuButton";
import {Formik} from "formik";
import {StyledModal} from "../components/StyledModal";
import {FormElement} from "../../triton/components/forms/FormElement";
import {FormikSingleLineInput} from "../../triton/components/forms/FormikSingleLineInput";
import {Grid} from "../../triton/components/layouts/Grid";
import {ButtonBase, ButtonVariant} from "../../triton/components/buttons/ButtonBase";
import styled from "styled-components";
import {TransitionGroup} from "react-transition-group";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import Collapse from "@mui/material/Collapse";

const StyledMenu = styled.div`
  .szh-menu__item {
    padding: 0 !important;
    
    &:hover {
      background-color: initial;
    }
  }
  
  .szh-menu__divider {
    margin-top: 2px;
    background-color: #30363d;
    margin-bottom: 2px;
  }
`;

export type FolderListViewProps = {
    path: string,
    expanded: boolean
}

const StyledFolderListView = styled.div`
  .folder-view-main {
        
    .folder-view-toolbar {
      padding-left: 8px;
    }
  }
`;

export const FolderListView: React.FC<FolderListViewProps> = props => {
    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);

    let folders = useLiveQuery(async () => {
        return webDB.vfsElements
            .where("path")
            .equals(props.path)
            .and(e => e.type === "folder")
            .toArray();
    }, [props.path]);

    if (folders === undefined) {
        // TODO: Better loading animation? Or just blank JSX?
        return (
            <div children={
                <ListItem title={"Loading"} icon={
                    // TODO: Add better loading icon -> Make standalone component
                    // <CircularProgress variant={"indeterminate"}/>
                    undefined
                }/>
            }/>
        );
    }

    function generateFolderListItemAppendix(folder: VFSElement): JSX.Element {
        return (
            <>
                <Menu
                    children={
                        <StyledMenu>

                            <ButtonModalCompound button={<MenuButton text={"Rename"} icon={<EditRounded/>}/>} modalContent={ctx => (
                                <Formik initialValues={{ title: folder.title }} onSubmit={values => {
                                    webDB.vfsElements.update(folder.id, values).then(() => ctx.close());
                                }} children={fp => (
                                    <StyledModal title={"Rename folder"} icon={<EditRounded/>} onClose={() => ctx.close()} children={
                                        <FormElement title={"New folder name"} children={
                                            <FormikSingleLineInput autoFocus name={"title"} formikProps={fp}/>
                                        }/>
                                    } footer={
                                        <Grid style={{ width: "100%" }} gTC={"repeat(2, 1fr)"}>
                                            <ButtonBase text={"Save"} baseProps={{
                                                onClick: e => fp.handleSubmit(e)
                                            }}/>
                                            <ButtonBase text={"Cancel"} baseProps={{
                                                onClick: () => ctx.close()
                                            }}/>
                                        </Grid>
                                    }/>
                                )}/>
                            )}/>

                            <ButtonModalCompound button={<MenuButton text={"Delete"} icon={<DeleteRounded/>}/>} modalContent={ctx => (
                                <StyledModal title={"Do you really want to delete the folder"} footer={
                                    <Grid style={{ width: "100%" }} gTC={"repeat(2, 1fr)"}>
                                        <ButtonBase text={"Yes, delete"} variant={ButtonVariant.DANGER} baseProps={{
                                            onClick: () => webDB.vfsElements.delete(folder.id).then(() => ctx.close())
                                        }}/>
                                        <ButtonBase text={"No, cancel"} baseProps={{
                                            onClick: () => ctx.close()
                                        }}/>
                                    </Grid>
                                }/>
                            )}/>
                        </StyledMenu>
                    }
                    menuButton={
                        <MoreHorizRounded sx={{ fontSize: 20 }}/>
                    }
                    theming={"dark"}
                    transition={true}
                    menuStyle={{
                        backgroundColor: "rgb(22, 27, 34)",
                        borderRadius: "12px",
                        boxShadow: "rgba(1, 4, 9, 0.85) 0px 16px 32px",
                        padding: "8px",
                        border: "1px solid rgb(48, 54, 61)"
                    }}
                    arrowStyle={{
                        borderLeftColor: "rgb(48, 54, 61)",
                        borderTopColor: "rgb(48, 54, 61)",
                        backgroundColor: "rgb(22, 27, 34)"
                    }}
                />

                {/* <SettingsRounded sx={{ fontSize: 16 }}/> */}
            </>
        );
    }

    return (
        <StyledFolderListView style={{
            display: "grid",
            gap: "2px",
            height: "min-content"
        }}>
            <TransitionGroup>
                { props.expanded && (
                    <Collapse>
                        <div className={"folder-view-main"}>
                            <div className={"folder-view-toolbar"}>
                                <DescriptiveTypography text={"Toolbar is here lol"}/>
                            </div>

                            <TransitionGroup>
                                { folders?.length === 0 && (
                                    <Collapse>
                                        <div style={{
                                            width: "100%",
                                            padding: "1rem",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 8
                                        }}>
                                            <DescriptiveTypography text={"Empty"}/>
                                        </div>
                                    </Collapse>
                                ) }

                                {
                                    folders?.map(f => (
                                        <Collapse key={f.id}>
                                            <ListItem
                                                title={f.title}
                                                icon={<FolderRounded sx={{ fontSize: 20 }}/>}
                                                onClick={() => api.appendToFilePath(f.id)}
                                                activeAppendix={generateFolderListItemAppendix(f)}
                                                hoverAppendix={generateFolderListItemAppendix(f)}
                                            />
                                        </Collapse>
                                    ))
                                }
                            </TransitionGroup>
                        </div>
                    </Collapse>
                ) }





            </TransitionGroup>
        </StyledFolderListView>
    );
}
