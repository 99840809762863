import React, {useContext} from "react";
import {VFSElement} from "../data/VFSElement";
import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../webapi/WebAPIDB";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {ArdaiAPIStateContext} from "../ArdaiMain";
import {VFSElementCard} from "../components/VFSElementCard";
import {MobileProjectWorkspaceLayout} from "../components/MobileProjectWorkspaceLayout";
import {ListItem} from "./ListItem";
import {
    AddRounded,
    CheckBoxRounded, CopyAllRounded,
    DeleteRounded, EditRounded, FolderRounded, InvertColorsRounded,
    MoreHorizRounded,
    MoreVertRounded,
    SelectAllRounded,
    SettingsRounded,
    TagRounded
} from "@mui/icons-material";
import {MenuButton} from "../components/MenuButton";
import styled from "styled-components";
import {ProjectListItem} from "./ProjectListItem";
import Collapse from "@mui/material/Collapse";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {TransitionGroup} from "react-transition-group";
import {useStaticState} from "../hooks/StaticStateHook";
import {DefaultButton} from "../components/DefaultButton";
import {Tag} from "../components/Tag";
import {useTriton} from "../../triton/TritonHooks";
import {Dot} from "../components/Dot";
import {CategoryHeader} from "./CategoryHeader";
import {Menu} from "../components/Menu";
import {MenuGroup} from "../components/MenuGroup";
import {HelpHint} from "../components/HelpHint";

const StyledProjectListView = styled.div`
  display: grid;
  gap: 2px;
  height: min-content;

  .project-view-main {
    .project-view-toolbar {
      padding-left: 8px;
    }
  }
`;

export type ProjectListViewProps = {
    path: string
}

export type ProjectListViewState = {
    selectedProjects: Array<string>,
    isInSelectionMode: boolean,
    expanded: boolean
}

export const ProjectListView: React.FC<ProjectListViewProps> = props => {
    const api = useContext(ArdaiAPIContext);
    const apiState = useContext(ArdaiAPIStateContext);
    const t = useTriton();

    const [state, ctx] = useStaticState<ProjectListViewState>({
        id: "ProjectListView",
        initial: {
            selectedProjects: [],
            isInSelectionMode: false,
            expanded: true
        }
    }).stateWithCtx;

    let projects = useLiveQuery(async () => {
        return webDB.vfsElements
            .where("path")
            .equals(props.path)
            .and(e => e.type === "project")
            .toArray();
    }, [props.path]);

    if (projects === undefined) {
        // TODO: Better loading animation? Or just blank JSX?
        return (
            <div children={
                <ListItem title={"Loading"} icon={
                    // TODO: Add better loading icon -> Make standalone component
                    // <CircularProgress variant={"indeterminate"}/>
                    undefined
                }/>
            }/>
        );
    }

    const isSelectionActive = state.isInSelectionMode || state.selectedProjects.length > 0;

    const startSelection = () => {
        ctx.update({
            isInSelectionMode: true
        });
    };

    const closeSelection = () => {
        ctx.update({
            isInSelectionMode: false,
            selectedProjects: []
        });
    };

    const toggleProjectSelect = (elem: VFSElement) => ctx
        .std.array
        .toggleElementPresence("selectedProjects", elem.id);

    const forSelectionDo = (block: (elem: VFSElement) => void) => {
        if (projects === undefined) return;
        state.selectedProjects.forEach(elemID => {
             const elem = projects!.find(elem => elem.id === elemID);
             if (elem === undefined) return;
             block(elem);
        });
    }

    return (
        <StyledProjectListView>

            <CategoryHeader
                title={"Projects"}
                expanded={state.expanded}
                onExpandToggle={() => ctx.reverseBool("expanded")}
                appendix={
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8
                    }}>
                        <Tag active={isSelectionActive} tag={
                            <CheckBoxRounded sx={{
                                fontSize: 14,
                                color: isSelectionActive ? t.col("color_primary") : "unset"
                            }}/>
                        } onClick={() => {
                            if (isSelectionActive) {
                                closeSelection()
                            } else {
                                startSelection()
                            }
                        }}/>
                        <Dot/>
                        <AddRounded sx={{ fontSize: 20 }}/>
                    </div>
                }
            />

            <TransitionGroup>
                { isSelectionActive && (
                    <Collapse>
                        <Menu opener={
                            <DefaultButton size={"small"}>
                                <DescriptiveTypography text={"Actions"}/>
                            </DefaultButton>
                        }>
                            <MenuButton text={"Duplicate"} icon={<CopyAllRounded/>} onSelect={() => {
                                const duplicatedProjectIDs: Array<string> = [];
                                forSelectionDo(elem => {
                                    api.duplicateProject({
                                        projectID: elem.id
                                    }).then((r) => {
                                        duplicatedProjectIDs.push(r.duplicateProject.id)
                                    });
                                });
                                ctx.update({
                                    selectedProjects: duplicatedProjectIDs
                                });
                            }}/>

                            <MenuGroup title={"Export"}>
                                <div>
                                    <MenuButton text={"Export project"}/>
                                    <MenuButton text={"Export project (single file)"} appendix={
                                        <HelpHint text={"Exports a single file with all resources attached"}/>
                                    }/>
                                    <MenuButton text={"Export resources"}/>
                                </div>
                            </MenuGroup>

                            <MenuButton text={"Delete"} icon={<DeleteRounded/>} onSelect={() => {
                                forSelectionDo(elem => {
                                    webDB.projects.delete(elem.targetID!);
                                    webDB.vfsElements.delete(elem.id);
                                })
                            }}/>
                        </Menu>

                        <Menu opener={
                            <DefaultButton size={"small"}>
                                <DescriptiveTypography text={"Selection"}/>
                            </DefaultButton>
                        }>
                            <MenuButton text={"Close selection"} onSelect={() => closeSelection()}/>
                            <MenuButton text={"Select all"} onSelect={() => {
                                ctx.update({
                                    selectedProjects: projects!.map(p => p.id)
                                })
                            }}/>
                            <MenuButton text={"Invert selection"} icon={<InvertColorsRounded/>} onSelect={() => {
                                ctx.update({
                                    selectedProjects: projects!
                                        .filter(p => !state.selectedProjects.find(sP => sP === p.id))
                                        .map(p => p.id)
                                })
                            }}/>
                        </Menu>
                    </Collapse>
                ) }
            </TransitionGroup>

            <TransitionGroup>
                { state.expanded && (
                    <Collapse>
                        <div className={"project-view-main"}>
                            {/*
                            <TransitionGroup>
                                { isSelectionActive && (
                                    <Collapse>
                                        <div className={"project-view-toolbar"}>
                                            <DefaultButton size={"small"} variant={"default"} children={"Actions"}/>
                                        </div>
                                    </Collapse>
                                ) }
                            </TransitionGroup>
                            */}

                            <TransitionGroup>
                                { projects?.length === 0 && (
                                    <Collapse>
                                        <div style={{
                                            width: "100%",
                                            padding: "1rem",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 8
                                        }}>
                                            <DescriptiveTypography text={"Empty"}/>
                                        </div>
                                    </Collapse>
                                ) }

                                {
                                    projects?.sort((a, b) => a.title < b.title ? -1 : 1).map(e => {
                                        const id = e.id;
                                        const isSelected = state.selectedProjects.includes(id);
                                        return (
                                            <Collapse key={id}>
                                                <ProjectListItem
                                                    isInSelectionMode={isSelectionActive}
                                                    vfsElem={e}
                                                    selected={isSelected}
                                                    onToggleSelect={() => toggleProjectSelect(e)}
                                                />
                                            </Collapse>
                                        );
                                    })
                                }
                            </TransitionGroup>
                        </div>
                    </Collapse>
                ) }
            </TransitionGroup>
        </StyledProjectListView>
    );
}
