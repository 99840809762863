import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const NodeFetch: NodeSetupInfo = {
    label: "NodeFetch",
    classname: "network.fetch",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "network.fetch",
        label: "fetch",
        defInPins: ["c", "req", "ƒ"],
        defOutPins: ["res", "blob", "text"],
        init() {
            this.pins.in("c").attach({
                read: async () => {
                    const req: RequestInfo | URL = this.pins.in("req").lastReadState;

                    const fetchKernel = async (request: RequestInfo | URL) => {
                        const method = this.pins.in("ƒ").lastReadState;
                        const res = await fetch(request, {
                            method: method ?? "get"
                        });
                        // TODO: Add condition to either text() or blob()
                        const text = await res.clone().text();
                        const blob = await res.blob();
                        return { res, text, blob };
                    };

                    const fetchRes = await fetchKernel(req);
                    this.pins.out("res").write(fetchRes.res);
                    this.pins.out("text").write(fetchRes.text);
                    this.pins.out("blob").write(fetchRes.blob);
                }
            });
        }
    })
}
