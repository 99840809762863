import React from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";

const StyledBoardingActionHint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`

export type BoardingActionHintProps = {
    question: string,
    action: string,
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const BoardingActionHint: React.FC<BoardingActionHintProps> = props => {
    return (
        <StyledBoardingActionHint onClick={e => props.onClick(e)}>
            <DescriptiveTypography text={props.question} style={{
                fontSize: "12px",
                color: "white",
                opacity: .6
            }}/>
            <DescriptiveTypography text={props.action} style={{
                fontSize: "12px",
                fontWeight: 600,
                color: "white",
            }}/>
        </StyledBoardingActionHint>
    );
}
