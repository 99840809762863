import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const NodeCableTieSingle: NodeSetupInfo = {
    label: "NodeCableTieSingle",
    classname: "organization.cable-tie-single",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "organization.cable-tie-single",
        label: "",
        defOutPins: [""],
        defInPins: [""],
        init: function () {
            this.createInternalWire({
                targetInPin: "",
                targetOutPin: "",
            })
        }
    })
}
