import React, {useEffect, useState} from "react";
import {BoolSense} from "./BoolSense";
import axios from "axios";
import {arcURL} from "../../ardspace/arc/Globals";
import {Flex} from "../../ardspace/arc/common/Flex";
import {Centered} from "../../base/components/base/PosInCenter";

export const LeviathanMain: React.FC = props => {
    const [config, setConfig] = useState<any>();

    useEffect(() => {
        axios({ url: arcURL("config/full/aConfig") }).then(res => setConfig(res.data))
    }, []);

    return (
        <Centered fullHeight>
            <Flex>
                { config?.map((info: any) => (
                    <BoolSense key={info.key} title={info.key} on={info.liveValue} onChange={async on => {
                        const res = await axios({
                            method: "patch",
                            url: arcURL(`config/aConfig/${info.key}`),
                            data: on
                        });
                        const valueWasActuallyUpdated = res.data;
                        console.log(valueWasActuallyUpdated)

                        // axios({ url: arcURL("config/full/aConfig") }).then(res => setConfig(res.data))
                    }}/>
                )) }
            </Flex>
        </Centered>
    );
}
