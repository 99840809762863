import React, {useEffect, useState} from "react";
import {useTimer} from 'react-timer-hook';
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {LinearProgress} from "@mui/material";
import styled from "styled-components";
import {FlatIconButton} from "../../components/FlatIconButton";
import {PauseRounded, PlayArrowRounded, RefreshRounded, SquareRounded} from "@mui/icons-material";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {DefaultButton} from "../../components/DefaultButton";
import formatDuration from "format-duration";

const StyledChallengeTimerComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  .acknowledge-toolbar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
  
  .timer-toolbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .right {
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }
`;

export type ChallengeTimerComponentProps = {
    time: number,
    onCompleted: (acknowledge: () => void, onAcknowledge: (callback: () => void) => void) => void,
}

export enum ChallengeTimerState {
    PAUSED, RUNNING, FINISHED, INITIAL
}

export type ChallengeTimerComponentState = {
    state: ChallengeTimerState,
    acknowledgeables: Array<() => void>
}

export const ChallengeTimerComponent: React.FC<ChallengeTimerComponentProps> = props => {
    const [state, setState] = useState<ChallengeTimerComponentState>({
        state: ChallengeTimerState.INITIAL,
        acknowledgeables: []
    });

    const createExpiryTimestamp = (durationInS: number) => {
        const time = new Date();
        time.setSeconds(time.getSeconds() + durationInS);
        return time;
    }

    const updateTimerState = (state: ChallengeTimerState) => {
        setState(prevState => ({
            ...prevState,
            state: state
        }));
    }

    const restart = (durationInS: number = props.time, autoStart: boolean = true) => {
        timer.restart(createExpiryTimestamp(durationInS), autoStart);
        if (autoStart) {
            updateTimerState(ChallengeTimerState.RUNNING);
        } else {
            updateTimerState(ChallengeTimerState.INITIAL);
        }
    }

    const acknowledge = () => {
        state.acknowledgeables.map(f => f());
        setState(prevState => ({
            ...prevState,
            state: ChallengeTimerState.INITIAL,
            acknowledgeables: []
        }));
        restart(props.time, false);
    }

    const onTimerCompletion = () => {
        updateTimerState(ChallengeTimerState.FINISHED);
        props.onCompleted(() => {
            acknowledge();
        }, callback => {
            setState(prevState => ({
                ...prevState,
                acknowledgeables: [...prevState.acknowledgeables, callback]
            }));
        });
    }

    const timer = useTimer({
        expiryTimestamp: createExpiryTimestamp(props.time),
        onExpire: onTimerCompletion,
        autoStart: false
    });

    const pause = () => {
        timer.pause();
        updateTimerState(ChallengeTimerState.PAUSED);
    }

    const resume = () => {
        timer.resume();
        updateTimerState(ChallengeTimerState.RUNNING);
    }

    const start = () => {
        timer.start();
        updateTimerState(ChallengeTimerState.RUNNING);
    }

    useEffect(() => {
        restart(props.time, false);
    }, [props.time]);

    const timeLeftPercentage = timer.totalSeconds / props.time * 1e2;

    return (
        <StyledChallengeTimerComponent>
            <TransitionGroup children={ (state.state === ChallengeTimerState.RUNNING || state.state === ChallengeTimerState.PAUSED) && (
                <Collapse children={
                    <LinearProgress
                        variant={"determinate"}
                        value={timeLeftPercentage}
                        color={timeLeftPercentage > 20 ? "primary" : (
                            timeLeftPercentage > 10 ? "warning" :
                                "error"
                        )}
                    />
                }/>
            ) }/>

            <TransitionGroup children={ state.state === ChallengeTimerState.FINISHED && (
                <Collapse children={
                    <div className={"acknowledge-toolbar"}>
                        <DefaultButton size={"small"} children={"Acknowledge"} variant={"primary"} onClick={acknowledge}/>
                        <DefaultButton size={"small"} children={"Restart"} onClick={() => {
                            acknowledge();
                            restart(props.time, true);
                        }}/>
                    </div>
                }/>
            ) }/>

            <div className={"timer-toolbar"}>
                <DescriptiveTypography text={(() => {
                    switch (state.state) {
                        case ChallengeTimerState.FINISHED:
                            return "0";
                        case ChallengeTimerState.INITIAL:
                            return `${formatDuration(props.time * 1e3)}`;
                        case ChallengeTimerState.PAUSED:
                        case ChallengeTimerState.RUNNING:
                            return `${formatDuration(timer.totalSeconds * 1e3)} / ${formatDuration(props.time * 1e3)}`;
                    }
                })()}/>

                <div className={"right"}>
                    <FlatIconButton
                        children={<SquareRounded fontSize={"small"}/>}
                        onClick={() => restart(props.time, false)}
                    />
                    <FlatIconButton
                        children={<RefreshRounded fontSize={"small"}/>}
                        onClick={() => restart()}
                    />

                    {(() => {
                        switch (state.state) {
                            case ChallengeTimerState.PAUSED:
                                return (
                                    <FlatIconButton
                                        children={<PlayArrowRounded fontSize={"small"}/>}
                                        onClick={() => resume()}
                                    />
                                );
                            case ChallengeTimerState.FINISHED:
                                return (
                                    <FlatIconButton
                                        children={<PlayArrowRounded fontSize={"small"}/>}
                                        onClick={() => restart()}
                                    />
                                );
                            case ChallengeTimerState.INITIAL:
                                return (
                                    <FlatIconButton
                                        children={<PlayArrowRounded fontSize={"small"}/>}
                                        onClick={() => start()}
                                    />
                                );
                            case ChallengeTimerState.RUNNING:
                                return (
                                    <FlatIconButton
                                        children={<PauseRounded fontSize={"small"}/>}
                                        onClick={() => pause()}
                                    />
                                );
                        }
                    })()}
                </div>
            </div>
        </StyledChallengeTimerComponent>
    )
}
