import {UnaryPipelineStep} from "./UnaryPipelineStep";

export class UnaryPipeline<T> implements UnaryPipelineStep<T> {

    private readonly steps: Array<UnaryPipelineStep<T>> = new Array<UnaryPipelineStep<T>>();

    constructor(...steps: Array<UnaryPipelineStep<T>>) {
        this.steps.push(...steps);
    }

    public addSteps(...steps: UnaryPipelineStep<T>[]): UnaryPipeline<T> {
        this.steps.push(...steps);
        return this;
    }

    public addFunctionalSteps(...steps: ((operand: T) => Promise<T>)[]): UnaryPipeline<T> {
        this.steps.push(...(steps.map(f => new class implements UnaryPipelineStep<T> {
            async operate(operand: T): Promise<T> {
                return await f(operand);
            }
        }())));
        return this;
    }

    public addFunctionalEditorialSteps(...steps: ((operand: T) => Promise<void>)[]): UnaryPipeline<T> {
        this.steps.push(...(steps.map(f => new class implements UnaryPipelineStep<T> {
            async operate(operand: T): Promise<T> {
                await f(operand);
                return operand;
            }
        }())));
        return this;
    }

    public addSyncFunctionalEditorialSteps(...steps: ((operand: T) => void)[]): UnaryPipeline<T> {
        this.steps.push(...(steps.map(f => new class implements UnaryPipelineStep<T> {
            async operate(operand: T): Promise<T> {
                f(operand);
                return operand;
            }
        }())));
        return this;
    }

    async operate(operand: T): Promise<T> {
        for (const step of this.steps) {
            operand = await step.operate(operand)
        }
        return operand;
    }
}
