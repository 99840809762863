import React, {useRef, useState} from "react";
import {StorybookPage} from "./StorybookPage";
import {StorybookAPI} from "./StorybookAPI";
import styled from "styled-components";
import {Flex} from "../Flex";
import Collapse from "@mui/material/Collapse";
import {TransitionGroup} from "react-transition-group";
import {PageMainRenderer} from "./PageMainRenderer";
import Slide from "@mui/material/Slide";
import {MobileInnerFormDialog} from "./MobileInnerFormDialog";
import Dialog from "@mui/material/Dialog";
import {StorybookResult} from "./StorybookResult";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {SignalFunction} from "./SignalFunction";

const StyledStorybook = styled.div`
  width: 100%;
  display: flex;
  position: static;
  max-width: 100vw;
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
`;

export type StorybookProps<T> = {
    signals?: Map<string, SignalFunction>,
    beginning: StorybookPage<any>,
    onExit: (result: StorybookResult<T>) => void,
}

export type StorybookState = {
    currentPage?: StorybookPage<any>,
    signals?: Map<string, SignalFunction>,
    nextPage?: StorybookPage<any>,
    transitionState: "initial" | "transitioning",
    transitionDirection?: "forward" | "backward",
    focussed: boolean,
    navigate?: NavigateFunction,
}

export function Storybook<T = any>(props: StorybookProps<T>) {
    const navigate = useNavigate();
    const api = useRef(new StorybookAPI());
    const [state, setState] = useState<StorybookState>({
        currentPage: props.beginning,
        transitionState: "initial",
        focussed: false,
        navigate: navigate,
        signals: props.signals
    });
    api.current.updateStateReferences(state, setState);
    const page = state.currentPage;
    const nextPage = state.nextPage;
    const direction = state.transitionDirection ?? "forward";
    const isTransitioning: boolean = state.transitionState === "transitioning";
    const pageTransitionDuration = .5e3;
    const pageTransitionDeltaDuration = .2e3;

    const onPageTransitionFinished = () => {
        if (isTransitioning) {
            setState(prevState => ({
                ...prevState,
                currentPage: prevState.nextPage,
                nextPage: undefined,
                transitionState: "initial",
                transitionDirection: "forward",
            }));
        }
    }

    return (
        <StorybookStateContext.Provider value={state} children={
            <StorybookAPIContext.Provider value={api.current} children={
                <StyledStorybook className={"storybook"} onFocus={e => {
                    // if (!state.focussed) {
                    //     setState(prevState => ({
                    //         ...prevState,
                    //         focussed: true
                    //     }));
                    // }
                }}>

                    <Dialog open={state.focussed} fullScreen children={
                        <MobileInnerFormDialog />
                    }/>

                    <Flex g={39} baseProps={{
                        style: {
                            height: "100%",
                            width: "100%",
                            flexDirection: "column",
                            alignItems: "center"
                        }
                    }}>
                        <Flex g={0} baseProps={{
                            style: {
                                width: "100%",
                                height: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative"
                            }
                        }}>
                            { /* Current page tray */ }
                            <Slide
                                in={state.transitionState === "initial" ? true : !isTransitioning}
                                direction={direction === "forward" ? "right" : "left"}
                                appear={false}
                                enter={true}
                                exit={true}
                                timeout={{
                                    exit: pageTransitionDuration
                                }}
                                style={{
                                    transitionTimingFunction: "ease-in-out",
                                    top: "0",
                                    position: "absolute",
                                    left: "0",
                                    width: "100%"
                                }}
                            >
                                <div style={{ height: "100%" }} children={page && (
                                    <PageMainRenderer master page={page}/>
                                )}/>
                            </Slide>

                            { /* Next page tray */ }
                            <Slide
                                direction={direction === "forward" ? "left" : "right"}
                                onTransitionEnd={onPageTransitionFinished}
                                in={isTransitioning}
                                appear={false}
                                exit={false}
                                timeout={{
                                    enter: pageTransitionDuration + pageTransitionDeltaDuration
                                }}
                                style={{
                                    transitionTimingFunction: "ease-in-out",
                                    top: "0",
                                    position: "absolute",
                                    left: "0",
                                    width: "100%"
                                }}
                            >
                                <div style={{ height: "100%" }} children={nextPage && (
                                    <PageMainRenderer page={nextPage!}/>
                                )}/>
                            </Slide>
                        </Flex>



                        <TransitionGroup exit={true} children={
                            page?.footer && (
                                <Collapse key={`storybook-${page.name}-footer`} children={
                                    page.footer(api.current)
                                }/>
                            )
                        }/>
                    </Flex>
                </StyledStorybook>
            }/>
        }/>
    );
}

export const StorybookStateContext = React.createContext<StorybookState>({
    transitionState: "initial",
    focussed: false
})

export const StorybookAPIContext = React.createContext<StorybookAPI>(new StorybookAPI())
