import {as} from "../../atlas/Lang";
import {Simulation} from "./Simulation";
import {F, KSU, SimulationLayer} from "./Simulation2Main";
import {CoreHeatState} from "./entities/engine/CoreHeatState";
import {FuelDistributionMode} from "./FuelDistributionMode";
import {AzypodState, newAzypod} from "./entities/azypod/AzypodEntity";
import {Func, newConstantFunc, newDiscontinuedFunc, newLinearTransformedLerpFunc} from "./math/Func";
import {ShipMainEntity} from "./entities/ship/ShipMainEntity";
import {Entity} from "./ecs/entity/Entity";
import {PIDController} from "./math/PIDController";
import {Std} from "../../../Std";
import {EnvironmentSimulationEntity} from "./entities/ambient/EnvironmentSimulationEntity";
import {SteeringAPEntity} from "./entities/ap/SteeringAPEntity";
import {DoorEntity} from "./entities/door/DoorEntity";

export const engineCSimulation = as<Simulation>({
    coreCount: 5,
    init(sim: SimulationLayer) {

        // Main ship entity - (hold central data)
        const ship = new ShipMainEntity;
        sim.addEntities(true, ship);

        sim.addEntities(true, new class extends Entity {
            init() {
                super.init();

                // Lowest deck ~ Engine room
                this.addChildren(new class extends Entity {
                    init() {
                        super.init();
                        this.addChildren(new DoorEntity("stairway-a-door"));
                    }
                }("deck-0", {
                    description: "Engine room deck. Deck-0 is the lowest deck on the ship, it's partially below waterline."
                }))
            }
        }("ship-structure", {
            description: "Describes the ships main structure elements like decks, rooms, compartments, doors etc."
        }));

        const mainShaftEngineClutch = new Entity("main-shaft-engine-clutch", {
            initialState: {
                maxKSU: 115,
                minKSU: 115,
            } as any,
            async asyncTick(e) {
                const engine = e.simulation.entity("engine");
                const s = e.state;
                const engineThrustKSU = engine.state[KSU] ?? 0;
                const minKSUToEngage = s.minKSU ?? 1, maxKSU = s.maxKSU ?? 115;

                const isEngaged = engineThrustKSU >= minKSUToEngage && engineThrustKSU <= maxKSU;

                // TODO: Split KSU between receivers
                e.out(KSU).first()?.setStatePartially({
                    [KSU]: engineThrustKSU
                });

                e.setStatePartially({
                    engaged: isEngaged,
                    ksuI: engineThrustKSU,
                    ksuO: isEngaged ? engineThrustKSU : 0
                });
            }
        });

        const mainShaftEngineClutch2 = new Entity("main-shaft-engine-clutch-2", {
            initialState: {
                maxKSU: 130,
                minKSU: 15,
            } as any,
            async asyncTick(e) {
                const engine = e.simulation.entity("engine");
                const s = e.state;
                const engineThrustKSU = engine.state[KSU] ?? 0;
                const minKSUToEngage = s.minKSU ?? 1, maxKSU = s.maxKSU ?? 115;
                const isEngaged = engineThrustKSU >= minKSUToEngage && engineThrustKSU <= maxKSU;

                e.setStatePartially({
                    engaged: isEngaged,
                    ksuI: engineThrustKSU,
                    ksuO: isEngaged ? engineThrustKSU : 0
                });
            }
        });

        const producer = new Entity("infinite-fuel-src", {
            initialState: {
                fuelSourcedPerTick: 200
            } as any,
            async asyncTick(e) {
                const fuelSourcedPerTick = e.state.fuelSourcedPerTick ?? 200;
                e.out(F).first().addToField(F, fuelSourcedPerTick, fuelSourcedPerTick);
            }
        });

        const valve = new Entity("valve", {
            async asyncTick(e) {
                const res = await e.reduceField(F, e.state.throughput ?? 0, 0);
                e.out(F).first().addToField(F, res.redBy, 0, 1000);
            }
        });

        const engine = new class extends Entity {

            getInitialState(): { [p: string]: any } {
                return {
                    e: 0,
                    [F]: 0
                };
            }

            init() {
                super.init();

                // Add engine cors (0-4)
                const coreCount = 5;
                for (let i = 0; i < coreCount; i++) {
                    const isFirstCore = i === 0;
                    this.addChildren(new Entity<any>(`core-${i}`, {
                        loggerEnabled: isFirstCore,
                        asyncTick: async e => {

                            // functions
                            const coreTempToThrustFacFunc = newDiscontinuedFunc(
                                [[-20, 0], newLinearTransformedLerpFunc(-20, 0, .75, 1)],
                                [[0, 70], newConstantFunc(1)],
                                [[70, 105], newLinearTransformedLerpFunc(50, 100, 1, 1.25)],
                                [[105, 130], newLinearTransformedLerpFunc(100, 130, 1.5, 2)],
                            );

                            // body

                            const manualActive = e.state?.active ?? false;
                            const overheatingThreshold = 120;
                            const overheatingCoolingThreshold = 60;
                            const environmentTemp = 20;
                            let temp = (e.state?.temp) ?? environmentTemp;
                            const wasOverheating = ((e.state?.heatState) ?? CoreHeatState.OK) === CoreHeatState.OVERHEATED;
                            const isOverheated = wasOverheating ? temp > overheatingCoolingThreshold : temp > overheatingThreshold;

                            // Calculate fuel & thrust
                            const engineChemToKinEConvFac = 1;
                            const mainActive = !isOverheated && manualActive;
                            const fuelIntakeAt100Percent = 20;
                            const currentThrottleInPercent = e.state?.throttle ?? 0; // Load from state
                            const fuelIntakeAtCurrentThrottle = fuelIntakeAt100Percent * currentThrottleInPercent * 1e-2;

                            let calculatedFuelIntake = mainActive ? fuelIntakeAtCurrentThrottle : 0;
                            // calculatedFuelIntake += e.state?.subCalculatedFuelIntake ?? 0;

                            const engine = e.parent!;
                            const fuelUsed = await engine.reduceField(F, calculatedFuelIntake, 0);
                            let ksu = fuelUsed.redBy * engineChemToKinEConvFac;

                            ksu = coreTempToThrustFacFunc.f(temp) * ksu;

                            // TODO: Add fac to ksu

                            engine.addToField(KSU, ksu);

                            // Calculate heat created
                            const heatEnergyPerFuelUnitBurned = .25;
                            const generatedHeat = fuelUsed.redBy * heatEnergyPerFuelUnitBurned;

                            const cooler = e.getChild("cooler");
                            const coolerReqFuelAmount = cooler.state.reqFuelAmount
                            calculatedFuelIntake += coolerReqFuelAmount;
                            let fuelEconomy = fuelUsed.redBy;
                            fuelEconomy += cooler.state.fuelEconomy;

                            e.setState(prevState => {
                                // Refine core heat value
                                const naturalHeatDissipation = 1.75;
                                let temp = (prevState.temp) ?? environmentTemp;
                                temp += generatedHeat;
                                if (temp > environmentTemp) temp = Math.max(temp - naturalHeatDissipation, environmentTemp);
                                else if (temp < environmentTemp) temp = Math.min(temp + naturalHeatDissipation, environmentTemp);

                                return ({
                                    ...prevState,
                                    thrust: ksu,
                                    fuelEconomy: fuelEconomy,
                                    calculatedFuelIntake: calculatedFuelIntake,
                                    temp: temp,
                                    heatState: isOverheated ? CoreHeatState.OVERHEATED : CoreHeatState.OK,

                                    reqFuelAmount: calculatedFuelIntake,

                                    // subCalculatedFuelIntake: 0
                                });
                            });


                        },
                        groups: ["cores"],
                        initialState: {
                            heatState: CoreHeatState,
                            fuelDistributionMode: FuelDistributionMode.PRIORITY,
                            active: false,
                            throttle: 0,

                            subCalculatedFuelIntake: 0
                        },
                        children: [
                            new Entity<any>("cooler", {
                                asyncTick: async e => {
                                    const core = e.parent!;
                                    const environmentTemp = 20;
                                    const temp = core.state.temp ?? environmentTemp;
                                    let active: boolean = e.state.active ?? false;
                                    let coolingForce: number = e.state.coolingForce ?? 0;

                                    // Recalculate cooler state if cooler is in auto mode
                                    const overruled = e.state.manOverruling ?? false;
                                    if (!overruled) {
                                        const [lowerGate, upperGate] = [40, 70];
                                        if (temp >= upperGate) {
                                            active = true;
                                            coolingForce = Math.min((temp - lowerGate), 100);
                                        } else if (temp < lowerGate) {
                                            active = false;
                                            coolingForce = 0;
                                        }
                                    } else {
                                        // Try to set the cooler state to the manually specified settings
                                        if (e.state.manActive) {
                                            coolingForce = e.state.manOverrulingCoolingForce ?? 100;
                                            active = true;
                                        }
                                    }

                                    // Calculate cooling force according to the fuel available to the cooling unit
                                    // Fuel-use for 100% cooling force
                                    const maxCoolerFuelUsage = 5;
                                    // .. = l * (% / 100)
                                    const calculatedFuelIntake = maxCoolerFuelUsage * (coolingForce / 100);
                                    const fuelUsed = await core.reduceField(F, calculatedFuelIntake, 0);
                                    // Check if fuel was actually used -> no 0-division
                                    const fuelSupplyFactor = fuelUsed.redBy > 0 ? calculatedFuelIntake / fuelUsed.redBy : 0;
                                    coolingForce = coolingForce * fuelSupplyFactor;
                                    // console.log("calc", calculatedFuelIntake, "used", fuelUsed, "supply fac", fuelSupplyFactor)
                                    e.setState(prevState => ({
                                        ...prevState,
                                        active: active,
                                        coolingForce: coolingForce,
                                        reqFuelAmount: calculatedFuelIntake,
                                        fuelEconomy: fuelUsed.redBy
                                    }));



                                    await core.reduceField("temp", coolingForce * .25, -20);
                                },
                                initialState: {
                                    manActive: false,
                                    manOverruling: false,
                                    manOverrulingCoolingForce: 0,
                                    reqFuelAmount: 0,
                                    fuelEconomy: 0,
                                    [F]: 0
                                }
                            })
                        ]
                    }))
                }
            }

            async tick() {
                await super.tick();

                // Reset thrust vector (kinetic-stress-units ~ ksu) for current cycle
                this.setStatePartially({
                    [KSU]: 0
                });

                // Distribute fuel to cores
                const fdMode: FuelDistributionMode = this.state?.fuelDistributionMode ?? FuelDistributionMode.AVG;
                const cores = this.getChildrenFromGroup("cores");
                const cL = cores.length;
                const coresReqFuel = cores.map(c => c.state?.reqFuelAmount ?? 0).reduce((a, x) => a + x);
                // const coreAvgFuelShare = coresReqFuel / cL;
                // let availableFuel = this.state[F] ?? 0;
                // cores.forEach(core => {
                //     const reqFuelAmount = core.state.reqFuelAmount ?? 0;
                //     switch (fdMode) {
                //         case FuelDistributionMode.AVG:
                //             core.addToField(F, coreAvgFuelShare, 0, reqFuelAmount);
                //             break;
                //         case FuelDistributionMode.PRIORITY:
                //             const reqToFulfill = reqFuelAmount - core.state[F];
                //             core.addToField(F, Math.min(reqToFulfill, availableFuel), 0, reqFuelAmount);
                //             availableFuel -= Math.max(reqToFulfill, 0);
                //             break;
                //     }
                // });

                const engineFuelUsed = await this.reduceField(F, coresReqFuel, 0);
                const requestToAvailableRatio = engineFuelUsed.redBy > 0 ? engineFuelUsed.redBy / coresReqFuel : 0;
                // console.log(engineFuelUsed.redBy, coresReqFuel, "=>", requestToAvailableRatio)
                cores.forEach(core => {
                    const coreTankCapacity = 100;
                    const coreFuelReq = core.state?.reqFuelAmount ?? 0;
                    const coreFuel = coreFuelReq * requestToAvailableRatio;
                    core.logger.log("fuel added", coreFuel, "fac", requestToAvailableRatio);
                    core.addToField(F, coreFuel, 0, coreTankCapacity);
                });
            }
        }("engine");

        // Setup virtualized "fuel line"
        producer.out(F).link(valve).out(F).link(engine);

        sim.addEntities(true, producer, valve, engine);
        sim.addEntities(true, mainShaftEngineClutch);
        sim.addEntities(true, mainShaftEngineClutch2);

        const portAzypod = newAzypod(sim, "port-azypod");
        mainShaftEngineClutch.out(KSU).link(portAzypod as any);

        sim.addEntities(true, portAzypod);
        sim.addEntities(true, new EnvironmentSimulationEntity);
        sim.addEntities(true, new SteeringAPEntity);

    }
})

