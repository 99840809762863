import React, {useContext, useEffect, useRef, useState} from "react";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {ArdaiAPIStateContext} from "../ArdaiMain";
import {Project} from "../data/Project";
import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../webapi/WebAPIDB";
import {ImageMetaData} from "../data/ImageMetaData";
import {getMetadata} from "meta-png";
import {ExtraNetworkMetaInfo} from "../data/ExtraNetworkMetaInfo";
import styled from "styled-components";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {ClipboardCopyButton} from "./ClipboardCopyButton";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {ExtraNetworkTagIndicator} from "./ExtraNetworkTagIndicator";
import {MetaBasicEntry} from "./MetaBasicEntry";
import {PromptRenderer} from "./promptRenderer/PromptRenderer";
import {useStaticState} from "../hooks/StaticStateHook";
import {TabBar} from "./TabBar";
import {TabBodyRenderer} from "./TabBodyRenderer";
import {StableDiffusionAttachment} from "../webapi/pngProperties/attachments/StableDiffusionAttachment";
import {DexieImage} from "../webapi/DexieImage";

const StyledMetadataViewV2 = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  .sliding-container {
    height: 100%;
    display: grid;
    grid-template-rows: min-content auto min-content;
    gap: 8px;
    width: 100%;
    
    .prompt-container, .additional-container, .metadata-view-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .metadata-view-header {

    }
    
    .prompt-container {
      overflow: scroll; 
    }
  }
`;

export enum MetadataViewV2TabNames {
    PROMPT = "prompt",
    ADDITIONAL = "additional"
}

export type MetadataViewV2State = {
    tab: MetadataViewV2TabNames | string,

    promptTab: string
}

export const MetadataViewV2: React.FC = props => {
    const api = useContext(ArdaiAPIContext);
    const state = useContext(ArdaiAPIStateContext);
    const [currentProject, setCurrentProject] = useState<Project | undefined>(undefined);
    useEffect(() => {
        api.getCurrentProject().then(cp => setCurrentProject(cp));
    }, [api, state]);

    const currentImage = useLiveQuery(async () => {
        return webDB.images
            .where("id")
            .equals(state.selectedImageId ?? "_placeholder")
            .first();
    }, [state.selectedImageId]);

    const [isMetaLoading, setMetaLoading] = useState(false);
    // const [currentImageMeta, setCurrentImageMeta] = useState<ImageMetaData | undefined>(undefined);

    const [currentImageMeta, setCurrentImageMeta] = useState<StableDiffusionAttachment | undefined>(undefined);

    const data = useRef<{
        rawMetaString?: string
    }>({});

    useEffect(() => {
        if (currentImage === undefined) {
            setMetaLoading(true);
            data.current.rawMetaString = undefined;
            setCurrentImageMeta(undefined);
            setMetaLoading(false)
            return;
        }

        api.pngPropertiesManager.getStableDiffusionAttachment(new DexieImage(currentImage!.id)).then(attachment => {
            setCurrentImageMeta(attachment);
        });
    }, [currentImage]);

    const [localState, ctx] = useStaticState<MetadataViewV2State>({
        id: "metadata-view-v2",
        initial: {
            tab: MetadataViewV2TabNames.PROMPT,

            // TODO: move to separate component
            promptTab: "all"
        }
    }).stateWithCtx;

    return (
        <StyledMetadataViewV2>
            <div className={"sliding-container"}>
                <div className={"metadata-view-header"}>
                    <MainTypography noSelect text={"Prompt information"}/>

                    <TabBar
                        activeBar={localState.tab}
                        equalSizeMode
                        tabs={[
                            {
                                id: MetadataViewV2TabNames.PROMPT,
                                title: "Prompt"
                            }, {
                                id: MetadataViewV2TabNames.ADDITIONAL,
                                title: "Additional",
                                tooltip: "Sampler, model, seed etc."
                            }
                        ]}
                        onTabChange={tab => {
                            ctx.update({
                                tab: tab
                            });
                        }}
                    />
                </div>

                <TabBodyRenderer
                    active={localState.tab ?? MetadataViewV2TabNames.PROMPT}
                    tabs={new Map<string, () => React.ReactElement>([
                        [MetadataViewV2TabNames.PROMPT, () => (
                            <div className={"prompt-container"}>

                                { currentImageMeta?.prompt && (
                                    <PromptRenderer
                                        title={"Positive prompt"}
                                        rendererName={"positive-prompt"}
                                        originalPrompt={currentImageMeta.prompt}
                                    />
                                ) }

                                { currentImageMeta?.negativePrompt && (
                                    <PromptRenderer
                                        title={"Negative prompt"}
                                        rendererName={"negative-prompt"}
                                        originalPrompt={currentImageMeta.negativePrompt}
                                    />
                                ) }

                                {/*
                                <TabBar
                                    activeBar={"positive" ?? localState.promptTab}
                                    equalSizeMode
                                    tabs={[
                                        {
                                            id: "all",
                                            title: "All"
                                        }, {
                                            id: "positive",
                                            title: "Prompt",
                                            tooltip: "Sampler, model, seed etc."
                                        }, {
                                            id: "negative",
                                            title: "Neg. prompt",
                                            tooltip: "Sampler, model, seed etc."
                                        }
                                    ]}
                                    onTabChange={tab => {
                                        ctx.update({
                                            promptTab: tab
                                        });
                                    }}
                                />

                                <TabBodyRenderer
                                    active={localState.tab ?? MetadataViewV2TabNames.PROMPT}
                                    tabs={new Map<string, () => React.ReactElement>([
                                        ["all", () => (
                                            <div className={"prompt-container"}>
                                                { currentImageMeta?.prompt && (
                                                    <PromptRenderer
                                                        title={"Positive prompt"}
                                                        rendererName={"positive-prompt"}
                                                        originalPrompt={currentImageMeta.prompt}
                                                    />
                                                ) }

                                                { currentImageMeta?.negativePrompt && (
                                                    <PromptRenderer
                                                        title={"Negative prompt"}
                                                        rendererName={"negative-prompt"}
                                                        originalPrompt={currentImageMeta.negativePrompt}
                                                    />
                                                ) }
                                            </div>
                                        )],
                                        ["positive", () => (
                                            <div className={"prompt-container"}>
                                                { currentImageMeta?.prompt && (
                                                    <PromptRenderer
                                                        title={"Positive prompt"}
                                                        rendererName={"positive-prompt"}
                                                        originalPrompt={currentImageMeta.prompt}
                                                    />
                                                ) }
                                            </div>
                                        )],
                                        ["negative", () => (
                                            <div className={"prompt-container"}>
                                                { currentImageMeta?.negativePrompt && (
                                                    <PromptRenderer
                                                        title={"Negative prompt"}
                                                        rendererName={"negative-prompt"}
                                                        originalPrompt={currentImageMeta.negativePrompt}
                                                    />
                                                ) }
                                            </div>
                                        )]
                                    ])}
                                />
                                */}
                            </div>
                        )],
                        [MetadataViewV2TabNames.ADDITIONAL, () => (
                            <div className={"additional-container"}>
                                <MainTypography text={"Extra networks"} noSelect/>
                                { currentImageMeta?.extraNetworks.length === 0 && (
                                    <DescriptiveTypography noSelect text={"No extra networks used"}/>
                                ) }
                                { currentImageMeta?.extraNetworks.map(netInfo => (
                                    <ExtraNetworkTagIndicator netInfo={netInfo}/>
                                )) }

                                <MainTypography text={"Additional info"} noSelect/>
                                <div style={{
                                    display: "grid",
                                    gap: "8px"
                                }}>
                                    {
                                        Array.from(currentImageMeta?.kvs?.entries() ?? []).map(e => ({ k: e[0], v: e[1] })).map(kv => (
                                            <MetaBasicEntry key={kv.k} title={kv.k} value={kv.v}/>
                                        ))
                                    }
                                </div>
                            </div>
                        )]
                    ])}
                />

                <div>
                    <DescriptiveTypography text={"This is a footer. Something very meaningful should be written here, but as you see, there's just me."}/>
                </div>
            </div>


        </StyledMetadataViewV2>
    );
}
