import {FC} from "react";
import {TypographyProps} from "./TypographyProps";
import {useTriton} from "../../../../triton/TritonHooks";
import {Typography} from "./Typography";

export const SecondaryTextTypography: FC<TypographyProps> = props => {
    const t = useTriton();
    const fontSize = props.fontSize ?? 14;
    return (
        <Typography {...props} s={{
            fontFamily: "'Poppins', serif",
            fontWeight: "lighter",
            whiteSpace: "nowrap",
            userSelect: "none",
            fontSize: fontSize,
            lineHeight: "21px",
            color: t.col("fg_secondary"),
            opacity: 1,
            ...(props.s ?? {})
        }}/>
    );
}
