import React from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../../../../triton/components/typography/DescriptiveTypography";
import {CheckRounded, CloseRounded} from "@mui/icons-material";
import {LinearProgress, linearProgressClasses} from "@mui/material";

const StyledPasswordStrengthIndicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledCriteriaContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;

const StyledPasswordStrengthCriteria = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  transition: all 500ms;
  background-color: black;
  color: rgba(255, 255, 255, .6);
  
  svg {
    width: 18px;
  }
  
  &[data-fulfilled=true] {
    color: #B55EF9;
  }
`;

export type PasswordStrengthIndicatorProps = {
    password: string
}

export type Criteria = {
    key: string,
    title?: string,
    checker: (password: string) => boolean
    hidden?: boolean,
    optional?: boolean,
}

export const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = props => {
    const criteria: Array<Criteria> = [
        {
            key: "contains-num",
            title: "Numbers",
            checker: password => /\d/.test(password)
        },
        {
            key: "lower-case",
            title: "Lower Case",
            checker: password => /(?=.*[a-z])/.test(password)
        },
        {
            key: "upper-case",
            title: "Upper Case",
            checker: password => /(?=.*[A-Z])/.test(password)
        },
        {
            key: "len",
            title: "Minimum length (8)",
            checker: password => password.length > 8
        },
        {
            key: "excellent-len",
            title: "Excellent length (12)",
            hidden: true,
            optional: true,
            checker: password => password.length > 12
        },
        {
            key: "symbol",
            title: "Symbols",
            checker: password => /(?=.*\W)/.test(password)
        }
    ]

    const res = criteria.map(c => ({
        criteria: c,
        test: c.checker(props.password ?? "")
    }));

    const requiredMinCount = criteria.reduceRight((acc, c) => c.optional ? acc : ++acc, 0);
    const mandatoryScore = res.reduceRight((acc, r) => (!r.criteria.optional && r.test) ? ++acc : acc, 0);


    return (
        <StyledPasswordStrengthIndicator>
            <StyledCriteriaContainer>
                { res.map(res => (
                    <StyledPasswordStrengthCriteria key={res.criteria.key} data-fulfilled={res.test}>
                        { res.test ? (
                            <CheckRounded/>
                        ) : (
                            <CloseRounded/>
                        ) }

                        { res.criteria.title && (
                            <DescriptiveTypography text={res.criteria.title}/>
                        ) }
                    </StyledPasswordStrengthCriteria>
                )) }
            </StyledCriteriaContainer>

            <LinearProgress value={mandatoryScore / requiredMinCount * 100} color={"secondary"} variant={"determinate"} sx={{
                width: "100%",
                borderRadius: 5,
                [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 5,
                    backgroundColor: "#B55EF9",
                },
            }}/>
        </StyledPasswordStrengthIndicator>
    );
}
