import {FQExtend, NodeId, PinId} from "./serialization/FullyQualified";
import {FQNodeId} from "./FQNodeId";
import {v4} from "uuid";
import {FQBankId} from "./FQBankId";

export type FQPinId = FQExtend<FQBankId, {
    pin: PinId
    pinLabel?: string
}>

export const mkFQPinId = (
    node: NodeId = v4(),
    bank: string = v4(),
    pin: PinId = v4(),
    bankLabel: string | undefined = undefined,
    pinLabel: string | undefined = undefined
): FQPinId => ({ node, bank, pin, bankLabel, pinLabel });
