import React from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {GuardedSector} from "./arc/common/GuardedSector";
import {MobileSignupPage} from "./arc/common/pages/signup/MobileSignupPage";
import {isWithoutSession, isWithSession} from "./arc/common/GuardStrategies";
import {MobileBoardingPage} from "./arc/common/pages/boarding/MobileBoardingPage";
import {MobileLoginPage} from "./arc/common/pages/login/MobileLoginPage";
import {HomePage} from "../test/auth/AuthTestMain";

export const ArdspaceRoutingMain: React.FC = props => {
    const mainSpaceUrl = "/ardspace";
    const ardspaceUrl = React.useMemo(() => (url: string, local: boolean = true) => `${local ? "" : `${mainSpaceUrl}/`}${url}`, []);
    const location = useLocation();
    const boardingUrl = React.useMemo(() => ardspaceUrl("boarding"), [ardspaceUrl]);

    return (
        <Routes location={location}>
            <Route path={ardspaceUrl("/")} element={
                <GuardedSector
                    notAllowedComponent={<Navigate to={boardingUrl}/>}
                    // TODO: Set real main component
                    children={<HomePage/>}
                    strategy={isWithSession}
                />
            }/>

            <Route path={ardspaceUrl("signup")} element={
                <GuardedSector
                    notAllowedComponent={<Navigate to={mainSpaceUrl}/>}
                    children={<MobileSignupPage/>}
                    strategy={isWithoutSession}
                />
            }/>
            <Route path={ardspaceUrl("boarding/:mode?")} element={
                <GuardedSector
                    notAllowedComponent={<Navigate to={mainSpaceUrl}/>}
                    children={<MobileBoardingPage/>}
                    strategy={isWithoutSession}
                />
            }/>
            <Route path={ardspaceUrl("login")} element={
                <GuardedSector
                    notAllowedComponent={<Navigate to={mainSpaceUrl}/>}
                    children={<MobileLoginPage/>}
                    strategy={isWithoutSession}
                />
            }/>

            <Route path={ardspaceUrl("*")} element={
                <Navigate to={mainSpaceUrl}/>
            }/>
        </Routes>
    );
}
