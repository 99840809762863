import {webDB} from "../webapi/WebAPIDB";
import {WebAPI} from "../webapi/WebAPI";

export class ProjectContext {

    private readonly api: WebAPI;

    private readonly id: string;

    constructor(api: WebAPI, id: string) {
        this.api = api;
        this.id = id;
    }

    public setPreviewImage(id: string | undefined) {
        webDB.projects.update(this.id, {
            "previewImageID": id
        });
    }

    public async editProperty(key: string, newVal: string) {
        const project = await webDB.projects.get(this.id)
        webDB.projects.update(this.id, {
            metaProperties: [
                ...project?.metaProperties?.filter(p => p.key !== key) ?? [], {
                    key: key,
                    value: newVal
                }
            ]
        });
    }

    public async clearProperties() {
        webDB.projects.update(this.id, {
            metaProperties: []
        });
    }

    public async removeProperty(key: string) {
        const project = await webDB.projects.get(this.id)
        webDB.projects.update(this.id, {
            metaProperties: project?.metaProperties?.filter(p => p.key !== key) ?? []
        });
    }
}
