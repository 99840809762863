import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import _ from "lodash";
import {ButtonGroup} from "../../../../ardai/components/ButtonGroup";
import {Tag} from "../../../../ardai/components/Tag";
import {AutoGraphRounded, HeightRounded} from "@mui/icons-material";
import {triton} from "../../../../triton/Triton";

export const WavePlotter: NodeSetupInfo = {
    label: "",
    classname: "visualization.wave-plotter",
    parameterConfig: [],
    factory: parameters => new Node<{
        plot: Array<number>,
        magReg: number,
        scaleX: number,
        scaleY: number,
        showLine: boolean
    }>({
        id: v4(),
        classname: "visualization.wave-plotter",
        label: "plotter",
        defInPins: ["c", "mag"],
        state: {
            plot: [],
            magReg: 0,
            scaleX: 1,
            scaleY: 1,
            showLine: true
        },
        init: function () {
            this.pins.in("c").attach({
                read(clockPulse: any) {
                    this.node.state.update(prevState => {
                        return ({
                            ...prevState,
                            plot: [prevState.magReg, ...prevState.plot].slice(0, 100)
                        });
                    });
                }
            })

            this.pins.in("mag").attach({
                read(mag: any) {
                    this.node.state.update({
                        magReg: mag
                    });
                }
            })
        },
        customRenderer: node => {
            const state = node.state.state;
            const plotHeight = 75;
            const t = triton();
            const pointColor = t.col("color_secondary");
            const lineColor = t.col("fg_muted");

            return (
                <div style={{
                    display: "grid",
                    gap: 4,
                    paddingBottom: 8
                }}>
                    <svg width={100} height={plotHeight}>
                        { state.showLine && (
                            <polyline stroke={lineColor} strokeLinejoin={"round"} strokeWidth={1} points={
                                state.plot.map((y, x) => `${x + (x * state.scaleX)},${(.5 * plotHeight) + (y * state.scaleY)}`).join(" ")
                            }/>
                        ) }

                        { state.plot.map((y, x) => {
                            return (
                                <circle cx={x + (x * state.scaleX)} cy={(.5 * plotHeight) + (y * state.scaleY)} r={1} fill={pointColor}/>
                            );
                        }) }
                    </svg>

                    <DescriptiveTypography
                        text={`${_.padStart(String(state.magReg).slice(0, 5), 5, " ")}`}
                        style={{ whiteSpace: "pre", marginLeft: "auto" }}
                    />

                    <ButtonGroup>
                        <Tag tag={"y+"} onClick={() => {
                            node.state.update({
                                scaleY: state.scaleY + 1
                            })
                        }}/>
                        <Tag tag={"y-"} onClick={() => {
                            node.state.update({
                                scaleY: state.scaleY - 1
                            })
                        }}/>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Tag tag={"x+"} onClick={() => {
                            node.state.update({
                                scaleX: state.scaleX + 1
                            })
                        }}/>
                        <Tag tag={"x-"} onClick={() => {
                            node.state.update({
                                scaleX: state.scaleX - 1
                            })
                        }}/>

                        <Tag tag={<AutoGraphRounded sx={{ fontSize: 14 }}/>} onClick={() => {
                            node.state.reverseBool("showLine")
                        }}/>
                    </ButtonGroup>
                </div>
            )
        }
    })
}
