import {FC, PropsWithChildren} from "react";
import styled from "styled-components";
import {useTriton} from "../../../../triton/TritonHooks";
import {Triton} from "../../../../triton/Triton";

const StyledIndicatorContainer = styled.div<{
    t: Triton,
    isCompactLayout: boolean,
    columns: number
}>`
  // box-shadow: ${p => p.t.col("fg_muted")} 0 0 0 1px;
  box-shadow: rgb(33, 38, 45) 0 0 0 1px;
  display: grid;
  grid-template-columns: repeat(${p => p.columns}, 1fr);
  gap: 4px;
  padding: ${p => p.isCompactLayout ? 0 : 8}px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;

export const IndicatorContainer: FC<PropsWithChildren<{
    compact?: boolean,
    columns?: number,
}>> = props => {
    const t = useTriton();
    const isCompactLayout = props.compact ?? false;
    const columns = props.columns ?? 2;
    return (
        <StyledIndicatorContainer
            isCompactLayout={isCompactLayout}
            children={props.children}
            columns={columns}
            t={t}
        />
    );
}
