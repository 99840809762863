import React from "react";
import styled from "styled-components";
import {Entity} from "../../ecs/entity/Entity";

const StyledAzypodIndicator = styled.div`
  // width: auto;
  width: 60px;
  
  aspect-ratio: 1 / 1 !important;
  border-radius: 10%;
  // border-radius: 8px;
  // border-radius: 100px;
  box-shadow: rgb(33, 38, 45) 0 0 0 1px;
  background-color: rgb(1, 4, 9);
  position: relative;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  --pipehint-width: 2px;
  cursor: pointer;

  .valve-pipe-hint {
    --pipehint-overflow: 0px;
    width: 100%;
    background-color: rgb(88, 166, 255);
    height: calc(var(--pipehint-width) + var(--pipehint-overflow));
    z-index: 2;
  }

  .valve-pointer-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    .valve-pointer {
      width: 100%;
      height: var(--pipehint-width);
      background-color: rgba(201, 209, 217, 0.1);
      // background-color: rgb(88, 166, 255);
      // border-radius: 25px;
      transition: all 1s ease-in-out;
      // transition: all 1.5s;
      // transition-timing-function: cubic-bezier(0.250, 0.250, 0.645, 1.295);

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      
      .pointer {        
        z-index: 10;
        height: 100%;
        min-width: 5px;
        width: 50%;
        background-color: #ffdf60;
        transition: width 4s ease-in-out;
      }
    }
  }
  
  .requested-indicator-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: calc(100% - 20%);
    
    &:before {
      content: '';
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      position: absolute;
      width: 100%;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      box-shadow: rgb(33, 38, 45) 0 0 0 1px;
    }
    
    .requested-indicator {
      // background-color: #d727e0;
      transition: all 1s ease-in-out;
      // width: 80px;
      width: 100%;
      height: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .requested-indicator-pointer {
        transition: all 1s ease-in-out;
        background-color: rgb(33, 38, 45);
        width: 9px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
      }
    }
  }
  
  .indicator-center-pointer {
    position: absolute;
    align-self: center;
    justify-self: center;
    background-color: rgb(33, 38, 45);
    width: 9px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    z-index: 20;
  }
`;

export type AzypodIndicatorProps = {
    actualAngle: number,
    requestedAngle: number,
    shaftKSU: number

    onClick?: () => void
}

export const AzypodIndicator: React.FC<AzypodIndicatorProps> = props => {
    const actualAngle = props.actualAngle;
    const requestedAngle = props.requestedAngle;
    const isDeviating = actualAngle !== requestedAngle;
    const ksu = props.shaftKSU;
    // ksu normalized (%)
    const ksuNormalized = Math.min(ksu, 100);
    const isRotating = ksu !== 0;

    return (
        <StyledAzypodIndicator onClick={props.onClick}>

            <div className={"indicator-center-pointer"}/>

            <div className={"valve-pipe-hint"}/>


            <div className={"requested-indicator-wrapper"} children={
                <div className={"requested-indicator"} style={{
                    transform: `rotate(${requestedAngle - 90}deg)`
                }} children={
                    <div className={"requested-indicator-pointer"} style={{
                        backgroundColor: isDeviating ? "#9148e1" : "rgb(30, 31, 34)"
                    }}/>
                }/>
            }/>



            <div className={"valve-pointer-holder"} children={
                <div className={"valve-pointer"} style={{
                    transform: `rotate(${actualAngle - 90}deg)`
                }} children={
                    <div className={"pointer"} style={{
                        // backgroundColor: isDeviating ? "#ffdf60" : (
                        //     (actualAngle % 90 === 0) ? "#9148e1" : (
                        //         isRotating ? "rgb(88, 166, 255)" : "#f14234"
                        //     )
                        // ),

                        backgroundColor: isDeviating ? "#ffdf60" : (
                            !isRotating ? "#f14234" :
                                (actualAngle % 90 === 0) ? "#9148e1" :
                                    ("rgb(88, 166, 255)")
                        ),
                        width: `${ksuNormalized}%`
                    }}/>
                }/>
            }/>


            <div className={"valve-pipe-hint"}/>
        </StyledAzypodIndicator>
    );
}
