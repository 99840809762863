import React, {useState} from "react";
import styled from "styled-components";
import {Entity} from "../../ecs/entity/Entity";

const StyledClutchIcon = styled.div`
  --shaft-width: 4px;
  --clutch-plate-width: 50%;
  --transition-duration: .5s;
  cursor: pointer;
  box-shadow: rgb(33, 38, 45) 0 0 0 1px;
  background-color: rgb(1, 4, 9);
  border-radius: 8px;
  aspect-ratio: 1 / 1 !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .clutch-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    height: max-content;
    align-items: center;

    .clutch-component {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      .shaft-end {
        width: var(--shaft-width);
        height: 50px;
        background-color: rgba(201, 209, 217, 0.1);
      }

      .clutch-plate {
        width: var(--clutch-plate-width);
        height: 2px;
        background-color: rgba(201, 209, 217, 0.1);
        transition: background-color var(--transition-duration) ease-in-out;
      }
    }

    .clutch-gap {
      width: var(--clutch-plate-width);
      transition: height var(--transition-duration) ease-in-out;
      height: 0;
      flex-shrink: 0;
      // background-color: rgba(255, 223, 96, 0.1);

      &[data-engaged=false] {
        // height: 50% !important;
        height: 10px !important;
      }
    }
  }
`;

export type ClutchIconProps = {
    entity: Entity
}

export const ClutchIcon: React.FC<ClutchIconProps> = props => {
    const isEngaged = props.entity?.state?.engaged ?? false;

    const handleIconClick = () => {
        props.entity?.setState(prevState => ({
            ...prevState,
            engaged: !prevState.engaged
        }));
    };

    return (
        <StyledClutchIcon style={{ width: "auto" }} onClick={handleIconClick}>
            <div className={"clutch-wrapper"}>
                <div className={"clutch-component engine-shaft"}>
                    <div className={"shaft-end"}/>
                    <div className={"clutch-plate"} style={{
                        // backgroundColor: isEngaged ? "rgb(88, 166, 255)" : "rgba(201, 209, 217, 0.1)"
                        backgroundColor: isEngaged ? "rgb(88, 166, 255)" : "rgb(255,223,96)"
                    }}/>
                </div>
                <div className={"clutch-gap"} data-engaged={isEngaged}/>
                <div className={"clutch-component driving-shaft"}>
                    <div className={"clutch-plate"} style={{
                        backgroundColor: isEngaged ? "rgb(88, 166, 255)" : "rgb(255,223,96)"
                    }}/>
                    <div className={"shaft-end"}/>
                </div>
            </div>
        </StyledClutchIcon>
    );
}
