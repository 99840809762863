import React, {useRef} from "react";
import {FormikProps} from "formik";
import styled from "styled-components";

const StyledRoundedFormikBaseInput = styled.input`
  width: 100%;
  height: 46px;
  color: black;
  background-color: white;
  border-radius: 500px;
  padding-left: 22px !important;
  padding-right: 22px;
  border: none;
  outline: none;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border: none;
  }
`;

export type RoundedFormikBaseInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    fp: FormikProps<any>,
    name: string,
}

export const RoundedFormikBaseInput = React.forwardRef<HTMLInputElement, RoundedFormikBaseInputProps>((props, ref) => {
    return (
        <StyledRoundedFormikBaseInput
            ref={ref}
            onChange={props.fp.handleChange}
            value={props.fp.values[props.name]}
            {...props as React.InputHTMLAttributes<HTMLInputElement>}
            spellCheck={false}
        />
    );
})
