import React, {useContext} from "react";
import {Modal} from "../../triton/components/dialogs/Modal";
import {TagListConfigurator} from "./TagListConfigurator";
import {ButtonBase} from "../../triton/components/buttons/ButtonBase";
import {Formik} from "formik";
import {FormElement} from "../../triton/components/forms/FormElement";
import {FormikSingleLineInput} from "../../triton/components/forms/FormikSingleLineInput";
import {ErrorMessage} from "../../triton/components/forms/ErrorMessage";
import {FormikTextArea} from "../../triton/components/forms/FormikTextArea";
import {px} from "../../base/logic/style/DimensionalMeasured";
import {Checkbox} from "@mui/material";
import {FormikFormCheckbox} from "./FormikFormCheckbox";
import {ArdaiAPIContext} from "../webapi/WebAPI";
import {FormDivider} from "../../triton/components/forms/FormDivider";
import {FormikAdvancedFormCheckbox} from "./FormikAdvancedFormCheckbox";
import {StyledVariableSlider, VariableSlider} from "../../imageSorter/sdInterface/VariableSlider";
import {FormikBasicSlider} from "../../triton/components/forms/FormikBasicSlider";
import {BasicSingleSelect} from "../../triton/components/forms/BasicSingleSelect";
import {FormikSingleSelectInput} from "../../triton/components/forms/FormikSingleSelectInput";
import {MainTypography} from "../../triton/components/typography/MainTypography";
import {Text} from "../../triton/components/typography/Text";
import {DescriptiveTypography} from "../../triton/components/typography/DescriptiveTypography";
import {ButtonModalCompound} from "./ButtonModalCompound";
import {StyledModal} from "./StyledModal";
import {Grid} from "../../triton/components/layouts/Grid";
import {ExifTagConfig} from "../webapi/export/ExifTagConfig";
import {ExifTag} from "../webapi/export/ExifTag";
import {MetaBasicEntry} from "./MetaBasicEntry";
import {IconButton} from "./IconButton";
import {
    AddRounded,
    FolderRounded,
    FormatListBulletedRounded, MoreHorizRounded,
    RefreshRounded, ShortTextRounded,
    SquareRounded,
    TextFieldsRounded
} from "@mui/icons-material";
import {Menu} from "./Menu";
import {MenuButton} from "./MenuButton";
import {ListItem} from "../_/ListItem";
import {KVEntryDisplay} from "./KVEntryDisplay";

export type ExportConfigDialogProps = {
    open: boolean,
    onClose: () => void
}

export const ExportConfigDialog: React.FC<ExportConfigDialogProps> = props => {
    const api = useContext(ArdaiAPIContext);

    return (
        <Formik
            initialValues={{
                fileName: "",
                removeMeta: true,
                applySubmittedTag: true,
                enableSmartCompression: true,
                quality: 92,
                exifConf: {
                    tags: new Array<ExifTag>()
                } as ExifTagConfig
            }}
            onSubmit={async (values) => {
                await api.exportManager.exportImages({
                    applySubmittedTag: values.applySubmittedTag,
                    fileTitle: values.fileName,
                    removeMetadata: values.removeMeta,
                    selectedImages: api.state.selectedImages,
                    compressionQuality: values.quality,
                    enableCompression: values.enableSmartCompression
                });
            }}
            children={fp => {
                return (
                    <Modal
                        title={"Prepare submission"}
                        open={props.open}
                        onClose={() => props.onClose()}
                        onSubmit={(e) => fp.handleSubmit(e)}
                        children={
                            <div style={{
                                display: "grid",
                                gap: "16px"
                            }}>
                                <FormElement caption={"Generate auto filename if field left blank"} title={"Filename"} children={
                                    <>
                                        <FormikSingleLineInput autoFocus formikProps={fp} name={"fileName"}/>
                                        <ErrorMessage formikProps={fp} name={"fileName"}/>
                                    </>
                                }/>

                                <div style={{
                                    display: "grid",
                                    gap: "8px"
                                }}>
                                    <FormDivider title={"Export config"}/>

                                    <FormikAdvancedFormCheckbox
                                        formik={fp}
                                        title={"Remove metadata"}
                                        name={"removeMeta"}
                                        helpText={"Removes the exif metadata from all png files"}
                                    />

                                    <FormikAdvancedFormCheckbox
                                        formik={fp}
                                        title={"Apply submitted tag"}
                                        name={"applySubmittedTag"}
                                        helpText={"Applies the 'submitted'-tag to all submitted images"}
                                    />

                                    <FormikSingleSelectInput
                                        placeholder={"Image format (Default: image/png)"}
                                        title={"Image format"}
                                        formikProps={fp}
                                        name={"format"}
                                        options={["image/png", "image/jpeg", "image/webp"].map(s => ({
                                            text: s,
                                            id: s
                                        }))}
                                    />
                                </div>

                                <div style={{
                                    display: "grid",
                                    gap: "8px"
                                }}>
                                    <FormDivider title={"Exif metadata (not implemented)"}/>

                                    <FormikAdvancedFormCheckbox
                                        formik={fp}
                                        title={"Include custom exif data"}
                                        name={"removeMeta"}
                                        helpText={"Removes the exif metadata from all png files"}
                                    />

                                    <Grid children={fp.values.exifConf.tags.map(t => (
                                        <KVEntryDisplay key={t.key} title={t.key} value={t.value} enableCopy={false}/>
                                    ))}/>

                                    <div style={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(2, 1fr)",
                                        gap: "8px",
                                        width: "100%"
                                    }}>
                                        <ButtonModalCompound button={<ButtonBase baseProps={{ type: "button", style: { width: "100%" } }} text={"Add exif-entry"}/>} modalContent={ctx => (
                                            <Formik initialValues={{ key: "", value: "" }} onSubmit={(v, h) => {
                                                const exifConf = fp.values.exifConf;
                                                exifConf.tags.push({
                                                    key: v.key,
                                                    value: v.value,
                                                    shouldEncrypt: false
                                                });
                                                fp.setFieldValue("exifConf", exifConf);
                                            }} validate={v => {
                                                const e: Partial<typeof v> = {};
                                                if (v.key.trim().length === 0) e.key = "Exif entry cannot have a blank key";
                                                return e;
                                            }} children={fp => (
                                                <StyledModal title={"Add exif-entry"} icon={<TextFieldsRounded/>} onClose={ctx.close} children={
                                                    <Grid>
                                                        <FormElement>
                                                            <FormikSingleLineInput
                                                                name={"key"}
                                                                placeholder={"Key"}
                                                                autoFocus
                                                                formikProps={fp}
                                                            />
                                                            <ErrorMessage formikProps={fp} name={"key"}/>
                                                        </FormElement>

                                                        <FormikSingleLineInput
                                                            name={"value"}
                                                            placeholder={"Value"}
                                                            formikProps={fp}
                                                        />
                                                    </Grid>
                                                } footer={
                                                    <Grid style={{ width: "100%" }} gTC={"min-content auto min-content"}>
                                                        <IconButton children={<AddRounded/>} size={"small"} tooltip={"Add and keep open"} onClick={() => {
                                                            fp.handleSubmit();
                                                        }}/>
                                                        <ButtonBase text={"Add"} baseProps={{
                                                            type: "button",
                                                            onClick: (e) => {
                                                                fp.handleSubmit(e);
                                                                ctx.close();
                                                            }
                                                        }}/>
                                                        <Menu children={
                                                            <MenuButton text={"Reset form"} icon={<RefreshRounded/>} onSelect={() => {
                                                                fp.resetForm({
                                                                    values: {
                                                                        key: "",
                                                                        value: ""
                                                                    }
                                                                });
                                                            }}/>
                                                        }/>
                                                    </Grid>
                                                }/>
                                            )}/>
                                        )}/>
                                        <ButtonBase text={"Clear"} baseProps={{
                                            type: "button",
                                            onClick: (e) => {
                                                const exifConf = fp.values.exifConf;
                                                exifConf.tags = [];
                                                fp.setFieldValue("exifConf", exifConf);
                                            }
                                        }}/>
                                    </div>



                                </div>

                                <div style={{
                                    display: "grid",
                                    gap: "8px"
                                }}>
                                    <FormDivider title={"Compression settings"}/>

                                    {/* <DescriptiveTypography text={"Image quality if the requested type is image/jpeg or image/webp"}/> */}

                                    <FormikAdvancedFormCheckbox
                                        formik={fp}
                                        title={"Enable compression"}
                                        name={"enableSmartCompression"}
                                        helpText={"Applies the 'submitted'-tag to all submitted images"}
                                    />

                                    <FormikBasicSlider defaultValue={92} name={"quality"} fp={fp}/>
                                </div>
                            </div>
                        }
                        footer={
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                gap: "8px",
                                width: "100%"
                            }}>
                                <ButtonBase text={"Submit"} baseProps={{
                                    type: "button",
                                    onClick: (e) => fp.handleSubmit(e)
                                }}/>
                                <ButtonBase text={"Cancel"} baseProps={{
                                    type: "button",
                                    onClick: () => props.onClose()
                                }}/>
                            </div>
                        }
                    />
                );
            }
        }/>
    );
}
