import {createBridge, ReactBackend} from "../../../ReactAPIBridgeUtils";
import {NodeAppBackendState} from "./NodeAppBackendState";
import {useContext} from "react";
import {MainTabManager} from "./tabs/MainTabManager";
import {StandaloneObservable} from "../../../ardai/webapi/pubsub/StandaloneObservable";
import {NodeAddBackendEventTypes} from "./NodeAddBackendEventTypes";

export class NodeAppBackend extends ReactBackend<NodeAppBackendState> {

    public static generateInitialState = (): NodeAppBackendState => ({});

    public readonly events = new StandaloneObservable<NodeAddBackendEventTypes>();

    public readonly tabs = new MainTabManager(this);

    public init() {
        this.tabs.init();
    }
}

export const nodeAppBackendBridge = createBridge<NodeAppBackendState, NodeAppBackend>({
    state: NodeAppBackend.generateInitialState(),
    backend() {
        return new NodeAppBackend();
    }
});

export function useNodeAppBackend(): NodeAppBackend {
    return useContext(nodeAppBackendBridge.backendCtx);
}
