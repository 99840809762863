import {Triton, triton} from "../../triton/Triton";
import {portfolioV1DarkBaseTheme, portfolioV1LightBaseTheme} from "./PortfolioV1DarkBaseTheme";
import {TritonDefaults} from "../../triton/TritonDefaults";

export namespace PortfolioV1Setup {
    export function setup() {
        setupTriton();
    }
}

function setupTriton() {
    const t = triton();
    t.registerThemes(
        // Main themes
        portfolioV1DarkBaseTheme,
        portfolioV1LightBaseTheme,

        // Optional themes
        TritonDefaults.crimsonFutureTheme,
        TritonDefaults.blueFutureTheme,
    );
    t.switchTheme(portfolioV1LightBaseTheme.id);
}
