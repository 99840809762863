import React from "react";
import {Path} from "../../webapi/fs/Path";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {FlatIconButton} from "../FlatIconButton";
import {DefaultButton} from "../DefaultButton";
import {Breadcrumb} from "./Breadcrumb";
import {AnimatePresence, motion} from "framer-motion";
import {HomeRounded} from "@mui/icons-material";
import {BreadcrumbSeparator} from "./BreadcrumbSeparator";

const StyledBreadcrumbsNavigation = styled.div`
  display: flex;
  gap: 0;
  align-items: center;
  
  .breadcrumb-separator-compound {
    display: flex;
    flex-direction: row;
    gap: 0;
    align-items: center;
  }
  
  .way-of-breadcrumbs {
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    gap: 0;
    align-items: center;
  }
`;

export type BreadcrumbsNavigationProps = {
    path: Array<string>,
    cd: (path: Array<string>) => void,
    goHome?: () => void,
    showHome?: boolean,
    isAtHome?: boolean
}

export const BreadcrumbsNavigation: React.FC<BreadcrumbsNavigationProps> = props => {
    return (
        <StyledBreadcrumbsNavigation>
            { props.showHome && (
                <FlatIconButton
                    children={<HomeRounded fontSize={"small"}/>}
                    deactivated={props.isAtHome ?? props.path.length === 0}
                    onClick={() => props.goHome?.()}
                />
            ) }

            <div className={"way-of-breadcrumbs"}>
                <AnimatePresence children={
                    props.path.map((folder, depth, arr) => {
                        const shouldShowSeparator = depth !== 0 || props.showHome, isLast = depth === arr.length - 1;

                        return (
                            <motion.div
                                key={depth}
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0 }}
                                className={"breadcrumb-separator-compound"}
                            >
                                { shouldShowSeparator && (
                                    <BreadcrumbSeparator/>
                                ) }
                                <Breadcrumb text={folder} onClick={() => {
                                    if (isLast) return;
                                    props.cd(arr.slice(0, depth + 1));
                                }}/>
                            </motion.div>
                        );
                    })
                }/>
            </div>


        </StyledBreadcrumbsNavigation>
    );
}
