import {ExportBlobAggregate} from "./ExportBlobAggregate";
import {BlobRevisionPipelineStep} from "./BlobRevisionPipelineStep";

export class JPEGCompressionStep implements BlobRevisionPipelineStep {

    async operate(operand: ExportBlobAggregate): Promise<ExportBlobAggregate> {
        // Guard cases
        if (!(operand.request.enableCompression ?? false)) return operand

        const req = operand.request;
        console.trace(`Applying compression to output image (Quality: ${req.compressionQuality ?? "auto"}%)`)
        const map = await createImageBitmap(operand.blob);
        operand.blob = await operand.api.compressionManager.resize(
            map,
            req.outputType,
            req.compressionQuality
        );
        return operand
    }
}
