import React from "react";
import {useSerializedState} from "./SerializedStateHook";
import styled from "styled-components";
import {TabBar} from "../../components/TabBar";
import {TagRounded} from "@mui/icons-material";
import {TabConfig} from "../../data/TabConfig";

const StyledMultiTray = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgb(16, 16, 22);
  padding: 8px;
  border-radius: 8px;
  margin: 0;
  display: grid;
  grid-template-rows: min-content auto;
  position: relative;

  .tray-header {
    width: 100%;
    overflow-y: scroll;
  }

  .tray-body-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow: scroll;

    .tray-body {
      height: 100%;
      position: relative;
    }
  }
`;

export type MultiTrayProps = {
    name: string,
    defaultTray?: string,
    trays: Map<string, TrayRenderer>,
    loadingTrayRenderer?: TrayRenderer,
    noRendererTrayRenderer?: TrayRenderer,
    tabHeaders?: TrayTabHeaderConfig
}

export type MultiTraySerializableState = {
    activeTray: string
}

export type TrayRenderer = (ctx: MultiTrayContext) => JSX.Element;

export interface MultiTrayContext {
    switchTray: (tray: string) => void
}

export type TrayTabHeaderConfig = Array<TabConfig>

/**
 * TODO: Implement
 * @param ctx
 */
export const loadingTrayRenderer: TrayRenderer = ctx => {
    return (
        <></>
    );
}

/**
 * TODO: Implement
 * @param ctx
 */
export const noRendererTrayRenderer: TrayRenderer = ctx => {
    return (
        <>no renderer!</>
    );
}

/**
 * TODO: Write documentation -> No-use clausal
 */
const loadingActiveTrayName = "_MultiTray-fallback-loading-active-tray-name";

export const MultiTray: React.FC<MultiTrayProps> = props => {
    const stateKey = `tray-${props.name}`;
    const tabHeaders = props.tabHeaders ?? [];

    const [state, setState, ctx] = useSerializedState<MultiTraySerializableState>(stateKey, {
        activeTray: props.defaultTray ?? loadingActiveTrayName
    });

    const switchTray = (trayKey: string) => setState(prevState => {
        prevState.activeTray = trayKey
        return prevState;
    });

    let trayRenderer = ctx.loading ? (props.loadingTrayRenderer ?? loadingTrayRenderer) : props.trays?.get(state.activeTray) ?? (props.noRendererTrayRenderer ?? noRendererTrayRenderer);
    const tray = trayRenderer({
        switchTray: switchTray
    });

    return (
        <StyledMultiTray>
            <div className={"tray-header"} children={
                <TabBar tabs={tabHeaders} activeBar={state.activeTray} onTabChange={switchTray}/>
            }/>
            <div className={"tray-body-wrapper"} children={
                <div className={"tray-body"} children={tray}/>
            }/>
        </StyledMultiTray>
    );
}
