import React, {useState} from "react";
import styled from "styled-components";
import {SDPromptField} from "../../imageSorter/sdInterface/SDPromptField";
import {
    BackspaceRounded,
    BugReportRounded, DeleteRounded, LandslideRounded,
    PlayArrowRounded,
    Refresh,
    RefreshRounded,
    StopRounded, SwipeUpRounded
} from "@mui/icons-material";
import {IconButton} from "../components/IconButton";
import {SDPromptEngine} from "../../imageSorter/sdInterface/SDPromptEngine";
import {SRCReader} from "./SRCReader";
import {BooleanContext} from "../../triton/components/BooleanContext";
import {SwipeableDrawer} from "@mui/material";

const StyledTestScriptingView = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto min-content;
  gap: 8px;
  overflow: hidden;
  
  .scripting-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
  
  .scripting-main {
    overflow-y: scroll;
  }
`;

const def = `
var a = 1.5,
$a, test, 'Hey, $name', "my name is a", :date, @test, @test(@ard(chris, winter, clothes)), @ai(100, ard, { 
    @test, @pick(winter, summer, @daytime, <
        rnd: :sytem_rnd,
        outdir: $system.dir, 
        sampler: euler a,
        fastGPU: true,
        steps: 50,
        size: <
            base: 2*9,
            ratio: 9..16
        >
    >) 
}), hey,
:js {
    console.log("Hello world")
},
@character
        `;

export const TestScriptingView: React.FC = props => {
    const [state, setState] = useState({
        src: def
    });

    return (
        <StyledTestScriptingView>
            <div className={"scripting-header"}>
                <IconButton
                    children={<PlayArrowRounded style={{ color: "mediumseagreen" }}/>}
                    tooltip={"Run"}
                    size={"small"}
                    onClick={() => {
                        window.alert(JSON.stringify(
                            new SDPromptEngine().parse(state.src)
                        ))
                    }}
                />

                <IconButton
                    children={<RefreshRounded/>}
                    // tooltip={"Reset"}
                    size={"small"}
                    onClick={() => {
                        setState(prevState => ({
                            ...prevState,
                            src: def
                        }))
                    }}
                />

                <IconButton
                    children={<DeleteRounded/>}
                    // tooltip={"Clear"}
                    size={"small"}
                    onClick={() => {
                        setState(prevState => ({
                            ...prevState,
                            src: ""
                        }))
                    }}
                />

                <IconButton
                    children={<BugReportRounded/>}
                    // tooltip={"Generate parser tree"}
                    size={"small"}
                    onClick={() => {

                        function shard(s: string, sep: string = ","): Array<string> {
                            let shards: Array<string> = [], fifo: Array<string> = [], buf = "";
                            const top = () => fifo[fifo.length - 1];
                            const checkBr = (e: string, p: [string, string]) => top() === p[0] && e === p[1];
                            for (const e of s) {
                                if (e === sep && fifo.length === 0) {
                                    shards.push(buf);
                                    buf = "";
                                    continue;
                                }
                                buf += e;
                                if (checkBr(e, ["(", ")"]) ||
                                    checkBr(e, ["{", "}"]) ||
                                    checkBr(e, ["[", "]"]) ||
                                    checkBr(e, ["<", ">"]) ||
                                    checkBr(e, ["'", "'"]) ||
                                    checkBr(e, ["\"", "\""])
                                ) fifo.pop();
                                else if ("([{<'\"".includes(e)) fifo.push(e);
                            }
                            if (buf.length > 0) shards.push(buf);
                            return shards.map(s => s.trim());
                        }

                        function collapse(s: string): Array<any> {
                            const shards = shard(s);
                            return shards.map(e => {
                                // Advanced syntax trees
                                if (e.startsWith("@")) return collapseMixin(e)
                                else if (e.startsWith(":")) return ["i_fn", e.slice(1)];
                                else if (e.startsWith("fn")) return ["u_fn_dec", e];
                                else if (e.startsWith("var")) return collapseVariableDeclaration(e);
                                else if (e.startsWith("{") && e.endsWith("}")) return ["lambda", collapse(e.slice(1, -1))];
                                else if (e.startsWith("[") && e.endsWith("]")) return collapseArray(e.slice(1, -1));
                                else if (e.startsWith("<") && e.endsWith(">")) return collapseObject(e.slice(1, -1));
                                else if (e.startsWith("\"") && e.endsWith("\"")) return ["literal", e.slice(1, -1)];
                                else if (e.startsWith("'") && e.endsWith("'")) return ["literal:template", e.slice(1, -1)];

                                // Simple values
                                else if (e === "true") return ["boolean", true];
                                else if (e === "false") return ["boolean", false];
                                else if (/^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/g.test(e)) return ["number", Number(e)];
                                else if (e.startsWith("$")) return ["var:get", e.slice(1)];

                                // Just literals
                                else return ["literal", e];
                            });
                        }

                        function collapseMixin(s: string): Array<any> {
                            s = s.slice(1);
                            if (!s.includes("(")) return ["mixin:pure", s, undefined] // s <=> mixin name
                            let mxName = "";
                            const mixinParamCode = new SRCReader(s).also(r => mxName = r.nLookAhead(s => s === "(")).remainder().slice(1, -1);
                            return ["mixin", mxName, collapse(mixinParamCode)];
                        }

                        function collapseObject(s: string): Array<any> {
                            const elements = collapse(s);
                            const parsedElements = elements.map(e => {
                                let k = "";
                                const v = new SRCReader(e[1]).also(r => k = r.nLookAhead(s => s === ":")).remainder().slice(1);
                                return ["object:entry", k, collapse(v)]
                            });
                            return ["object", parsedElements];
                        }

                        function collapseArray(s: string): Array<any> {
                            return ["array", collapse(s)];
                        }

                        function collapseVariableDeclaration(s: string): Array<any> {
                            s = s.slice(3).trim();
                            let name = "";
                            const v = new SRCReader(s).also(r => name = r.nLookAhead(s => s === "=")).remainder().slice(1);
                            return ["var:declare", name.trim(), collapse(v)]
                        }

                        setState(prevState => ({
                            ...prevState,
                            src: JSON.stringify(
                                collapse(state.src), null, 2
                            )
                        }))
                    }}
                />


                <BooleanContext children={(bool, setBool) => (
                    <>
                        <IconButton
                            children={<SwipeUpRounded/>}
                            size={"small"}
                            onClick={() => setBool(!bool)}
                        />

                        <SwipeableDrawer
                            open={bool}
                            onOpen={() => setBool(true)}
                            onClose={() => setBool(false)}
                            anchor={"bottom"}
                            sx={{
                                ".MuiDrawer-paper": {
                                    backgroundColor: "transparent"
                                }
                            }}
                            children={
                                <div children={<SDPromptField/>} style={{
                                    height: "calc(100vh - 250px)",
                                    borderRadius: 10,
                                    overflow: "hidden"
                                }}/>
                            }
                        />
                    </>
                )}/>

            </div>
            <div className={"scripting-main"}>
                <SDPromptField value={state.src} onChange={(value, ev) => {
                    setState(prevState => ({
                        ...prevState,
                        src: value ?? ""
                    }))
                }}/>
            </div>
            <span></span>
        </StyledTestScriptingView>
    );
}

function randomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
