import {FC} from "react";
import styled from "styled-components";
import {PrimaryDisplayTypography} from "../../typography/PrimaryDisplayTypography";
import {SecondaryTextTypography} from "../../typography/SecondaryTextTypography";
import {DefaultButton} from "../../../../../triton/components/buttons/DefaultButton";
import {LinkDisplayTypography} from "../../typography/LinkDisplayTypography";
import {Dot} from "../../../../../ardai/components/Dot";

const StyledMainNavigationHeader = styled.div`
  width: 100%;
  padding: 25px 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  
  background-color: navy;
  // background-color: orange;
  // background: linear-gradient(to right, orange, #000);

  .header-left, .header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
  
  .header-left {
    flex-grow: 1;
    flex-shrink: 1;
  }
  
  .header-right {
    flex-shrink: 0;
  }
`;

export const MainNavigationHeader: FC = props => {
    return (
        <StyledMainNavigationHeader>
            <div className={"header-left"}>
                <div style={{
                    height: 70,
                    aspectRatio: "1 / 1",
                    backgroundColor: "white"
                }}/>

                <div>
                    <LinkDisplayTypography text={"Development"}/>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 16
                    }}>
                        <SecondaryTextTypography text={"This is very cool"}/>
                        <Dot/>
                        <SecondaryTextTypography text={"This is very cool"}/>
                        <Dot/>
                        <SecondaryTextTypography text={"This is very cool"}/>
                    </div>
                </div>
            </div>
            <div className={"header-right"}>
                <DefaultButton text={"More"}/>
                <DefaultButton text={"Contact"}/>
            </div>
        </StyledMainNavigationHeader>
    );
}
