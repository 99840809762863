import {SyntheticEventInfo} from "./SyntheticEventInfo";

export function createSyntheticEvent<T = any>(name: string): SyntheticEventInfo<T> {
    return {
        name,
        create(detail: T, eventInitDictOverwrites: CustomEventInit<T> = {}) {
            return new CustomEvent<T>(name, {
                detail,
                ...eventInitDictOverwrites
            });
        }
    }
}
