import React, {useContext, useEffect, useRef} from "react";
import {ArdaiAPIContext} from "../../WebAPI";
import {FSManagerEvents} from "../FSManager";
import {IDisk} from "../IDisk";

export type UseDiskConfig = {
    events: Array<FSManagerEvents>
}

const defaultUseDiskConfig: UseDiskConfig = {
    events: [FSManagerEvents.DISK_STATE_UPDATED]
};

export const useDisk: (cfg?: Partial<UseDiskConfig>) => [IDisk] = (cfg = defaultUseDiskConfig) => {
    // Force rerender logic
    const [, updateState] = React.useState<{}>();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const api = useContext(ArdaiAPIContext);
    const fs = api.FSManager;
    const useDiskRef = useRef<{ disk: IDisk, cfg: UseDiskConfig }>({
        disk: fs.disk,
        cfg: { ...cfg, ...defaultUseDiskConfig }
    });

    useEffect(() => api.FSManager.observe(...useDiskRef.current.cfg.events).on(() => {
        useDiskRef.current.disk = fs.disk;
        forceUpdate();
    }).destructor, []);

    useEffect(() => {
        useDiskRef.current.cfg = { ...cfg, ...defaultUseDiskConfig };
    }, [cfg])

    return [useDiskRef.current.disk];
}
