import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import React, {CSSProperties, FC} from "react";
import {Triton, triton} from "../../../../triton/Triton";
import {Tag} from "../../../../ardai/components/Tag";
import {ButtonModalCompound} from "../../../../ardai/components/ButtonModalCompound";
import {
    AbcRounded,
    DragHandleRounded, DragIndicatorRounded, HelpRounded, MenuOpenRounded, MoreVertRounded,
    NumbersRounded, RefreshRounded,
    SettingsRounded,
    SwitchRightRounded,
    ToggleOnRounded
} from "@mui/icons-material";
import {StyledModal} from "../../../../ardai/components/StyledModal";
import {Serializable} from "../../../../../std/Serializable";
import {Menu} from "../../../../ardai/components/Menu";
import {MenuButton} from "../../../../ardai/components/MenuButton";
import {DragControls, Reorder, useDragControls} from "framer-motion"
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {StringField} from "../../../../ardai/testing/StringField";
import _ from "lodash";
import {SingleLineInput} from "../../../../triton/components/forms/SingleLineInput";
import {BasicSingleSelect} from "../../../../triton/components/forms/BasicSingleSelect";
import {px} from "../../../../base/logic/style/DimensionalMeasured";
import {useTriton} from "../../../../triton/TritonHooks";
import styled from "styled-components";
import {DefaultCharacterSymbols} from "../../DefaultCharacterSymbols";

export type NodeDynamicConfigState = {
    definitions: Array<ConfigLineDefinition>
}

type NodeDynamicConfigType = Node<NodeDynamicConfigState>;

/**
 * Definition syntax
 * <type>:<pin>:<label>:<initial-value>
 * e.g. b:e:enabled:false
 */
export const NodeDynamicConfig: NodeSetupInfo = {
    label: "NodeDynamicConfig",
    classname: "input.dynamic-config",
    parameterConfig: [],
    factory: parameters => {

        return (
            new Node<NodeDynamicConfigState>({
                id: v4(),
                classname: "input.dynamic-config",
                label: "cfg",
                defOutPins: ["s"],
                state: {
                    definitions: []
                },
                init: function () {

                },
                customRenderer: node => (
                    <NodeDynamicConfigComponent
                        node={node}
                    />
                )
            })
        );
    }
}

type ConfigLineDefinition<
    ValueType extends Serializable = Serializable,
    ParameterType extends Serializable = Serializable
> = {
    readonly id: string,
    type: string,
    pin: string,
    initialValue: ValueType,
    label?: string,
    parameters?: ParameterType
}

const NodeDynamicConfigComponent: FC<{
    node: NodeDynamicConfigType
}> = props => {
    const { node } = props;

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            padding: "8px 0",
            gap: 8,
            width: "100%"
        }}>
            {/* cfg lines */}
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%"
            }}>
                { node.state.state.definitions.map(def => (
                    <ConfigLineComponent
                        node={node}
                        def={def}
                    />
                )) }
            </div>

            {/* cfg preferences & editor opener */}
            <ButtonModalCompound
                button={
                    <Tag applyActiveScaling tag={
                        <SettingsRounded sx={{
                            fontSize: 14
                        }}/>
                    }/>
                }
                modalContent={ctx => (
                    <ConfigSettingsModal
                        node={node}
                    />
                )}
            />
        </div>
    );
}

const StyledConfigLineComponent = styled.div<{
    t: Triton
}>`
    display: grid;
    grid-template-columns: min-content 1fr min-content 1fr;
    align-items: center;
    gap: 4px;
    width: 274px;
    height: 24px;
    border-radius: 8px;
    background-color: ${p => p.t.col("bg_menu")};
    padding: 0 8px;

    // overflow: hidden;

    .type-icon {
        color: ${p => p.t.col("fg_secondary")};
        align-items: center;
        display: flex;
        margin-right: 4px;

        svg {
            width: 18px;
            aspect-ratio: 1 / 1;
        }
    }

    input[type=text], input[type=number] {
        width: 100%;
        font-size: 12px;
        border: none;
        outline: none;
        color: ${p => p.t.col("fg_secondary")};
        background-color: transparent;
        // background-color: rgba(16, 16, 22, 0.66);
    }
`;

const ConfigLineComponent: FC<{
    node: NodeDynamicConfigType,
    def: ConfigLineDefinition
}> = props => {
    const { node, def } = props;
    const t = useTriton();

    const type = def.type;

    return (
        <StyledConfigLineComponent t={t}>

            <div className={"type-icon"} children={
                type === "string" ? (
                    <AbcRounded/>
                ) : (type === "number" ? (
                    <NumbersRounded/>
                ) : (type === "boolean" ? (
                    <ToggleOnRounded/>
                ) : (
                    <HelpRounded/>
                )))
            }/>
            {/*
            <DescriptiveTypography text={def.label} style={{
                width: "pre"
            }}/>
            */}
            <input
                type={"text"}
                placeholder={DefaultCharacterSymbols.placeholder}
                value={def.label}
                onChange={e => {
                    const value = e.currentTarget.value;
                    updateConfigLineDefinitionHelper(node, def, {
                        label: value
                    })
                }}
            />
            <DescriptiveTypography text={":"}/>
            <input
                type={type === "string" ? "text" : "number"}
                placeholder={DefaultCharacterSymbols.placeholder}
            />
        </StyledConfigLineComponent>
    );
}

const ConfigSettingsModal: FC<{
    node: NodeDynamicConfigType
}> = props => {
    const {node} = props;

    return (
        <StyledModal
            title={"Config Settings"}
            w={px(600)}
            icon={
                <SettingsRounded sx={{
                    fontSize: 14
                }}/>
            }
            children={
                <ConfigSettingsBody
                    node={node}
                />
            }
        />
    );
}

const ConfigSettingsBody: FC<{
    node: NodeDynamicConfigType
}> = props => {
    const { node } = props;

    const setDefinitions = (definitions: Array<ConfigLineDefinition>) => {
        node.state.update({
            definitions
        });
    }
    const definitions = node.state.state.definitions;

    const typeToInitialValueDict = new Map<string, Serializable>([
        ["string", ""],
        ["boolean", false],
        ["number", 0],
    ]);

    const createConfigLine = (type: string) => {
        node.state.update(prevState => {
            const id = v4();
            prevState.definitions.push({
                id: id,
                type,
                pin: id,
                initialValue: typeToInitialValueDict.get(type) ?? ""
            });
            return prevState;
        })
    }

    return (
        <div>
            <Reorder.Group
                axis={"y"}
                onReorder={setDefinitions}
                values={definitions}
                // layoutScroll
                style={{
                    // overflowY: "scroll",
                    listStyle: "none",
                    paddingLeft: 0
                }}
                children={
                    definitions.map(def => {
                        return (
                            <ConfigLineEditorReorderWrapperComponent
                                node={node}
                                def={def}
                                key={def.id}
                            />
                        );
                    })
                }
            />

            <div>
                <Menu opener={
                    <Tag
                        tag={"+"}
                        applyActiveScaling
                        onClick={e => {
                            if (!e.ctrlKey) return;
                            // Using shortcut
                            e.stopPropagation();
                            e.preventDefault();
                            createConfigLine("string")
                        }}
                    />
                }>
                    <MenuButton
                        text={"string"}
                        icon={<AbcRounded/>}
                        onSelect={() => createConfigLine("string")}
                    />
                    <MenuButton
                        text={"number"}
                        icon={<NumbersRounded/>}
                        onSelect={() => createConfigLine("number")}
                    />
                    <MenuButton
                        text={"boolean"}
                        icon={<ToggleOnRounded/>}
                        onSelect={() => createConfigLine("string")}
                    />
                </Menu>
            </div>
        </div>
    );
}

const ConfigLineEditorReorderWrapperComponent: FC<{
    node: NodeDynamicConfigType,
    def: ConfigLineDefinition,
}> = props => {
    const { node, def } = props;
    const controls = useDragControls()

    return (
        <Reorder.Item
            value={def}
            dragListener={false}
            dragControls={controls}
            children={
                <ConfigLineEditorComponent
                    dragControls={controls}
                    node={node}
                    def={def}
                />
            }
        />
    );
}

const updateConfigLineDefinitionHelper = (
    node: NodeDynamicConfigType,
    def: ConfigLineDefinition,
    updates: Partial<ConfigLineDefinition>
) => {
    node.state.update(prevState => {
        const prevStateDef = prevState.definitions.find(el => el.id === def.id);
        defUpdateLogic: {
            if (prevStateDef === undefined) break defUpdateLogic;
            const idx = prevState.definitions.indexOf(prevStateDef);
            prevState.definitions[idx] = {
                ...prevStateDef,
                ...updates
            };
        }
        return prevState;
    });
}

const ConfigLineEditorComponent: FC<{
    node: NodeDynamicConfigType,
    def: ConfigLineDefinition,
    dragControls: DragControls
}> = props => {
    const { node, def, dragControls } = props;
    const t = useTriton();

    const updateDefinition = (updates: Partial<ConfigLineDefinition>) => {
        updateConfigLineDefinitionHelper(node, def, updates);
    }

    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "3fr 2fr",
            alignItems: "center",
            width: "100%",
            gap: 4,
            paddingBottom: 4
        }}>
            <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                alignItems: "center",
                width: "100%",
                gap: 4
            }}>
                <BasicSingleSelect
                    name={"type"}
                    placeholder={"type"}
                    selected={def.type}
                    options={[
                        {id: "string", text: "string"},
                        {id: "number", text: "number"},
                        {id: "boolean", text: "boolean"}
                    ]}
                    onSelect={type => {
                        updateDefinition({
                            type
                        })
                    }}
                />
                <SingleLineInput
                    placeholder={"pin"}
                    value={def.pin}
                    onChange={(e, pin) => updateDefinition({
                        pin
                    })}
                />
                <SingleLineInput
                    placeholder={"label"}
                    value={def.label}
                    onChange={(e, label) => updateDefinition({
                        label
                    })}
                />
            </div>

            <div style={{
                display: "grid",
                alignItems: "center",
                width: "100%",
                gap: 4,
                gridTemplateColumns: "auto min-content"
            }}>
                {
                    def.type === "string" ? (
                        <SingleLineInput
                            placeholder={"value"}
                            value={String(def.initialValue)}
                            onChange={(e, initialValue) => updateDefinition({
                                initialValue
                            })}
                        />
                    ) : (
                        def.type === "number" ? (
                            <SingleLineInput
                                baseProps={{
                                    type: "number"
                                }}
                                placeholder={"value"}
                                value={Number(def.initialValue)}
                                onChange={(e, initialValue) => updateDefinition({
                                    initialValue
                                })}
                            />
                        ) : (
                            <BasicSingleSelect
                                name={"value"}
                                placeholder={"value"}
                                selected={def.initialValue ? "true" : "false"}
                                disableSearchbar
                                options={[
                                    { id: "true", text: "False" },
                                    { id: "false", text: "True" }
                                ]}
                                onSelect={initialValue => {
                                    updateDefinition({
                                        initialValue: initialValue === "true"
                                    })
                                }}
                            />
                        )
                    )
                }

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: 4
                }}>
                    <Menu opener={
                        <MoreVertRounded
                            sx={{
                                fontSize: 16,
                                marginBottom: "-1px",
                                color: t.col("fg_muted"),
                                cursor: "pointer",
                                ["&:hover"]: {
                                    filter: "brightness(1.25)"
                                }
                            }}
                        />
                    }>
                        <MenuButton
                            icon={<RefreshRounded/>}
                            text={"Reset"}
                            onSelect={() => updateDefinition({
                                pin: "",
                                label: ""
                            })}
                        />
                    </Menu>
                    <DragIndicatorRounded
                        className="reorder-handle"
                        onPointerDown={(e) => dragControls.start(e)}
                        sx={{
                            fontSize: 14,
                            color: t.col("fg_muted"),
                            cursor: "grab",
                            ["&:active"]: {
                                cursor: "grabbing"
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
