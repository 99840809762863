import React, {useContext} from "react";
import {TabConfig} from "./TabConfig";
import styled from "styled-components";
import {SimulationContext} from "../../Simulation2Main";
import {SimulationWindowAPIContext} from "../VesselSimulationMain";

const StyledTabBodyRenderer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  background-color: #060c10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px
`;

export type TabBodyRendererProps = {
    tab: TabConfig
}

export const TabBodyRenderer: React.FC<TabBodyRendererProps> = props => {
    const sim = useContext(SimulationContext);
    const api = useContext(SimulationWindowAPIContext);
    const tab = props.tab;

    return (
        <StyledTabBodyRenderer>
            { tab.renderer({
                api: api,
                sim: sim
            }) }

            {/*
            { tab.background && (
                <Background config={tab.background as any}/>
            ) }
            */}
        </StyledTabBodyRenderer>
    );
}
