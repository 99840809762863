import {StateDispatcher} from "../../ship/test/core/StateDispatcher";
import {SDInterfaceState} from "./SDInterfaceState";
import React from "react";
import axios from "axios";
import {SDInterfaceRequestContextData} from "./SDInterfaceMain";
import {SDAPIRequestData} from "./SDAPIRequestData";
import {SDPromptEngine} from "./SDPromptEngine";
import {webDB} from "../../ardai/webapi/WebAPIDB";
import {v4} from "uuid";
import {Buffer} from "buffer";
import {Image} from "../../ardai/data/Image";
import {ContentSensitivity} from "../../ardai/webapi/cp/ContentSensitivity";
import {SDInterfaceDynamicState} from "./SDInterfaceDynamicState";
import {IStaticStateCtx} from "../../ardai/hooks/StaticStateHook";

export class SDInterfaceAPI {

    private _dynState?: SDInterfaceDynamicState;

    private _stateCtx?: IStaticStateCtx<SDInterfaceState>;

    private _setDynState?: StateDispatcher<SDInterfaceDynamicState>;

    private _requestContextData: SDInterfaceRequestContextData | undefined;

    constructor(
        stateCtx?: IStaticStateCtx<SDInterfaceState>,
        dynState?: SDInterfaceDynamicState,
        setDynState?: StateDispatcher<SDInterfaceDynamicState>,
        rcd?: SDInterfaceRequestContextData
    ) {
        this._stateCtx = stateCtx;
        this._dynState = dynState;
        this._setDynState = setDynState;
        this._requestContextData = rcd;
    }

    public updateReactStateInfo(stateCtx: IStaticStateCtx<SDInterfaceState>) {
        this._stateCtx = stateCtx;
    }

    public updateReactDynStateInfo(dynState: SDInterfaceDynamicState, setDynState: StateDispatcher<SDInterfaceDynamicState>) {
        this._dynState = dynState;
        this._setDynState = setDynState;
    }

    public updateRequestContextData(rcd: SDInterfaceRequestContextData) {
        this._requestContextData = rcd;
    }

    public updateRequestData(delta: Partial<SDAPIRequestData>) {
        // this.state.updateRequest?.(delta);
        this.dynState.updateRequest?.(delta)
    }

    /**
     * TODO: Check if even working..
     */
    public updateRequest(val: Partial<SDAPIRequestData> | ((prevState: SDAPIRequestData) => Partial<SDAPIRequestData>)) {
        this.stateCtx.update(prevState => ({
            request: {
                ...prevState.request,
                ...(
                    typeof val === "function" ? val(prevState.request) : val
                )
            }
        }));
    }

    public get req(): SDAPIRequestData {
        return this.state.request;
    }

    public interruptImageGeneration() {
        axios.post("http://127.0.0.1:7860/sdapi/v1/interrupt").then(res => {
            this.setState(prevState => ({
                ...prevState,
                phase: "default",
                // TODO: Multiple images handled differently?
                resultImage: res.data,
                previewImage: undefined
            }));
        });
    }

    public async generate() {
        this.setState(prevState => ({ ...prevState, phase: "generating" }));
        const baseUrl = "http://127.0.0.1:7860/sdapi/v1";

        let progressRetriever = setInterval(() => {
            axios.get(`${baseUrl}/progress`, {
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(res => {
                this.setState(prevState => ({
                    ...prevState,
                    progress: res.data,
                    previewImage: res.data.current_image
                }));
            });
        }, 500);

        // const req = this.requestContextData.deltaRequestData!;
        const req = this.stateCtx.state.request;

        const compiler = await new SDPromptEngine().initUserMixins();
        // const compiledPromptData = compiler.parse(this.requestContextData.deltaRequestData?.prompt ?? "");
        const compiledPromptData = compiler.parse(req.prompt ?? "Prompt error: Prompt is empty");
        // const compiledNegativePromptData = compiler.parse(this.requestContextData.deltaRequestData?.negativePrompt ?? "");
        const compiledNegativePromptData = compiler.parse(req.negativePrompt ?? "Prompt error: Negative prompt is empty");


        const conf = {
            // prompt: compiledPromptData.cmd,
            prompt: req.prompt,
            // negative_prompt: compiledNegativePromptData.cmd,
            negative_prompt: req.negativePrompt,
            steps: req.samplingSteps,

            // sampler_index: req.sampler,
            sampler_index: "UniPC",

            cfg_scale: req.cfgScale,
            // width: req.width * req.sizeScale,
            width: req.width ?? 512,
            // height: req.height * req.sizeScale,
            height: req.height ?? 512,
            batch_size: req.batchSize,

            n_iter: 1,
            denoising_strength: 0.4,
            enable_hr: false,
            hr_scale: 1.5,
            hr_upscaler: "R-ESRGAN 4x+ Anime6B",
            hr_second_pass_steps: 50,
        };

        // axios.post(`${baseUrl}/txt2img`, conf).then(async res => {
        axios.post(`${baseUrl}/txt2img`, conf, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then(async res => {
            clearInterval(progressRetriever);
            const ids: Array<string> = [];
            const images: Array<any> = res.data.images as any[];
            const refinedImages: Array<Image> = images.map(rI => {
                const buffer = Buffer.from(rI, 'base64');
                const id = v4();
                ids.push(id);
                return ({
                    id: id,
                    favourite: false,
                    tags: [],
                    creationTimestamp: new Date(),
                    data: new Blob([new Uint8Array(buffer, 0, buffer.length)]),
                    contentSensitivity: ContentSensitivity.UNCLASSIFIED
                });
            });

            await webDB.sdInterfaceResults.bulkAdd(refinedImages);

            this.setState(prevState => ({
                ...prevState,
                phase: "default",
                // TODO: Remove
                resultImage: res.data.images,

                previewImage: undefined,
                progress: undefined,
                currentGeneratedBatchIds: ids
            }));
        }).catch(reason => {
            clearInterval(progressRetriever);
            this.setState(prevState => ({
                ...prevState,
                phase: "default",
                resultImage: prevState.previewImage ?? prevState.resultImage,
                progress: undefined
            }));
            alert("POST ERROR! " + reason);
        });
    }

    get setState(): StateDispatcher<SDInterfaceState> {
        return this._stateCtx?.setState!;
    }

    get state(): SDInterfaceState {
        return this._stateCtx?.state!;
    }

    get stateCtx(): IStaticStateCtx<SDInterfaceState> {
        return this._stateCtx!;
    }

    get requestContextData(): SDInterfaceRequestContextData {
        return this._requestContextData!;
    }

    get dynState(): SDInterfaceDynamicState {
        return this._dynState!;
    }

    get setDynState(): StateDispatcher<SDInterfaceDynamicState> {
        return this._setDynState!;
    }
}

export const SDInterfaceAPIContext = React.createContext<SDInterfaceAPI>(new SDInterfaceAPI())
