import {Trait} from "../../ecs/entity/trait/Trait";
import {StdTraits} from "../StdTraits";
import {as} from "../../../../atlas/Lang";
import {StatusData} from "./StatusData";

export enum StdStatusCodes {
    DEFAULT = 0,
    OK = 1
}

export interface StatusTraitConfig {
    defaultStatusCode: StdStatusCodes,
    statusUpdateMode: StatusUpdateMode,
    statusStateField: string
}

export enum StatusUpdateMode {
    PROACTIVE, REACTIVE
}

const defaultStatusTraitConfig = as<StatusTraitConfig>({
    defaultStatusCode: StdStatusCodes.DEFAULT,
    statusUpdateMode: StatusUpdateMode.REACTIVE,
    statusStateField: "status"
});

export abstract class AbstractStatusTrait extends Trait {

    private readonly statusTraitConfig: StatusTraitConfig;

    constructor(config: Partial<StatusTraitConfig> = {}) {
        super({
            traitName: StdTraits.STATUS
        });
        this.statusTraitConfig = {
            ...defaultStatusTraitConfig,
            ...config
        }
    }

    public abstract getStatus(): string;

    public getFormattedStatus(): string {
        return this.getStatus();
    }

    public getStatusCode(): StdStatusCodes | number {
        return this.statusTraitConfig.defaultStatusCode;
    }

    syncTick() {
        super.syncTick();
        const config = this.statusTraitConfig;
        if (config.statusUpdateMode === StatusUpdateMode.PROACTIVE) {
            const fieldName = config.statusStateField;
            this.entity.setStatePartially({
                [fieldName]: as<StatusData>({
                    status: this.getStatus(),
                    formattedStatus: this.getFormattedStatus(),
                    statusCode: this.getStatusCode()
                })
            });
        }
    }
}

export const newStatusTrait = (status: (this: AbstractStatusTrait, trait: AbstractStatusTrait) => string, config: Partial<StatusTraitConfig> = {}) => as<AbstractStatusTrait>(new class extends AbstractStatusTrait {
    getStatus(): string {
        return status.call(this, this);
    }
}(config));
