import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {StdTypeConversionUtils} from "../../../std/StdTypeConversionUtils";

export const IfCondition: NodeSetupInfo = {
    label: "If condition",
    classname: "logic.if-sig",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "logic.if-sig",
        label: "if",
        defOutPins: ["t", "f"],
        defInPins: ["c"],
        init: function () {
            const signalTruePort = this.pins.out("t");
            const signalFalsePort = this.pins.out("f");

            this.pins.in("c").attach({
                read(cond: any) {
                    const boolCond = StdTypeConversionUtils.booleanishToBoolean(cond);

                    if (boolCond) {
                        signalTruePort.write(1);
                    } else {
                        signalFalsePort.write(1);
                    }
                }
            });
        }
    })
}
