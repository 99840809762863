import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";

export const NodeUnixTimestamp: NodeSetupInfo = {
    label: "NodeUnixTimestamp",
    classname: "time.unix-timestamp",
    parameterConfig: [],
    factory: parameters => new Node({
        id: v4(),
        classname: "time.unix-timestamp",
        label: "time",
        defInPins: ["c"],
        defOutPins: ["t"],
        init() {
            // format
            this.pins.in("c").attach({
                read: () => {
                    this.pins.out("t").write(Date.now());
                }
            })

        }
    })
}
