import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {triton} from "../../../../triton/Triton";
import {Tag} from "../../../../ardai/components/Tag";
import {
    BackspaceRounded,
    CalculateRounded,
    ClearAllRounded,
    IsoRounded,
    RemoveRounded,
    TextDecreaseRounded
} from "@mui/icons-material";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {MenuDivider} from "@szhsin/react-menu";
import {MenuGroup} from "../../../../ardai/components/MenuGroup";
import {FormDivider} from "../../../../triton/components/forms/FormDivider";
import {Pin} from "../../../backend/Pin";
import {evaluate} from "mathjs";

export const AdvancedNumericInput: NodeSetupInfo = {
    label: "AdvancedNumericInput",
    classname: "input.numeric-input-debug-advanced",
    parameterConfig: [],
    factory: parameters => {

        function appendToExpr(node: Node, appendix: string) {
            node.state.update(prevState => ({
                nDeltaExpr: (prevState.nDeltaExpr ?? "") + appendix
            }));
        }

        function removeFromExpr(node: Node, deleteCount: number = 1) {
            node.state.update(prevState => ({
                nDeltaExpr: (prevState.nDeltaExpr as string ?? "").slice(0, -deleteCount)
            }));
        }

        function clearExpr(node: Node) {
            node.state.update({
                nDeltaExpr: undefined
            });
        }

        return new Node<{
            n: number,
            nDeltaExpr: string | undefined,
            showAdvInput: boolean
        }>({
            id: v4(),
            classname: "input.numeric-input-debug-advanced",
            label: "adv num inp *",
            defOutPins: ["n"],
            state: {
                n: 0,
                nDeltaExpr: undefined,
                showAdvInput: false
            },
            reset: function () {
                this.pins.out("n").write(0);
            },
            init() {
                this.pins.out("n").attach({
                    read() {
                        const i = this.node.pins.in("i").lastReadState;
                        this.node.pins.out("o").write(i);
                    }
                })
            },
            customRenderer: node => (
                <div style={{
                    display: "flex",
                    gap: 4,
                    flexDirection: "column",
                    padding: 0,
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                    paddingTop: 8,
                    width: 135
                }}>

                    <div style={{
                        width: "100%",
                        display: "grid",
                        gap: 4,
                        gridTemplateColumns: "1fr 3fr",
                        alignItems: "center",
                        paddingBottom: node.state.state.showAdvInput ? 0 : 8
                    }}>
                        <Tag tag={<CalculateRounded sx={{ fontSize: 14 }}/>} hideBackgroundOnDefault onClick={() => {
                            node.state.reverseBool("showAdvInput")
                        }}/>

                        <input
                            type={"number"}
                            inputMode={"numeric"}
                            value={node.state.state.n}
                            onChange={e => {
                                const n = Number(e.currentTarget.value);
                                node.pins.out("n").write(n);
                                node.state.update({ n });
                            }}
                            style={{
                                width: "100%",
                                fontSize: 12,
                                color: triton().col("fg_muted"),
                                border: "none",
                                backgroundColor: "transparent",
                                outline: "none",

                                textAlign: "end",
                            }}
                        />
                    </div>

                    { node.state.state.showAdvInput && (
                        <>
                            <div style={{ width: "100%" }}>
                                <FormDivider/>
                            </div>

                            {/*
                                <DescriptiveTypography text={`${node.state.state.nDeltaExpr ?? "0"}`} style={{
                                    textAlign: "end",
                                    width: "100%"
                                }}/>
                            */}

                            <input
                                value={node.state.state.nDeltaExpr ?? ""}
                                placeholder={"0"}
                                onChange={e => {
                                    node.state.update({
                                        nDeltaExpr: e.currentTarget.value
                                    });
                                }}
                                style={{
                                    width: "100%",
                                    fontSize: 12,
                                    color: triton().col("fg_muted"),
                                    border: "none",
                                    backgroundColor: "transparent",
                                    outline: "none",
                                    textAlign: "end"
                                }}
                            />

                            <div style={{
                                display: "grid",
                                gap: 4,
                                gridTemplateColumns: "repeat(4, 1fr)",
                                paddingBottom: 8
                            }}>
                                <Tag tag={"c"} onClick={() => clearExpr(node)}/>
                                <Tag tag={"+-"}/>
                                <Tag tag={<BackspaceRounded sx={{ fontSize: 14 }}/>} onClick={() => removeFromExpr(node)}/>
                                <Tag tag={"+"} onClick={() => appendToExpr(node, "+")}/>

                                <Tag tag={"1"} onClick={() => appendToExpr(node, "1")}/>
                                <Tag tag={"2"} onClick={() => appendToExpr(node, "2")}/>
                                <Tag tag={"3"} onClick={() => appendToExpr(node, "3")}/>
                                <Tag tag={"-"} onClick={() => appendToExpr(node, "-")}/>

                                <Tag tag={"4"} onClick={() => appendToExpr(node, "4")}/>
                                <Tag tag={"5"} onClick={() => appendToExpr(node, "5")}/>
                                <Tag tag={"6"} onClick={() => appendToExpr(node, "6")}/>
                                <Tag tag={"*"} onClick={() => appendToExpr(node, "*")}/>

                                <Tag tag={"7"} onClick={() => appendToExpr(node, "7")}/>
                                <Tag tag={"8"} onClick={() => appendToExpr(node, "8")}/>
                                <Tag tag={"9"} onClick={() => appendToExpr(node, "9")}/>
                                <Tag tag={"/"} onClick={() => appendToExpr(node, "/")}/>

                                <Tag tag={<CalculateRounded sx={{ fontSize: 14 }}/>}/>
                                <Tag tag={"0"} onClick={() => appendToExpr(node, "0")}/>
                                <Tag tag={"."} onClick={() => appendToExpr(node, ".")}/>
                                <Tag tag={"="} onClick={() => {
                                    const expr = node.state.state.nDeltaExpr ?? "0";
                                    const val = evaluate(expr, {}) as number;
                                    node.pins.out("n").write(val)
                                    node.state.update({ n: val });
                                }}/>
                            </div>
                        </>
                    ) }
                </div>
            )
        });
    }
}
