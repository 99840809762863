import React, {useContext} from "react";
import {KSU, SimulationContext} from "../../Simulation2Main";
import {castEntity, castEntityFull} from "../../SimStd";
import {AzypodState} from "../../entities/azypod/AzypodEntity";
import {AzypodIndicator} from "../../entities/azypod/AzypodIndicator";
import {SteeringAPEntity} from "../../entities/ap/SteeringAPEntity";
import {StyledControlDisplayGroup} from "../../components/StyledControlDisplayGroup";
import {AutoModeRounded, NumbersRounded, WindPowerRounded} from "@mui/icons-material";
import {hex} from "../../../../base/logic/style/Color";
import {Button} from "../../components/Button";
import {Interpolate} from "../../../../triton/components/advanced/Interpolate";
import {ShipMainEntity} from "../../entities/ship/ShipMainEntity";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {Std} from "../../../../../Std";
import also = Std.also;

export const SteeringAPWidget: React.FC = props => {
    const sim = useContext(SimulationContext);
    const steeringAP = castEntityFull<SteeringAPEntity>(sim.entity("auto"));
    const ship = castEntityFull<ShipMainEntity>(sim.entity("ship"));

    return (
        <StyledControlDisplayGroup style={{
            height: 60,
            // aspectRatio: "1 / 1",
            display: "flex",
            flexDirection: "row",
            gap: 6
        }} >

            <Button square children={<AutoModeRounded/>} color={{
                if: [
                    { cond: () => steeringAP?.state?.engaged && (steeringAP?.currentTargetGeneratorName === "fallback"), color: hex("#f14234") },
                    { cond: () => steeringAP?.state?.engaged ?? false, color: hex("#60ffc7") }
                ]
            }} onClick={() => {
                steeringAP.setStatePartially(prevState => ({
                    engaged: !prevState.engaged
                }))
            }}/>

            <Button square children={<NumbersRounded/>} color={{
                if: [{ cond: () => steeringAP?.currentTargetGeneratorName === "manual", color: hex("#ffdf60") }]
            }} onClick={() => {
                steeringAP.switchTargetGenerator("manual");
            }}/>

            <Button square children={<WindPowerRounded/>} color={{
                if: [{ cond: () => steeringAP?.currentTargetGeneratorName === "wind", color: hex("#ffdf60") }]
            }} onClick={() => {
                steeringAP.switchTargetGenerator("wind");
            }}/>

            <div style={{
                width: 40,
                height: "100%",
                display: "grid",
                gridTemplateRows: "repeat(1, 1fr)",
                alignItems: "center"
            }}>
                <DescriptiveTypography text={steeringAP?.state?.target?.master?.get()?.toFixed(2) ?? 0}/>
                <Interpolate stepDelay={100} value={(ship?.state?.heading?.theta) ? (
                    also(ship?.state?.heading?.theta, (t) => Math.round(t * 100) / 100)
                ) : 0}/>
            </div>

        </StyledControlDisplayGroup>
    );
}
