import {SimulationWindowState} from "./SimulationWindowState";
import {StateDispatcher} from "../../test/core/StateDispatcher";
import {EntityData} from "../Simulation2Main";
import {TabConfig} from "./tab/TabConfig";
import {allTab} from "./tab/tabs/AllTab";

export class SimulationWindowAPI {

    private _state: SimulationWindowState;

    constructor(defaultWindowState: SimulationWindowState) {
        this._state = defaultWindowState;
    }

    private _setState?: StateDispatcher<SimulationWindowState>;

    get setState(): StateDispatcher<SimulationWindowState> {
        return this._setState!;
    }

    set setState(value: StateDispatcher<SimulationWindowState>) {
        this._setState = value;
    }
    get state(): SimulationWindowState {
        return this._state;
    }

    set state(value: SimulationWindowState) {
        this._state = value;
    }

    public setStatePartially(value: ((prevState: SimulationWindowState) => Partial<SimulationWindowState>) | Partial<SimulationWindowState>) {
        this.setState(prevState => {
            let newPartialState = typeof value === "function" ? value(prevState) : value;
            return ({
                ...prevState,
                ...newPartialState
            });
        });
    }

    public closeTab(tabID: string) {
        this.setStatePartially({
            tab: "_main"
        });
    }

    public selectTab(tabID: string = "_main") {
        if (this.state.tab === tabID) {
            return this.closeTab(tabID);
        }
        else this.setStatePartially({
            tab: tabID
        });
    }

    /**
     * TODO: Default to a different tab (not allTab) -> Maybe dashboard or special error tab
     */
    public get currentTab(): TabConfig {
        return this.state.tabLibrary.filter(tC => tC.id === this.state.tab)[0] ?? allTab;
    }

    public toggleZenMode(zenMode: undefined | boolean = undefined) {
        this.setStatePartially(prevState => ({
            zenMode: zenMode ?? !prevState.zenMode
        }));
    }
}
