import {Trait} from "../ecs/entity/trait/Trait";
import {StdTraits} from "./StdTraits";
import {PIDController} from "../math/PIDController";

export class PIDTrait extends Trait {

    constructor(private _controller: PIDController = new PIDController()) {
        super({
            traitName: StdTraits.THRUST_PRODUCER
        });
    }

    public get controller(): PIDController {
        return this._controller;
    }
}
