import {Serializable} from "../../../../../../std/Serializable";

export namespace IndicatorUtils {

    export type NumericMappingFn = (key: number, tar: number) => boolean;

    export type MappingStep<T> = {
        isAuthority(key: number): boolean,
        get(key: number): T
    }

    export class NumericMapping<T> {

        private steps: MappingStep<T>[] = [];

        constructor(block: (mapping: NumericMapping<T>) => void = () => {}) {
            block(this);
        }

        mappingFn = (pred: NumericMappingFn) => {
            return (target: number, mappedTo: T) => {
                this.steps.push({
                    get(key: number): T {
                        return mappedTo;
                    },
                    isAuthority(key: number): boolean {
                        return pred(key, target)
                    }
                });
                return this;
            }
        }

        fallback = this.mappingFn(() => true)

        eq = this.mappingFn((key, tar) => key === tar)

        l = this.mappingFn((key, tar) => key < tar)

        lEq = this.mappingFn((key, tar) => key <= tar)

        g = this.mappingFn((key, tar) => key > tar)

        gEq = this.mappingFn((key, tar) => key >= tar)

        public get(key: number): T | undefined {
            for (let step of this.steps) {
                if (!step.isAuthority(key)) continue;
                return step.get(key);
            }
        }
    }

    new NumericMapping<string>()
        .l(-20, "too cold")
        .l(0, "cold")
        .eq(0, "inert")
        .l(70, "ok")
        .l(90, "hot")
        .gEq(90, "too hot")

    new NumericMapping<string>()
        .fallback(0, "")
}

