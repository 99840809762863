import React, {useState} from "react";
import styled from "styled-components";
import {useTriton} from "../../../triton/TritonHooks";
import {DefaultButton} from "../../components/DefaultButton";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import Slide from "@mui/material/Slide";
import {IconButton} from "../../components/IconButton";
import {MobileNavigationIcon} from "./MobileNavigationIcon";
import {
    DataArrayRounded, DeveloperBoard,
    ExpandCircleDownRounded, InfoRounded,
    NotificationsRounded, PlayArrowRounded, RoomPreferencesRounded,
    SettingsAccessibilityRounded, SettingsRounded,
    TagRounded
} from "@mui/icons-material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ButtonModalCompound} from "../../components/ButtonModalCompound";
import {MenuButton} from "../../components/MenuButton";
import {ColorableTag} from "../../components/ColorableTag";
import {Color} from "../../../base/logic/style/Color";
import {HelpHint} from "../../components/HelpHint";
import {
    LineArrayToJsonArrayConvertorMain
} from "../../tools/lineArrayToJsonArrayConvertor/LineArrayToJsonArrayConvertorMain";
import {TopicSelectorMain} from "../../tools/topicSelector/TopicSelectorMain";
import {ThemeSelectorMenuPart} from "../../components/ThemeSelectorMenuPart";
import {PowerActionMenuSection} from "../../components/navbar/PowerActionMenuSection";
import {ListItem} from "../../_/ListItem";
import {MainTypography} from "../../../triton/components/typography/MainTypography";

const StyledMobileNavigation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  
  .static-navigation {
    padding: 16px 16px 24px;
    transition: all 200ms;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
    
    
  }
`;

export type MobileNavigationState = {

    openTray: boolean,

    openStaticTray: boolean
}

export const MobileNavigation: React.FC = props => {
    const triton = useTriton();
    const [state, setState] = useState<MobileNavigationState>({
        openTray: false,
        openStaticTray: false
    });

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <StyledMobileNavigation>
            <div style={{
                position: "relative",
                zIndex: 0
            }}>
                <TransitionGroup style={{
                    position: "absolute",
                    // top: -130,
                    bottom: 32,
                    // top: 0,
                    width: "100%",
                    zIndex: 1
                }} children={
                    state.openTray && (
                        <Slide timeout={300} easing={"ease-out"} direction={"up"} children={
                            <div style={{
                                // height: "130px",
                                // height: 500,

                                width: "100%",
                                paddingBottom: 30,
                                marginBottom: -30,
                                padding: 8,
                                backgroundColor: triton.col("bg_tray"),
                                // backgroundColor: "lightcyan",
                                color: triton.col("fg_default"),
                                borderRadius: "20px 20px 0 0"
                            }}>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 8
                                }}>
                                    <MainTypography text={"Quick action"}/>

                                    <ButtonModalCompound
                                        button={
                                            <ListItem
                                                icon={<DataArrayRounded/>}
                                                title={"Line-array to json-array convertor"}
                                                defaultAppendix={
                                                    <>
                                                        <ColorableTag tag={"BETA"} c={Color.ofHex("#8561ff")}/>
                                                        <HelpHint text={"Convert a line-array file (typically .txt) to a json-array file (.json)"}/>
                                                    </>
                                                }
                                            />
                                        }
                                        modalContent={ctx => (
                                            <LineArrayToJsonArrayConvertorMain onClose={ctx.close}/>
                                        )}
                                    />
                                    <ButtonModalCompound
                                        button={
                                            <ListItem
                                                icon={<TagRounded/>} title={"Topic selector"}
                                            />
                                        }
                                        modalContent={ctx => (
                                            <TopicSelectorMain onClose={ctx.close}/>
                                        )}
                                    />

                                    <Link to={"/ai/development/component"} style={{ textDecoration: "none" }} children={
                                        <ListItem
                                            icon={<DeveloperBoard/>} title={"Component testing space"}
                                        />
                                    }/>

                                    <ThemeSelectorMenuPart/>
                                </div>
                            </div>
                        }/>
                    )
                }/>
            </div>


            <TransitionGroup style={{
                zIndex: 2
            }} children={
                state.openStaticTray && (
                    <Collapse timeout={200} easing={"ease-out"} children={
                        <div children={"Collapse"} style={{
                            height: "130px",
                            width: "100%",
                            paddingBottom: 30,
                            marginBottom: -30,
                            padding: 8,
                            backgroundColor: triton.col("bg_tray"),
                            color: triton.col("fg_default"),
                            borderRadius: "20px 20px 0 0"
                        }}/>
                    }/>
                )
            }/>

            <div className={"static-navigation"} style={{
                // backgroundColor: triton.col("bg_tray"),
                backgroundColor: "black",
                color: triton.col("fg_default"),

                // TODO: Reactivate
                // borderRadius: (state.openTray || state.openStaticTray) ? 0 : "20px 20px 0 0"
            }}>
                <MobileNavigationIcon
                    active={location.pathname === "/ai"}
                    onSelect={() => {
                        navigate("");
                    }}
                    children={
                        <TagRounded/>
                    }
                />

                <MobileNavigationIcon
                    active={location.pathname === "/ai/generate"}
                    onSelect={() => {
                        navigate("generate");
                    }}
                    children={
                        <PlayArrowRounded/>
                    }
                />

                <MobileNavigationIcon active={state.openTray} children={<InfoRounded/>} onSelect={() => {
                    setState(prevState => ({
                        ...prevState,
                        openTray: !prevState.openTray
                    }));
                }}/>

                <MobileNavigationIcon active={state.openStaticTray} children={<ExpandCircleDownRounded/>} onSelect={() => {
                    setState(prevState => ({
                        ...prevState,
                        openStaticTray: !prevState.openStaticTray
                    }));
                }}/>

                <MobileNavigationIcon children={
                    <NotificationsRounded/>
                }/>

                {/*
                <MobileNavigationIcon children={
                    <SettingsRounded/>
                }/>
                */}

            </div>


        </StyledMobileNavigation>
    );
}
