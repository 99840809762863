import {Entity} from "../../ecs/entity/Entity";
import {GenericEntityState} from "../../Simulation2Main";

export type DoorEntityState = {
    closed: boolean
}

export class DoorEntity extends Entity<DoorEntityState> {

    getInitialState(): GenericEntityState | DoorEntityState {
        return ({
            closed: false
        });
    }

    public toggleDoor() {
        this.setStatePartially(prevState => ({
            closed: !prevState.closed
        }));
    }
}
