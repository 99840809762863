import React, {useContext, useState} from "react";
import styled from "styled-components";
import {
    ChevronLeft,
    CommitRounded,
    DataObject, FolderRounded, ForkRightRounded,
    FormatShapesRounded,
    Interests,
    ShapeLine,
    SubdirectoryArrowRightRounded
} from "@mui/icons-material";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {motion} from "framer-motion";

import {BranchType, LeafType, TabStructureNode} from "./components/TabSelectionView";
import {ListItem} from "../../../ardai/_/ListItem";
import {FlatIconButton} from "../../../ardai/components/FlatIconButton";
import {Std} from "../../../../Std";
import using = Std.using;
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {SimulationWindowAPIContext} from "./VesselSimulationMain";

const StyledTabDisplay = styled.div`
  width: 100%;
  
  .entity-display-header {
    display: grid;
    grid-template-columns: auto min-content;
    gap: 8px;
    align-items: center;
  }
  
  .entity-display-details-container {
    padding: 8px 0 8px 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export type TabDisplayProps = {
    node: TabStructureNode
}

export type TabDisplayState = {
    expanded: boolean
}

export const TabDisplay: React.FC<TabDisplayProps> = props => {
    const api = useContext(SimulationWindowAPIContext);
    const n = props.node;
    const data = n.data!;

    const [state, setState] = useState<TabDisplayState>({
        expanded: false
    });

    switch (data.type) {
        case "leaf": {
            return using(data as LeafType, leaf => (
                <StyledTabDisplay>

                    <div className={"entity-display-header"}>
                        <ListItem
                            title={leaf.staticTabName}
                            // icon={<CommitRounded/>}
                            // icon={<ShapeLine/>}
                            // icon={<Interests/>}
                            icon={<Interests sx={{
                                fontSize: 16
                            }}/>}
                            active={leaf.id === api.currentTab.id}
                            onClick={() => api.selectTab(leaf.id)}
                        />

                        {/*
                        <FlatIconButton onClick={() => setState(prevState => ({
                            ...prevState,
                            expanded: !prevState.expanded
                        }))} children={
                            <div style={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} children={
                                <motion.div style={{
                                    aspectRatio: "1 / 1",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }} animate={state.expanded ? "expanded" : "collapsed"} variants={{
                                    expanded: {
                                        rotate: 90
                                    },
                                    collapsed: {
                                        rotate: 270
                                    }
                                }} children={
                                    <ChevronLeft sx={{
                                        fontSize: 20
                                    }}/>
                                }/>
                            }/>
                        }/>
                        */}
                    </div>
                </StyledTabDisplay>
            ));
        }
        case "branch": {
            return using(data as BranchType, branch => (
                <StyledTabDisplay>

                    <div className={"entity-display-header"}>
                        <ListItem
                            title={branch.title}
                            // icon={<CommitRounded/>}
                            // icon={<ShapeLine/>}
                            icon={<FolderRounded sx={{
                                fontSize: 16
                            }}/>}
                            // icon={<DataObject sx={{
                            //     fontSize: 16
                            // }}/>}
                            onClick={() => setState(prevState => ({
                                ...prevState,
                                expanded: !prevState.expanded
                            }))}
                        />

                        <FlatIconButton onClick={() => setState(prevState => ({
                            ...prevState,
                            expanded: !prevState.expanded
                        }))} children={
                            <div style={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }} children={
                                <motion.div style={{
                                    aspectRatio: "1 / 1",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }} animate={state.expanded ? "expanded" : "collapsed"} variants={{
                                    expanded: {
                                        rotate: 90
                                    },
                                    collapsed: {
                                        rotate: 270
                                    }
                                }} children={
                                    <ChevronLeft sx={{
                                        fontSize: 20
                                    }}/>
                                }/>
                            }/>
                        }/>
                    </div>

                    <TransitionGroup children={state.expanded && (
                        <Collapse>
                            <div className={"entity-display-details-container"}>

                                { branch.description && (
                                    <DescriptiveTypography text={branch.description}/>
                                ) }

                                { (() => {
                                    const children = Array.from((n.children ?? []).values());

                                    if (children.length === 0) return (
                                        <DescriptiveTypography text={"Empty"}/>
                                    )

                                    return (
                                        <div style={{
                                            paddingLeft: 10
                                        }} children={
                                            children.map(cNode => (
                                                <TabDisplay node={cNode}/>
                                            ))
                                        }/>
                                    )
                                })() }

                            </div>
                        </Collapse>
                    )}/>
                </StyledTabDisplay>
            ));
        }
    }
}
