import React, {useContext} from "react";
import {SimulationWindowAPIContext} from "../VesselSimulationMain";
import styled from "styled-components";
import {SimulationContext} from "../../Simulation2Main";
import {ListItem} from "../../../../ardai/_/ListItem";
import {CommitRounded} from "@mui/icons-material";
import {StringField} from "../../../../ardai/testing/StringField";
import {EntityDisplay} from "./EntityDisplay";
import {ControlModalCompound} from "../../../../ardai/components/ControlModalCompound";
import {Main} from "../../../../triton/components/layouts/Main";
import {MainTypography} from "../../../../triton/components/typography/MainTypography";

const StyledEntityListView = styled.div`
  background-color: #010409;
  width: 350px;
  height: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;

  .entity-list-header {
    
  }
  
  .entity-list {
    overflow: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
`;

export const EntityListView: React.FC = props => {
    const api = useContext(SimulationWindowAPIContext);
    const sim = useContext(SimulationContext);

    return (
        <StyledEntityListView>

            <div className={"entity-list-header"}>
                <MainTypography text={"Entity view"}/>
            </div>

            <div className={"entity-list"} children={
                sim?.getAllEntities()?.map(e => (
                    <ControlModalCompound
                        controller={ctx => (
                            <div onClick={ev => {
                                if (ev.ctrlKey) {
                                    ev.stopPropagation();
                                    ev.preventDefault();
                                    ctx.openDirect(ctx1 => (
                                        <div style={{
                                            backgroundColor: "#010409",
                                            overflowX: "hidden",
                                            borderRadius: 8,
                                            padding: 8,
                                            width: 400,
                                            maxWidth: "100vw"
                                        }} children={
                                            <EntityDisplay entity={e}/>
                                        }/>
                                    ), "")
                                }
                            }} children={
                                <EntityDisplay entity={e}/>
                            }/>
                        )}
                    />
                ))
            }/>
        </StyledEntityListView>
    );
}
