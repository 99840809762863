import {FC} from "react";
import {PinBank} from "../../../backend/PinBank";
import {useTriton} from "../../../../triton/TritonHooks";
import {v4} from "uuid";
import {Pin} from "../../../backend/Pin";
import {NodePinDisplay} from "../../../frontend/NodePinDisplay";
import {triton} from "../../../../triton/Triton";
import {AddRounded} from "@mui/icons-material";
import {Alignment} from "../../../frontend/Alignment";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";

export const ManualPortConfigurationPinBankDisplay: FC<{
    bank: PinBank,
    align?: Alignment
}> = props => {
    const { bank } = props;
    const align = props.align ?? Alignment.LEFT;

    const t = useTriton();

    const addNewPort = () => {
        // FIXME: Remove dirty trick ".setLabel("")" to render the new port
        //  -> Create createPin method in bank
        //  -> On createPin: fire "pin created event" (naming changes..)
        bank.pin(v4()).setLabel("");
    }

    return (
        <div style={{
            // width: 50,
            // backgroundColor: t.col("bg_tray"),
            backgroundColor: "#10141a",
            borderRadius: 8,
            display: "grid",
            gap: 0,
            position: "relative",

            // direction: align === Alignment.RIGHT ? "rtl" : "ltr"
            direction: align === Alignment.RIGHT ? "ltr" : "rtl"
        }}>
            { Array.from(bank.pins.values()).map(pin => {
                return (
                    <ConfigurablePinDisplay
                        pin={pin}
                        key={pin.config.id}
                        align={align}
                    />
                );
            }) }

            <div style={{
                padding: 4,
                paddingRight: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                width: "100%"
            }}>
                <AddRounded onClick={() => addNewPort()} sx={{
                    fontSize: 14,
                    marginRight: "1px",
                    color: t.col("fg_muted"),
                    cursor: "pointer",
                    ["&:hover"]: {
                        color: t.col("color_primary"),
                    }
                }}/>
            </div>
        </div>
    );
}

const ConfigurablePinDisplay: FC<{
    pin: Pin,
    align: Alignment
}> = props => {
    const { pin } = props;
    const pinLabel = pin.config.label;
    const pinType = "any";

    const updatePinLabel = (label: string) => {
        pin.setLabel(label);
    }

    const parseAndExecutePinLabelMacro = (macro: string) => {
        if (macro.startsWith(".")) {
            const symbol = macro.substring(1);
            const symbolDict: Map<string, string> = new Map<string, string>([
                ["sum", "Σ"],
                ["delta", "Δ"],
                ["null", "Ø"],
                ["integral", "∫"],
                ["fn", "ƒ"],
                ["clock", "∿"],
                ["freq", "λ"],
            ]);
            if (!symbolDict.has(symbol)) return;
            updatePinLabel(symbolDict.get(symbol)!);
        }
    }

    return (
        <div style={{
            display: "flex",
            gap: 4,
            flexDirection: "row",
            padding: 0,
            alignItems: "center",
        }}>
            <div style={{
                display: "flex",
                gap: 0,
                flexDirection: "row",
                padding: 0,
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
                direction: "ltr"
            }}>
                <input
                    type={"text"}
                    value={pinLabel}
                    onChange={e => {
                        updatePinLabel(e.currentTarget.value);
                    }}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            parseAndExecutePinLabelMacro(e.currentTarget.value);
                        }
                    }}
                    style={{
                        height: 18,
                        maxWidth: 80,
                        fontSize: 12,
                        lineHeight: 1.5,
                        color: triton().col("fg_muted"),
                        border: "none",
                        backgroundColor: "transparent",
                        outline: "none",
                        textAlign: "end"
                    }}
                    placeholder={"░"}
                />

                <DescriptiveTypography text={": "} style={{ whiteSpace: "pre" }}/>
                <input
                    type={"text"}
                    value={pin.config.typeName}
                    onChange={e => {
                        const typeName = e.currentTarget.value;
                        pin.setTypeName(typeName)
                    }}
                    spellCheck={false}
                    style={{
                        maxWidth: 30,
                        fontSize: 12,
                        lineHeight: 1.5,
                        color: triton().col("fg_muted"),
                        border: "none",
                        backgroundColor: "transparent",
                        outline: "none",
                        fontWeight: "lighter"
                    }}
                    placeholder={"░"}
                />
            </div>

            <NodePinDisplay
                pin={pin}
                position={props.align === Alignment.LEFT ? "left" : "right"}
                showLabel={false}
            />
        </div>
    );
}
