import React from "react";
import styled from "styled-components";
import {PolarCoordinates} from "./math/PolarCoordinates";
import {PolarVec2D} from "./math/PolarVec2D";
import {Color} from "../../base/logic/style/Color";

const StyledVectorIndicator = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .vector-arrow {
    --rotation-speed: 1s ease-in-out;
    transition: height 1s ease-in-out, rotate var(--rotation-speed);
    position: absolute;
    width: 1px;
    height: 0;
    min-height: 10px;
    background-color: rgb(33, 38, 45);
    display: grid;
    grid-template-rows: repeat(2, 1fr);

    .pointer {
      width: 100%;
      height: 100%;
      background-color: rgb(145, 72, 225);
      
      .pointer-tip-anchor {
        transition: rotate var(--rotation-speed);
      }
    }

  }
`;

export type VectorIndicatorProps = {
    for: PolarVec2D,
    thetaOffset?: number,
    tipElement?: React.ReactNode,
    hideInverseHint?: boolean,
    color?: Color
}

export const VectorIndicator: React.FC<VectorIndicatorProps> = props => {
    const c = props.for;
    const hideInverseHint = props.hideInverseHint ?? true;
    const color = props.color ?? new Color(145, 72, 256);
    const theta = c.theta + (props.thetaOffset ?? 0);
    return (
        <StyledVectorIndicator>
            <div className={"vector-arrow"} style={{
                height: c.r * 2,
                rotate: `${theta}deg`,
                backgroundColor: hideInverseHint ? "transparent" : "rgb(33, 38, 45)"
            }} children={
                <div className={"pointer"} children={
                    props.tipElement && (
                        <div className={"pointer-tip-anchor"} children={props.tipElement} style={{
                            rotate: `${-theta}deg`
                        }}/>
                    )
                } style={{
                    backgroundColor: color.css()
                }}/>
            }/>
        </StyledVectorIndicator>
    );
}
