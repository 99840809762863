import {SaveStateNode} from "./SaveStateNode";
import {SerializableObject} from "../../../../../std/SerializableObject";
import 'reflect-metadata';
import {ArrayLinkImpl} from "../../../../../std/Accumulate";
import {InflatableHelpers} from "./InflatableHelpers";
import {InflationLibGlobals} from "./InflationLibGlobals";
import {inflate} from "./DecoratorInflate";
import {markInflatable} from "./DecoratorMarkInflatable";
import {InflationContext} from "./InflationContext";

export function testInflatableSystem(): any {
    const root: Inflatable = new TestInflatable;
    const save = root.deflate!();
    console.log("root deflated", save);

    save.data.test = "overwritten test value";
    save.data.manualString = "overwritten manualString value";
    save.children[0].data.life = 69;
    const rootToBeInflated: Inflatable = new TestInflatable;
    rootToBeInflated.inflate!(save.data, save, new InflationContext({}));
    console.log("diff. root inflated", rootToBeInflated);
}

export interface Inflatable<SaveStateType extends SerializableObject = SerializableObject> {
    get id(): string,
    inflate?(save: SaveStateType, node: SaveStateNode, ctx: InflationContext): void
    amendInflate?(save: SaveStateType, node: SaveStateNode, ctx: InflationContext): void

    deflate?(): SaveStateNode,
    amendDeflate?(save: SaveStateType, node: SaveStateNode): void
}

@markInflatable()
export class TestInflatable implements Inflatable {
    id = "TestInflatable"

    @inflate
    test?: string = "this is the default test value"

    @inflate
    aNumber?: number = 42;

    @inflate
    bBool?: boolean = false;

    manualString: string = "unchanged"

    sub = new TestSubInflatable
    sub0 = new TestSubSubInflatable("#0");

    // inflate = (save: SerializableObject, node: SaveStateNode) => {
    //     this.test = save.test as string;
    //     inflateRecursively(this, node);
    // };

    // deflate = (): SaveStateNode => deflateRecursively(this, {
    //     test: this.test ?? "test"
    // });

    amendInflate(save: SerializableObject, node: SaveStateNode) {
        this.manualString = save.manualString as string;
    }

    amendDeflate(save: SerializableObject, node: SaveStateNode) {
        save.manualString = this.manualString;
    }
}

@markInflatable()
export class TestSubInflatable implements Inflatable<{ life: number, isWorthLiving: boolean }> {
    id = "TestSubInflatable"

    @inflate
    life?: number

    @inflate
    isWorthLiving?: boolean

    sub1 = new TestSubSubInflatable("#1")

    sub2 = new TestSubSubInflatable("#2", {
        a: 42,
        b: true,
        c: {
            c_a: "c_a was here!"
        }
    })

    // inflate = (save: { life: number; isWorthLiving: boolean }, node: SaveStateNode) => {
    //     this.life = save.life;
    //     this.isWorthLiving = save.isWorthLiving;
    //     inflateRecursively(this, node);
    // };

    // deflate = (): SaveStateNode => deflateRecursively(this, {
    //     life: this.life ?? 0,
    //     isWorthLiving: this.isWorthLiving ?? false
    // });
}

export class TestSubSubInflatable implements Inflatable {
    constructor(
        public readonly id: string,
        public dynState: SerializableObject = {}
    ) {}

    inflate = (save: SerializableObject, node: SaveStateNode) => {
        this.dynState = save;
    };

    deflate = (): SaveStateNode => InflatableHelpers.deflateRecursively(this, this.dynState);
}

new class implements Inflatable {
    id = ""
}
