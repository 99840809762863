import React from "react";
import styled from "styled-components";
// import {LazyLog, ScrollFollow} from 'react-lazylog';

const StyledCLIMain = styled.div`
  width: 600px;
  height: 350px;
  background-color: red;
`;

export const CLIMain: React.FC = props => {
    return <></>
    // return (
    //     <StyledCLIMain>
    //
    //         <ScrollFollow
    //             startFollowing={true}
    //             render={({ follow }) => (
    //                 <LazyLog
    //                     stream
    //                     url="https://github.com/christian-bernstein/sql-editor-web/blob/465f5eeff84d9f896ebaa76f0056074f39750e78/LICENSE"
    //                     follow={follow}
    //                     extraLines={3}
    //                     // text={"Hello world"}
    //                 />
    //             )}
    //         />
    //     </StyledCLIMain>
    // );
}
