import React from "react";
import {Notification} from "./Notification";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";

const StyledNotificationBalloon = styled.div`
  background-color: green;
  padding: 16px;
  border-radius: 8px;
`;

export type NotificationBalloonProps = {
    notification: Notification
}

export const NotificationBalloon: React.FC<NotificationBalloonProps> = props => {
    const n = props.notification;
    return (
        <StyledNotificationBalloon>
            <DescriptiveTypography text={n.message}/>
        </StyledNotificationBalloon>
    );
}
