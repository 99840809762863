import {Inflatable} from "../serialization/v2/Inflatable";
import {Node} from "../Node";
import {Attachment} from "./Attachment";
import {inflate} from "../serialization/v2/DecoratorInflate";
import {markInflatable} from "../serialization/v2/DecoratorMarkInflatable";
import {transient} from "../serialization/v2/DecoratorTransient";

@markInflatable()
export class AttachmentManager implements Inflatable {

    id

    @inflate
    private attachments: { [key: string]: Attachment } = {};

    @transient
    private readonly node: Node;

    constructor(node: Node) {
        this.node = node;
        this.id = `${this.node.id}-attachment-manager`;
    }

    public get<T extends Attachment = Attachment>(key: string, def?: T): T | undefined {
        return <T>(this.attachments[key] ?? def);
    }

    public set(key: string, attachment: Attachment): Attachment {
        this.attachments[key] = attachment;
        return attachment;
    }

    public has(key: string): boolean {
        return this.attachments[key] !== undefined;
    }

    public withAttachment<
        AttachmentType extends Attachment = Attachment, ComputeResult = void
    >(key: string, block: (this: AttachmentType, attachment: AttachmentType) => ComputeResult): ComputeResult | undefined {
        if (!this.has(key)) return undefined;
        const attachment = this.get<AttachmentType>(key);
        if (attachment === undefined) return undefined;
        return block.call(attachment, attachment);
    }

    public delete(key: string): boolean {
        return delete this.attachments[key];
    }
}
