import {ResourceRequest} from "./ResourceRequest";
import {Resource} from "./Resource";
import {ResourceParamsType} from "./ResourceParamsType";

export class ResourceContainer<T, Params extends ResourceParamsType = void> {

    private readonly resources: Map<string, Resource<T, Params>>
        = new Map<string, Resource<T, Params>>();

    /**
     * TODO: Return a resource handle
     */
    public registerResource(id: string, resource: Resource<T,  Params>): void {
        this.resources.set(id, resource);
    }

    public getResource(req: ResourceRequest<Params>): T {
        const id = req.id;
        const res = this.resources.get(id);
        return res!.get(req.params);
    }

    /**
     * "It'll be fine"
     * - famous last words
     *
     * TODO: Make type checking / error handling
     */
    public getResourceWithoutParams(id: string): T {
        return this.getResource({ id, params: undefined as any });
    }
}
