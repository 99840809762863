import {NodeSetupInfo} from "../NodeSetupInfo";
import {Node} from "../../backend/Node";
import {v4} from "uuid";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {Tag} from "../../../ardai/components/Tag";
import {triton} from "../../../triton/Triton";
import React, {FC} from "react";
import {RefreshRounded} from "@mui/icons-material";

function updateInterval(node: Node) {
    if (node.state.state.interval !== undefined) {
        clearInterval(node.state.state.interval);
    }
    const enabled = node.state.state.enabled;
    const hz = node.state.state.hertz;
    const period = 1 / hz;
    const interval = (hz >= 0 && enabled) ? setInterval(() => {
        node.pins.out("c").write(0b1);
    }, period * 1e3) : undefined;


    node.state.update({
        interval
    });
}

export type AdvancedOscillatorState = {
    interval?: NodeJS.Timer
    hertz: number,
    enabled: boolean
}

export const AdvancedOscillator: NodeSetupInfo = {
    label: "AdvancedOscillator",
    classname: "adv-oscillator",
    parameterConfig: [],
    factory: parameters => new Node<AdvancedOscillatorState>({
        id: v4(),
        classname: "adv-oscillator",
        label: "oscillator *",
        defOutPins: ["c"],
        state: {
            hertz: 1,
            enabled: false
        },
        customRenderer: (node) => (
            <AdvancedOscillatorDisplay
                node={node}
            />
        )
    })
}

const AdvancedOscillatorDisplay: FC<{
    node: Node<AdvancedOscillatorState>
}> = props => {
    const { node } = props;

    const updateFrequency = (e: React.MouseEvent<HTMLButtonElement>, mode: "sub" | "add") => {
        const isCtrl = e.ctrlKey;
        const isAlt = e.altKey;
        const delta = isCtrl ? 10 : (
            isAlt ? .1 : 1
        )
        node.state.update(prevState => {
            let hertz = Math.max(0, mode === "add" ? (
                prevState.hertz + delta
            ) : (
                prevState.hertz - delta
            ))

            hertz = Number(hertz.toFixed(2));

            return {
                hertz
            }
        })
        e.stopPropagation();
        updateInterval(node);
    }

    return (
        <div style={{
            padding: "8px 0",
            height: "100%",
            minWidth: "100%",
            display: "grid",
            gap: 4
        }}>
            <div style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "auto min-content",
                gap: 2
            }}>
                <input min={0} type={"number"} inputMode={"numeric"} max={250} value={node.state.state.hertz}
                       onChange={e => {
                           const hertz = Math.min(250, Number(e.currentTarget.value));
                           node.state.update({hertz})
                           updateInterval(node);
                       }} style={{
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    color: triton().col("fg_muted"),
                    width: "100%"
                }}/>
                <DescriptiveTypography text={"Hz"}/>
            </div>

            <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 2
            }}>
                <Tag tag={"-"} onClick={e => updateFrequency(e, "sub")}/>
                <Tag tag={"+"} onClick={e => updateFrequency(e, "add")}/>
                <Tag tag={"∞"} active={node.state.state.hertz === 0} onClick={() => {
                    node.state.update(prevState => ({
                        hertz: 0
                    }))
                    updateInterval(node);
                }}/>
            </div>

            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: 2
            }}>
                <Tag
                    tag={
                        <RefreshRounded sx={{
                            fontSize: 11
                        }}/>
                    }
                    applyActiveScaling
                    onClick={() => {
                        node.state.update(prevState => ({
                            enabled: false,
                            hertz: 1
                        }))
                        updateInterval(node);
                    }}
                />

                <Tag
                    tag={"on"}
                    active={node.state.state.enabled}
                    applyActiveScaling
                    onClick={() => {
                        node.state.update(prevState => ({
                            enabled: !prevState.enabled
                        }))
                        updateInterval(node);
                    }}
                />
            </div>
        </div>
    );
}
