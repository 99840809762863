import React, {useState} from "react";
import {RoundedFormikBaseInput} from "./RoundedFormikBaseInput";
import {FormikProps} from "formik";
import {VisibilityOffRounded, VisibilityRounded} from "@mui/icons-material";
import styled from "styled-components";

const StyledRoundedFormikPasswordInput = styled.div`
  position: relative;
  width: 100%;
  
  .adornment {
    position: absolute;
    right: 0;
    padding-right: 27px;
    top: 50%;
    transform: translateY(-50%);
    color: black;
    opacity: .6;
    display: flex;
    height: 100%;
    align-items: center;
  }
`;

export type RoundedFormikPasswordInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    fp: FormikProps<any>,
    name: string
}

export const RoundedFormikPasswordInput: React.FC<RoundedFormikPasswordInputProps> = props => {
    const [state, setState] = useState({
        showPassword: false
    });

    return (
        <StyledRoundedFormikPasswordInput>
            <RoundedFormikBaseInput
                {...props}
                type={state.showPassword ? "text" : "password"}
                placeholder={"Password"}
            />

            <span className={"adornment"} onPointerDown={e => {
                e.stopPropagation();
                e.preventDefault();
                setState(prevState => ({ ...prevState, showPassword: !prevState.showPassword }))
            }} children={state.showPassword ? (
                <VisibilityRounded/>
            ) : (
                <VisibilityOffRounded/>
            )}/>
        </StyledRoundedFormikPasswordInput>
    );
}
