import React, {CSSProperties} from "react";
import {Image} from "../data/Image";
import {useLiveQuery} from "dexie-react-hooks";
import {webDB} from "../webapi/WebAPIDB";
import {ImageRounded} from "@mui/icons-material";
import {ContentSensitivity} from "../webapi/cp/ContentSensitivity";
import {ContentSensitiveRenderer} from "../webapi/cp/components/ContentSensitiveRenderer";
import {ContentSensitivityAttachment} from "../webapi/cp/ContentSensitivityAttachment";

export type ISAImageProps = {
    imageID: string,
    imageRenderer?: (i: Image) => React.ReactElement,
    noImageComponent?: React.ReactElement,
    style?: CSSProperties,
    onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void,
    isaTable?: "images" | "sdInterfaceResults"
}

export const ISAImage: React.FC<ISAImageProps> = props => {
    const image = useLiveQuery(async () => {
        return webDB[props.isaTable ?? "sdInterfaceResults"]
            .where("id")
            .equals(props.imageID)
            .first();
    }, [props.imageID]);

    if (image === undefined) {
        return props.noImageComponent !== undefined ? props.noImageComponent : (
            <ImageRounded/>
        );
    }

    const localImageRenderer = () => {
        const imageSensitivity = image.contentSensitivity ?? ContentSensitivity.UNCLASSIFIED;
        const csa: ContentSensitivityAttachment = {
            sensitivity: imageSensitivity
        };

        return (
            <ContentSensitiveRenderer csa={csa} children={ctx => (
                <img
                    className={ctx.classes.img}
                    loading={"lazy"}
                    src={URL.createObjectURL(image.data)}
                    alt={image.id}
                    onClick={event => props.onClick?.(event)}
                    style={props.style}
                />
            )}/>
        );
    }

    return props.imageRenderer !== undefined ? props.imageRenderer(image) : localImageRenderer();
}
