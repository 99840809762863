import {CSSProperties, FC, PropsWithChildren} from "react";
import {Orientation} from "../../../../base/logic/style/Orientation";
import styled from "styled-components";

const StyledSectionTray = styled.div`
  &[data-orientation="VERTICAL"] {
    overflow-y: scroll;
  }

  &[data-orientation="VERTICAL"] {
    overflow-x: scroll;
  }
  
  &[data-elable-snapping=true] {
    &[data-orientation="VERTICAL"] {
      scroll-snap-type: x proximity;
    }

    &[data-orientation="VERTICAL"] {
      scroll-snap-type: y proximity;
    }
  }
`;

export type SectionTrayProps = {
    enableSnapping?: boolean,
    orientation?: Orientation
    s?: CSSProperties
}

export const SectionTray: FC<PropsWithChildren<SectionTrayProps>> = props => {
    const doesSnap = props.enableSnapping ?? false;
    const orientation = props.orientation ?? Orientation.VERTICAL;

    return (
        <StyledSectionTray
            children={props.children}
            data-elable-snapping={doesSnap}
            data-orientation={orientation}
            style={props.s}
        />
    );
}
