import React, {useContext, useRef} from "react";
import {TopicSelectorStateContext} from "./TopicSelectorStateContext";
import styled from "styled-components";
import {DeleteRounded, TagRounded} from "@mui/icons-material";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {IconButton} from "../../components/IconButton";
import {MetaBasicEntry} from "../../components/MetaBasicEntry";

const StyledDishView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  .header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    
    .left-header, .right-header {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
    }
  }
`;

export const DishView: React.FC = props => {
    const [state, setState] = useContext(TopicSelectorStateContext);
    const dishRef = useRef(state.dish!!);
    const dish = dishRef.current;

    return (
        <StyledDishView>
            <div className={"header"}>
                <div className={"left-header"}>
                    <TagRounded style={{ width: 18 }}/>
                    <MainTypography text={"Generated topic"}/>
                </div>
                <div className={"right-header"}>
                    <IconButton tooltip={"Clear"} size={"small"} onClick={() => {
                        setState(prevState => ({
                            ...prevState,
                            dish: undefined
                        }));
                    }} children={
                        <DeleteRounded/>
                    }/>
                </div>
            </div>

            <MetaBasicEntry title={"Seed"} value={dish.seed}/>
            <MetaBasicEntry title={"Topic"} value={dish.topic.title}/>
            <MetaBasicEntry title={"Spice prompt"} value={dish.spiceValues.join(", ")}/>
        </StyledDishView>
    );
}
