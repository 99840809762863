import {SaveStateNode} from "./SaveStateNode";
import {InflationContext} from "./InflationContext";
import {SerializableObject} from "../../../../../std/SerializableObject";

export namespace PhasingUtilities {

    export interface Phase<SaveStateType extends SerializableObject = SerializableObject> {
        inflate?(save: SaveStateType, node: SaveStateNode, ctx: InflationContext): void
    }

    export class Phaser<SaveStateType extends SerializableObject = SerializableObject> {

        private phases: Map<string, Phase<SaveStateType>> = new Map<string, PhasingUtilities.Phase<SaveStateType>>();

        public registerPhase(phaseKey: string, phase: Phase<SaveStateType>): this {
            this.phases.set(phaseKey, phase);
            return this;
        }

        inflate(save: SaveStateType, node: SaveStateNode, ctx: InflationContext) {
            const phase = ctx.parameters.phase;
            const isPhaseless = phase === undefined;
            if (isPhaseless) {
                console.warn("anonymous phases not yet supported")
                return;
            }
            this.phases.get(phase)?.inflate?.(save, node, ctx);
        }
    }
}
