import {RawStateEntry} from "./RawStateEntry";
import {as} from "../../atlas/Lang";

export type StateEntryExtractorConfig = {
    hiddenKeys: Array<string>
}

export class StateEntryExtractor {

    private readonly config: StateEntryExtractorConfig = {
        hiddenKeys: [
            "id", // user-given entity name
            "simID" // system given unique id
        ]
    }

    public extract(state: any, config: StateEntryExtractorConfig = this.config): Array<RawStateEntry> {
        return Object
            .entries(state)
            .map(e => as<RawStateEntry>({ key: e[0], value: e[1] }))
            .filter(e => !config.hiddenKeys.includes(e.key))
    }
}
