import React, {useContext} from "react";
import styled from "styled-components";
import {SDPromptField} from "../../../imageSorter/sdInterface/SDPromptField";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {Text} from "../../../triton/components/typography/Text";
import {SDInterfaceAPIContext} from "../../../imageSorter/sdInterface/SDInterfaceAPI";
import {SDInterfaceStateContext} from "../../../imageSorter/sdInterface/SDInterfaceMain";
import {
    CodeRounded,
    HistoryRounded, InfoRounded,
    LiveTvRounded,
    SettingsApplicationsRounded,
    TokenRounded
} from "@mui/icons-material";
import {TabBar} from "../../components/TabBar";
import {px} from "../../../base/logic/style/DimensionalMeasured";
import {TabBodyRenderer} from "../../components/TabBodyRenderer";
import {ArdspaceAIGenerateMain} from "../../views/generate/ArdspaceAIGenerateMain";
import {MainTab} from "../../../imageSorter/sdInterface/MainTab";
import {MixinTab} from "../../../imageSorter/sdInterface/MixinTab";
import {HistoryTab} from "../../../imageSorter/sdInterface/HistoryTab";
import {GenerationConfigTab} from "../../../imageSorter/sdInterface/GenerationConfigTab";
import {LiveProcessTab} from "../../../imageSorter/sdInterface/LiveProcessTab";
import {MobileGenerationViewNavigation} from "./MobileGenerationViewNavigation";
import {MobilePromptTab} from "./tabs/prompt/MobilePromptTab";
import {MobileMainTab} from "./tabs/main/MobileMainTab";

const StyledMobileGenerationView = styled.div`
  // display: grid;
  // grid-template-rows: auto max-content;
  
  display: flex;
  flex-direction: column;
  
  width: 100%;
  height: 100%;
  max-height: calc(100% - 0px);
  flex-grow: 0;
  position: relative;
  
  .mobile-generation-tab-body-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-grow: 1;
  }
`;

export const MobileGenerationView: React.FC = props => {
    const sdApi = useContext(SDInterfaceAPIContext);
    const state = useContext(SDInterfaceStateContext);

    return (
        <StyledMobileGenerationView>
            <div className={"mobile-generation-tab-body-container"} children={
                <TabBodyRenderer
                    active={state.activeTab}
                    tabs={new Map<string, () => React.ReactElement>([
                        ["main", () => <MobileMainTab/>],
                        ["prompt", () => <MobilePromptTab/>]
                    ])}
                />
            }/>

            <MobileGenerationViewNavigation/>
        </StyledMobileGenerationView>
    );
}
