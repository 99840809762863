import React, {useContext, useEffect, useState} from "react";
import {ArdaiAPIContext} from "../../webapi/WebAPI";
import {ArdaiAPIStateContext} from "../../ArdaiMain";
import {VFSElement} from "../../data/VFSElement";
import {ButtonGroup} from "../../components/ButtonGroup";
import {IconButton} from "../../components/IconButton";
import {
    ArrowLeftRounded,
    CreateNewFolderRounded,
    CreateRounded,
    MoreVertRounded,
    UploadRounded, WarningAmberRounded
} from "@mui/icons-material";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {Menu} from "../../components/Menu";
import {CheckMenuButton, MenuButton} from "../../components/MenuButton";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";
import {BooleanContext} from "../../../triton/components/BooleanContext";
import {FileStructureImportModal} from "../../components/FileStructureImportModal";
import {MenuDivider} from "@szhsin/react-menu";
import {VFSViewSettings} from "../../settings/VFSViewSettings";
import {VFSFolderContentViewer} from "../../components/VFSFolderContentViewer";
import {webDB} from "../../webapi/WebAPIDB";
import {ButtonModalCompound} from "../../components/ButtonModalCompound";
import {Modal} from "../../../triton/components/dialogs/Modal";
import {VFSFolderContentViewerV2} from "../../components/VFSFolderContentViewerV2";
import {FSPathDisplay} from "../../components/FSPathDisplay";
import {LocalVFSNav} from "../../components/LocalVFSNav";

export const VFSTrayTab: React.FC = props => {
    return (
        <div style={{
            display: "grid",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            gridTemplateRows: "min-content auto",
            gap: "8px",
        }}>
            <FSPathDisplay/>

            <div style={{
                paddingLeft: 8,
                overflowX: "visible",
                overflowY: "hidden",
                // overflowY: "scroll",
                // overflow: "scroll"
            }} children={
                <>
                    <VFSFolderContentViewerV2/>
                    {/* <VFSFolderContentViewer/> */}
                </>
            }/>
        </div>
    );
}
