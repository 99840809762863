import React, {useContext, useEffect, useState} from "react";
import {ArdaiAPIContext, WebAPI} from "../../webapi/WebAPI";
import {Challenge} from "./Challenge";
import {SearchLogic} from "../../logic/SearchLogic";
import {webDB} from "../../webapi/WebAPIDB";
import {ISAImage} from "../../components/ISAImage";
import styled from "styled-components";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {FlatIconButton} from "../../components/FlatIconButton";
import {
    CloseRounded,
    FullscreenRounded, MoreHorizRounded,
    OpenInBrowserRounded,
    OpenInFull,
    OpenInFullRounded, PlayArrowRounded,
    RefreshRounded,
    SelectAllRounded, TagRounded,
    ViewComfyRounded,
    ViewInArRounded
} from "@mui/icons-material";
import {ChallengeTimerComponent} from "./ChallengeTimerComponent";
import {randomInt} from "mathjs";
import {ChallengeDifficulty} from "./ChallengeDifficulty";
import {TabBar} from "../../components/TabBar";
import {Tag} from "../../components/Tag";
import {ColorableTag} from "../../components/ColorableTag";
import {AdvancedISAImage} from "../../components/AdvancedISAImage";
import {ButtonModalCompound} from "../../components/ButtonModalCompound";
import {Workspace} from "../../components/Workspace";
import {Image} from "../../data/Image";
import {ContextCompound} from "../../../base/components/base/ContextCompound";
import {Menu} from "../../components/Menu";
import {MenuButton} from "../../components/MenuButton";
import {useTriton} from "../../../triton/TritonHooks";
import {Modal} from "../../../triton/components/dialogs/Modal";
import {ChallengeTemplateCreator} from "../challengeTemplateCreator/ChallengeTemplateCreator";
import {px} from "../../../base/logic/style/DimensionalMeasured";

const StyledChallengerTab = styled.div`
  // display: flex;
  // flex-direction: column;
  // gap: 8px;
  // height: 60vh;
  overflow: hidden;
  height: 100%;
  
  display: grid;
  gap: 8px;
  grid-template-rows: repeat(3, min-content) auto min-content;
  
  .challenger-tab-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .left, .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;
    }
  }
  
  .challenge-image-container {
    flex-shrink: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
  }
  
  .challenge-info-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left, .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;
    }
  }
`;

export type ChallengerTabProps = {
    initialChallenge?: Challenge
}

export type ChallengerTabState = {
    challenge?: Challenge,
    maxDifficulty: ChallengeDifficulty,

    requireChallengeTag: boolean
}

export const ChallengerTab: React.FC<ChallengerTabProps> = props => {
    const api = useContext(ArdaiAPIContext);
    const t = useTriton();
    const [state, setState] = useState<ChallengerTabState>({
        challenge: props.initialChallenge,
        maxDifficulty: ChallengeDifficulty.NORMAL,
        requireChallengeTag: false
    });

    const renewChallenge = (config: Partial<ChallengeCreationConfig> = {
        requireChallengeTag: state.requireChallengeTag
    }) => new Promise<void>(async (resolve, reject) => {
        try {
            const challenge = await createChallenge(api, config);
            setState(prevState => ({
                ...prevState,
                challenge: challenge
            }));
        } catch (e) {
            reject();
        }
        resolve();
    })

    useEffect(() => {
        if (state.challenge !== undefined) return;
        renewChallenge().then(() => {});
    }, []);

    return (
        <StyledChallengerTab>
            <div className={"challenger-tab-toolbar"}>
                <div className={"left"}>
                    <MainTypography text={"Challenge"} noSelect/>
                </div>

                <div className={"right"}>
                    <ButtonModalCompound
                        borderless={true}
                        button={
                            <FlatIconButton
                                children={<FullscreenRounded fontSize={"small"}/>}
                                onClick={() => renewChallenge()}
                            />
                        }
                        modalContent={ctx => (
                            <div style={{
                                width: "100vw",
                                height: "100vh",
                                backgroundColor: "black",
                                // padding: "0 30%"
                            }} children={
                                <Workspace style={{ borderRadius: 0, overflow: "hidden" }} config={{ name: "challenger-tab-fullscreen", mode: "desktop" }} children={
                                    <ChallengerTab
                                        initialChallenge={state.challenge}
                                    />
                                }/>
                            }/>
                        )}
                    />

                    <FlatIconButton
                        tooltip={"Require 'challenge' tag"}
                        children={<TagRounded fontSize={"small"} style={{
                            transition: "color 150ms",
                            color: state.requireChallengeTag ? t.col("color_primary") : undefined
                        }}/>}
                        onClick={() => {
                            setState(prevState => ({
                                ...prevState,
                                requireChallengeTag: !prevState.requireChallengeTag
                            }))
                        }}
                    />

                    <FlatIconButton
                        children={<RefreshRounded fontSize={"small"}/>}
                        onClick={() => renewChallenge()}
                    />

                    <FlatIconButton
                        children={<CloseRounded fontSize={"small"}/>}
                        onClick={() => {
                            setState(prevState => ({
                                ...prevState,
                                challenge: undefined
                            }))
                        }}
                    />

                    <Menu opener={
                        <FlatIconButton
                            children={<MoreHorizRounded fontSize={"small"}/>}
                        />
                    }>
                        <MenuButton text={"Challenge from selected image"} onSelect={async () => {
                            await renewChallenge({
                                requireChallengeTag: state.requireChallengeTag,
                                image: await api.getCurrentSelectedImageInView()
                            });
                        }}/>

                        <ButtonModalCompound button={
                            <MenuButton text={"Challenge template creator"}/>
                        } modalContent={ctx => (
                            <Modal
                                open={true}
                                onClose={ctx.close}
                                title={"Challenge template creator"}
                                w={px(1200)}
                                children={
                                    <ChallengeTemplateCreator/>
                                }
                            />
                        )}/>
                    </Menu>
                </div>
            </div>

            <TabBar
                tabs={(Object.keys(ChallengeDifficulty).map(key => ChallengeDifficulty[key as any]).filter(value => typeof value === 'string') as string[]).map(e => ({
                    title: e.toLowerCase(),
                    id: e,
                }))}
                activeBar={ChallengeDifficulty[state.maxDifficulty as any]}
                onTabChange={maxDifficulty => {
                    setState(prevState => ({
                        ...prevState,
                        maxDifficulty: ChallengeDifficulty[maxDifficulty as any] as any
                    }))
                }}
                equalSizeMode
            />

            { state.challenge && (
                <>
                    <ChallengeTimerComponent time={state.challenge.time} onCompleted={(acknowledge, onAcknowledge) => {
                        let src = 'https://file-examples.com/storage/fee710faaf65ae8909c8513/2017/11/file_example_MP3_700KB.mp3';
                        let audio = new Audio(src);
                        onAcknowledge(() => audio.pause());
                        audio.play().then(() => {});
                    }}/>

                    {/* <AdvancedISAImage image={state.challenge.image}/> */}

                    <div className={"challenge-image-container"} children={
                        <ISAImage
                            isaTable={"images"}
                            imageID={state.challenge.image.id}
                            style={{
                                position: "absolute",
                                height: "auto",
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                                objectPosition: "center center",

                                // display: "block",
                                // height: "100%",
                                // width: "100%",
                                // // maxWidth: "100%",
                                // maxHeight: "100%",
                                borderRadius: 8,
                                // overflow: "hidden",
                                // objectFit: "contain"
                            }}
                        />
                    }/>

                    <div className={"challenge-info-footer"}>
                        <Tag tag={`${ChallengeDifficulty[state.challenge.difficulty]}`} />

                        <div className={"left"}>
                            <FlatIconButton
                                children={<SelectAllRounded fontSize={"small"}/>}
                                onClick={() => api.selectionManager.select([state.challenge!.image.id])}
                            />
                            <FlatIconButton
                                children={<OpenInBrowserRounded fontSize={"small"}/>}
                                onClick={() => api.selectImageByID(state.challenge!.image.id, true)}
                            />
                        </div>
                    </div>
                </>
            ) }

            { !state.challenge && (
                <>
                    <div/>
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }} children={
                        <FlatIconButton
                            tooltip={"Start a challenge"}
                            children={<PlayArrowRounded fontSize={"small"}/>}
                            onClick={() => renewChallenge()}
                        />
                    }/>
                </>
            ) }
        </StyledChallengerTab>
    );
}

export type ChallengeCreationConfig = {
    image: Image,
    requireChallengeTag: boolean
}

async function createChallenge(api: WebAPI, config: Partial<ChallengeCreationConfig> = {}): Promise<Challenge> {
    // Image
    let image: Image;
    if (config.image === undefined) {
        const challengeTag = "challenge";
        let searchLet = "";
        if (config.requireChallengeTag) searchLet += `#${challengeTag} `;
        searchLet += ":rnd";
        const compiledSearchLet = new SearchLogic().parseImageQuery(searchLet, api);
        let candidates = await webDB.images.limit(1024).toArray();
        candidates = await compiledSearchLet(candidates);
        if (candidates.length < 1) throw Error("No matching image");
        image = candidates[0];
    } else image = config.image!;


    // Time
    const timeCandidatesInM = [.5, 1, 1.5, 2, 2.5, 5, 10, 15]; // TODO: Take
    const tCInMIndex = randomInt(0, timeCandidatesInM.length);
    const time = timeCandidatesInM[tCInMIndex];

    return ({
        image: image,
        time: time * 6e1,
        difficulty: ChallengeDifficulty.NORMAL
    });
}

