import React, {useState} from "react";
import styled from "styled-components";
import {Workspace} from "../components/Workspace";
import {WorkspaceConfig} from "../data/WorkspaceConfig";
import {ComponentTest} from "./ComponentTest";
import {SubHeaderTypography} from "../../triton/components/typography/SubHeaderTypography";
import {DType} from "./DType";
import {JSDataType} from "./JSDataType";
import {UpdateFieldFunc} from "./UpdateFieldFunc";
import {FieldRenderer} from "./FieldRenderer";
import {DefaultButton} from "../components/DefaultButton";
import {BlendMode, blendModes} from "../../triton/BlendMode";
import {BasicSingleSelect} from "../../triton/components/forms/BasicSingleSelect";
import {Color} from "../../base/logic/style/Color";
import {LayersClearRounded, LayersRounded} from "@mui/icons-material";
import {ControlledColorPicker} from "../../triton/components/forms/ControlledColorPicker";
import {Menu} from "../components/Menu";

const StyledTestingViewMain = styled.div`
  display: grid;
  grid-template-columns: min-content auto min-content;
  gap: 8px;

  .testing-main {
    background-color: rgb(16, 16, 22);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;

    &:hover {
      .toolbar {
        opacity: 1;
      }
    }

    .toolbar {
      position: absolute;
      top: 0;
      right: 0;
      padding: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      // opacity: 0;
      transition: opacity 30ms;

      .blend-mode-selector-container {
        width: 150px;
      }
    }

    .testing-main-component {
      color: white;
    }

    .testing-main-ref-container {
      position: absolute;

      .testing-main-ref {
        user-select: none;
        //noinspection CssUnknownProperty
        user-drag: none;
      }
    }
  }

  .testing-controls {
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const controlWorkspaceConfig: WorkspaceConfig = {
    name: "dev.component@control",
    resizable: true,
    mode: "desktop",
    resizablePropertyOverrides: {
        resizeHandles: ["w"]
    }
}

export const controlMetaWorkspaceConfig: WorkspaceConfig = {
    name: "dev.component@netacontrol",
    resizable: true,
    mode: "desktop",
    resizablePropertyOverrides: {
        resizeHandles: ["e"]
    }
}

export type TestingViewMainProps<T> = {
    test: ComponentTest<T>
}

export type TestingViewMainState = {
    componentProps: any,
    showReference: boolean,
    componentOpacity: number,
    reverseReferenceLayer: boolean,
    refBlendMode: BlendMode,
    blendColor: Color,
    enableRefBlending: boolean,
    refDy: number
}

export function TestingViewMain<T>(props: TestingViewMainProps<T>) {
    const [state, setState] = useState<TestingViewMainState>({
        componentProps: props.test.defaultProps,
        showReference: false,
        componentOpacity: 1,
        reverseReferenceLayer: false,
        refBlendMode: "normal",
        blendColor: Color.white,
        enableRefBlending: false,
        refDy: 0
    });

    const updateField: UpdateFieldFunc = (key: string, value: any) => setState(prevState => {
        return ({
            ...prevState,
            componentProps: {
                ...prevState.componentProps,
                [key]: value
            }
        });
    });

    const fieldRenderer = new FieldRenderer(updateField);

    const metaFieldRenderer = new FieldRenderer((key, value) => setState(prevState => {
        return ({
            ...prevState,
            [key]: value
        });
    }));

    const mapDefaultTypeAsDType = (jsDT: JSDataType): DType => {
        switch (jsDT) {
            case "string":
                return DType.STRING;
            case "number":
                return DType.NUMBER;
            case "boolean":
                return DType.BOOLEAN;
            case "bigint":
            case "symbol":
            case "undefined":
            case "object":
            case "function":
                return DType.UNKNOWN;
        }
    }

    const getDType = (property: keyof T, value: any): DType => {
        const lookup = props.test.propertyDTypeLookup;
        const lookupType = (lookup as unknown as {[key in keyof T]: DType})?.[property];
        if (lookupType === undefined) return mapDefaultTypeAsDType(typeof value);
        return lookupType
    }

    return (
        <StyledTestingViewMain>
            <Workspace config={controlMetaWorkspaceConfig} children={
                <div className={"testing-controls"}>
                    <SubHeaderTypography text={"Workbench configuration"}/>

                    {
                        // Object.entries(state).map(e => metaFieldRenderer.renderField(
                        //     e[0],
                        //     getDType(e[0] as any, e[1]),
                        //     e[1]
                        // ))
                    }

                    {/*
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FseWqOIpGctcA0kfrIa95m7%2FArd%3Ftype%3Ddesign%26node-id%3D0%253A1%26mode%3Ddesign%26t%3DF8FwzSofAd0898qM-1"
                        allowFullScreen
                    />
                    */}
                </div>
            }/>

            <div className={"testing-main"}>
                <div className={"toolbar"}>

                    <input
                        title={"Reference offset y"}
                        value={state.refDy}
                        type={"number"}
                        min={0}
                        onChange={e => setState(prevState => ({ ...prevState, refDy: Number(e.target.value) }))}
                    />

                    <input
                        value={state.componentOpacity * 100}
                        type={"number"}
                        min={0}
                        max={100}
                        step={1}
                        onChange={e => setState(prevState => ({ ...prevState, componentOpacity: Number(e.target.value) / 100 }))}
                    />

                    <Menu
                        menuProps={{ containerProps: { style: { flexGrow: 1 } } }}
                        opener={<input className={"color-input"} value={state.blendColor.toHex()} type={"color"} onClick={e => e.preventDefault()}/>}
                        children={<ControlledColorPicker
                            color={state.blendColor}
                            onChange={color => setState(prevState => ({ ...prevState, blendColor: color }))}
                        />}
                    />

                    <DefaultButton
                        size={"small"}
                        children={
                            <>
                                { state.showReference ? <LayersRounded/> : <LayersClearRounded/> }
                                Enable reference
                            </>
                        }
                        variant={state.showReference ? "primary" : "default"}
                        onClick={() => setState(prevState => ({ ...prevState, showReference: !prevState.showReference }))}
                    />
                    <DefaultButton
                        size={"small"}
                        children={"Reverse ref layer"}
                        variant={state.reverseReferenceLayer ? "primary" : "default"}
                        onClick={() => setState(prevState => ({ ...prevState, reverseReferenceLayer: !prevState.reverseReferenceLayer }))}
                    />
                    <DefaultButton
                        size={"small"}
                        children={"Enable ref blending"}
                        variant={state.enableRefBlending ? "primary" : "default"}
                        onClick={() => setState(prevState => ({ ...prevState, enableRefBlending: !prevState.enableRefBlending }))}
                    />

                    <div className={"blend-mode-selector-container"} children={
                        <BasicSingleSelect name={"blend-mode"} selected={state.refBlendMode} options={blendModes.map(bm => ({
                            text: bm, id: bm, color: bm !== "normal" ? undefined : Color.ofHex("#c9d1d9")
                        }))} onSelect={bm => {
                            setState(prevState => ({
                                ...prevState,
                                refBlendMode: bm as BlendMode
                            }));
                        }}/>
                    }/>
                </div>


                <div
                    className={"testing-main-component"}
                    children={props.test.generateComponent(state.componentProps)}
                    style={{
                        opacity: state.reverseReferenceLayer ? state.componentOpacity : 1,
                        zIndex: state.reverseReferenceLayer ? 2 : 1
                    }}
                />
                { state.showReference && (
                    <div className={"testing-main-ref-container"} style={{
                        opacity: state.reverseReferenceLayer ? 1 : state.componentOpacity,
                        zIndex: state.reverseReferenceLayer ? 1 : 2,
                        background: state.enableRefBlending ? state.blendColor.css() : undefined,
                        marginTop: state.refDy
                    }} children={
                        <img alt={"testing-main-ref"} className={"testing-main-ref"} src={require("..//assets/development/references/profile-large-ref.png")} style={{
                            mixBlendMode: state.enableRefBlending ? state.refBlendMode : undefined,
                        }}/>
                    }/>
                ) }
            </div>

            <Workspace config={controlWorkspaceConfig} children={
                <div className={"testing-controls"}>
                    <SubHeaderTypography text={"Properties"}/>

                    {
                        Object.entries(state.componentProps).map(e => fieldRenderer.renderField(
                            e[0],
                            getDType(e[0] as any, e[1]),
                            e[1]
                        ))
                    }
                </div>
            }/>
        </StyledTestingViewMain>
    );
}
