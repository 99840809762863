import React from "react";
import styled from "styled-components";
import {PropsDeclarationPrefix} from "./PropsDeclarationPrefix";
import {IconButton} from "../components/IconButton";
import {AddRounded, RemoveRounded} from "@mui/icons-material";
import {ButtonGroup} from "../components/ButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import {Switch} from "@mui/material";

const StyledBooleanField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  
  .boolean-field-controls {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .boolean-switch-input {
      cursor: pointer;
    }
  }
`;

export type BooleanFieldProps = {
    name: string,
    data: boolean,
    onChange: (data: boolean) => void
}

export const BooleanField: React.FC<BooleanFieldProps> = props => {
    return (
        <StyledBooleanField>
            <PropsDeclarationPrefix name={props.name} type={"bool"}/>


            <div className={"boolean-field-controls"}>
                <input className={"boolean-switch-input"} type={"checkbox"} checked={props.data} onChange={e => props.onChange(e.target.checked)}/>

                {/* <Switch checked={props.data} onChange={(e, b) => props.onChange(b)}/> */}
            </div>
        </StyledBooleanField>
    );
}
