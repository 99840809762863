import React, {useContext} from "react";
import styled from "styled-components";
import {ListItem} from "../../../../ardai/_/ListItem";
import {
    ControlCameraRounded,
    EngineeringRounded,
    RouterRounded,
    SelectAllRounded, TagRounded,
    TwoWheelerRounded
} from "@mui/icons-material";
import {Text} from "../../../../triton/components/typography/Text";
import {MainTypography} from "../../../../triton/components/typography/MainTypography";
import {SimulationWindowAPI} from "../SimulationWindowAPI";
import {SimulationWindowAPIContext} from "../VesselSimulationMain";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {ShipMovementPanel} from "../../entities/ship/ShipMovementPanel";
import {BooleanContext} from "../../../../triton/components/BooleanContext";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {ButtonBase} from "../../../../triton/components/buttons/ButtonBase";
import {CollapseIconButton} from "../../../../ardai/components/CollapseIconButton";
import {IStaticTreeNode} from "../../../../triton/tree/IStaticTreeNode";
import {TabConfig} from "../tab/TabConfig";
import {TabDisplay} from "../TabDisplay";
import {StdTabLibrary} from "../tab/StdTabLibrary";

const StyledTabSelectionView = styled.div`
  background-color: #010409;
  width: 350px;
  height: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  
  .tab-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
`;

type typed<Type, T> = T & {
    type: Type
}

export type BranchType = typed<"branch", {
    title: string,
    description?: string
}>

export type LeafType = typed<"leaf", TabConfig>;

export type TabStructureNode = IStaticTreeNode<BranchType | LeafType>;

const tabStructure: Array<TabStructureNode> = [
    {
        children: [], data: {
            type: "leaf",
            id: "test tab",
            staticTabName: "Dashboard",
            renderer: () => <></>
        }
    },
    {
        children: [], data: {
            type: "branch",
            title: "All",
            description: "Lists all views."
        }
    },
    {
        data: {
            type: "branch",
            title: "Std bridge",
            description: "Standard bridge 4/3-aspect-ratio views."
        },
        children: ["Steering", "Engine", "Sonar", "Radar"].map(name => ({
            children: [], data: {
                type: "leaf",
                id: name,
                staticTabName: name,
                renderer: () => <></>
            }
        })),
    },
    {
        data: {
            type: "branch",
            title: "Deprecated",
        },
        children: StdTabLibrary.tabs.map(tab => ({
            children: [], data: {
                type: "leaf",
                ...tab,
            }
        }))
    }
]

export const TabSelectionView: React.FC = props => {
    const api = useContext(SimulationWindowAPIContext);
    const currentTab = api.currentTab;
    const currentTabID = currentTab.id;

    return (
        <StyledTabSelectionView>

            <MainTypography text={"Tabs"}/>

            <div className={"tab-list"}>
                { tabStructure.map(node => (
                    <TabDisplay node={node}/>
                )) }
            </div>

            <BooleanContext children={(bool, setBool) => (
                <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                    <CollapseIconButton open={bool} onToggle={open => setBool(open)}/>

                    <TransitionGroup children={bool && (
                        <Collapse children={
                            <ShipMovementPanel/>
                        }/>
                    )}/>
                </div>
            )}/>



        </StyledTabSelectionView>
    );
}
