import {StorybookPage} from "../../../../storybook/StorybookPage";
import axios from "axios";
import {arcURL} from "../../../../../Globals";
import {Flex} from "../../../../Flex";
import {StorybookStaticStepIndicator} from "../../../../components/StorybookStaticStepIndicator";
import {TypoHeaderVisual} from "../../../../components/TypoHeaderVisual";
import {FormElement} from "../../../../../../../triton/components/forms/FormElement";
import {RoundedFormikBaseInput} from "../../../../components/form/RoundedFormikBaseInput";
import {ErrorMessage} from "../../../../../../../triton/components/forms/ErrorMessage";
import {RoundedMainTextButton} from "../../../../components/RoundedMainTextButton";
import {CircularProgress} from "@mui/material";
import {BoardingActionHint} from "../../../../components/BoardingActionHint";
import React from "react";
import {passwordPage} from "./PasswordStoryPage";
import {BoardingFlowMode} from "../../../boarding/BoardingFlowMode";

export type EmailAndUsernameStoryPageData = {
    email: string,
    username: string
}

export const emailAndUsernamePage: StorybookPage<EmailAndUsernameStoryPageData> = {
    name: "emailAndUsername",
    title: "Email & Username",
    initialValues: { email: "", username: "" },
    validateOnChange: false,
    validate: async (values, errors) => {
        // Preflight email tests
        if (!values.email?.includes("@")) errors.email = "Invalid email";
        if (values.email?.trim() === "") errors.email = "Email cannot be blank";
        // Prevent unnecessary traffic from happening
        if (!errors.email) {
            // Check email availability
            try {
                const emailUsed = (await axios({
                    method: "post",
                    url: arcURL(`account/check-email-available/${values.email}`),
                })).data.payload;
                if (emailUsed) errors.email = "Email already in use";
            } catch (e) {
                errors.email = `${e}`;
            }
        }

        // Preflight username tests
        if (values.username?.trim().length < 3) errors.username = "A username must be at least 3 characters long";
        if (values.username?.trim().length >= 20) errors.username = "A username cannot be longer than 20 characters";
        if (values.username?.trim() === "") errors.username = "Username cannot be blank";
        // Prevent unnecessary traffic from happening
        if (!errors.username) {
            // Check username availability
            try {
                const usernameUsed = (await axios({
                    method: "post",
                    url: arcURL(`account/check-username-available/${values.username}`),
                })).data.payload;
                if (usernameUsed) errors.username = "Username already in used";
            } catch (e) {
                errors.username = `${e}`;
            }
        }
    },
    header: (api, fp) => (
        <Flex g={30} baseProps={{
            style: {
                flexDirection: "column",
                alignItems: "center"
            }
        }}>
            <StorybookStaticStepIndicator step={1}/>
            <TypoHeaderVisual
                header={"Welcome!"}
                descriptiveSubheader={"We’re happy to see you. Please create an account to continue"}
                text={"We’ll get you scrolling in under one minute!"}
            />
        </Flex>
    ),
    onSubmit: (api, values) => {
        api.setPageData("emailAndUsername", values);
    },
    page: (api, fp) => (
        <>
            <FormElement>
                <RoundedFormikBaseInput
                    fp={fp}
                    name={"email"}
                    type={"email"}
                    placeholder={"Email"}
                    autoCorrect={"false"}
                />
                <ErrorMessage formikProps={fp} name={"email"}/>
            </FormElement>
            <FormElement>
                <RoundedFormikBaseInput
                    fp={fp}
                    name={"username"}
                    type={"text"}
                    placeholder={"Username"}
                    autoCorrect={"false"}
                />
                <ErrorMessage formikProps={fp} name={"username"}/>
            </FormElement>
        </>
    ),
    bottomActionPanel: (api, fp, isSubmitting) => (
        <RoundedMainTextButton
            text={!fp.isSubmitting ? "Next" : (
                <CircularProgress size={22} thickness={5} style={{ color: "black" }}/>
            )}
            onClick={() => api.submitAndForward(passwordPage)}
        />
    ),
    footer: (api) => (
        <BoardingActionHint
            question={"Already have an account?"}
            action={"Log In"}
            onClick={() => api.signal(
                "switch-to-login",
                BoardingFlowMode.LOGIN,
                () => api.getNavigateFunction()("boarding/login")
            )}
        />
    )
}
