import React, {useContext} from "react";
import styled from "styled-components";
import {SDInterfaceAPIContext} from "../../../../../imageSorter/sdInterface/SDInterfaceAPI";
import {SDInterfaceStateContext} from "../../../../../imageSorter/sdInterface/SDInterfaceMain";
import {Formik} from "formik";
import {FormikBasicSlider} from "../../../../../triton/components/forms/FormikBasicSlider";
import {FormDivider} from "../../../../../triton/components/forms/FormDivider";

const StyledMobileMainTab = styled.div`
  
  
  .main-form-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const MobileMainTab: React.FC = props => {
    const sdApi = useContext(SDInterfaceAPIContext);
    const state = useContext(SDInterfaceStateContext);

    return (
        <StyledMobileMainTab>
            <Formik initialValues={sdApi.req} onSubmit={req => {}} validate={req => {sdApi.updateRequest(req)}} children={fp => (
                <div className={"main-form-container"}>
                    <FormDivider title={"Sampler"}/>
                    <FormikBasicSlider fp={fp} name={"samplingSteps"} defaultValue={20}/>
                    <FormikBasicSlider fp={fp} name={"cfgScale"} defaultValue={20}/>

                </div>
            )}/>
        </StyledMobileMainTab>
    );
}
