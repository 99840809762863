import {FC} from "react";
import styled from "styled-components";
import {Triton} from "../../../triton/Triton";
import {useTriton} from "../../../triton/TritonHooks";

import Vase1 from "../assets/images/abstract/vase-1.png";
import SampleImageToBeReplaced from "../assets/images/abstract/Screenshot_16.png";

const StyledLandingPageFocalPointContent = styled.div<{
    t: Triton
}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  
  .portal {
    --border-radius-round: 10000px;
    width: 450px;
    max-width: 450px;
    height: 620px;
    border-radius: var(--border-radius-round);
    position: relative;
    
    :before {
      --bleed: 20px;
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transform-origin: center;
      width: calc(100% + 2 * var(--bleed));
      height: calc(100% + 2 * var(--bleed));
      border-radius: var(--border-radius-round);

      border: 2px solid ${p => p.t.col("fg_muted")};
    }

    img {
      user-select: none;
      border-radius: var(--border-radius-round);
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    } 
  }
`;

export const LandingPageFocalPointContent: FC = props => {
    const t = useTriton();

    return (
        <StyledLandingPageFocalPointContent t={t}>
            <div className={"portal"}>
                <img src={Vase1}/>
            </div>
        </StyledLandingPageFocalPointContent>
    );
};
