import {FC, PropsWithChildren} from "react";
import styled from "styled-components";

const StyledSection = styled.section`
  position: relative;
  width: 100%;
  padding: 0 100px;
  margin: 125px 0;
  
  &[data-is-snapping=true] {
    scroll-snap-align: start;
  }
  
  &[data-is-seemlsee=true] {
    margin: 0;
  }
  
  &[data-is-dense=true] {
    padding: 0 300px;
  }
`;

export const Section: FC<PropsWithChildren<{
    dense?: boolean,
    seamless?: boolean,
    snap?: boolean
}>> = props => {
    const isDense = props.dense ?? true;
    const isSeamless = props.seamless ?? false;
    const isSnapping = props.snap ?? false;

    return (
        <StyledSection
            data-is-dense={isDense}
            data-is-seemless={isSeamless}
            data-is-snapping={isSnapping}
            children={props.children}
        />
    );
}
