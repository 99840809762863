import React, {useContext, useState} from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {KSU, SimulationContext} from "../../Simulation2Main";
import {AzypodState} from "../azypod/AzypodEntity";
import {VectorIndicator} from "../../VectorIndicator";
import {
    invertPolarCoordinates,
    PolarCoordinates,
    rotatePolarCoordinates,
    scalePolarCoordinates
} from "../../math/PolarCoordinates";
import {StyledControlDisplayGroup} from "../../components/StyledControlDisplayGroup";
import {Interpolate} from "../../../../triton/components/advanced/Interpolate";
import {AnimatePresence, motion, useMotionValue, useSpring, useTime, useTransform} from "framer-motion";
import {Entity} from "../../ecs/entity/Entity";
import {ShipMainEntity} from "./ShipMainEntity";
import {castEntity, castEntityFull} from "../../SimStd";
import {PolarVec2D} from "../../math/PolarVec2D";
import {hex, ofHex} from "../../../../base/logic/style/Color";
import {as} from "../../../../atlas/Lang";
import {PIDController} from "../../math/PIDController";
import {CompassAnnotation} from "../../CompassAnnotation";
import {EnvironmentSimulationEntity} from "../ambient/EnvironmentSimulationEntity";
import {SteeringAPEntity} from "../ap/SteeringAPEntity";
import {AutoModeRounded, NorthRounded} from "@mui/icons-material";
import {FlatIconButton} from "../../../../ardai/components/FlatIconButton";
import {Button} from "../../components/Button";
import {stat} from "fs";

const StyledShipMovementPanel = styled.div`
  aspect-ratio: 1 / 1;
  height: 300px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;

  .compass-disk {
    position: absolute;
    z-index: 1;
    background-color: rgba(47, 79, 79, 0.1);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // transition: rotate 150ms ease-in-out;
    
    color: lawngreen;
    font-size: 12px;
    
    .pointer {
      position: absolute;
      height: 100%;
    }
  }

  .inner-disk {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ship {
    height: 175px;
    aspect-ratio: .4 / 1;
    // border: 1px solid #ffdf60;
    position: relative;
    box-shadow: rgb(33, 38, 45) 0 0 0 1px;;

    .azypod-anchor {
      position: absolute;
      width: 20px;
      aspect-ratio: 1 / 1;
      border: 1px solid #ffdf60aa;
      bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mass-point {
      position: absolute;
      width: 9px;
      border-radius: 50%;
      aspect-ratio: 1 / 1;
      background-color: rgb(33, 38, 45);
      bottom: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  .compass-tools {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px;
    z-index: 100;
  }
`;

export enum CompassAlignment {
    NORTH, HEADING
}

export type ShipMovementPanelState = {
    alignment: CompassAlignment,
    showAnnotations: boolean
}

export const ShipMovementPanel: React.FC = props => {
    const sim = useContext(SimulationContext);
    const portAzypod = sim.entity("port-azypod");
    const azyMag = .5 * portAzypod?.state[KSU] ?? -1;
    const azyRot = portAzypod?.state.actualDeg ?? 0;

    const azyPolar = new PolarVec2D(azyRot, azyMag);

    const ship = castEntityFull<ShipMainEntity>(sim.entity("ship"));
    const heading = ship?.state?.heading ?? new PolarVec2D();
    const shipCurrentVelocity = heading.r;

    const steeringAP = sim.entity("auto").as<SteeringAPEntity>();
    const headingController = steeringAP.getHeadingPIDController();

    const env = castEntityFull<EnvironmentSimulationEntity>(sim.entity("env"));
    const windVec = env?.state?.wind ?? new PolarVec2D;
    const windDirection = windVec.theta;

    const [state, setState] = useState<ShipMovementPanelState>({
        alignment: CompassAlignment.HEADING,
        showAnnotations: true
    });

    return (
        <StyledControlDisplayGroup>
            <StyledShipMovementPanel>
                <motion.div className={"compass-disk"} transition={{
                    duration: 1,
                    damping: 100
                }} animate={{
                    rotate: state.alignment === CompassAlignment.HEADING ? heading.theta : 0
                }}>
                    { ["N", "NE", "E", "SE", "S", "SW", "W", "NW"].map((v, i, arr) => (
                        <span className={"pointer"} style={{
                            rotate: `${(360 / arr.length) * i}deg`
                        }} children={v}/>
                    )) }

                    {/* TODO: Add annotation enter/leave animations */}
                    <AnimatePresence children={state.showAnnotations && (
                        <>
                            <CompassAnnotation
                                rotation={headingController?.target ?? 0}
                                bgColor={ofHex(steeringAP?.state?.engaged ? "#ffdf60" : "#21262D")}
                                children={
                                    <>
                                        <DescriptiveTypography text={"auto"}/>
                                        <DescriptiveTypography text={`${headingController?.target ?? 0}°`}/>
                                    </>
                                }
                            />

                            <CompassAnnotation
                                rotation={windDirection}
                                bgColor={ofHex("#60ffc7")}
                                children={
                                    <>
                                        <DescriptiveTypography text={"wind"}/>
                                        <DescriptiveTypography text={`${windDirection}kn`}/>
                                    </>
                                }
                            />
                        </>
                    )}/>



                    {/*
                    <DescriptiveTypography text={
                        `θ=${Math.round(heading.theta)}, r=${heading.r}`
                    }/>
                    */}
                </motion.div>

                <div className={"inner-disk"}>
                    <motion.div className={"ship"} transition={{
                        duration: 1,
                        damping: 100
                    }} animate={{
                        rotate: state.alignment === CompassAlignment.NORTH ? -heading.theta : 0
                    }}>

                        <div className={"tunnel-thruster-anchor"} style={{
                            position: "absolute",
                            width: "100%",
                            height: "20px",
                            top: 16,
                            border: "1px dashed #ffdf60aa"
                        }}>
                            <div style={{
                                width: "100%",
                                height: "100%",
                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }} children={
                                <div className={"azypod-anchor"} style={{
                                    bottom: "unset"
                                }}>
                                    <VectorIndicator thetaOffset={90} for={
                                        new PolarVec2D(0, azyPolar.times(.5).r)
                                    }/>
                                </div>
                            }/>
                        </div>

                        <div className={"azypod-anchor"} style={{
                            bottom: 8,
                            left: 8
                        }}>
                            <VectorIndicator hideInverseHint={false} thetaOffset={180} for={azyPolar} tipElement={
                                // <VectorIndicator for={
                                //     azyPolar.times(.5).rotate(-90)
                                // }/>
                                <></>
                            }/>
                        </div>

                        <div className={"azypod-anchor"} style={{
                            bottom: 8,
                            right: 8
                        }}>
                            <VectorIndicator hideInverseHint={false} thetaOffset={180} for={azyPolar} tipElement={
                                // <VectorIndicator for={
                                //     azyPolar.times(.5).rotate(-90)
                                // }/>
                                <></>
                            }/>
                        </div>

                        <div className={"mass-point"}>
                            <VectorIndicator
                                // .rotate(ship?.state?.heading?.theta ?? 0)
                                for={new PolarVec2D(-windDirection, windVec.r).rotate(ship?.state?.heading?.theta ?? 0)}
                                thetaOffset={180}
                                color={ofHex("#60ffc7")}
                                tipElement={
                                    <VectorIndicator
                                        thetaOffset={180}
                                        for={azyPolar.invert()}
                                    />
                                }
                            />
                        </div>

                        <div className={"velocity-anchor"} style={{
                            position: "absolute",
                            top: 0,
                            left: "50%",
                            transform: "translateX(-50%)"
                        }}>
                            <VectorIndicator
                                for={new PolarVec2D(0, shipCurrentVelocity * .5)}
                                color={ofHex("#60ffc7")}
                            />
                        </div>
                    </motion.div>
                </div>

                <div className={"compass-tools"}>
                    <div style={{
                        marginRight: "auto"
                    }} children={
                        <Button
                            border={false}
                            square
                            monospace
                            children={"@"}
                            onClick={() => setState(prevState => ({ ...prevState, showAnnotations: !prevState.showAnnotations }))}
                            color={{
                                if: [{ cond: () => state.showAnnotations, color: hex("#8b949e") }]
                            }}
                        />
                    }/>


                    <Button
                        border={false}
                        square
                        children={<NorthRounded/>}
                        onClick={() => setState(prevState => ({ ...prevState, alignment: CompassAlignment.NORTH }))}
                        color={{
                            if: [{ cond: () => state.alignment === CompassAlignment.NORTH, color: hex("#8b949e") }]
                        }}
                    />
                    <Button
                        border={false}
                        square
                        children={<AutoModeRounded/>}
                        onClick={() => setState(prevState => ({ ...prevState, alignment: CompassAlignment.HEADING }))}
                        color={{
                            if: [{ cond: () => state.alignment === CompassAlignment.HEADING, color: hex("#8b949e") }]
                        }}
                    />
                </div>
            </StyledShipMovementPanel>
        </StyledControlDisplayGroup>
    );
}
