import React from "react";
import {RoundedFormikBaseInput, RoundedFormikBaseInputProps} from "./RoundedFormikBaseInput";
import styled from "styled-components";
import {CheckRounded} from "@mui/icons-material";
import {DescriptiveTypography} from "../../../../../triton/components/typography/DescriptiveTypography";
import {ArcText} from "../../ArcText";

const StyledRoundedFormikCheckboxInputWrapper = styled.span`
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  border-radius: 500px;
  height: 46px;
  
  .foreground {
    z-index: 2;
    display: flex;
    align-items: center;
    padding-left: 22px;
    padding-right: 22px;
    gap: 15px;
    
    .checkbox {
      border-radius: 6px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FFEAC4;
      
      * {
        color: rgba(0, 0, 0, .6);
      }
    }
    
    p, span {
      user-select: none;
      color: rgba(0, 0, 0, .6) !important;
      font-size: 14px !important;
    }
  }
  
  
`;

const StyledRoundedFormikCheckboxInput = styled.input`
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  color: black;
  padding-left: 22px !important;
  padding-right: 22px;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  -webkit-appearance: none;
  margin: 0 !important;
  
  &:focus {
    outline: none;
    border: none;
  }
`;

export type RoundedFormikCheckboxInputProps = RoundedFormikBaseInputProps

export const RoundedFormikCheckboxInput = React.forwardRef<HTMLInputElement, RoundedFormikCheckboxInputProps>((props, ref) => {
    return (
        <StyledRoundedFormikCheckboxInputWrapper>
            <div className={"foreground"}>
                <div className={"checkbox"} children={
                    props.fp.values[props.name] === true ? (
                        <CheckRounded style={{
                            width: 18
                        }}/>
                    ) : undefined
                }/>

                <ArcText text={<span>I agree to the <u>Terms & Privacy</u></span>}/>
            </div>

            <StyledRoundedFormikCheckboxInput
                ref={ref}
                type={"checkbox"}
                onChange={props.fp.handleChange}
                value={props.fp.values[props.name]}
                {...props as React.InputHTMLAttributes<HTMLInputElement>}
                spellCheck={false}
            />
        </StyledRoundedFormikCheckboxInputWrapper>

    );
});
