import React, {useContext} from "react";
import styled from "styled-components";
import {RoundedMainTextButton} from "../components/RoundedMainTextButton";
import {RoundedFormikBaseInput} from "../components/form/RoundedFormikBaseInput";
import {StorybookAPIContext} from "./Storybook";

const StyledMobileInnerFormDialog = styled.div`
  width: 100%;
  height: 100%;
  background-color: red;
`;

export const MobileInnerFormDialog: React.FC = props => {
    const api = useContext(StorybookAPIContext);

    return (
        <StyledMobileInnerFormDialog>

        </StyledMobileInnerFormDialog>
    );
}
