import {SearchContext} from "./SearchContext";
import {webDB} from "../webapi/WebAPIDB";
import {Project} from "../data/Project";
import {SearchResult} from "./SearchResult";
import _ from "lodash";

export class SearchEngine {

    private _ctx: SearchContext | undefined;

    private get ctx(): SearchContext {
        return this._ctx as SearchContext;
    }

    public async search(ctx: SearchContext): Promise<SearchResult> {
        try {
            this._ctx = ctx;

            return ({
                projects: await this.searchProjects()
            });
        } finally {
            this._ctx = undefined;
        }
    }

    private async searchProjects(): Promise<Array<Project>> {
        const searchInTitle = this.ctx.input.trim().length > 0;

        return webDB.projects.toCollection().filter(x => {
            let latch = false;

            // Search in titles
            if (searchInTitle && x.title.includes(this.ctx.input)) latch = true;

            // Search in tags
            if (x.tags.includes(this.ctx.input)) latch = true;

            // Search in custom properties
            if (x.metaProperties.map(p => p.value).includes(this.ctx.input)) latch = true;

            return latch;
        }).toArray();
    }
}
