// noinspection SpellCheckingInspection

import {NodeSetupInfo} from "../../../NodeSetupInfo";
import {Node} from "../../../../backend/Node";
import {v4} from "uuid";
import axios from "axios";
import {
    StableDiffusionAttachmentRetriever
} from "../../../../../ardai/webapi/pngProperties/StableDiffusionAttachmentRetriever";

export type NodeI2IUpscaleState = {
    scale: number,
    denoisingStrength: number,
    steps: number,
    sampler: string,
}

export const NodeI2IUpscale: NodeSetupInfo<NodeI2IUpscaleState> = {
    label: "NodeI2IUpscale",
    classname: "tests.sdwui.i2i-upscale",
    parameterConfig: [],
    factory: parameters => new Node<NodeI2IUpscaleState>({
        id: v4(),
        classname: "tests.sdwui.i2i-upscale",
        label: "upscale",
        defInPins: [
            "c", "img"
        ],
        defOutPins: [
            "img"
        ],
        state: {
            scale: 1,
            denoisingStrength: .2,
            steps: 50,
            sampler: "Euler"
        },
        init() {

            const config = this.pins.createPinBank("config", {
                label: "config",
                hideLabelFlag: true
            });

            config.pin("scale").attachOnRead((scale: number) => {
                scale = Number(scale);
                this.state.update({ scale });
            });

            config.pin("denoise").attachOnRead((denoisingStrength: number) => {
                denoisingStrength = Number(denoisingStrength);
                this.state.update({ denoisingStrength });
            });

            config.pin("steps").attachOnRead((steps: number) => {
                steps = Number(steps);
                this.state.update({ steps });
            });

            config.pin("sampler").attachOnRead((sampler: string) => {
                this.state.update({ sampler });
            });

            this.pins.in("c").attachOnRead(async (signal: any) => {
                const img = this.pins.in("img").lastReadState;
                // TODO: Handle img === undefined

                let reqBodyObj: any = {};
                const imgBlob = await (await fetch(`data:image/png;base64,${img}`)).blob();
                const imgMeta = await new StableDiffusionAttachmentRetriever(imgBlob).retrieveMetaData()
                const scale = this.state.state.scale ?? 1;
                const denoisingStrength = this.state.state.denoisingStrength ?? .2;
                const steps = this.state.state.steps ?? 50;
                const sampler = this.state.state.sampler ?? "Euler";
                const bitmap = await createImageBitmap(imgBlob);

                reqBodyObj = {
                    init_images: [img],
                    denoising_strength: denoisingStrength,
                    prompt: imgMeta.prompt,
                    negative_prompt: imgMeta.negativePrompt,
                    sampler_name: sampler,
                    steps: steps,
                    cfg_scale: 7,
                    width: bitmap.width * scale,
                    height: bitmap.height * scale,
                }

                const res = await axios.post("http://localhost:7860/sdapi/v1/img2img", reqBodyObj, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                });

                const body = res.data;
                const upscaledImg = body.images[0];
                this.pins.out("img").write(upscaledImg);
                this.pins.out("body").write(body);
                this.pins.out("stat").write(res.status);
            });
        }
    })
}
