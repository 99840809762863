import {NodeAppBackend} from "./NodeAppBackend";
import {AppSubSystemInfo} from "./AppSubSystemInfo";
import {v4} from "uuid";

export abstract class AppSubSystem {

    private _info: AppSubSystemInfo = {
        id: v4(),
        label: `generic-sub-system`,
    };

    public constructor(
        protected readonly app: NodeAppBackend,
    ) {}

    public updateInfo(updates: Partial<AppSubSystemInfo> = {}) {
        this._info = {
            ...this._info,
            ...updates
        };
    }

    public init() {}

    public start() {}

    get info(): AppSubSystemInfo {
        return this._info;
    }
}
