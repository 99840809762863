import {NodeSetupInfo} from "../../NodeSetupInfo";
import {Node} from "../../../backend/Node";
import {v4} from "uuid";
import {triton} from "../../../../triton/Triton";
import {CSSProperties, FC} from "react";

export const NodeStringInput: NodeSetupInfo = {
    label: "String input",
    classname: "input.string-input",
    parameterConfig: [],
    factory: parameters => {
        const hasSetter = parameters.get("enable-setter") ?? false;
        const isMultilineMode = parameters.get("multiline-mode") ?? true;
        const width = parameters.get("width") ?? 80;

        return (
            new Node({
                id: v4(),
                classname: "input.string-input",
                label: "string",
                defOutPins: ["s"],
                state: {
                    width,
                    isMultilineMode,
                    hasSetter,
                    s: ""
                },
                reset: function () {
                    this.pins.out("s").write("");
                },
                init: function () {
                    // TODO: Doesn't work... -> state not always present
                    if (this.state.state.hasSetter ?? hasSetter) {
                        this.pins.in("s").attachOnRead(s => {
                            this.state.update({
                                s
                            });
                        });
                    }
                },
                customRenderer: node => {

                    const isMultilineMode = node.state.state.isMultilineMode;
                    const width = node.state.state.width;

                    const updateString = (s: string) => {
                        node.pins.out("s").write(s);
                        node.state.update({ s });
                    }

                    const inputBaseStyling: CSSProperties = {
                        maxWidth: width,
                        width: width,
                        fontSize: 12,
                        color: triton().col("fg_muted"),
                        border: "none",
                        backgroundColor: "transparent",
                        outline: "none",
                        resize: "vertical",
                        minHeight: 17,
                        padding: "1px 2px"
                    }

                    return (
                        <div style={{
                            display: "flex",
                            gap: 4,
                            flexDirection: "column",
                            padding: "8px 0",
                            alignItems: "center",
                            height: "100%",
                            justifyContent: "center"
                        }}>
                            { isMultilineMode ? (
                                <textarea
                                    value={node.state.state.s}
                                    onChange={e => updateString(e.currentTarget.value)}
                                    style={{
                                        ...inputBaseStyling,
                                        height: 80
                                    }}
                                />
                            ) : (
                                <input
                                    type={"text"}
                                    value={node.state.state.s}
                                    onChange={e => updateString(e.currentTarget.value)}
                                    style={inputBaseStyling}
                                />
                            ) }
                        </div>
                    );
                }
            })
        );
    }
}

const StringInputComponent: FC<{
    node: Node
}> = props => {
    const { node } = props;

    return (
        <></>
    );
}
