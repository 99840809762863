import React, {useContext} from "react";
import styled from "styled-components";
import {StringField} from "../testing/StringField";
import {Property} from "../data/Property";
import {Menu} from "./Menu";
import {ButtonGroup} from "./ButtonGroup";
import {IconButton} from "./IconButton";
import {Backspace, DeleteRounded, EditRounded, MoreHorizRounded} from "@mui/icons-material";
import {MenuButton} from "./MenuButton";
import {ArdaiAPIContext} from "../webapi/WebAPI";

const StyledPropertyDisplay = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  gap: 8px;
  align-items: center;
  
  .property-field {
    overflow: hidden;
  }
`;

export type PropertyDisplayProps = {
    property: Property
}

export const PropertyDisplay: React.FC<PropertyDisplayProps> = props => {
    const p = props.property;
    const api = useContext(ArdaiAPIContext);

    return (
        <StyledPropertyDisplay>
            <div className={"property-field"}>
                <StringField name={p.key} data={p.value} onChange={async (value) => {
                    await api.getProjectContext().editProperty(p.key, value);
                }}/>
            </div>

            <Menu opener={<MoreHorizRounded sx={{ color: "white", fontSize: 20, cursor: "pointer" }}/>}>
                <MenuButton text={"Edit"} icon={<EditRounded/>}/>
                <MenuButton text={"Remove property"} icon={<DeleteRounded/>} onSelect={async () => {
                    await api.getProjectContext().removeProperty(p.key);
                }}/>
            </Menu>
        </StyledPropertyDisplay>
    );
}
