export class MasterWorkerField<T> {

    private _master: T;

    private _worker: T;

    constructor(master: T, worker: T = master) {
        this._worker = worker;
        this._master = master;
    }

    public commit(): T {
        const temp = this._master;
        this._master = this._worker;
        this._worker = temp;
        return this._master;
    }

    public update(f: (this: T, f: T) => T | undefined): this {
        const updated = f.call(this.worker, this.worker);
        if (updated !== undefined) {
            this.worker = updated;
        }
        return this;
    }

    get worker(): T {
        return this._worker;
    }

    set worker(value: T) {
        this._worker = value;
    }
    get master(): T {
        return this._master;
    }
}
