import React from "react";
import styled from "styled-components";
import {DescriptiveTypography} from "../../typography/DescriptiveTypography";
import {MainTypography} from "../../typography/MainTypography";

const StyledDrawerHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

export type DrawerHeaderProps = {
    // visuals & decorators
    title?: string,
    description?: string,
    // actions
    onClose?: () => void
}

export const DrawerHeader: React.FC<DrawerHeaderProps> = props => {
    return (
        <StyledDrawerHeader>

            { props.title && (
                <MainTypography text={props.title} style={{
                    fontSize: 20
                }}/>
            ) }

            { props.description && (
                <DescriptiveTypography text={props.description} style={{
                    textAlign: "center",
                    padding: "0 32px"
                }}/>
            ) }

        </StyledDrawerHeader>
    );
}
