import {Vec2D} from "./Vec2D";
import {PolarVec2D} from "./PolarVec2D";
import {Quadrant} from "./Quadrant";

export class CartesianVec2D extends Vec2D {

    public get quadrant(): Quadrant {
        if (this.x < 0 && this.y < 0) {
            return Quadrant.SW;
        } else if (this.x < 0 && this.y >= 0) {
            return Quadrant.NW;
        } else if (this.x >= 0 && this.y < 0) {
            return Quadrant.SE;
        } else {
            return Quadrant.NW
        }
    }

    public get polar(): PolarVec2D {
        let theta = Math.atan(this.y / this.x);
        switch (this.quadrant) {
            case Quadrant.NW:
            case Quadrant.SW:
                theta += 180;
                break;
            case Quadrant.SE:
                theta += 360;
        }
        return new PolarVec2D(
            theta,
            Math.sqrt(this.x ** 2 + this.y ** 2)
        );
    }

    public plus(operand: CartesianVec2D): CartesianVec2D {
        return new CartesianVec2D(
            this.x + operand.x,
            this.y + operand.y
        );
    }

    public toString(fractionDigits: number = -1) {
        return `[x=${
            fractionDigits >= 0 ? this.x.toFixed(fractionDigits) : this.x
        }, y=${
            fractionDigits >= 0 ? this.y.toFixed(fractionDigits) : this.y
        }]`;
    }
}
