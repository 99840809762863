import React, {PropsWithChildren} from "react";
import styled from "styled-components";

const StyledMasonryImageElement = styled.div`
  position: relative;
  border-radius: 10px;
  // overflow: hidden;
  // height: auto;
  min-height: 75px;
  width: 100%;
  -webkit-user-drag: none;
  
  .masonry-image-el-fg {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    pointer-events: none;
  }

  .masonry-image-el-bg {
    position: relative;
    width: 100%;
    overflow: hidden;
    // background-color: #E2E2E4;
    // background-color: red;
    border-radius: 10px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    min-height: 75px;
    height: auto;

    object-fit: contain;
    -webkit-user-drag: none;
    user-select: none;
    
    img {
      width: 100%;
      object-fit: contain;
      height: auto;
      border-radius: 10px;
      -webkit-user-drag: none;
    }
  }
`;

export type MasonryImageElementProps = PropsWithChildren<{
    header?: React.ReactNode,
    footer?: React.ReactNode,
}>

export const MasonryImageElement: React.FC<MasonryImageElementProps> = props => {
    return (
        <StyledMasonryImageElement>
            <div className={"masonry-image-el-fg"}>
                { props.header ?? <span/> }
                { props.footer }
            </div>
            <div className={"masonry-image-el-bg"}>
                { props.children }
            </div>
        </StyledMasonryImageElement>
    );
}
