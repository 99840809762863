import React, {useContext} from "react";
import {SimulationContext} from "../../Simulation2Main";
import {ValveControlDisplay} from "../../entities/valve/ValveControlDisplay";
import {GasMeterRounded, NumbersRounded} from "@mui/icons-material";
import {hex} from "../../../../base/logic/style/Color";
import {Button} from "../../components/Button";
import {StyledControlDisplayGroup} from "../../components/StyledControlDisplayGroup";
import {clamp} from "lodash";
import {ButtonBase} from "../../../../triton/components/buttons/ButtonBase";
import {DescriptiveTypography} from "../../../../triton/components/typography/DescriptiveTypography";
import {Interpolate} from "../../../../triton/components/advanced/Interpolate";

export const EngineFuelLineWidget: React.FC = props => {
    const sim = useContext(SimulationContext);
    const valve = sim.entity("valve");

    const setValveOpenPercentage = (percent: number, isDelta: boolean = false) => {
        valve.setState(prevState => ({
            ...prevState,
            throughput: clamp((isDelta ? prevState.throughput ?? 0 : 0) + percent, 0, 100)
        }));
    }

    return (
        <StyledControlDisplayGroup style={{
            height: 60,
            // aspectRatio: "1 / 1",
            display: "flex",
            flexDirection: "row",
            gap: 6
        }}>
            <div style={{
                width: 40,
                height: "100%",
                display: "grid",
                gridTemplateRows: "repeat(1, 1fr)",
                alignItems: "center"
            }}>
                <Interpolate value={valve?.state.throughput ?? 0}/>
            </div>

            <Button square children={<GasMeterRounded/>} color={{
                if: [{ cond: () => valve?.state.throughput > 0, color: hex("#ffdf60") }]
            }} onClick={() => {
                if (valve?.state.throughput > 0) {
                    valve.setStatePartially({
                        throughput: 0
                    })
                } else {
                    valve.setStatePartially({
                        throughput: 100
                    })
                }
            }}/>

            <Button square children={<DescriptiveTypography text={"-10"}/>} onClick={() => setValveOpenPercentage(-10, true)}/>

            <Button square children={<DescriptiveTypography text={"+10"}/>} onClick={() => setValveOpenPercentage(10, true)}/>

        </StyledControlDisplayGroup>


    );
}
