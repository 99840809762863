import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import {useTriton} from "../../../triton/TritonHooks";
import {TransitionGroup} from "react-transition-group";
import {Zoom} from "@mui/material";

const StyledMobileNavigationIcon = styled.div`
  .mni-visual {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    & > * {
      width: 32px;
      aspect-ratio: 1 / 1;
      transition: background-color 100ms;
    }
  }
  
  .mni-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 26px;
    
    .active-tab-indicator {
      width: 5px;
      aspect-ratio: 1 / 1;
      border-radius: 10px;
    }
  }
`;

export type MobileNavigationIconProps = PropsWithChildren<{
    active?: boolean,
    onSelect?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}>

export const MobileNavigationIcon: React.FC<MobileNavigationIconProps> = props => {
    const triton = useTriton();
    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        props.onSelect?.(e);
    };

    return (
        <StyledMobileNavigationIcon onClick={handleClick}>
            <div className={"mni-visual"} children={props.children} style={{
                color: props.active ? triton.col("fg_default") : triton.col("fg_muted")
            }}/>
            <div className={"mni-footer"}>
                <TransitionGroup children={ props.active && (
                    <Zoom timeout={1e2} children={
                        <div className={"active-tab-indicator"} style={{
                            backgroundColor: triton.col("button_primary")
                        }}/>
                    }/>
                ) }/>
            </div>
        </StyledMobileNavigationIcon>
    );
}
