export enum Quadrant {
    // -
    NE = 1,
    // x < 0
    NW = 2,
    // x, y < 0
    SW = 3,
    // y < 0
    SE = 4
}
