import React, {useContext} from "react";
import {ArdaiAPIStateContext} from "../../ArdaiMain";
import {Workspace} from "../../components/Workspace";
import {VFSViewOptions} from "../../components/VFSViewOptions";
import {VFSView} from "../../components/VFSView";
import {CodeRounded, HistoryRounded, SettingsApplicationsRounded} from "@mui/icons-material";
import {TabBar} from "../../components/TabBar";
import {MobileGalleryViewNavigation} from "../../mobile/MobileGalleryViewNavigation";
import styled from "styled-components";
import {ImageView} from "../../components/ImageView";
import {SelectionView} from "../../components/SelectionView";
import {ProjectHeaderView} from "../../components/ProjectHeaderView";
import {ProjectView} from "../../components/ProjectView";
import {MetadataView} from "../../components/MetadataView";
import {MetadataViewV2} from "../../components/MetadataViewV2";
import {ChallengerTab} from "../../_/challenger/ChallengerTab";
import {ScrollViewContainer} from "../../components/mobile/main/ScrollViewContainer";
import {ScrollView} from "../../components/mobile/main/ScrollView";
import {MainTypography} from "../../../triton/components/typography/MainTypography";
import {ImageDetailsView} from "../../components/imageDetailsView/ImageDetailsView";

const StyledMobileGalleryView = styled.div`
  display: grid;
  height: 100%;
  row-gap: 8px;
  grid-template-rows: auto min-content;
  
  .main-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100vw;
    scroll-snap-type: x mandatory;
    overflow-y: hidden;
    overflow-x: scroll;
    gap: 16px;
    
    transition: all 1s; // TODO: Remove
    
    .crude-tab {
      width: 100vw !important;
      flex-shrink: 0;
      height: 100% !important;
      scroll-snap-align: start;
      display: grid;
    }
  }
`;

export const MobileGalleryView: React.FC = props => {
    const state = useContext(ArdaiAPIStateContext);

    return (
        <StyledMobileGalleryView>
            <div className={"main-container"}>

                <ScrollView id={"vfs"}>
                    <ScrollViewContainer scrollOrientation={"y"}>
                        <ScrollView style={{
                            gridTemplateRows: "min-content auto",
                            rowGap: "8px",
                        }}>
                            <Workspace children={<VFSViewOptions/>} config={{mode: "desktop", name: "vfs-options"}}/>
                            <Workspace children={<VFSView/>} config={{mode: "desktop", name: "vfs"}} style={{
                                overflow: "hidden"
                            }}/>
                        </ScrollView>

                        <ScrollView>
                            <Workspace children={<ProjectHeaderView/>} config={{mode: "desktop", name: "project-title"}} style={{
                                height: "100%"
                            }}/>
                        </ScrollView>
                    </ScrollViewContainer>
                </ScrollView>


                <ScrollView id={"project"} style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "8px",
                    height: "calc(100% - 0px)"
                }}>
                    <Workspace style={{flexShrink: 0, height: "min-content"}} children={<ProjectHeaderView/>} config={{mode: "desktop", name: "project-title"}}/>
                    <Workspace style={{flexShrink: 2}} children={<ProjectView/>} config={{mode: "desktop", name: "project"}}/>
                </ScrollView>


                <ScrollView id={"image"}>
                    <ScrollViewContainer scrollOrientation={"y"}>
                        <ScrollView style={{
                            gridTemplateRows: "auto min-content",
                            rowGap: "8px",
                        }}>
                            <ImageView/>
                            <SelectionView/>
                        </ScrollView>

                        <ScrollView>
                            <Workspace
                                children={<MetadataViewV2/>}
                                config={{ mode: "desktop", name: "current-image-meta-view" }}
                            />
                        </ScrollView>

                        <ScrollView>
                            <Workspace
                                children={<ImageDetailsView/>}
                                config={{ mode: "desktop", name: "current-image-details-view" }}
                                style={{
                                    overflow: "hidden"
                                }}
                            />
                        </ScrollView>
                    </ScrollViewContainer>
                </ScrollView>


                <ScrollView id={"challenge"}>
                    <Workspace
                        children={<ChallengerTab/>}
                        config={{ mode: "desktop", name: "challenge-view" }}
                        style={{
                            overflow: "hidden"
                        }}
                    />
                </ScrollView>
            </div>

            <MobileGalleryViewNavigation/>
        </StyledMobileGalleryView>
    );
}
