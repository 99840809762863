import {RGBValue} from "./RGBValue";

export namespace HSVToRGBUtils {

    export function HSVtoRGB(h: number, s: number, v: number): RGBValue {
        let r, g, b, i, f, p, q, t;
        i = Math.floor(h * 6);
        f = h * 6 - i;
        p = v * (1 - s);
        q = v * (1 - f * s);
        t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0: r = v; g = t; b = p; break;
            case 1: r = q; g = v; b = p; break;
            case 2: r = p; g = v; b = t; break;
            case 3: r = p; g = q; b = v; break;
            case 4: r = t; g = p; b = v; break;
            case 5: r = v; g = p; b = q; break;
        }
        return {
            r: Math.round(r as number * 255),
            g: Math.round(g as number * 255),
            b: Math.round(b as number * 255)
        };
    }
}
