import {Pin, PinMode} from "./Pin";
import {NodeDependent} from "./NodeDependent";
import {Node} from "./Node";
import {PinBank, PinBankConfig} from "./PinBank";
import {Inflatable} from "./serialization/v2/Inflatable";
import {markInflatable} from "./serialization/v2/DecoratorMarkInflatable";
import {SaveStateNode} from "./serialization/v2/SaveStateNode";
import {SerializableObject} from "../../../std/SerializableObject";
import {FQPinId} from "./FQPinId";
import {transient} from "./serialization/v2/DecoratorTransient";
import {PhasingUtilities} from "./serialization/v2/PhasingUtilities";
import {InflationContext} from "./serialization/v2/InflationContext";

@markInflatable({
    additionalTransients: ["node"]
})
export class PinLayout<Key = string> extends NodeDependent implements Inflatable {

    @transient
    private readonly inflationPhaser = new PhasingUtilities.Phaser();

    public readonly pinBanks: Map<string, PinBank<Key>> = new Map<string, PinBank<Key>>();

    id;

    constructor(node: Node) {
        super(node);
        this.id = `${node.id}-pin-layout`
        this.initPhasedInflationSystem();
        this.initDefaultBanks();
    }

    private initPhasedInflationSystem() {
        this.inflationPhaser.registerPhase("wiring", {
            inflate: (save: SerializableObject, node: SaveStateNode, ctx: InflationContext) => {
                const outConnectionsTo: Array<[FQPinId, FQPinId]> = save["outPinConnections"] as Array<[FQPinId, FQPinId]>;
                outConnectionsTo.forEach(outCon => {
                    const sourcePinId = outCon[0], targetPinId = outCon[1];

                    const targetPinInstance = this.node.env.getNodeByID(targetPinId.node).pins.resolvePinByPinFqId(targetPinId);
                    const sourcePinInstance = this.node.env.getNodeByID(sourcePinId.node).pins.resolvePinByPinFqId(sourcePinId);

                    sourcePinInstance.connect(targetPinInstance);

                    // targetPinInstance.connect(sourcePinInstance);
                })
            }
        });
    }

    public resolvePinByPinFqId(fq: FQPinId): Pin {
        const bank = this.bank(fq.bankLabel!);
        return bank.pin(fq.pinLabel! as Key);
    }

    public get defInBank(): PinBank<Key> {
        return this.bank("in");
    }

    public get defOutBank(): PinBank<Key> {
        return this.bank("out");
    }

    private initDefaultBanks() {
        this.createPinBank("in", {
            label: "in",
            groups: ["in"],
            defaultPinMode: PinMode.IN
        });

        this.createPinBank("out", {
            label: "out",
            groups: ["out"],
            defaultPinMode: PinMode.OUT
        });
    }

    public createPinBank(key: string, config: PinBankConfig): PinBank<Key> {
        const bank = new PinBank<Key>(this.node, config);
        this.pinBanks.set(key, bank);
        return bank;
    }

    public bank(key: string): PinBank<Key> {
        return this.pinBanks.get(key)!;
    }

    public get allDefaultIn(): Array<Pin> {
        return Array.from(this.defInBank.allPins);
    }

    public get allDefaultOut(): Array<Pin> {
        return Array.from(this.defOutBank.allPins);
    }

    public get allIn(): Array<Pin> {
        return this.filterAllPins(p => p.config.mode === PinMode.IN);
    }

    public get allOut(): Array<Pin> {
        return this.filterAllPins(p => p.config.mode === PinMode.OUT);
    }

    public in(key: Key): Pin {
        return this.defInBank.pin(key);

        // if (!this.inBank.has(key)) {
        //     const newPin = new Pin(this.node, {
        //         label: String(key)
        //     });
        //     this.inBank.set(key, newPin);
        //     return newPin;
        // }
        // return this.inBank.get(key)!;
    }

    public out(key: Key): Pin {
        return this.defOutBank.pin(key);

        // if (!this.outBank.has(key)) {
        //     const newPin = new Pin(this.node, {
        //         label: String(key),
        //         mode: PinMode.OUT
        //     });
        //     this.outBank.set(key, newPin);
        //     return newPin;
        // }
        // return this.outBank.get(key)!;
    }

    public getPinById(id: string): Pin | undefined {
        for (let pin of this.allDefaultIn) {
            if (pin.config.id === id) return pin;
        }
        for (let bank of Array.from(this.pinBanks.values())) {
            for (let pin of Array.from(bank.pins.values())) {
                if (pin.config.id === id) return pin;
            }
        }
    }

    public getAllPins(): Array<Pin> {
        return this.filterAllPins();
    }

    public filterAllPins(predicate: (pin: Pin) => boolean = () => true): Array<Pin> {
        const targets: Array<Pin> = [];
        // targets.push(...this.allIn);
        // targets.push(...this.allOut);
        for (let bank of Array.from(this.pinBanks.values())) {
            targets.push(...Array.from(bank.pins.values()));
        }
        return targets.filter(predicate);
    }

    public filterAllBanks(predicate: (bank: PinBank<Key>) => boolean): Array<PinBank<Key>> {
        return Array.from(this.pinBanks.values()).filter(predicate);
    }

    private deflatePinOutputConnections(): Array<[FQPinId, FQPinId]> {
        const outConnectionsTo: Array<[FQPinId, FQPinId]> = [];
        this.getAllPins().forEach(pin => {
            pin.outputConnections.forEach(outCon => {
                outConnectionsTo.push([pin.fqId, outCon.fqId]);
            });
        });
        return outConnectionsTo;
    }

    amendDeflate(save: SerializableObject, node: SaveStateNode) {
        save["outPinConnections"] = this.deflatePinOutputConnections();
    }

    amendInflate(save: SerializableObject, node: SaveStateNode, ctx: InflationContext) {
        this.inflationPhaser.inflate(save, node, ctx);

    }
}
