import {AppSubSystem} from "../AppSubSystem";
import {TritonTabManager} from "../../../../triton/logic/tabs/TritonTabManager";
import {NodeAddBackendEventTypes} from "../NodeAddBackendEventTypes";
import {TabManagerEvent} from "../../../../triton/logic/tabs/TabManagerEvent";
import {TabManagerEvents} from "../../../../triton/logic/tabs/TabManagerEvents";

/**
 * open <- TabInfo
 * close <- TabId
 * tabs
 * current -> Tab
 *
 */
export class MainTabManager extends AppSubSystem {

    private fireOverarchingSubsystemEvent() {
        this.app.events.notify(NodeAddBackendEventTypes.TAB_SUBSYSTEM_OVERARCHING, undefined);
    }

    public readonly manager = new TritonTabManager({
        onPostEvent: (e: TabManagerEvent, on: keyof TabManagerEvents) => {
            this.fireOverarchingSubsystemEvent();
        }
    });
}
