import {Userspace} from "./Userspace";
import {MemCache} from "../../MemCache";
import {MemKVCache} from "../../MemKVCache";
import {LoginStatus} from "../../shared/LoginStatus";
import axios from "axios";
import {arcURL} from "../../Globals";
import {StandardizedAPIResponse} from "../../shared/StandardizedAPIResponse";
import {LoginStatusMode} from "../../shared/LoginStatusMode";

export class CommonCore {

    private _cache: MemKVCache = new MemKVCache();

    private _userspace: Userspace = new Userspace();

    public async loginByBasicCredentials(email: string, password: string): Promise<LoginStatus> {
        return new Promise<LoginStatus>((resolve, reject) => {
            axios({
                url: arcURL("login"),
                method: "post",
                data: {
                    password: password,
                    email: email
                }
            }).then(res => {
                const data: StandardizedAPIResponse = res.data;
                // OK
                if (data.code === 0 && data.payload !== null) {
                    const crudeToken = data.payload.refreshToken;
                    const jwt = data.payload.jwt;
                    // Set crudeToken & and jwt to the local-store
                    localStorage.setItem("master-token", crudeToken);
                    localStorage.setItem("session-token", jwt);
                    // Set Bearer token
                    axios.defaults.headers.common = {'Authorization': `Bearer ${jwt}`}
                    // Login was fully successful :: Redirect to origin page
                    resolve({
                        mode: LoginStatusMode.OK
                    });
                }

                // INCORRECT CREDENTIALS
                if (data.code === -100) {
                    resolve({
                        mode: LoginStatusMode.INCORRECT_CREDENTIALS
                    });
                }

                // EMAIL NOT VERIFIED
                if (data.code === -101) {
                    resolve({
                        mode: LoginStatusMode.VERIFY_IP
                    });
                }
            }).catch(reason => reject(reason));
        })
    }

    get cache(): MemKVCache {
        return this._cache
    }

    get userspace(): Userspace {
        return this._userspace
    }
}

let commonCoreInstance: CommonCore | undefined = undefined;

export const commonCore = (factory: () => CommonCore = () => new CommonCore()) => {
    if (commonCoreInstance === undefined) commonCoreInstance = factory();
    return commonCoreInstance!
}
