import React, {useContext} from "react";
import {ScreenSaver} from "./components/shared/screenSaver/ScreenSaver";
import {AppHeader} from "./components/desktop/AppHeader";
import {ArdaiAPIStateContext} from "./ArdaiMain";
import {Route, Routes, useLocation} from "react-router-dom";
import {GalleryView} from "./views/gallery/GalleryView";
import {GenerationView} from "./views/generate/GenerationView";
import {GlobalStyles} from "@mui/material";
import {TestingViewMain} from "./testing/TestingViewMain";
import {default2MockupUserProfileProps, UserProfile, UserProfileProps} from "./testing/tests/profile/UserProfile";
import {DType} from "./testing/DType";
import {TestScriptingView} from "./unifiedGraphicsLanguage/TestScriptingView";
import {Workspace} from "./components/Workspace";
// import {CommandContext, CommandNode, CommandNodeOption, KeyCommandTest} from "./_/keyCommand/test/KeyCommandTest";
import {hex} from "../base/logic/style/Color";
import {NotificationCenterWrapperTest} from "./_/notifications/test/NotificationCenterTest";
import {Background} from "./background/Background";
import {BackgroundType} from "./background/BackgroundType";
import {as} from "../atlas/Lang";
import {useTheme} from "../triton/TritonHooks";
import {TritonContext} from "../triton/TritonContext";
import {BackgroundSolidInformation} from "./background/BackgroundSolidInformation";
import {TransitionGroup} from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import {BackgroundImageInformation} from "./background/BackgroundImageInformation";
import {ImageRenderingMode} from "../atlas/hyperion/datatypes/ImageRenderingMode";
import {FooterComponent} from "./footer/FooterComponent";
import {ArdaiRoutesComponent} from "./ArdaiRoutesComponent";
import {BackgroundImages} from "./BackgroundImages";
import {GlobalStyling} from "./GlobalStyling";
import {CommandContext, CommandNode, CommandNodeOption, KeyCommandTest} from "./_/keyCommand/test/KeyCommandTest";
import {KeyCommandOption} from "../atlas/keylogger/KeyCommandOption";

export const DefaultImageGalleryView: React.FC = props => {
    const state = useContext(ArdaiAPIStateContext);
    const location = useLocation();
    const theme = useTheme();
    const triton = useContext(TritonContext);

    return (
        <div style={{
            display: "grid",
            padding: "8px",
            gridAutoRows: "min-content auto",
            width: "calc(100vw + 0px)",
            height: "calc(100vh)",
            maxHeight: "calc(100vh)",
            overflow: "hidden",
            position: "relative",

            // transform: "scale(.7)",
        }}>
            {/* Main react-based systems */}
            <ScreenSaver/>
            <NotificationCenterWrapperTest/>
            <GlobalStyling/>


            <KeyCommandTest displayPreferences={{
                palette: {
                    // TODO: Remove
                    main: triton.colObj("color_primary") /* hex("#ffbd29") */
                }
            }} cmdLib={
                CommandNode.newRoot()
                    // Q :: Quick actions
                    .addSubcommand(new CommandNode<void>({
                        key: "KeyQ",
                        title: "Quick actions",
                        description: "Fast quick action chooser",
                        execute(ctx: CommandContext) {

                            // ctx.instruction.mcc?.openDirect(mccCtx => (
                            //     <div style={{ width: 500, height: 500 }} children={
                            //         <Searchbar/>
                            //     }/>
                            // ), "searchbar");

                            // Execute quick
                            window.alert(ctx.instruction.keyPath[ctx.instruction.keyPath.length - 1].option?.value)
                        },
                        getOptions(): Array<CommandNodeOption> {
                            return ["Notifications"].map(a => ({
                                title: a, value: a
                            }))
                        }
                    }))
                    // T
                    .addSubcommand(new CommandNode<void>({
                        key: "KeyT",
                        title: "Switch theme",
                        execute(ctx: CommandContext) {
                            triton.switchTheme(ctx.instruction
                                .keyPath[ctx.instruction.keyPath.length - 1]
                                .option?.value ?? triton.theme.id
                            );
                        },
                        getOptions(): Array<CommandNodeOption> {
                            return triton.registeredThemes.map(t => ({
                                title: t.title,
                                value: t.id
                            }))
                        },
                        onOptionChange(option: CommandNodeOption) {
                            triton.switchTheme(option.value ?? triton.theme.id);
                        },
                        onLeaveNodeUnExecuted() {
                            triton.resetTheme();
                        }
                    }))
            }/>

            <TransitionGroup children={
                state.showMainNavbar && (
                    <Collapse orientation={"vertical"} children={
                        <div style={{ marginBottom: "1rem" }} children={
                            <AppHeader/>
                        }/>
                    }/>
                )
            }/>

            <ArdaiRoutesComponent/>

            {/* <FooterComponent/> */}

            <Background config={true ? ({
                type: BackgroundType.SOLID,
                data: as<BackgroundSolidInformation>({
                    color: theme.colors.color_gb_main
                })
            }) : ({
                type: BackgroundType.IMAGE,
                data: as<BackgroundImageInformation>({
                    opacity: .2,
                    blur: 0,
                    srcType: "url",
                    renderingMode: ImageRenderingMode.AUTO,
                    srcLink: BackgroundImages.mainAppBackgroundImage
                })
            })}/>
        </div>
    );
}
