import {FC} from "react";
import {Pin} from "../backend/Pin";
import {Node} from "../backend/Node";
import {Menu} from "../../ardai/components/Menu";
import {ColorableTag} from "../../ardai/components/ColorableTag";
import {Color} from "../../base/logic/style/Color";
import {PinInfoDisplay} from "./PinInfoDisplay";
import {NodeDisplay} from "./NodeDisplay";

export type NodeLinkTagProps = {
    node: Node
}

export const NodeLinkTag: FC<NodeLinkTagProps> = props => {
    const { node } = props;

    return (
        <div>
            <Menu padding={0} opener={
                <div
                    onMouseEnter={e => {
                        for (let nodeTargetHtmlElement of Array.from(document.querySelectorAll(`[data-node-target-id="${node.id}"]`))) {
                            const attrQN = "data-soft-selected";
                            let attr = nodeTargetHtmlElement.getAttributeNode(attrQN);
                            if (attr === null) {
                                attr = document.createAttribute(attrQN);
                                nodeTargetHtmlElement.setAttributeNode(attr);
                            }
                            attr!.value = "true";
                        }
                    }}
                    onMouseLeave={e => {
                        for (let nodeTargetHtmlElement of Array.from(document.querySelectorAll(`[data-node-target-id="${node.id}"]`))) {
                            const attrQN = "data-soft-selected";
                            let attr = nodeTargetHtmlElement.getAttributeNode(attrQN);
                            if (attr === null) {
                                attr = document.createAttribute(attrQN);
                                nodeTargetHtmlElement.setAttributeNode(attr);
                            }
                            attr!.value = "false";
                        }
                    }}
                >
                    <ColorableTag
                        tag={node.config.label ?? "?"}
                    />
                </div>
            }>
                <div style={{
                    padding: 4
                }}>
                    <NodeDisplay node={node} isPreview/>
                </div>
            </Menu>
        </div>
    );
}
