import React from "react";
import {PromptRendererBaseProps} from "./PromptRendererBaseProps";
import {DescriptiveTypography} from "../../../triton/components/typography/DescriptiveTypography";

export type PromptTextRenderer = PromptRendererBaseProps;

export const PromptTextRenderer: React.FC<PromptTextRenderer> = props => {
    return (
        <DescriptiveTypography text={props.originalPrompt}/>
    );
}
